import LinkOrAnchor from '@designSystem/LinkOrAnchor';
import { OrderSteps } from '../utils/constants';
import { ReactChild } from 'react';
import { changeStep } from '../reducer/orderSlice';
import styled from 'styled-components';
import theme from '@theme';
import { useAppDispatch } from '../../../app/hooks';
import { useTranslation } from 'react-i18next';

const OrderBookToast = ({
  message = '',
  canDisplayLink = false,
  onClose
}: {
  message: string | ReactChild;
  canDisplayLink: boolean;
  onClose: CallableFunction;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <StyledOrderToastContainer>
      <div className="title">{t('features.order.oops', 'Oops...')}</div>
      <div className="error_message">{message}</div>
      {canDisplayLink && (
        <div className="error_cta">
          <LinkOrAnchor
            nopadding
            onClick={() => {
              dispatch(changeStep(OrderSteps.APPOINTMENT));
              onClose();
            }}
          >
            {t('features.order.chooseSlot', 'Choose a new slot')}
          </LinkOrAnchor>
        </div>
      )}
    </StyledOrderToastContainer>
  );
};

export default OrderBookToast;

const StyledOrderToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .title {
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};
    color: ${theme.palette.grey[900]};
  }

  .error_message {
    font-weight: ${theme.fonts.weight.regular};
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};
    color: ${theme.palette.grey[500]};
  }

  .error_cta {
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};
  }
`;
