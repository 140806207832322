export const calendarDatePickerStyle = `
  .rdp {
    display: flex;
    justify-content:center;
    margin: 0px;
  }

  .rdp-months {
    justify-content: center;
  }

  .rdp-table {
    margin-left: 20px;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    background: #3884FF;
    // font-size: 13px;
    line-height: 24px;
    color: white;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    background: #3884FF;
    // font-size: 13px;
    line-height: 24px;
    color: white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
  }


  .rdp-day_selected:not([aria-disabled='true']), .rdp-day_selected:focus:not([aria-disabled='true']), .rdp-day_selected:active:not([aria-disabled='true']), .rdp-day_selected:hover:not([aria-disabled='true']) {
    color: black;
    background-color: #E7F0FF;
  }

  .rdp-button:hover:not([disabled]) {
      background-color: #CCE3FF;
  }
  
  .day-today { 
    font-weight: bold;
    font-size: 100%; 
  }
`;
