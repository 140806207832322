import {
  IOfferingResult,
  ProductOfferingQualificationItem
} from '@features/eligibility/interfaces';
import OptionsForm, {
  getDefaultGtrFromProduct
} from '../../../eligibility/products/productDetail/OptionsForm';
import { useEffect, useState } from 'react';

import Badge from '@designSystem/Badges';
import LinkOrAnchor from '@designSystem/LinkOrAnchor';
import { OrderOptions } from '../../utils/orderInterfaces';
import ProductDetailModalFactory from '@features/eligibility/products/productDetail/modal/ProductDetailModalFactory';
import ResultsItem from '../../../eligibility/products/results/ResultsItem';
import { StyledSideContentCard } from '../../utils/OrderStyles';
import styled from 'styled-components';
import theme from '@theme';
import { useMatomoHarmonizer } from '../../../../utils/matomoHarmonizer';
import { useTranslation } from 'react-i18next';

const EditLink = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: ${theme.fonts.weight.medium};
  margin-top: 8px;
`;

const OrderInformationsSidebar = ({
  productToOrder,
  selectedOptions,
  isBusiness
}: {
  productToOrder: IOfferingResult | null;
  selectedOptions: OrderOptions | undefined;
  isBusiness?: boolean;
}) => {
  const [showPopOver, setShowPopOver] = useState<string | false>(false);
  const defaultGtr = productToOrder ? getDefaultGtrFromProduct(productToOrder) : '';
  const { t } = useTranslation();
  const trackEvent = useMatomoHarmonizer();
  const [detailProductData, setDetailProductData] = useState<
    ProductOfferingQualificationItem | undefined
  >();

  useEffect(() => {
    if (detailProductData) {
      trackEvent({
        page: 'Order_informations',
        category: 'Options',
        actionType: 'Select',
        actionDetails: 'New_Order'
      });
    }
  }, [detailProductData]);
  return (
    <>
      <StyledSectionTitle>
        {t('features.order.orderInformations.selectProduct', 'Selected product')}
      </StyledSectionTitle>
      {productToOrder && (
        <>
          <StyledSideContentCard>
            <ResultsItem
              row={productToOrder}
              showPopOver={showPopOver}
              setShowPopOver={setShowPopOver}
            />
          </StyledSideContentCard>
          {isBusiness && (
            <>
              <br />
              <StyledSideContentCard>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontWeight: 500,
                    fontSize: '0.80rem'
                  }}
                >
                  <span>{t('features.order.orderInformations.debit', 'Débit intermédiaire')}</span>
                  <Badge>{selectedOptions?.profile}</Badge>
                </div>
              </StyledSideContentCard>
              <EditLink>
                <LinkOrAnchor onClick={() => setDetailProductData(productToOrder)}>
                  {t('component.button.edit', 'Edit')}
                </LinkOrAnchor>
              </EditLink>
            </>
          )}
        </>
      )}
      <br />
      <br />
      <StyledSectionTitle>
        {t('features.order.orderInformations.selectedOption', 'Selected options')}
      </StyledSectionTitle>
      {productToOrder && (
        <>
          <StyledSideContentCard>
            <OptionsForm
              selectedProduct={productToOrder}
              defaultOption={{ gtr: defaultGtr, eqt: undefined }}
              optionsToOrder={{ gtr: defaultGtr, ...selectedOptions }}
              editable={false}
            />
          </StyledSideContentCard>
          <EditLink>
            <LinkOrAnchor onClick={() => setDetailProductData(productToOrder)}>
              {t('component.button.edit', 'Edit')}
            </LinkOrAnchor>
          </EditLink>
        </>
      )}
      <br />
      {isBusiness && productToOrder && (
        <>
          <StyledSectionTitle>
            {t('features.order.orderInformations.selected_troncs', 'Selected Trunk(s)')}
          </StyledSectionTitle>
          <StyledSideContentCard>
            <TruncsStyled>
              <div className="sub_title">
                {t(
                  'features.eligibility.products.productDetail.optionTrunks_nominal',
                  "Tronc de l'accès nominal"
                )}
              </div>
              <div className="data">{selectedOptions?.trunks?.trunk ?? ''}</div>

              {selectedOptions?.trunks?.safetyTrunk ? (
                <>
                  <div className="sub_title">
                    {t(
                      'features.eligibility.products.productDetail.optionTrunks_bronze',
                      "Tronc de l'option sécurisation bronze"
                    )}
                  </div>
                  <div className="data">{selectedOptions?.trunks?.safetyTrunk ?? ''}</div>
                </>
              ) : null}

              {selectedOptions?.trunks?.trunkOptionPlus ? (
                <>
                  <div className="sub_title">
                    {t(
                      'features.eligibility.products.productDetail.optionTrunks_plus',
                      "Tronc de l'Option Plus"
                    )}
                  </div>
                  <div className="data">{selectedOptions?.trunks?.trunkOptionPlus ?? ''}</div>
                </>
              ) : null}
            </TruncsStyled>
          </StyledSideContentCard>
          <EditLink>
            <LinkOrAnchor onClick={() => setDetailProductData(productToOrder)}>
              {t('component.button.edit', 'Edit')}
            </LinkOrAnchor>
          </EditLink>
        </>
      )}
      <br />
      <ProductDetailModalFactory
        selectedProduct={productToOrder}
        detailProductData={detailProductData}
        selectedOptions={selectedOptions}
        handleClose={() => setDetailProductData(undefined)}
        nextStep={() => setDetailProductData(undefined)}
        loading={false}
      />
    </>
  );
};

const TruncsStyled = styled.div`
  display: grid;
  grid-gap: 8px;
  .sub_title {
    color: ${theme.palette.grey[400]};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .data {
    .data {
      color: ${theme.palette.grey[900]}
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }
`;
const StyledSectionTitle = styled.p`
  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.sm};
`;
export default OrderInformationsSidebar;
