import React, { Fragment } from 'react';

import styled from 'styled-components';
import theme from '../../style/theme';

export type StepProps = {
  key: string;
  title: string;
};

type ProgressStepperProps = {
  currentStep: string;
  stepList: StepProps[];
};

const StepperContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.primary};
  font-size: ${theme.fonts.size.xs};
  color: ${theme.palette.grey[400]};
`;

const StepName = styled.div`
  font-weight: bold;
  font-family: ${theme.fonts.primary};
  font-size: ${theme.fonts.size.xs};
  color: ${theme.palette.grey[700]};
`;

const ProgressBar = styled.div`
  display: flex;
  width: 100%;
  height: 8px;
  margin-top: 8px;
  background-color: ${theme.palette.grey[200]};
  position: relative;
  border-radius: 8px;
`;

const Progress = styled.div<{ progress: number }>`
  height: 100%;
  background-color: ${theme.palette.blue[500]};
  border-radius: 8px;
  width: ${({ progress }) => `${progress}%`};
  transition: width 0.3s ease-in-out;
`;

const ProgressStepper: React.FC<ProgressStepperProps> = ({ currentStep, stepList }) => {
  const totalSteps = stepList.length;
  const currentStepIndex = stepList.findIndex((step) => step.key === currentStep);
  const progress = ((currentStepIndex + 1) / totalSteps) * 100;

  return (
    <>
      <StepperContainer>
        {stepList.map((step, index) => (
          <Fragment key={step.key}>
            {index === currentStepIndex && <StepName> {step.title}</StepName>}
          </Fragment>
        ))}
        <Step>
          {currentStepIndex + 1}/{totalSteps}
        </Step>
      </StepperContainer>
      <ProgressBar>
        <Progress progress={progress} />
      </ProgressBar>
    </>
  );
};

export default ProgressStepper;
