import {
  StyledOrderLabelValue,
  StyledOrderSection,
  StyledOrderSiteWrapper
} from '../../utils/OrderStyles';

import OrderRecapEditLink from '../components/OrderRecapEditLink';
import { OrderSections } from '../../utils/OrderHelper';
import { StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import { useTranslation } from 'react-i18next';
import { Place } from '@features/order/utils/orderInterfaces';
import { formatAddressToString } from '@features/eligibility/eligibilitySlice';
import { IGeographicAddress } from '@features/eligibility/interfaces';
import { getBuildingIdFromAddress } from '@features/eligibility/helper';

const OrderRecapSiteSection = ({
  editable,
  places,
  address
}: {
  editable: boolean;
  places?: Place[];
  address: IGeographicAddress;
}) => {
  const { t } = useTranslation();
  const place = places?.length && places[0];

  const withoutAttribution = 'N/A';
  return (
    <StyledOrderSection>
      <div className="section-title">
        {t('features.order.orderRecap.orderRecapSections.end', 'End Client site')}
      </div>

      <StyledOrderSiteWrapper>
        <div className="half-width">
          <StyledLabel>
            {t('features.order.orderRecap.orderRecapSections.buildingRef', 'Building reference')}
          </StyledLabel>

          <StyledOrderLabelValue>
            {getBuildingIdFromAddress({
              id: (place && place.id) || '',
              referenceType: address.referenceType
            })}
          </StyledOrderLabelValue>
        </div>

        <div>
          <StyledLabel>
            {t('features.order.orderRecap.orderRecapSections.address', 'Address')}
          </StyledLabel>
          <StyledOrderLabelValue>{formatAddressToString(address)}</StyledOrderLabelValue>
        </div>

        <div className="section-row">
          <div className="section-row-item">
            <StyledLabel>
              {t('features.order.orderRecap.orderRecapSections.building', 'Building')}
            </StyledLabel>
            <StyledOrderLabelValue>
              {(place && place.buildingName) ?? withoutAttribution}
            </StyledOrderLabelValue>
          </div>
          <div className="section-row-item">
            <StyledLabel>
              {t('features.order.orderRecap.orderRecapSections.stair', 'Stair')}
            </StyledLabel>
            <StyledOrderLabelValue>
              {(place && place.stair) ?? withoutAttribution}
            </StyledOrderLabelValue>
          </div>
          <div className="section-row-item">
            <StyledLabel>
              {t('features.order.orderRecap.orderRecapSections.level', 'Level')}
            </StyledLabel>
            <StyledOrderLabelValue>
              {' '}
              {(place && place.floor) ?? withoutAttribution}
            </StyledOrderLabelValue>
          </div>
        </div>
      </StyledOrderSiteWrapper>
      {editable && <OrderRecapEditLink stepToGo={3} anchorKey={OrderSections.FinalClientSite} />}
    </StyledOrderSection>
  );
};

export default OrderRecapSiteSection;
