import { Col, Modal, Row } from 'react-bootstrap';
import { StyledModalBody, StyledModalCrossButton } from '../utils/SiteStatusStyled';

import OrderTickets from './Tickets';
import React from 'react';
import { XCircle } from 'react-bootstrap-icons';
import { selectSiteStatusState } from '../reducer/siteStatusSlice';
import { useAppSelector } from '../../../app/hooks';
import { useTranslation } from 'react-i18next';

const TicketsModal: React.FC<{
  setCurrentRefPrestationPrise: (_arg1: string | null) => void;
  currentRefPrestationPrise?: string | null;
}> = ({ setCurrentRefPrestationPrise, currentRefPrestationPrise }) => {
  const { siteStatusTickets } = useAppSelector(selectSiteStatusState);
  const { t } = useTranslation();
  return (
    <Modal
      dialogClassName="custom-bs-modal-width"
      size="xl"
      centered
      show={!!currentRefPrestationPrise}
      style={{ padding: '5%', overflow: 'auto' }}
      onHide={() => setCurrentRefPrestationPrise(null)}
    >
      <StyledModalBody className="large-padding">
        <Row>
          <Col sm="12" className="d-flex small-padding-horizontal" style={{ alignItems: 'center' }}>
            <h3>
              {t('features.siteStatus.tickets.title', 'X Associated Tickets', {
                defaultValue: '{{tickets}} Associated Tickets',
                tickets: siteStatusTickets?.length || 0
              })}
            </h3>
          </Col>
        </Row>
        <Row className="small-padding-horizontal">
          <Col sm="12" className="d-flex small-padding-vertical align-items-center">
            {t('features.siteStatus.tickets.outletService', 'Outlet service ref')}:{' '}
            {currentRefPrestationPrise}
          </Col>
        </Row>
        <Row style={{ overflow: 'auto' }}>
          <OrderTickets refPrestationPrise={currentRefPrestationPrise} />
        </Row>
      </StyledModalBody>
      <StyledModalCrossButton
        className="clickable"
        onClick={() => setCurrentRefPrestationPrise(null)}
      >
        <XCircle color="red" />
      </StyledModalCrossButton>
    </Modal>
  );
};

export default TicketsModal;
