import {
  Briefcase,
  ChartPie,
  Cube,
  LocationMarker,
  Matrice,
  Server,
  Stars,
  Graph,
  Eyes,
  Tag
} from '../../images/sidebar';
import { Role, SpaceServicesRole, UserRole } from '../../utils/role.types';
import { isPermitted, isProd } from '../../app/AuthService';

import BreadCrumbs from './BreadCrumbs';
import { DateManager } from '../../utils/DateManager';
import { ReactElement } from 'react';
import { clearDraft } from '@features/order/reducer/orderSlice';
import { clearSearchType } from '@features/eligibility/eligibilitySlice';
import { env } from '../../config';
import { i18n } from '@i18n';
import { matchPath } from 'react-router-dom';

const allProfiles = [
  Role.PORTAL_ORDER_FULL_BETA,
  Role.PORTAL_ORDER_FULL, //'EFO_FAI'
  Role.PORTAL_ORDER_QUOTATION, //'M-FAI-RESTREINT'
  Role.PORTAL_ORDER_PRICE, // 'EFO_Interne'
  Role.PORTAL_ORDER_CONSULTATION, //'U-DEL' ,'EFO_Distributeur' //delegant
  Role.INTERN, // hasura
  Role.DELEGANT, // hasura
  Role.EFO_INTERN,
  Role.FAI_RESTREINT,
  Role.FAI
];

const allErdvRoles = [Role.ERDV_ADMIN, Role.ERDV_MANAGER, Role.ERDV_PROVIDER, Role.ERDV_SUPPLIER];
const authorizedRolesDelegants = [
  Role.INTERN,
  Role.DELEGANT,
  Role.PORTAL_INFOS_ALL,
  Role.PORTAL_INFOS_DSP_RESTRICTED
];
const allObservabilityRoles = [
  Role.OBSERVABILITY_ADMIN,
  Role.OBSERVABILITY_EDITOR,
  Role.OBSERVABILITY_VIEWER
];

export const BYPASS_FLAG_KEY = 'bypassFlag';

export function getRule(pathname: string): NavItemsType | undefined {
  return navItems.find((nav) => {
    if (nav.childRoute && pathname != nav.to()) {
      return nav.childRoute.find((subNav) => {
        return !!matchPath(subNav.to(), pathname);
      });
    }
    return !!matchPath(nav.to(), pathname);
  });
}

export const hasDSPRole = (roles: Role[]): boolean => isPermitted(roles, authorizedRolesDelegants);
export const hasAnyKnownRole = (roles: Role[]): boolean => isPermitted(roles, allProfiles);
export const hasFullBetaRole = (roles: Role[]): boolean =>
  isPermitted(roles, [Role.PORTAL_ORDER_FULL_BETA]);
export const hasErdvRole = (roles: Role[]): boolean => isPermitted(roles, allErdvRoles);
export const hasObservabilityRole = (roles: Role[]): boolean =>
  isPermitted(roles, allObservabilityRoles);

export const isFAIRestreint = (userRoles: Role[]): boolean =>
  isPermitted(userRoles, [Role.FAI_RESTREINT]);

export const getFullOpsRoute = (route = ''): string => {
  return env.OPS_PORTAL_URL + route + '?lang=' + i18n.language;
};

export type NavItemsType = {
  to: () => string;
  title?: () => JSX.Element | string;
  htmlBefore?: ReactElement;
  highLight?: string[];
  authorizedProfiles?: Array<Role | SpaceServicesRole>;
  reset?: CallableFunction;
  disabled?: boolean;
  hideOnProd?: boolean;
  childRoute?: NavItemsType[];
  requiredItems?: CallableFunction;
  firstChildRedirect?: boolean;
  isChild?: boolean;
  shouldNotRedirect?: boolean;
  isExternalLink?: boolean;
};

export const navItems: NavItemsType[] = [
  {
    title: () => i18n.t('component.layout.navItems.section.order', 'Commandes'),
    to: () => 'isSectionName',
    authorizedProfiles: allProfiles
  },
  {
    title: () => i18n.t('component.layout.navItems.eligibility', 'Eligibility'),
    htmlBefore: <img src={LocationMarker} alt="icon" />,
    to: () => '/eligibility',
    reset: clearSearchType,
    authorizedProfiles: allProfiles
  },
  {
    title: () => i18n.t('component.layout.navItems.order', 'Orders'),
    htmlBefore: <img src={Cube} alt="icon" />,
    to: () => '/ordersBeta',
    firstChildRedirect: true,
    highLight: ['/ordersBeta/tracking', '/ordersBeta/:id', '/ordersBeta/'],
    reset: clearDraft,
    authorizedProfiles: [Role.PORTAL_ORDER_FULL_BETA],
    childRoute: [
      {
        title: () => i18n.t('component.layout.navItems.myOrder', 'My orders'),
        to: () => '/ordersBeta/tracking',
        highLight: ['/ordersBeta/tracking', '/ordersBeta/:id', '/ordersBeta'],
        authorizedProfiles: [Role.PORTAL_ORDER_FULL_BETA],
        isChild: true
      },
      {
        title: () => i18n.t('component.layout.navItems.draft', 'My drafts'),
        to: () => '/ordersBeta/draft',
        authorizedProfiles: [Role.PORTAL_ORDER_FULL_BETA],
        isChild: true
      },
      {
        to: () => '/ordersBeta/:orderId',
        authorizedProfiles: [Role.PORTAL_ORDER_FULL_BETA],
        isChild: true
      }
    ]
  },
  {
    to: () => '/eligibility-light',
    authorizedProfiles: allProfiles
  },
  {
    htmlBefore: <img src={Briefcase} alt="icon" />,
    title: () => i18n.t('component.layout.navItems.administration', 'Administration'),
    to: () => '/erdv',
    authorizedProfiles: [Role.ERDV_ADMIN, Role.ERDV_MANAGER, Role.ERDV_SUPPLIER],
    childRoute: [
      {
        title: () => i18n.t('component.layout.navItems.calendar', 'Calendar'),
        to: () => '/erdv/calendar/list',
        authorizedProfiles: [Role.ERDV_ADMIN, Role.ERDV_MANAGER],
        isChild: true
      },
      {
        title: () => i18n.t('component.layout.navItems.geographic', 'Geographic zone'),
        to: () => '/erdv/geographic-zone/list',
        authorizedProfiles: [Role.ERDV_ADMIN, Role.ERDV_MANAGER],
        isChild: true
      },
      {
        title: () => i18n.t('component.layout.navItems.appointment', 'Appointments'),
        to: () => '/erdv/appointment/list',
        authorizedProfiles: [Role.ERDV_ADMIN, Role.ERDV_SUPPLIER],
        isChild: true
      }
    ]
  },
  {
    to: () => '/erdv/appointment/create/step1',
    authorizedProfiles: [Role.ERDV_ADMIN, Role.ERDV_SUPPLIER]
  },
  {
    to: () => '/erdv/appointment/create/step2',
    authorizedProfiles: [Role.ERDV_ADMIN, Role.ERDV_SUPPLIER]
  },
  {
    to: () => '/erdv/calendar/create',
    authorizedProfiles: [Role.ERDV_ADMIN]
  },
  {
    to: () => '/erdv/calendar/:calendarId/update',
    authorizedProfiles: [Role.ERDV_ADMIN, Role.ERDV_MANAGER]
  },
  {
    to: () => '/erdv/geographic-zone/create',
    authorizedProfiles: [Role.ERDV_ADMIN]
  },
  {
    to: () => '/erdv/geographic-zone/:zoneGeoId/update',
    authorizedProfiles: [Role.ERDV_ADMIN]
  },
  {
    to: () => '/erdv/geographic-zone/:zoneId/reference/list',
    authorizedProfiles: [Role.ERDV_ADMIN, Role.ERDV_MANAGER]
  },
  {
    htmlBefore: <img src={Matrice} alt="icon" />,
    to: () => '/escalation',
    authorizedProfiles: [
      Role.PORTAIL_INFOS_ESCALATION_EDITOR_INTERN,
      Role.PORTAIL_INFOS_ESCALATION_VIEWER
    ]
  },
  {
    htmlBefore: <img src={Matrice} alt="icon" />,
    to: () => '/escalation/*',
    authorizedProfiles: [
      Role.PORTAIL_INFOS_ESCALATION_EDITOR_INTERN,
      Role.PORTAIL_INFOS_ESCALATION_VIEWER
    ]
  },
  {
    title: () => i18n.t('component.layout.navItems.digital', 'Digital Observatory'),
    // htmlBefore: <Eyeglasses />,
    to: () => '/digital-observatory',
    hideOnProd: true,
    authorizedProfiles: [Role.PORTAL_INFOS_ALL]
  },
  {
    title: () => i18n.t('component.layout.navItems.section.operations', "Centre d'exploitation"),
    to: () => 'isSectionName',
    authorizedProfiles: [
      SpaceServicesRole.ServicesConsultation,
      SpaceServicesRole.SupportAll,
      SpaceServicesRole.SupportConsultation,
      SpaceServicesRole.ObservabilityConsultation,
      SpaceServicesRole.ApplicationSupport,
      ...authorizedRolesDelegants
    ]
  },
  {
    title: () => i18n.t('component.layout.navItems.adjourments', 'Adjournments'),
    htmlBefore: <img src={ChartPie} alt="icon" />,
    to: () => '/dashboard/ajournements',
    authorizedProfiles: authorizedRolesDelegants
  },
  {
    title: () => i18n.t('component.layout.navItems.indicator', 'Indicateurs'),

    htmlBefore: <img src={ChartPie} alt="icon" />,
    to: () => '/dashboard/indicateurs',
    authorizedProfiles: authorizedRolesDelegants
  },
  {
    title: () => i18n.t('component.layout.navItems.status', 'Site status'),
    htmlBefore: <img src={Stars} alt="icon" />,
    to: () => '/dataracco',
    authorizedProfiles: authorizedRolesDelegants
  },
  {
    title: () => i18n.t('component.layout.navItems.dashboard', 'Tableau de bord'),
    to: () => getFullOpsRoute('/'),
    htmlBefore: <img src={Graph} alt="icon" />,
    authorizedProfiles: [
      SpaceServicesRole.ServicesConsultation,
      SpaceServicesRole.SupportAll,
      SpaceServicesRole.SupportConsultation,
      SpaceServicesRole.ObservabilityConsultation,
      SpaceServicesRole.ApplicationSupport
    ],
    isExternalLink: true
  },
  {
    title: () => i18n.t('component.layout.navItems.parc', 'Parc en service'),
    to: () => getFullOpsRoute('/services'),
    htmlBefore: <img src={Server} alt="icon" />,
    authorizedProfiles: [SpaceServicesRole.ServicesConsultation],
    isExternalLink: true
  },
  {
    title: () => i18n.t('component.layout.navItems.state.service', 'Etat des Services'),
    to: () => getFullOpsRoute('/observability/weather-map'),
    shouldNotRedirect: true,
    htmlBefore: <img src={Eyes} alt="icon" />,
    childRoute: [
      {
        title: () => i18n.t('component.layout.navItems.state.furnitures', 'États des équipements'),
        to: () => getFullOpsRoute('/observability/supervision'),
        authorizedProfiles: [SpaceServicesRole.ObservabilityConsultation],
        isChild: true,
        isExternalLink: true
      },
      {
        title: () => i18n.t('component.layout.navItems.state.maps', 'Carte'),
        to: () => getFullOpsRoute('/observability/map'),
        authorizedProfiles: [SpaceServicesRole.ObservabilityConsultation],
        isChild: true,
        isExternalLink: true
      },
      {
        title: () => i18n.t('component.layout.navItems.state.meteo.trafic', 'Météo Trafic'),

        to: () => getFullOpsRoute('/observability/weatherMap/Traffic'),
        authorizedProfiles: [SpaceServicesRole.ObservabilityConsultation],
        isChild: true,
        isExternalLink: true
      },
      {
        title: () => i18n.t('component.layout.navItems.state.meteo.multi', 'Météo Multicast'),

        to: () => getFullOpsRoute('/observability/weatherMap/Multicast'),
        authorizedProfiles: [SpaceServicesRole.ObservabilityConsultation],
        isChild: true,
        isExternalLink: true
      },
      {
        title: () =>
          i18n.t('component.layout.navItems.state.meteo.percentile', 'Météo 95e Percentile'),

        to: () => getFullOpsRoute('/observability/weatherMap/Percentile'),
        authorizedProfiles: [SpaceServicesRole.ObservabilityConsultation],
        isChild: true,
        isExternalLink: true
      }
    ],
    authorizedProfiles: [
      SpaceServicesRole.ServicesConsultation,
      SpaceServicesRole.SupportAll,
      SpaceServicesRole.SupportConsultation,
      SpaceServicesRole.ObservabilityConsultation,
      SpaceServicesRole.ApplicationSupport
    ]
  },
  {
    title: () => i18n.t('component.layout.navItems.support', 'Support'),
    htmlBefore: <img src={Tag} alt="icon" />,
    shouldNotRedirect: true,
    to: () => getFullOpsRoute('/support/issues'),
    firstChildRedirect: false,
    authorizedProfiles: [SpaceServicesRole.SupportAll],
    childRoute: [
      {
        title: () => i18n.t('component.layout.navItems.signal', 'Signalements'),
        to: () => getFullOpsRoute('/support/issues'),
        authorizedProfiles: [SpaceServicesRole.SupportAll],
        isChild: true,
        isExternalLink: true
      },
      {
        title: () => i18n.t('component.layout.navItems.maintain', 'Maintenance'),

        to: () => getFullOpsRoute('/support/maintenance'),
        authorizedProfiles: [SpaceServicesRole.SupportAll],
        isChild: true,
        isExternalLink: true
      },
      {
        title: () => i18n.t('component.layout.navItems.incident', 'Incidents'),

        to: () => getFullOpsRoute('/support/incidents'),
        authorizedProfiles: [SpaceServicesRole.SupportAll],
        isChild: true,
        isExternalLink: true
      }
    ]
  },
  {
    to: () => '/legals',
    authorizedProfiles: [
      ...allProfiles,
      ...allErdvRoles,
      ...authorizedRolesDelegants,
      ...allObservabilityRoles
    ]
  }
];

export const generatePageTitle = (pathname: string, params?: any) => {
  let idParams = '';

  if (params && Object.keys(params)) {
    idParams = params[Object.keys(params)[0]];
  }

  switch (pathname) {
    case 'dataracco':
      return <BreadCrumbs title={i18n.t('component.layout.pathTitle.status', 'Site status')} />;
    case 'escalate':
      return (
        <BreadCrumbs title={i18n.t('component.layout.pathTitle.escalate', `Matrice d'escalade`)} />
      );
    case 'eligibility':
      return (
        <BreadCrumbs title={i18n.t('component.layout.pathTitle.eligibility', 'Eligibility')} />
      );
    case 'ordersBeta':
      return <BreadCrumbs title={i18n.t('component.layout.pathTitle.order', 'My orders')} />;
    case 'drafts':
      return <BreadCrumbs title={i18n.t('component.layout.pathTitle.draft', 'My drafts')} />;
    case 'ordersBetaTracking':
      return <BreadCrumbs title={i18n.t('component.layout.pathTitle.order', 'My orders')} />;
    case 'dashboard':
      return <BreadCrumbs title={i18n.t('component.layout.pathTitle.dashboard', 'Dashboard')} />;
    case 'dashboard/ajournements':
      return (
        <BreadCrumbs
          title={i18n.t('component.layout.pathTitle.running', `Running adjournments at {{date}}`, {
            date: new DateManager().format('DD/MM/YYYY')
          })}
        />
      );
    case 'erdv/appointment/create/step2':
      return (
        <BreadCrumbs
          title={i18n.t('component.layout.pathTitle.appointment', 'Make an appointment')}
        />
      );
    case 'erdv/appointment/create/step1':
      return (
        <BreadCrumbs
          title={i18n.t('component.layout.pathTitle.appointment', 'Make an appointment')}
        />
      );
    case 'erdv/calendar/list':
      return (
        <BreadCrumbs title={i18n.t('component.layout.pathTitle.calendarList', 'Calendar list')} />
      );
    case 'erdv/calendar/create':
      return (
        <BreadCrumbs
          title={i18n.t('component.layout.pathTitle.createCalendar', 'Create calendar')}
        />
      );
    case `erdv/calendar/${idParams}/update`:
      return (
        <BreadCrumbs
          title={i18n.t('component.layout.pathTitle.updateCalendar', 'Update calendar')}
        />
      );
    case 'erdv/geographic-zone/create':
      return (
        <BreadCrumbs
          title={i18n.t('component.layout.pathTitle.createZone', 'Create Geographic zone')}
        />
      );
    case 'erdv/geographic-zone/list':
      return (
        <BreadCrumbs
          title={i18n.t('component.layout.pathTitle.listZone', 'Geographic zones list')}
        />
      );
    case `erdv/geographic-zone/${idParams}/update`:
      return (
        <BreadCrumbs
          title={i18n.t('component.layout.pathTitle.updateZone', 'Update Geographic zone')}
        />
      );
    case 'erdv/appointment/list':
      return (
        <BreadCrumbs
          title={i18n.t('component.layout.pathTitle.appointmentList', 'Your appointment list')}
        />
      );
    case `digital-observatory`:
      return (
        <BreadCrumbs
          title={i18n.t('component.layout.pathTitle.observatory', 'Digital Observatory')}
        />
      );
    case `legals`:
      return <BreadCrumbs title={i18n.t('component.layout.pathTitle.policy', 'Privacy policy')} />;
    default:
      return <></>;
  }
};

const isFeatureFlagBypassed = (): boolean => {
  return localStorage.getItem(BYPASS_FLAG_KEY) === 'true';
};

export const canDisplaySideBarItem = (item: NavItemsType, profileType: UserRole) => {
  if (item.hideOnProd && isProd() && !isFeatureFlagBypassed()) {
    return false;
  }
  if (item.authorizedProfiles) {
    return item.authorizedProfiles.some((authorizedRole) => {
      return profileType.rolesNames.includes(authorizedRole as never);
    });
  }

  return true;
};

export const isPathHighlighted = (currentPath: string, highlightPaths?: string[], id?: string) => {
  if (!highlightPaths) return false;

  const basePath = currentPath.split('?')[0];

  const isHighlight = highlightPaths.some((path) => {
    let pathWithId = path;

    if (id) {
      pathWithId = path.replace(':id', id);
    }

    const pathParts = pathWithId.split('/');
    const currentParts = basePath.split('/');

    if (pathParts.length !== currentParts.length) {
      return false;
    }

    return pathParts.every((part, index) => {
      return part === currentParts[index];
    });
  });

  return isHighlight;
};
