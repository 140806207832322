import { Col, Row } from 'react-bootstrap';
import {
  ticketDetailKeyTranslations,
  ticketDetailKeysOrder,
  ticketsLignBackgroundColor
} from '../utils/siteStatusUtils';

import { ArrowReturnRight } from 'react-bootstrap-icons';
import LignBackgroundFill from '../../../components/LignBackgroundFill';
import React from 'react';
import { Ticket } from '../utils/siteStatusInterfaces';
import { useTranslation } from 'react-i18next';

const TicketDetail: React.FC<{ currentTicket: Ticket }> = ({ currentTicket }) => {
  const { t } = useTranslation();
  return (
    <Row className="d-flex g-0" style={{ position: 'relative' }}>
      <LignBackgroundFill lignColor={ticketsLignBackgroundColor} />
      <Row className="small-padding medium-padding-horizontal">
        <Col md="12" className="pt-3">
          <h5 className="d-flex">
            <ArrowReturnRight size="1.5rem" />
            <div className="small-padding-horizontal">
              {t('features.siteStatus.tickets.detail', 'Ticket detail')}
            </div>
          </h5>
        </Col>
        {ticketDetailKeysOrder.map((currentTicketKey) => {
          if (currentTicket[currentTicketKey]) {
            return (
              <Col
                md="6"
                sm="12"
                className="display-flex-row small-padding-vertical"
                key={`current-order-key-${currentTicketKey}`}
              >
                <Col sm="6" className="medium-padding-horizontal">
                  {ticketDetailKeyTranslations(currentTicketKey)}
                </Col>
                <Col sm="6">{currentTicket[currentTicketKey]}</Col>
              </Col>
            );
          }
          return null;
        })}
      </Row>
    </Row>
  );
};

export default TicketDetail;
