import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { orderListTableColumns } from './orderList/OrderListTableColumns';
import { selectSiteStatusState } from './reducer/siteStatusSlice';
import { SiteStatusRT } from './utils/siteStatusInterfaces';
import { useAppSelector } from '../../app/hooks';
import BuildingDetailsCard from './buildingDetail/BuildingDetailsCard';
import OrderList from './orderList/OrderList';
import SiteStatusAlert from './utils/SiteStatusAlert';
import TicketsModal from './tickets/TicketsModal';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';

const SiteStatus: React.FC = () => {
  const { siteStatusList, lastApiError } = useAppSelector(selectSiteStatusState);
  const [currentOrder, setCurrentOrder] = useState<SiteStatusRT | null>(null);
  const [currentRefPrestationPrise, setCurrentRefPrestationPrise] = useState<string | null>(null);
  const trackEvent = useMatomoHarmonizer();

  const { t } = useTranslation();

  const showOrderInfos = (orderId: string | null) => {
    if (orderId) {
      trackEvent({
        page: 'EtatSite',
        category: 'Commande',
        actionType: 'Clic',
        actionDetails: 'Detail_Commande'
      });
      setCurrentOrder(siteStatusList.find((order) => order.id === orderId) || null);
    } else {
      setCurrentOrder(null);
    }
  };

  const showTicketsInfos = (rpp: string | null | undefined) => {
    trackEvent({
      page: 'EtatSite',
      category: 'Ticket',
      actionType: 'Clic',
      actionDetails: 'Ticket'
    });
    setCurrentRefPrestationPrise(rpp || null);
  };

  const columns = React.useMemo(
    () =>
      orderListTableColumns(
        showOrderInfos,
        showTicketsInfos,
        currentOrder ? currentOrder.id : null
      ),
    [currentOrder, siteStatusList]
  );

  return (
    <Container className="font-size-14">
      {lastApiError && (
        <SiteStatusAlert
          variant="danger"
          title={t('errorMessages.errorHappen', 'An error happened')}
          message={lastApiError}
        />
      )}
      <BuildingDetailsCard />

      <OrderList currentOrder={currentOrder} columns={columns} />
      <TicketsModal
        currentRefPrestationPrise={currentRefPrestationPrise}
        setCurrentRefPrestationPrise={setCurrentRefPrestationPrise}
      />
    </Container>
  );
};

export default SiteStatus;
