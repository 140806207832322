import { DSPWithDepartements, DepartementInfos, Segment } from './interfaces';
import { Role, UserRole } from '../../utils/role.types';
import { getSegmentName, typesInterventionList } from './filters/delegantsFiltersInfos';

import { LatLngTuple } from 'leaflet';
import { TimeDimension } from '@cubejs-client/core';
import { isPermitted } from '../../app/AuthService';

export const parisCoordinates: LatLngTuple = [48.85662635323328, 2.3522621331352127];

export const generateDspSelectList = (dspNames: string[], profileType: UserRole) => {
  if (!profileType) {
    return [];
  } else {
    return isPermitted(profileType.rolesNames, [Role.INTERN, Role.PORTAL_INFOS_ALL])
      ? dspNames
      : dspNames.filter(
          (dspFromlist) =>
            profileType.organization &&
            profileType.organization.toLowerCase() === dspFromlist.toLowerCase()
        );
  }
};

export const generateDepartementsSelectList = (
  departementAndDSP: DSPWithDepartements[],
  currentDsp: { value: string; label: string }
) => {
  const dpts = departementAndDSP.find((d) => d.nom_new_portail === currentDsp.value);
  let optionsArray: { label: string; value: string }[] = [];
  if (dpts) {
    optionsArray = dpts.departmentsList.map((dep: DepartementInfos) => {
      return {
        value: dep.libelle_departement,
        label: `${dep.libelle_departement} (${dep.code_departement})`
      };
    });
  }
  return optionsArray.length === 1
    ? optionsArray
    : [
        {
          value: 'Tous',
          label: 'Tous'
        },
        ...optionsArray
      ];
};

const currentDate = new Date().toISOString().split('T')[0];
const oneYearbeforeToday = new Date().setFullYear(new Date().getFullYear() - 1);
const oneYearBeforeCurrentDate = new Date(oneYearbeforeToday).toISOString().split('T')[0];

export const getTimeDimensions = (schemaName: string) => {
  return [
    {
      dimension: `${schemaName}.dateCreationUnShown`,
      dateRange: [oneYearBeforeCurrentDate, currentDate]
    }
  ] as TimeDimension[];
};

const generateOcSegment = (currentOC: string[]) => {
  return currentOC
    .map((y: string) => {
      if (y !== 'Tous') {
        return getSegmentName(y);
      }
      return '';
    })
    .sort((a, b) => {
      if (a < b || b === '_others') return -1;
      if (a > b || a === '_others') return 1;
      return 0;
    })
    .join('');
};

const generateDspAndDepSegment = (
  currentDsp: DSPWithDepartements,
  currentDepartement: DepartementInfos
) => {
  const isDSP = currentDsp && currentDsp.nom_new_portail !== 'Tous';

  if (currentDepartement) {
    const newDepartementKey = currentDepartement.code_departement;
    const depIsInDSP = currentDsp.departmentsList.find(
      (dep) => dep.code_departement === newDepartementKey
    );
    if (isDSP && depIsInDSP && newDepartementKey && currentDsp.departmentsList.length > 1) {
      return `${getSegmentName(currentDsp.nom_new_portail)}_${newDepartementKey}`;
    } else {
      return getSegmentName(newDepartementKey);
    }
  } else if (isDSP) {
    return getSegmentName(currentDsp.nom_new_portail);
  }
};

const generateInterventionSegment = (currentTypeIntervention: { value: string }) => {
  if (currentTypeIntervention && currentTypeIntervention.value !== 'Tous') {
    const newTypeIntervention = typesInterventionList.find(
      (ti) => ti.name === currentTypeIntervention.value
    );
    return getSegmentName(newTypeIntervention ? newTypeIntervention.name : '');
  }
};

export const generateSegments = ({
  currentOC,
  currentDsp,
  currentDepartement,
  currentTypeIntervention
}: any) => {
  const allSegments: Segment[] = [];
  allSegments.push({ name: 'oc', key: generateOcSegment(currentOC) || '' });
  allSegments.push({
    name: 'dsp_dep',
    key: generateDspAndDepSegment(currentDsp, currentDepartement) || ''
  });
  allSegments.push({
    name: 'intervention',
    key: generateInterventionSegment(currentTypeIntervention) || ''
  });
  return allSegments.filter((segment) => segment['key'].trim() !== '');
};
