import React, { useEffect, useState } from 'react';

export const FeatureFlagContext = React.createContext({});

const FeatureFlagProvider = ({
  children,
  initialRoleNames,
  featureFlagsDictionary
}: {
  children: React.ReactNode;
  initialRoleNames: string[];
  featureFlagsDictionary: any;
}) => {
  const [contextValue, setContextValue] = useState({
    featureFlags: {},
    roles: initialRoleNames
  });

  const validateRole = (role: string): boolean => {
    // eslint-disable-next-line no-prototype-builtins
    if (featureFlagsDictionary) return featureFlagsDictionary.hasOwnProperty(role);
    else return false;
  };

  const updateContext = async (
    roles: string[],
    cb?: (_nff: Record<string, unknown>, _newrole: string[]) => void
  ) => {
    /** Get new set of permissions based on view mode */
    try {
      const newFeatureFlags = { ...featureFlagsDictionary._default };

      for (const [featureName, commonFeatureDisplayRule] of Object.entries(
        featureFlagsDictionary._default
      )) {
        let onlyFalse = true;

        // for each role we check rules for each feature
        roles.forEach((role) => {
          if (validateRole(role)) {
            if (
              // if false by default but one role is authorize update to true
              commonFeatureDisplayRule === false &&
              featureFlagsDictionary[role][featureName] === true
            ) {
              newFeatureFlags[featureName] = true;
            } else if (
              // if true by default check if all roles are false to update to false
              commonFeatureDisplayRule === true &&
              featureFlagsDictionary[role][featureName] !== false
            ) {
              onlyFalse = false;
            }
          }
        });

        // if all roles hide feature then update to false
        if (commonFeatureDisplayRule === true && onlyFalse) {
          newFeatureFlags[featureName] = false;
        }
      }
      setContextValue({ featureFlags: newFeatureFlags, roles });
      if (typeof cb === 'function') {
        cb(newFeatureFlags, roles);
      }
    } catch (error) {
      console.error('something went wrong while updating the feature flags', error);
    }
  };
  const componentDidMount = () => {
    updateContext(initialRoleNames);
  };
  useEffect(componentDidMount, []);

  return (
    <FeatureFlagContext.Provider value={{ data: contextValue, updateContext }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export { FeatureFlagProvider };
