import { FunctionComponent, ReactElement, cloneElement, useEffect, useState } from 'react';
import { clearSearchType, selectSearchInfos } from './eligibilitySlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import Button from '@designSystem/Button';
import Card from '@designSystem/Card';
import HeaderCardCustom from './HeaderCardCustom';
import SearchByAddress from './search/SearchByAddress';
import SearchByBuildingRef from './search/SearchByBuildingRef';
import SearchByCoordinates from './search/SearchByCoordinates';
import SearchByFunnel from './search/SearchByFunnel';
import SearchByOtoRef from './search/SearchByOtoRef';
import SearchBySIRET from './search/SearchBySIRET';
import { i18n } from '@i18n';
import mapFr from '../../images/map_fr.svg';
import styled from 'styled-components';
import theme from '@theme';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MapWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;

  & > div {
    height: auto;
    max-width: 50%;
    float: left;
  }
  &::after {
    content: ' ';
    height: auto;
    width: 50%;
    min-height: calc(100vh - 150px);
    display: flex;
    background: url(${mapFr});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
  }
`;

const WrapperInput = styled.div`
  width: 100%;
`;

const SubTitleCard = styled.div`
  display: flex;
  font-weight: ${theme.fonts.weight.medium};
  font-family: ${theme.fonts.primary};
  line-height: ${theme.fonts.spacing.lg};
  margin-bottom: 0.5rem;
`;

type FormOption = {
  label: string | ReactElement;
  value: string;
};

export const SelectOptions = [
  {
    name: 'funnel',
    label: i18n.t('features.eligibility.selectOptions.funnel', 'Funnel'),
    component: <SearchByFunnel />
  },
  {
    name: 'address',
    label: i18n.t('features.eligibility.selectOptions.address', 'Address'),
    component: <SearchByAddress />
  },
  {
    name: 'coordinates',
    label: i18n.t('features.eligibility.selectOptions.coordinates', 'Coordinates'),
    component: <SearchByCoordinates />
  },
  {
    name: 'siret',
    label: i18n.t('features.eligibility.selectOptions.siret', 'SIRET'),
    component: <SearchBySIRET />
  },
  {
    name: 'building',
    label: i18n.t('features.eligibility.selectOptions.building', 'Building'),
    component: <SearchByBuildingRef />
  },
  {
    name: 'initialOTO',
    label: i18n.t('features.eligibility.selectOptions.initialOTO', 'OTO'),
    component: <SearchByOtoRef />
  }
];

const SelectOptionsList: FunctionComponent<{
  nextStep: () => void;
}> = ({ nextStep }) => {
  let options = SelectOptions;
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentOption, setCurrentOption] = useState<FormOption | null>({
    label:
      options.find((item) => item.name === searchParams.get('type'))?.label || options[0].label,
    value: searchParams.get('type') || options[0].name
  });
  const searchInfos = useAppSelector(selectSearchInfos);
  const { t } = useTranslation();

  useEffect(() => {
    options = SelectOptions;
    setCurrentOption({
      label: t(`features.eligibility.selectOptions.${currentOption?.value}`),
      value: currentOption?.value || ''
    });
  }, [i18n.language]);

  useEffect(() => {
    if (currentOption) {
      if (currentOption.value === 'geoaddress') {
        return setCurrentOption({
          label: t('features.eligibility.coordinate', 'Coordinates'),
          value: 'coordinates'
        });
      }
      if (!Object.values(options).find((t) => t.name === currentOption.value)) {
        return setCurrentOption({
          label: options[0].label,
          value: options[0].name
        });
      }

      searchParams.set('type', currentOption.value);
      setSearchParams(searchParams);
    }
  }, [currentOption?.value]);

  const updateOption = (selected: FormOption | null) => {
    if (selected) {
      dispatch(clearSearchType());
      setCurrentOption({
        label: selected.label,
        value: selected.value
      });
      setSearchParams({ type: selected.value });
    }
  };

  return (
    <MapWrapper>
      <Card
        header={
          <HeaderCardCustom
            titleHeader={t('features.eligibility.choosePlace', 'Choose a place')}
            subTitleHeader={t(
              'features.eligibility.searchMode',
              'Select the search mode of your choice'
            )}
          />
        }
        footer={
          <Button
            style={{
              marginLeft: 'auto'
            }}
            disabled={!searchInfos.type}
            onClick={() => nextStep()}
            data-testid={'select-options-list_next-step'}
          >
            {t('component.button.nextStep', 'Next step')}
          </Button>
        }
      >
        <SubTitleCard>{t('features.eligibility.searchPlace', 'Search a place')}</SubTitleCard>
        <>
          {Object.values(options).map((tab, index) => {
            if (tab.name == currentOption?.value) {
              return (
                <WrapperInput key={index}>
                  {cloneElement(tab.component, {
                    selectProps: { currentOption, updateOption }
                  })}
                </WrapperInput>
              );
            }
          })}
        </>
      </Card>
    </MapWrapper>
  );
};

export default SelectOptionsList;
