import React, { ReactChild } from 'react';

import styled from 'styled-components';
import theme from '@theme';

const StyledAddressCardContentText = styled.span`
  font-weight: ${theme.fonts.weight.regular};
  font-size: ${theme.fonts.size.xxs};
  line-height: ${theme.fonts.spacing.xxs};
  word-break: break-all;

  color: ${theme.palette.grey[400]};
`;

const AddressCardContent: React.FC<{
  icon: ReactChild;
  text: string | number | ReactChild;
}> = ({ icon, text }) => {
  return (
    <div className="text-muted mb-1 d-flex justify-content-start align-items-center text-break">
      <div className="d-flex small-margin-right">{icon}</div>
      <StyledAddressCardContentText>{text}</StyledAddressCardContentText>
    </div>
  );
};

export default AddressCardContent;
