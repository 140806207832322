import { IThemeSpinner, defaultStyle } from './designStyles';

import React from 'react';
import { ThemeVariants } from '../../style/theme';
import styled from 'styled-components';

export interface SpinnerProps {
  variant?: ThemeVariants;
  spinnerSize: number;
  reverse?: boolean;
  center?: boolean;
}

const SpinnerWrapper = styled.div<IThemeSpinner>`
  ${defaultStyle()}
`;

const Spinner: React.FC<SpinnerProps> = ({
  variant = 'blue',
  spinnerSize = 1,
  reverse = false,
  center = false
}) => {
  return (
    <SpinnerWrapper
      data-testid="spinner"
      variant={variant}
      spinnerSize={spinnerSize}
      reverse={reverse}
      center={center}
    />
  );
};

export default Spinner;
