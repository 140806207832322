import LinkOrAnchor from '@designSystem/LinkOrAnchor';
import { SidebarFooterLinkWrapper } from './MainSidebar/SidebarStyles';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';
import { Light } from '../../images/sidebar';

const CustomBetaFooter = () => {
  const { t } = useTranslation();
  return (
    <SidebarFooterLinkWrapper>
      <StyledCustomBetaFooter>
        <div className="footer_wrapper">
          <div className="badge_wrapper">
            <img src={Light} alt="icon" />
          </div>
          <div className="text_wrapper">
            {t(
              'component.layout.customBetaFooter.contact',
              'If you encounter any problem, please contact :'
            )}
            <LinkOrAnchor
              onClick={(e) => {
                window.location.href = 'mailto:support.mon-espace@axione.fr';
                e.preventDefault();
              }}
              nopadding
            >
              <div className="link">support.mon-espace@axione.fr</div>
            </LinkOrAnchor>
          </div>
        </div>
      </StyledCustomBetaFooter>
    </SidebarFooterLinkWrapper>
  );
};

export default CustomBetaFooter;
const StyledCustomBetaFooter = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.65rem;
  gap: 12px;

  background: ${theme.palette.blue[50]};

  border-bottom: 1px solid ${theme.palette.grey[100]};
  border-radius: 6px;

  .footer_wrapper {
    display: flex;
    flex-direction: column;

    .badge_wrapper {
      display: flex;
      margin-bottom: 10px;
    }

    .text_wrapper {
      font-weight: ${theme.fonts.weight.medium};
      font-size: ${theme.fonts.size.xs};
      line-height: ${theme.fonts.spacing.sm};
      text-align: left;

      color: ${theme.palette.blue[700]};

      .link {
        font-weight: ${theme.fonts.weight.medium};
        font-size: ${theme.fonts.size.xs};
        line-height: ${theme.fonts.spacing.sm};
        color: ${theme.palette.blue[500]};

        text-align: left;
      }
    }
  }
`;
