import { IThemePaginationComponent } from './index';
import { css } from 'styled-components';

export const defaultStyle = () => {
  return css`
    display: flex;
    list-style: none;
    justify-content: space-between;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    margin: 0;

    background: ${(props: IThemePaginationComponent) =>
      props.backgroundHasBlur ? 'rgba(240, 240, 240, 0.6)' : 'white'};
    backdrop-filter: ${(props: IThemePaginationComponent) =>
      props.backgroundHasBlur && 'blur(2.5px)'};
    position: ${(props: IThemePaginationComponent) => props.isSticky && 'sticky'};
    bottom: ${(props: IThemePaginationComponent) => props.isSticky && '30px'};
    margin-top: ${(props: IThemePaginationComponent) => props.isSticky && '48px'};

    .prev {
      width: 30px;
      height: 30px;
      align-self: flex-start;
    }

    .button-direction {
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      background-color: ${(props: IThemePaginationComponent) => props.theme.palette['blue'][100]};
      &:hover {
        background-color: ${(props: IThemePaginationComponent) => props.theme.palette['blue'][200]};
      }
    }
    Button {
      height: 30px;
      width: 30px;
    }
    .button-prev {
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.16578 0.234315C5.4782 0.546734 5.4782 1.05327 5.16578 1.36569L2.53147 4L5.16578 6.63431C5.4782 6.94673 5.4782 7.45327 5.16578 7.76569C4.85336 8.07811 4.34683 8.07811 4.03441 7.76569L0.834412 4.56569C0.521993 4.25327 0.521993 3.74673 0.834412 3.43431L4.03441 0.234315C4.34683 -0.0781049 4.85336 -0.0781049 5.16578 0.234315Z' fill='%233884FF'/%3E%3C/svg%3E%0A");
    }
    .button-next {
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.834413 7.76569C0.521993 7.45327 0.521993 6.94673 0.834413 6.63432L3.46873 4L0.834412 1.36569C0.521993 1.05327 0.521993 0.546734 0.834412 0.234315C1.14683 -0.078105 1.65336 -0.078105 1.96578 0.234315L5.16578 3.43431C5.4782 3.74673 5.4782 4.25327 5.16578 4.56569L1.96578 7.76569C1.65336 8.07811 1.14683 8.07811 0.834413 7.76569Z' fill='%233884FF'/%3E%3C/svg%3E%0A");
    }

    .next {
      align-self: flex-end;
      width: 30px;
      height: 30px;
    }

    .item {
      border: none;
      margin: 0 2px;
      font-size: ${(props: IThemePaginationComponent) => props.theme.fonts.size.xs};
      background-color: transparent;
    }
    .item:hover {
      background-color: ${(props: IThemePaginationComponent) => props.theme.palette['blue'][200]};
    }
    .not_active {
      color: ${(props: IThemePaginationComponent) => props.theme.palette['grey'][400]};
    }

    .active {
      background-color: ${(props: IThemePaginationComponent) =>
        props.theme.palette[props.variant][100]};
    }
  `;
};
