import {
  StyledOrderRecapConfirmModalButtonContainer,
  StyledOrderRecapConfirmModalDescription,
  StyledOrderRecapConfirmModalSpinnerContainer,
  StyledOrderRecapConfirmModalTitle
} from './OrderRecapConfirmModalStyles';
import { resetAllSteps, selectOrderState } from '../../reducer/orderSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

import Badge from '@designSystem/Badges';
import Button from '@designSystem/Button';
import { Check } from 'react-bootstrap-icons';
import { IOrderInformationsForm } from '../../utils/orderInterfaces';
import Icon from '@designSystem/Icon';
import { OrderSteps } from '../../utils/constants';
import { clearAddress } from '../../../eligibility/eligibilitySlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OrderRecapConfirmModalSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { orderFormSteps } = useAppSelector(selectOrderState);
  const clientInformations: IOrderInformationsForm =
    orderFormSteps.steps[OrderSteps.ORDER_INFO_FORM].data;

  const resetToOrderPage = () => {
    dispatch(clearAddress());
    dispatch(resetAllSteps({}));
    navigate('/ordersBeta');
  };

  return (
    <>
      <StyledOrderRecapConfirmModalSpinnerContainer>
        <Icon variant="green" round>
          <Check />
        </Icon>
      </StyledOrderRecapConfirmModalSpinnerContainer>
      <StyledOrderRecapConfirmModalTitle>
        {t('features.order.orderRecap.orderSaved', 'Your order has been saved : ')}
      </StyledOrderRecapConfirmModalTitle>
      <StyledOrderRecapConfirmModalDescription>
        {t(
          'features.order.orderRecap.followProgress',
          ' You can follow the progress of your order at any time in the my orders section. '
        )}
        <br />
        <br />
        {t('features.order.orderRecap.orderReference', 'Order reference : ')}{' '}
        <Badge>{clientInformations?.internReference}</Badge>
      </StyledOrderRecapConfirmModalDescription>
      <StyledOrderRecapConfirmModalButtonContainer>
        <Button autoFocus style={{ width: '100%' }} onClick={resetToOrderPage}>
          {t('features.order.orderRecap.goBack', 'Go back to order')}
        </Button>
      </StyledOrderRecapConfirmModalButtonContainer>
    </>
  );
};

export default OrderRecapConfirmModalSuccess;
