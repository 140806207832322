import { Trans, useTranslation } from 'react-i18next';

import InputWrapper from '@designSystem/InputWrapper';
import { getErrorMessage } from '../../../../utils/formHelper';
import { FieldValues, FormState } from 'react-hook-form';

interface IOrderAppointmentFormInformationsProps {
  appointmentComments: string;
  register: CallableFunction;
  formState: Partial<FormState<FieldValues>>;
}

const OrderAppointmentFormInformations = ({
  appointmentComments,
  register,
  formState
}: IOrderAppointmentFormInformationsProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <InputWrapper
        inputLabel={
          <Trans
            i18nKey="features.order.orderAppointment.orderAppointmentForm.complementaryInformations"
            default="Complementary informations <optional>(optional)</optional>"
            components={{
              optional: <div className="optional" />
            }}
          />
        }
        error={getErrorMessage('appointmentComments', formState)}
      >
        <textarea
          {...register('appointmentComments', {
            maxLength: {
              value: 200,
              message: t(
                'errorMessages.tooManyCharactersEntered',
                'Maximum number of 200 characters exceeded',
                {
                  defaultValue: '{{count}}',
                  count: 200
                }
              )
            }
          })}
          id="appointmentComments"
          label="comments"
          value={appointmentComments}
          placeholder={t(
            'features.order.accessibilityInformation',
            `If you have any information regarding site accessibility: building code, intercom, opening hours...`
          )}
          aria-label="comments-input"
          style={{ minHeight: 123 }}
        />
      </InputWrapper>
    </div>
  );
};

export default OrderAppointmentFormInformations;
