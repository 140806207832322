import React, { useMemo } from 'react';
import TableHeadSort, { SortObject } from '@designSystem/Table/HeadSort';
import { useSortBy, useTable } from 'react-table';

import { CSSProperties } from 'styled-components';
import FixedSpinner from './FixedSpinner';
import Table from '@designSystem/Table';
import { ThemeProps } from '@theme';

interface TableProps {
  theme?: ThemeProps;
  style?: CSSProperties;
  className?: string;
  columns: any;
  data: any;
  isLoading?: boolean;
  currentFilterAndSort?: { search?: string; sort?: SortObject };
  sortColumn?: (_arg1?: SortObject) => void | null;
  cellRendering?: CallableFunction;
  headerRendering?: CallableFunction;
  initialState?: {
    sortBy?: any[];
  };
}

const defaultCellDisplay = (
  row: {
    getRowProps: () => { [x: string]: any; key: any };
    cells: {
      getCellProps: () => { [x: string]: any; key: any };
      render: (arg0: string) => any;
    }[];
  },
  prepareRow: (arg0: any) => void
) => {
  prepareRow(row);
  const { key: keyRow, ...restRow } = row.getRowProps();
  return (
    <tr key={keyRow} {...restRow}>
      {row.cells.map(
        (cell: {
          getCellProps: () => { [x: string]: any; key: any };
          render: (arg0: string) => any;
        }) => {
          const { key: keyCell, ...restCell } = cell.getCellProps();
          return (
            <td key={keyCell} {...restCell}>
              {cell.render('Cell')}
            </td>
          );
        }
      )}
    </tr>
  );
};

const defaultHeaderDisplay = (
  column: {
    getHeaderProps: () => { [x: string]: any; key: any };
    render: (arg0: string) => any;
  },
  sortColumn?: ((_arg1?: SortObject | undefined) => void) | null | undefined,
  currentFilterAndSort?: { search?: string; sort?: SortObject }
) => {
  const { key, ...restColumn } = column.getHeaderProps();
  if (sortColumn !== null) {
    return (
      <th key={key} {...restColumn}>
        <TableHeadSort
          column={column}
          currentSortObject={currentFilterAndSort?.sort}
          sortByApi={sortColumn}
        >
          {column.render('Header')}
        </TableHeadSort>
      </th>
    );
  }
  return (
    <th key={key} {...restColumn}>
      <TableHeadSort column={column}>{column.render('Header')}</TableHeadSort>
    </th>
  );
};

const FullTable: React.FC<TableProps> = ({
  columns,
  data,
  isLoading = false,
  currentFilterAndSort,
  sortColumn = null,
  headerRendering = defaultHeaderDisplay,
  cellRendering = defaultCellDisplay,
  initialState,
  ...props
}) => {
  const columnData = useMemo(() => columns, [columns]);
  const rowData = useMemo(() => data, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columnData,
      data: rowData,
      initialState: {
        hiddenColumns: columns.map((column: any) => {
          if (column.hide === true) return column.accessor || column.id;
        }),
        ...initialState
      }
    },
    useSortBy
  );

  return (
    <FixedSpinner loading={isLoading}>
      <Table {...props} {...getTableProps()} maxCols={headerGroups[0]?.headers.length}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key: keyGroup, ...restHeader } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={keyGroup} {...restHeader}>
                {headerGroup.headers.map((column) => {
                  return headerRendering(column, sortColumn, currentFilterAndSort);
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            return cellRendering(row, prepareRow);
          })}
        </tbody>
      </Table>
    </FixedSpinner>
  );
};

export default FullTable;
