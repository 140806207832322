import { inputDefaultStyle } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import styled from 'styled-components';

export const StyledWrapComponent = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const StyledFormControl = styled('input')`
  ${inputDefaultStyle()}
  flex: 2;
  border-radius: 6px 0 0 6px;
  width: calc(100% - 160px);
  height: 38px;
`;
