import { Controller } from 'react-hook-form';
import CustomClipboard from '../../../../components/CustomClipboard';
import InputWrapper from '@designSystem/InputWrapper';
import React from 'react';
import ReactSelect from 'react-select';
import Tooltip from '@designSystem/Tooltip';
import { customSelectStyles } from '../../../../style/customSelectStyle';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledDivRelative = styled.div`
  position: relative;
`;

const ProductVerticalitySelect: React.FC<{
  list: { value: string; label: string }[];
  selectValue: string | undefined;
  registerTitle: 'building' | 'stair' | 'floor' | 'outlet';
  label: string;
  placeholder: string;
  control: any;
  emptyOption?: { label: string; value: string };
  clipboard?: boolean;
  disabled?: boolean;
}> = ({
  control,
  list,
  selectValue,
  registerTitle,
  label,
  placeholder,
  clipboard = false,
  emptyOption,
  disabled
}) => {
  const options = emptyOption ? [emptyOption, ...list] : list;
  const { t } = useTranslation();
  return (
    <StyledDivRelative>
      {clipboard && <CustomClipboard valueToCopy={selectValue} />}
      <Controller
        control={control}
        name={registerTitle}
        render={({ field: { onChange } }) => (
          <InputWrapper
            inputLabel={
              <>
                {label}
                {registerTitle === 'outlet' && (
                  <Tooltip direction="right">
                    {t(
                      'features.eligibility.products.productVerticality.selectExisting',
                      'Please select the existing outlet reference'
                    )}
                  </Tooltip>
                )}
              </>
            }
          >
            <ReactSelect
              options={options}
              value={{
                value: selectValue || emptyOption?.value,
                label: selectValue || emptyOption?.label
              }}
              inputId={registerTitle}
              isDisabled={disabled}
              onChange={(val: any) => onChange(val.value)}
              placeholder={placeholder}
              aria-labelledby={registerTitle}
              aria-label={`${registerTitle}-input`}
              styles={customSelectStyles}
            />
          </InputWrapper>
        )}
      />
    </StyledDivRelative>
  );
};

export default ProductVerticalitySelect;
