import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { zoneGeoCreate, zoneGeoUpdate } from '../reducer/zonesGeoSlice';

import { Card } from 'react-bootstrap';
import CardHeader from '../../../components/CardHeader';
import FormFooter from '../../../components/FormFooter';
import { GeoAltFill } from 'react-bootstrap-icons';
import { IZoneGeoForm } from '../utils/interfaces';
import ZoneGeoFormInputs from './ZoneGeoFormInputs';
import { dspNameList } from '../../profiles/profileSlice';
import toast from 'react-hot-toast';
import { unwrapResult } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ZoneGeoForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { zoneGeoId } = useParams();

  const { departementAndDSP } = useSelector((state: RootStateOrAny) => state.profile);
  const { currentZoneGeo } = useSelector((state: RootStateOrAny) => state.zonesGeo);

  const dspNames = useAppSelector(dspNameList);
  const [dspSelectList, setDspSelectList] = useState<string[]>([]);

  const { register, control, watch } = useForm<IZoneGeoForm>({
    mode: 'onChange',
    defaultValues:
      currentZoneGeo !== null
        ? {
            zoneGeoName: currentZoneGeo.name,
            dsp: { label: currentZoneGeo.dsp, value: currentZoneGeo.dsp },
            contractDelay: currentZoneGeo.contract_delay,
            zoneGeoType: { label: currentZoneGeo.type, value: currentZoneGeo.type }
          }
        : {
            zoneGeoName: '',
            dsp: undefined,
            contractDelay: 0,
            zoneGeoType: undefined
          }
  });
  const zoneGeoName = watch('zoneGeoName');
  const dsp = watch('dsp');
  const contractDelay = watch('contractDelay');
  const zoneGeoType = watch('zoneGeoType');
  const { t } = useTranslation();
  useEffect(() => {
    if (departementAndDSP.length > 0 && dspSelectList.length === 0) {
      setDspSelectList(dspNames.filter((dspName: string) => dspName !== 'Tous'));
    }
  }, [departementAndDSP]);

  const saveZoneGeo = () => {
    if (dsp?.value && zoneGeoType?.value && contractDelay !== undefined) {
      const params = {
        name: zoneGeoName,
        dsp: dsp.value,
        contract_delay: contractDelay,
        type: zoneGeoType.value
      };
      if (zoneGeoId) {
        dispatch(zoneGeoUpdate({ ...params, zoneGeoId }))
          .then(unwrapResult)
          .then(() => {
            navigate('/erdv/geographic-zone/list');
          });
      } else {
        dispatch(zoneGeoCreate(params))
          .then(unwrapResult)
          .then(() => {
            navigate('/erdv/geographic-zone/list');
          });
      }
    } else {
      toast.error(t('errorMessages.valueMissing', `Some values are missing`));
    }
  };

  const makeZoneGeoFormTitle = () => {
    if (zoneGeoId) {
      return t('features.zonesGeo.zoneGeoForm.updateZone', 'Update geographic zone');
    }
    return t('features.zonesGeo.zoneGeoForm.createZone', 'Create geographic zone');
  };

  return (
    <Card>
      <CardHeader icon={<GeoAltFill />} title={makeZoneGeoFormTitle()} />
      <ZoneGeoFormInputs
        register={register}
        control={control}
        formValues={{ zoneGeoName, dsp, contractDelay, zoneGeoType }}
        dspSelectList={dspSelectList}
      />
      <FormFooter
        action={saveZoneGeo}
        actionTitle={t('component.button.save', 'Save')}
        isActionDisabled={!(zoneGeoName !== '' && dsp?.value)}
        backAction={() => {
          navigate('/erdv/geographic-zone/list');
        }}
      />
    </Card>
  );
};

export default ZoneGeoForm;
