import {
  ErrorMessage,
  StyledErrorIcon,
  StyledInputIcon,
  StyledInputWrapper,
  StyledLabel
} from './InputWrapperDesignStyles';
import React, { ReactChild } from 'react';

import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { ReactElement } from 'react';
import theme from '../../style/theme';

interface InputWrapperProps extends React.HTMLProps<HTMLElement> {
  icon?: ReactChild;
  iconSize?: string;
  children: ReactElement;
  inputLabel?: ReactElement | string;
  error?: string | null;
  classname?: string;
  optional?: string;
}

const InputWrapper: React.FC<InputWrapperProps> = ({
  inputLabel,
  icon,
  iconSize,
  children,
  error,
  classname,
  optional
}) => {
  return (
    <div className={`inputWrapper ${classname}`}>
      {inputLabel && (
        <StyledLabel htmlFor={children?.props?.id || children?.props?.inputId}>
          {inputLabel}
          {optional && (
            <div style={{ fontWeight: 400, color: 'rgb(156, 163, 175)' }}>{optional}</div>
          )}
        </StyledLabel>
      )}

      <div style={{ position: 'relative' }}>
        {icon !== undefined && (
          <StyledInputIcon
            noBackground={true}
            iconSize={iconSize || '14px'}
            variant={'grey'}
            variantDeclination={300}
            fillColor={error ? theme.palette.red[500] : undefined}
          >
            {icon}
          </StyledInputIcon>
        )}
        <StyledInputWrapper error={error} icon={icon}>
          {children}
        </StyledInputWrapper>
        {error && (
          <>
            <StyledErrorIcon
              noBackground={true}
              iconSize={'16px'}
              variantDeclination={400}
              fillColor={theme.palette.red[500]}
            >
              <ExclamationCircleFill />
            </StyledErrorIcon>
            <ErrorMessage>{error}</ErrorMessage>
          </>
        )}
      </div>
    </div>
  );
};

export default InputWrapper;
