import {
  StyledOrderLabelValue,
  StyledOrderOutletWrapper,
  StyledOrderSection
} from '../../utils/OrderStyles';

import Badge from '@designSystem/Badges';
import OrderRecapEditLink from '../components/OrderRecapEditLink';
import { OrderSections, otoStatusFinder } from '../../utils/OrderHelper';
import { StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import { isZTD } from '../../../eligibility/helper';
import { selectOrderState } from '../../reducer/orderSlice';
import { useAppSelector } from '../../../../app/hooks';
import { useTranslation } from 'react-i18next';
import { IOrderInformationsForm, Order } from '@features/order/utils/orderInterfaces';

const OrderRecapOutletSection = ({
  editable,
  orderInformation,
  order
}: {
  editable: boolean;
  orderInformation: IOrderInformationsForm;
  order: Order;
}) => {
  const { orderFormSteps } = useAppSelector(selectOrderState);
  const isProductZTD = isZTD(orderFormSteps.steps[2].data);
  const { t } = useTranslation();
  const { finalOTO } = otoStatusFinder(order);

  return (
    <StyledOrderSection>
      <div className="section-title">
        {t('features.order.orderRecap.orderRecapSections.outlet', 'Outlet')}
      </div>

      <StyledOrderOutletWrapper>
        <div className="no-gap">
          <StyledLabel className="section-label-question">
            {t(
              'features.order.orderRecap.orderRecapSections.isExisting',
              'Is there an existing outlet on site ?'
            )}
          </StyledLabel>
          <Badge variant="grey">
            {orderInformation?.existingOutlet ? t('app.yes', 'Yes') : t('app.no', 'No')}
          </Badge>
        </div>

        {orderInformation?.existingOutlet && (
          <div>
            <StyledLabel>
              {t('features.order.orderRecap.orderRecapSections.outlet', 'Outlet')}
            </StyledLabel>
            <StyledOrderLabelValue>
              {orderInformation.outlet ||
                t('errorMessages.unReferenced', 'Unknown / Not referenced')}
            </StyledOrderLabelValue>
          </div>
        )}

        {finalOTO?.reference && (
          <div className="half-width">
            <StyledLabel>
              {t('features.order.orderRecap.orderRecapSections.finalRefTaken', 'Final Outlet')}
            </StyledLabel>
            <StyledOrderLabelValue>{finalOTO.reference}</StyledOrderLabelValue>
          </div>
        )}
        {isProductZTD && orderInformation?.existingOutlet && (
          <div className="no-gap">
            <StyledLabel className="section-label-question">
              {t(
                'features.order.orderRecap.orderRecapSections.wantComplementary',
                'Do you want a complementary outlet on this housing ?'
              )}
            </StyledLabel>
            <Badge variant="grey">
              {orderInformation.complementaryOutlet ? t('app.yes', 'Yes') : t('app.no', 'No')}
            </Badge>
          </div>
        )}
      </StyledOrderOutletWrapper>
      {editable && <OrderRecapEditLink stepToGo={3} anchorKey={OrderSections.Outlet} />}
    </StyledOrderSection>
  );
};

export default OrderRecapOutletSection;
