import { useTranslation } from 'react-i18next';
import { DualPanel } from '../../../../components/DualPanel';
import { productCharacteristicFinder } from '@features/order/utils/OrderHelper';
import { Order } from '@features/order/utils/orderInterfaces';
import Tooltip from '@designSystem/Tooltip';
import styled from 'styled-components';
import theme from '@theme';
import CustomClipboard from '../../../../components/CustomClipboard';
// Hide followUp tab for next release
// import { DateManager } from '../../../../utils/DateManager';
// import InformationBlock from '@designSystem/InformationBlock';
// import { ChatLeftTextFill, ExclamationTriangleFill } from 'react-bootstrap-icons';
// import { useAppSelector } from '../../../../app/hooks';
// import { selectOrderState } from '@features/order/reducer/orderSlice';

type OrderRecapComplementaryAuthProps = {
  order: Order;
  tabState: string;
};

export const OrderRecapComplementaryAuth = ({
  order,
  tabState
}: OrderRecapComplementaryAuthProps) => {
  const { t } = useTranslation();
  const circuitId =
    productCharacteristicFinder<string>(order.productOrderItem, 'circuitId')?.value ||
    t('features.order.orderRecap.complementary.circuitId', 'Pas encore disponible');

  // Hide followUp tab for next release
  // const { order: orderStore } = useAppSelector(selectOrderState);
  // const characteristicsIntervention = orderStore?.followUp?.find((f) => f.name === 'INTERVENTION');

  return (
    <DualPanel
      left={
        <>
          <div>{t('features.orderRecap.complementary.auth', 'Authentification  ')}</div>
          <div>{t('features.orderRecap.complementary.opt', '(Option 82) :')}</div>
        </>
      }
      right={
        <div style={{ display: 'flex', gap: '10px' }}>
          <Tooltip icon={<EllipsisContainer>{circuitId}</EllipsisContainer>}>{circuitId}</Tooltip>
          <CustomClipboard valueToCopy={circuitId} customPosition="unset" />
        </div>
      }
    />
    // Hide followUp tab for next release
    // <Container>
    //   {tabState === 'visit' && (
    //     <DualPanel
    //       left={
    //         <>
    //           <div>{t('features.orderRecap.complementary.auth', 'Authentification  ')}</div>
    //           <div>{t('features.orderRecap.complementary.opt', '(Option 82) :')}</div>
    //         </>
    //       }
    //       right={
    //         <div style={{ display: 'flex', gap: '10px' }}>
    //           <Tooltip icon={<EllipsisContainer>{circuitId}</EllipsisContainer>}>
    //             {circuitId}
    //           </Tooltip>
    //           <CustomClipboard valueToCopy={circuitId} customPosition="unset" />
    //         </div>
    //       }
    //     />
    //   )}
    //   {tabState === 'connection' && (
    //     <>
    //       {characteristicsIntervention && (
    //         <>
    //           <DualPanel
    //             left="Date d'intervention (CRI)"
    //             right={new DateManager(characteristicsIntervention.date).format('DD/MM/YYYY')}
    //           />
    //           <DualPanel
    //             left="Etat de l'intervention (CRI)"
    //             right={characteristicsIntervention.state}
    //           />
    //           {characteristicsIntervention.value.isBoxInstalled !== undefined && (
    //             <DualPanel
    //               left="Box posée"
    //               right={characteristicsIntervention.value.isBoxInstalled ? 'Oui' : 'Non'}
    //             />
    //           )}

    //           {characteristicsIntervention.value.installationComment && (
    //             <InformationBlock
    //               type="custom"
    //               customIcon={<ChatLeftTextFill />}
    //               title="Commentaire"
    //             >
    //               {characteristicsIntervention.value.installationComment}
    //             </InformationBlock>
    //           )}
    //           {characteristicsIntervention.value.isOtoInstalled !== undefined && (
    //             <DualPanel
    //               left="PTO Posée (CRI)"
    //               right={characteristicsIntervention.value.isOtoInstalled ? 'Oui' : 'Non'}
    //             />
    //           )}

    //           {characteristicsIntervention.value.newIntervention !== undefined && (
    //             <DualPanel
    //               left="Nouvelle Intervention"
    //               right={characteristicsIntervention.value.newIntervention === 'O' ? 'Oui' : 'Non'}
    //             />
    //           )}
    //           {/*On attends le correctif de KARCHIKAKA pour avoir la date*/}
    //           {/*<DualPanel*/}
    //           {/*  left="Date nouvelle intervention"*/}
    //           {/*  right={new DateManager(characteristicsIntervention.value.newInterventionDate).format(*/}
    //           {/*    'DD/MM/YYYY'*/}
    //           {/*  )}*/}
    //           {/*/>*/}
    //           {characteristicsIntervention.value.failureLabel !== undefined && (
    //             <>
    //               <DualPanel
    //                 left="Cause d’echec"
    //                 right={characteristicsIntervention.value.failureReason}
    //               />
    //               <InformationBlock
    //                 type="custom"
    //                 backgroundVariant="red"
    //                 textVariant="red"
    //                 customIcon={<ExclamationTriangleFill />}
    //                 title="Libéllé d’échec"
    //               >
    //                 {characteristicsIntervention.value.failureLabel}
    //               </InformationBlock>
    //             </>
    //           )}
    //         </>
    //       )}
    //     </>
    //   )}
    // </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const EllipsisContainer = styled.div`
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: ${theme.palette.grey[700]}:;
`;
