import React, { ReactChild } from 'react';

import Button from '@designSystem/Button';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import theme from '@theme';
import { i18n } from '@i18n';

const StyledCardFooter = styled(Card.Footer)`
  width: 100%;
  background-color: ${theme.palette.grey[50]};
  padding: 12px 24px;
`;

const FormFooter: React.FC<{
  action: CallableFunction;
  actionTitle: ReactChild | string | null;
  isActionDisabled?: boolean;
  backAction: CallableFunction;
  backActionTitle?: ReactChild | string | null;
}> = ({
  action,
  isActionDisabled = false,
  actionTitle,
  backAction,
  backActionTitle = i18n.t('component.button.back', 'Back')
}) => {
  return (
    <StyledCardFooter className="d-flex justify-content-between">
      <Button design="white" onClick={() => backAction()}>
        {backActionTitle}
      </Button>
      <Button disabled={isActionDisabled} onClick={() => action()}>
        {actionTitle}
      </Button>
    </StyledCardFooter>
  );
};

export default FormFooter;
