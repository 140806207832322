import React, { ReactChild } from 'react';

import TableNoDataImage from '../images/TableNoDataImage.svg';
import styled from 'styled-components';
import theme from '@theme';

const StyledTableNoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTableNoDataMaxWidthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 278px;
`;

const StyledTableNoDataTitle = styled.div`
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.sm};
  line-height: 20px;
  text-align: center;
  color: ${theme.palette.grey[500]};
  margin-top: 10px;
`;
const StyledTableNoDataDescription = styled.div`
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.xs};
  line-height: 16px;
  text-align: center;
  color: ${theme.palette.grey[400]};
  margin-top: 10px;
`;

const TableNoData: React.FC<{
  customImage?: string;
  title?: string | null;
  description: ReactChild | string | null;
  containerStyle?: React.CSSProperties;
}> = ({ customImage = TableNoDataImage, title = '', description = '', containerStyle }) => {
  return (
    <StyledTableNoDataContainer style={containerStyle}>
      <StyledTableNoDataMaxWidthContainer>
        <img className="d-inline-block align-top" src={customImage} alt="Portail Axione" />
        <StyledTableNoDataTitle>{title}</StyledTableNoDataTitle>
        <StyledTableNoDataDescription>{description}</StyledTableNoDataDescription>
      </StyledTableNoDataMaxWidthContainer>
    </StyledTableNoDataContainer>
  );
};

export default TableNoData;
