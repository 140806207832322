import { Col, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { getSiteStatusDetail, selectSiteStatusState } from '../reducer/siteStatusSlice';
import {
  siteStatusDetailKeyTranslations,
  siteStatusDetailKeysOrder,
  siteStatusDetailLignBackgroundColor
} from '../utils/siteStatusUtils';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { ArrowReturnRight } from 'react-bootstrap-icons';
import FixedSpinner from '../../../components/FixedSpinner';
import LignBackgroundFill from '../../../components/LignBackgroundFill';
import { selectAdressInfos } from '../../eligibility/eligibilitySlice';
import { useTranslation } from 'react-i18next';

const OrderDetail: React.FC<{ currentOrderId: string }> = ({ currentOrderId }) => {
  const dispatch = useAppDispatch();
  const addressInfos = useAppSelector(selectAdressInfos);
  const { siteStatusDetail, siteStatusDetailLoading } = useAppSelector(selectSiteStatusState);

  useEffect(() => {
    if (addressInfos && addressInfos.id) {
      dispatch(getSiteStatusDetail({ orderId: currentOrderId }));
    }
  }, [addressInfos, dispatch]);
  const { t } = useTranslation();
  return (
    <FixedSpinner loading={siteStatusDetailLoading}>
      <LignBackgroundFill lignColor={siteStatusDetailLignBackgroundColor} />
      <Row className="small-padding medium-padding-horizontal">
        <Col md="12" className="pt-3">
          <h5 className="d-flex colored-primary">
            <ArrowReturnRight size="1.5rem" />
            <div className="small-padding-horizontal">
              {t('features.siteStatus.orderList.orderDetail', 'Order detail')}
            </div>
          </h5>
        </Col>
        {siteStatusDetail &&
          siteStatusDetailKeysOrder.map((currentOrderKey) => {
            if (siteStatusDetail[currentOrderKey]) {
              return (
                <Col
                  md="6"
                  sm="12"
                  className="display-flex-row small-padding-vertical"
                  key={`current-order-key-${currentOrderKey}`}
                >
                  <Col sm="6" className="medium-padding-horizontal">
                    {siteStatusDetailKeyTranslations(currentOrderKey)}
                  </Col>
                  <Col sm="6">{siteStatusDetail[currentOrderKey]}</Col>
                </Col>
              );
            }
            return null;
          })}
      </Row>
    </FixedSpinner>
  );
};

export default OrderDetail;
