import GoogleMap from 'google-maps-react-markers';
import { IDataCoordinates, IGeographicAddress } from '../interfaces';
import { getRadiusFromZoom, getZoomFromRadius } from './helper';
import { useEffect, useRef, useState } from 'react';

import Marker from './Marker';
import debounce from 'debounce';
import { env } from '../../../config';
import { trackingPages, useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';

const mapOptions = {
  fullscreenControl: false,
  streetViewControl: true,
  clickableIcons: false,
  mapTypeControlOptions: {
    position: 3
  },
  mapTypeControl: true,
  scrollwheel: true,
  styles: [
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [{ visibility: 'on' }]
    }
  ]
};

const EligGoogleMap = (props: {
  center: { lng: number; lat: number };
  markers: IGeographicAddress[];
  searchCenter?: IDataCoordinates;
  searchType?: string;
  selectedSite?: IGeographicAddress | null;
  setSelectedSite: CallableFunction;
  setCenterChanged: CallableFunction;
  pageTracking?: trackingPages;
}): JSX.Element => {
  const trackEvent = useMatomoHarmonizer();

  const mapRef = useRef<any>(null);
  const [hoverKey, setHoverKey] = useState<string>(props.selectedSite?.id || '');
  const defaultZoom = getZoomFromRadius(Number(props.searchCenter?.radius));
  const [zoom, setZoom] = useState<number | undefined>(defaultZoom);

  useEffect(() => {
    if (props.selectedSite) {
      setHoverKey(props.selectedSite.id);
    }
  }, [props.selectedSite]);

  useEffect(() => {
    if (mapRef.current && props.center) {
      mapRef.current?.setCenter(props.center);
    }
  }, [props.center]);

  useEffect(() => {
    if (mapRef.current && zoom) {
      mapRef.current?.setZoom(zoom);
    }
  }, [zoom]);

  const onGoogleApiLoaded = ({ map }: { map: any }) => {
    mapRef.current = map;
  };

  const _onDrag = debounce(async () => {
    if (props.pageTracking)
      trackEvent({
        page: props.pageTracking,
        category: 'Choix_Site',
        actionType: 'Clic',
        actionDetails: 'Carte'
      });
  }, 500);

  const _onChange = (value: any) => {
    const radius = getRadiusFromZoom(value.zoom);
    const lng = value.center[0];
    const lat = value.center[1];

    if (zoom !== value.zoom) {
      props.setCenterChanged({ lng, lat, radius: radius });
      setZoom(value.zoom);
    } else if (
      lng.toPrecision(13) != props.center.lng?.toPrecision(13) ||
      lat.toPrecision(13) != props.center.lat?.toPrecision(13)
    ) {
      props.setCenterChanged({ lng, lat, radius: radius });
      _onDrag();
    } else {
      props.setCenterChanged(null);
    }
  };

  const _onChildClick = (key: string) => {
    props.setSelectedSite(key);
    setHoverKey(key);
    if (props.pageTracking)
      trackEvent({
        page: props.pageTracking,
        category: 'Choix_Site',
        actionType: 'Clic',
        actionDetails: 'Site_Carte'
      });
  };

  return (
    <GoogleMap
      apiKey={env.GOOGLE_API_KEY || ''}
      libraries={['places']}
      options={mapOptions}
      defaultZoom={defaultZoom}
      defaultCenter={props.center}
      onChange={_onChange}
      onGoogleApiLoaded={onGoogleApiLoaded}
      onChildClick={_onChildClick}
    >
      {props.markers.map((geographicAddress: IGeographicAddress) => {
        const coords = geographicAddress.geographicLocation?.bbox;

        if (coords && coords.length === 2) {
          return (
            <Marker
              key={geographicAddress.id}
              lat={coords[1]}
              lng={coords[0]}
              hover={hoverKey === geographicAddress.id}
              onClick={() => _onChildClick(geographicAddress.id)}
            />
          );
        }
      })}

      {props.searchCenter &&
        ['coordinates', 'geoaddress', 'address'].includes(props.searchType || '') && (
          <Marker
            disabled
            key="center"
            lng={Number(props.searchCenter?.longitude)}
            lat={Number(props.searchCenter?.latitude)}
          />
        )}
    </GoogleMap>
  );
};

export default EligGoogleMap;
