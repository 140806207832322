import * as Sentry from '@sentry/browser';

import { BrowserOptions } from '@sentry/browser';
import { CaptureContext } from '@sentry/types';
import { env } from '../config';

interface Monitoring {
  init(options?: BrowserOptions): void;
  captureException(exception: unknown, captureContext?: CaptureContext): string;
}

export class ErrorMonitoring implements Monitoring {
  private errorMonitoring = Sentry;

  init(options?: BrowserOptions) {
    const defaultOptions: BrowserOptions = {
      dsn: `https://${env.SENTRY_KEY_FRONT}@sentry.axione.fr/41`,
      integrations: [],
      environment: env.ENVIRONMENT,
      tracesSampleRate: 1.0,
      release: env.DOCKER_TAG || 'unknown-version',
      ...options
    };

    try {
      this.errorMonitoring.init(defaultOptions);
    } catch (error) {
      console.error('Failed to initialize Sentry:', error);
    }
  }

  captureException(e: unknown, captureContext?: CaptureContext): string {
    const contextWithTimestamp = {
      ...captureContext,
      tags: {
        ...(captureContext as any)?.tags,
        timestamp: new Date().toISOString()
      }
    } as CaptureContext;

    try {
      return this.errorMonitoring.captureException(e, contextWithTimestamp);
    } catch (error) {
      console.error('Failed to capture exception:', error);
      return '';
    }
  }
}
