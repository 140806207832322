import { concatComments } from '@features/order/utils/OrderHelper';
import {
  IOrderInformationsOperaBusinessForm,
  ORDER_NTE_PORT_TYPE,
  OrderOptions
} from '@features/order/utils/orderInterfaces';
export class OrderInformationOperaBusinessDataBuilder {
  constructor(
    private values: IOrderInformationsOperaBusinessForm & { appointmentComments: string },
    private options: { gtrValue: string; selectedOptions?: OrderOptions }
  ) {
    this.values = values;
    this.options = options;
  }

  buildOrder() {
    return {
      ...this.buildClient(),
      ...this.buildClientCompany(),
      ...this.buildSite(),
      ...this.buildTechnicalCharacteristics(),
      ...this.buildTechnicalCharacteristicsOptional(),
      ...this.buildComplementary(),
      ...this.buildSelectedOptions()
    };
  }

  private buildClientCompany() {
    return {
      businessName: this.values.businessName,
      clientType: this.values.clientType,
      siret: this.values.siret || undefined,
      siren: this.values.siren || undefined,
      ...(this.values.siretAbsenceReason
        ? { siretAbsenceReason: this.values.siretAbsenceReason }
        : {})
    };
  }

  private buildSelectedOptions() {
    return {
      options: {
        eqt: this.options.selectedOptions?.eqt || false,
        gtr: this.options.gtrValue,
        profile: this.options.selectedOptions?.profile,
        bronze: this.options.selectedOptions?.bronze,
        optionPlus: this.options.selectedOptions?.optionPlus,
        duration: this.options.selectedOptions?.duration,
        trunks: {
          trunk: this.options.selectedOptions?.trunks?.trunk,
          ...(this.options.selectedOptions?.trunks?.safetyTrunk && {
            safetyTrunk: this.options.selectedOptions?.trunks?.safetyTrunk
          }),
          ...(this.options.selectedOptions?.trunks?.trunkOptionPlus && {
            trunkOptionPlus: this.options.selectedOptions?.trunks?.trunkOptionPlus
          })
        }
      }
    };
  }

  private buildClient() {
    return {
      firstName: this.values.firstName,
      lastName: this.values.lastName,
      email: this.values.email,
      phoneContact1: this.values.phoneContact1,
      ...(this.values.phoneContact2 ? { phoneContact2: this.values.phoneContact2 } : {})
    };
  }

  private buildSite() {
    return {
      buildingRef: this.values.buildingRef,
      address: this.values.address,
      ...(this.values.stair ? { stairs: this.values.stair } : {}),
      ...(this.values.floor ? { floor: this.values.floor } : {}),
      ...(this.values.building ? { building: this.values.building } : {}),
      existingOutlet: this.values.existingOutlet,
      ...(this.values.outlet ? { outlet: this.values.outlet } : {}),
      ...(this.values.complementaryOutlet
        ? { complementaryOutlet: this.values.complementaryOutlet }
        : {})
    };
  }

  private buildTechnicalCharacteristics() {
    return {
      vlan: this.values.vlan,
      networkInterface: this.values.networkInterface,
      spanningTreeTransparency: this.values.spanningTreeTransparency
    };
  }

  private buildTechnicalCharacteristicsOptional() {
    return {
      interfaceOptionPlus: this.values.interfaceOptionPlus,
      ...(this.values.ntePortType ? { ntePortType: this.values.ntePortType } : {}),
      ...(this.values.vlanOptionPlus ? { vlanOptionPlus: this.values.vlanOptionPlus } : {}),
      ...(this.values.ntePortType === ORDER_NTE_PORT_TYPE.MONO_PORT
        ? { cVlanOptionPlus: this.values.cVlanOptionPlus }
        : {})
    };
  }

  private buildComplementary() {
    return {
      internReference: this.values.internReference,
      projectName: this.values.projectName || '',
      ...(this.values.comments ? { comments: this.concatComments() } : {})
    };
  }

  private concatComments() {
    return concatComments(
      this.values.clientType,
      undefined,
      undefined,
      this.values?.businessName,
      this.values.comments,
      this.values.appointmentComments
    );
  }
}
