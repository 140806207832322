import React from 'react';
import Spinner from '@designSystem/Spinner';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Loading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FixedSpinner = ({ loading, children }: { loading: boolean; children?: React.ReactNode }) => {
  return (
    <Wrapper>
      {loading && (
        <Loading>
          <Spinner spinnerSize={2} />
        </Loading>
      )}
      {children}
    </Wrapper>
  );
};

export default FixedSpinner;
