import {
  IGeographicAddress,
  ProductOfferingQualification,
  ProductOfferingQualificationItem
} from '@features/eligibility/interfaces';
import {
  IOrderInformationsForm,
  IOrderInformationsOperaBusinessForm,
  Order,
  OrderOptions,
  OrderOptionsPlus,
  ProductCharacteristic
} from './orderInterfaces';
import {
  getDefinedListOfProduct,
  getFormValuesFromData,
  getProductByOfferingName,
  getProductCharacteristic
} from '@features/order/utils/OrderHelper';
import { getOrder, getProductByName } from '@features/order/reducer/orderAPI';
import { useEffect, useState } from 'react';

import { buildSearchQueryByInfos } from '@features/eligibility/select/helper';
import { getGeographicAddresses } from '@features/eligibility/eligibilityAPI';

interface OrderInformations {
  order: (Order & ProductOfferingQualificationItem) | null;
  productSelected: ProductOfferingQualification | null;
  selectedOptions: OrderOptions | null;
  orderInformation: IOrderInformationsForm | IOrderInformationsOperaBusinessForm | null;
  address: IGeographicAddress | null;
  appointmentComments?: string;
  optionPlusSelected: OrderOptionsPlus;
}
function useGetOrderInformationsByOrderId(orderId: string): OrderInformations {
  const [currentOrderPageInformation, setCurrentOrderPageInformations] =
    useState<OrderInformations>({
      order: null,
      productSelected: null,
      selectedOptions: null,
      orderInformation: null,
      address: null,
      optionPlusSelected: {
        ntePortType: '',
        interfaceOptionPlus: '',
        vlanOptionPlus: '',
        cVlanOptionPlus: ''
      }
    });

  useEffect(() => {
    async function fetchCurrentOrderPage() {
      const { data: order } = await getOrder({ id: orderId });
      const addressQueryUrl = getURLParamsAddressByOrder(order);
      const {
        data: [address]
      } = await getGeographicAddresses(addressQueryUrl);
      const product = getProductByOfferingName(order.productOrderItem, getDefinedListOfProduct());
      const { data: productSelected } = await getProductByName({
        site: address,
        name: product?.product?.productOffering?.id || ''
      });
      const { form: clientInfo, rdv } = getFormValuesFromData(order, address);
      setCurrentOrderPageInformations({
        order: order,
        productSelected: productSelected,
        selectedOptions: formatSelectedOptionsFromOrder(order) ?? null,
        optionPlusSelected: getOptionPlusSelected(order),
        orderInformation: clientInfo as
          | IOrderInformationsForm
          | IOrderInformationsOperaBusinessForm,
        address: address,
        appointmentComments: rdv.appointmentComments
      });
    }
    fetchCurrentOrderPage();
  }, [orderId]);

  return currentOrderPageInformation;
}

export default useGetOrderInformationsByOrderId;

const getOptionPlusSelected = (order: Order): OrderOptionsPlus => {
  const items = getItemOptionPlus(order);
  const interfaceOptionPlus = items?.find((item) => item.name === 'interface')?.value;
  const ntePortType = items?.find((item) => item.name === 'nte')?.value;
  const vlanOptionPlus = items?.find((item) => item.name === 'vlan')?.value;
  const cVlanOptionPlus = items?.find((item) => item.name === 'cvlan')?.value;

  return {
    ntePortType,
    interfaceOptionPlus,
    vlanOptionPlus,
    cVlanOptionPlus
  };
};

const getItemOptionPlus = (order: Order): ProductCharacteristic[] | undefined => {
  return order?.productOrderItem[0].productOrderItem?.find(
    (item) => item.product.productOffering.id === 'optionPlus'
  )?.product.productCharacteristic;
};

const getValueOfProductCharacteristicByOfferingName = (
  order: Order,
  offeringName: string
): string | undefined => {
  const product = getProductByOfferingName(order.productOrderItem, [offeringName]);
  return product ? getProductCharacteristic(product, 'value')?.value : undefined;
};

function getURLParamsAddressByOrder(order: Order) {
  const places = order.productOrderItem[0].product.place;
  const place = places?.length && places[0];
  const isIPEBuilding = place && place.id.length < 45;

  // 45 is the length of code adresse, in FTTB both code adresse and IPE IMB code are in the same field
  // but for code adresse we do not want to get eligibility by this id
  if (isIPEBuilding) {
    return buildSearchQueryByInfos(
      {
        type: 'building',
        data: {
          buildingRef: place && place.id
        }
      },
      undefined
    );
  }
  return buildSearchQueryByInfos(
    {
      type: 'funnel',
      data: {
        city: (place && place.city) || '',
        postcode: (place && place.postcode) || '',
        street: (place && place.streetName) || '',
        streetNr: (place && place.streetNr) || ''
      }
    },
    undefined
  );
}

export function formatSelectedOptionsFromOrder(order: Order): OrderOptions | null {
  const gtr = getProductByOfferingName(order.productOrderItem[0].productOrderItem, ['gtr']);
  // FIXME: Why ?
  if (!gtr) return null;
  const gtrValue = getProductCharacteristic(gtr, 'value')?.value;
  const installEqt = getProductByOfferingName(order.productOrderItem[0].productOrderItem, [
    'installEqt'
  ]);
  const installEqtValue = installEqt
    ? getProductCharacteristic(installEqt, 'value')?.value
    : undefined;
  const optionPlus = getProductByOfferingName(order.productOrderItem[0].productOrderItem, [
    'optionPlus'
  ]);

  // business access values
  const productOrderItemAcces = order?.productOrderItem[0].productOrderItem.find(
    (p) => p.product.productOffering.id == 'acces'
  );
  const trunk =
    productOrderItemAcces && getProductCharacteristic(productOrderItemAcces, 'trunk')?.value;
  const profile =
    productOrderItemAcces && getProductCharacteristic(productOrderItemAcces, 'profile')?.value;

  // business securing option
  const productOrderItemSecuring = order?.productOrderItem[0].productOrderItem.find(
    (p) => p.product.productOffering.id == 'securing'
  );
  const safetyTrunkValue =
    productOrderItemSecuring && getProductCharacteristic(productOrderItemSecuring, 'trunk')?.value;
  const safetyLevelValue =
    productOrderItemSecuring && getProductCharacteristic(productOrderItemSecuring, 'safetyLevel');

  // business optionPlus option
  const productOrderItemOptionPlus = order?.productOrderItem[0].productOrderItem.find(
    (p) => p.product.productOffering.id == 'optionPlus'
  );
  const profileOptionPlusValue =
    productOrderItemOptionPlus &&
    getProductCharacteristic(productOrderItemOptionPlus, 'profile')?.value;
  const trunkOptionPlusValue =
    productOrderItemOptionPlus &&
    getProductCharacteristic(productOrderItemOptionPlus, 'trunk')?.value;

  return {
    gtr: formatGtr(gtrValue),
    eqt: installEqtValue,
    ...(safetyLevelValue && { bronze: safetyLevelValue }),
    ...(profileOptionPlusValue && { optionPlus: profileOptionPlusValue }),
    ...(profile && { profile }),
    ...(trunk && {
      trunks: {
        trunk: trunk,
        ...(safetyTrunkValue && { safetyTrunk: safetyTrunkValue }),
        ...(trunkOptionPlusValue && { trunkOptionPlus: trunkOptionPlusValue })
      }
    })
  };
}

const formatGtr = (gtrName: string): string => {
  if (gtrName === 'SLA Plus') {
    return 'gtr_sla_plus_monthly_fee';
  }
  return 'gtr_' + gtrName + '_monthly_fee';
};
