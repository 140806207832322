import { Controller } from 'react-hook-form';
import { IOrderInformationsForm } from '../utils/orderInterfaces';
import InformationBlock from '@designSystem/InformationBlock';
import InputWrapper from '@designSystem/InputWrapper';
import ReactSelect, { CSSObjectWithLabel } from 'react-select';
import { StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import Tooltip from '@designSystem/Tooltip';
import { VerticalityContext } from '../../eligibility/VerticalityProvider';
import { isZTD } from '../../eligibility/helper';
import { selectOrderState } from '../../order/reducer/orderSlice';
import theme from '@theme';
import { useAppSelector } from '../../../app/hooks';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { buildReactSelectStyled } from '../../../utils/styled';

const OrderInformationsOutletFormOptions = ({
  orderInformationsFormValues
}: {
  orderInformationsFormValues: IOrderInformationsForm;
}) => {
  const { orderFormSteps } = useAppSelector(selectOrderState);
  const selectedProduct = orderFormSteps.steps[2].data;
  const isProductZTD = isZTD(selectedProduct);

  const { outletsList } = useContext(VerticalityContext);
  const { t } = useTranslation();
  const displayAlert = () => {
    const warningAlert = (
      <InformationBlock
        type="warning"
        title={t(
          'features.order.orderInformations.beCareful',
          'Be careful if there is an active service on the selected outlet, it will be erased when connecting.'
        )}
      />
    );

    if (orderInformationsFormValues.outlet) {
      if (isProductZTD && orderInformationsFormValues.complementaryOutlet) {
        return null;
      }
      return warningAlert;
    }
  };

  return (
    <>
      <div>
        <Controller
          name={'outlet'}
          render={({ field: { onChange } }) => (
            <InputWrapper
              inputLabel={
                <>
                  {t('features.order.orderInformations.outlet', 'Outlet')}
                  <Tooltip direction="right" max="175px">
                    {t(
                      'features.order.orderInformations.pleaseSelect',
                      'Please select the existing outlet reference'
                    )}
                  </Tooltip>
                </>
              }
            >
              <ReactSelect
                options={[
                  { label: t('errorMessages.unReferenced', `Unknown / Not referenced`), value: '' },
                  ...outletsList
                ]}
                value={{
                  value: orderInformationsFormValues.outlet || '',
                  label:
                    orderInformationsFormValues.outlet ||
                    t('errorMessages.unReferenced', `Unknown / Not referenced`)
                }}
                inputId="outlet"
                styles={{
                  control: (base: CSSObjectWithLabel) =>
                    buildReactSelectStyled(base, { borderColor: theme.palette.grey[200] })
                }}
                onChange={(val: any) => onChange(val.value)}
              />
            </InputWrapper>
          )}
        />
      </div>

      <>
        {isProductZTD && (
          <Controller
            name={'complementaryOutlet'}
            render={({ field: { onChange } }) => (
              <div className="no-gap">
                <StyledLabel className="section-label-question">
                  {t(
                    'features.order.orderInformations.complemantaryOutlet',
                    'Do you want a complementary outlet on this housing ?'
                  )}
                </StyledLabel>
                <div className="radio-container">
                  <input
                    onChange={() => onChange(true)}
                    type="radio"
                    id="complementaryOutlet_true"
                    name="complementaryOutlet"
                    value="true"
                    checked={!!orderInformationsFormValues.complementaryOutlet}
                  />
                  <label htmlFor="complementaryOutlet_true">{t('app.yes', 'Yes')}</label>
                </div>
                <div className="radio-container">
                  <input
                    onChange={() => onChange(false)}
                    type="radio"
                    id="complementaryOutlet_false"
                    name="complementaryOutlet"
                    checked={!orderInformationsFormValues.complementaryOutlet}
                  />
                  <label htmlFor="complementaryOutlet_false">{t('app.no', 'No')}</label>
                </div>
              </div>
            )}
          />
        )}
        {displayAlert()}
      </>
    </>
  );
};

export default OrderInformationsOutletFormOptions;
