import { IThemedComponent } from '../designInterfaces';
import { css } from 'styled-components';

export const defaultStyle = () => {
  return css`
    transition: 0.5s;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    flex: 0 0 calc(50% - 1rem);
    padding: 1rem 1.5rem;
    height: 100%;

    border: 1px solid ${(props) => props.theme.palette['grey'][300]};
    border-radius: 6px;

    &:hover {
      border: 1px solid ${(props: IThemedComponent) => props.theme.palette[props.variant][200]};
      background: ${(props: IThemedComponent) => props.theme.palette[props.variant][50]};
    }

    strong {
      font-family: ${(props) => props.theme.fonts.primary};
      font-weight: ${(props) => props.theme.fonts.weight.medium};
      font-size: ${(props) => props.theme.fonts.size.sm};
      line-height: ${(props) => props.theme.fonts.spacing.sm};
      color: ${(props) => props.theme.palette['grey'][900]};
      margin-bottom: 0.25rem;
    }
    p {
      font-family: ${(props) => props.theme.fonts.primary};
      font-weight: ${(props) => props.theme.fonts.weight.normal};
      font-size: ${(props) => props.theme.fonts.size.sm};
      line-height: ${(props) => props.theme.fonts.spacing.sm};
      color: ${(props) => props.theme.palette['grey'][400]};
      margin: 0px;
    }
    div {
      margin-bottom: 0.75rem;
    }
  `;
};

export const containerStyle = css`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 2rem;
  flex: 1 0 100%;
`;
