import React, { MouseEventHandler } from 'react';
import {
  StyledOrderRecapConfirmModalButtonContainer,
  StyledOrderRecapConfirmModalDescription,
  StyledOrderRecapConfirmModalSpinnerContainer,
  StyledOrderRecapConfirmModalTitle
} from './OrderRecapConfirmModalStyles';
import { changeStep, selectOrderState } from '../../reducer/orderSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

import Button from '@designSystem/Button';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import Icon from '@designSystem/Icon';
import { getOrderErrorMessageAndActionLabel } from '../../utils/OrderHelper';
import { useTranslation } from 'react-i18next';

const OrderRecapConfirmModalError = ({
  closeModal
}: {
  closeModal: MouseEventHandler<HTMLButtonElement>;
}) => {
  const { orderCreationError } = useAppSelector(selectOrderState);
  const errorsAndAction = getOrderErrorMessageAndActionLabel(orderCreationError);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <>
      <StyledOrderRecapConfirmModalSpinnerContainer>
        <Icon variant="red" round>
          <ExclamationTriangleFill />
        </Icon>
      </StyledOrderRecapConfirmModalSpinnerContainer>
      <StyledOrderRecapConfirmModalTitle>
        {t('errorMessages.orderCreationFailed', 'Order creation has failed')}
      </StyledOrderRecapConfirmModalTitle>
      <StyledOrderRecapConfirmModalDescription>
        {errorsAndAction.errors.length ? (
          errorsAndAction.errors.map((error, index) => <span key={index}>{error}</span>)
        ) : (
          <>
            {t('errorMessages.tryAgain', 'Please try again later or contact')}
            <span className="email"> support.mon-espace@axione.fr</span>
          </>
        )}
      </StyledOrderRecapConfirmModalDescription>

      <StyledOrderRecapConfirmModalButtonContainer>
        <Button
          autoFocus
          design="white"
          style={{ width: '100%' }}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (errorsAndAction.redirectToStep)
              dispatch(changeStep(errorsAndAction.redirectToStep));
            return closeModal(e);
          }}
        >
          {errorsAndAction.action}
        </Button>
      </StyledOrderRecapConfirmModalButtonContainer>
    </>
  );
};

export default OrderRecapConfirmModalError;
