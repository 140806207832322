import { IThemedButton, disabledColor } from './designHelper';

import { css } from 'styled-components';

export const defaultStyle = (colorFunction: CallableFunction) => {
  return css`
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${(props: IThemedButton) => getFinalPadding(props)};

    gap: 8px;
    border-radius: 6px;
    text-decoration: none;
    appearance: none;
    white-space: nowrap;

    cursor: ${(props: IThemedButton) => (props.disabled ? 'default' : 'pointer')};
    pointer-events: ${(props: IThemedButton) => (props.disabled ? 'none' : 'auto')};
    box-sizing: border-box;
    transition: background-color 250ms ease-out, color 250ms ease-out, border-color 250ms ease-out;
    border: 1px solid
      ${(props) =>
        props.disabled ? disabledColor(props)['border'] : colorFunction(props)['border']};

    font-size: ${(props) => props.theme.fonts.size.sm};
    font-family: ${(props) => props.theme.fonts.primary};
    font-weight: ${(props) =>
      props.size === 'normal' ? props.theme.fonts.weight.medium : props.theme.fonts.weight.normal};
    line-height: ${(props) => props.theme.fonts.spacing.sm};

    &:hover,
    &:active {
      background-color: ${(props: IThemedButton) =>
        props.disabled
          ? disabledColor(props)['backgroundColor']
          : colorFunction(props)['hoverBackgroundColor']};
    }

    &:focus,
    &:focus:not(:focus-visible) {
      background-color: ${(props: IThemedButton) =>
        !props.disabled && colorFunction(props)['backgroundColor']};
      outline: ${(props: IThemedButton) => getOutlineCrossStyle(props, colorFunction)};
    }
  `;
};

const getOutlineCrossStyle = (props: IThemedButton, colorFunction: CallableFunction) => {
  if (!props.disabled) {
    return `${colorFunction(props)['focusOutlineColor']} solid 4px`;
  }
  return '0';
};

const getFinalPadding = (props: IThemedButton) => {
  if (props.nopadding) {
    return '0px';
  }
  if (props.size === 'normal') {
    return '7px 11px';
  }
  return '1px 4px';
};
