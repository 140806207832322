import OrderAppointmentForm from './orderAppointment/OrderAppointmentForm';
import OrderAppointmentSidebar from './orderAppointment/OrderAppointmentSidebar';
import styled from 'styled-components';
import theme from '@theme';
import { useContext, useEffect } from 'react';
import { MatomoCustomContext } from '../../utils/MatomoCustomProvider';

const OrderAppointment = () => {
  const { matomoTrackPageView } = useContext(MatomoCustomContext);

  useEffect(() => matomoTrackPageView('Commande_rdv'), []);

  return (
    <>
      <StyledCardOrderInformation>
        <OrderAppointmentForm />
        <OrderAppointmentSidebar />
      </StyledCardOrderInformation>
    </>
  );
};

const StyledCardOrderInformation = styled.div`
  display: flex;
  gap: 2rem;
  align-items: start;
  max-width: 1140px;
  margin: 0 auto 2rem auto;

  .side_content {
    max-width: 400px;
    margin-bottom: 1.5rem;
    box-shadow: none;
    p {
      font-weight: ${theme.fonts.weight.medium};
      color: ${theme.palette.grey[500]};
    }
  }
`;

export default OrderAppointment;
