import { ReactChild, ReactElement } from 'react';

import { useFlagHook } from '.';

const Guard = ({
  children,
  flag,
  fallback
}: {
  children: ReactChild;
  flag: string;
  fallback?: ReactElement;
}) => {
  const { featureFlags } = useFlagHook();

  if (featureFlags && featureFlags[flag]) {
    return <>{children}</>;
  } else if (fallback) {
    return <>{fallback}</>;
  }
  return <></>;
};
export { Guard };
