import {
  IDataOTO,
  IOfferVerticalityFloors,
  IOfferVerticalityLines,
  IOfferVerticalityResult,
  IOfferVerticalityStairs
} from './interfaces';
import React, { ReactChild, useEffect, useMemo, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { selectSearchInfos } from './eligibilitySlice';
import { useAppSelector } from '../../app/hooks';

interface IVerticalityContext {
  buildingsList: { value: string; label: string }[];
  stairsList: { value: string; label: string }[];
  floorsList: { value: string; label: string }[];
  outletsList: { value: string; label: string }[];
}

const formatListForSelect = (list: string[]) => list.map((item) => ({ value: item, label: item }));

const VerticalityContext = React.createContext<IVerticalityContext>({
  buildingsList: [],
  stairsList: [],
  floorsList: [],
  outletsList: []
});

function VerticalityProvider({
  setValue,
  getValues,
  children
}: {
  setValue: CallableFunction;
  getValues: CallableFunction;
  children: ReactChild[] | ReactChild;
}) {
  const { verticalityInfos } = useSelector((state: RootStateOrAny) => state.eligibility);
  const [buildingsList, setBuildingsList] = useState<string[]>([]);
  const [stairsList, setStairsList] = useState<string[]>([]);
  const [floorsList, setFloorsList] = useState<string[]>([]);
  const [outletsList, setOutletsList] = useState<string[]>([]);

  const { building, stair, floor } = getValues();
  const searchInfos = useAppSelector(selectSearchInfos);

  useEffect(() => {
    if (verticalityInfos) {
      setBuildingsList(verticalityInfos.map((v: IOfferVerticalityResult) => v.name));
      if (verticalityInfos.length === 1) {
        setValue('building', verticalityInfos[0].name);
      }
    }
  }, [verticalityInfos]);

  useEffect(() => {
    if (searchInfos.type === 'initialOTO' && verticalityInfos) {
      const searchedOutlet = (searchInfos.data as IDataOTO).otoRef;

      const flattenOutletsInfos = verticalityInfos.flatMap((b: IOfferVerticalityResult) => {
        return b.stairs.flatMap((s: IOfferVerticalityStairs) => {
          return s.floors.flatMap((f: IOfferVerticalityFloors) => {
            return f.lines.flatMap((l: IOfferVerticalityLines) => {
              return {
                b: b.name,
                s: s.name,
                f: f.name,
                outlet: l.otoReference
              };
            });
          });
        });
      });

      const SearchedOTOInfos = flattenOutletsInfos.find(
        (e: { outlet: string }) => e.outlet === searchedOutlet
      );

      if (SearchedOTOInfos) {
        const { b, s, f } = SearchedOTOInfos;
        setValue('building', b);
        setValue('stair', s);
        setValue('floor', f);
      }
    }
  }, [searchInfos, verticalityInfos]);

  useEffect(() => {
    if (verticalityInfos) {
      if (building) {
        const newStairsList = verticalityInfos
          .find((b: IOfferVerticalityResult) => b.name === building)
          ?.stairs.map((s: IOfferVerticalityStairs) => s.name);
        if (newStairsList) {
          setStairsList(newStairsList);
          if (newStairsList.length === 1) {
            setValue('stair', newStairsList[0]);
          }
        }
      }
    }
  }, [building, verticalityInfos]);

  useEffect(() => {
    if (verticalityInfos) {
      if (stair) {
        const newFloorsList = verticalityInfos
          .find((b: IOfferVerticalityResult) => b.name === building)
          ?.stairs.find((s: IOfferVerticalityStairs) => s.name === stair)
          ?.floors.map((f: IOfferVerticalityStairs) => f.name);
        if (newFloorsList) {
          setFloorsList(newFloorsList);
          if (newFloorsList.length === 1) {
            setValue('floor', newFloorsList[0]);
          }
        }
      }
    }
  }, [stair, verticalityInfos]);

  useEffect(() => {
    if (verticalityInfos) {
      if (floor) {
        const newOutletsList = verticalityInfos
          .find((b: IOfferVerticalityResult) => b.name === building)
          ?.stairs.find((s: IOfferVerticalityStairs) => s.name === stair)
          ?.floors.find((f: IOfferVerticalityStairs) => f.name === floor)
          ?.lines.map((l: IOfferVerticalityLines) => l.otoReference);

        if (newOutletsList) {
          setOutletsList(newOutletsList);
        }
      }
    }
  }, [floor, verticalityInfos]);

  const verticalityValues = useMemo(() => {
    return {
      buildingsList: formatListForSelect(buildingsList),
      stairsList: formatListForSelect(stairsList),
      floorsList: formatListForSelect(floorsList),
      outletsList: formatListForSelect(outletsList)
    };
  }, [buildingsList, stairsList, floorsList, outletsList]);

  return (
    <VerticalityContext.Provider value={verticalityValues}>{children}</VerticalityContext.Provider>
  );
}

export { VerticalityContext, VerticalityProvider };
