import { Building, GeoAltFill, HouseDoorFill } from 'react-bootstrap-icons';
import { IGeographicAddress, IOrganization } from '../interfaces';
import { Trans, useTranslation } from 'react-i18next';
import { trackingPages, useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';

import AddressCardContent from './AddressCardContent';
import Button from '@designSystem/Button';
import Card from '@designSystem/Card';
import Modal from '@designSystem/Modal';
import React from 'react';
import Tooltip from '@designSystem/Tooltip';
import styled from 'styled-components';
import theme from '@theme';
import { getBuildingIdFromAddress } from '@features/eligibility/helper';

const StyledAddressesCardTitle = styled.div`
  font-size: ${theme.fonts.size.xs};
  font-weight: ${theme.fonts.weight.medium};
`;

const AddressesCard: React.FC<{
  address: IGeographicAddress;
  validate: () => void;
  selected?: boolean;
  trackingPages?: trackingPages;
}> = ({ address, validate, selected = false, trackingPages }) => {
  const { t } = useTranslation();
  const [showModalWithSiret, setShowModalWithSiret] = React.useState(false);

  const trackEvent = useMatomoHarmonizer();

  const MAXIMUM_NBR_OF_SIRETS_TO_DISPLAY_WITHOUT_SCROLLBAR = 3;
  const siretsWithScrollBar =
    address?.organizations &&
    address.organizations?.length > MAXIMUM_NBR_OF_SIRETS_TO_DISPLAY_WITHOUT_SCROLLBAR;

  const handleModalWithSiret = () => {
    setShowModalWithSiret(true);
    trackEvent({
      page: trackingPages ?? 'Eligibilité',
      category: 'Choix_Site',
      actionType: 'Clic',
      actionDetails: 'Informations_SIRET'
    });
  };

  const handleOnShowTooltip = () => {
    trackEvent({
      page: trackingPages ?? 'Eligibilité',
      category: 'Choix_Site',
      actionType: 'Survol',
      actionDetails: 'Informations_SIRET'
    });
  };

  return (
    <>
      <Modal show={showModalWithSiret && !!address?.organizations?.length} width="615px">
        <PopOverStyledSirets>
          <div className="header">
            {t('features.eligibility.select.companyList', 'List of compagnies at this address')}
          </div>
          <div className="column-titles" style={siretsWithScrollBar ? { marginRight: 10 } : {}}>
            {t('features.eligibility.select.company', 'Compagnies', {
              defaultValue: '{{count}} Companies',
              count: address?.organizations?.length
            })}
            <div>SIRET</div>
          </div>
          <div
            className="company-info"
            style={siretsWithScrollBar ? { overflow: 'auto', paddingRight: 5 } : {}}
          >
            {address?.organizations?.map((organization: IOrganization) => {
              return (
                <CompanyInfoCardStyled key={organization.id}>
                  <div className="company-name">{organization.name}</div>
                  <div className="company-siret">{organization.siret}</div>
                </CompanyInfoCardStyled>
              );
            })}
          </div>
          <Button
            style={{ width: '100%', background: '#E7F0FF', color: '#3884FF' }}
            onClick={() => setShowModalWithSiret(false)}
          >
            {t('features.eligibility.select.close', 'Close')}
          </Button>
        </PopOverStyledSirets>
      </Modal>
      <StyledCard className="clickable" selected={selected} hoverable onClick={validate}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
          <StyledAddressesCardTitle as="h6">
            {address.streetNr}
            {address.streetNrSuffix}, {address.streetName}, {address.postcode} {address.city}
          </StyledAddressesCardTitle>

          {address?.organizations?.length ? (
            <div onClick={handleModalWithSiret}>
              <Tooltip delay={0} direction={'left'} onShow={handleOnShowTooltip}>
                <div style={{ fontSize: 12, fontWeight: 600 }}>
                  <Trans
                    i18nKey="features.eligibility.select.seeCompaniesAtTheAddress"
                    defaultValue={`See companies at the address`}
                  />
                </div>
              </Tooltip>
            </div>
          ) : null}
        </div>
        {address.name && <AddressCardContent icon={<GeoAltFill size="13" />} text={address.name} />}
        {address.id && (
          <AddressCardContent
            icon={<Building size="13" />}
            text={getBuildingIdFromAddress({
              id: address.id,
              referenceType: address.referenceType
            })}
          />
        )}
        <>
          {address.housingUnitsNr && (
            <AddressCardContent
              icon={<HouseDoorFill size="13" />}
              text={
                <>
                  {address.housingUnitsNr} {t('features.eligibility.select.housing', 'housing')}
                </>
              }
            />
          )}
        </>
      </StyledCard>
    </>
  );
};

export default AddressesCard;

const StyledCard = styled(Card)`
  > div {
    padding: 8px 12px;
  }
`;

const CompanyInfoCardStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-radius: 9px;
  border: 1px solid ${theme.palette.grey[200]};
  background: #fbfcff;

  .company-name {
    color: ${theme.palette.grey[700]};
    font-size: 14px;
    font-weight: ${theme.fonts.weight.medium};
    line-height: 20px;
  }
  .company-siret {
    color: ${theme.palette.grey[400]};
    font-size: 14px;
    font-weight: ${theme.fonts.weight.medium};
    line-height: 24px;
  }
`;

const PopOverStyledSirets = styled.div`
  display: grid;
  grid-gap: 18px;

  .header {
    display: grid;
    justify-content: center;
    text-align: center;
    color: ${theme.palette.grey[800]};
    font-size: ${theme.fonts.size.lg};
    font-weight: ${theme.fonts.weight.medium};
    line-height: 24px;
  }
  .column-titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${theme.palette.grey[400]};
    font-size: ${theme.fonts.size.sm};
    font-weight: ${theme.fonts.weight.medium};
    line-height: 20px;
  }
  .company-info {
    display: grid;
    grid-gap: 6px;
    max-height: 386px;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #e7f0ff;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #b30000;
    }
  }
`;
