import {
  IDataSearchType,
  IGeographicAddress,
  ISearchInfos
} from '@features/eligibility/interfaces';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { buildSearchQueryByInfos, checkDataConsistency } from '@features/eligibility/select/helper';
import { decodeDataFrom, encodeDataFrom } from '@features/eligibility/helper';
import {
  loadGeographicAddresses,
  selectAdressInfos,
  selectSearchInfos,
  setAddress,
  setSearchType
} from '@features/eligibility/eligibilitySlice';
import { trackingPages, useMatomoHarmonizer } from '../utils/matomoHarmonizer';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import { MatomoCustomContext } from '../utils/MatomoCustomProvider';
import ProgressStepper from '@designSystem/ProgressStepper';
import SelectOptionsList from '@features/eligibility/SelectOptionsList';
import SelectSite from '@features/eligibility/SelectSite';
import { Tab } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../utils/stringUtils';

const StepperGoogle: React.FC<{
  thirdStepText: string;
  children: ReactElement;
  filterReferenceType?: string;
  pageTracking?: trackingPages;
}> = ({ thirdStepText, children, filterReferenceType, pageTracking }) => {
  const dispatch = useAppDispatch();

  const searchInfos = useAppSelector(selectSearchInfos);
  const addressInfos = useAppSelector(selectAdressInfos);
  const { matomoTrackPageView } = useContext(MatomoCustomContext);
  const trackEvent = useMatomoHarmonizer();

  const [currentStep, setCurrentStep] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (pageTracking) {
      if (currentStep === 'search-address') {
        matomoTrackPageView(`${pageTracking}_Recherche_site`);
      } else if (currentStep === 'select-place') {
        matomoTrackPageView(`${pageTracking}_Choix_site`);
      }
    }
  }, [currentStep]);

  const tabChanged = (key: string | null) => {
    if (key) {
      //step 1
      if (key === 'search-address') {
        if (currentStep === 'select-place' && pageTracking) {
          trackEvent({ page: pageTracking, category: 'Choix_Site', actionType: 'Retour' });
        }
        dispatch(setAddress({ address: undefined })); // reset step3
        searchParams.delete('selected');
        setSearchParams(searchParams);
      } //step 2
      else if (key === 'select-place') {
        validateFirstStep();
      } //step 3
      else if (key === 'third-step') {
        validateSecondStep();
      }
      setCurrentStep(key);
    }
  };

  useEffect(() => {
    if (!searchParams.get('type')) {
      tabChanged('search-address');
    }
  }, [searchParams]);

  // On page init get to the last possible page
  useEffect(() => {
    const type = searchParams.get('type') as IDataSearchType;
    const data = decodeDataFrom(searchParams);
    const alreadySelected = searchParams.get('selected');

    const search: ISearchInfos = {
      type,
      data
    };

    if (data && type) {
      if (checkDataConsistency(type, data)) {
        dispatch(setSearchType(search));
      } else {
        tabChanged('search-address');
        return;
      }
    }

    if (alreadySelected && data && type) {
      const queryParam = buildSearchQueryByInfos(search);
      dispatch(loadGeographicAddresses(queryParam)).then((res) => {
        if (res.payload) {
          const addresses: IGeographicAddress[] = res.payload;
          const selected = addresses.find((address) => address.id === alreadySelected);
          dispatch(setAddress({ address: selected }));
        }
      });

      tabChanged('third-step');
    } else if (data && type) {
      tabChanged('select-place');
    } else {
      tabChanged('search-address');
    }
  }, []);

  const validateFirstStep = () => {
    if (searchInfos.type) {
      searchParams.set('data', encodeDataFrom(searchInfos.data));
      const typeValue = searchParams.get('type');

      if (pageTracking)
        trackEvent({
          page: pageTracking,
          category: 'Recherche_Site',
          actionType: 'Valider',
          actionDetails: typeValue ? capitalizeFirstLetter(typeValue) : undefined
        });
    }
    setSearchParams(searchParams);
  };

  const validateSecondStep = () => {
    if (addressInfos) {
      searchParams.set('type', searchInfos.type);
      searchParams.set('data', encodeDataFrom(searchInfos.data));
      searchParams.set('selected', addressInfos.id);
      setSearchParams(searchParams);
      if (pageTracking)
        trackEvent({ page: pageTracking, category: 'Choix_Site', actionType: 'Valider' });
    }
  };
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          padding: '1rem 1.5rem',
          maxWidth: '1188px',
          margin: '0 auto'
        }}
      >
        <ProgressStepper
          currentStep={currentStep}
          stepList={[
            {
              key: 'search-address',
              title: t('component.stepperGoogle.search', 'Search')
            },
            {
              key: 'select-place',
              title: t('component.stepperGoogle.place', 'Place')
            },
            {
              key: 'third-step',
              title: thirdStepText
            }
          ]}
        />
      </div>
      <Tab.Container activeKey={currentStep} onSelect={tabChanged} unmountOnExit>
        <Tab.Content className="px-4">
          <Tab.Pane eventKey="search-address">
            <SelectOptionsList nextStep={() => tabChanged('select-place')} />
          </Tab.Pane>
          <Tab.Pane eventKey="select-place">
            <SelectSite
              nextStep={() => tabChanged('third-step')}
              previousStep={() => tabChanged('search-address')}
              filterReferenceType={filterReferenceType}
              pageTracking={pageTracking}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="third-step">
            {React.cloneElement(children, { previousStep: () => tabChanged('select-place') })}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default StepperGoogle;
