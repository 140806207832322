import { FormProvider, useForm } from 'react-hook-form';
import { IOrderInformationsOperaBusinessForm } from '@features/order/utils/orderInterfaces';
import {
  callPatchOrder,
  selectOptionsToOrder,
  selectOrderState,
  updateStepData
} from '@features/order/reducer/orderSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import OrderInformationsOutletForm from './orderInformations/OrderInformationsOutletForm';
import Card from '@designSystem/Card';
import { ErrorMonitoring } from '../../utils/ErrorMonitoring';
import OrderFooter from '@features/order/components/OrderFooter';
import { OrderInformationOperaBusinessDataBuilder } from '@features/order/orderInformations/operaBusiness/OrderInformationOperaBusinessDataBuilder';
import OrderInformationsClientCompanyHomeBusinessForm from '@features/order/orderInformations/company/OrderInformationsClientCompanyHomeBusinessForm';
import OrderInformationsClientForm from '@features/order/orderInformations/OrderInformationsClientForm';
import OrderInformationsComplementaryForm from '@features/order/orderInformations/OrderInformationsComplementaryForm';
import OrderInformationsHeader from '@features/order/orderInformations/OrderInformationsHeader';
import OrderInformationsSidebar from './orderInformations/orderInformationsSidebar/OrderInformationsSidebar';
import { OrderSteps } from '@features/order/utils/constants';
import TechnicalCharacteristics from '@features/order/orderInformations/operaBusiness/TechnicalCharacteristics';
import { VerticalityProvider } from '@features/eligibility/VerticalityProvider';
import { constructOrderOperaBusinessInformationsDefaultValues } from '@features/order/orderInformations/OrderInformationsHelper';
import { getDefaultGtrFromProduct } from '@features/eligibility/products/productDetail/OptionsForm';
import { listOfProductOptions } from '@features/eligibility/products/constants';
import { orderVerticality, selectAdressInfos } from '@features/eligibility/eligibilitySlice';
import styled from 'styled-components';
import theme from '@theme';
import toast from 'react-hot-toast';
import { unwrapResult } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrderInformationsOperaBusinessSiteForm from '@features/order/orderInformations/operaBusiness/OrderInformationsOperaBusinessSiteForm';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';
import { onPatchOrderError } from '@features/order/OrderPatchOrderErrorHandler';
import { ModalSpanningTreeTransparencyError } from '@features/order/orderInformations/operaBusiness/ModalSpanningTreeTransparencyError';
import useGetOrderInformationsByOrderId from './utils/orderHooks';

const OrderInformationOperaBusinessForm = ({ isZTD }: { isZTD: boolean }) => {
  const [loading, setLoading] = useState(false);
  const { orderId } = useParams();

  const { orderFormSteps, order, productToOrder, selectedOptions } =
    useAppSelector(selectOrderState);
  const { optionPlusSelected, orderInformation } = useGetOrderInformationsByOrderId(
    orderId ?? order?.id ?? ''
  );
  const dispatch = useAppDispatch();
  const addressInfos = useAppSelector(selectAdressInfos);
  const { t } = useTranslation();
  const trackEvent = useMatomoHarmonizer();
  const [spanningTreeTransparencyIsInError, setSpanningTreeTransparencyIsInError] = useState<
    boolean | undefined
  >(undefined);

  const alreadyFilledInformations = orderFormSteps.steps[OrderSteps.ORDER_INFO_FORM].data;
  const formMethods = useForm<IOrderInformationsOperaBusinessForm>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: constructOrderOperaBusinessInformationsDefaultValues(
      addressInfos,
      alreadyFilledInformations
    )
  });
  const { control, watch, getValues, setValue, trigger, setError } = formMethods;

  const watchAllFields = watch();

  const handleNextStep = async () => {
    const values = getValues();
    const spanningTreeTransparencyValue = watch('spanningTreeTransparency');

    if (selectedOptions?.bronze && spanningTreeTransparencyValue === true) {
      setSpanningTreeTransparencyIsInError(true);
    }

    const isValid = await trigger();
    const phoneRegex = /^(?!00)(\+)?\d{10,14}$/;
    const formValues = getValues();
    const isPhoneValid = phoneRegex.test(formValues.phoneContact1);

    if (!isPhoneValid) {
      setError('phoneContact1', {
        type: 'manual',
        message: t('features.order.orderInformations.invalid.form.phone1', 'Invalid phone number.')
      });
      return;
    }
    if (isValid) {
      // if (!formState.isDirty) {
      //   dispatch(
      //     updateStepData({ step: OrderSteps.ORDER_INFO_FORM, data: values, nextStep: true })
      //   );
      // } else
      if (order && order.id) {
        const appointmentInformations = orderFormSteps.steps[OrderSteps.APPOINTMENT].data;
        const gtrValue =
          (productToOrder &&
            listOfProductOptions[
              selectedOptions?.gtr || getDefaultGtrFromProduct(productToOrder)
            ]) ||
          '';
        const orderBuild = new OrderInformationOperaBusinessDataBuilder(
          {
            ...values,
            appointmentComments: appointmentInformations?.appointmentComments
          },
          {
            gtrValue,
            selectedOptions
          }
        ).buildOrder();
        dispatch(
          callPatchOrder({
            ...orderBuild,
            id: order.id,
            address: addressInfos,
            productOfferingId: productToOrder?.product.productOffering.id
          })
        )
          .then(unwrapResult)
          .then(() => {
            dispatch(
              updateStepData({
                step: OrderSteps.ORDER_INFO_FORM,
                data: getValues(),
                nextStep: true
              })
            );
          })
          .catch((error) => {
            onPatchOrderError(error, setLoading, setError, trackEvent, t);
            new ErrorMonitoring().captureException(Error('PATCH order exception'), {
              extra: error
            });
          });
      } else {
        setLoading(false);
        toast.error('An error happened');
        new ErrorMonitoring().captureException(
          Error('PATCH order exception: There is no order or order.id')
        );
      }
    }
  };

  useEffect(() => {
    if (addressInfos && addressInfos.buildingRef && addressInfos.id.length < 45) {
      dispatch(orderVerticality({ addressInfos }));
    }
  }, [addressInfos]);

  useEffect(() => {
    if (spanningTreeTransparencyIsInError === false) {
      handleNextStep();
    }
  }, [spanningTreeTransparencyIsInError]);
  return (
    <CardOrderInformation>
      <Card
        header={<OrderInformationsHeader />}
        footer={
          <OrderFooter
            handleNextStep={handleNextStep}
            previousStep={OrderSteps.SELECT_PRODUCT}
            disabledBack={!!(order && orderId)}
            loading={loading}
          />
        }
      >
        <FormProvider {...formMethods}>
          <form className="form" onSubmit={formMethods.handleSubmit(handleNextStep)}>
            <div>
              <div className="section-title">
                {t('features.order.orderInformations.endClient', 'End Client informations')}
              </div>
              <OrderInformationsClientCompanyHomeBusinessForm
                orderInformationsFormValues={watchAllFields}
                order={order}
              />
              <OrderInformationsClientForm<IOrderInformationsOperaBusinessForm>
                isHomeOrOfficeProduct={true}
                isHomeBasicProduct={false}
                isBusiness={true}
                orderInformationsFormValues={watchAllFields}
              />
            </div>

            <VerticalityProvider getValues={getValues} setValue={setValue}>
              <OrderInformationsOperaBusinessSiteForm
                orderInformationsFormValues={watchAllFields}
              />
              {isZTD ? (
                <>
                  {watchAllFields.building && watchAllFields.floor && watchAllFields.stair && (
                    <>
                      <OrderInformationsOutletForm
                        control={control}
                        orderInformationsFormValues={watchAllFields}
                      />
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </VerticalityProvider>

            <ModalSpanningTreeTransparencyError
              isOpen={!!spanningTreeTransparencyIsInError}
              goBack={() => setSpanningTreeTransparencyIsInError(undefined)}
              close={() => {
                dispatch(
                  selectOptionsToOrder({
                    ...selectedOptions,
                    bronze: false,
                    trunks: {
                      ...selectedOptions?.trunks,
                      safetyTrunk: undefined
                    }
                  })
                );
                useGetOrderInformationsByOrderId;
                setSpanningTreeTransparencyIsInError(false);
              }}
              t={t}
            />
            <TechnicalCharacteristics
              orderInformationsFormValues={watchAllFields}
              defaultOrderValue={orderInformation as IOrderInformationsOperaBusinessForm}
              defaultOptionPlusValue={optionPlusSelected}
              isZTD={isZTD}
            />
            <OrderInformationsComplementaryForm orderInformationsFormValues={watchAllFields} />
          </form>
        </FormProvider>
      </Card>
      <Card className="side_content">
        {productToOrder && (
          <OrderInformationsSidebar
            productToOrder={productToOrder}
            selectedOptions={selectedOptions}
            isBusiness={true}
          />
        )}
      </Card>
    </CardOrderInformation>
  );
};

export default OrderInformationOperaBusinessForm;

const CardOrderInformation = styled.div`
  display: flex;
  gap: 2rem;
  align-items: start;
  max-width: 1140px;
  margin: 0 auto 2rem auto;

  .section-title {
    margin-bottom: 1rem;
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};

    color: ${theme.palette.grey[500]};
  }

  .side_content {
    max-width: 400px;
    margin-bottom: 1.5rem;
    background: ${theme.palette.blue[50]};
    box-shadow: none;

    p {
      font-weight: ${theme.fonts.weight.medium};
      color: ${theme.palette.grey[500]};
    }
  }

  .form {
    display: grid;
    grid-gap: 24px;

    & > * {
      padding-bottom: 24px;
      border-bottom: 1px solid ${theme.palette.grey[200]};
    }
  }
`;
