const scrollIntoViewIfNeeded = (target: HTMLElement): void => {
  if (target.getBoundingClientRect().bottom > window.innerHeight) {
    target.scrollIntoView(false);
  }
  if (target.getBoundingClientRect().top < 0) {
    target.scrollIntoView();
  }
};

export default scrollIntoViewIfNeeded;
