import * as ProfileApiService from './profileAPI';

import { DSPWithDepartements, DepartementInfos } from '../charts/interfaces';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { UserRole } from '../../utils/role.types';

export interface ProfileState {
  profileType: UserRole | null;
  profileLoading: boolean;
  profileError: string | undefined;
  departementAndDSP: Array<DSPWithDepartements>;
  departementAndDSPLoading: boolean;
  departementAndDSPError: string | undefined;
  sideBarExpanded: boolean;
}

const initialState: ProfileState = {
  profileType: { rolesNames: [], organization: '', username: '' },
  profileLoading: true,
  profileError: undefined,
  departementAndDSP: [],
  departementAndDSPLoading: false,
  departementAndDSPError: undefined,
  sideBarExpanded: true
};

export const loadProfile = createAsyncThunk('profile/getProfile', async () => {
  const {
    data: { roles, user, organizationName, codeARCEP }
  } = await ProfileApiService.getProfile();
  return {
    rolesNames: roles || [],
    organization: organizationName,
    username: user,
    codeArcep: codeARCEP
  };
});

export const setNewApiProfile = createAsyncThunk(
  'profile/setProfile',
  async (params: UserRole, { rejectWithValue }) => {
    try {
      const {
        data: { roles, user, organizationName }
      } = await ProfileApiService.postSpecifiedProfile(params);
      return {
        rolesNames: roles || [],
        organization: organizationName,
        username: user
      };
    } catch (e: any) {
      if (e.response.data) {
        return rejectWithValue(e.response.data);
      }
      throw e;
    }
  }
);

export const getDepartementAndDSP = createAsyncThunk('profile/departementAndDSP', async () => {
  const { data } = await ProfileApiService.getDepartementAndDSP();
  const res: DSPWithDepartements[] = data.config_t_correspondance_contrat;
  const allDepartementsList: DepartementInfos[] = [];
  res.forEach((dsp) => {
    dsp.departmentsList.forEach((dep) => {
      if (!allDepartementsList.find((all) => all.code_departement === dep.code_departement)) {
        allDepartementsList.push(dep);
      }
    });
  });
  return [
    {
      nom_new_portail: 'Tous',
      departmentsList: allDepartementsList
    },
    ...res
  ];
});

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileType: (state, action) => {
      state.profileType = action.payload;
    },
    setSideBarExpanded: (state, action) => {
      state.sideBarExpanded = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProfile.pending, (state) => {
        state.profileLoading = true;
      })
      .addCase(loadProfile.fulfilled, (state, { payload }) => {
        state.profileType = payload;
        state.profileLoading = false;
      })
      .addCase(loadProfile.rejected, (state, action) => {
        state.profileLoading = false;
        state.profileError = action.error.message;
      })
      .addCase(setNewApiProfile.pending, (state) => {
        state.profileLoading = true;
      })
      .addCase(setNewApiProfile.fulfilled, (state, { payload }) => {
        state.profileType = payload;
        state.profileLoading = false;
      })
      .addCase(setNewApiProfile.rejected, (state, action) => {
        state.profileLoading = false;
        state.profileError = action.error.message;
      })
      .addCase(getDepartementAndDSP.pending, (state) => {
        state.departementAndDSPLoading = true;
      })
      .addCase(getDepartementAndDSP.fulfilled, (state, { payload }) => {
        state.departementAndDSP = payload;
        state.departementAndDSPLoading = false;
      })
      .addCase(getDepartementAndDSP.rejected, (state, action) => {
        state.departementAndDSPLoading = false;
        state.departementAndDSPError = action.error.message;
      });
  }
});

export const { setProfileType, setSideBarExpanded } = profileSlice.actions;

const selectProfileState = createSelector(
  (state: RootState) => state.profile,
  (s) => s
);

export const selectProfilePending = createSelector(selectProfileState, (s) => s.profileLoading);

export const dspNameList = createSelector(selectProfileState, (s) =>
  s.departementAndDSP.map((e) => e.nom_new_portail)
);

export const selectProfileError = createSelector(selectProfileState, (s) => s.profileError);

export const selectSideBarExpanded = createSelector(selectProfileState, (s) => s.sideBarExpanded);

export default profileSlice.reducer;
