export interface ICreateZoneGeoAPIForm {
  zoneGeoId?: number;
  name: string;
  dsp: string;
  type: string;
  contract_delay: number;
}

export interface IUpdateZoneGeoAPIForm {
  zoneGeoId: string;
  name: string;
  dsp: string;
  contract_delay: number;
  type: string;
}

export interface IZoneGeoForm {
  zoneGeoName: string;
  dsp: { value: string; label: string } | undefined;
  contractDelay: number | undefined;
  zoneGeoType: { value: string; label: string } | undefined;
}

export enum ErdvZoneGeographicTypes {
  RACCO = 'RACCO',
  SAV = 'SAV',
  PRERACC = 'PRERACC'
}

export interface IZoneGeoFromApi {
  id: number;
  name: string;
  dsp: string;
  contract_delay: number;
  code_oi_charlie: string;
  addresses_mediapost: IZoneGeoMediapostAddress[];
  site_references: IZoneGeoSiteReference[];
}

interface IZoneGeoMediapostAddress {
  geo_key: string;
}

interface IZoneGeoSiteReference {
  reference: string;
  ref_type: number;
}
