import theme from '@theme';
import { ReactNode } from 'react';
import styled from 'styled-components';

type DualPanelProps = {
  right: ReactNode;
  left: ReactNode;
};

// Petit composant préventif qui pourra aligner facilement les futures composants à display
export const DualPanel = ({ right, left }: DualPanelProps) => {
  return (
    <>
      {right ? (
        <GridContainer>
          <DefaultLeftStyle>{left}</DefaultLeftStyle>
          <DefaultRightStyle>{right}</DefaultRightStyle>
        </GridContainer>
      ) : null}
    </>
  );
};

const DefaultLeftStyle = styled.div`
  color: ${theme.palette.grey[400]};
  font-size: ${theme.fonts.size.sm};
`;
const DefaultRightStyle = styled.div`
  color: ${theme.palette.grey[700]};
  font-size: ${theme.fonts.size.sm};
`;

const GridContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 4px;
  }
`;
