import { Appointment, AppointmentSchedule } from './interfaces';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { enGB, fr } from 'date-fns/locale';
import { minDaysBeforeSlotOpen, timeSlotsNames, todayUTC } from './helper';

import DatePickerSchedule from './datePickerSchedule';
import { DayPicker } from 'react-day-picker';
import FixedSpinner from '../../../components/FixedSpinner';
import { appointmentDatePickerStyle } from './style';
import { getCalendarSchedules } from '../reducer/appointmentSlice';
import { useAppDispatch } from '../../../app/hooks';
import { i18n } from '@i18n';
import { DateManager } from '../../../utils/DateManager';
const DatePicker: React.FC<{
  loading?: boolean;
  slots: Appointment[];
  updateTimeSlots: (_m: string) => void;
  chooseTimeSlot: (_s?: Appointment) => void;
}> = ({ loading, slots, updateTimeSlots, chooseTimeSlot }) => {
  const dispatch = useAppDispatch();

  const { calendar, calendarSchedules, calendarSchedulesLoading } = useSelector(
    (state: RootStateOrAny) => state.appointment
  );

  const [allSlots, setAllSlots] = useState<Appointment[]>([]);

  const [selectedDay, setSelectedDay] = useState<Date>();
  const [selectedTime, setSelectedTime] = useState<number>();

  const [validSlotsDays, setValidSlotsDays] = useState<string[]>([]);
  const [validSlotsTimes, setValidSlotsTimes] = useState<number[]>([1, 2, 3]);

  const [month, setMonth] = useState<Date>(todayUTC);
  const [alreadyFetchedMonths, setFetched] = useState<string[]>([]);
  const [enableSchedule, setEnableSchedule] = useState(false);

  const updateFetchedMonths = (m: string) => {
    if (!alreadyFetchedMonths.includes(m)) {
      const fetchedMonths = [...alreadyFetchedMonths, m];

      setFetched(fetchedMonths);
      updateTimeSlots(m);
    }
  };

  const isInSlotsDays = (day: string) => validSlotsDays.includes(day);

  const isDayDisabled = (day: Date) => {
    return (
      day < minDaysBeforeSlotOpen(calendar.min_days_book_before_appointment) ||
      !isInSlotsDays(new DateManager(day).format('YYYY-MM-DD'))
    );
  };

  const toggleSelectedTime = (time: number) => {
    if (time === selectedTime) {
      setSelectedTime(undefined);
    } else {
      setSelectedTime(time);
    }
  };

  const allDays = allSlots.map((s) => s.day);

  useEffect(() => {
    //init with current month
    updateFetchedMonths(new DateManager().startOf('month').format('YYYY-MM-DD'));
  }, []);

  useEffect(() => {
    setAllSlots([...allSlots, ...slots]);
  }, [slots]);

  // We reset all slots in case we change the calendar ID
  useEffect(() => {
    setAllSlots([]);
  }, [calendar]);

  useEffect(() => {
    // fetch calendar specifics schedules
    if (calendarSchedules.length === 0 && !calendarSchedulesLoading && calendar.id) {
      dispatch(getCalendarSchedules({ idCalendar: calendar.id }));
    }
  }, [calendarSchedules, calendar]);

  useEffect(() => {
    // on select day update possible times
    if (selectedDay) {
      const newValidTimes = allSlots
        .filter((s) => s.day === new DateManager(selectedDay).format('YYYY-MM-DD'))
        .map((s) => s.schedule.type);
      setValidSlotsTimes(newValidTimes);
    } else {
      setValidSlotsTimes(calendarSchedules.map((cs: AppointmentSchedule) => cs.type));
    }
  }, [selectedDay, allSlots]);

  useEffect(() => {
    // on select time update possible days
    if (selectedTime) {
      const newvalidDays = allSlots
        .filter((s) => s.schedule.type === selectedTime)
        .map((s) => s.day);
      setValidSlotsDays(newvalidDays);
    } else {
      setValidSlotsDays(allDays);
    }
  }, [selectedTime, allSlots]);

  useEffect(() => {
    // on select time/day change update choosed slot
    if (selectedDay && selectedTime) {
      const chossedSlot = allSlots.find(
        (s) =>
          s.day === new DateManager(selectedDay).format('YYYY-MM-DD') &&
          s.schedule.type === selectedTime
      );
      chooseTimeSlot(chossedSlot);
    } else {
      chooseTimeSlot(undefined);
    }
  }, [selectedDay, selectedTime]);

  useEffect(() => {
    const currentMonth = month.getMonth();
    const hasSlotInCurrentMonth = allSlots.find(
      (slot) => currentMonth === new Date(slot.day).getMonth()
    );
    setEnableSchedule(!!hasSlotInCurrentMonth);
  }, [month, allSlots]);

  return (
    <>
      <style>{appointmentDatePickerStyle}</style>
      <div className="d-flex align-items-center">
        <div>
          <FixedSpinner loading={!!loading}>
            <DayPicker
              fixedWeeks
              mode="single"
              selected={selectedDay}
              onSelect={(d: Date | undefined) => {
                setSelectedDay(d || undefined);
              }}
              modifiersClassNames={{
                selected: 'day-selected',
                today: 'day-today'
              }}
              month={month}
              fromMonth={todayUTC}
              onMonthChange={(m: Date) => {
                updateFetchedMonths(new DateManager(m).format('YYYY-MM-DD'));
                setMonth(m);
              }}
              modifiersStyles={{
                disabled: { fontSize: '75%' }
              }}
              locale={i18n.language === 'fr' ? fr : enGB}
              numberOfMonths={1}
              disabled={isDayDisabled}
            />
          </FixedSpinner>
        </div>
        <div className="d-flex flex-column w-100" style={{ maxWidth: '160px' }}>
          {validSlotsTimes &&
            calendarSchedules.map((time: AppointmentSchedule) => {
              return (
                <DatePickerSchedule
                  key={`${time.type}`}
                  design={selectedTime === time.type ? 'plain' : 'reverse'}
                  disabled={!validSlotsTimes.includes(time.type) || !enableSchedule}
                  onClick={() => toggleSelectedTime(time.type)}
                  message={timeSlotsNames[time.type]}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default DatePicker;
