import { selectOrderState } from '../reducer/orderSlice';
import { useAppSelector } from '../../../app/hooks';
import useGetOrderInformationsByOrderId from '../utils/orderHooks';
import { useParams } from 'react-router-dom';
// import { HeaderSection } from './complementary/HeaderSection';
import { OrderRecapComplementaryAuth } from './complementary/OrderRecapComplementaryAuth';
import { LoaderIcon } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { ITab } from 'src/components/PageHeader/tabHeader/TabHeader';
import { useTranslation } from 'react-i18next';

const OrderRecapComplementary = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const { order: orderStore } = useAppSelector(selectOrderState);
  const { order } = useGetOrderInformationsByOrderId(orderId || orderStore?.id || '');
  const hasConnectionTab = orderStore!.followUp?.find((f) => f.name === 'INTERVENTION');

  const defaultTab = {
    name: t(
      'order.orderRecap.orderReacapComplementary.additional.info.visit',
      'Informations techniques'
    ),
    state: 'visit',
    i18nKey: 'order.orderRecap.orderReacapComplementary.additional.info.visit'
  };

  const [tabs, setTabs] = useState<ITab[]>([defaultTab]);

  useEffect(() => {
    if (hasConnectionTab) {
      setTabs([
        defaultTab,
        {
          name: t(
            'order.orderRecap.orderReacapComplementary.additional.info.connection',
            'Raccordement'
          ),
          state: 'connection',
          i18nKey: 'order.orderRecap.orderReacapComplementary.additional.info.connection'
        }
      ]);
    }
  }, [hasConnectionTab]);

  const [tabSelected, setTabSelected] = useState<ITab>(tabs[0]);
  if (!order)
    return (
      <div
        style={{
          minHeight: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <LoaderIcon />
      </div>
    );

  return (
    <div style={{ width: '350px' }}>
      {/* <HeaderSection tabs={tabs} setTabSelected={setTabSelected} tabSelected={tabSelected} /> */}
      <OrderRecapComplementaryAuth order={order} tabState={tabSelected.state!} />
    </div>
  );
};

export default OrderRecapComplementary;
