import { typesInterventionList } from '../filters/delegantsFiltersInfos';
import { DateManager } from '../../../utils/DateManager';

const primarycolor = '#0180A5';
const secondarycolor = '#E45419';
const greyColor = '#6E6E6E';
const darkGreyColor = '#333';
const redColor = '#D0021B';

export const options = {
  nombreAjournementEnCours: {
    categoryAxisIsX: true,
    seriesOption: [
      {
        name: 'Ajournements',
        key: 'NombreAjournementEnCours.EnCours',
        type: 'bar',
        label: {
          show: true
        },
        color: primarycolor
      }
    ],
    plotOptions: {
      title: {
        text: "Nombre d'ajournements en cours par département"
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        }
      },
      grid: {},
      yAxis: {
        type: 'log'
      },
      xAxis: {
        type: 'category',
        axisTick: {
          length: 6,
          lineStyle: {
            type: 'dashed'
            // ...
          }
        },
        axisLabel: {
          interval: 0,
          margin: 8
          // rotate: 30 //If the label names are too long you can manage this by rotating the label.
        }
      },
      toolbox: {
        feature: {
          saveAsImage: {
            title: 'Télécharger            '
          }
        }
      }
    }
  },

  ticketEvolution: {
    categoryAxisIsX: true,
    seriesOption: [
      {
        name: 'Ajournements créés',
        key: 'TicketEvolution.Crees',
        type: 'bar',
        color: secondarycolor
      },
      {
        name: 'Ajournements clôturés',
        key: 'TicketEvolution.Clotures',
        type: 'bar',
        color: greyColor
      },
      {
        name: 'Backlog',
        key: 'TicketEvolution.Backlog_date_ttr',
        type: 'line',
        color: primarycolor,
        yAxisIndex: 1
      }
    ],
    plotOptions: {
      title: {
        text: 'Flux mensuels des ajournements'
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        top: '10%'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {
            title: 'Télécharger            '
          }
        }
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          label: {},
          formatter: function (value: Date) {
            const date = new Date(value);
            return new DateManager(date).format('MMMM YYYY');
          }
        }
      },
      yAxis: [
        {
          type: 'value'
        },
        {
          type: 'value',
          axisLabel: {
            color: primarycolor
          }
        }
      ]
    }
  },

  tauxBrutparOC: {
    categoryAxisIsX: true,
    seriesOption: [
      {
        key: 'BYTEL,TTauxBrutEspaceDelegant.tauxBrut',
        name: 'BYTEL',
        type: 'line',
        color: primarycolor
      },
      {
        key: 'SDFAST,TTauxBrutEspaceDelegant.tauxBrut',
        name: 'BYTEL',
        type: 'line',
        color: primarycolor
      },
      {
        key: 'SFR,TTauxBrutEspaceDelegant.tauxBrut',
        name: 'SFR',
        type: 'line',
        color: redColor
      },
      {
        key: 'ORANGE,TTauxBrutEspaceDelegant.tauxBrut',
        name: 'ORANGE',
        type: 'line',
        color: secondarycolor
      },
      {
        key: 'FREE,TTauxBrutEspaceDelegant.tauxBrut',
        name: 'FREE',
        type: 'line',
        color: greyColor
      },
      {
        key: 'AUTRES,TTauxBrutEspaceDelegant.tauxBrut',
        name: 'AUTRES',
        type: 'line',
        color: darkGreyColor
      }
    ],
    plotOptions: {
      title: {
        text: 'Taux brut des ajournements par OC'
      },
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value: number) => value.toFixed(2) + '%'
      },
      legend: {
        data: ['BYTEL', 'SFR', 'ORANGE', 'FREE', 'AUTRES'],
        top: '10%'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {
            title: 'Télécharger            '
          }
        }
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          label: {},
          formatter: function (value: Date) {
            const date = new Date(value);
            return new DateManager(date).format('MMMM YYYY');
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          label: {},
          formatter: function (value: Date) {
            return `${value}%`;
          }
        }
      }
    }
  },

  nbJoursOuvresMoyenAjournement: {
    categoryAxisIsX: true,
    seriesOption: [
      {
        name: 'Volumes en cours',
        key: 'NbJoursOuvresMoyenAjournement.Backlog',
        type: 'bar',
        label: {
          show: true,
          color: primarycolor,
          formatter: function (param: { data: number }) {
            return Math.round(param.data);
          },
          position: 'top'
        },
        labelLayout: function (params: { rect: { x: number; y: number } }) {
          return {
            x: params.rect.x - 10,
            y: params.rect.y - 30
          };
        },
        labelLine: {
          show: true
        },
        itemStyle: {
          // Color of the point.
          color: primarycolor
        }
      },
      {
        name: 'Délai de résolution constaté sur 1 an glissant',
        key: 'NbJoursOuvresMoyenAjournement.NbJoursOuvresMoyen',
        type: 'scatter',
        yAxisIndex: 1,
        label: {
          color: secondarycolor,
          show: true,
          formatter: function (param: { data: number }) {
            return Math.round(param.data);
          },
          position: 'top'
        },
        labelLayout: function (params: { rect: { x: number; y: number } }) {
          return {
            x: params.rect.x + 10,
            y: params.rect.y - 20
          };
        },
        labelLine: {
          show: true
        },
        itemStyle: {
          // Color of the point.
          color: secondarycolor
        }
      }
    ],
    plotOptions: {
      title: {
        text: 'Volume des ajournements en cours'
      },
      legend: {
        top: '10%'
        // data: ['ORANGE', 'BYTEL']
      },
      grid: {
        left: '5%',
        right: '5%',
        top: '30%',
        bottom: '0%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {
            title: 'Télécharger            '
          }
        }
      },
      xAxis: {
        type: 'category',

        axisLabel: {
          interval: 0,
          rotate: 30, //If the label names are too long you can manage this by rotating the label.
          formatter: function (value: string) {
            const interventionDisplayName = typesInterventionList.find((e) => e.name === value);
            return interventionDisplayName ? interventionDisplayName.displayName : value;
          }
        }
      },

      yAxis: [
        {
          type: 'value',
          min: 0,
          interval: 2000
        },
        {
          type: 'value',
          min: 0,
          interval: 50
        }
      ]
    }
  }
};
