import CustomClipboard from '../../../../components/CustomClipboard';
import InputWrapper from '@designSystem/InputWrapper';
import React from 'react';
import ReactSelect from 'react-select';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

const StyledDivRelative = styled.div`
  position: relative;
`;

const ProductVerticalityDisabledSelect: React.FC<{
  label: string;
  placeholder: string;
  optionValue: string | undefined;
  clipboard?: boolean;
}> = ({ label, placeholder, optionValue, clipboard = false }) => {
  return (
    <Row className="g-2 mt-3">
      <StyledDivRelative>
        {clipboard && <CustomClipboard valueToCopy={optionValue} />}
        <InputWrapper inputLabel={label}>
          <ReactSelect
            isDisabled
            aria-label={`${placeholder}-input`}
            placeholder={placeholder}
            value={{ value: optionValue, label: optionValue }}
            options={[{ value: optionValue, label: optionValue }]}
          ></ReactSelect>
        </InputWrapper>
      </StyledDivRelative>
    </Row>
  );
};

export default ProductVerticalityDisabledSelect;
