import { ThemeProps } from '../../style/theme';
import { css, CSSProperties } from 'styled-components';
interface StyledTableProps {
  theme?: ThemeProps;
  style?: CSSProperties;
  className?: string;
  maxCols?: number;
}
interface TableProps extends StyledTableProps {
  theme: ThemeProps;
}

export const defaultStyle = () => {
  return css`
    table-layout: auto;
    width: 100%;
    max-width: 100%;
    font-size: ${(props: TableProps) => props.theme.fonts.size.base};
    background: white;
    overflow-wrap: break-word;
    overflow-x: scroll;

    div,
    span,
    p {
      display: flex;
      align-items: center;
    }

    p {
      color: ${(props: TableProps) => props.theme.palette['grey'][800]};
      margin: 0;
    }

    tr {
      border-bottom: 1px solid ${(props: TableProps) => props.theme.palette['grey'][100]};
    }

    th,
    td {
      color: ${(props: TableProps) => props.theme.palette['grey'][400]};
      font-family: ${(props: TableProps) => props.theme.fonts.primary};
      font-weight: ${(props: TableProps) => props.theme.fonts.weight.medium};
      font-size: ${(props: TableProps) => props.theme.fonts.size.xs};
      line-height: ${(props: TableProps) => props.theme.fonts.size.base};
      width: ${getWidth};

      button {
        word-break: normal;
      }
    }

    th {
      color: ${(props: TableProps) => props.theme.palette['grey'][700]};
      text-transform: uppercase;
      background: ${(props: TableProps) => props.theme.palette['grey'][50]};
      padding: 14px 18px;
      border-bottom: 1px solid ${(props: TableProps) => props.theme.palette['grey'][200]};

      button {
        word-break: break-all;
        text-transform: uppercase;
      }
    }
    td {
      word-break: break-all;
      padding: 14px 18px;

      div {
        gap: 0.5rem;

        & svg.clickable {
          font-size: ${(props: TableProps) => props.theme.fonts.size.base};
        }
      }
    }

    tr:hover {
      background: ${(props: TableProps) => props.theme.palette['blue'][50]};
    }

    .selected-row-blue {
      background: ${(props: TableProps) => props.theme.palette['blue'][50]};
    }

    .selected-row-purple {
      background: ${(props: TableProps) => props.theme.palette['purple'][50]};
    }

    &.compact {
      th {
        padding: 10px 4px;
        button {
          padding: 2px 4px;
        }
      }
      td {
        padding: 8px 8px;
      }
    }
  `;
};

const getWidth = (props: TableProps) => {
  if (props.maxCols) {
    return `${1020 / props.maxCols}px`;
  }
  return 'inherit';
};
