import Badge from '@designSystem/Badges';
import Button from '@designSystem/Button';
import { Modal } from 'react-bootstrap';
import ModalContentWithIcon from '@designSystem/Modal/ModalContentWithIcon';
import { PeopleFill } from 'react-bootstrap-icons';
import React from 'react';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const StyledTitle = styled.p`
  margin-top: 0.5rem;
  font-weight: ${theme.fonts.weight.semibold};
  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.sm};
  color: ${theme.palette.grey[600]};
  margin-bottom: 4px;
`;

const StyleMailList = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding: 9px 12px;
  border-radius: 6px;
  background: ${theme.palette.grey[50]};
  border: 1px solid ${theme.palette.grey[300]};
  margin-bottom: 1.5rem;
`;

const MailListModal: React.FC<{
  mails: { id: string; name: string }[];
  isCAF: boolean;
  backAction: CallableFunction;
}> = ({ mails, isCAF, backAction }) => {
  const { t } = useTranslation();
  return (
    <Modal show={mails.length > 0} onHide={() => backAction()}>
      <ModalContentWithIcon
        title={t('features.calendars.profile', 'Profil gestion')}
        description={
          isCAF
            ? t('features.calendars.caf', `Manage CAF`)
            : t('features.calendars.gestion', `Manage gestionnaires`)
        }
      >
        <PeopleFill />
      </ModalContentWithIcon>
      <Modal.Body style={{ paddingTop: '0' }}>
        <StyledTitle>Mail(s) envois CAF ({mails.length})</StyledTitle>
        <StyleMailList>
          {mails.map((m) => {
            return (
              <Badge variant="grey" key={m.id}>
                {m.name}
              </Badge>
            );
          })}
        </StyleMailList>
        <Button autoFocus design="white" style={{ width: '100%' }} onClick={() => backAction()}>
          {t('component.button.back', 'Back')}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default MailListModal;
