import toast from 'react-hot-toast';
import { Trans } from 'react-i18next';

import { formatAddressToString } from '@features/eligibility/eligibilitySlice';
import { IGeographicAddress } from '@features/eligibility/interfaces';
import { i18n } from '@i18n';
import theme from '@theme';

import { env } from '../../../config';
import { getAllProductCode, productsListWithInfos } from '../../eligibility/const';
import OrderBookToast from '../components/OrderBookToast';
import { OrderSteps } from './constants';
import { ClientType, Order, OrderOperaBusiness, ProductOrderItem } from './orderInterfaces';

export enum OrderSections {
  EndClientInformations = 'EndClientInformations',
  FinalClientSite = 'FinalClientSite',
  Outlet = 'Outlet',
  ComplementaryInformations = 'ComplementaryInformations'
}

export const ErdvApexErrorsMap = (): { [key: string]: string | JSX.Element } => ({
  default: i18n.t(
    'errorMessages.ErdvApexErrorsMap.invalid_format',
    'Oops… An error has occurred. Please try again later.'
  ),
  '-1': i18n.t(
    'errorMessages.ErdvApexErrorsMap.err1',
    'An unknown error happened, please try again later'
  ),
  '-2': i18n.t(
    'errorMessages.ErdvApexErrorsMap.err2',
    'No more slot available on the asked period'
  ),
  '-3': i18n.t(
    'errorMessages.ErdvApexErrorsMap.err3',
    'It is too late to book an appointment on this slot'
  ),
  '-4': i18n.t('errorMessages.ErdvApexErrorsMap.err4', 'Calendar not found'),
  '-5': i18n.t('errorMessages.ErdvApexErrorsMap.err5', 'DSP not found'),
  '-9': (
    <Trans
      i18nKey="errorMessages.ErdvApexErrorsMap.err9"
      defaultValue="There are no appointments available in this area. Please contact your ADV at <email>adv-ftth-active@axione.fr<email />"
      components={{
        email: (
          <span
            style={{
              fontWeight: theme.fonts.weight.medium,
              color: theme.palette.blue[500],
              display: 'contents'
            }}
          />
        )
      }}
    />
  ),
  '-10': i18n.t('errorMessages.ErdvApexErrorsMap.err10', 'An error happened'),
  EXTERNAL_API_ERROR: i18n.t('errorMessages.ErdvApexErrorsMap.external', 'An error happened'),
  no_appointments_available: i18n.t(
    'errorMessages.ErdvApexErrorsMap.no_appointments_available',
    'Oops… This address does not yet have any slots to place orders. You can try again later.'
  ),
  INVALID_FORMAT: i18n.t(
    'errorMessages.ErdvApexErrorsMap.invalid_format',
    'Oops… An error has occurred. Please try again later.'
  )
});

export const ErdvApexBookErrorsMap: {
  [key: string]: string;
} = {
  default: i18n?.t('errorMessages.ErdvApexBookErrorsMap.default', 'An error happened'),
  '-1': i18n?.t(
    'errorMessages.ErdvApexBookErrorsMap.err1',
    'An unknown error happened, please try again later'
  ),
  '-2': i18n?.t(
    'errorMessages.ErdvApexBookErrorsMap.err2',
    `You can't book this slot because you are not linked to the geographic zone.`
  ),
  '-4': i18n?.t(
    'errorMessages.ErdvApexBookErrorsMap.err4',
    `This slot can't be booked, it is too close from today.`
  ),
  '-5': i18n?.t(
    'errorMessages.ErdvApexBookErrorsMap.err5',
    'This slot as already been booked. Please take another slot and validate again.'
  ),
  '-6': i18n?.t(
    'errorMessages.ErdvApexBookErrorsMap.err6',
    `The slot doesn't exist. Please take another slot and validate again.`
  ),
  '-7': i18n?.t('errorMessages.ErdvApexBookErrorsMap.err7', 'Slot not found.'),
  EXTERNAL_API_ERROR: i18n?.t('errorMessages.ErdvApexBookErrorsMap.external', 'An error happened')
};

const OrderCreationErrorsMap: {
  [key: string]: string;
} = {
  default: i18n?.t(`An error happened. Please try again later or contact Axione.`),
  MISSING_OPERATOR_CODE: i18n?.t(`Operator code is missing`)
};

export const getOrderErrorMessageAndActionLabel = (
  errors: any
): { errors: string[]; action: string; redirectToStep?: number } => {
  if (errors?.pdcErrors) {
    return errors.pdcErrors.reduce(
      (acc: { errors: string[]; action: string; redirectToStep?: number }, error: any) => {
        switch (error.detail) {
          case 'FIMP13: IDENTIFIANT COMMANDE INTERNE OC DEJA UTILISE':
            acc.errors.push(i18n?.t(`Your intern reference is already used, please change`));
            acc.action = i18n?.t('pages.order.editRef', 'Edit my intern reference');
            acc.redirectToStep = OrderSteps.ORDER_INFO_FORM;
            break;
          case 'Saisissez un numéro de téléphone valide.':
            acc.errors.push(i18n?.t(`Phone number format is invalid`));
            acc.action = i18n?.t('pages.order.editPhone', 'Edit my phone number');
            break;
          case 'parse_error':
            acc.errors.push(
              i18n?.t(`Your order includes an error, please start a new order creation`)
            );
            acc.action = i18n?.t('pages.order.newOrder', 'New order');
            break;
          default:
            acc.action = i18n?.t('component.button.close', 'Close');
        }
        return acc;
      },
      { errors: [], action: i18n?.t('component.button.goBack', 'Go Back') }
    );
  }

  return {
    errors: [OrderCreationErrorsMap[errors?.internalError]],
    action: i18n?.t('component.button.goBack', 'Close')
  };
};

// We only display a CTA to choose another slot for this error codes.
const errorBookCodesToDisplayReturnButton = ['-4', '-5', '-6', '-7'];

export const displayOrderBookToastError = (error: string, errorCode: string) => {
  const hasButtonForCTA = errorBookCodesToDisplayReturnButton.includes(errorCode);

  toast((toastParams) => {
    toastParams.type = 'error';
    toastParams.className = 'toast-top-right toast-top-right-large';
    if (hasButtonForCTA) {
      toastParams.duration = 15000;
    }

    return (
      <OrderBookToast
        message={error}
        canDisplayLink={hasButtonForCTA}
        onClose={() => {
          toast.dismiss(toastParams.id);
        }}
      />
    );
  });
};

export const LoadingBarTimeOut = env.ENVIRONMENT === 'test' ? 200 : 2000;
export const FakeLoadingBarTimeOut = env.ENVIRONMENT === 'test' ? 200 : 4000;

export const concatComments = (
  clientType: ClientType,
  complementaryOutlet?: boolean,
  outlet?: string,
  businessName?: string,
  comments?: string,
  appointmentComments?: string
) => {
  const comment = [];

  if (complementaryOutlet) {
    comment.push(`MULTI-ACCES ${outlet || ''}`);
  }
  if (businessName && clientType === ClientType.COMPANY) {
    comment.push(`RS: ${businessName.trim()}`);
  }
  if (comments) {
    comment.push(`${comments.replaceAll('\n', ' ')}`);
  } else {
    comment.push('');
  }
  if (appointmentComments) {
    comment.push(`RDV: ${appointmentComments.replaceAll('\n', ' ')}`);
  }
  return comment.join(' - ');
};

export const getProductCharacteristic = (productItem: ProductOrderItem, name: string) => {
  return productItem.product.productCharacteristic.find(
    (productCharacteristic) => productCharacteristic.name === name
  );
};

export const getProductByOfferingName = (productOrderItem: ProductOrderItem[], name: string[]) => {
  if (productOrderItem && productOrderItem.length) {
    return productOrderItem.find((productItem) =>
      name.includes(productItem.product.productOffering.id)
    );
  }
};

export const getDefinedListOfProduct = () =>
  productsListWithInfos.map((productFromDefinedList) => productFromDefinedList.code);

export const FAKE_SIRET = '00000000000000';
const isSiretMandatoryValue = (
  siret: string,
  lastName: string | null,
  reasonNoSirenOrSiret: string | null
) => {
  // If siret is filled, it means the input is mandatory
  if (siret && siret !== FAKE_SIRET) {
    return true;
  }
  // If the user has filled other informations, and siret is empty, it means he checked no siret
  if (lastName || siret === FAKE_SIRET) {
    return false;
  }

  // Else we define the value depending on api result just in case
  return reasonNoSirenOrSiret !== null ? !reasonNoSirenOrSiret : true;
};

const rdvMatch = /^RDV:\s*(.*)?$/i;
export const getFormValuesFromData = (
  order: Order | OrderOperaBusiness,
  addressInfos?: IGeographicAddress
) => {
  const { reasonNoSirenOrSiret, contact, siret, name } = order.customer || {};
  const places = order?.productOrderItem.find((p) => p.product.isBundle)?.product?.place;
  const place = places?.length && places[0];
  const productOrderItemAccess = order?.productOrderItem[0].productOrderItem.find(
    (p) => p.product.productOffering.id == 'acces'
  );
  const productToOrder = getProductByOfferingName(
    order.productOrderItem,
    getDefinedListOfProduct()
  );
  const productComment = (order.note?.length && order.note[0].text) || '';
  // const productOtoRef =
  //   productOrderItemAccess && getProductCharacteristic(productOrderItemAccess, 'initialOTO');
  const isHomeBasic = productToOrder?.product.productOffering.id === 'OPHR03';
  const isBusiness =
    productToOrder &&
    productsListWithInfos.find((p) => p.code === productToOrder.product.productOffering.id)
      ?.isBusiness;

  const vlan = productOrderItemAccess && getProductCharacteristic(productOrderItemAccess, 'vlan');
  const spanningTreeTransparency =
    productOrderItemAccess &&
    getProductCharacteristic(productOrderItemAccess, 'spanningTreeTransparency');
  const networkInterface =
    productOrderItemAccess && getProductCharacteristic(productOrderItemAccess, 'interface');
  const projectName =
    productOrderItemAccess && getProductCharacteristic(productOrderItemAccess, 'projectName');

  const RStoRemove = `RS: ${name} -`;
  const isMultiAccess = productComment?.startsWith('MULTI-ACCES');

  const match = productComment
    .replace(RStoRemove, '')
    .replace('MULTI-ACCES  - ', '')
    .match(/^(?:MULTI-ACCES\s+([\w-]+)\s+-\s+)?(.*?)(?:\s+-?\s*RDV:\s*(.*))?$/i);

  const multiAccess = match ? match[1] || '' : '';
  let comment = match ? match[2] || '' : '';
  let rdvComment = match ? match[3] || '' : '';

  // As second match is w+, if comment is empty it will match the rdv comment if it exist
  if (match && match[2].match(rdvMatch)) {
    comment = '';
    rdvComment = match[2];
  }

  // Remove RDV: from comment to display
  rdvComment = rdvComment?.replace('RDV:', '').trim();

  return {
    form: {
      isSiretMandatory: isSiretMandatoryValue(siret, contact?.lastName, reasonNoSirenOrSiret),
      clientType:
        isHomeBasic || isBusiness || name || siret ? ClientType.COMPANY : ClientType.INDIVIDUAL,
      businessName: name,
      siret: siret || '',
      siretAbsenceReason: reasonNoSirenOrSiret,
      lastName: contact?.lastName || '',
      firstName: contact?.firstName || '',
      phoneContact1: contact?.mobilePhoneNumber || '',
      phoneContact2:
        contact?.phoneNumber !== contact?.mobilePhoneNumber ? contact?.phoneNumber : '',
      email: contact?.emailAddress || '',
      buildingRef: place && place.id,
      building: place && place.buildingName,
      stair: place && place.stair,
      floor: place && place.floor,
      existingOutlet: !!(place && place.otoRef) || isMultiAccess || (place && place?.otoIsPresent),
      outlet: (place && place.otoRef) || multiAccess || '',
      complementaryOutlet: isMultiAccess,
      internReference: order.externalReference,
      comments: comment,
      address: addressInfos ? formatAddressToString(addressInfos) : '',
      ...(isBusiness && {
        vlan: vlan?.value,
        spanningTreeTransparency: spanningTreeTransparency?.value,
        networkInterface: networkInterface?.value,
        ...(projectName && { projectName: projectName.value })
      })
    },
    rdv: {
      appointmentComments: rdvComment
    }
  };
};

export const setHighLightedText = (
  stringToHighlight: string,
  searchedText: string | undefined,
  tooltip = false
): JSX.Element | string => {
  if (tooltip) {
    return stringToHighlight;
  }

  if (searchedText) {
    const normalizedSearchedText = searchedText
      .normalize('NFD')
      .toLowerCase()
      .replace(/[\u0300-\u036f]/g, '');
    const normalizedStringToHighlight = stringToHighlight
      .normalize('NFD')
      .toLowerCase()
      .replace(/[\u0300-\u036f]/g, '');

    if (normalizedSearchedText === normalizedStringToHighlight) {
      return <span className="highlighted_text">{stringToHighlight}</span>;
    }

    const index = normalizedStringToHighlight.indexOf(normalizedSearchedText);
    if (index !== -1) {
      const before = stringToHighlight.substring(0, index);
      const after = stringToHighlight.substring(index + searchedText.length);
      const matched = stringToHighlight.substring(index, index + searchedText.length);

      const highlightedAfter = setHighLightedText(after, searchedText, true);

      return (
        <>
          {before}
          <span className="highlighted_text">{matched}</span>
          {highlightedAfter}
        </>
      );
    }
  }

  return stringToHighlight;
};

const otoKeysGetter = ['initialOTO', 'finalOTO'];
type OtoStatusFinderResult = {
  [key in typeof otoKeysGetter[number]]: {
    reference: string | null;
    isPresent: boolean;
    type: string;
  };
};
export const otoStatusFinder = (order: Order): Partial<OtoStatusFinderResult> => {
  const productsCodeInfos = getAllProductCode();

  return order?.productOrderItem.reduce((acc, curr) => {
    if (productsCodeInfos.includes(curr?.product?.productOffering.id)) {
      return {
        ...curr?.product?.productCharacteristic?.reduce((acc, curr) => {
          if (otoKeysGetter.includes(curr.name)) return { ...acc, [curr.name]: curr.value };
          return acc;
        }, {})
      };
    }
    return acc;
  }, {});
};

type AppointmentDateFinderResult = {
  type?: string;
  initialDate?: string;
  initialSlot?: string;
  finalDate?: string;
  finalSlot?: string;
};
export const appointmentDateFinder = (order?: Order): AppointmentDateFinderResult => {
  if (!order) return {};
  return order?.productOrderItem.reduce((acc, curr) => {
    const productsCodeInfos = getAllProductCode();
    if (productsCodeInfos.includes(curr?.product?.productOffering.id)) {
      return curr.appointment;
    }
    return acc;
  }, {});
};

type ProductCharacteristicValue<T = string | { reference: string }> = T;

type CharacteristicFinderResult<T = ProductCharacteristicValue> = {
  name?: string;
  value?: T;
  valueType?: string;
};

export const productCharacteristicFinder = <
  T extends ProductCharacteristicValue = ProductCharacteristicValue
>(
  productOrderItem: ProductOrderItem[],
  characteristic: string
): CharacteristicFinderResult<T> => {
  return productOrderItem.reduce(
    (acc, curr) =>
      curr.product.productCharacteristic?.find((e) => e.name === characteristic) || acc,
    {}
  );
};
