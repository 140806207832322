import React from 'react';
import ReactMainLogo from '../../images/main-logo.svg';

const Logo: React.FC<{ reduced?: boolean; reverse?: boolean }> = ({
  reduced = false,
  reverse = false
}) => {
  if (reduced) {
    return (
      <img id="main-logo" style={{ maxWidth: '130px' }} src={ReactMainLogo} alt="Portail Axione" />
    );
  }
  if (reverse) {
    return (
      <img
        id="main-logo"
        style={{ float: 'left', margin: '6px 8px 0 0' }}
        width="20"
        src={ReactMainLogo}
        alt="Portail Axione"
      />
    );
  }

  return (
    <img id="main-logo" style={{ maxWidth: '130px' }} src={ReactMainLogo} alt="Portail Axione" />
  );
};

export default Logo;
