import { StyledCardFooter, StyledCardBody, StyledCardHeader, StyledCard } from './designStyles';

import React, { ReactChild } from 'react';

interface CardProps extends Omit<Omit<React.HTMLProps<HTMLElement>, 'as'>, 'ref'> {
  header?: ReactChild | null;
  footer?: ReactChild | null;
  selected?: boolean;
  hoverable?: boolean;
  children?: ReactChild[] | ReactChild | null;
}

const Card: React.FC<CardProps> = ({ header, footer, selected, children, ...props }) => {
  return (
    <StyledCard selected={selected} {...props}>
      {header && <StyledCardHeader>{header}</StyledCardHeader>}
      <StyledCardBody hasHeader={!!header} hasFooter={!!footer}>
        {children}
      </StyledCardBody>

      {footer && <StyledCardFooter>{footer}</StyledCardFooter>}
    </StyledCard>
  );
};

export default Card;
