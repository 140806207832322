import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import theme, { ThemeProps } from '@theme';

import { Card } from 'react-bootstrap';
import InputWrapper from '@designSystem/InputWrapper';
import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledForm = styled(Card.Body)`
  margin-top: 1rem;

  .select {
    font-family: ${(props: { theme: ThemeProps }) => props.theme.fonts.primary};
    line-height: ${(props: { theme: ThemeProps }) => props.theme.fonts.size.base};
    color: ${theme.palette.grey[900]};
    font-size: ${(props: { theme: ThemeProps }) => props.theme.fonts.size.sm};
    font-weight: ${(props: { theme: ThemeProps }) => props.theme.fonts.weight.regular};
    margin-bottom: 1rem;
  }
`;

export type ZoneGeoRefFormData = {
  typology: { value: number; label: string };
  name: string;
};

type UseControllerReturn = {
  field: ControllerRenderProps<ZoneGeoRefFormData, 'typology'>;
};

const ZoneGeoReferenceFormInputs: React.FC<{
  register: CallableFunction;
  control: Control<ZoneGeoRefFormData>;
  formValues: ZoneGeoRefFormData;
}> = ({ control, register, formValues }) => {
  const typologySelectList = [
    { value: 1, label: 'REF_PM' },
    { value: 2, label: 'CODE_INSEE' },
    { value: 3, label: 'BUILDING_ID' }
  ];
  const { t } = useTranslation();
  return (
    <StyledForm>
      <Controller
        name="typology"
        control={control}
        render={({ field }: UseControllerReturn) => (
          <InputWrapper
            inputLabel={t(
              'features.zonesGeo.zoneGeoreferenceForm.typology',
              'Typology of the reference'
            )}
          >
            <ReactSelect
              onChange={(value) => field.onChange(value)}
              inputId="typology"
              aria-label="typology-select"
              defaultValue={null}
              options={typologySelectList}
              value={formValues.typology}
              placeholder={''}
              className="select"
            />
          </InputWrapper>
        )}
      />
      <InputWrapper
        inputLabel={t('features.zonesGeo.zoneGeoreferenceForm.name', 'Name of the reference')}
      >
        <input type="text" id="name" {...register('name')} aria-label="name-input" />
      </InputWrapper>
    </StyledForm>
  );
};

export default ZoneGeoReferenceFormInputs;
