import React, { ReactChild } from 'react';

import Button from '@designSystem/Button';
import FixedSpinner from './FixedSpinner';
import { Modal } from 'react-bootstrap';
import ModalContentWithIcon from '@designSystem/Modal/ModalContentWithIcon';
import { TrashFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

const DeleteModal: React.FC<{
  modalId: number | false;
  title: ReactChild | string;
  description: ReactChild | string;
  children?: ReactChild;
  loading?: boolean;
  backAction: CallableFunction;
  deleteAction: CallableFunction;
}> = ({ modalId, children, title, description, loading = false, backAction, deleteAction }) => {
  const { t } = useTranslation();
  return (
    <Modal show={!!modalId || modalId === 0} onHide={() => backAction()}>
      <FixedSpinner loading={loading}>
        <ModalContentWithIcon variant="red" title={title} description={description}>
          <TrashFill />
        </ModalContentWithIcon>
        <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>{children}</div>
        <Modal.Footer className="d-flex justify-content-between flex-row">
          <Button autoFocus design="white" style={{ width: '45%' }} onClick={() => backAction()}>
            {t('component.button.back', 'Back')}
          </Button>
          <Button variant="red" style={{ width: '45%' }} onClick={() => deleteAction()}>
            {t('component.button.delete', 'Delete')}
          </Button>
        </Modal.Footer>
      </FixedSpinner>
    </Modal>
  );
};

export default DeleteModal;
