import { containerStyle, defaultStyle } from './designStyles';

import { IThemedComponent } from '../designInterfaces';
import React from 'react';
import { ThemeVariants } from '../../style/theme';
import styled from 'styled-components';

type LinkedCardProps = {
  children?: React.ReactNode;
  variant?: ThemeVariants;
  onClick?: () => void;
};

const LinkedCardContainer = styled.div`
  ${containerStyle}
`;

const styles = defaultStyle();

const SidebarLinkedCard = styled.div<IThemedComponent>`
  ${styles}
`;

const LinkedCard = ({ variant = 'blue', onClick, ...props }: LinkedCardProps) => {
  return <SidebarLinkedCard onClick={onClick} variant={variant} {...props} />;
};

export { LinkedCardContainer };

export default LinkedCard;
