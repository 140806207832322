export type productsListWithInfosType = {
  code: string;
  titre: string;
  availableToOrder: boolean;
  hasVerticality: boolean;
  isBusiness: boolean;
  isZTD: boolean;
  profiles?: string[];
  engineeringType?: string;
};

export const getAllProductCode = () => productsListWithInfos.map((infos) => infos.code);

export const HomeBasicProductCode = 'OPHR03';

export const productsListWithInfos: productsListWithInfosType[] = [
  {
    code: 'OPHR01',
    titre: 'OPERA Home RIP Profil GP avec multicast',
    availableToOrder: true,
    hasVerticality: true,
    isBusiness: false,
    isZTD: false,
    engineeringType: 'FTTH'
  },
  {
    code: 'OPHR02',
    titre: 'OPERA Home RIP Profil Pro sans multicast',
    availableToOrder: true,
    hasVerticality: true,
    isBusiness: false,
    isZTD: false,
    engineeringType: 'FTTH-PRO'
  },
  {
    code: 'OPHR03',
    titre: 'OPERA Home Basic',
    availableToOrder: true,
    hasVerticality: false,
    isBusiness: false,
    isZTD: false,
    engineeringType: 'OPERA-BASIC'
  },
  {
    code: 'OPHZ01',
    titre: 'OPERA Home ZTD Profil GP avec multicast',
    availableToOrder: true,
    hasVerticality: true,
    isBusiness: false,
    isZTD: true,
    engineeringType: 'FTTH'
  },
  {
    code: 'OPHZ02',
    titre: 'OPERA Home ZTD Profil Pro sans multicast',
    availableToOrder: true,
    hasVerticality: true,
    isBusiness: false,
    isZTD: true,
    engineeringType: 'FTTH-PRO'
  },
  {
    code: 'OPOR01',
    titre: 'OPERA Office RIP 10 Mbps',
    availableToOrder: true,
    hasVerticality: true,
    isBusiness: false,
    isZTD: false,
    engineeringType: 'OPERA-OFFICE'
  },
  {
    code: 'OPOR02',
    titre: 'OPERA Office RIP 100 Mbps',
    availableToOrder: true,
    hasVerticality: true,
    isBusiness: false,
    isZTD: false,
    engineeringType: 'OPERA-OFFICE'
  },
  {
    code: 'OPOZ01',
    titre: 'OPERA Office ZTD 100 Mbps',
    availableToOrder: true,
    hasVerticality: true,
    isBusiness: false,
    isZTD: true,
    engineeringType: 'OPERA-OFFICE'
  },
  {
    code: 'OPBR01',
    titre: 'OPERA Business RIP 10 Mbps',
    availableToOrder: true,
    hasVerticality: false,
    isBusiness: true,
    isZTD: false,
    profiles: ['2 Mbits', '4 Mbits', '6 Mbits', '10 Mbits']
  },
  {
    code: 'OPBR02',
    titre: 'OPERA Business RIP 100 Mbps',
    availableToOrder: true,
    hasVerticality: false,
    isBusiness: true,
    isZTD: false,
    profiles: ['20 Mbits', '30 Mbits', '40 Mbits', '50 Mbits', '60 Mbits', '100 Mbits']
  },
  {
    code: 'OPBR03',
    titre: 'OPERA Business RIP 300 Mbps',
    availableToOrder: true,
    hasVerticality: false,
    isBusiness: true,
    isZTD: false,
    profiles: ['200 Mbits', '300 Mbits']
  },
  {
    code: 'OPBR04',
    titre: 'OPERA Business RIP 1 Gbps',
    availableToOrder: true,
    hasVerticality: false,
    isBusiness: true,
    isZTD: false,
    profiles: ['500 Mbits', '1000 Mbits']
  },
  {
    code: 'OPBZ00',
    titre: 'OPERA Business ZTD 10 Mbps',
    availableToOrder: false,
    hasVerticality: true,
    isBusiness: true,
    isZTD: true,
    profiles: ['2 Mbits', '4 Mbits', '6 Mbits', '10 Mbits']
  },
  {
    code: 'OPBZ01',
    titre: 'OPERA Business ZTD 100 Mbps',
    availableToOrder: false,
    hasVerticality: true,
    isBusiness: true,
    isZTD: true,
    profiles: ['20 Mbits', '30 Mbits', '40 Mbits', '50 Mbits', '60 Mbits', '100 Mbits']
  },
  {
    code: 'OPBZ02',
    titre: 'OPERA Business ZTD 300 Mbps',
    availableToOrder: false,
    hasVerticality: true,
    isBusiness: true,
    isZTD: true,
    profiles: ['200 Mbits', '300 Mbits']
  },
  {
    code: 'OPBZ03',
    titre: 'OPERA Business ZTD 1 Gbps',
    availableToOrder: false,
    hasVerticality: true,
    isBusiness: true,
    isZTD: true,
    profiles: ['500 Mbits', '1000 Mbits']
  },
  {
    code: 'OPBC01',
    titre: 'OPERA Business ZTD (Centre Co) 100 Mbps',
    availableToOrder: false,
    hasVerticality: true,
    isBusiness: true,
    isZTD: true,
    profiles: ['20 Mbits', '30 Mbits', '40 Mbits', '50 Mbits', '60 Mbits', '100 Mbits']
  },
  {
    code: 'OPBC02',
    titre: 'OPERA Business ZTD (Centre Co) 300 Mbps',
    availableToOrder: false,
    hasVerticality: true,
    isBusiness: true,
    isZTD: true,
    profiles: ['200 Mbits', '300 Mbits']
  },
  {
    code: 'OPBC03',
    titre: 'OPERA Business ZTD (Centre Co) 1 Gbps',
    availableToOrder: false,
    hasVerticality: true,
    isBusiness: true,
    isZTD: true,
    profiles: ['500 Mbits', '1000 Mbits']
  },
  {
    code: 'OPBF01',
    titre: 'OPERA Business RIP Fidélisation 100 Mbps',
    availableToOrder: true,
    hasVerticality: false,
    isBusiness: true,
    isZTD: false,
    profiles: ['100 Mbits']
  }
];

export const mailtoAxione = 'mailto:cotation@axione.fr';
