import { Trans, useTranslation } from 'react-i18next';

import { Card } from 'react-bootstrap';
import InformationBlock from '@designSystem/InformationBlock';
import OrderAppointmentSidebarIcon from './OrderAppointmentForm/OrderAppointmentSidebarIcon';
import React from 'react';
import { isHomeBasicProduct } from '@features/eligibility/helper';
import { selectOrderState } from '@features/order/reducer/orderSlice';
import { useAppSelector } from '../../../app/hooks';

const OrderAppointmentSidebar = () => {
  const { t } = useTranslation();
  const { productToOrder } = useAppSelector(selectOrderState);

  const isBasic = isHomeBasicProduct(productToOrder);
  const timeTranslation = isBasic
    ? t('features.order.orderAppointment.hours', { count: 72 })
    : t('features.order.orderAppointment.days', { count: 5 });

  return (
    <Card className="side_content">
      <InformationBlock
        title={t('features.order.orderAppointment.tipOne', 'Tip n°1 :')}
        type="custom"
        customIcon={OrderAppointmentSidebarIcon()}
      >
        <Trans
          i18nKey="features.order.orderAppointment.updateAppointment"
          defaultValue="You can update your appointment up to {{time}} before the date initially fixed."
          values={{ time: timeTranslation }}
        />
      </InformationBlock>

      <InformationBlock
        title={t('features.order.orderAppointment.tipTwo', 'Tip n°2 :')}
        type="custom"
        customIcon={OrderAppointmentSidebarIcon()}
        style={{ marginTop: '10px' }}
      >
        {t(
          'features.order.orderAppointment.technician',
          "The technician can intervene at any time of the selected time slot. His intervention can last from 1h to 4h, depending on the configuration of the end customer's accommodation and the work to be carried out."
        )}
      </InformationBlock>
    </Card>
  );
};

export default OrderAppointmentSidebar;
