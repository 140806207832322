import {
  BoxWithSeparator,
  BoxWithoutSeparator,
  CardBaseOrderStyled,
  CardOrderStyled
} from './CardOrderContainer';
import { Clock, GeoAltFill, PencilSquare, PersonFill, TrashFill } from 'react-bootstrap-icons';
import { Dispatch, SetStateAction } from 'react';

import Badge from '@designSystem/Badges';
import Icon from '@designSystem/Icon';
import { Order } from '../utils/constants';
import Tooltip from '@designSystem/Tooltip';
import { formatAddressToString } from '@features/eligibility/eligibilitySlice';
import { i18n } from '@i18n';
import { setHighLightedText } from '../utils/OrderHelper';
import theme from '@theme';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';
import { useNavigate } from 'react-router-dom';
import { DateManager } from '../../../utils/DateManager';

interface CardOrderDraftProps {
  makeReferenceText: (
    commercialOperator: string,
    textToHighLight: string | undefined,
    tooltip?: boolean,
    isDraft?: boolean
  ) => string | JSX.Element;
  toHighLight?: string;
  order: Order;
  makeClientName: (
    name: string,
    textToHighLight: string | undefined,
    tooltip?: boolean
  ) => string | JSX.Element;
  setOrderIdToDelete: Dispatch<SetStateAction<string | undefined>> | undefined;
}

export const CardOrderDraft = ({
  makeReferenceText,
  toHighLight,
  order,
  makeClientName,
  setOrderIdToDelete
}: CardOrderDraftProps) => {
  const { address, externalReference, name, orderId, orderDate } = order;
  const trackEvent = useMatomoHarmonizer();

  const navigate = useNavigate();

  const deleteDraft = () => {
    trackEvent({
      page: 'Brouillons',
      category: 'Carte',
      actionType: 'Clic',
      actionDetails: 'Supprimer'
    });
    setOrderIdToDelete && setOrderIdToDelete(orderId);
  };

  const editDraft = () => {
    trackEvent({
      page: 'Brouillons',
      category: 'Carte',
      actionType: 'Clic',
      actionDetails: 'Editer'
    });
    navigate(`/ordersBeta/${orderId}`);
  };

  return (
    <CardBaseOrderStyled>
      <CardOrderStyled>
        <div className="info-draft">
          <BoxWithSeparator>
            <Tooltip
              icon={
                <div className="external-reference">
                  {makeReferenceText(externalReference, toHighLight, false, true)}
                </div>
              }
            >
              {makeReferenceText(externalReference, toHighLight, true, true)}
            </Tooltip>

            <div>
              <Icon
                className="icon"
                size="minimal"
                noBackground={true}
                variant="grey"
                iconSize="9px"
              >
                <Clock />
              </Icon>
              {i18n.t('features.order.createdAt', 'Created at:')}
              {new DateManager(orderDate).format('DD/MM/YYYY - HH:mm')}
            </div>
          </BoxWithSeparator>

          <BoxWithoutSeparator>
            <div className="sub-info">
              <Tooltip
                icon={
                  <div className="client-text">
                    <Badge
                      className={name ? '' : 'text-faded'}
                      variant="grey"
                      {...{
                        leftContent: (
                          <PersonFill
                            size="10px"
                            color={theme.palette.grey[300]}
                            className="client-icon"
                          />
                        ),
                        children: <div>{makeClientName(name, toHighLight)}</div>
                      }}
                    />
                  </div>
                }
              >
                {makeClientName(name, toHighLight, true)}
              </Tooltip>
              {address && (
                <div>
                  <Icon
                    className="icon"
                    size="minimal"
                    noBackground={true}
                    variant="grey"
                    iconSize="9px"
                    fillColor={theme.palette.grey[400]}
                  >
                    <GeoAltFill />
                  </Icon>
                  {setHighLightedText(formatAddressToString(address), toHighLight)}
                </div>
              )}
            </div>
          </BoxWithoutSeparator>
        </div>

        <div className="actions">
          <Icon
            className="icon-delete"
            size="small"
            onClick={deleteDraft}
            variant="red"
            iconSize="10px"
          >
            <TrashFill />
          </Icon>
          <Icon
            className="icon-link"
            size="small"
            variant="blue"
            iconSize="12px"
            onClick={editDraft}
          >
            <PencilSquare />
          </Icon>
        </div>
      </CardOrderStyled>
    </CardBaseOrderStyled>
  );
};
