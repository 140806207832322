import { IGeographicAddress, IOfferingResult } from '../../eligibility/interfaces';

import { OrderState as OrderStatus } from './constants';
import { SelectedTrunks } from '@features/eligibility/products/productDetail/modal/productBusinessDetailModal';

export interface OrderOptions {
  gtr?: string;
  duration?: string;
  eqt?: boolean;
  bronze?: boolean;
  optionPlus?: boolean;
  trunks?: SelectedTrunks;
  profile?: string;
}

export interface OrderOptionsPlus {
  ntePortType: string;
  interfaceOptionPlus: string;
  vlanOptionPlus: string;
  cVlanOptionPlus: string;
}

export interface OrderState {
  erdvRaccoBooked?: IOrderAppointmentBook;
  erdvRaccoBookedSlot?: ISelectedSlot;
  appointmentSlots: IGetAppointmentSlot[];
  lastApiError?: string;
  orderCreationError: any;
  orderFormSteps: IOrderFromSteps;
  orderLoading: boolean;
  productToOrder?: IOfferingResult;
  selectedOptions?: OrderOptions;
  order?: Order | OrderOperaBusiness;
}

export interface IOrderFromSteps {
  currentStep: number;
  steps: Array<{ enabled: boolean; data?: any; errors?: [] }>; // fixme: put real types in data
}

export interface Order {
  id: string;
  source: string;
  state: {
    status: OrderStatus;
    lastEvent?: { name: string; date: string };
  };
  note: { text: string }[];
  cancellationDate: Date | null;
  cancellationReason: string | null;
  description: string | null;
  completionDate: Date | null;
  expectedCompletionDate: Date | null;
  reference: string;
  name: string | null;
  externalReference: string;
  executiveContact: Contact | null;
  technicalContact: Contact | null;
  orderDate: string;
  lastUpdated: string;
  requestedCompletionDate: string;
  requestedOrderType: string;
  effectiveOrderType: string;
  protocol: string;
  version: string | null;
  operator: {
    name: string | null;
    code: string;
  };
  customer: {
    name: string;
    siret: string;
    siren: string;
    reasonNoSirenOrSiret: string | null;
    contact: Contact;
    technicalContact: Contact;
  };
  domain: string;
  productOrderItem: ProductOrderItem[];
  followUp?: any[];
}

export interface OrderOperaBusiness extends Order {
  networkInterface: string;
  vlan: number;
  spanningTreeTransparency: boolean;
  interfaceOptionPlus?: string;
  ntePortType?: ORDER_NTE_PORT_TYPE;
  vlanOptionPlus?: number;
  cVlanOptionPlus?: string;
  projectName?: string;
}

interface Contact {
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  mobilePhoneNumber: string;
}

export interface Place {
  id: string;
  city: string;
  streetName: string;
  streetType: string;
  streetNr: string;
  streetNrSuffix?: string | null;
  postcode: string;
  geographicLocation: {
    bbox: string[];
  };
  buildingAddressCode?: string | null;
  buildingName?: string | null;
  referenceType?: string;
  stair?: string | null;
  floor?: string | null;
  otoIsPresent?: boolean;
  otoRef?: string | null;
}

export interface ProductOrderItem {
  product: {
    isBundle: boolean;
    productCharacteristic: ProductCharacteristic[];
    productOffering: {
      id: string;
    };
    place?: Place[];
  };
  appointment: {
    id: string | null;
    type: string | null;
    initialDate?: string;
    initialSlot?: string;
    finalDate?: string;
    finalSlot?: string;
    isEditable: boolean;
  };
  productOrderItem: ProductOrderItem[];
}

export interface ProductCharacteristic {
  name: string;
  valueType?: string;
  value?: any;
}

interface IOrderInformationsBasicForm {
  clientType: ClientType;
  businessName: string;
  siret: string;
  lastName: string;
  firstName: string;
  phoneContact1: string;
  phoneContact2: string;
  email: string;
  buildingRef: string;
  address: string;
  building?: string;
  stair?: string;
  floor?: string;
  internReference: string;
  comments: string;
}
export interface IOrderInformationsForm extends IOrderInformationsBasicForm {
  isSiretMandatory?: boolean;
  existingOutlet: boolean;
  outlet?: string;
  complementaryOutlet: boolean;
}

export interface IOrderInformationsOperaBusinessForm extends IOrderInformationsBasicForm {
  existingOutlet: boolean;
  outlet?: string;
  complementaryOutlet: boolean;
  isSiretMandatory?: boolean;
  siretAbsenceReason?: string;
  networkInterface: string;
  vlan?: number; // input entre 1 et 4096
  spanningTreeTransparency?: boolean;
  interfaceOptionPlus: string;
  ntePortType?: ORDER_NTE_PORT_TYPE;
  vlanOptionPlus?: number;
  cVlanOptionPlus?: string; //uniquement si la valeur de ntePortType est monoPort sinon cette input n'apparait pas
  projectName?: string;
  siren?: string;
}
export enum ClientType {
  INDIVIDUAL = 'individual',
  COMPANY = 'company'
}

export interface IOrderAppointmentForm {
  selectedDate: string;
  appointmentComments: string;
}

export interface IOrderAppointmentGetSlotsParams {
  start_day: string;
  end_day: string;
  reference: string;
  intervention_type: string;
  eligibilityId: string;
  product: string;
  companyName: string;
}

export interface ISlot {
  id: string;
  value: string;
}

export interface IGetAppointmentSlot {
  day: string;
  slots: ISlot[];
}

export interface IOrderAppointmentSlot {
  day: string;
  id: string;
  slot: ISlot;
}

export interface IOrderAppointmentBook {
  id: string | undefined;
  id_calendar: string | undefined;
}

export interface ISelectedSlot {
  id: number;
  day: string;
  value: string;
}

interface IOrderCreationCommonParams {
  siret?: string;
  lastName?: string;
  firstName?: string;
  phoneContact1?: string;
  phoneContact2?: string;
  email?: string;
  businessName: string;
  buildingRef?: string;
  address?: IGeographicAddress | string;
  building?: string;
  stair?: string;
  floor?: string;
  existingOutlet?: boolean;
  outlet?: string;
  complementaryOutlet?: boolean;
  internReference?: string;
  comments?: string;
}
export interface IOrderCreationParams extends IOrderCreationCommonParams {
  appointmentId?: string;
  appointmentDate?: string;
  appointmentSlot?: string;
  eligibilityId?: string;
  fdhRef?: string | undefined;
  productOfferingId?: string | undefined;
  spvCode?: string | undefined;
  options?: OrderOptions;
  isSiretMandatory?: boolean;
  clientType?: ClientType;
}

export enum ORDER_NTE_PORT_TYPE {
  MONO_PORT = 'même port',
  BI_PORT = '2 ports'
}

export const ORDER_NTE_PORT_TYPE_LABELS = {
  [ORDER_NTE_PORT_TYPE.MONO_PORT]: 'Mono-port',
  [ORDER_NTE_PORT_TYPE.BI_PORT]: 'Bi-port'
};

export interface IOrderCreationOperaBusinessParams extends IOrderCreationCommonParams {
  duration: string;
  siretAbsenceReason?: string;
  networkInterface: any;
  vlan: number;
  spanningTreeTransparency: boolean;
  interfaceOptionPlus?: string;
  ntePortType?: ORDER_NTE_PORT_TYPE;
  vlanOptionPlus?: number;
  cVlanOptionPlus?: string;
  projectName?: string;
  profile?: string;
}

interface IOrderParamsBasic {
  id: string;
  state?: string;
}

interface IOrderBasicPatchParams extends IOrderParamsBasic, IOrderCreationParams {}

interface IOrderOperaBusinessPatchParams
  extends IOrderParamsBasic,
    IOrderCreationOperaBusinessParams {}

export type OrderPatchParams = IOrderBasicPatchParams | IOrderOperaBusinessPatchParams;
