import { ReactChild } from 'react';
import styled from 'styled-components';
import theme from '@theme';
import toast from 'react-hot-toast';

export const displayToastErrorWithMessage = (title: string, description?: string | ReactChild) => {
  toast((t) => {
    t.type = 'error';
    t.className = 'toast-top-right toast-top-right-large';

    return <ToastWithDescription title={title} description={description} />;
  });
};

export const displayToastSuccessWithMessage = (
  title: string,
  description?: string | ReactChild
) => {
  toast((t) => {
    t.type = 'success';
    t.className = 'toast-top-right toast-top-right-large';

    return <ToastWithDescription title={title} description={description} />;
  });
};

const ToastWithDescription = ({
  title = '',
  description = ''
}: {
  title: string | ReactChild;
  description?: string | ReactChild;
}) => {
  return (
    <StyledOrderToastContainer>
      <div className="title">{title}</div>
      {description && <div className="toast-msg">{description}</div>}
    </StyledOrderToastContainer>
  );
};

const StyledOrderToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .title {
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};
    color: ${theme.palette.grey[900]};
  }

  .toast-msg {
    font-weight: ${theme.fonts.weight.regular};
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};
    color: ${theme.palette.grey[500]};
  }
`;
