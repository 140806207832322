import { Role } from '../role.types';

// default value is mandatory before setting the flag on different roles
export const flagListPROD = {
  _default: {
    eligibility_access: true,
    eligibility_offer_verticality: false,
    download_quotation: false,
    display_product_price: false,
    make_an_order: false,
    request_a_quote: false,
    erdv_calendar_list_read: false,
    erdv_geographic_zone_list_read: false,
    erdv_appointment_list_read: false,
    erdv_geographic_zone_create: false,
    erdv_geographic_zone_update: false,
    erdv_geographic_zone_delete: false,
    erdv_calendar_create: false,
    erdv_calendar_delete: false,
    wrong_account_warning: false
  },
  [Role.PORTAL_ORDER_FULL_BETA]: {
    eligibility_access: false,
    download_quotation: true,
    wrong_account_warning: true,
    display_product_price: true,
    make_an_order: true,
    request_a_quote: true
  },
  [Role.PORTAL_ORDER_FULL]: {
    download_quotation: true,
    wrong_account_warning: true,
    display_product_price: true,
    make_an_order: true,
    request_a_quote: true
  },
  [Role.FAI]: {
    download_quotation: true,
    limited_products: true,
    wrong_account_warning: true,
    display_product_price: true,
    make_an_order: true,
    request_a_quote: true
  },
  [Role.PORTAL_ORDER_QUOTATION]: {
    display_product_price: true,
    eligibility_offer_verticality: true,
    request_a_quote: true,
    download_quotation: true
  },
  [Role.FAI_RESTREINT]: {
    display_product_price: true,
    eligibility_offer_verticality: true,
    request_a_quote: true,
    download_quotation: true
  },
  [Role.PORTAL_ORDER_PRICE]: {
    display_product_price: true,
    download_quotation: true,
    eligibility_offer_verticality: true
  },
  [Role.PORTAL_ORDER_CONSULTATION]: {
    eligibility_offer_verticality: true
  },
  [Role.DISTRIBUTEUR]: {
    eligibility_offer_verticality: true
  },
  [Role.PORTAL_INFOS_ALL]: {},
  [Role.PORTAL_INFOS_DSP_RESTRICTED]: {},
  [Role.DELEGANT]: {
    eligibility_offer_verticality: true
  },
  [Role.INTERN]: {
    display_product_price: true,
    download_quotation: true,
    eligibility_offer_verticality: true
  },
  [Role.EFO_INTERN]: {
    display_product_price: true,
    download_quotation: true
  },
  [Role.ERDV_ADMIN]: {
    erdv_appointment_list_read: true,
    erdv_calendar_list_read: true,
    erdv_geographic_zone_list_read: true,
    erdv_geographic_zone_create: true,
    erdv_geographic_zone_update: true,
    erdv_geographic_zone_delete: true,
    erdv_calendar_create: true,
    erdv_calendar_delete: true
  },
  [Role.ERDV_MANAGER]: {
    erdv_calendar_list_read: true,
    erdv_geographic_zone_list_read: true
  },
  [Role.ERDV_SUPPLIER]: {
    erdv_appointment_list_read: true
  }
};

export const flagListDEV = {
  ...flagListPROD,
  _default: {
    ...flagListPROD._default
  }
};
