import { PinFill } from 'react-bootstrap-icons';
import styled from 'styled-components';

const Plot = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  top: -5px;
  left: -5px;
  border: 1px solid #025a73;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
`;

const PlotHover = styled(Plot)`
  border: none;
  width: 12px;
  height: 12px;
  background-color: #0180a5;
  z-index: 1;
`;

const Marker = ({
  hover,
  disabled,
  onClick,
  markerId,
  lat,
  lng
}: {
  disabled?: boolean;
  hover?: boolean;
  onClick?: any;
  markerId?: any;
  lat: number;
  lng: number;
}) => {
  if (disabled) {
    return (
      <PinFill style={{ fontSize: '1.5rem', color: '#4d4d4d', marginLeft: -11, marginTop: -36 }} />
    );
  } else if (hover) {
    return <PlotHover data-testid="plot-hover" />;
  } else {
    return (
      <Plot
        data-testid="plot"
        onClick={(e) => (onClick ? onClick(e, { markerId, lat, lng }) : null)}
      />
    );
  }
};

export default Marker;
