import { Order, OrdersListPaginateModel } from '@features/order/utils/constants';
import { clearAddress, resetOrderData } from '@features/eligibility/eligibilitySlice';
import { deleteOrderById, getOrdersPaginate } from '@features/order/reducer/orderAPI';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ApiError from '../../components/ApiError';
import Button from '@designSystem/Button';
import { CardOrderContainer } from '@features/order/orderList/CardOrderContainer';
import { MatomoCustomContext } from '../../utils/MatomoCustomProvider';
import { ModalDeleteOrder } from '@features/order/orderList/ModalDeleteOrder';
import NoOrdersFound from '@features/order/orderList/NoOrdersFound';
import OrderBetaFirstDraftModal from './modals/OrderBetaFirstDraftModal';
import PageHeader from '../../components/PageHeader/PageHeader';
import Pagination from '@designSystem/Pagination';
import Spinner from '@designSystem/Spinner';
import styled from 'styled-components';
import theme from '@theme';
import toast from 'react-hot-toast';
import { useAppDispatch } from '../../app/hooks';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';
import { useTranslation } from 'react-i18next';

const DraftsPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { matomoTrackPageView } = useContext(MatomoCustomContext);
  const trackEvent = useMatomoHarmonizer();

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const [orders, setOrders] = useState<OrdersListPaginateModel>();
  const [isLoading, setIsLoading] = useState(false);
  const [APIError, setAPIError] = useState(false);
  const [orderIdToDelete, setOrderIdToDelete] = useState<string | undefined>();
  const [currentFilterAndSort, setCurrentFilterAndSort] = useState<{
    search?: string;
    from?: string;
    to?: string;
    currentPage: number;
  }>({
    search: '',
    currentPage: Number(searchParams.get('currentPage')) || 0
  });

  const hasNoOrdersFound = orders?.orders.length === 0;

  const totalOrders = orders?.total ?? 0;
  const rowsPerPage = 10;

  useEffect(() => {
    matomoTrackPageView(`Mes_Brouillons`);
    dispatch(resetOrderData());
    dispatch(clearAddress());
  }, []);

  const fetchOrders = async () => {
    setIsLoading(true);
    setAPIError(false);
    await getOrdersPaginate({
      page: (currentFilterAndSort.currentPage + 1).toString(),
      state: 'draft',
      search: currentFilterAndSort.search,
      ...(currentFilterAndSort.from ? { from: currentFilterAndSort.from } : {}),
      ...(currentFilterAndSort.to ? { to: currentFilterAndSort.to } : {})
    })
      .then((res) => {
        setOrders(res.data);
      })
      .catch(() => {
        toast.error(t('errorMessages.errorHappen', `An error happened`));
        setOrders(undefined);
        setAPIError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateDataFromSearchInput = (newFilter: { search?: string }) => {
    setCurrentFilterAndSort({ ...newFilter, currentPage: 0 });
    searchParams.set('currentPage', `0`);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    fetchOrders();
  }, [currentFilterAndSort]);

  const deleteOrder = async (orderId: string) => {
    trackEvent({
      page: 'Brouillons',
      category: 'Brouillon',
      actionType: 'Clic',
      actionDetails: 'Confirmer_Supprimer'
    });
    const deleted = await deleteOrderById(orderId);
    if (deleted) {
      fetchOrders().catch(() => toast.error(`An error happened`));
    }
    setOrderIdToDelete(undefined);
  };

  return (
    <DraftsPageStyled>
      <OrderBetaFirstDraftModal />
      <PageHeader
        className="page-header"
        updateData={updateDataFromSearchInput}
        trackingSearchName={'Brouillon'}
        currentFilterAndSort={currentFilterAndSort}
        totalRows={totalOrders}
        allowZeroResults
        inputPlaceholder={t('pages.order.searchReference', `Search by reference, name or address`)}
        allowDateRange
        actionsDisabled={APIError}
        rightContent={
          <Button
            onClick={() => {
              trackEvent({
                page: 'Brouillons',
                category: 'Brouillon',
                actionType: 'Clic',
                actionDetails: 'Nouvelle_Commande'
              });
              navigate('/ordersBeta?no-construction=true');
            }}
          >
            {t('pages.order.newOrder', 'New order')}
          </Button>
        }
      />
      <ModalDeleteOrder
        deleteOrder={() => deleteOrder(orderIdToDelete ?? '')}
        show={!!orderIdToDelete}
        onClose={() => setOrderIdToDelete(undefined)}
      />
      <div className="container-content">
        <div className="orders">
          {isLoading && <Spinner spinnerSize={2} center />}
          {!isLoading && (
            <>
              {hasNoOrdersFound && (
                <div className="container-not-found">
                  <NoOrdersFound />
                </div>
              )}
              {APIError && (
                <div className="container-not-found">
                  <ApiError />
                </div>
              )}
            </>
          )}
          {!isLoading &&
            orders?.orders?.map((order: Order) => {
              return (
                <CardOrderContainer
                  key={order.orderId}
                  order={order}
                  toHighLight={currentFilterAndSort.search}
                  setOrderIdToDelete={setOrderIdToDelete}
                />
              );
            })}
        </div>
        <Pagination
          totalRows={totalOrders}
          parentCurrentPage={currentFilterAndSort.currentPage}
          pageChangeHandler={(e: number) => {
            setCurrentFilterAndSort({ ...currentFilterAndSort, currentPage: e });
            if (e !== 0) {
              trackEvent({
                page: 'Pagination',
                category: 'Brouillon',
                actionType: 'Chargement',
                actionDetails: 'Page',
                lastParamsNumber: e + 1
              });
            }
            searchParams.set('currentPage', `${e}`);
            setSearchParams(searchParams);
          }}
          {...{ backgroundHasBlur: true, isSticky: true }}
          rowsPerPage={rowsPerPage}
        />
      </div>
    </DraftsPageStyled>
  );
};

const DraftsPageStyled = styled.div`
  background: ${theme.palette.grey[25]};
  min-height: calc(100vh - 63px);

  .page-header {
    background: white;
    input {
      width: 370px;
    }
  }
  .orders {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: calc(100vh - 63px);
  }
  .container-content {
    margin: 12px 24px 0;
    height: 100%;
    background: ${theme.palette.grey[25]};
  }
  .container-not-found {
    margin-top: 24px;
    display: grid;
    align-items: center;
    justify-content: center;
  }
`;

export default DraftsPage;
