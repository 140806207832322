import {
  IOrderAppointmentForm,
  IOrderInformationsForm,
  ISelectedSlot
} from '../../utils/orderInterfaces';
import { StyledOrderComplementaryWrapper, StyledOrderSection } from '../../utils/OrderStyles';
import { callPatchOrder, selectOrderState, updateStepData } from '../../reducer/orderSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

import Card from '@designSystem/Card';
import { ErrorMessageWithMail } from '../../components/ToasErrorWithMail';
import { isHomeBasicProduct } from '@features/eligibility/helper';
import InformationBlock from '@designSystem/InformationBlock';
import OrderAppointmentFormDateAndSchedule from './OrderAppointmentFormDateAndSchedule';
import OrderAppointmentFormInformations from './OrderAppointmentFormInformations';
import OrderAppointmentHeader from '../OrderAppointmentHeader';
import OrderFooter from '../../components/OrderFooter';
import { OrderSteps } from '../../utils/constants';
import { concatComments } from '../../utils/OrderHelper';
import { displayToastErrorWithMessage } from '../../../../utils/toastHelper';
import { getDefaultGtrFromProduct } from '../../../eligibility/products/productDetail/OptionsForm';
import { listOfProductOptions } from '../../../eligibility/products/constants';
import { selectAdressInfos } from '../../../eligibility/eligibilitySlice';
import toast from 'react-hot-toast';
import { unwrapResult } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import { useMatomoHarmonizer } from '../../../../utils/matomoHarmonizer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMonitoring } from '../../../../utils/ErrorMonitoring';
import { DateManager } from '../../../../utils/DateManager';

const OrderAppointmentForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const addressInfos = useAppSelector(selectAdressInfos);
  const { orderFormSteps, order, productToOrder, selectedOptions } =
    useAppSelector(selectOrderState);
  const alreadyFilledInformations = orderFormSteps.steps[OrderSteps.APPOINTMENT].data;
  const clientInformations: IOrderInformationsForm =
    orderFormSteps.steps[OrderSteps.ORDER_INFO_FORM].data;

  const [selectedSlot, setSelectedSlot] = useState<ISelectedSlot | undefined>(
    alreadyFilledInformations?.selectedSlot
  );
  const [loading, setLoading] = useState<boolean>(false);

  const { register, watch, formState } = useForm<IOrderAppointmentForm>({
    mode: 'onChange',
    defaultValues: {
      appointmentComments: alreadyFilledInformations?.appointmentComments || ''
    }
  });

  const appointmentComments = watch('appointmentComments');

  const trackEvent = useMatomoHarmonizer();

  const nextStep = () => {
    if (order && order.id) {
      if (appointmentComments?.length) {
        trackEvent({
          page: 'Commandes',
          category: 'Rendez_vous',
          actionType: 'Saisie',
          actionDetails: 'Commentaire'
        });
      }

      const comment = concatComments(
        clientInformations.clientType,
        clientInformations?.complementaryOutlet || false,
        clientInformations.outlet,
        clientInformations?.businessName,
        clientInformations.comments,
        appointmentComments
      );
      dispatch(
        callPatchOrder({
          ...clientInformations,
          id: order.id,
          address: addressInfos,
          comments: comment,
          productOfferingId: productToOrder?.product.productOffering.id,
          options: {
            eqt: selectedOptions?.eqt || undefined,
            gtr:
              (productToOrder &&
                listOfProductOptions[
                  selectedOptions?.gtr || getDefaultGtrFromProduct(productToOrder)
                ]) ||
              ''
          }
        })
      )
        .then(unwrapResult)
        .then(() => {
          dispatch(
            updateStepData({
              step: OrderSteps.APPOINTMENT,
              data: {
                selectedSlot: {
                  ...selectedSlot,
                  day: new DateManager(selectedSlot?.day).toString()
                },
                appointmentComments
              },
              nextStep: true
            })
          );
        })
        .catch((error) => {
          setLoading(false);
          displayToastErrorWithMessage(
            t('errorMessages.wentWrong', `Something went wrong`),
            <ErrorMessageWithMail />
          );
          new ErrorMonitoring().captureException(Error('PATCH order exception'), {
            extra: error
          });
        });
    } else {
      setLoading(false);
      toast.error('An error happened');
      new ErrorMonitoring().captureException(
        Error('PATCH order exception: There is no order or order.id')
      );
    }
  };

  const isHomeBasic = isHomeBasicProduct(productToOrder);
  return (
    <Card
      header={<OrderAppointmentHeader />}
      footer={
        <OrderFooter
          handleNextStep={() => {
            setLoading(true);
            nextStep();
          }}
          previousStep={OrderSteps.ORDER_INFO_FORM}
          disabled={!selectedSlot?.id}
          loading={loading}
        />
      }
    >
      <StyledOrderSection>
        <div className="section-title">
          {t('features.order.orderAppointment.taking', 'Taking an appointment')}
        </div>
        {isHomeBasic && (
          <InformationBlock
            title={t(
              'features.order.orderAppointment.appointment.cap',
              'For this product, available appointments are between D+7 and up to D+60'
            )}
            className="section_info mt-4"
            style={{ marginBottom: 15 }}
          />
        )}
        <StyledOrderComplementaryWrapper>
          <OrderAppointmentFormDateAndSchedule
            setSelectedSlot={setSelectedSlot}
            selectedSlot={selectedSlot}
            maxDays={isHomeBasic ? 60 : Infinity}
          />

          {!isHomeBasic && (
            <OrderAppointmentFormInformations
              appointmentComments={appointmentComments}
              register={register}
              formState={formState}
            />
          )}
        </StyledOrderComplementaryWrapper>
        <InformationBlock
          style={{ margin: `35px 0 35px 0` }}
          type="warning"
          title={t(
            'features.order.orderAppointment.appointment.next',
            'Your appointment will only be reserved once the order submitted (next step)'
          )}
          className="section_info mt-4"
        />
      </StyledOrderSection>
    </Card>
  );
};

export default OrderAppointmentForm;
