import { Column, useSortBy, useTable } from 'react-table';
import React, { ReactChild, useEffect, useState } from 'react';
import { StyledContainer, StyledCustomCard } from './ResultsStyles';

import { IOfferingResult } from '../../interfaces';
import ResultsHeader from './ResultsHeader';
import ResultsItem from './ResultsItem';
import Spinner from '@designSystem/Spinner';
import { useExportData } from 'react-table-plugins';
import { useMatomoHarmonizer } from '../../../../utils/matomoHarmonizer';

const Results: React.FC<{
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  columns: Column<IOfferingResult>[] | any;
  data: IOfferingResult[];
  setSelectedProduct: CallableFunction;
  selectedProduct: IOfferingResult | null;
  loading: boolean;
  exportFile?: ({ _columns, _data }: any) => boolean;
  noDataDisplay?: ReactChild | null;
  displayVerticality?: CallableFunction;
  onShowPopOver?: CallableFunction;
}> = ({
  columns,
  data,
  setSelectedProduct,
  selectedProduct,
  loading,
  exportFile,
  noDataDisplay = null,
  displayVerticality,
  onShowPopOver
}) => {
  const [showPopOver, setShowPopOver] = useState<string | false>(false);
  const trackEvent = useMatomoHarmonizer();

  useEffect(() => {
    setShowPopOver(false);
  }, [selectedProduct]);

  useEffect(() => {
    if (showPopOver && onShowPopOver) {
      onShowPopOver();
    }
  }, [showPopOver]);

  const TABLE_INITIALSTATE = {
    sortBy: [{ id: 'qualificationItemResult', desc: false }]
  };

  const { exportData } = useTable(
    {
      columns: columns || [],
      data: data || [],
      initialState: TABLE_INITIALSTATE,
      getExportFileBlob: exportFile
    },
    useSortBy,
    useExportData
  );

  const needStudy = data[0]?.qualificationItemResult?.toUpperCase() === 'TO_BE_STUDIED';
  useEffect(() => {
    if (noDataDisplay) {
      trackEvent({
        page: 'Commandes',
        category: 'Choix_site',
        actionType: 'Recherche',
        actionDetails: 'Adresse_Non_Eligible'
      });
    }
  }, []);
  return (
    <>
      <ResultsHeader
        needStudy={needStudy}
        exportData={exportData}
        dataLength={data.length}
        loading={loading}
        displayVerticality={displayVerticality}
      />
      {loading ? (
        <Spinner spinnerSize={2} center />
      ) : (
        <StyledContainer>
          {data.map((row: IOfferingResult) => {
            return (
              <StyledCustomCard
                key={row.id}
                data-testid={`product_${row.productOffering.name.replaceAll(' ', '-')}`}
                selected={
                  !!selectedProduct &&
                  selectedProduct.product.productOffering.id === row.product.productOffering.id
                }
                onClick={() => {
                  setSelectedProduct(row);
                }}
              >
                <ResultsItem
                  key={row.id}
                  row={row}
                  showPopOver={showPopOver}
                  setShowPopOver={setShowPopOver}
                />
              </StyledCustomCard>
            );
          })}
        </StyledContainer>
      )}
      {noDataDisplay}
      <br />
    </>
  );
};

export default Results;
