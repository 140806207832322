import { Card, Col, Row } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import { ErdvZoneGeographicTypes, IZoneGeoForm } from '../utils/interfaces';
import { RootStateOrAny, useSelector } from 'react-redux';

import FixedSpinner from '../../../components/FixedSpinner';
import InputWrapper from '@designSystem/InputWrapper';
import React from 'react';
import ReactSelect from 'react-select';
import ZoneGeoFormInfos from './ZoneGeoFormInfos';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const StyledCardBody = styled(Card.Body)`
  padding: 24px 24px;
  color: ${theme.palette.grey[600]};
  font-size: ${theme.fonts.size.sm};
`;

const StyledZoneGeoInputsCustomRow = styled(Row)`
  margin-top: 1.5rem;
`;

const ZoneGeoFormInputs: React.FC<{
  register: CallableFunction;
  control: Control<IZoneGeoForm>;
  formValues: IZoneGeoForm;
  dspSelectList: string[];
}> = ({ control, register, formValues, dspSelectList }) => {
  const { departementAndDSPLoading } = useSelector((state: RootStateOrAny) => state.profile);
  const { t } = useTranslation();
  const noDspOption = () => {
    if (departementAndDSPLoading) {
      return (
        <div style={{ height: 30 }}>
          <FixedSpinner loading />
        </div>
      );
    }
    return t('features.zonesGeo.zoneGeoForm.noDsp', 'No DSP found');
  };

  return (
    <StyledCardBody>
      <ZoneGeoFormInfos />
      <StyledZoneGeoInputsCustomRow>
        <Col md={6} sm={12}>
          <InputWrapper
            inputLabel={t('features.zonesGeo.zoneGeoForm.form.geographicZone', 'Geographic zone')}
          >
            <input
              type="text"
              id="zoneGeoName"
              {...register('zoneGeoName')}
              value={formValues.zoneGeoName}
              aria-label="zoneGeoName-input"
              placeholder={t('features.zonesGeo.zoneGeoForm.geoZone', `Geographic zone`)}
            />
          </InputWrapper>
        </Col>

        <Col md={6} sm={12}>
          <Controller
            name="dsp"
            control={control}
            render={({ field }) => (
              <InputWrapper inputLabel={t('features.zonesGeo.zoneGeoForm.form.dsp', 'DSP')}>
                <ReactSelect
                  {...field}
                  aria-label="dsp-select"
                  inputId="dsp"
                  defaultValue={null}
                  options={dspSelectList.map((dsp: string) => {
                    return { value: dsp, label: dsp };
                  })}
                  value={formValues.dsp}
                  isDisabled={dspSelectList.length === 1}
                  placeholder={''}
                  noOptionsMessage={noDspOption}
                />
              </InputWrapper>
            )}
          />
        </Col>
      </StyledZoneGeoInputsCustomRow>

      <StyledZoneGeoInputsCustomRow>
        <Col md={6} sm={12}>
          <Controller
            name="zoneGeoType"
            control={control}
            render={({ field }) => (
              <InputWrapper inputLabel={t('features.zonesGeo.zoneGeoForm.form.type', 'Type')}>
                <ReactSelect
                  {...field}
                  aria-label="zoneGeoType-select"
                  inputId="zoneGeoType"
                  defaultValue={undefined}
                  options={Object.keys(ErdvZoneGeographicTypes).map(
                    (ErdvZoneGeographicTypeKey: string) => {
                      return {
                        value: ErdvZoneGeographicTypeKey,
                        label: ErdvZoneGeographicTypeKey
                      };
                    }
                  )}
                  value={formValues.zoneGeoType}
                  placeholder={''}
                />
              </InputWrapper>
            )}
          />
        </Col>

        <Col md={6} sm={12}>
          <InputWrapper
            inputLabel={t(
              'features.zonesGeo.zoneGeoForm.form.usualAppointment',
              'Usual appointment delay(working days)'
            )}
          >
            <input
              type="number"
              min="0"
              id="contractDelay"
              {...register('contractDelay')}
              value={formValues.contractDelay}
              aria-label="contractDelay-input"
              placeholder={'7'}
            />
          </InputWrapper>
        </Col>
      </StyledZoneGeoInputsCustomRow>
    </StyledCardBody>
  );
};

export default ZoneGeoFormInputs;
