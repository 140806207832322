import theme, { ThemeVariants } from '@theme';

import Badge from '@designSystem/Badges';
import { ChevronRight } from 'react-bootstrap-icons';
import { EscalationTrad } from './const';
import Icon from '@designSystem/Icon';
import { Level } from './escalateTypes';
import styled from 'styled-components';

interface LevelContactCardProps {
  level: Level;
}

export const LevelContactCard = ({ level }: LevelContactCardProps) => {
  return (
    <CardBaseListStyled>
      <CardListStyled style={{ cursor: 'pointer', height: 40 }}>
        <div className="info">
          <div>
            <Badge
              style={{ fontSize: '11px', padding: '2px 6px', lineHeight: 'initial' }}
              variant={
                (['green', 'yellow', 'orange', 'red', 'purple'][level.priority] ||
                  'grey') as ThemeVariants
              }
            >
              {level.name && (EscalationTrad()[level.name] || level.name)}
            </Badge>
          </div>

          <div style={{ fontSize: '11px', color: '#6B7280' }}>{level.role}</div>

          <div>
            <Badge
              style={{ fontSize: '11px', padding: '2px 6px', lineHeight: 'initial' }}
              variant="green"
            >
              {level.schedule && (EscalationTrad()[level.schedule] || level.schedule)}
            </Badge>
          </div>

          <div style={{ textAlign: 'end' }}>
            <Icon className="icon-link" size="small" variant="blue" iconSize="10px">
              <ChevronRight />
            </Icon>
          </div>
        </div>
      </CardListStyled>
    </CardBaseListStyled>
  );
};

const CardListStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${theme.fonts.size.xs};
  font-weight: ${theme.fonts.weight.medium};
  line-height: ${theme.fonts.spacing.xs};
  padding: 10px 24px;
  .info {
    display: grid;
    gap: 1rem;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 0.5fr;
  }
`;

const CardBaseListStyled = styled.div`
  background: #ffffff;
  padding: 10px 24px;
  border: 1px solid ${theme.palette.grey[100]};
  border-top: 0;
  min-height: 62px;
  &:hover {
    background: ${theme.palette.blue[50]};
  }
`;

export const CardBaseListHeader = styled.div`
  background: ${theme.palette.grey[100]};
  color: ${theme.palette.grey[500]};
  font-size: 12px;
  font-weight: ${theme.fonts.weight.medium};
  line-height: ${theme.fonts.spacing.xs};
  text-transform: uppercase;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  padding: 4px 55px;
  border-radius: 9px 9px 0 0;
  border: 1px solid ${theme.palette.grey[100]};
`;
