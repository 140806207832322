import { ArrowDownUp, CalendarMinus, Info } from 'react-bootstrap-icons';
import {
  StyledOfferProductIcon,
  StyledOfferProductInfoIconContainer,
  StyledPopOverItemContainer,
  StyledPriceGreyCard,
  StyledProductDetails,
  StyledProductLeadTime
} from './ResultsStyles';
import {
  getQualificationItemBillingZone,
  getQualificationItemDelay,
  makeAvailableDateBadge,
  makeUnavailabilityReasonBadge
} from '../../helper';

import Badge from '@designSystem/Badges';
import { IOfferingResult } from '../../interfaces';
import Icon from '@designSystem/Icon';
import PopOver from '@designSystem/PopOver';
import ProductPriceAndFees from '../productDetail/ProductPriceAndFees';
import React from 'react';
import { billingZoneMap } from '../constants';
import theme from '@theme';
import { useGuardHook } from '../../../../utils/FeatureFlag';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../../../utils/matomoHarmonizer';

const ResultsItem: React.FC<{
  row: IOfferingResult;
  showPopOver: string | false;
  setShowPopOver: CallableFunction;
}> = ({ setShowPopOver, showPopOver, row }) => {
  const billingZone = getQualificationItemBillingZone(row);
  const { t } = useTranslation();
  const canDisplayPrice = useGuardHook('display_product_price');

  const needStudy = row.qualificationItemResult?.toUpperCase() === 'TO_BE_STUDIED';
  const shouldDisplayPriceAndFees = !!row.product.productPrice?.length;
  const trackEvent = useMatomoHarmonizer();

  const displayPopOver = () => {
    if (showPopOver && row.product.productOffering.id === showPopOver) {
      trackEvent({
        page: 'Commandes',
        category: 'Choix_produit',
        actionType: 'Clic',
        actionDetails: 'InfoBulle'
      });
      return (
        <PopOver vertical="bottom" horizontal="left">
          {row.product?.productOffering?.description
            ?.split('\n')
            .map((productDescriptionItem: string) => {
              return (
                <StyledPopOverItemContainer key={productDescriptionItem}>
                  {productDescriptionItem}
                </StyledPopOverItemContainer>
              );
            })}
        </PopOver>
      );
    }
    return null;
  };
  return (
    <>
      {makeUnavailabilityReasonBadge(row)}
      {makeAvailableDateBadge(row)}
      {billingZone && (
        <Badge
          variant={billingZoneMap[billingZone]?.badgeVariant || 'blue'}
          style={{ marginLeft: '6px' }}
        >
          Zone {billingZone.toLowerCase()}
        </Badge>
      )}
      <StyledProductDetails>
        <div style={{ display: 'flex' }}>
          <StyledOfferProductIcon>
            <Icon iconSize="1rem" round>
              <ArrowDownUp />
            </Icon>
          </StyledOfferProductIcon>
          <div style={{ paddingRight: 12 }}>{row.product.productOffering.name}</div>
        </div>

        <StyledOfferProductInfoIconContainer>
          <Icon
            background
            round
            variant={'blue'}
            size="minimal"
            variantDeclination={500}
            className={'clickable'}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();

              const newPopOverValue =
                showPopOver === row.product.productOffering.id
                  ? false
                  : row.product.productOffering.id;
              setShowPopOver(newPopOverValue);
            }}
            style={{ position: 'relative' }}
          >
            <Info />
            {displayPopOver()}
          </Icon>
        </StyledOfferProductInfoIconContainer>
      </StyledProductDetails>
      {row.leadTime && (
        <StyledProductLeadTime>
          <div style={{ marginRight: 4 }}>
            <CalendarMinus size={16} color={theme.palette.grey[400]} />
          </div>
          {t('features.eligibility.products.results.leadTime', 'Lead time')}{' '}
          {getQualificationItemDelay(row)}
        </StyledProductLeadTime>
      )}
      {canDisplayPrice && (shouldDisplayPriceAndFees || needStudy) && (
        <StyledPriceGreyCard>
          <ProductPriceAndFees product={row} />
        </StyledPriceGreyCard>
      )}
    </>
  );
};

export default ResultsItem;
