import { Calendar2 } from 'react-bootstrap-icons';
import InputWrapper from '@designSystem/InputWrapper';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DateManager } from '../../../../utils/DateManager';

interface IOrderAppointmentFormDateInputProps {
  selectedDate: string;
  popOver: React.ReactNode;
  setIsPopOverShown: (value: boolean) => void;
}

const OrderAppointmentFormDateInput: React.FC<IOrderAppointmentFormDateInputProps> = ({
  selectedDate,
  popOver = null,
  setIsPopOverShown
}: IOrderAppointmentFormDateInputProps) => {
  const { t } = useTranslation();

  const handleDateClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsPopOverShown(true);
  };

  return (
    <StyledOrderAppointmentInputWrapper>
      <div className="relative_wrapper">
        <InputWrapper
          inputLabel={t('features.order.orderAppointment.orderAppointmentForm.date', 'Date')}
          icon={<Calendar2 />}
        >
          <input
            type="text"
            id="selectedDate"
            data-testid="selectedDate-input"
            placeholder={t('features.order.orderAppointment.orderAppointmentForm.date', 'Date')}
            readOnly
            value={!selectedDate ? '' : new DateManager(selectedDate).format('DD-MM-YYYY')}
            aria-label="selectedDate-input"
            className="clickable"
            style={{ padding: '10px 10px 10px 35px' }}
            onClick={handleDateClick}
          />
        </InputWrapper>
        {popOver}
      </div>
    </StyledOrderAppointmentInputWrapper>
  );
};

export default OrderAppointmentFormDateInput;

const StyledOrderAppointmentInputWrapper = styled.div`
  width: 100%;
  max-width: 100%;

  .relative_wrapper {
    position: relative;
  }
`;
