import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect, { CSSObjectWithLabel } from 'react-select';
import styled from 'styled-components';

import InputWrapper from '@designSystem/InputWrapper';
import { ErrorMessage, StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import { selectAdressInfos } from '@features/eligibility/eligibilitySlice';
import { StyledOrderOutletWrapper } from '@features/order/utils/OrderStyles';
import { siretAbsenceReasonsList } from '@features/order/utils/constants';
import {
  IOrderInformationsOperaBusinessForm,
  Order,
  OrderOperaBusiness
} from '@features/order/utils/orderInterfaces';

import { useAppSelector } from '../../../../app/hooks';
import { getErrorMessage, isFieldErrored } from '../../../../utils/formHelper';
import { buildReactSelectStyled } from '../../../../utils/styled';
import SiretDropdown from './SiretInput';
import SirenInput from './SirenInput';

interface IOrderInformationsClientCompanyHomeBusinessForm {
  orderInformationsFormValues: IOrderInformationsOperaBusinessForm;
  order: Order | OrderOperaBusiness | undefined;
}

const OrderInformationsClientCompanyHomeBusinessForm: React.FC<
  IOrderInformationsClientCompanyHomeBusinessForm
> = ({ orderInformationsFormValues, order }) => {
  const { register, formState, clearErrors, watch, setValue } = useFormContext();
  const { errors } = formState;
  const { t } = useTranslation();
  const SIRET_LENGTH = 14;
  const siretValue = watch('siret');
  const reasonMissingSiret = watch('siretAbsenceReason');
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  }>({ label: '', value: '' });
  const [isMissingSIRET, setIsMissingSIRET] = useState<boolean>(false);
  const addressInfos = useAppSelector(selectAdressInfos);

  const companies = addressInfos?.organizations?.map((organization) => ({
    siret: organization.siret,
    name: organization.name,
    siren: organization.siren
  }));

  useEffect(() => {
    if (reasonMissingSiret) {
      setIsMissingSIRET(true);
    }
    if (orderInformationsFormValues.isSiretMandatory === false) {
      setIsMissingSIRET(true);
      setValue('siretAbsenceReason', orderInformationsFormValues.siretAbsenceReason);
    }
  }, [reasonMissingSiret, orderInformationsFormValues.isSiretMandatory]);

  const defineSiretToRegistration = () => {
    setValue('siretAbsenceReason', "Entreprise en cours d'immatriculation");
    setValue('siret', null);
    setValue('businessName', null);
    setValue('isSiretMandatory', false);
    clearErrors('siret');
    clearErrors('siren');

    clearErrors('businessName');
    setIsMissingSIRET(true);
  };

  const setWithSiret = () => {
    setValue('siret', '');
    clearErrors('siret');

    setValue('siren', '');
    clearErrors('siren');
    clearErrors('businessName');
    setSelectedOption({ label: '', value: '' });
    setValue('siretAbsenceReason', '');
    setIsMissingSIRET(false);
  };

  const setToMissingSiret = () => {
    setValue('siret', '');
    setValue('siren', '');
    clearErrors('siret');
    clearErrors('businessName');
    setSelectedOption({ label: '', value: '' });
    setValue('siretAbsenceReason', '');
    setIsMissingSIRET(true);
  };
  return (
    <Container>
      <StyledOrderOutletWrapper>
        <div className="no-gap">
          <StyledLabel className="section-label-question">
            {t(
              'features.order.orderInformations.siret.question',
              "Votre client final dispose-t-il d'un SIRET ?"
            )}
          </StyledLabel>
          <div className="radio-container">
            <input
              onChange={setWithSiret}
              type="radio"
              id="is_siret_true"
              name="is_siret"
              checked={!isMissingSIRET}
            />
            <label htmlFor="is_siret_true">{t('app.yes', 'Yes')}</label>
          </div>
          <div className="radio-container">
            <input
              onChange={setToMissingSiret}
              type="radio"
              id="is_siret_false"
              name="is_siret"
              value="false"
              checked={isMissingSIRET}
            />
            <label htmlFor="is_siret_false">{t('app.no', 'No')}</label>
          </div>
        </div>
      </StyledOrderOutletWrapper>

      {isMissingSIRET && (
        <div className="information">
          <Controller
            name={'siretAbsenceReason'}
            rules={{
              required: isMissingSIRET
            }}
            render={({ field: { onChange } }) => (
              <InputWrapper
                inputLabel={t(
                  'features.order.orderInformations.siretAbsenceReason_label',
                  'Reason for absence of Siret'
                )}
              >
                <ReactSelect
                  styles={{
                    control: (baseStyles: CSSObjectWithLabel) =>
                      buildReactSelectStyled(baseStyles, { fontSize: 14 }),
                    option: (baseStyles: CSSObjectWithLabel) =>
                      buildReactSelectStyled(baseStyles, { fontSize: 14 }),
                    singleValue: (baseStyles: CSSObjectWithLabel) =>
                      buildReactSelectStyled(baseStyles, { fontSize: 14 })
                  }}
                  inputId="siretAbsenceReason"
                  options={siretAbsenceReasonsList(t)}
                  value={{
                    value: orderInformationsFormValues.siretAbsenceReason,
                    label: siretAbsenceReasonsList(t).find(
                      (reason) => reason.value === orderInformationsFormValues.siretAbsenceReason
                    )?.label
                  }}
                  onChange={(val: any) => {
                    onChange(val.value);
                    setValue('siren', null);
                    clearErrors('siren');
                  }}
                />
              </InputWrapper>
            )}
          />
          {isFieldErrored('siretAbsenceReason', errors) && (
            <ErrorMessage>{getErrorMessage('siretAbsenceReason', formState)}</ErrorMessage>
          )}
        </div>
      )}
      {reasonMissingSiret ===
        t(
          'features.order.orderInformations.siretAbsenceReasons.establishmentInProcessOfRegistration'
        ) && (
        <InputWrapper
          inputLabel={t('features.order.orderInformations.siren', 'SIREN')}
          error={getErrorMessage('siren', formState)}
        >
          <SirenInput
            SIREN_LENGTH={9}
            register={register}
            defineSiretToRegistration={defineSiretToRegistration}
            setValue={setValue}
            companies={companies}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            sirenValue={siretValue}
            order={order}
          />
        </InputWrapper>
      )}

      <div style={{ display: 'flex', gap: 20 }}>
        {!isMissingSIRET && (
          <div style={{ flex: 1, width: '100%' }}>
            <InputWrapper
              inputLabel={t('features.order.orderInformations.siret', 'SIRET')}
              error={getErrorMessage('siret', formState)}
            >
              <SiretDropdown
                SIRET_LENGTH={SIRET_LENGTH}
                register={register}
                defineSiretToRegistration={defineSiretToRegistration}
                setValue={setValue}
                companies={companies}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                siretValue={siretValue}
                isSiretMandatory={!isMissingSIRET}
                order={order}
              />
            </InputWrapper>
          </div>
        )}
        <div style={{ flex: 1, width: '100%' }}>
          <InputWrapper
            inputLabel={t('features.order.orderInformations.businessName', 'Business name')}
            error={getErrorMessage('businessName', formState)}
          >
            <input
              {...register('businessName', {
                required: { value: true, message: t('errorMessages.missingVal', `Missing value`) },
                maxLength: {
                  value: 64,
                  message: t(
                    'errorMessages.tooManyCharactersEntered',
                    'Maximum number of 64 characters exceeded',
                    {
                      defaultValue: '{{count}}',
                      count: 64
                    }
                  )
                }
              })}
              data-testid="order-input-businessName"
              id="businessName"
              placeholder={t('features.order.orderInformations.businessName', `Business name`)}
              value={orderInformationsFormValues.businessName}
              style={{ marginBottom: '10px' }}
            />
          </InputWrapper>
        </div>
      </div>
    </Container>
  );
};

export default OrderInformationsClientCompanyHomeBusinessForm;

const Container = styled.div`
  display: grid;
  grid-gap: 16px;

  .information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    margin-bottom: 8px;
  }
`;
