import React, { useEffect } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import AppointmentToast from './AppointmentToast';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const AppointmentListAlerts: React.FC = () => {
  const { bookAppointmentError, bookedAppointment, calendarSchedulesError } = useSelector(
    (state: RootStateOrAny) => state.appointment
  );
  const { t } = useTranslation();
  const constructBookedAppointmentMessage = () => {
    return (
      <>
        {t('features.appointment.bookedId', 'Appointment booked with ID :')}
        {bookedAppointment.id}
      </>
    );
  };

  useEffect(() => {
    if (bookAppointmentError) {
      toast((t) => {
        t.type = 'error';
        t.className = 'toast-top-right';

        return (
          <AppointmentToast
            //Todo
            title="Appointment booking Failed"
            // title={t('features.appointment.bookedFailed', 'Appointment booking Failed')}
            message={JSON.stringify(bookAppointmentError)}
          />
        );
      });
    }
    if (calendarSchedulesError) {
      toast((t) => {
        t.type = 'error';
        t.className = 'toast-top-right';

        return (
          <AppointmentToast
            //TODO
            title="Could not retrieve calendar options"
            // title={t(
            //   'features.appointment.bookedCalendarFailed',
            //   'Could not retrieve calendar options'
            // )}
            message={JSON.stringify(calendarSchedulesError)}
          />
        );
      });
    }
    if (bookedAppointment) {
      toast((t) => {
        t.type = 'success';
        t.className = 'toast-top-right';

        return (
          <AppointmentToast
            //TODO
            title="Appointment booked"
            // title={t('features.appointment.booked', 'Appointment booked')}
            message={constructBookedAppointmentMessage()}
          />
        );
      });
    }
  }, [bookAppointmentError, calendarSchedulesError, bookedAppointment]);

  return null;
};

export default AppointmentListAlerts;
