import {
  StyledOrderRecapConfirmModalContainer,
  StyledOrderRecapConfirmModalDescription,
  StyledOrderRecapConfirmModalTitle
} from '../../../order/orderRecap/confirmModals/OrderRecapConfirmModalStyles';

import FakeProgressBar from '../../../../components/FakeProgressBar';
import { useTranslation } from 'react-i18next';

const OrderInfoPanelModalLoading = ({
  isQuotation,
  speed
}: {
  isQuotation: boolean;
  speed: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <StyledOrderRecapConfirmModalContainer>
      <FakeProgressBar time={speed ? 2000 : 45000} />
      <StyledOrderRecapConfirmModalTitle>
        {isQuotation
          ? t(
              'features.eligibility.products.orderInfoPanel.quotationInProgress',
              'Quotation project creation in progress'
            )
          : t(
              'features.eligibility.products.orderInfoPanel.orderInProgress',
              'Order project creation in progress'
            )}
      </StyledOrderRecapConfirmModalTitle>
      <StyledOrderRecapConfirmModalDescription>
        {t(
          'features.eligibility.products.orderInfoPanel.redirected',
          "Don't leave you will be redirected, It can take up to one minute"
        )}
      </StyledOrderRecapConfirmModalDescription>
    </StyledOrderRecapConfirmModalContainer>
  );
};

export default OrderInfoPanelModalLoading;
