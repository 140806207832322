import { IOrderCreationOperaBusinessParams, IOrderCreationParams } from '../utils/orderInterfaces';
import { EnvelopeFill, TelephoneFill } from 'react-bootstrap-icons';
import { StyledOrderClientWrapper, StyledOrderSection } from '../utils/OrderStyles';

import InputWrapper from '@designSystem/InputWrapper';
import Tooltip from '@designSystem/Tooltip';
import { getErrorMessage } from '../../../utils/formHelper';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const NAME_REGEX = /^[a-zA-Z\u00C0-\u017F '-]+$/;
const PHONE_REGEX = /^(?!00)(\+)?\d{10,14}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]{2,}\.[a-z]{2,4}$/;

const OrderInformationsClientForm = <
  T extends IOrderCreationParams | IOrderCreationOperaBusinessParams
>({
  orderInformationsFormValues,
  isHomeOrOfficeProduct = false,
  isHomeBasicProduct = false,
  isBusiness = false
}: {
  orderInformationsFormValues: T;
  isHomeOrOfficeProduct?: boolean;
  isHomeBasicProduct?: boolean;
  isBusiness?: boolean;
}) => {
  const { register, formState, watch, trigger } = useFormContext();
  const { t } = useTranslation();

  const lastName = watch('lastName');
  const firstName = watch('firstName');

  const validateCombinedLength = () => {
    if (!isHomeBasicProduct && !isBusiness) {
      return true;
    }
    const totalLength = (lastName?.trim().length || 0) + (firstName?.trim().length || 0);
    return (
      totalLength <= (isBusiness ? 25 : 40) ||
      t(
        'errorMessages.combinedLengthExceeded',
        `Le nombre maximum de ${
          isBusiness ? '25' : '40'
        } caractères pour les champs Nom et Prénom est dépassé.`
      )
    );
  };

  useEffect(() => {
    if (lastName) {
      trigger(['lastName']);
    }
    if (firstName) {
      trigger(['firstName']);
    }
  }, [lastName, firstName, trigger]);

  return (
    <StyledOrderSection>
      <StyledOrderClientWrapper>
        <div>
          <InputWrapper
            inputLabel={t('features.order.orderInformations.lastName', 'Lastname')}
            error={getErrorMessage('lastName', formState)}
          >
            <input
              type="text"
              id="lastName"
              data-testid="order-input-lastName"
              {...register('lastName', {
                setValueAs: (v) => v?.trimStart(),
                required: {
                  value: true,
                  message: t('errorMessages.valueMissing', `Missing value`)
                },
                validate: {
                  nameValidation: (value) =>
                    NAME_REGEX.test(value) || t('errorMessages.invalid', 'Invalid format')
                }
              })}
              placeholder={t('features.order.orderInformations.lastName', `Lastname`)}
              value={orderInformationsFormValues.lastName}
              aria-label="lastName-input"
            />
          </InputWrapper>
        </div>
        <div>
          <InputWrapper
            inputLabel={t('features.order.orderInformations.firstname', 'Firstname')}
            error={getErrorMessage('firstName', formState)}
          >
            <input
              type="text"
              id="firstName"
              data-testid="order-input-firstName"
              {...register('firstName', {
                setValueAs: (v) => v?.trimStart(),
                required: {
                  value: true,
                  message: t('errorMessages.valueMissing', `Missing value`)
                },
                validate: {
                  nameValidation: (value) =>
                    NAME_REGEX.test(value) || t('errorMessages.invalid', 'Invalid format'),
                  combinedLength: validateCombinedLength
                }
              })}
              placeholder={t('features.order.orderInformations.firstname', `Firstname`)}
              value={orderInformationsFormValues.firstName}
              aria-label="firstName-input"
            />
          </InputWrapper>
        </div>
        <div>
          <InputWrapper
            inputLabel={t('features.order.orderInformations.phone', 'Phone contact n°1')}
            error={getErrorMessage('phoneContact1', formState)}
            icon={<TelephoneFill />}
          >
            <input
              type="text"
              id="phoneContact1"
              data-testid="order-input-phoneContact1"
              {...register('phoneContact1', {
                required: {
                  value: true,
                  message: t('errorMessages.valueMissing', `Missing value`)
                },
                setValueAs: (v) => v?.replace(/[ ,-]/gm, ''),
                pattern: {
                  value: PHONE_REGEX,
                  message: t('errorMessages.invalid', `Invalid format`)
                },
                validate: (value) =>
                  PHONE_REGEX.test(value) || t('errorMessages.invalid', `Invalid format`)
              })}
              placeholder={t('features.order.orderInformations.phone1', `Phone contact n°1`)}
              value={orderInformationsFormValues.phoneContact1}
              aria-label="phoneContact1-input"
            />
          </InputWrapper>
        </div>
        <div>
          <InputWrapper
            inputLabel={
              <>
                {t('features.order.orderInformations.phoneTwo', 'Phone contact n°2')}
                <div className="optional">
                  {t('features.order.orderInformations.optional', '(optional)')}
                </div>
                <Tooltip direction="bottom" max="175px">
                  {t(
                    'features.order.orderInformations.fillOut',
                    'Fill out a second client phone number'
                  )}
                </Tooltip>
              </>
            }
            error={getErrorMessage('phoneContact2', formState)}
            icon={<TelephoneFill />}
          >
            <input
              type="text"
              id="phoneContact2"
              {...register('phoneContact2', {
                setValueAs: (v) => v?.replace(/[ ,-]/gm, ''),
                pattern: {
                  value: PHONE_REGEX,
                  message: t('errorMessages.invalid', `Invalid format`)
                },
                validate: (value) =>
                  !value ||
                  !value.length ||
                  PHONE_REGEX.test(value) ||
                  t('errorMessages.invalid', `Invalid format`)
              })}
              placeholder={t('features.order.orderInformations.phone2', `Phone contact n°2`)}
              value={orderInformationsFormValues.phoneContact2}
              aria-label="phoneContact2-input"
            />
          </InputWrapper>
        </div>
        <div>
          <InputWrapper
            inputLabel={
              <>
                {t('features.order.orderInformations.email', 'Email')}
                {!isHomeOrOfficeProduct && (
                  <div className="optional">{t('features.order.orderInformations.optional')}</div>
                )}
              </>
            }
            error={getErrorMessage('email', formState)}
            icon={<EnvelopeFill />}
          >
            <input
              type="text"
              id="email"
              data-testid="order-input-email"
              {...register('email', {
                validate: (value) =>
                  !value ||
                  !value.length ||
                  EMAIL_REGEX.test(value) ||
                  t('errorMessages.invalid', `Invalid format`),
                required: {
                  value: isHomeOrOfficeProduct,
                  message: t('errorMessages.valueMissing', `Missing value`)
                }
              })}
              placeholder={t('features.order.orderInformations.email', `Email`)}
              value={orderInformationsFormValues.email}
              aria-label="email-input"
            />
          </InputWrapper>
        </div>
      </StyledOrderClientWrapper>
    </StyledOrderSection>
  );
};

export default OrderInformationsClientForm;
