import { Card, Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  bookAppointment,
  loadTimeSlots,
  resetAppointmentData,
  resetCalendar
} from './reducer/appointmentSlice';

import { Appointment } from './utils/interfaces';
import { ArrowLeftCircle } from 'react-bootstrap-icons';
import Button from '@designSystem/Button';
import DatePicker from './utils/datePicker';
import { Role } from '../../utils/role.types';
import Spinner from '@designSystem/Spinner';
import { isPermitted } from '../../app/AuthService';
import { useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateManager } from '../../utils/DateManager';

const MakeAppointmentBody: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);
  const {
    calendar,
    timeSlotsLoading,
    timeSlots,
    bookAppointmentLoading,
    calendarSchedulesLoading
  } = useSelector((state: RootStateOrAny) => state.appointment);

  const [choosedTimeSlot, setChoosedTimeSlot] = useState<Appointment | undefined>(undefined);
  const [dispatchedAppointment, setDispatchedAppointment] = useState<boolean>(false);
  const bookChoosedTimeSlot = (slot: Appointment) => {
    dispatch(resetAppointmentData());
    dispatch(bookAppointment({ slotId: slot.id, fai: calendar.fai }));
    setDispatchedAppointment(true);
    navigate('/erdv/appointment/list');
    returnOnCalendarForm();
  };

  const updateTimeSlots = (month: string) => {
    dispatch(
      loadTimeSlots({
        id_calendar: calendar.id,
        start_day: new DateManager(month).startOf('month').format('YYYY-MM-DD'),
        end_day: new DateManager(month).endOf('month').format('YYYY-MM-DD')
      })
    );
  };

  const returnOnCalendarForm = () => {
    dispatch(resetCalendar());
  };

  useEffect(() => {
    updateTimeSlots(new DateManager().startOf('month').format('YYYY-MM-DD'));
  }, []);

  useEffect(() => {
    if (!calendar.id) {
      navigate('/erdv/appointment/create/step1');
    }
  }, [calendar]);

  const getCTAContent = () => {
    if (bookAppointmentLoading || calendarSchedulesLoading) {
      return <Spinner spinnerSize={2} center />;
    }
    if (isPermitted(profileType.rolesNames, [Role.INTERN])) {
      return <>{t('features.appointment.confirm', 'Confirm Appointment')}</>;
    }
    return <>{t('features.appointment.book', 'Book Appointment')}</>;
  };

  const isButtonDisabled = !choosedTimeSlot || bookAppointmentLoading || dispatchedAppointment;

  return (
    <Row className="justify-content-md-center">
      <Col lg="6" sm="12">
        <Card>
          <Card.Body>
            <h3 onClick={() => returnOnCalendarForm()} className="clickable">
              <ArrowLeftCircle />
            </h3>
            <DatePicker
              loading={timeSlotsLoading}
              slots={timeSlots}
              updateTimeSlots={updateTimeSlots}
              chooseTimeSlot={setChoosedTimeSlot}
            />
          </Card.Body>
          <Button
            disabled={isButtonDisabled}
            onClick={() => {
              if (choosedTimeSlot) {
                bookChoosedTimeSlot(choosedTimeSlot);
              }
            }}
          >
            {getCTAContent()}
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default MakeAppointmentBody;
