import * as EligibilityApiService from './eligibilityAPI';

import {
  AnyAction,
  SerializedError,
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import {
  IDataSearchMap,
  IDataSearchType,
  IGeographicAddress,
  IOfferVerticalityResult,
  IOfferingResult,
  IOrderProductInfos,
  ProductError
} from './interfaces';

import { RootState } from '../../app/store';

export interface EligibilityState<T extends IDataSearchType> {
  searchType: T;
  searchData: IDataSearchMap[T];
  lastApiError: string | undefined;
  productsLoading: boolean;
  products: IOfferingResult[];
  productsErrors: ProductError[] | null;
  orderLoading: boolean;
  orderInfos: IOfferingResult | null;
  orderError: string | null;
  verticalityLoading: boolean;
  verticalityInfos: IOfferVerticalityResult | null;
  verticalityError: SerializedError | null;
  address: IGeographicAddress | undefined;
}

const initialState: EligibilityState<IDataSearchType> = {
  searchType: '',
  searchData: null,
  lastApiError: '',
  productsLoading: false,
  products: [],
  productsErrors: null,
  orderLoading: false,
  orderInfos: null,
  orderError: null,
  verticalityLoading: false,
  verticalityInfos: null,
  verticalityError: null,
  address: undefined
};

export const loadCities = createAsyncThunk(
  'eligibility/loadCities',
  async (postcode: string, { dispatch }) => {
    dispatch(setAPIError(null));
    try {
      const response = await EligibilityApiService.getCities(postcode);
      return response.data;
    } catch (e) {
      dispatch(setAPIError((e as Error).message));
      throw e;
    }
  }
);

export const loadStreets = createAsyncThunk(
  'eligibility/loadStreets',
  async (query: { postcode: string; city: string }, { dispatch }) => {
    dispatch(setAPIError(null));
    try {
      const response = await EligibilityApiService.getStreets(query);
      return response.data;
    } catch (e) {
      dispatch(setAPIError((e as Error).message));
      throw e;
    }
  }
);

export const loadStreetNumbers = createAsyncThunk(
  'eligibility/loadStreetNumbers',
  async (query: { postcode: string; city: string; street: string }, { dispatch }) => {
    dispatch(setAPIError(null));
    try {
      const response = await EligibilityApiService.getStreetNumbers(query);
      return response.data;
    } catch (e) {
      dispatch(setAPIError((e as Error).message));
      throw e;
    }
  }
);

export const loadOrganizations = createAsyncThunk(
  'eligibility/loadOrganizations',
  async (siret: string, { dispatch }) => {
    dispatch(setAPIError(null));
    try {
      const response = await EligibilityApiService.getOrganizations(siret);
      return response.data;
    } catch (e) {
      dispatch(setAPIError((e as Error).message));
      throw e;
    }
  }
);

export const loadOrganizations2 = createAsyncThunk(
  'eligibility/loadOrganizations',
  async (siren: string, { dispatch }) => {
    dispatch(setAPIError(null));
    try {
      const response = await EligibilityApiService.getOrganizations(siren);
      return response.data;
    } catch (e) {
      dispatch(setAPIError((e as Error).message));
      throw e;
    }
  }
);

export const loadGeographicAddresses = createAsyncThunk(
  'eligibility/loadGeographicAddresses',
  async (params: string, { dispatch }) => {
    dispatch(setAPIError(null));
    try {
      const response = await EligibilityApiService.getGeographicAddresses(params);
      return response.data;
    } catch (e) {
      dispatch(setAPIError((e as Error).message));
      throw e;
    }
  }
);

export const getProducts = createAsyncThunk(
  'eligibility/getProducts',
  async (site: IGeographicAddress) => {
    const { data, errors } = await EligibilityApiService.getProducts(site);
    return { data, errors };
  }
);

export const orderProduct = createAsyncThunk(
  'eligibility/orderProduct',
  async (orderInfos: IOrderProductInfos, { rejectWithValue }) => {
    const { type, selectedProduct, addressInfos, verticalityInfos, existingPlug, multiAccess } =
      orderInfos;
    try {
      const response = await EligibilityApiService.orderProduct(
        type,
        selectedProduct,
        addressInfos,
        verticalityInfos,
        existingPlug,
        multiAccess
      );
      return response.data;
    } catch (e: any) {
      if (e.response.data) {
        return rejectWithValue(e.response.data);
      }
      throw e;
    }
  }
);

/**
 * Get verticality option from product before ordering
 */
export const orderVerticality = createAsyncThunk(
  'eligibility/orderVerticality',
  async ({ addressInfos }: { addressInfos: IGeographicAddress }) => {
    const response = await EligibilityApiService.orderVerticality(addressInfos);
    const transformData = (data: any) => {
      return data.map((item: any) => ({
        ...item,
        stairs: item.stairs.map((stair: any) => ({
          ...stair,
          name: stair.name === '' || stair.name.length > 25 ? 'NA' : stair.name,
          floors: stair.floors.map((floor: any) => ({
            ...floor,
            name: floor.name === '' || floor.name.length > 25 ? 'NA' : floor.name
          }))
        }))
      }));
    };
    return transformData(response.data);
  }
);

const eligibilitySlice = createSlice({
  name: 'eligibility',
  initialState,
  reducers: {
    setSearchType: (state, action) => {
      state.searchType = action.payload.type;
      state.searchData = action.payload.data;
    },
    clearSearchType: (state) => {
      state.searchType = '';
      state.searchData = null;
    },
    setAddress: (state, action) => {
      state.address = action.payload.address;
    },
    clearAddress: (state) => {
      state.address = undefined;
    },
    resetOrderData: (state) => {
      state.orderInfos = null;
      state.orderError = null;
      state.orderLoading = false;
      state.verticalityInfos = null;
      state.verticalityError = null;
    },
    setAPIError: (state, action) => {
      state.lastApiError = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.productsLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.productsLoading = false;
        state.products = payload.data;
        state.productsErrors = payload.errors;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.productsLoading = false;
        state.lastApiError = action.error.message;
      })
      .addCase(orderProduct.pending, (state) => {
        state.orderLoading = true;
      })
      .addCase(orderProduct.fulfilled, (state, { payload }) => {
        state.orderLoading = false;
        state.orderInfos = payload;
      })
      .addCase(orderProduct.rejected, (state, action: AnyAction) => {
        state.orderLoading = false;
        if (action.payload && action.payload.message) {
          state.orderError = action.payload.message;
        } else {
          state.orderError = action.error.message;
        }
      })
      .addCase(orderVerticality.pending, (state) => {
        state.verticalityLoading = true;
      })
      .addCase(orderVerticality.fulfilled, (state, { payload }) => {
        state.verticalityLoading = false;
        state.verticalityInfos = payload;
      })
      .addCase(orderVerticality.rejected, (state, { error }) => {
        state.verticalityLoading = false;
        state.verticalityError = error;
      });
  }
});

export const {
  setAPIError,
  clearSearchType,
  setSearchType,
  clearAddress,
  setAddress,
  resetOrderData
} = eligibilitySlice.actions;

const selectEligibilityState = createSelector(
  (state: RootState) => state.eligibility,
  (s) => s
);

export const selectSearchInfos = createSelector(selectEligibilityState, (s) => ({
  type: s.searchType,
  data: s.searchData
}));

export const selectAdressInfos = createSelector(selectEligibilityState, (s) => s.address);

export const formatAddressToString = (addressInfos: IGeographicAddress) => {
  return `${addressInfos.streetNr || ''}${addressInfos.streetNrSuffix || ''}, ${
    addressInfos.streetName || ''
  } ${addressInfos.postcode} ${addressInfos.city}`;
};

export const selectAdressInfosFormated = createSelector(selectEligibilityState, (s) => {
  const addressInfos = s.address;
  return (
    addressInfos && {
      address: formatAddressToString(addressInfos),
      id: addressInfos.id,
      referenceType: addressInfos.referenceType
    }
  );
});

export const selectLastApiError = createSelector(selectEligibilityState, (s) => s.lastApiError);

export default eligibilitySlice.reducer;
