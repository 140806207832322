import LinkOrAnchor from '@designSystem/LinkOrAnchor';
import { OrderSections } from '../../utils/OrderHelper';
import { changeStep } from '../../reducer/orderSlice';
import styled from 'styled-components';
import theme from '@theme';
import { useAppDispatch } from '../../../../app/hooks';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../../../utils/matomoHarmonizer';

interface IOrderRecapEditLinkProps {
  stepToGo: number;
  anchorKey?: OrderSections;
}

const OrderRecapEditLink = ({ stepToGo, anchorKey }: IOrderRecapEditLinkProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const trackEvent = useMatomoHarmonizer();

  const setAnchorThenGoToStep = () => {
    history.replaceState(null, '', `#${anchorKey}`);
    if (anchorKey) {
      trackEvent({
        page: 'Commandes',
        category: 'Recapulatif',
        actionType: 'Vu',
        actionDetails: anchorKey
      });
    }
    dispatch(changeStep(stepToGo));
  };

  return (
    <StyledOrderEditLinkWrapper>
      <LinkOrAnchor onClick={setAnchorThenGoToStep}>
        {t('component.button.edit', 'Edit')}
      </LinkOrAnchor>
    </StyledOrderEditLinkWrapper>
  );
};

export default OrderRecapEditLink;

const StyledOrderEditLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: ${theme.fonts.weight.medium};
  margin-top: 8px;
`;
