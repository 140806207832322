import { IOfferingResult, ProductPrice } from '../interfaces';
import {
  billingZoneMap,
  engagementLabels,
  leadTimeMsg,
  offerStatus,
  pricingLabels
} from './constants';

import { Row } from 'react-table';
import { getQualificationItemBillingZone } from '../helper';
import { i18n } from '@i18n';
import { getDSPFromProduct } from '@features/order/utils/utils';

const getTextForPrice = (
  value: IOfferingResult,
  priceFilter: (_v: ProductPrice, i?: number, _p?: ProductPrice[]) => boolean
) => {
  const productPrice = value?.product?.productPrice;
  const status = value?.qualificationItemResult?.toUpperCase();
  const price = productPrice && productPrice.filter(priceFilter);
  const noPriceMsg = status === 'TO_BE_STUDIED' ? i18n.t('columns', 'To be studied') : '-';
  return price && price.length > 0
    ? price
        .map(
          (v) =>
            `${price.length > 1 ? engagementLabels(v.contractTerm) || ' ' : ''} ` +
            v.price.dutyFreeAmount.value +
            (pricingLabels[v.price.dutyFreeAmount.unit] || '-')
        )
        .join('\n')
    : noPriceMsg;
};

export const productColumns = (canDisplayPrice = false) => {
  const columns = [
    {
      Header: i18n.t('features.eligibility.products.columns.name', 'PRODUCT NAME'),
      getColumnExportValue: () =>
        i18n.t('features.eligibility.products.columns.name', 'PRODUCT NAME'),
      accessor: 'productOffering.name',
      aggregate: 'count',
      canView: true
    },
    {
      Header: i18n.t('features.eligibility.products.columns.eligibility', 'ELIGIBILITY STATUS'),
      getColumnExportValue: () =>
        i18n.t('features.eligibility.products.columns.eligibility', 'ELIGIBILITY STATUS'),
      // accessor is used for grouping so we use availability AND activation date
      accessor: (row: IOfferingResult) => {
        const billingZone = getQualificationItemBillingZone(row);
        let qualifAccessor = row.qualificationItemResult;

        if (billingZone) qualifAccessor += `_${billingZone}`;
        return qualifAccessor;
      },
      id: 'qualificationItemResult',
      aggregate: 'count',
      canView: true,
      getCellExportValue: (row: Row<IOfferingResult>) => {
        const value = row?.original;
        const status = value?.qualificationItemResult?.toUpperCase();
        const billingZone = getQualificationItemBillingZone(value);

        return (
          (offerStatus[status] || '-') +
          `${billingZoneMap[billingZone] ? '\n' + (billingZoneMap[billingZone].label || '') : ''}`
        );
      }
    },
    {
      // ONLY on quotation PDF export
      Header: () => i18n.t('features.eligibility.products.columns.network', 'NETWORK'),
      getColumnExportValue: () =>
        i18n.t('features.eligibility.products.columns.network', 'NETWORK'),
      id: '',
      accessor: 'product.productCharacteristic[O]',
      canView: true,
      hide: true,
      getCellExportValue: (row: Row<IOfferingResult>) => {
        const value = row?.original;

        return getDSPFromProduct(value?.product)?.toUpperCase();
      }
    },
    {
      Header: () => i18n.t('features.eligibility.products.columns.monthlyFee', 'Monthly fee'),
      getColumnExportValue: () =>
        i18n.t('features.eligibility.products.columns.monthlyFee', 'Monthly fee'),
      accessor: 'product.productPrice',
      id: 'monthly_fee',
      canView: canDisplayPrice,
      disableExport: !canDisplayPrice,
      getCellExportValue: (row: Row<IOfferingResult>) => {
        const value = row?.original;
        const priceFilter = (v: ProductPrice, i?: number, p?: ProductPrice[]) => {
          return (
            (v.name.includes('main_monthly_fee') &&
              p &&
              p.findIndex(
                (v2) =>
                  v2.name.includes('main_monthly_fee') &&
                  v2.price.dutyFreeAmount.value === v.price.dutyFreeAmount.value
              ) === i) ||
            false
          );
        };
        return getTextForPrice(value, priceFilter);
      }
    },
    {
      Header: () => i18n.t('features.eligibility.products.columns.activationFee', 'Activation fee'),
      getColumnExportValue: () =>
        i18n.t('features.eligibility.products.columns.activationFee', 'Activation fee'),
      accessor: 'product.productPrice',
      id: 'activation_fee',
      canView: canDisplayPrice,
      disableExport: !canDisplayPrice,
      getCellExportValue: (row: Row<IOfferingResult>) => {
        const value = row?.original;
        const priceFilter = (v: ProductPrice) => v.name.includes('main_activation_fee');
        return getTextForPrice(value, priceFilter);
      }
    },
    {
      Header: () => i18n.t('features.eligibility.products.columns.delai', 'DELAI'),
      getColumnExportValue: () => i18n.t('features.eligibility.products.columns.delai', 'DELAI'),
      accessor: 'leadTime.value',
      aggregate: 'count',
      canView: true,
      getCellExportValue: (row: Row<IOfferingResult>) => {
        const oValue = row?.original;
        const status = oValue?.qualificationItemResult?.toUpperCase();
        const itemResult = oValue?.qualificationItemResult;
        if (status === 'TO_BE_STUDIED') {
          return i18n.t('columns', 'To be studied');
        } else if (
          oValue &&
          oValue.leadTime &&
          ['eligible', 'to_be_studied'].indexOf(itemResult) !== -1
        ) {
          const { value, unit } = oValue.leadTime;
          return leadTimeMsg(value)[unit] || '-';
        }
      }
    }
  ];

  return columns.filter((c) => c.canView);
};
