import { Col, Row } from 'react-bootstrap';
import { buildingDetailsKeyTranslations } from '../utils/siteStatusUtils';

import { BuildingDetails } from '../utils/siteStatusInterfaces';
import React from 'react';

const BuildingDetailDisplayer = ({
  detailKey,
  value
}: {
  detailKey: string;
  value?: string | number;
}) => {
  return (
    <Col md="12" sm="12" className="display-flex-row small-padding-vertical">
      <Col sm="6" className="medium-padding-horizontal">
        {buildingDetailsKeyTranslations(detailKey) || detailKey}
      </Col>
      <Col sm="6">{value}</Col>
    </Col>
  );
};

const BuildingDetailsCardBody: React.FC<{ buildingDetails: BuildingDetails }> = ({
  buildingDetails
}) => {
  return (
    <>
      <Row>
        <Col md="6" sm="12" className="small-padding-vertical">
          <BuildingDetailDisplayer
            detailKey="etat_immeuble"
            value={buildingDetails.etat_immeuble}
          />
          <BuildingDetailDisplayer
            detailKey="nombre_logements_adresse_ipe"
            value={buildingDetails.nombre_logements_adresse_ipe}
          />
          <BuildingDetailDisplayer
            detailKey="raccordement_long"
            value={buildingDetails.raccordement_long}
          />
          <BuildingDetailDisplayer
            detailKey="susceptible_raccordable_demande"
            value={buildingDetails.susceptible_raccordable_demande}
          />
          <BuildingDetailDisplayer
            detailKey="code_adresse_immeuble"
            value={buildingDetails.code_adresse_immeuble}
          />
        </Col>
        <Col md="6" sm="12" className="small-padding-vertical">
          <BuildingDetailDisplayer
            detailKey="blocage_eligibilite"
            value={buildingDetails.blocage_eligibilite}
          />
          <BuildingDetailDisplayer
            detailKey="motif_blocage"
            value={buildingDetails.motif_blocage}
          />
          <BuildingDetailDisplayer
            detailKey="date_debut_blocage_eligibilite"
            value={buildingDetails.date_debut_blocage_eligibilite}
          />
        </Col>
      </Row>
    </>
  );
};

export default BuildingDetailsCardBody;
