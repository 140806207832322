import {
  StyledOrderRecapConfirmModalDescription,
  StyledOrderRecapConfirmModalTitle
} from './OrderRecapConfirmModalStyles';

import { FakeLoadingBarTimeOut } from '../../utils/OrderHelper';
import FakeProgressBar from '../../../../components/FakeProgressBar';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const OrderRecapConfirmModalLoading = ({ title }: { title: ReactNode }) => {
  const { t } = useTranslation();
  return (
    <>
      <FakeProgressBar time={FakeLoadingBarTimeOut} />
      <StyledOrderRecapConfirmModalTitle>{title}</StyledOrderRecapConfirmModalTitle>
      <StyledOrderRecapConfirmModalDescription>
        {t('features.order.orderRecap.dontLeave', 'Don‘t leave, It can take up to one minute')}
      </StyledOrderRecapConfirmModalDescription>
    </>
  );
};

export default OrderRecapConfirmModalLoading;
