import { Alert, Col, Container } from 'react-bootstrap';
import { doLogout, getUsername } from '../app/AuthService';

import { Guard } from '../utils/FeatureFlag';
import SelectOffer from '@features/eligibility/SelectOffer';
import StepperGoogle from '../components/StepperGoogle';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import InformationBlock from '@designSystem/InformationBlock';
import Spinner from '@designSystem/Spinner';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 50px;
`;

const Eligibility = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const FallbackComponent = () => {
    useEffect(() => {
      const timer = setTimeout(() => {
        navigate('/ordersBeta?no-construction=true');
      }, 3000);

      return () => clearTimeout(timer);
    }, [navigate]);

    return (
      <Wrapper>
        <InformationBlock
          title={
            <>
              Le parcours d&apos;éligibilité est maintenant intégré au parcours de commandes. &nbsp;
              <Spinner spinnerSize={1} />
            </>
          }
        >
          Vous allez être redirigé(e) vers l’onglet Commandes
        </InformationBlock>
      </Wrapper>
    );
  };

  return (
    <>
      <Guard flag="eligibility_access" fallback={<FallbackComponent />}>
        <Container fluid className="main-content-container h-100 bg-white overflow-scroll px-0">
          <Guard flag="wrong_account_warning">
            <>
              {
                // Only for FAI account not personnal
                getUsername()?.substring(0, 4) === 'fai.' && (
                  <Col sm="12" className="text-center">
                    <Alert variant="warning">
                      {t('pages.eligibility.logged', 'You are logged in with account')}{' '}
                      {getUsername()}.
                      <br />
                      <br />
                      {t(
                        'pages.eligibility.orderToTake',
                        ' In order to take advantage of all the features of the tool, please log in  again with your nominative account if you have one. '
                      )}
                      <br />
                      <StyledLink onClick={() => doLogout()}>
                        {t('pages.eligibility.changeAccount', 'Click here to change account')}
                      </StyledLink>
                    </Alert>
                  </Col>
                )
              }
            </>
          </Guard>
          <StepperGoogle
            thirdStepText={t('pages.eligibility.offers', 'Offers')}
            pageTracking="Eligibilité"
          >
            <SelectOffer />
          </StepperGoogle>
        </Container>
      </Guard>
    </>
  );
};

const StyledLink = styled.a`
  display: inline-block;
  font-size: ${theme.fonts.size.base};
  padding: ${theme.fonts.spacing.xs} 0;
`;

export default Eligibility;
