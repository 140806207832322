import { MiddlewareAPI, Dispatch, AnyAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

const logger =
  (store: MiddlewareAPI<Dispatch<AnyAction>, RootState>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    console.group(action.type);
    console.info('Dispatching', action);
    const result = next(action);
    console.log('Next state', store.getState());
    console.groupEnd();
    return result;
  };

export default logger;
