import React, { ReactChild, useEffect, useState } from 'react';
import theme, { ThemeVariants } from '../../style/theme';

import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

interface IModal {
  variant?: ThemeVariants;
  style?: React.CSSProperties;
  show: boolean;
  onClose?: () => void;
  footer?: ReactChild;
  width?: string;
  children: ReactChild;
  canClose?: boolean;
  rootId?: 'root' | '__next';
}

const Modal: React.FC<IModal> = ({
  show,
  rootId = 'root',
  width,
  onClose,
  footer,
  children,
  canClose = true
}) => {
  const closeOnEscapeKeyDown = (e: { charCode: any; keyCode: any }) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose && onClose();
    }
  };

  const nodeRef = React.useRef(null);
  const [containerToLoad, setContainerToLoad] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setContainerToLoad(document.getElementById(rootId));

    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  return (
    containerToLoad &&
    ReactDOM.createPortal(
      <div>
        <CSSTransition nodeRef={nodeRef} in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
          <StyledModal
            width={width || '500px'}
            ref={nodeRef}
            className="modal"
            style={{ fontSize: '16px' }}
            onClick={canClose ? onClose : undefined}
          >
            <div className="modal_content" onClick={(e) => e.stopPropagation()}>
              <div className="modal_body">{children}</div>
              {footer && <div className="modal_footer">{footer}</div>}
            </div>
          </StyledModal>
        </CSSTransition>
      </div>,
      document.getElementById(rootId) as Element
    )
  );
};
interface StyledModalProps {
  width?: string;
}

const StyledModal = styled.div<StyledModalProps>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  opacity: 0;

  &.enter-done {
    opacity: 1;
    pointer-events: visible;
    .modal_content {
      transform: translateY(0);
    }
  }

  &.exit {
    opacity: 0;
    .modal_content {
      transform: translateY(-50px);
    }
  }

  .modal_content {
    width: ${(props) => props.width || '500px'};
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    transform: translateY(-50px);
    background: #ffffff;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 8px 8px;
  }

  .modal_footer {
    padding: 12px 1.5rem;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    border-radius: 0 0 8px 8px;
    background: ${theme.palette['grey'][50]};

    & > div {
      display: flex;
      width: 100%;
      gap: 1rem;

      button {
        flex: 1 1 auto;
      }
    }
  }

  .modal_body {
    padding: 24px;
  }
`;

export default Modal;
