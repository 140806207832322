import { i18n } from '@i18n';

const today = new Date();
const now_utc = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), 0, 0, 0);

export const todayUTC = new Date(now_utc);

// By default 8 days from now
export const minDaysBeforeSlotOpen = (min_days_book_before_appointment = 8) =>
  new Date(now_utc + 3600 * 1000 * 24 * min_days_book_before_appointment);

export const timeSlotsNames: {
  [key: number]: string;
} = {
  1: i18n.t('enums.timeSlotsNames.enum_1', 'Morning'),
  2: i18n.t('enums.timeSlotsNames.enum_2', 'Afternoon'),
  3: i18n.t('enums.timeSlotsNames.enum_3', 'Noon')
};

export const appointmentStatus: {
  [key: number]: string;
} = {
  1: i18n.t('enums.appointmentStatus.enum_1', 'booked'),
  2: i18n.t('enums.appointmentStatus.enum_2', 'confirmed'),
  3: i18n.t('enums.appointmentStatus.enum_3', 'cancelled'),
  4: i18n.t('enums.appointmentStatus.enum_4', 'postponed')
};
