import { CalendarMinus } from 'react-bootstrap-icons';
import { ProductOfferingQualificationItem } from '../../interfaces';
import React from 'react';
import { leadTimeMsg } from '../constants';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const StyledProductLeadTime = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  line-height: ${theme.fonts.size.xs};
  font-size: ${theme.fonts.size.xs};
  font-weight: ${theme.fonts.weight.medium};
  color: ${theme.palette.grey[400]};
`;

const StyledName = styled.p`
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.xs};
  margin-bottom: 5px;
`;

const ProductNameAndDelais: React.FC<{
  product: ProductOfferingQualificationItem;
}> = ({ product }) => {
  const getQualificationItemDelay = (item: ProductOfferingQualificationItem) => {
    const itemResult = item.qualificationItemResult;
    if (item.leadTime && ['eligible', 'to_be_studied'].indexOf(itemResult) !== -1) {
      const { value, unit } = item.leadTime;

      return <>{leadTimeMsg(value)[unit]}</>;
    }
    return '';
  };

  const { t } = useTranslation();
  return (
    <div>
      <StyledName>{product.productOffering.name}</StyledName>
      {product.leadTime && (
        <StyledProductLeadTime>
          <div style={{ marginRight: 4 }}>
            <CalendarMinus size={16} color={theme.palette.grey[400]} />
          </div>
          {t('features.eligibility.products.productDetail.leadTime', 'Lead time')}{' '}
          {getQualificationItemDelay(product)}
        </StyledProductLeadTime>
      )}
    </div>
  );
};

export default ProductNameAndDelais;
