import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { loadOrders, selectSiteStatusState } from '../reducer/siteStatusSlice';
import { selectAdressInfos } from '../../eligibility/eligibilitySlice';
import { SiteStatusRT } from '../utils/siteStatusInterfaces';
import { SortObject } from '@designSystem/Table/HeadSort';
import { TableWrapper } from '@designSystem/Table';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import OrderDetail from './OrderDetail';
import OrderListTable from './OrderListTable';
import Pagination from '@designSystem/Pagination';
import SiteStatusAlert from '../utils/SiteStatusAlert';
import TableNoData from '../../../components/TableNoData';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';

const OrderList: React.FC<{
  columns: Column<SiteStatusRT> | any;
  currentOrder: SiteStatusRT | null;
}> = ({ columns, currentOrder }) => {
  const dispatch = useAppDispatch();
  const addressInfos = useAppSelector(selectAdressInfos);
  const { siteStatusList, siteStatusTotalCount } = useAppSelector(selectSiteStatusState);
  const [loading, setLoading] = useState(false);
  const trackEvent = useMatomoHarmonizer();

  const [currentPage, setCurrentPage] = useState(0);
  // Used to sort the table
  const [currentSortObject, setCurrentSortObject] = useState<SortObject | undefined>({
    sortDesc: true,
    sortKey: 'lastmodifieddate'
  });
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    if (addressInfos && addressInfos.id) {
      dispatch(
        loadOrders({
          idBuilding: addressInfos.id,
          currentPage,
          pageSize: 10,
          sortKey: currentSortObject?.sortKey || null,
          sortDesc: currentSortObject?.sortDesc ? 'desc' : 'asc'
        })
      ).then((res) => {
        setLoading(false);
        const ordersLn = res?.payload?.data?.ftth_src_t_ftth_cmde_acces_v2?.length;
        const ordersAggreateLn =
          res?.payload?.data?.ftth_src_t_ftth_cmde_acces_v2_aggregate?.aggregate?.totalCount;

        if (ordersLn === 0) {
          if (ordersAggreateLn > 0) {
            trackEvent({
              page: 'EtatSite',
              category: 'Commande',
              actionType: 'Erreur',
              actionDetails: 'Hors_Territoire'
            });
          } else if (ordersAggreateLn === 0) {
            trackEvent({
              page: 'EtatSite',
              category: 'Commande',
              actionType: 'Erreur',
              actionDetails: 'Aucune_Commande'
            });
          }
        }
      });
    }
  }, [currentPage, currentSortObject, addressInfos, dispatch]);

  return (
    <TableWrapper className={`mt-4 mb-4 pt-2 ${!!currentOrder && 'pb-5 mb-4'}`}>
      <div className="table_header">
        <h4>
          {t('features.siteStatus.orderList.buildingAssociated', 'Building associated orders')}
        </h4>
      </div>
      <OrderListTable
        columns={columns}
        data={siteStatusList}
        isLoading={loading}
        currentSelectedRow={currentOrder ? currentOrder.id : null}
        sortByApi={setCurrentSortObject}
        currentSortObject={currentSortObject}
      >
        {currentOrder && <OrderDetail currentOrderId={currentOrder.id} />}
      </OrderListTable>

      {siteStatusList.length === 0 && siteStatusTotalCount > 0 && (
        <TableNoData
          description={t(
            'features.siteStatus.orderList.relatedBuilding',
            "Informations related to a building out of your territory aren't available"
          )}
          containerStyle={{ marginBottom: 16 }}
        />
      )}
      {siteStatusList.length === 0 && siteStatusTotalCount === 0 && (
        <SiteStatusAlert
          variant="warning"
          message={t('features.siteStatus.orderList.noData', 'No data available for this site')}
        />
      )}
      {siteStatusList.length > 0 && (
        <Pagination totalRows={siteStatusTotalCount} pageChangeHandler={setCurrentPage} />
      )}
    </TableWrapper>
  );
};

export default OrderList;
