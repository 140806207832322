import { useFormContext } from 'react-hook-form';

import { IOrderInformationsForm, Order, OrderOperaBusiness } from '../../utils/orderInterfaces';
import InputWrapper from '@designSystem/InputWrapper';
import { getErrorMessage } from '../../../../utils/formHelper';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SiretDropdown from './SiretInput';
import { StyledOrderOutletWrapper } from '@features/order/utils/OrderStyles';
import { StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';

const SIRET_LENGTH = 14;
const HOMEBASIC_COMPANY_REGEX = /^[0-9A-zÀ-ú-\s]{3,40}$/;

const OrderInformationsClientCompanyForm = ({
  orderInformationsFormValues,
  isHomeBasicProduct = false,
  companies,
  setSelectedOption,
  selectedOption,
  order,
  defineSiretToRegistration,
  siretValue,
  isMissingSIRET,
  setWithSiret,
  setToMissingSiret
}: {
  orderInformationsFormValues: IOrderInformationsForm;
  isHomeBasicProduct: boolean;
  companies:
    | {
        siret: string;
        name: string;
        siren: string;
      }[]
    | undefined;
  setSelectedOption: Dispatch<
    SetStateAction<{
      label: string;
      value: string;
    }>
  >;
  selectedOption: {
    label: string;
    value: string;
  };
  order: Order | OrderOperaBusiness | undefined;
  defineSiretToRegistration: () => void;
  siretValue: string;
  isMissingSIRET: boolean;
  setWithSiret: () => void;
  setToMissingSiret: () => void;
}) => {
  const { register, unregister, formState, clearErrors, setValue, control, getValues } =
    useFormContext();
  const { isSiretMandatory } = orderInformationsFormValues;
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      unregister('siret', { keepValue: true });
      unregister('businessName', { keepValue: true });
    };
  }, []);

  useEffect(() => {
    const isSiretMandatory = getValues('isSiretMandatory');
    if (!isSiretMandatory) {
      clearErrors('siret');
    }
  }, []);

  useEffect(() => {
    if (!isSiretMandatory) {
      setToMissingSiret();
    }
  }, [isSiretMandatory]);

  return (
    <div>
      <StyledOrderOutletWrapper>
        <div className="no-gap" style={{ marginBottom: 20 }}>
          <StyledLabel className="section-label-question">
            {t(
              'features.order.orderInformations.siret.question',
              "Votre client final dispose-t-il d'un SIRET ?"
            )}
          </StyledLabel>
          <div className="radio-container">
            <input
              onChange={setWithSiret}
              type="radio"
              id="is_siret_true"
              name="is_siret"
              checked={!isMissingSIRET}
            />
            <label htmlFor="is_siret_true">{t('app.yes', 'Yes')}</label>
          </div>
          <div className="radio-container">
            <input
              onChange={setToMissingSiret}
              type="radio"
              id="is_siret_false"
              name="is_siret"
              value="false"
              checked={isMissingSIRET}
            />
            <label htmlFor="is_siret_false">{t('app.no', 'No')}</label>
          </div>
        </div>
      </StyledOrderOutletWrapper>
      <div style={{ display: 'flex', width: '100%', gap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <div style={{ flex: 1, width: '100%' }}>
            <InputWrapper
              inputLabel={t('features.order.orderInformations.siret', 'SIRET')}
              error={getErrorMessage('siret', formState)}
            >
              <SiretDropdown
                SIRET_LENGTH={SIRET_LENGTH}
                register={register}
                disabled={isMissingSIRET}
                defineSiretToRegistration={defineSiretToRegistration}
                setValue={setValue}
                companies={companies}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                siretValue={siretValue}
                isSiretMandatory={!isMissingSIRET}
                order={order}
              />
            </InputWrapper>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <InputWrapper
            inputLabel={t('features.order.orderInformations.businessName', 'Business name')}
            error={getErrorMessage('businessName', formState)}
            style={{ width: '100%' }}
          >
            <input
              {...register('businessName', {
                validate: (value) => {
                  return isHomeBasicProduct
                    ? HOMEBASIC_COMPANY_REGEX.test(value) ||
                        t('errorMessages.invalidHomeBasicBusinessName', `Invalid format`)
                    : true;
                },
                required: {
                  value: true,
                  message: t('errorMessages.missingVal', `Missing value`)
                },
                setValueAs: (v) => v?.trimStart()
              })}
              data-testid="order-input-businessName"
              id="businessName"
              placeholder={t('features.order.orderInformations.businessName', `Business name`)}
              value={orderInformationsFormValues.businessName}
              aria-label="businessName-input"
              style={{ width: '100%' }}
            />
          </InputWrapper>
        </div>
      </div>
    </div>
  );
};

export default OrderInformationsClientCompanyForm;
