import React from 'react';

import CalendarFormStepOne from './CalendarFormStepOne';
import { ICreateCalendarForm } from '../utils/interfaces';
import CalendarFormStepTwo from './CalendarFormStepTwo';
import { Control } from 'react-hook-form';
import { OrderSteps } from '../../order/utils/constants';

const CalendarFormSteps: React.FC<{
  formValues: ICreateCalendarForm;
  register: CallableFunction;
  setValue: CallableFunction;
  control: Control<ICreateCalendarForm, any>;
  currentStep: number;
}> = ({ formValues, register, setValue, control, currentStep }) => {
  return (
    <>
      {currentStep === OrderSteps.SELECT_ADDRESS && (
        <CalendarFormStepOne
          formValues={formValues}
          register={register}
          setValue={setValue}
          control={control}
        />
      )}
      {currentStep === 2 && (
        <CalendarFormStepTwo formValues={formValues} register={register} setValue={setValue} />
      )}
    </>
  );
};

export default CalendarFormSteps;
