import React, { ReactChild } from 'react';
import theme, { ThemeVariants } from '../../style/theme';

import Icon from '../Icon';
import styled from 'styled-components';

interface ModalContentWithIconProps {
  children?: React.ReactNode;
  variant?: ThemeVariants;
  iconPosition?: 'top' | 'left';
  title?: ReactChild | string;
  description?: ReactChild | string;
  style?: React.CSSProperties;
}

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  .modal_content_icon {
    ${(props: ModalContentWithIconProps) =>
      props.iconPosition === 'left' ? 'margin: 0 1rem 0.5rem 0;' : 'margin: 1rem 0 0.5rem 0;'};
  }

  ${(props: ModalContentWithIconProps) =>
    props.iconPosition === 'left'
      ? 'flex-direction: row; text-align: left;'
      : 'flex-direction: column; text-align: center;'};
`;

export const StyledTitle = styled.p`
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.lg};
  line-height: ${theme.fonts.spacing.base};
  color: ${theme.palette.grey[900]};
  margin-bottom: 0;
`;

export const StyledDescription = styled.p`
  margin-top: 0.5rem;
  line-height: ${theme.fonts.spacing.sm};
  font-weight: ${theme.fonts.weight.regular};
  font-size: ${theme.fonts.size.sm};
  color: ${theme.palette.grey[500]};
  margin-bottom: 0;
`;

export const ModalContentWithIcon = ({
  variant = 'blue',
  iconPosition = 'top',
  style = {},
  ...props
}: ModalContentWithIconProps) => {
  return (
    <StyledBody iconPosition={iconPosition} style={style}>
      <Icon className="modal_content_icon" round size="large" variant={variant}>
        {props.children}
      </Icon>
      <div>
        <StyledTitle>{props.title}</StyledTitle>
        <StyledDescription>{props.description}</StyledDescription>
      </div>
    </StyledBody>
  );
};

export default ModalContentWithIcon;
