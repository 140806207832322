import Icon from '@designSystem/Icon';
import { Lightbulb } from 'react-bootstrap-icons';
import styled from 'styled-components';

const OrderAppointmentSidebarIcon = () => {
  return (
    <StyledOrderAppointementSidebarIconWrapper>
      <Icon noBackground size="minimal" variantDeclination={400} variant="blue">
        <Lightbulb />
      </Icon>
    </StyledOrderAppointementSidebarIconWrapper>
  );
};

export default OrderAppointmentSidebarIcon;

const StyledOrderAppointementSidebarIconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;
