import { FAKE_SIRET, OrderSections } from '../../utils/OrderHelper';
import {
  StyledOrderClientWrapper,
  StyledOrderLabelValue,
  StyledOrderSection
} from '../../utils/OrderStyles';

import { Order } from '../../utils/orderInterfaces';
import OrderRecapEditLink from '../components/OrderRecapEditLink';
import { StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import { useTranslation } from 'react-i18next';
import { siretAbsenceReasonsList } from '@features/order/utils/constants';

const OrderRecapEndClientSection = ({
  editable,
  client
}: {
  editable: boolean;
  client: Order['customer'];
}) => {
  const { t } = useTranslation();

  const emptyFieldText = t(
    'features.order.orderRecap.orderRecapSections.notSpecified',
    'Not specified'
  );
  return (
    <StyledOrderSection>
      <div className="section-title">
        {t('features.order.orderRecap.orderRecapSections.endClient', 'End Client informations')}
      </div>
      <StyledOrderClientWrapper>
        {(client?.name || (client?.siret && client?.siret !== FAKE_SIRET)) && (
          <>
            <div>
              <StyledLabel>
                {t('features.order.orderRecap.orderRecapSections.businessName', 'Business name')}
              </StyledLabel>
              <StyledOrderLabelValue>{client?.name}</StyledOrderLabelValue>
            </div>

            <div>
              <StyledLabel>
                {t('features.order.orderRecap.orderRecapSections.siret', 'SIRET')}
              </StyledLabel>
              <StyledOrderLabelValue>
                {client.siret && client.siret !== FAKE_SIRET ? client.siret : emptyFieldText}
              </StyledOrderLabelValue>
            </div>
            {client.siren && (
              <div>
                <StyledLabel>
                  {t('features.order.orderRecap.orderRecapSections.siren', 'SIREN')}
                </StyledLabel>
                <StyledOrderLabelValue>
                  {client.siren && client.siren !== FAKE_SIRET ? client.siren : emptyFieldText}
                </StyledOrderLabelValue>
              </div>
            )}
            {client?.reasonNoSirenOrSiret && (
              <div>
                <StyledLabel>
                  {t(
                    'features.order.orderInformations.siretAbsenceReason_label',
                    'Reason for absence of Siret'
                  )}
                </StyledLabel>
                <StyledOrderLabelValue>
                  {siretAbsenceReasonsList(t).find(
                    (reason) => reason.value === client.reasonNoSirenOrSiret
                  )?.label || emptyFieldText}
                </StyledOrderLabelValue>
              </div>
            )}
          </>
        )}
        <div>
          <StyledLabel>
            {t('features.order.orderRecap.orderRecapSections.lastName', 'Lastname')}
          </StyledLabel>
          <StyledOrderLabelValue>{client.contact?.lastName}</StyledOrderLabelValue>
        </div>
        <div>
          <StyledLabel>
            {t('features.order.orderRecap.orderRecapSections.firstName', 'Firstname')}
          </StyledLabel>
          <StyledOrderLabelValue> {client.contact?.firstName}</StyledOrderLabelValue>
        </div>

        <div>
          <StyledLabel>
            {t('features.order.orderRecap.orderRecapSections.phone', 'Phone contact n°1')}
          </StyledLabel>
          <StyledOrderLabelValue> {client.contact?.mobilePhoneNumber}</StyledOrderLabelValue>
        </div>
        <div>
          <StyledLabel>
            {t('features.order.orderRecap.orderRecapSections.phoneTwo', 'Phone contact n°2')}
            <div className="optional">
              {t('features.order.orderRecap.orderRecapSections.optional', '(optional)')}
            </div>
          </StyledLabel>
          <StyledOrderLabelValue>
            {client.contact?.phoneNumber &&
            client.contact?.phoneNumber !== client.contact?.mobilePhoneNumber
              ? client.contact.phoneNumber
              : emptyFieldText}
          </StyledOrderLabelValue>
        </div>

        <div>
          <StyledLabel>
            {t('features.order.orderRecap.orderRecapSections.email', 'Email')}
          </StyledLabel>
          <StyledOrderLabelValue>
            {client.contact?.emailAddress || emptyFieldText}
          </StyledOrderLabelValue>
        </div>
      </StyledOrderClientWrapper>
      {editable && (
        <OrderRecapEditLink stepToGo={3} anchorKey={OrderSections.EndClientInformations} />
      )}
    </StyledOrderSection>
  );
};

export default OrderRecapEndClientSection;
