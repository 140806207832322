import { ReactElement } from 'react';
import ReactSelect from 'react-select';
import { SelectOptions } from '../SelectOptionsList';
import { customSelectSearch } from '../../../style/customSelectStyle';

export type SelectSearchProps = {
  currentOption: FormOption | null;
  updateOption: CallableFunction;
};

type FormOption = {
  label: () => string | ReactElement;
  value: string;
};

const SelectSearchComponent = ({ currentOption, updateOption }: SelectSearchProps) => {
  const options = SelectOptions;

  return (
    <ReactSelect
      aria-label="searchtype-input"
      options={options.map((item) => {
        return { label: item.label, value: item.name };
      })}
      onChange={(item) => {
        updateOption(item as FormOption);
      }}
      value={currentOption}
      isSearchable={false}
      styles={customSelectSearch}
    />
  );
};

export default SelectSearchComponent;
