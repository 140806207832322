import { RootStateOrAny, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { LoadingBarTimeOut } from '../../order/utils/OrderHelper';
import Modal from '@designSystem/Modal';
import OrderInfoPanelModalError from './orderInfoPanelModal/OrderInfoPanelModalError';
import OrderInfoPanelModalLink from './orderInfoPanelModal/OrderInfoPanelModalLink';
import OrderInfoPanelModalLoading from './orderInfoPanelModal/OrderInfoPanelModalLoading';
import { resetOrderData } from '../eligibilitySlice';
import { useAppDispatch } from '../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { flushSync } from 'react-dom';

interface IOrderInfoPanelProps {
  isQuotation: boolean;
  navigateBackUrl?: string;
}
const OrderInfoPanel = ({ isQuotation, navigateBackUrl }: IOrderInfoPanelProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { orderLoading, orderError, orderInfos } = useSelector(
    (state: RootStateOrAny) => state.eligibility
  );
  // Those 3 lines prevent text and display update when reducer is updated for smooth transition
  const [showModal, setShowModal] = useState<boolean>(false);
  const [toDisplay, setToDisplay] = useState<'loading' | 'loadingFast' | 'error' | 'link'>(
    'loading'
  );
  const [currentError, setCurrentError] = useState<string | undefined>(undefined);

  const orderLink = orderInfos ? orderInfos.href : null;

  useEffect(() => {
    if (orderLink) {
      setToDisplay('loadingFast');
      setTimeout(() => {
        flushSync(() => {
          setToDisplay('link');
        });
        window.open(orderLink);
      }, LoadingBarTimeOut);
      setShowModal(true);
    }
    if (orderError) {
      setToDisplay('error');
      setCurrentError(orderError);
      setShowModal(true);
    }
    if (orderLoading) {
      setToDisplay('loading');
      setShowModal(true);
    }
  }, [orderLink, orderLoading, orderError]);

  const closeModal = () => {
    setShowModal(false);
    if (orderLink && navigateBackUrl) {
      navigate(navigateBackUrl);
    } else {
      dispatch(resetOrderData());
    }
  };

  const constructOrderLoadingModalContent = (
    contentToDisplay: 'link' | 'error' | 'loading' | 'loadingFast'
  ) => {
    const speed = contentToDisplay === 'loadingFast';

    if (contentToDisplay === 'link' && orderLink) {
      return (
        <OrderInfoPanelModalLink
          closeModal={closeModal}
          isQuotation={isQuotation}
          orderLink={orderLink}
        />
      );
    }

    if (contentToDisplay === 'error') {
      return (
        <OrderInfoPanelModalError
          error={currentError}
          closeModal={closeModal}
          isQuotation={isQuotation}
        />
      );
    }

    return <OrderInfoPanelModalLoading speed={speed} isQuotation={isQuotation} />;
  };

  return (
    <Modal canClose={false} show={showModal} onClose={closeModal}>
      {constructOrderLoadingModalContent(toDisplay)}
    </Modal>
  );
};

export default OrderInfoPanel;
