import { selectSideBarExpanded, setSideBarExpanded } from '@features/profiles/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { BurgerMenu } from '../../../images/sidebar';
import Logo from '@designSystem/Logo';
import React from 'react';

export const SidebarMenuToggle: React.FC = () => {
  const dispatch = useAppDispatch();
  const expanded = useAppSelector(selectSideBarExpanded);
  return (
    <div className={`top-section ${expanded ? 'open' : 'close'}`}>
      {expanded && (
        <div className="logo-container">
          <Logo reduced={!expanded} />
          <div className="title">Axione</div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          cursor: 'pointer'
        }}
      >
        <img
          className="menu-icon-sidebar"
          src={BurgerMenu}
          alt="Menu"
          onClick={() => dispatch(setSideBarExpanded(!expanded))}
        />
      </div>
    </div>
  );
};
