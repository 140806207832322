import { SortReferences } from './referencesSlice';
import axios from 'axios';
import { env } from '../../../config';

interface ICreateZoneGeoRefAPIForm {
  id?: number;
  zoneId: number;
  name: string;
  typology: number;
}

export const deleteReference = async (zoneId: string, id: number) => {
  return axios.delete(`${env.API_URL}/erdv/zonegeo/${zoneId}/site_reference/${id}`);
};

export const fetchReferencesList = async (arg: {
  zoneId: string;
  currentPage: number;
  sort?: SortReferences;
  search?: string;
}) => {
  const { zoneId, currentPage, sort, search } = arg;
  const order =
    sort && sort.order_by
      ? `&order_by=${sort.order_by}&order_ascending=${sort.order_ascending}`
      : '';
  const search_str = search ? `&search=${search}` : '';
  return axios.get(
    `${env.API_URL}/erdv/zonegeo/${zoneId}/site_reference?&offset=${
      currentPage * 10
    }&limit=10${order}${search_str}`
  );
};

export const zoneGeoRefCreate = async (body: ICreateZoneGeoRefAPIForm) => {
  return axios.post(`${env.API_URL}/erdv/zonegeo/${body.zoneId}/site_reference`, {
    ...body
  });
};

export const zoneGeoRefUpdate = async (body: ICreateZoneGeoRefAPIForm) => {
  return axios.put(`${env.API_URL}/erdv/zonegeo/${body.zoneId}/site_reference/${body.id}`, {
    ...body
  });
};
