import { CaretDown, CaretUp, JournalText } from 'react-bootstrap-icons';
import { StyledOrderStatusDiv, StyledOrderStatusText } from '../utils/SiteStatusStyled';

import { SiteStatusRT } from '../utils/siteStatusInterfaces';
import { i18n } from '@i18n';
import { DateManager } from '../../../utils/DateManager';

const greyColor = '#6E6E6E';
const primaryColor = '#0180A5';

export const orderListTableColumns = (
  showOrderInfos: (_arg1: string | null) => void,
  showTicketsInfos: (_arg1: string | null | undefined) => void,
  isOrderDetailed: string | null
) => {
  return [
    {
      Header: i18n.t('features.siteStatus.orderList.reference', 'Reference'),
      accessor: 'ref_prestation_prise__c'
    },
    {
      Header: i18n.t('features.siteStatus.orderList.ordered', 'Ordered'),
      accessor: 'createddate',
      Cell: ({ value }: { value: Date }) => new DateManager(value).format('DD/MM/YYYY')
    },
    {
      Header: i18n.t('features.siteStatus.orderList.updated', 'Updated'),
      accessor: 'lastmodifieddate',
      Cell: ({ value }: { value: Date }) => new DateManager(value).format('DD/MM/YYYY')
    },
    {
      Header: i18n.t('features.siteStatus.orderList.isp', 'ISP'),
      accessor: 'oc__c',
      Cell: ({ value }: { value: string }) => {
        const faiHash: { [key: string]: string } = {
          IFTR: 'FREE',
          BOUY: 'BOUYGUES TELECOM',
          CRFT: 'ORANGE',
          DEVO: 'IDLINE',
          FTEL: 'ORANGE',
          INPC: 'IDLINE',
          SFRA: 'SFR'
        };
        return faiHash[value] || value;
      }
    },
    {
      Header: i18n.t('features.siteStatus.orderList.connectionType', 'Connection type'),
      accessor: 'type_raccordement__c'
    },
    {
      Header: i18n.t('features.siteStatus.orderList.state', 'State'),
      accessor: 'etat__c',
      Cell: ({ value }: { value: string }) => (
        <StyledOrderStatusDiv className="text-center">
          <StyledOrderStatusText>{value}</StyledOrderStatusText>
        </StyledOrderStatusDiv>
      )
    },
    {
      Header: i18n.t('features.siteStatus.orderList.status', 'Status'),
      accessor: 'statut__c'
    },
    {
      Header: i18n.t('features.siteStatus.orderList.canceled', 'Canceled'),
      accessor: 'date_demande_annulation__c',
      Cell: ({ value }: { value: Date }) => new DateManager(value).format('DD/MM/YYYY') || ''
    },
    {
      Header: i18n.t('features.siteStatus.orderList.details', 'Details'),
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <div
          className="clickable text-center"
          onClick={() => {
            if (value === isOrderDetailed) {
              showOrderInfos(null);
            } else {
              showOrderInfos(value);
            }
          }}
        >
          {value === isOrderDetailed ? <CaretDown size={20} /> : <CaretUp size={20} />}
        </div>
      )
    },
    {
      Header: i18n.t('features.siteStatus.orderList.rtTicket', 'RT Tickets'),
      accessor: 'all',
      disableSortBy: true,
      Cell: ({ row }: { row: { original: SiteStatusRT } }) => {
        const ticketsLength = row.original.tickets?.length || 0;
        const hasAtLeastOneTicket = ticketsLength > 0;
        return (
          <div
            className={`${hasAtLeastOneTicket ? 'clickable' : ''} text-center`}
            style={hasAtLeastOneTicket ? {} : { color: greyColor }}
            onClick={() => {
              if (hasAtLeastOneTicket) {
                showTicketsInfos(row.original.ref_prestation_prise__c);
              }
            }}
          >
            <JournalText size="1.5rem" color={hasAtLeastOneTicket ? primaryColor : greyColor} />{' '}
            {ticketsLength}
          </div>
        );
      }
    }
  ];
};
