import { PencilFill, TrashFill } from 'react-bootstrap-icons';

import Badge from '@designSystem/Badges';
import { IZoneGeoRef, IZoneGeoRefFromApi } from '../interfaces';
import { Column, Row } from 'react-table';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

export const referencesColumns = (
  updateReference: (_ref: IZoneGeoRef) => void,
  deleteReference: (_ref: IZoneGeoRef) => void
): Column<IZoneGeoRefFromApi>[] => {
  const { t } = useTranslation();

  return [
    {
      Header: t('features.zonesGeo.referencesListTable.columns.reference', 'Reference'),
      accessor: 'reference',
      disableSortBy: true
    },
    {
      Header: t('features.zonesGeo.referencesListTable.columns.type', 'Reference type'),
      accessor: 'ref_type_label',
      disableSortBy: true,
      Cell: ({ row }: { row: Row<any> }) => {
        const value = row?.original;
        return (
          <div>
            <Badge variant="grey">{value.ref_type_label}</Badge>
          </div>
        );
      }
    },
    {
      disableSortBy: true,
      Header: t('features.zonesGeo.referencesListTable.columns.action', 'Actions'),
      accessor: 'any',
      Cell: ({ row }: { row: Row<any> }) => {
        const value = row?.original;
        return (
          <div>
            <PencilFill
              className="clickable"
              color={theme.palette.blue[500]}
              onClick={() => {
                updateReference(value);
              }}
            />
            <TrashFill
              className="clickable"
              color={theme.palette.blue[500]}
              onClick={() => {
                deleteReference(value);
              }}
            />
          </div>
        );
      }
    }
  ];
};
