import { useEffect, useRef } from 'react';

import { initializeMap } from './esri/map';
import styled from 'styled-components';

export const MapView = () => {
  const mapRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    initializeMap(mapRef.current);
  }, []); // only after first render

  return <MapDiv ref={mapRef}></MapDiv>;
};

const MapDiv = styled.div`
  width: 100%;
  height: 100%;
`;
