import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import theme from '@theme';

import APIErrorSvg from '../images/ApiError.svg';

const ApiError = () => {
  const { t } = useTranslation();
  return (
    <ApiErrorStyled>
      <img className="image" src={APIErrorSvg} />
      <div className="title">{t('component.apiError.title', 'A technical error occurred')}</div>
      <div className="sub-title">
        {t(
          'component.apiError.subTitle',
          'You can try again later. If the problem persists, contact us at'
        )}{' '}
        <a href="mailto:support.mon-espace@axione.fr">support.mon-espace@axione.fr</a>
      </div>
    </ApiErrorStyled>
  );
};

const ApiErrorStyled = styled.div`
  display: grid;
  grid-gap: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 350px;
  margin: auto;

  .image {
    width: 100%;
    height: 282px;
  }
  .title {
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};
    font-weight: ${theme.fonts.weight.medium};
    color: ${theme.palette.grey[500]};
  }
  .sub-title {
    font-size: ${theme.fonts.size.xs};
    line-height: ${theme.fonts.spacing.xs};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.palette.grey[400]};
    width: 100%;
  }
`;

export default ApiError;
