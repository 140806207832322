import React, { useEffect, useState } from 'react';
import { displayEngagement, getPriceByType } from '../../helper';

import Badge from '@designSystem/Badges';
import { ProductOfferingQualificationItem } from '../../interfaces';
import { listOfLabelsFull } from '../constants';
import { selectOrderState } from '@features/order/reducer/orderSlice';
import styled from 'styled-components';
import theme from '@theme';
import { useAppSelector } from '../../../../app/hooks';
import { useTranslation } from 'react-i18next';

const StyledPrice = styled.div`
  font-family: ${theme.fonts.primary};
  font-size: ${theme.fonts.size.base};
  font-weight: ${theme.fonts.weight.medium};
  & > span {
    color: ${theme.palette.grey[400]};
    font-weight: ${theme.fonts.weight.regular};
    font-size: ${theme.fonts.size.xs};
  }
`;

const ProductPriceAndFees: React.FC<{
  product: ProductOfferingQualificationItem;
}> = ({ product }) => {
  const { selectedOptions } = useAppSelector(selectOrderState);
  const [monthlyPrice, setMonthlyPrice] = useState<number | undefined>(
    getPriceByType(product.product.productPrice, 'main_monthly_fee')?.price.dutyFreeAmount.value
  );
  const [activationPrice, setActivationPrice] = useState<number | undefined>(
    getPriceByType(product.product.productPrice, 'main_activation_fee')?.price.dutyFreeAmount.value
  );
  const [engagement, setEngagement] = useState<string | null>(
    displayEngagement(product.product.productPrice)
  );

  const needStudy = product.qualificationItemResult?.toUpperCase() === 'TO_BE_STUDIED';
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedOptions?.duration) {
      const selectedPrice = product.product.productPrice?.find(
        (e) =>
          e.name.includes('main_monthly_fee') && e.name.includes(selectedOptions?.duration || '')
      )?.price.dutyFreeAmount.value;
      selectedPrice && setMonthlyPrice(selectedPrice);

      const activationPrice = product.product.productPrice?.find(
        (e) =>
          e.name.includes('main_activation_fee') && e.name.includes(selectedOptions?.duration || '')
      )?.price.dutyFreeAmount.value;
      activationPrice && setActivationPrice(activationPrice);

      selectedPrice &&
        activationPrice &&
        setEngagement(listOfLabelsFull[`${selectedOptions?.duration}_months`]);
    }
  }, [selectedOptions]);

  return (
    <div>
      {product.product.productPrice?.length ? (
        <StyledPrice data-testid="offer-monthly-price">
          {monthlyPrice +
            ' €/' +
            t('features.eligibility.products.productDetail.month', 'month') +
            ' '}
          <span>({engagement})</span>
        </StyledPrice>
      ) : (
        needStudy && (
          <StyledPrice>
            <span>
              {t(
                'features.eligibility.products.productDetail.subscriptionFrr',
                'Subscription, activation fee and lead time'
              )}
            </span>
          </StyledPrice>
        )
      )}
      <Badge variant={'grey'} style={{ fontSize: theme.fonts.size.xxs }}>
        {product.product.productPrice?.length ? (
          <>
            {t('features.eligibility.products.productDetail.activationFee', 'Activation fee')}{' '}
            {activationPrice} €
          </>
        ) : (
          t('features.eligibility.products.productDetail.toStudied', 'To be studied')
        )}
      </Badge>
    </div>
  );
};

export default ProductPriceAndFees;
