import {
  EligibilityState,
  getProducts,
  orderProduct,
  orderVerticality,
  resetOrderData,
  selectAdressInfos,
  selectAdressInfosFormated
} from './eligibilitySlice';
import {
  IDataSearchType,
  IOfferingResult,
  IVerticalitySelectedProductData,
  ProductOfferingQualificationItem
} from './interfaces';
import { IOrderCreationParams, OrderOptions } from '@features/order/utils/orderInterfaces';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
  callPlaceOrder,
  changeStep,
  resetAllSteps,
  updateStepData
} from '../order/reducer/orderSlice';
import { exportFile, isProductWithVerticality, isZTD, isBusiness } from './helper';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import AddressCard from './select/AddressCard';
import Button from '@designSystem/Button';
import Card from '@designSystem/Card';
import { Col } from 'react-bootstrap';
import ErrorList from './products/ErrorList';
import { ErrorMessageWithMail } from '@features/order/components/ToasErrorWithMail';
import { ErrorMonitoring } from '../../utils/ErrorMonitoring';
import HeaderCardCustom from './HeaderCardCustom';
import { NoDataComponent } from './SelectOffer';
import OrderInfoPanel from './products/OrderInfoPanel';
import { OrderSteps } from '../order/utils/constants';
import ProductDetailModalFactory from '@features/eligibility/products/productDetail/modal/ProductDetailModalFactory';
import ProductVerticalityModal from './products/productVerticality/productVerticalityModal';
import Results from './products/results/Results';
import Spinner from '@designSystem/Spinner';
import { displayToastErrorWithMessage } from '../../utils/toastHelper';
import { getDefaultGtrFromProduct } from './products/productDetail/OptionsForm';
import { listOfProductOptions } from './products/constants';
import { productColumns } from './products/Columns';
import styled from 'styled-components';
import theme from '@theme';
import toast from 'react-hot-toast';
import { unwrapResult } from '@reduxjs/toolkit';
import { useGuardHook } from '../../utils/FeatureFlag';
import { addSelectedOptionsToOrder } from '@features/order/reducer/orderSlice';
import { getDSPFromProduct } from '@features/order/utils/utils';
import Badge from '@designSystem/Badges';

const CustomCard = styled(Card)`
  max-width: 100%;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 1440px) {
    max-width: 1160px;
    margin: 0 auto 1.5rem auto;
  }
`;

const StyledScroll = styled.div`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const OrderOffer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { products, productsErrors, productsLoading }: Partial<EligibilityState<IDataSearchType>> =
    useSelector((state: RootStateOrAny) => state.eligibility);
  const { orderError } = useSelector((state: RootStateOrAny) => state.order);
  const [showVerticalityModal, setShowVerticalityModal] = useState(false);
  const [verticalityProductData, setVerticalityProductData] =
    useState<IVerticalitySelectedProductData>();

  const [detailProductData, setDetailProductData] = useState<
    ProductOfferingQualificationItem | undefined
  >();
  const [selectedProduct, setSelectedProduct] = useState<IOfferingResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const addressInfos = useAppSelector(selectAdressInfos);
  const addressInfosFormated = useAppSelector(selectAdressInfosFormated);

  const canDisplayPrice = useGuardHook('display_product_price');

  const isQuotation = (product: IOfferingResult | null) => {
    if (!product) {
      return false;
    }

    return product?.qualificationItemResult.toUpperCase() === 'TO_BE_STUDIED';
  };

  const previousStep = () => dispatch(changeStep(1));
  const nextStep = (optionsToOrder: OrderOptions) => {
    dispatch(resetAllSteps({ keepEnableds: true }));
    dispatch(
      callPlaceOrder({
        fdhRef: selectedProduct?.product.place[0].fdhRef,
        spvCode: selectedProduct?.product.productCharacteristic.find((pc) => pc.name === 'spvCode')
          ?.value.value,
        productOfferingId: selectedProduct?.product.productOffering.id,
        address: addressInfos,
        options: {
          duration: optionsToOrder?.duration || undefined,
          eqt: optionsToOrder?.eqt || undefined,
          bronze: optionsToOrder?.bronze || undefined,
          optionPlus: optionsToOrder?.optionPlus || undefined,
          trunks: optionsToOrder?.trunks || undefined,
          profile: optionsToOrder?.profile,
          gtr:
            (selectedProduct &&
              listOfProductOptions[
                optionsToOrder?.gtr || getDefaultGtrFromProduct(selectedProduct)
              ]) ||
            ''
        }
      } as IOrderCreationParams)
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(
          updateStepData({ step: OrderSteps.SELECT_PRODUCT, data: selectedProduct, nextStep: true })
        );
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          <Trans
            i18nKey="errorMessages.orderOffer"
            defaultValue="An error has occurred. If the problem persists contact <email>support.mon-espace@axione.fr</email>"
            components={{
              email: (
                <span
                  style={{
                    fontWeight: theme.fonts.weight.medium,
                    color: theme.palette.blue[500],
                    display: 'contents'
                  }}
                />
              )
            }}
          />
        );
        new ErrorMonitoring().captureException(Error('POST order exception'), {
          extra: error
        });
      });
  };

  const goToOrderForm = React.useCallback(() => {
    if (addressInfos && addressInfos.buildingRef) {
      dispatch(orderVerticality({ addressInfos }));
    }
    if (addressInfos && selectedProduct) {
      const isVerticality = isProductWithVerticality(selectedProduct);
      const type =
        selectedProduct?.qualificationItemResult.toUpperCase() === 'TO_BE_STUDIED'
          ? 'quotation'
          : 'order';

      if ((!isBusiness(selectedProduct) || !isZTD(selectedProduct)) && type !== 'quotation') {
        setDetailProductData(selectedProduct);
      } else if (isVerticality) {
        setVerticalityProductData({
          orderType: type,
          product: selectedProduct.product,
          isZTD: isZTD(selectedProduct)
        });
      } else {
        dispatch(
          orderProduct({
            type,
            selectedProduct: selectedProduct.product,
            addressInfos,
            verticalityInfos: {}
          })
        );
      }
    }
  }, [addressInfos, selectedProduct]);

  useEffect(() => {
    if (addressInfos) {
      setSelectedProduct(null);
      dispatch(resetOrderData());
      dispatch(getProducts(addressInfos));
    }
  }, [addressInfos]);

  useEffect(() => {
    if (orderError) {
      displayToastErrorWithMessage(
        t('errorMessages.wentWrong', `Something went wrong`),
        <ErrorMessageWithMail />
      );
    }
  }, [orderError]);

  const displayVerticality = () => {
    if (addressInfos && addressInfos.buildingRef) {
      dispatch(orderVerticality({ addressInfos }));
    }
    setShowVerticalityModal(true);
  };

  const columns = React.useMemo(() => productColumns(canDisplayPrice), [addressInfos]);

  const displayTable = productsLoading || (products && products.length > 0);

  const cardFooterButtonTitle = () => {
    if (products && products.length > 0 && isQuotation(products[0])) {
      return t('features.eligibility.launchStudy', 'Launch study');
    }
    return t('features.eligibility.order', 'Order');
  };

  const getRedirectionToEligibilityPath = () => {
    const pathName = window.location.href;
    return pathName;
  };

  const isOrderButtonDisabled = !(
    selectedProduct && !selectedProduct.eligibilityUnavailabilityReason
  );
  const spvName = getDSPFromProduct(selectedProduct?.product);
  return (
    <CustomCard
      header={
        <HeaderCardCustom
          titleHeader={t('features.eligibility.chooseProduct', 'Choose a product')}
          subTitleHeader={t(
            'features.eligibility.selectProduct',
            'Select the product that suits your needs'
          )}
        />
      }
      footer={
        <>
          <Button design="white" onClick={() => previousStep && previousStep()}>
            {t('component.button.previousStep', 'Previous Step')}
          </Button>
          <Button
            style={{
              marginLeft: 'auto'
            }}
            id={'select-product_next-step'}
            data-testid={'select-product_next-step'}
            disabled={isOrderButtonDisabled}
            onClick={() => !loading && goToOrderForm()}
          >
            {cardFooterButtonTitle()}
            {loading && <Spinner spinnerSize={1} reverse />}
          </Button>
        </>
      }
    >
      <StyledScroll>
        <Col sm="12">
          <AddressCard
            badge={spvName ? <Badge>{spvName}</Badge> : <></>}
            previousStep={previousStep}
          />
        </Col>
        {productsErrors && (
          <Col sm="12">
            <ErrorList productsErrors={productsErrors} />
          </Col>
        )}

        <Results
          exportFile={(i) => exportFile(getRedirectionToEligibilityPath(), addressInfosFormated)(i)}
          loading={!!productsLoading}
          columns={columns}
          data={products || []}
          setSelectedProduct={(p: IOfferingResult) => setSelectedProduct(p)}
          selectedProduct={selectedProduct}
          noDataDisplay={!displayTable ? NoDataComponent() : null}
          displayVerticality={displayVerticality}
        />

        <ProductDetailModalFactory
          selectedProduct={selectedProduct}
          detailProductData={detailProductData}
          handleClose={() => {
            dispatch(addSelectedOptionsToOrder({ duration: '36' }));
            setDetailProductData(undefined);
          }}
          nextStep={(optionsToOrder: OrderOptions) => {
            setLoading(true);
            nextStep(optionsToOrder);
          }}
          loading={loading}
        />

        {showVerticalityModal && (
          <ProductVerticalityModal
            showModal={showVerticalityModal}
            handleClose={() => setShowVerticalityModal(false)}
          />
        )}

        {verticalityProductData && (
          <>
            <ProductVerticalityModal
              orderType={verticalityProductData.orderType}
              selectedProduct={verticalityProductData.product}
              isZTD={verticalityProductData?.isZTD}
              showModal={!!verticalityProductData}
              handleClose={() => setVerticalityProductData(undefined)}
              showValidate
            />
          </>
        )}
        {selectedProduct && (
          <OrderInfoPanel
            isQuotation={isQuotation(selectedProduct)}
            navigateBackUrl={'/ordersBeta'}
          />
        )}
      </StyledScroll>
    </CustomCard>
  );
};

export default OrderOffer;
