import { Alert } from 'react-bootstrap';
import { ProductError } from '../interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorList: React.FC<{
  productsErrors: ProductError[];
}> = ({ productsErrors }) => {
  const { t } = useTranslation();
  return (
    <Alert variant="danger">
      <h5>
        {t(
          'features.eligibility.products.errorFetch',
          'Some of the products have not been fetched:'
        )}
      </h5>
      <ul className="mb-0">
        {productsErrors.map((err: ProductError, i: number) => {
          return (
            <li key={i}>
              {err.solution} : {err.message}
            </li>
          );
        })}
      </ul>
    </Alert>
  );
};

export default ErrorList;
