import React from 'react';
import styled from 'styled-components';
import theme from '@theme';

const TitleCardStyle = styled.div`
  color: ${theme.palette.grey[900]};
  font-family: ${theme.fonts.primary};
  font-size: ${theme.fonts.size.sm};
  font-weight: ${theme.fonts.weight.medium};
`;

const SubTitleStyle = styled.span`
  display: flex;
  color: ${theme.palette.grey[500]};
  font-size: ${theme.fonts.size.xs};
  font-family: ${theme.fonts.primary};
`;

const HeaderCardCustom: React.FC<{
  titleHeader: string;
  subTitleHeader: string;
}> = ({ titleHeader, subTitleHeader }) => {
  return (
    <div>
      <TitleCardStyle>{titleHeader}</TitleCardStyle>
      <SubTitleStyle>{subTitleHeader}</SubTitleStyle>
    </div>
  );
};

export default HeaderCardCustom;
