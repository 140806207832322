import { ProductOfferingItem } from '@features/eligibility/interfaces';

export const isUUID = (uuid: string): boolean => {
  const uuidNil = '00000000-0000-0000-0000-000000000000';

  if (uuid === uuidNil) return false;

  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};

export const getDSPFromProduct = (product?: ProductOfferingItem) => {
  const spvNameObj = product?.productCharacteristic?.find((e) => e.name === 'spvName');

  return spvNameObj?.value.value;
};
