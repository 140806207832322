import { Card, Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { StyledBuildingDetailsCardHeader, StyledRoundCard } from '../utils/SiteStatusStyled';
import {
  getBuildingDetails,
  resetBuildingDetails,
  selectSiteStatusState
} from '../reducer/siteStatusSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import BuildingDetailsCardBody from './BuildingDetailsCardBody';
import FixedSpinner from '../../../components/FixedSpinner';
import { selectAdressInfos } from '../../eligibility/eligibilitySlice';
import { useTranslation } from 'react-i18next';

const BuildingDetailsCard: React.FC = () => {
  const dispatch = useAppDispatch();
  const addressInfos = useAppSelector(selectAdressInfos);
  const { buildingDetails, buildingDetailsLoading } = useAppSelector(selectSiteStatusState);
  const [canDisplayDetails, setCanDisplayDetails] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (addressInfos && addressInfos.id) {
      dispatch(getBuildingDetails(addressInfos.id));
    }

    return () => {
      dispatch(resetBuildingDetails());
    };
  }, [addressInfos, dispatch]);

  useEffect(() => {
    setCanDisplayDetails(!!buildingDetails);
  }, [buildingDetails]);

  return (
    <StyledRoundCard>
      <StyledBuildingDetailsCardHeader>
        <Col md="12" className="small-padding-vertical">
          <h4>{t('features.siteStatus.buildingDetail.identity', 'Building identity')}</h4>
          <div>
            {t('features.siteStatus.buildingDetail.buildingId', 'Building id')} :{' '}
            {addressInfos && addressInfos.id}
          </div>
        </Col>
      </StyledBuildingDetailsCardHeader>
      <Card.Body style={{ paddingBottom: 5 }}>
        {canDisplayDetails && (
          <FixedSpinner loading={buildingDetailsLoading}>
            <Row className="small-padding-vertical">
              <Col md="3" className="medium-padding-horizontal">
                {t('features.siteStatus.buildingDetail.address', 'Address')}
              </Col>
              {addressInfos && (
                <Col md="9" className="no-padding">
                  {addressInfos.streetNr}
                  {addressInfos.streetNrSuffix}, {addressInfos.streetName}, {addressInfos.postcode}{' '}
                  {addressInfos.city}
                </Col>
              )}
            </Row>
            {buildingDetails && <BuildingDetailsCardBody buildingDetails={buildingDetails} />}
          </FixedSpinner>
        )}
      </Card.Body>
    </StyledRoundCard>
  );
};

export default BuildingDetailsCard;
