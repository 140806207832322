import styled, { css } from 'styled-components';

import theme, { ThemeProps } from '../../style/theme';

interface IThemedCard {
  hasFooter?: boolean;
  hasHeader?: boolean;
  selected?: boolean;
  hoverable?: boolean;
  theme: ThemeProps;
}

const defineCardBackground = (props: IThemedCard) => {
  if (props.selected) {
    return `background: ${props.theme.palette.blue[50]};
    border: 1px solid ${props.theme.palette.blue[500]};`;
  }
  return 'background: white;';
};

const defineCardHover = (props: IThemedCard) => {
  if (props.hoverable) {
    return `&:hover {background-color: ${props.theme.palette.blue[50]};}`;
  }
  return '';
};

const defaultCardStyle = () => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 24px;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    max-width: 800px;
    ${defineCardBackground}

    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    ${defineCardHover};
  `;
};

export const StyledCard = styled.div<IThemedCard>`
  ${defaultCardStyle()}
`;

const defineCardBodyPadding = (props: IThemedCard) => {
  if (props.hasHeader && props.hasFooter) {
    return '0px 24px';
  }
  if (props.hasHeader) {
    return '0px 24px 24px 24px';
  }
  if (props.hasFooter) {
    return '24px 24px 0px 24px';
  }
  return '24px';
};

const cardBodyStyle = () => {
  return css`
    position: relative;
    padding: ${defineCardBodyPadding};

    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
  `;
};

export const StyledCardBody = styled.div<IThemedCard>`
  ${cardBodyStyle()}
`;

export const StyledCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  padding: 12px 24px;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;

  background: ${theme.palette.grey[50]};
  border-radius: 0px 0px 8px 8px;
`;

export const StyledCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  padding: 24px 24px 0px 24px;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;
