import { Col, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import SelectSearchComponent, { SelectSearchProps } from './SelectSearchComponent';
import { StyledFormControl, StyledWrapComponent } from './SearchStyle';
import { clearSearchType, setSearchType } from '../eligibilitySlice';

import Icon from '@designSystem/Icon';
import InformationBlock from '@designSystem/InformationBlock';
import { Lightbulb } from 'react-bootstrap-icons';
import { decodeDataFrom } from '../helper';
import styled from 'styled-components';
import theme from '@theme';
import { useAppDispatch } from '../../../app/hooks';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IFormInputs {
  latitude: string;
  longitude: string;
  radius: string;
}

const StyledRange = styled.div`
  display: flex;
  align-items: center;

  input {
    width: calc(100% - 3rem);
    -webkit-appearance: none;
    appearance: none;
    height: 0.5rem;
    background: ${theme.palette.grey[200]};
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    cursor: pointer;
  }
  input::-webkit-slider-thumb,
  input::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    background: ${theme.palette.blue[500]};
    cursor: pointer;
  }
  span {
    display: inline-block;
    padding-left: 1rem;
    color: ${theme.palette.blue[500]};
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.sm};
  }
`;

const SearchByCoordinates: React.FC<{
  selectProps?: SelectSearchProps;
}> = ({ selectProps }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { register, watch, setValue } = useForm<IFormInputs>({
    mode: 'onChange',
    defaultValues: {
      latitude: `${decodeDataFrom(searchParams).latitude || ''}`,
      longitude: `${decodeDataFrom(searchParams).longitude || ''}`,
      radius: `${decodeDataFrom(searchParams).radius || '200'}`
    }
  });
  const latitude = watch('latitude');
  const longitude = watch('longitude');
  const radius = watch('radius');
  const { t } = useTranslation();
  const distanceInMeters = {
    minimum: 10,
    maximum: 500
  };

  const buildSearchType = () => {
    if (latitude && longitude && radius) {
      dispatch(
        setSearchType({
          type: 'coordinates',
          data: {
            latitude: latitude.replace(',', '.'),
            longitude: longitude.replace(',', '.'),
            radius
          }
        })
      );
    } else {
      dispatch(clearSearchType());
    }
  };

  useEffect(() => {
    if (latitude.includes(',') && latitude.length > 17) {
      const coords = latitude.split(',');

      setValue('latitude', parseFloat(coords[0]).toString());
      setValue('longitude', parseFloat(coords[1]).toString());
    }

    buildSearchType();
  }, [latitude]);

  useEffect(() => {
    buildSearchType();
  }, [longitude, radius]);

  return (
    <>
      <Row className="g-2">
        <Col md className="p-0">
          <StyledWrapComponent>
            <StyledFormControl
              type="text"
              {...register('latitude')}
              value={latitude}
              aria-label="latitude-input"
              placeholder={t('features.eligibility.search.latitude', `Latitude`)}
            />
            {selectProps && <SelectSearchComponent {...selectProps} />}
          </StyledWrapComponent>
        </Col>
      </Row>
      <Row className="g-2">
        <Col md className="p-0 mb-3">
          <StyledFormControl
            type="text"
            {...register('longitude')}
            value={longitude}
            aria-label="longitude-input"
            placeholder={t('features.eligibility.search.longitude', `Longitude`)}
            style={{ width: '70%', borderRadius: '6px' }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>{t('features.eligibility.search.radius', 'Radius:')}</label>
          <StyledRange>
            <input
              type="range"
              min={distanceInMeters.minimum}
              max={distanceInMeters.maximum}
              {...register('radius')}
            />
            <span>{radius}m</span>
          </StyledRange>
          <InformationBlock
            style={{ marginTop: '1.5rem', marginBottom: '2rem' }}
            type="custom"
            customIcon={
              <Icon noBackground size="minimal" variantDeclination={400} variant="blue">
                <Lightbulb />
              </Icon>
            }
            title={t(
              'features.eligibility.search.copypast',
              'You can copy-paste the full GPS coordinates into the Latitude field.'
            )}
          ></InformationBlock>
        </Col>
      </Row>
    </>
  );
};

export default SearchByCoordinates;
