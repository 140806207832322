import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { typesInterventionList } from '../filters/delegantsFiltersInfos';

const LegendValueWrapper = styled.div`
  display: flex;
  min-height: 50px;
  align-items: left;
  width: 100%;
`;

const LegendDot = styled.div`
  min-height: 15px;
  height: 15px;
  min-width: 15px;
  width: 15px;
  border-radius: 15px;
  border: 1px solid white;
  margin-top: 2px;
`;

const LegendValue = styled.div`
  width: 100%;
  font-size: 0.875rem;
  text-align: left;
  margin-left: 10px;
`;

const LeafletLegend = () => {
  return (
    <Col md={2}>
      <div className="mt-1">
        {typesInterventionList.map((typeIntervention) => {
          return (
            <LegendValueWrapper
              key={`marker-legend-key-${typeIntervention.name}`}
              className="justify-content-center"
            >
              <LegendDot className={`${typeIntervention.color}`}></LegendDot>
              <LegendValue>{typeIntervention.displayName}</LegendValue>
            </LegendValueWrapper>
          );
        })}
      </div>
    </Col>
  );
};

export default LeafletLegend;
