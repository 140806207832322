import React, { ReactChild } from 'react';

import styled from 'styled-components';
import theme from '@theme';

const StyledFormSectionHeaderTitle = styled.div`
  color: ${theme.palette.grey[500]};
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.base};
`;

const StyledFormSectionHeaderSubTitle = styled.div`
  color: ${theme.palette.grey[400]};
  font-weight: ${theme.fonts.weight.regular};
  font-size: ${theme.fonts.size.sm};
`;

const FormSectionHeader: React.FC<{
  headerTitle: ReactChild | string | null;
  headerSubtitle?: ReactChild | string | null;
}> = ({ headerTitle, headerSubtitle }) => {
  return (
    <>
      <StyledFormSectionHeaderTitle>{headerTitle}</StyledFormSectionHeaderTitle>
      {headerSubtitle && (
        <StyledFormSectionHeaderSubTitle>{headerSubtitle}</StyledFormSectionHeaderSubTitle>
      )}
    </>
  );
};

export default FormSectionHeader;
