import { ReactChild } from 'react';
import styled from 'styled-components';
import theme from '@theme';

const StyledContainerMaxWidthAndBorder = styled.div`
  min-width: 800px;
  width: 100%;
  border: 1px solid ${theme.palette.grey[200]};
  border-radius: 8px;
  border-width: 1px 1px 1px 1px;
`;

const StyledContainerCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.table-max-width-margin {
    margin: 24px;
  }

  &.table-border-top {
    table {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    th,
    thead,
    tr:first-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  &.table-border-bottom {
    table {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    tr:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const TableContainerWithBorderMaxWidth = ({
  children,
  className
}: {
  children?: ReactChild;
  className?: string;
}) => {
  return (
    <StyledContainerCentered className={className}>
      <StyledContainerMaxWidthAndBorder>{children}</StyledContainerMaxWidthAndBorder>
    </StyledContainerCentered>
  );
};

export default TableContainerWithBorderMaxWidth;
