import { IThemedColors, IThemedComponent } from '../designInterfaces';

export interface IThemedButton extends IThemedComponent {
  design?: 'plain' | 'white' | 'reverse';
  size?: 'sm' | 'normal';
  nopadding?: boolean;
  selected?: boolean;
}

export const buttonColors = (props: IThemedButton) => {
  switch (props.design) {
    case 'white':
      return {
        backgroundColor: props.selected ? props.theme.palette['blue'][50] : 'white',
        hoverBackgroundColor: props.theme.palette['grey'][100],
        focusOutlineColor: props.theme.palette[props.variant][300],
        border: props.selected
          ? props.theme.palette['blue'][500]
          : props.theme.palette['grey'][300],
        color: props.selected ? props.theme.palette['blue'][500] : props.theme.palette['grey'][700]
      } as IThemedColors;
    case 'reverse':
      return {
        backgroundColor: props.theme.palette[props.variant][50],
        hoverBackgroundColor: props.theme.palette[props.variant][100],
        focusOutlineColor: props.theme.palette[props.variant][300],
        border: 'transparent',
        color: props.theme.palette[props.variant][500]
      } as IThemedColors;

    case 'plain':
    default:
      return {
        backgroundColor: props.theme.palette[props.variant][500],
        hoverBackgroundColor: props.theme.palette[props.variant][600],
        focusOutlineColor: props.theme.palette[props.variant][300],
        border: 'transparent',
        color: 'white'
      } as IThemedColors;
  }
};

export const disabledColor = (props: IThemedButton) => {
  switch (props.design) {
    case 'white':
      return {
        backgroundColor: props.theme.palette['grey'][50],
        border: props.theme.palette['grey'][300],
        color: props.theme.palette['grey'][400]
      } as IThemedColors;
    case 'reverse':
      return {
        backgroundColor: props.theme.palette[props.variant][50],
        border: 'transparent',
        color: props.theme.palette[props.variant][200]
      } as IThemedColors;

    case 'plain':
    default:
      return {
        backgroundColor: props.theme.palette['grey'][200],
        border: 'transparent',
        color: 'white'
      } as IThemedColors;
  }
};
