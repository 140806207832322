import { Query } from '@cubejs-client/core';
import { Segment } from '../interfaces';
import { DateManager } from '../../../utils/DateManager';

const lastMonth = new DateManager().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
const oneYearBeforeLastMonth = new DateManager()
  .subtract(1, 'year')
  .subtract(1, 'month')
  .startOf('month')
  .format('YYYY-MM-DD');

export const queries = {
  delaiMoyen: {
    measures: [
      'VFtthNbRaccoDelaimoyen.modeOI',
      'VFtthNbRaccoDelaimoyen.modeSTOC',
      'VFtthNbRaccoDelaimoyen.delaiMoyenOI',
      'VFtthNbRaccoDelaimoyen.delaiMoyenSTOC'
    ],
    order: [['VFtthNbRaccoDelaimoyen.periode', 'asc']],
    dimensions: ['VFtthNbRaccoDelaimoyen.periode']
  },
  raccoSumDpt: {
    measures: ['VFtthNbRaccoDelaimoyenDpt.raccoSumDpt'],
    order: {
      'VFtthNbRaccoDelaimoyenDpt.raccoSumDpt': 'asc'
    },
    dimensions: ['VFtthNbRaccoDelaimoyenDpt.dpt']
  },
  carteAjournements: {
    measures: ['VMapAdjournments.listeAjournementsCoordonnees'],
    order: {
      'VMapAdjournments.typeIntervention': 'asc'
    },
    dimensions: [
      'VMapAdjournments.wgs84X',
      'VMapAdjournments.wgs84Y',
      'VMapAdjournments.typeIntervention',
      'VMapAdjournments.numeroVoieImmeuble',
      'VMapAdjournments.typeVoieImmeuble',
      'VMapAdjournments.nomVoieImmeuble',
      'VMapAdjournments.codePostalImmeuble',
      'VMapAdjournments.communeImmeuble',
      'VMapAdjournments.dateCreation',
      'VMapAdjournments.status',
      'VMapAdjournments.oc',
      'VMapAdjournments.plaque',
      'VMapAdjournments.referencePm',
      'VMapAdjournments.referencePbo',
      'VMapAdjournments.identifiantImmeuble',
      'VMapAdjournments.id'
    ]
  },
  carteNbJoursOuvresMoyen: {
    measures: ['NbJoursOuvresMoyenAjournement.NbJoursOuvresMoyen'],
    order: {
      'NbJoursOuvresMoyenAjournement.typeIntervention': 'asc'
    },
    dimensions: ['NbJoursOuvresMoyenAjournement.typeIntervention']
  },
  tauxBrut: {
    measures: ['TTauxBrutEspaceDelegant.tauxBrut']
  },
  nbJoursOuvresMoyenAjournement: {
    measures: [
      'NbJoursOuvresMoyenAjournement.Backlog',
      'NbJoursOuvresMoyenAjournement.NbJoursOuvresMoyen'
    ],
    order: [['NbJoursOuvresMoyenAjournement.Backlog', 'desc']],
    dimensions: ['NbJoursOuvresMoyenAjournement.typeIntervention'],
    segments: ['NbJoursOuvresMoyenAjournement.shortListInterventions']
  },
  denomTauxReelRT: {
    measures: ['DenomTauxReelRT.DenomTauxReelRT']
  },
  //
  nombreAjournementEnCours: {
    measures: ['NombreAjournementEnCours.EnCours'],
    dimensions: ['NombreAjournementEnCours.departement']
  },
  tauxBrutparOC: {
    measures: ['TTauxBrutEspaceDelegant.tauxBrut'],
    timeDimensions: [
      {
        dimension: 'TTauxBrutEspaceDelegant.dateCalcul',
        granularity: 'month',
        dateRange: [oneYearBeforeLastMonth, lastMonth]
      }
    ],
    order: {
      'TTauxBrutEspaceDelegant.tauxBrut': 'desc'
    },
    dimensions: ['TTauxBrutEspaceDelegant.oc']
  },
  ticketEvolution: {
    measures: [
      'TicketEvolution.Backlog_date_ttr',
      'TicketEvolution.Crees',
      'TicketEvolution.Clotures'
    ],
    timeDimensions: [
      {
        dimension: 'TicketEvolution.mois',
        granularity: 'month',
        dateRange: [oneYearBeforeLastMonth, lastMonth]
      }
    ],
    filters: [],
    dimensions: [],
    timezone: 'Europe/Paris'
  }
} as { [key: string]: Query };

export const transformSegmentForCube = (
  segmentsList: Segment[] | undefined,
  cubeName: string,
  excludedSegment?: Array<'oc' | 'dsp_dep' | 'intervention'>
) => {
  if (segmentsList === undefined) return [''];

  return segmentsList
    .map((segment) => {
      return excludedSegment?.includes(segment.name) ? '' : `${cubeName}.${segment.key}`;
    })
    .filter((e) => e !== '');
};
