import { CaretDown, CaretUp } from 'react-bootstrap-icons';

import { Ticket } from '../utils/siteStatusInterfaces';
import { getCategoryTranslation } from '../utils/siteStatusUtils';
import { i18n } from '@i18n';
import { DateManager } from '../../../utils/DateManager';
const translateStatus = (statusString: string) => {
  switch (statusString) {
    case 'resolved':
      return i18n.t('features.siteStatus.tickets.resolved', 'Resolved');
    case 'rejected':
      return i18n.t('features.siteStatus.tickets.rejected', 'Rejected');
    default:
      return statusString;
  }
};

export const siteStatusTicketsListColumns = (
  showTicketsDetails: (_arg1: Ticket | null) => void,
  isTicketDetailed: number | null
) => {
  return [
    {
      Header: i18n.t('features.siteStatus.tickets.ticketNumber', 'Ticket n°'),
      accessor: 'id'
    },
    {
      Header: i18n.t('features.siteStatus.tickets.category', 'Category'),
      accessor: 'category',
      Cell: ({ value }: { value: string }) => getCategoryTranslation(value)
    },
    {
      Header: i18n.t('features.siteStatus.tickets.createdDate', 'Created date'),
      accessor: 'created',
      Cell: ({ value }: { value: Date }) => new DateManager(value).format('DD/MM/YYYY')
    },
    {
      Header: i18n.t('features.siteStatus.tickets.state', 'State'),
      accessor: 'status',
      Cell: ({ value }: { value: string }) => translateStatus(value)
    },
    {
      Header: i18n.t('features.siteStatus.tickets.lastUpdate', 'Last update'),
      accessor: 'last_updated',
      Cell: ({ value }: { value: Date }) =>
        value ? new DateManager(value).format('DD/MM/YYYY') : ''
    },
    {
      Header: i18n.t('features.siteStatus.tickets.associationStart', 'Association start'),
      accessor: 'date_de_debut_ordre_de_travail',
      Cell: ({ value }: { value: Date }) =>
        value ? new DateManager(value).format('DD/MM/YYYY') : ''
    },
    {
      Header: i18n.t('features.siteStatus.tickets.associationStatus', 'Association status'),
      accessor: 'statut_de_l_ordre_de_travail'
    },
    {
      Header: i18n.t('features.siteStatus.tickets.associationType', 'Intervention type'),
      accessor: 'domaine_d_intervention'
    },
    {
      Header: i18n.t('features.siteStatus.tickets.code', 'Resolved code'),
      accessor: 'code_de_cloture'
    },
    {
      Header: i18n.t('features.siteStatus.tickets.details', 'Ticket detail'),
      accessor: 'row',
      disableSortBy: true,
      Cell: ({ row }: { row: { original: Ticket } }) => (
        <div
          className="clickable text-center"
          onClick={() => {
            if (row.original.id === isTicketDetailed) {
              showTicketsDetails(null);
            } else {
              showTicketsDetails(row.original);
            }
          }}
        >
          {row.original.id === isTicketDetailed ? <CaretDown size={20} /> : <CaretUp size={20} />}
        </div>
      )
    }
  ];
};
