import { CalendarFill, ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { CaptionProps, DateRange, DayPicker, useNavigation } from 'react-day-picker';
import { Card, Col, Modal, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import Button from '@designSystem/Button';
import CalendarFormStepTwoSlot from './CalendarFormStepTwoSlot';
import FormSectionHeader from '../../../components/FormSectionHeader';
import { ICreateCalendarForm } from '../utils/interfaces';
import InformationBlock from '@designSystem/InformationBlock';
import InputWrapper from '@designSystem/InputWrapper';
import { calendarDatePickerStyle } from '../utils/calendarDatePickerStyle';
import styled from 'styled-components';
import theme from '@theme';
import { i18n } from '@i18n';
import { useTranslation } from 'react-i18next';
import { DateManager } from '../../../utils/DateManager';

const StyledCardBody = styled(Card.Body)`
  padding: 24px 24px;
  color: ${theme.palette.grey[700]};
  font-size: ${theme.fonts.size.sm};
`;

const StyledCalendarInputsCustomRow = styled(Row)`
  margin-top: 1.5rem;
`;

const StyledCalendarInputsCustomRowMedium = styled(Row)`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`;

const StyledDayPickerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: 331px;
  height: 40px;

  justify-content: space-between;

  background: ${theme.palette.grey[50]};
  border-radius: 6px;

  color: ${theme.palette.blue[500]};
`;

const StyledCalendarFormDateInputIcon = styled.span`
  position: absolute;
  left: 13px;
  top: 6px;
`;

const daysOfWeek = [
  {
    label: () => i18n.t('features.calendars.calendarForm.monday', 'Monday'),
    value: 'slotsForMonday'
  },
  {
    label: () => i18n.t('features.calendars.calendarForm.tuesday', 'Tuesday'),
    value: 'slotsForTuesday'
  },
  {
    label: () => i18n.t('features.calendars.calendarForm.wednesday', 'Wednesday'),
    value: 'slotsForWednesday'
  },
  {
    label: () => i18n.t('features.calendars.calendarForm.thursday', 'Thursday'),
    value: 'slotsForThursday'
  },
  {
    label: () => i18n.t('features.calendars.calendarForm.friday', 'Friday'),
    value: 'slotsForFriday'
  }
];

function CustomCaption(props: CaptionProps) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  return (
    <StyledDayPickerHeader>
      <ChevronLeft
        style={{ cursor: 'pointer' }}
        color={'blue'}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      />
      {new DateManager(props.displayMonth).format('MMMM YYYY')}
      <ChevronRight
        color={'blue'}
        style={{ cursor: 'pointer' }}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      />
    </StyledDayPickerHeader>
  );
}

const CalendarFormStepTwo: React.FC<{
  formValues: ICreateCalendarForm;
  register: CallableFunction;
  setValue: CallableFunction;
  isAnUpdate?: boolean;
}> = ({ formValues, setValue, register, isAnUpdate = false }) => {
  const defaultSelected: DateRange = {
    from: undefined,
    to: undefined
  };

  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const [inputRange, setInputRange] = useState<DateRange | undefined>(defaultSelected);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  useEffect(() => {
    setValue('dateRange', getRangeStringFromState());
  }, []);

  const getRangeStringFromState = () => {
    const fromDateString = new DateManager(inputRange?.from).format('DD/MM/YYYY');
    const toDateString = new DateManager(inputRange?.to).format('DD/MM/YYYY');
    return `${fromDateString} — ${toDateString}`;
  };

  return (
    <StyledCardBody>
      <FormSectionHeader
        headerTitle={t('features.calendars.calendarForm.indicateDate', 'Indicate dates')}
      />
      <StyledCalendarInputsCustomRow>
        <Col md={6} sm={12}>
          <InputWrapper
            inputLabel={t('features.calendars.calendarForm.chooseDate', 'Choose dates')}
          >
            <>
              <input
                style={{ paddingLeft: 35 }}
                type="text"
                id="dateRange"
                onClick={() => {
                  setIsModalOpen(true);
                }}
                {...register('dateRange')}
                value={getRangeStringFromState()}
                aria-label="dateRange-input"
              />
              <StyledCalendarFormDateInputIcon>
                <CalendarFill color={theme.palette.grey[400]} />
              </StyledCalendarFormDateInputIcon>
            </>
          </InputWrapper>
        </Col>
      </StyledCalendarInputsCustomRow>
      <StyledCalendarInputsCustomRow>
        <FormSectionHeader
          headerTitle={t(
            'features.calendars.calendarForm.availableSlot',
            'Available slots and days'
          )}
          headerSubtitle={
            isAnUpdate
              ? t(
                  'features.calendars.calendarForm.makeBulk',
                  'Make a bulk update of available slots and days'
                )
              : t(
                  'features.calendars.calendarForm.maxAppointments',
                  'Indicate max appointments per day on selected days'
                )
          }
        />
      </StyledCalendarInputsCustomRow>
      <StyledCalendarInputsCustomRowMedium>
        <Col md={8} />
        <Col md={2}>{t('features.calendars.calendarForm.morning', 'Morning')}</Col>

        <Col md={2}>{t('features.calendars.calendarForm.afternoon', 'Afternoon')}</Col>
      </StyledCalendarInputsCustomRowMedium>
      {daysOfWeek.map((dayOfWeek, i) => (
        <CalendarFormStepTwoSlot
          dayOfWeek={dayOfWeek}
          key={`day-of-week-${i}`}
          register={register}
          formValues={formValues}
        />
      ))}
      {isAnUpdate && (
        <InformationBlock
          title={t(
            'features.calendars.calendarForm.displayedSlot',
            'Displayed slots values are fetched from last week of this calendar'
          )}
        />
      )}
      <Modal
        show={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
        }}
      >
        <Modal.Body className="d-flex" style={{ maxWidth: 351, padding: '20px 10px' }}>
          <>
            <style>{calendarDatePickerStyle}</style>
            <DayPicker
              mode="range"
              components={{
                Caption: CustomCaption
              }}
              selected={range}
              onSelect={setRange}
              defaultMonth={new Date()}
            />
          </>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between flex-row">
          <Button design="white" onClick={() => setIsModalOpen(false)}>
            {t('component.button.back', 'Back')}
          </Button>
          <Button
            onClick={() => {
              setIsModalOpen(false);
              setInputRange(range);
            }}
          >
            {t('component.button.save', 'Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </StyledCardBody>
  );
};

export default CalendarFormStepTwo;
