import { Check, ExclamationTriangleFill, Info, X } from 'react-bootstrap-icons';
import theme, { ThemeProps, ThemeVariants } from '../../style/theme';

import Icon from '../Icon';
import LinkOrAnchor from '../LinkOrAnchor';
import { ReactChild } from 'react';
import { informationBlockDefaultStyle } from './informationBlockDesignStyles';
import styled from 'styled-components';

export type IThemedInformationBlockType = 'default' | 'warning' | 'error' | 'success' | 'custom';

export interface IThemedInformationBlock {
  type: IThemedInformationBlockType;
  theme: ThemeProps;
  backgroundVariant?: ThemeVariants;
  textVariant?: ThemeVariants;
  isReduced?: boolean;
}

const StyledInformationBlockIconContainer = styled(Icon)`
  width: 16px;
  height: 16px;
  text-align: center;
`;

const informationBlockDefaultIcons = (
  type: IThemedInformationBlockType,
  variant: ThemeVariants,
  action: CallableFunction,
  reduced = true,
  customIcon?: ReactChild
) => {
  switch (type) {
    case 'warning':
      return (
        <StyledInformationBlockIconContainer
          background={false}
          variant={variant}
          size="minimal"
          variantDeclination={400}
          className={reduced ? 'clickable' : ''}
          onClick={() => action()}
        >
          <ExclamationTriangleFill />
        </StyledInformationBlockIconContainer>
      );

    case 'error':
      return (
        <StyledInformationBlockIconContainer
          background
          round
          variant={variant}
          size="minimal"
          variantDeclination={400}
          className={reduced ? 'clickable' : ''}
          onClick={() => action()}
        >
          <X />
        </StyledInformationBlockIconContainer>
      );
    case 'success':
      return (
        <StyledInformationBlockIconContainer
          background
          round
          variant={variant}
          size="minimal"
          variantDeclination={400}
          className={reduced ? 'clickable' : ''}
          onClick={() => action()}
        >
          <Check />
        </StyledInformationBlockIconContainer>
      );
    case 'custom':
      return customIcon;
    default:
      return (
        <StyledInformationBlockIconContainer
          background
          round
          variant={variant}
          size="minimal"
          variantDeclination={400}
          className={reduced ? 'clickable' : ''}
          onClick={() => action()}
        >
          <Info />
        </StyledInformationBlockIconContainer>
      );
  }
};

const informationBlockDefaultVariants = (type: IThemedInformationBlockType) => {
  switch (type) {
    case 'success':
      return 'green' as ThemeVariants;
    case 'error':
      return 'red' as ThemeVariants;
    case 'warning':
      return 'orange' as ThemeVariants;
    case 'default':
    default:
      return 'blue' as ThemeVariants;
  }
};

export { informationBlockDefaultIcons, informationBlockDefaultVariants };

const getFinalBackgroundVariant = (props: IThemedInformationBlock) => {
  if (props.backgroundVariant) {
    return props.backgroundVariant;
  }
  return informationBlockDefaultVariants(props.type);
};

const getFinalTextVariant = (props: IThemedInformationBlock) => {
  if (props.textVariant) {
    return props.textVariant;
  }
  return informationBlockDefaultVariants(props.type);
};

export const StyledInformationBlockContainer = styled.div<IThemedInformationBlock>`
  ${informationBlockDefaultStyle()}
  background-color: ${(props) => theme.palette[getFinalBackgroundVariant(props)][50]};
  color: ${(props) => theme.palette[getFinalTextVariant(props)][700]};
  max-width: ${(props) => (props.isReduced ? '50px' : 'none')};
  display: ${(props) => (props.isReduced ? 'none' : 'flex')};
`;

export const StyledInformationBlockFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-top: 10px;
`;

export const StyledInformationBlockFooterRightLink = styled(LinkOrAnchor)`
  margin-left: 31px;
`;

export const StyledInformationBlockCross = styled(Icon)<IThemedInformationBlock>`
  color: ${(props) => theme.palette[getFinalTextVariant(props)][400]};
  position: absolute;
  right: 16px;
`;

export const StyledInformationBlockTextContainer = styled.div<IThemedInformationBlock>`
  display: flex;
  flex-direction: column;
  font-size: ${theme.fonts.size.sm};
`;

export const StyledInformationBlockTitle = styled.div<IThemedInformationBlock>`
  font-weight: ${theme.fonts.weight.medium};
  color: ${(props) => theme.palette[getFinalTextVariant(props)][700]};
  padding: 0 16px 0 0;
`;

export const StyledInformationBlockDescription = styled.div<IThemedInformationBlock>`
  color: ${(props) => theme.palette[getFinalTextVariant(props)][700]};
  font-weight: ${theme.fonts.weight.regular};
  margin-top: 0.5rem;
`;
