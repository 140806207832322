import { Col, Row } from 'react-bootstrap';

import { ICreateCalendarForm } from '../utils/interfaces';
import InputWrapper from '@designSystem/InputWrapper';
import React from 'react';
import Switch from '@designSystem/SwitchInput';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledCalendarInputsCustomRowMedium = styled(Row)`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`;

const CalendarFormStepTwoSlot: React.FC<{
  formValues: ICreateCalendarForm;
  register: CallableFunction;
  dayOfWeek: {
    label: () => string;
    value: string;
  };
}> = ({ dayOfWeek, formValues, register }) => {
  const { t } = useTranslation();
  return (
    <StyledCalendarInputsCustomRowMedium>
      <Col md={4}>
        <label>{dayOfWeek.label()}</label>
      </Col>
      <Col md={4} className="d-flex">
        <Switch
          inputLabel={
            formValues[dayOfWeek.value].enabled
              ? t('enums.orderState.status.enabled', 'Enabled')
              : t('enums.orderState.status.disabled', 'Disabled')
          }
        >
          <input
            type="checkbox"
            value={formValues[dayOfWeek.value].enabled}
            {...register(`${dayOfWeek.value}.enabled`)}
          />
        </Switch>
      </Col>
      <Col md={2}>
        <InputWrapper>
          <input
            disabled={!formValues[dayOfWeek.value].enabled}
            type="number"
            min="0"
            {...register(`${dayOfWeek.value}.morning`)}
            value={formValues[dayOfWeek.value].morning}
            aria-label={`${dayOfWeek.value}-input`}
          />
        </InputWrapper>
      </Col>
      <Col md={2}>
        <InputWrapper>
          <input
            disabled={!formValues[dayOfWeek.value].enabled}
            type="number"
            min="0"
            {...register(`${dayOfWeek.value}.afternoon`)}
            value={formValues[dayOfWeek.value].afternoon}
            aria-label={`${dayOfWeek.value}-input`}
          />
        </InputWrapper>
      </Col>
    </StyledCalendarInputsCustomRowMedium>
  );
};

export default CalendarFormStepTwoSlot;
