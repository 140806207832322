import { Container, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { ArrowLeftCircle } from 'react-bootstrap-icons';
import CalendarSearchForm from '@features/appointment/calendarSearch/CalendarSearchForm';
import { resetCalendar } from '@features/appointment/reducer/appointmentSlice';
import { useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';

const CalendarSearch: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { calendar } = useSelector((state: RootStateOrAny) => state.appointment);

  useEffect(() => {
    if (calendar && calendar.id) {
      navigate('/erdv/appointment/create/step2');
    }
  }, [calendar]);

  const returnOnCalendarForm = () => {
    dispatch(resetCalendar());
    navigate('/erdv');
  };

  return (
    <Container fluid className="main-content-container pt-4">
      <h3 onClick={() => returnOnCalendarForm()} className="clickable">
        <ArrowLeftCircle />
      </h3>
      <Row className="justify-content-md-center">
        <CalendarSearchForm />
      </Row>
    </Container>
  );
};

export default CalendarSearch;
