import { Dispatch, SetStateAction } from 'react';

import CardOrder from './CardOrder';
import { CardOrderDraft } from './CardOrderDraft';
import { Order } from '../utils/constants';
import { ProductOrderItem } from '@features/order/utils/orderInterfaces';
import { i18n } from '@i18n';
import { setHighLightedText } from '../utils/OrderHelper';
import styled from 'styled-components';
import theme from '@theme';

interface CardOrderContainerProps {
  order: Order;
  toHighLight: string | undefined;
  setOrderIdToDelete?: Dispatch<SetStateAction<string | undefined>>;
  setOrderId?: Dispatch<SetStateAction<string | undefined>>;
  setOrderInfos?: Dispatch<
    SetStateAction<{
      orderId: string | undefined;
      buildingId: string | undefined;
      productOrderItem: ProductOrderItem[] | undefined;
    }>
  >;
  setOrderIdToUpdate?: Dispatch<SetStateAction<string | undefined>>;
}
const makeClientName = (name: string, textToHighLight: string | undefined, tooltip = false) => {
  if (name) {
    return setHighLightedText(name, textToHighLight, tooltip);
  }
  return i18n.t('features.order.orderLit.customerMissing', 'Customer missing');
};

const makeReferenceText = (
  commercialOperator: string,
  textToHighLight: string | undefined,
  tooltip = false,
  isDraft = false
) => {
  if (commercialOperator) {
    return setHighLightedText(commercialOperator, textToHighLight, tooltip);
  }
  return isDraft ? (
    <span style={{ color: `${theme.palette.grey[400]}` }}>
      {i18n.t('features.order.orderLit.referenceMissing', 'Reference missing')}
    </span>
  ) : (
    <span style={{ color: `${theme.palette.grey[400]}` }}>
      {i18n.t('features.order.orderLit.referenceFetching', 'Reference fetching')}
    </span>
  );
};

export const CardOrderContainer = ({
  order,
  toHighLight,
  setOrderIdToDelete,
  setOrderInfos,
  setOrderId,
  setOrderIdToUpdate
}: CardOrderContainerProps) => {
  const isDraft = order.state.status === 'draft';
  if (isDraft)
    return (
      <CardOrderDraft
        makeReferenceText={makeReferenceText}
        toHighLight={toHighLight}
        order={order}
        makeClientName={makeClientName}
        setOrderIdToDelete={setOrderIdToDelete}
      />
    );
  return (
    <CardOrder
      makeReferenceText={makeReferenceText}
      toHighLight={toHighLight}
      order={order}
      makeClientName={makeClientName}
      setOrderInfos={setOrderInfos}
      setOrderId={setOrderId}
      setOrderIdToUpdate={setOrderIdToUpdate}
    />
  );
};

export const CardOrderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${theme.fonts.size.xs};
  font-weight: ${theme.fonts.weight.medium};
  line-height: ${theme.fonts.spacing.xs};

  .info {
    .sub-info {
      color: ${theme.palette.grey[800]};
      align-items: center;
      gap: 0;
    }
    &-draft {
      display: grid;
      grid-template-columns: repeat(2, minmax(150px, auto));
      gap: 1rem;
      color: ${theme.palette.grey[800]};
    }

    &-tracking {
      display: grid;
      gap: 1rem;
      grid-template-columns: 205px 200px 250px 150px;
    }
  }

  .external-reference {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    padding-right: 10px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 50px;
    gap: 0.5rem;
  }

  .icon-delete:hover {
    cursor: pointer;
    background-color: ${theme.palette.red[200]};
  }

  .icon-link:hover {
    cursor: pointer;
    background-color: ${theme.palette.blue[200]};
  }

  .text-faded {
    & > div {
      color: ${theme.palette.grey[400]};
    }
    .client-icon {
      color: ${theme.palette.grey[300]};
    }
  }

  .highlighted_text {
    background-color: ${theme.palette.blue[50]};
    color: ${theme.palette.blue[500]};
  }

  .editable-appointment {
    color: ${theme.palette.blue[500]};
    background: ${theme.palette.blue[100]};
    border-radius: 4px;
    padding: 2px 4px;
    width: max-content;
    cursor: pointer;
    align-items: center;

    svg {
      fill: ${theme.palette.blue[500]};
    }
  }

  .appointment-container {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: max-content max-content max-content;
    align-items: center;
  }
`;

export const VerticalLineStyled = styled.div<{ height?: number }>`
  background-color: ${theme.palette.grey[200]};
  height: ${(props) => props.height ?? 16}px;
  width: 1px;
  margin: 0 8px;
`;

export const CardBaseOrderStyled = styled.div`
  background: #ffffff;
  padding: 10px 24px;
  border-radius: 9px;
  border: 1px solid ${theme.palette.grey[100]};
  min-height: 62px;
  &:hover {
    background: ${theme.palette.blue[50]};
  }
`;

export const BoxWithSeparator = styled.div`
  background: linear-gradient(${theme.palette.grey[200]} 20px, transparent 40%) right/1px 100%
    no-repeat;
  flex-basis: fit-content;
  position: relative;
  gap: 5px;
`;

export const BoxWithoutSeparator = styled.div`
  flex-basis: fit-content;
  gap: 5px;
`;
