import { combineReducers, configureStore } from '@reduxjs/toolkit';

import appointmentReducer from '@features/appointment/reducer/appointmentSlice';
import calendarsReducer from '@features/calendars/reducer/calendarSlice';
import eligibilityReducer from '@features/eligibility/eligibilitySlice';
import navigationReducer from '../components/layout/navigationReducer';
import orderReducer from '@features/order/reducer/orderSlice';
import profileReducer from '@features/profiles/profileSlice';
import referenceReducer from '@features/zonesGeoReferences/reducer/referencesSlice';
import siteStatusReducer from '@features/siteStatus/reducer/siteStatusSlice';
import zonesGeoReducer from '@features/zonesGeo/reducer/zonesGeoSlice';

export const reducer = combineReducers({
  appointment: appointmentReducer,
  calendars: calendarsReducer,
  eligibility: eligibilityReducer,
  navigation: navigationReducer,
  order: orderReducer,
  siteStatus: siteStatusReducer,
  profile: profileReducer,
  zoneGeoReferences: referenceReducer,
  zonesGeo: zonesGeoReducer
});

export const store = configureStore({ reducer });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
