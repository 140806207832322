import * as CalendarsAPIService from './calendarAPI';

import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ICalendarAPI,
  ICalendarLastWeekSlotsForForm,
  ICalendarSaveAPIForm,
  ICreateCalendarGetSlotsAPIForm
} from '../utils/interfaces';

import { IPaginationSort } from '../../../utils/interfaces';
import { displayToastErrorWithMessage } from '../../../utils/toastHelper';
import { i18n } from '@i18n';
import toast from 'react-hot-toast';

interface CalendarsState {
  calendarsListLoading: boolean;
  calendarsList: any[];
  currentCalendar: ICalendarAPI | null;
  currentCalendarLoading: boolean;
  calendarLastWeekSlots: ICalendarLastWeekSlotsForForm | null;
  calendarsTotalCount: number | null;
}

const initialState: CalendarsState = {
  calendarsListLoading: false,
  calendarsList: [],
  calendarsTotalCount: null,
  currentCalendar: null,
  currentCalendarLoading: false,
  calendarLastWeekSlots: null
};

export const calendarListFetch = createAsyncThunk(
  'calendar/fetch',
  async (
    arg: { currentPage: number; sort?: IPaginationSort; search?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await CalendarsAPIService.calendarListFetch(arg);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const calendarFetchById = createAsyncThunk(
  'calendar/fetchById',
  async (arg: { calendarId: string }, { rejectWithValue }) => {
    try {
      const response = await CalendarsAPIService.calendarFetchById(arg);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const calendarCreate = createAsyncThunk(
  'calendar/create',
  async (args: ICalendarSaveAPIForm, { rejectWithValue }) => {
    try {
      const response = await CalendarsAPIService.calendarCreate(args);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const calendarUpdate = createAsyncThunk(
  'calendar/update',
  async (args: ICalendarSaveAPIForm, { rejectWithValue }) => {
    try {
      const response = await CalendarsAPIService.calendarUpdate(args);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const calendarTimeSlotsBulk = createAsyncThunk(
  'calendar/getSlots',
  async (args: ICreateCalendarGetSlotsAPIForm, { rejectWithValue }) => {
    try {
      const response = await CalendarsAPIService.calendarTimeSlotsBulk(args);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const calendarDelete = createAsyncThunk(
  'calendar/delete',
  async (params: { id: number }, { rejectWithValue }) => {
    try {
      const response = await CalendarsAPIService.calendarDelete(params.id);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

const calendarsSlice = createSlice({
  name: 'calendars',
  initialState,
  reducers: {
    setCalendarLastWeekSlots: (state, action) => {
      state.calendarLastWeekSlots = action.payload;
    },
    resetCurrentCalendar: (state) => {
      state.currentCalendar = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(calendarFetchById.pending, (state) => {
        state.currentCalendarLoading = true;
      })
      .addCase(calendarFetchById.fulfilled, (state, action: AnyAction) => {
        state.currentCalendarLoading = false;
        state.currentCalendar = action.payload;
      })
      .addCase(calendarFetchById.rejected, (state, action: AnyAction) => {
        state.currentCalendarLoading = false;
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          i18n.t('errorMessages.errorHappen', `An error happened`),
          errorMessage
        );
      })
      .addCase(calendarDelete.fulfilled, () => {
        toast.success(i18n.t('reducers.calendarDeleted', `Calendar deleted successfully`));
      })
      .addCase(calendarCreate.fulfilled, () => {
        toast.success(i18n.t('reducers.calendarCreated', `Calendar created successfully`));
      })
      .addCase(calendarCreate.rejected, () => {
        toast.error(i18n.t('errorMessages.errorHappen', `An error happened`));
      })
      .addCase(calendarUpdate.fulfilled, () => {
        toast.success(i18n.t('reducers.calendarUpdate', `Calendar updated successfully`));
      })
      .addCase(calendarListFetch.pending, (state) => {
        state.calendarsListLoading = true;
      })
      .addCase(calendarListFetch.fulfilled, (state, action: AnyAction) => {
        state.calendarsListLoading = false;
        state.calendarsTotalCount = action.payload.total_count;
        state.calendarsList = action.payload.calendars; // Named calendars in API
      })
      .addCase(calendarListFetch.rejected, (state, action: AnyAction) => {
        state.calendarsListLoading = false;
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          i18n.t('errorMessages.errorHappen', `An error happened`),
          errorMessage
        );
      });
  }
});

export const { setCalendarLastWeekSlots, resetCurrentCalendar } = calendarsSlice.actions;

export default calendarsSlice.reducer;
