import { ArrowBarRight, Check } from 'react-bootstrap-icons';
import { MouseEventHandler, useEffect } from 'react';
import {
  StyledOrderRecapConfirmModalButtonContainer,
  StyledOrderRecapConfirmModalContainer,
  StyledOrderRecapConfirmModalDescription,
  StyledOrderRecapConfirmModalSpinnerContainer,
  StyledOrderRecapConfirmModalTitle
} from '../../../order/orderRecap/confirmModals/OrderRecapConfirmModalStyles';

import Button from '@designSystem/Button';
import Icon from '@designSystem/Icon';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../../../utils/matomoHarmonizer';
import { useLocation } from 'react-router-dom';

const correctDestinationMatomo = (orderLink: string) => {
  if (orderLink === '/ordersBeta') return 'Commandes';
  return 'Eligibilité';
};

const OrderInfoPanelModalLink = ({
  closeModal,
  isQuotation,
  orderLink
}: {
  closeModal: MouseEventHandler<HTMLButtonElement>;
  isQuotation: boolean;
  orderLink: string;
}) => {
  const trackEvent = useMatomoHarmonizer();

  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const matomoQuotationLabel = isQuotation ? 'projet_étude_crée' : 'commande_créée';
    trackEvent({
      page: correctDestinationMatomo(orderLink),
      category: 'Choix_produit',
      actionType: 'Vu',
      actionDetails: matomoQuotationLabel
    });
  }, []);
  return (
    <StyledOrderRecapConfirmModalContainer>
      <StyledOrderRecapConfirmModalSpinnerContainer>
        <Icon variant="green" round>
          <Check />
        </Icon>
      </StyledOrderRecapConfirmModalSpinnerContainer>
      <StyledOrderRecapConfirmModalTitle>
        {isQuotation
          ? t(
              'features.eligibility.products.orderInfoPanel.quotationCreated',
              'Your quotation project has been created : '
            )
          : t(
              'features.eligibility.products.orderInfoPanel.projectCreated',
              'Your order project has been created : '
            )}
      </StyledOrderRecapConfirmModalTitle>
      <StyledOrderRecapConfirmModalDescription>
        {t(
          'features.eligibility.products.orderInfoPanel.pleaseCompleteOrder',
          `Please complete your order on SalesForce page. If you are not automatically redirected,
          please press the “Continue order” button below.
        `
        )}

        <StyledOrderRecapConfirmModalButtonContainer>
          <Button
            design="white"
            style={{ width: '100%', marginRight: '12px' }}
            onClick={closeModal}
          >
            {t('component.button.goBack', 'Go Back')}
          </Button>
          <Button
            autoFocus
            style={{ width: '100%' }}
            onClick={() => {
              trackEvent({
                page: correctDestinationMatomo(orderLink),
                category: 'Choix_produit',
                actionType: 'Clic',
                actionDetails: 'Lien_Salesforce'
              });
              window.open(orderLink, '_blank');
            }}
          >
            <ArrowBarRight size={'12px'} />{' '}
            {t('features.eligibility.products.orderInfoPanelModal.continue', 'Continue order')}
          </Button>
        </StyledOrderRecapConfirmModalButtonContainer>
      </StyledOrderRecapConfirmModalDescription>
    </StyledOrderRecapConfirmModalContainer>
  );
};

export default OrderInfoPanelModalLink;
