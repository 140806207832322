import React, { ChangeEventHandler, CSSProperties, ReactChild, ReactElement } from 'react';

import styled from 'styled-components';
import theme from '@theme';

const StyledCheckboxWrapper = styled.div`
  margin: 8px 0px;
  display: flex;

  .checkbox-label {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
`;

const StyledCheckbox = styled.div`
  display: flex;
  width: 18px;
  height: 18px;

  .animated-checkbox {
    --checkbox-width: 1.125rem;
    --checkbox-bg: white;
    --checkbox-radius: 4px;
    --checkbox-border-color: ${theme.palette.grey[600]};
    --checkbox-hover-border-color: ${theme.palette.grey[900]};
    --checkbox-checked-bg: ${theme.palette.blue[500]};
    --checkbox-disabled-bg: ${theme.palette.grey[300]};
    --checkbox-disabled-checked-bg: ${theme.palette.grey[900]};
    width: 18px;
    height: 18px;
    position: relative;
    background: ${theme.palette.blue[500]};
    border: none;
    border-radius: 4px;
    transition: 0.3s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    cursor: pointer;

    &:before {
      position: absolute;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18px' height='18px' fill='none' stroke-width='2px' stroke='white' stroke-linecap='round' viewBox='0 0 21 21'%3E%3Cpolyline points='5 10.75 8.5 14.25 16 6'%3E%3C/polyline%3E%3C/svg%3E");
      transform: scale(0);
    }

    &:checked:before {
      -webkit-animation: scale-in 0.1s 0.2s linear forwards;
      animation: scale-in 0.1s 0.2s linear forwards;
      transform: scale(1);
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: white;
      border-radius: inherit;
      box-shadow: inset 0 0 0 1px ${theme.palette.grey[300]};
      transition: 0.2s;
    }

    &:checked:after {
      transform: scale(0);
    }
  }
`;

interface CheckboxProps extends Omit<React.HTMLProps<HTMLInputElement>, 'label'> {
  children: ReactElement;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  label: false | ReactChild | ReactChild[];
  style?: CSSProperties;
}

const Checkbox: React.FC<CheckboxProps> = ({ children, label, style }) => {
  return (
    <StyledCheckboxWrapper style={style}>
      <StyledCheckbox>{children}</StyledCheckbox>

      <div className="checkbox-label">
        {label && <label htmlFor={children.props.id}>{label}</label>}
      </div>
    </StyledCheckboxWrapper>
  );
};

export default Checkbox;
