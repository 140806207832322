import theme, { defaultToastStyle } from '@theme';
import toast, { ToastBar, Toaster, resolveValue } from 'react-hot-toast';

import { X } from 'react-bootstrap-icons';
import styled from 'styled-components';

const StyledToasterContainer = styled.span`
  max-width: 230px;
  margin-right: 1rem;
  display: flex;
`;

const StyledDismissButtonContainer = styled.span`
  position: absolute;
  top: 1rem;
  right: 1rem;
  curosr: pointer;
  width: 20px;
  height: 20px;
`;

const StyledDismissIcon = styled(X)`
  width: 20px;
  height: 20px;
  color: ${theme.palette.grey[400]};
  cursor: pointer;
`;

const ToasterCustom = () => {
  return (
    <Toaster position="top-right" gutter={8} toastOptions={defaultToastStyle}>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <StyledToasterContainer>
              {icon}
              {resolveValue(message, t)}
              {t.type !== 'loading' && (
                <StyledDismissButtonContainer onClick={() => toast.dismiss(t.id)}>
                  <StyledDismissIcon />
                </StyledDismissButtonContainer>
              )}
            </StyledToasterContainer>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default ToasterCustom;
