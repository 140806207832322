import {
  Controller,
  FieldValues,
  FormState,
  useFormContext,
  UseFormRegister
} from 'react-hook-form';
import { StyledOrderSection, StyledOrderSiteWrapper } from '../../utils/OrderStyles';
import {
  customVerticalityStyles,
  customVerticalityStylesWithError
} from '../../../../style/customSelectStyle';
import { getErrorMessage, isFieldErrored } from '../../../../utils/formHelper';

import { ErrorMessage } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import { IOrderInformationsOperaBusinessForm } from '../../utils/orderInterfaces';
import InputWrapper from '@designSystem/InputWrapper';
import ReactSelect from 'react-select';
import { VerticalityContext } from '../../../eligibility/VerticalityProvider';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { getBuildingIdFromAddress } from '@features/eligibility/helper';
import { useAppSelector } from '../../../../app/hooks';
import { selectAdressInfos } from '@features/eligibility/eligibilitySlice';

const OrderInformationsOperaBusinessSiteForm = ({
  orderInformationsFormValues
}: {
  orderInformationsFormValues: IOrderInformationsOperaBusinessForm;
}) => {
  const { buildingsList, stairsList, floorsList } = useContext(VerticalityContext);
  const { register, formState, clearErrors, setValue } = useFormContext();
  const { t } = useTranslation();
  const addressInfos = useAppSelector(selectAdressInfos);

  return (
    <StyledOrderSection>
      <div className="section-title">
        {t('features.order.orderInformations.endClientSite', 'End Client site')}
      </div>
      <StyledOrderSiteWrapper>
        <div className="half-width">
          <InputWrapper
            inputLabel={t(
              'features.order.orderInformations.buildingReference',
              'Building reference'
            )}
          >
            <input
              disabled
              type="text"
              {...register('buildingRef')}
              placeholder={t('features.order.orderInformations.buildingRef', `Building reference`)}
              value={getBuildingIdFromAddress({
                id: addressInfos?.id ?? '',
                referenceType: addressInfos?.referenceType
              })}
              aria-label="buildingRef-input"
            />
          </InputWrapper>
        </div>
        <div>
          <InputWrapper inputLabel={t('features.order.orderInformations.address', 'Address')}>
            <input
              disabled
              type="text"
              {...register('address')}
              placeholder={t('features.order.orderInformations.address', `Address`)}
              value={orderInformationsFormValues.address}
              aria-label="address-input"
            />
          </InputWrapper>
        </div>
        <div className="section-row">
          <div className="section-row-item">
            <InputWrapperVerticality
              t={t}
              formState={formState}
              inputLabel={t('features.order.orderInformations.building', 'Building')}
              name={'building'}
              inputMaxLength={60}
              register={register}
              placeholder={t('features.order.orderInformations.building', 'Building')}
              value={orderInformationsFormValues.building}
              list={buildingsList}
              required={{ value: true, message: t('errorMessages.valueMissing', `Missing value`) }}
              handleOnChange={() => {
                setValue('stair', stairsList?.length === 1 ? stairsList[0].value : undefined);
                setValue('floor', floorsList?.length === 1 ? floorsList[0].value : undefined);
                setValue('outlet', undefined);
                clearErrors('building');
              }}
            />
          </div>
          <div className="section-row-item">
            <InputWrapperVerticality
              t={t}
              inputMaxLength={25}
              formState={formState}
              inputLabel={t('features.order.orderInformations.stair', 'Stair')}
              name={'stair'}
              register={register}
              placeholder={t('features.order.orderInformations.stair', 'Stair')}
              value={orderInformationsFormValues.stair}
              list={stairsList}
              required={{ value: true, message: t('errorMessages.valueMissing', `Missing value`) }}
              handleOnChange={() => {
                setValue('floor', floorsList?.length === 1 ? floorsList[0].value : undefined);
                setValue('outlet', undefined);
                clearErrors('stair');
              }}
            />
          </div>
          <div className="section-row-item">
            <InputWrapperVerticality
              t={t}
              inputMaxLength={25}
              formState={formState}
              inputLabel={t('features.order.orderInformations.level', 'Level')}
              name={'floor'}
              register={register}
              placeholder={t('features.order.orderInformations.level', 'Level')}
              value={orderInformationsFormValues.floor}
              list={floorsList}
              required={{ value: true, message: t('errorMessages.valueMissing', `Missing value`) }}
              handleOnChange={() => {
                setValue('outlet', undefined);
                clearErrors('floor');
              }}
            />
          </div>
        </div>
      </StyledOrderSiteWrapper>
    </StyledOrderSection>
  );
};

export default OrderInformationsOperaBusinessSiteForm;

interface IInputWrapperVerticality {
  formState: FormState<FieldValues>;
  register: UseFormRegister<FieldValues>;
  value?: string;
  t: TFunction<'translation', undefined, 'translation'>;
  inputMaxLength?: number;
  inputLabel: string;
  name: string;
  list: { value: string; label: string }[];
  placeholder: string;
  required?: { value: boolean; message: string };
  handleOnChange?: () => void;
}
const InputWrapperVerticality: React.FC<IInputWrapperVerticality> = ({
  required,
  handleOnChange,
  list,
  placeholder,
  t,
  inputMaxLength,
  name,
  value,
  inputLabel,
  formState,
  register
}) => {
  if (!list.length) {
    const validateLength = (inputValue: string) => {
      if (inputMaxLength && inputValue && inputValue.length > inputMaxLength) {
        return t(
          'errorMessages.tooManyCharactersEntered',
          `Maximum number of ${inputMaxLength} characters exceeded`,
          {
            defaultValue: '{{count}}',
            count: inputMaxLength
          }
        );
      }
      return true;
    };
    return (
      <>
        <InputWrapper
          inputLabel={inputLabel}
          error={getErrorMessage(name, formState)}
          optional={t('features.order.orderInformations.optional', `Optional`)}
        >
          <div>
            <input
              type="text"
              id={name}
              {...register(name, { validate: validateLength })} // Utilisation de la validation personnalisée
              data-testid={`order-input-${name}`}
              {...register(name)}
              placeholder={placeholder}
              value={value}
              aria-label={`${name}-input`}
            />
          </div>
        </InputWrapper>
      </>
    );
  }
  return (
    <>
      <Controller
        name={name}
        rules={{
          required: required
        }}
        render={({ field: { onChange } }) => (
          <InputWrapper inputLabel={inputLabel}>
            <ReactSelect
              styles={
                isFieldErrored(name, formState.errors)
                  ? customVerticalityStylesWithError
                  : customVerticalityStyles
              }
              inputId={name}
              options={list}
              value={{
                value: value,
                label: value
              }}
              onChange={(val: any) => {
                onChange(val.value);
                handleOnChange && handleOnChange();
              }}
            />
          </InputWrapper>
        )}
      />
      {isFieldErrored(name, formState.errors) && (
        <ErrorMessage>{getErrorMessage(name, formState)}</ErrorMessage>
      )}
    </>
  );
};
