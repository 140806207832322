import React, { useState } from 'react';
import styled from 'styled-components';

import Clipboard from '../images/Clipboard.svg';
import Tick from '../images/Tick.svg';

const StyledIcon = styled.div`
  position: absolute;
  right: 50px;
  bottom: 7px;
  z-index: 1;
  cursor: pointer;
`;

const CustomClipboard: React.FC<{
  valueToCopy?: string;
  customPosition?: 'absolute' | 'unset' | 'relative';
}> = ({ valueToCopy = '', customPosition = 'absolute' }) => {
  const [isCopied, setIsCopied] = useState(false);

  const toggleClipboardIcon = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <StyledIcon
      onClick={() => {
        navigator.clipboard.writeText(valueToCopy);
        toggleClipboardIcon();
      }}
      style={{ position: customPosition }}
    >
      <img
        className="d-inline-block align-top"
        style={{ maxWidth: '24px' }}
        src={isCopied ? Tick : Clipboard}
        alt="Clipboard"
      />
    </StyledIcon>
  );
};

export default CustomClipboard;
