import { ChevronLeft, PersonFill } from 'react-bootstrap-icons';
import { Order, ProductOrderItem } from '../utils/orderInterfaces';
import React, { Dispatch, SetStateAction, useMemo } from 'react';

import AppointmentDate from '@features/order/components/AppointmentDate';
import Badge from '@designSystem/Badges';
import { HomeBasicProductCode, productsListWithInfos } from '@features/eligibility/const';
import Icon from '@designSystem/Icon';
import Modal from '@designSystem/Modal';
import MsgRequest from '../../../images/order/tracking/msg-request-white.svg';
import OrderRecapConfirmModalRequests from '../orderRecap/confirmModals/OrderRecapConfirmModalRequests';
import OrderStatus from '@features/order/orderList/OrderStatus';
import { VerticalLineStyled } from '../orderList/CardOrderContainer';
import styled from 'styled-components';
import theme from '@theme';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { env } from '../../../config';
import Button from '@designSystem/Button';

const OrderTrackingHeader: React.FC<{
  order: Order;
  setOrderIdToUpdated: Dispatch<SetStateAction<string | undefined>>;
  setOrderInfos: Dispatch<
    SetStateAction<{
      orderId: string | undefined;
      buildingId: string | undefined;
      productOrderItem: ProductOrderItem[] | undefined;
    }>
  >;
}> = ({ order, setOrderInfos, setOrderIdToUpdated }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const trackEvent = useMatomoHarmonizer();

  const [confirmRequestModal, setConfirmRequestModal] = React.useState(false);
  const { state, customer, reference, externalReference, orderDate, productOrderItem } = order;
  const places = productOrderItem.find((p) => p.product.isBundle)?.product?.place;
  const place = places?.length && places[0];
  const buildingId = (place && place.id) || '';

  const productCode = productOrderItem[0].product.productOffering.id;
  const salesforceId = productOrderItem[0].productOrderItem
    .find((p) => p.product.productOffering.id === 'acces')
    ?.product.productCharacteristic.find((charac) => charac.name === 'remoteOrderId');

  const badgeName =
    customer.contact?.firstName || customer.contact?.lastName
      ? `${customer.contact.firstName} ${customer.contact.lastName}`
      : t('errorMessages.customerUnknow', 'Customer unknown');
  const canSeeRequestBtn = ['submitted', 'inProgress', 'failed'].includes(state.status);

  const returnOnTracking = () => {
    navigate(-1);
  };

  const setEditOrder = () => {
    setOrderIdToUpdated(order.id);
    setOrderInfos({ orderId: order.id, buildingId, productOrderItem });
  };

  const confirmBtnClickedSF = () => {
    trackEvent({
      page: 'Mes_Commandes',
      category: 'Pop_UP_Sf',
      actionType: 'Clic',
      actionDetails: 'Confirmation_Requête_SF'
    });

    window.open(`${env.ORDER_REQUESTS_PAGE_URL}/${salesforceId?.value}`);
  };

  const confirmBtnClickedMail = () => {
    trackEvent({
      page: 'Mes_Commandes',
      category: 'Pop_UP_Mail_request',
      actionType: 'Clic',
      actionDetails: 'Confirmation_Requête_Mail'
    });
    const mailtoLink = `mailto:adv-ftth@axione.fr`;
    window.location.href = mailtoLink;
  };

  const isMailRequest = useMemo(() => {
    return Boolean(
      productOrderItem?.find((curr) => curr.product?.productOffering?.id === HomeBasicProductCode)
    );
  }, []);

  const isBusiness = productsListWithInfos.find(
    (product) => product.code === productCode
  )?.isBusiness;

  return (
    <OrderTrackingHeaderStyled>
      <div>
        <span onClick={() => returnOnTracking()} className="clickable">
          <Icon variant="grey" noBackground size="minimal" iconSize={'1rem'}>
            <ChevronLeft />
          </Icon>
        </span>
      </div>
      <div>
        <div className="order-ref">
          <div>{reference}</div>
          <VerticalLineStyled height={11} />
          <div>{externalReference}</div>
        </div>

        <OrderStatus
          salesforceId={salesforceId?.value}
          isBusiness={isBusiness}
          state={state}
          orderDate={orderDate}
          withSeparator={true}
        />

        <Badge variant="grey" style={{ width: 'max-content' }} leftContent={<PersonFill />}>
          <div>{badgeName}</div>
        </Badge>
      </div>
      <div className="action-container">
        {!isBusiness && (
          <AppointmentDate action={setEditOrder} productOrderItem={order.productOrderItem} />
        )}
        {canSeeRequestBtn && (
          <div onClick={() => setConfirmRequestModal(true)}>
            <Button size="sm">
              <img src={MsgRequest} alt="request" />
              {t(
                'features.order.orderRecap.orderTrackingHeader.give_requests',
                'Formuler une requête'
              )}
            </Button>
          </div>
        )}
      </div>

      <Modal show={confirmRequestModal} width="592px">
        {isMailRequest ? (
          <OrderRecapConfirmModalRequests
            title={t(
              'features.order.orderRecap.orderRecapConfirmModals.mailRequestTitle',
              'Would you like to make an inquiry about this order ?'
            )}
            subtitle={t(
              'features.order.orderRecap.orderRecapConfirmModals.mailRequestDescription.text',
              'You can contact ADV for more information about your order'
            )}
            confirmAction={confirmBtnClickedMail}
            cancelBtnClicked={() => setConfirmRequestModal(false)}
            confirmationText={t(
              'features.order.orderRecap.orderRecapConfirmModals.mailRequestDescription.button',
              'Envoyer un mail'
            )}
          />
        ) : (
          <OrderRecapConfirmModalRequests
            title={t(
              'features.order.orderRecap.orderRecapConfirmModals.sfRequestTitle',
              'Do you want to make or consult a request on this order?'
            )}
            subtitle={t(
              'features.order.orderRecap.orderRecapConfirmModals.sfRequestDescription',
              'Please confirm to be redirected to Salesforce so that you can contact contact the sales department.'
            )}
            confirmAction={confirmBtnClickedSF}
            cancelBtnClicked={() => setConfirmRequestModal(false)}
          />
        )}
      </Modal>
    </OrderTrackingHeaderStyled>
  );
};

export default OrderTrackingHeader;

const OrderTrackingHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  justify-content: flex-start;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  padding: 0.8rem 1.875rem;
  background-color: rgba(251, 253, 255, 0.7);
  position: sticky;
  top: 0;
  z-index: 2;
  backdrop-filter: blur(2px);
  border-bottom: 1px solid ${theme.palette.grey[200]};
  margin-bottom: 1rem;

  font-size: ${theme.fonts.size.xs};
  font-weight: ${theme.fonts.weight.medium};
  line-height: ${theme.fonts.spacing.xs};

  .order-ref {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }
  .order-state {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
  .order-badge {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 6px;
    height: 20px;
    width: max-content;
  }

  .editable-appointment {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: max-content max-content max-content;
    align-items: center;
  }

  .action-container {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: max-content max-content;
    align-items: center;
    margin-left: auto;
  }
`;
