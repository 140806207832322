import { Col, Row } from 'react-bootstrap';
import { queries, transformSegmentForCube } from './utils/chartsQueries';

import ChartCardContainer from './ChartCardContainer';
import { CubejsApi } from '@cubejs-client/core';
import EchartsDrawer from './drawer/EchartsDrawer';
import React from 'react';
import { Segment } from './interfaces';
import { options } from './utils/chartsOptions';

const MonthlyDatas: React.FC<{
  segments: Segment[] | undefined;
  cubejsApi: CubejsApi;
  manageError?: (_e: Error) => void;
}> = ({ segments, cubejsApi, manageError }) => {
  return (
    <Row>
      <Col sm={12}>
        <ChartCardContainer noHeader>
          <EchartsDrawer
            cubejsApi={cubejsApi}
            query={{
              ...queries.ticketEvolution,
              segments: transformSegmentForCube(segments, 'TicketEvolution'),
              timeDimensions: queries.ticketEvolution.timeDimensions
            }}
            chartType="line"
            chartOptions={options.ticketEvolution}
            errorCallback={manageError}
          />
        </ChartCardContainer>
      </Col>

      <Col sm={12}>
        <ChartCardContainer noHeader>
          <EchartsDrawer
            cubejsApi={cubejsApi}
            query={{
              ...queries.tauxBrutparOC,
              segments: transformSegmentForCube(segments, 'TTauxBrutEspaceDelegant', [
                'intervention'
              ]),
              timeDimensions: queries.tauxBrutparOC.timeDimensions
            }}
            chartType="line"
            chartOptions={options.tauxBrutparOC}
            errorCallback={manageError}
          />
        </ChartCardContainer>
      </Col>
    </Row>
  );
};

export default MonthlyDatas;
