import React, { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import { ChevronDown, Search } from 'react-bootstrap-icons';
import styled from 'styled-components';

interface Option {
  name: string;
  id: string;
}

export interface ButtonGroupItem {
  titleName: string;
  id: string;
  options?: Option[];
  icon?: any;
}

export interface ButtonGroupProps {
  data: ButtonGroupItem[];
  setSelectedOptions: Dispatch<
    SetStateAction<{
      [id: string]: string | null;
    }>
  >;
  selectedOptions: {
    [id: string]: string | null;
  };
}

const ButtonGroupContainer = styled.div`
  display: flex;
  background-color: #f6f6f9;
  padding: 4px;
  width: fit-content;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

const Button = styled.button<{ selected?: boolean; isfirst?: boolean; islast?: boolean }>`
  padding: 6px 20px;
  background-color: ${(props) => (props.selected ? 'white' : '#F6F6F9')};
  color: #666687;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  &:not(:first-child) {
    margin-left: -1px;
  }
`;

const Dropdown = styled.div<{ isopen: boolean; isLeft?: boolean }>`
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 1;
  min-width: 200px;
  display: ${(props) => (props.isopen ? 'block' : 'none')};
  border-radius: 8px;
  margin-top: 15px;
  width: 240px;
  right: ${(props) => (props.isLeft ? '0' : 'auto')};
`;

const OptionItem = styled.div`
  cursor: pointer;
  margin-bottom: 4px;
  width: 240px;
  height: 200;
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  color: #374151;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: #f5f9ff;
    margin-bottom: 0;
    border-radius: 6px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 225px;
  border-radius: 6px;
  background-color: #f3f4f6;
`;

const SearchIcon = styled(Search)`
  margin: 0 8px;
  color: #d1d5db;
`;

const SearchInput = styled.input`
  flex: 1;
  height: 30px;
  padding: 10px 11px 10px 0;
  border-radius: 6px;
  border: none;
  background-color: transparent;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: grey;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 14px;
    color: lightgray;
  }
`;

const ButtonGroup: React.FC<ButtonGroupProps> = ({ data, setSelectedOptions, selectedOptions }) => {
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  const [searchText, setSearchText] = useState('');
  const [isDropdownLeft, setIsDropdownLeft] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleButtonClick = (id: string, hasOptions: boolean) => {
    if (hasOptions) {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        const spaceOnRight = window.innerWidth - rect.right;
        setIsDropdownLeft(spaceOnRight < 300);
      }
      setOpenDropdownId((prevOpenDropdownId) => (prevOpenDropdownId === id ? null : id));
    } else {
      setSelectedOptions({ [id]: null });
      setOpenDropdownId(null);
    }
  };

  const handleOptionClick = (id: string, optionId: string) => {
    setSelectedOptions({
      [id]: optionId
    });
    setSearchText('');
    setOpenDropdownId(null);
  };

  const handleClickOutsideDropdown = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    };
  }, []);

  const filterOptions = (options: Option[], searchText: string) => {
    return options.filter((option) => option.name.toLowerCase().includes(searchText.toLowerCase()));
  };

  return (
    <ButtonGroupContainer>
      {data.map((item, index) => {
        const hasOptions = !!item.options;
        const isSelected = selectedOptions[item.id] !== undefined;
        const isopen = openDropdownId === item.id;
        const isFirstButton = index === 0;
        const isLastButton = index === data.length - 1;
        const IconCurr = item.icon || <></>;

        return (
          <div key={item.id} style={{ position: 'relative' }}>
            <Button
              ref={buttonRef}
              selected={isSelected}
              isfirst={isFirstButton}
              islast={isLastButton}
              onClick={() => handleButtonClick(item.id, hasOptions)}
            >
              {item.icon && <IconCurr color={isSelected ? '#3884FF' : 'inherit'} />}
              {hasOptions && selectedOptions[item.id] !== null
                ? item.options?.find((option) => option.id === selectedOptions[item.id])?.name ||
                  item.titleName
                : item.titleName}
              {hasOptions && <ChevronDown size={8} color="#C0C0CF" />}
            </Button>
            {hasOptions && isopen && (
              <Dropdown ref={dropdownRef} isopen={isopen} isLeft={isDropdownLeft}>
                <SearchContainer>
                  <SearchIcon />
                  <SearchInput
                    type="text"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search..."
                  />
                </SearchContainer>
                {filterOptions(item.options || [], searchText).map((option) => (
                  <OptionItem key={option.id} onClick={() => handleOptionClick(item.id, option.id)}>
                    &nbsp;&nbsp;{option.name}
                  </OptionItem>
                ))}
              </Dropdown>
            )}
          </div>
        );
      })}
    </ButtonGroupContainer>
  );
};

export default ButtonGroup;
