import ButtonGroup, { ButtonGroupItem } from '@designSystem/ButtonGroup';
import { CardBaseListHeader, LevelContactCard } from '@features/escalate/LevelContactCard';
import { Escalation, Level } from '@features/escalate/escalateTypes';
import { Palette2, PersonCircle } from 'react-bootstrap-icons';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { getEscalationList, getEscalationOwners } from '@features/escalate/reducer/matriceAPI';
import { useEffect, useState } from 'react';

import ApiError from '../components/ApiError';
import { DrawerInformationsContact } from '../components/DrawerInformationContact';
import Dropdown from '@designSystem/Dropdown';
import { EscalationTrad } from '@features/escalate/const';
import NoEscalationListFound from '@features/escalate/NoEscalationListFound';
import PageHeader from '../components/PageHeader/PageHeader';
import Spinner from '@designSystem/Spinner';
import { getLevelsBy } from '@features/escalate/reducer/matriceAPI';
import styled from 'styled-components';
import theme from '@theme';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const Escalate = () => {
  const [selectedOptions, setSelectedOptions] = useState<{ [id: string]: string | null }>({
    AXIONE: null
  });

  const { t } = useTranslation();
  const [escalationList, setEscalationList] = useState<Escalation[] | null>(null);
  const [levels, setLevels] = useState<Level[] | null>(null);
  const [owners, setOwners] = useState<{ name: string; id: string }[] | null>(null);

  const [sectionId, setSectionId] = useState<number | null>();
  const [selectedSections, setSelectedSections] =
    useState<{ section?: string; childSection?: string }>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [APIError, setAPIError] = useState(false);

  function sectionsFromTab(escalationList: Escalation[]) {
    return escalationList?.filter((e) => `${e.id}` === currentFilterAndSort.state)[0]?.sections;
  }

  const [currentFilterAndSort, setCurrentFilterAndSort] = useState<{
    search?: string;
    state?: string;
  }>({
    search: '',
    state: ''
  });

  const hasNoListFound = escalationList?.length === 0;
  const hasNoContactFound = levels?.length === 0;

  const fetchEscalationList = async (clientName: string) => {
    setIsLoading(true);
    setAPIError(false);

    await getEscalationList(clientName)
      .then((res) => {
        setEscalationList(res.data);
      })
      .catch(() => {
        toast.error(t('errorMessages.errorHappen', `An error happened`));
        setAPIError(true);
        setEscalationList(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchEscalationOwners = async () => {
    setAPIError(false);
    await getEscalationOwners()
      .then((res) => {
        setOwners(res?.data);
      })
      .catch(() => {
        toast.error(t('errorMessages.errorHappen', `An error happened`));
        setAPIError(true);
      });
  };

  const fetchLevelsBy = async (sectionId?: number, searchValue?: string) => {
    setIsLoading(true);
    setAPIError(false);
    if (!sectionId) return setLevels(null);
    await getLevelsBy(sectionId, searchValue)
      .then((res) => {
        setLevels(res.data);
      })
      .catch(() => {
        toast.error(t('errorMessages.errorHappen', `An error happened`));
        setAPIError(true);
        setLevels(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (sectionId) {
      fetchLevelsBy(sectionId);
    }
  }, [sectionId]);

  useEffect(() => {
    if (escalationList && escalationList.length > 0) {
      setCurrentFilterAndSort({ ...currentFilterAndSort, state: `${escalationList[0].id}` });
    }
  }, [escalationList]);

  useEffect(() => {
    if (currentFilterAndSort?.search?.length && sectionId) {
      fetchLevelsBy(undefined, currentFilterAndSort.search);
    } else if (sectionId) {
      fetchLevelsBy(sectionId);
    }
  }, [currentFilterAndSort.search]);

  useEffect(() => {
    if (escalationList?.length && currentFilterAndSort && currentFilterAndSort.state) {
      const section = sectionsFromTab(escalationList)?.[0];
      setSelectedSections({
        section: section.name,
        childSection: section.childSections[0] ? section.childSections[0].name : undefined
      });
    }
  }, [currentFilterAndSort.state]);

  useEffect(() => {
    if (!escalationList?.length) {
      setSectionId(null);
      setLevels(null);
      return;
    }
    if (escalationList && selectedSections) {
      let id;
      if (selectedSections?.childSection) {
        id = sectionsFromTab(escalationList)
          ?.filter((e) => e.name === selectedSections.section)[0]
          .childSections.filter((e) => e.name === selectedSections.childSection)[0].id;
      } else if (selectedSections?.section) {
        id = sectionsFromTab(escalationList)?.filter((e) => e.name === selectedSections.section)[0]
          .id;
      }
      setSectionId(id);
    }
  }, [selectedSections]);

  useEffect(() => {
    fetchEscalationOwners();
    fetchEscalationList('AXIONE');
  }, []);

  const openModalAndSelectContact = (levelId: number) => {
    navigate(`/escalation/${levelId}`);
  };

  const updateDataFromHeader = (newFilter: { search?: string }) => {
    setCurrentFilterAndSort({ ...newFilter });
  };

  const handleClientResearch = (p: { [key: string]: string }) => {
    const client = Object.values(p);

    fetchEscalationList(client?.[0] || 'AXIONE');
    setSelectedOptions(p);
  };

  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);

  const generateDataButtonGroup = (): ButtonGroupItem[] => {
    const baseData: ButtonGroupItem[] = [{ titleName: 'Axione', id: 'AXIONE' }];
    if (!profileType.rolesNames.includes('portal-infos_escalation-viewer')) {
      baseData.push({
        titleName: 'Client',
        id: 'otherClient',
        icon: PersonCircle,
        options: owners || []
      });
    }
    return baseData;
  };

  return (
    <EscalateStyled>
      <Routes>
        <Route path=":levelId" element={<DrawerInformationsContact />} />
      </Routes>

      <PageHeader
        className="page-header"
        id="PageHeader"
        updateData={updateDataFromHeader}
        isClearSelectedTab={!!currentFilterAndSort.search?.length}
        trackingSearchName={'Matrice'}
        currentFilterAndSort={currentFilterAndSort}
        setCurrentFilterAndSort={setCurrentFilterAndSort}
        inputPlaceholder={t('pages.escalate.searchByReference', `Rechercher`)}
        tabs={escalationList?.map((escalation) => {
          return {
            name: EscalationTrad()[escalation.name] || escalation.name,
            state: `${escalation.id}`
          };
        })}
        actionsDisabled={APIError}
        resetOnClick
        hideSearch
      />
      <div className="container-content">
        <div className="list">
          {isLoading && <Spinner spinnerSize={2} center />}

          {!isLoading && (
            <>
              {escalationList && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Filters>
                    {selectedSections?.section && (
                      <Dropdown
                        variant="grey"
                        key={`${selectedSections?.section}`}
                        reversed
                        disabled={!!currentFilterAndSort.search?.length}
                        onSelect={(v) => {
                          const name =
                            Object.keys(EscalationTrad()).find(
                              (key) => EscalationTrad()[key] === v
                            ) || v;
                          const firstChildName =
                            sectionsFromTab(escalationList).filter((e) => e.name === name)[0]
                              .childSections[0]?.name || undefined;

                          setSelectedSections({
                            section: name,
                            childSection: firstChildName
                          });
                        }}
                        placeholder={
                          <div
                            style={{
                              display: 'flex',
                              gap: '8px',
                              alignItems: 'center',
                              color: theme.palette.grey[500],
                              fontSize: '12px'
                            }}
                          >
                            <Palette2 size={'12px'} />
                            {currentFilterAndSort.search?.length
                              ? ''
                              : EscalationTrad()[selectedSections?.section] ||
                                selectedSections?.section}
                          </div>
                        }
                        items={sectionsFromTab(escalationList)?.map(
                          (e) => EscalationTrad()[e.name] || e.name
                        )}
                      ></Dropdown>
                    )}
                    {selectedSections?.section && selectedSections?.childSection && (
                      <>
                        <span
                          style={{
                            fontSize: '12px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: theme.palette.grey[800]
                          }}
                        >
                          Et
                        </span>
                        <Dropdown
                          variant="grey"
                          key={`${selectedSections?.section}`}
                          reversed
                          disabled={!!currentFilterAndSort.search?.length}
                          onSelect={(v) => {
                            const name =
                              Object.keys(EscalationTrad()).find(
                                (cle) => EscalationTrad()[cle] === v
                              ) || v;
                            setSelectedSections({ ...selectedSections, childSection: name });
                          }}
                          placeholder={
                            <div
                              style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                color: theme.palette.grey[500],
                                fontSize: '12px'
                              }}
                            >
                              <Palette2 size={'12px'} color={theme.palette.grey[500]} />
                              {currentFilterAndSort.search?.length
                                ? ''
                                : EscalationTrad()[selectedSections?.childSection] ||
                                  selectedSections?.childSection}
                            </div>
                          }
                          items={sectionsFromTab(escalationList)
                            ?.filter((e) => e.name === selectedSections.section)[0]
                            ?.childSections?.map((e) => EscalationTrad()[e.name] || e.name)}
                        ></Dropdown>
                      </>
                    )}
                  </Filters>
                  <div style={{ marginLeft: '12px' }}>
                    <ButtonGroup
                      selectedOptions={selectedOptions}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      setSelectedOptions={handleClientResearch}
                      data={generateDataButtonGroup()}
                    />
                  </div>
                </div>
              )}
              {(hasNoListFound || hasNoContactFound) && (
                <div className="container-not-found">
                  <NoEscalationListFound />
                </div>
              )}
              {APIError && (
                <div className="container-not-found">
                  <ApiError />
                </div>
              )}

              {!!levels?.length && !APIError && !hasNoListFound && (
                <>
                  <CardBaseListHeader>
                    <div>{t('pages.escalate.header.name', `Name`)}</div>
                    <div>{t('pages.escalate.header.role', `Role`)}</div>
                    <div>{t('pages.escalate.header.schedule', `Schedule`)}</div>
                  </CardBaseListHeader>
                  {escalationList &&
                    levels?.map((contact, index: number) => {
                      return (
                        <div
                          onClick={() => openModalAndSelectContact(contact.id)}
                          key={contact.id}
                          id={index === 0 ? 'Card-first-line' : undefined}
                        >
                          <LevelContactCard key={contact.id} level={contact} />
                        </div>
                      );
                    })}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </EscalateStyled>
  );
};

export default Escalate;

const EscalateStyled = styled.div`
  background: white;
  min-height: calc(100vh - 63px);

  .page-header {
    background-color: rgba(255, 255, 255, 0.7);
    input {
      width: 370px;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 63px);
  }
  .container-content {
    margin: 12px 24px 0;
    height: 100%;
    background: ${theme.palette.grey[25]};
  }
  .container-not-found {
    margin-top: 100px;
    display: grid;
    align-items: center;
    justify-content: center;
  }
`;
const Filters = styled.div`
  display: grid;
  grid-template-columns: minmax(208px, max-content) 39px minmax(208px, max-content);
  gap: 0.5rem;
  margin-top: 6px;
  margin-bottom: 18px;
  .dropdown_text {
    font-size: 9px;
    display: flex;
  }
  span {
    display: block;
    text-align: center;
    padding: 7px 12px;
    background: ${theme.palette.blue[50]};
    color: ${theme.palette.blue[300]};
    border-radius: 4px;
  }
`;
