import { Fragment, ReactChild } from 'react';

import { Column } from 'react-table';
import FullTable from '../../../components/FullTable';
import TableHeadSort from '@designSystem/Table/HeadSort';
import { Ticket } from '../utils/siteStatusInterfaces';

const OrderTicketTable = ({
  columns,
  data,
  currentSelectedRow,
  selectedRowClassName = 'selected-row-purple',
  children
}: {
  columns: Column<Ticket> | any;
  data: Ticket[];
  currentSelectedRow: number | string | null;
  selectedRowClassName?: string;
  children: ReactChild;
}) => {
  const orderTicketTableHeaderDisplay = (column: {
    getHeaderProps: (_arg1: any) => { [x: string]: any; key: any };
    getSortByToggleProps: () => { [x: string]: any; key: any };
    render: (arg0: string) => React.ReactNode;
  }) => {
    const { ...restSortColumn } = column.getSortByToggleProps();
    const { key, ...restColumn } = column.getHeaderProps(restSortColumn);
    {
      return (
        <th key={key} {...restColumn}>
          <TableHeadSort column={column}>{column.render('Header')}</TableHeadSort>
        </th>
      );
    }
  };

  const orderTicketTableCellDisplay = (
    row: {
      getRowProps: () => { [x: string]: any; key: any };
      cells: {
        getCellProps: () => { [x: string]: any; key: any };
        render: (arg0: string) => any;
        value?: string;
      }[];
    },
    prepareRow: CallableFunction
  ) => {
    prepareRow(row);
    const { key, ...restRow } = row.getRowProps();
    const isSelected = row.cells.find(
      (c) => c.value === currentSelectedRow && currentSelectedRow !== null
    );

    return (
      <Fragment key={key}>
        <tr {...restRow} className={`${isSelected ? selectedRowClassName : ''}`}>
          {row.cells.map((cell) => {
            const { key: cellKey, ...restCell } = cell.getCellProps();
            return (
              <td key={cellKey} {...restCell}>
                {cell.render('Cell')}
              </td>
            );
          })}
        </tr>
        <tr>{isSelected && <td colSpan={12}>{children}</td>}</tr>
      </Fragment>
    );
  };

  return (
    <FullTable
      className="compact"
      columns={columns}
      data={data}
      headerRendering={orderTicketTableHeaderDisplay}
      cellRendering={orderTicketTableCellDisplay}
    />
  );
};

export default OrderTicketTable;
