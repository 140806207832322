import { Alert, Container, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import cubejs, { CubejsApi } from '@cubejs-client/core';
import { getToken, isPermitted, updateToken } from '../app/AuthService';

import ChartsAndMap from '@features/charts/ChartsAndMap';
import MonthlyDatas from '@features/charts/MonthlyDatas';
import NumbersAndBacklog from '@features/charts/NumbersAndBacklog';
import { Role } from '../utils/role.types';
import ScrollDirFilters from '@features/charts/filters/ScrollDirFilters';
import { Segment } from '@features/charts/interfaces';
import { env } from '../config';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../utils/matomoHarmonizer';

const DelegantDashboard: React.FC = () => {
  const [segments, setSegments] = useState<Segment[] | undefined>(undefined);
  const [selectedDepIsAll, setSelectedDepIsAll] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[]>([]);
  const trackEvent = useMatomoHarmonizer();

  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);
  const [cubejsApi, setCubejsApi] = useState<CubejsApi | undefined>();
  const { t } = useTranslation();

  const updateSegment = (newSegment: Segment[]) => {
    const isNotIntern =
      profileType && !isPermitted(profileType.rolesNames, [Role.INTERN, Role.PORTAL_INFOS_ALL]);

    if (!profileType || (isNotIntern && !newSegment.length)) {
      return;
    }

    if (JSON.stringify(newSegment) !== JSON.stringify(segments)) {
      updateToken(5).then(() => {
        loadCube();
        setSegments(newSegment);
      });
    }
  };

  const manageError = (e?: Error) => {
    if (!e) {
      setErrors([]);
    } else {
      setErrors([...errors, e.message]);
    }
  };

  const loadCube = () => {
    setCubejsApi(
      cubejs('', {
        apiUrl: `${env.API_URL}/cube`,
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      })
    );
  };

  useEffect(() => {
    manageError();
  }, [JSON.stringify(segments)]);

  useEffect(() => {
    trackEvent({ page: 'Ajournement', category: 'Page', actionType: 'Vu' });
    loadCube();
  }, []);

  return (
    <ScrollDirFilters updateSegment={updateSegment} setSelectedDepIsAll={setSelectedDepIsAll}>
      <Container fluid className="main-content-container px-0">
        <Form
          autoComplete="off"
          onSubmit={(e: { preventDefault: () => void }) => {
            e.preventDefault();
          }}
        >
          {cubejsApi && (
            <>
              {errors.length > 0 && (
                <Alert variant="danger">
                  <h4 className="alert-heading">
                    {t('errorMessages.errors', 'Error', {
                      defaultValue: 'Error(s)',
                      count: errors.length
                    })}
                  </h4>
                  <ul>
                    {errors.map((errMsg) => {
                      return <li key={errMsg}>{errMsg}</li>;
                    })}
                  </ul>
                  <hr />
                </Alert>
              )}

              {segments && (
                <>
                  <NumbersAndBacklog
                    cubejsApi={cubejsApi}
                    showAdjByDep={selectedDepIsAll}
                    segments={segments}
                    manageError={manageError}
                  />
                  <ChartsAndMap
                    cubejsApi={cubejsApi}
                    segments={segments}
                    manageError={manageError}
                  />
                  <MonthlyDatas
                    cubejsApi={cubejsApi}
                    segments={segments}
                    manageError={manageError}
                  />
                </>
              )}
            </>
          )}
        </Form>
      </Container>
    </ScrollDirFilters>
  );
};

export default DelegantDashboard;
