import React, { useContext, useState } from 'react';

import { BoxArrowUpRight } from 'react-bootstrap-icons';
import Button from '@designSystem/Button';
import Card from '@designSystem/Card';
import Checkbox from '../../components/CheckBox';
import Icon from '@designSystem/Icon';
import InformationBlock from '@designSystem/InformationBlock';
import { MatomoCustomContext } from '../../utils/MatomoCustomProvider';
import Modal from '@designSystem/Modal';
import incognito from '../../images/incognito.png';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';

const ModalText = styled.span`
  text-align: center;
  color: ${theme.palette.grey[500]};
  font-size: ${theme.fonts.size.xs};
  margin-bottom: 1rem;

  h2,
  label {
    color: ${theme.palette.grey[900]};
    font-size: ${theme.fonts.size.sm};
    font-weight: ${theme.fonts.weight.medium};
    line-height: ${theme.fonts.spacing.xs};
  }
`;

const ModalButton = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  width: 100%;
  button {
    width: 100%;
  }
`;

const StyledTitle = styled.h2`
  color: ${theme.palette.grey[900]};
  font-size: ${theme.fonts.size.base};
  line-height: ${theme.fonts.spacing.base};
  font-weight: ${theme.fonts.weight.medium};
`;

const StyledSubTitle = styled.h3`
  color: ${theme.palette.grey[500]};
  font-size: ${theme.fonts.size.base};
  line-height: ${theme.fonts.spacing.lg};
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: ${theme.fonts.weight.medium};
`;

const Block = styled(InformationBlock)`
  margin-bottom: 1rem;
`;

const Mentions = styled(Card)`
  margin: 2rem auto;
  color: ${theme.palette.grey[500]};
  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.sm};
  font-weight: ${theme.fonts.weight.regular};

  p {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0rem;
    }
  }
  .list_title,
  li {
    margin-bottom: 0rem;
  }

  ul:last-child {
    margin-bottom: 0rem;
  }

  table {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 0 1px ${theme.palette.grey[200]};
    li {
      margin-bottom: 0;
    }
  }
  thead {
    background-color: ${theme.palette.grey[50]};
  }
  th:first-child {
    border-radius: 10px 0 0 0;
  }
  th:last-child {
    border-radius: 0 10px 0 0;
  }

  tbody tr {
    border-top: 1px solid ${theme.palette.grey[200]};
  }

  thead,
  tbody,
  tr,
  td,
  th {
    border-radius: 10px;
  }
  th,
  td {
    vertical-align: top;
    padding: 0.5rem;
    color: ${theme.palette.grey[600]};
    font-weight: ${theme.fonts.weight.medium};
  }
`;

const LegalMentions: React.FC = () => {
  const defaultConsent = localStorage.getItem('forgetMe') === 'true' || false;
  const { t } = useTranslation();
  const [modalOpen, setModal] = useState(false);
  const [consentChecked, setConsentChecked] = useState(defaultConsent);
  const { matomoPushInstruction } = useContext(MatomoCustomContext);
  const trackEvent = useMatomoHarmonizer();

  const updateConsent = (checked: boolean) => {
    if (checked) {
      localStorage.setItem('forgetMe', 'true');
      matomoPushInstruction('forgetUserOptOut');
    } else {
      localStorage.removeItem('forgetMe');
      matomoPushInstruction('optUserOut');
    }
    window.location.reload();
  };

  return (
    <>
      <Mentions>
        <StyledTitle>{t('features.legalNotice.privayPolicy', 'PRIVACY POLICY')}</StyledTitle>
        <p>{t('features.legalNotice.text_1_1', '')}</p>
        <p>{t('features.legalNotice.text_1_2', '')}</p>
        <p>
          {t('features.legalNotice.text_1_3', '')}
          <a href="https://www.cnil.fr" target="_blank" rel="noopener noreferrer">
            www.cnil.fr
          </a>
        </p>
        <p className="list_title">{t('features.legalNotice.means', 'text_1_list')}</p>
        <ul>
          <li>{t('features.legalNotice.text_1_list_1', '')}</li>
          <li>{t('features.legalNotice.text_1_list_2', '')}</li>
          <li>{t('features.legalNotice.text_1_list_3', '')}</li>
          <li>{t('features.legalNotice.text_1_list_4', '')}</li>
          <li>{t('features.legalNotice.text_1_list_5', '')}</li>
        </ul>
        <p>{t('features.legalNotice.text_1_5', '')}</p>
        <StyledTitle>
          {t(
            'features.legalNotice.whoIsTheData',
            'WHO IS THE DATA CONTROLLER OF YOUR PERSONAL DATA ?'
          )}
        </StyledTitle>
        <p>{t('features.legalNotice.text_2_1', '')}</p>
        <p>{t('features.legalNotice.text_2_2', '')}</p>
        <StyledTitle>
          {t('features.legalNotice.whatIsPersonalData', 'WHAT IS PERSONAL DATA ?')}
        </StyledTitle>
        <p>{t('features.legalNotice.text_3_1', '')}</p>
        <StyledTitle>
          {t(
            'features.legalNotice.whatpersonalData',
            'WHAT PERSONAL DATA DO WE COLLECT FROM YOU AND HOW DO WE COLLECT IT ?'
          )}
        </StyledTitle>
        <p>{t('features.legalNotice.text_4_1', '')}</p>
        <p>{t('features.legalNotice.text_4_2', '')}</p>
        <p>{t('features.legalNotice.text_4_3', '')}</p>
        <Block
          title={
            <p>
              {t('features.legalNotice.text_4_4', '')}
              <br />
              {t('features.legalNotice.text_4_list', '')}
            </p>
          }
        >
          <ul>
            <li>{t('features.legalNotice.text_4_list_1', '')}</li>
            <li>{t('features.legalNotice.text_4_list_2', '')}</li>
          </ul>
        </Block>

        <p>{t('features.legalNotice.text_4_5', '')}</p>

        <table>
          <thead>
            <tr>
              <th>{t('features.legalNotice.text_4_col_1_line_1', '')}</th>
              <th>{t('features.legalNotice.text_4_col_2_line_1', '')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('features.legalNotice.text_4_col_1_line_2', '')}</td>
              <td>
                {t('features.legalNotice.text_4_col_2_line_2', '')}
                <ul>
                  <li>{t('features.legalNotice.text_4_col_2_line_2_a', '')}</li>
                  <li>{t('features.legalNotice.text_4_col_2_line_2_b', '')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('features.legalNotice.text_4_col_1_line_3', '')}</td>
              <td>
                {t('features.legalNotice.text_4_col_2_line_3', '')}
                <ul>
                  <li>{t('features.legalNotice.text_4_col_2_line_3_a', '')}</li>
                  <li>{t('features.legalNotice.text_4_col_2_line_3_b', '')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('features.legalNotice.text_4_col_1_line_4', '')}</td>
              <td>
                {t('features.legalNotice.text_4_col_2_line_4', '')}
                <ul>
                  <li>{t('features.legalNotice.text_4_col_2_line_4_a', '')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('features.legalNotice.text_4_col_1_line_5', '')}</td>
              <td>
                {t('features.legalNotice.text_4_col_2_line_5', '')}
                <ul>
                  <li>{t('features.legalNotice.text_4_col_2_line_5_a', '')}</li>
                  <li>{t('features.legalNotice.text_4_col_2_line_5_b', '')}</li>
                  <li>{t('features.legalNotice.text_4_col_2_line_5_c', '')}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>{t('features.legalNotice.text_4_6', '')}</p>
        <p>{t('features.legalNotice.text_4_7', '')}</p>
        <StyledTitle>
          {t('features.legalNotice.whyAxioneDoes', 'WHY DOES AXIONE COLLECT MY PERSONAL DATA ?')}
        </StyledTitle>
        <p>{t('features.legalNotice.text_5_1', '')}</p>
        <p>{t('features.legalNotice.text_5_list', '')}</p>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={<p>{t('features.legalNotice.text_5_list_1', '')}</p>}
        >
          <ul>
            <li>{t('features.legalNotice.text_5_list_1_a', '')}</li>
          </ul>
        </Block>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={
            <p>
              {t('features.legalNotice.text_5_list_2', '')}{' '}
              <a href="mailto:cotation@axione.fr">cotation@axione.fr</a>
            </p>
          }
        />
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={<p>{t('features.legalNotice.text_5_list_3', '')}</p>}
        >
          <ul>
            <li>{t('features.legalNotice.text_5_list_3_a', '')}</li>
          </ul>
        </Block>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={<p>{t('features.legalNotice.text_5_list_4', '')}</p>}
        >
          <ul>
            <li>{t('features.legalNotice.text_5_list_4_a', '')}</li>
          </ul>
          <Button
            onClick={() => {
              setModal(true);
              trackEvent({
                page: 'Legals',
                category: 'Politique_confidentialité',
                actionType: 'Clic',
                actionDetails: 'Popup_Optout'
              });
            }}
          >
            <Icon fillColor="white" noBackground size="minimal">
              <BoxArrowUpRight />
            </Icon>
            {t('features.legalNotice.refuseTracked', 'Refuse to be tracked')}
          </Button>
        </Block>

        <p>{t('features.legalNotice.text_5_2', '')}</p>
        <StyledTitle>
          {t(
            'features.legalNotice.onWhatLegal',
            'ON WHAT LEGAL BASIS AND FOR HOW LONG IS YOUR PERSONAL DATA PROCESSED ?'
          )}
        </StyledTitle>
        <p>{t('features.legalNotice.text_6_1', '')}</p>
        <StyledSubTitle>{t('features.legalNotice.text_6_2', '')}</StyledSubTitle>
        <p>{t('features.legalNotice.text_6_list', '')}</p>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={<p>{t('features.legalNotice.text_6_list_1', '')}</p>}
        >
          <ul>
            <li>{t('features.legalNotice.text_6_list_1_a', '')}</li>
          </ul>
        </Block>
        <p>{t('features.legalNotice.text_6_3', '')}</p>
        <table>
          <thead>
            <tr>
              <th>{t('features.legalNotice.text_6_col_1_line_1', '')}</th>
              <th>{t('features.legalNotice.text_6_col_2_line_1', '')}</th>
              <th colSpan={2}>{t('features.legalNotice.text_6_col_3_line_1', '')}</th>
              <th>{t('features.legalNotice.text_6_col_4_line_1', '')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('features.legalNotice.text_6_col_1_line_2', '')}</td>
              <td>{t('features.legalNotice.text_6_col_2_line_2', '')}</td>
              <td colSpan={2}>
                <p>{t('features.legalNotice.text_6_col_3_line_2', '')}</p>
                <p>{t('features.legalNotice.text_6_col_3_line_2_a', '')}</p>
              </td>
              <td>/</td>
            </tr>
          </tbody>
        </table>
        <p>{t('features.legalNotice.text_6_4', '')}</p>
        <StyledTitle>
          {t(
            'features.legalNotice.whoHasAccess',
            ' WHO HAS ACCESS TO YOUR PERSONAL DATA ? WHO ARE THE RECIPIENTS OF YOUR DATA ? '
          )}
        </StyledTitle>
        <p>{t('features.legalNotice.text_7_1', '')}</p>
        <p className="list_title">{t('features.legalNotice.text_7_list', '')}</p>
        <ul>
          <li>{t('features.legalNotice.text_7_list_1', '')}</li>
          <li>{t('features.legalNotice.text_7_list_2', '')}</li>
        </ul>
        <p>{t('features.legalNotice.text_7_2', '')}</p>
        <StyledTitle>
          {t(
            'features.legalNotice.whereDoWeStore',
            ' WHERE DO WE STORE YOUR PERSONAL DATA ? IS YOUR DATA TRANSFERRED OUTSIDE THE EUROPEAN UNION ? '
          )}
        </StyledTitle>
        <p>{t('features.legalNotice.text_8_1', '')}</p>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={t('features.legalNotice.text_8_block_title_1', '')}
        >
          <p>
            <b>{t('features.legalNotice.whatThisMean', 'What this means : ')}</b>
            {t('features.legalNotice.text_8_block_1', '')}
          </p>
        </Block>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={t('features.legalNotice.text_8_block_title_2', '')}
        >
          <p>
            <b>{t('features.legalNotice.whatThisMeans', 'What this means : ')}</b>
            {t('features.legalNotice.text_8_block_2', '')}
          </p>
          <p>{t('features.legalNotice.text_8_block_2_a', '')}</p>
          <p>{t('features.legalNotice.text_8_block_2_b', '')}</p>
        </Block>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={t('features.legalNotice.text_8_block_title_3', '')}
        >
          <p>
            <b>{t('features.legalNotice.whatThisMeans', 'What this means : ')}</b>
            {t('features.legalNotice.text_8_block_3', '')}
          </p>
          <p>{t('features.legalNotice.text_8_block_3_a', '')}</p>
        </Block>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={t('features.legalNotice.text_8_block_title_4', '')}
        >
          <p className="list_title">
            <b>{t('features.legalNotice.whatThisMeans', 'What this means : ')}</b>
            {t('features.legalNotice.text_8_block_4', '')}
          </p>
          <ul>
            <li>{t('features.legalNotice.text_8_block_4_a', '')}</li>
            <li>{t('features.legalNotice.text_8_block_4_b', '')}</li>
            <li>{t('features.legalNotice.text_8_block_4_c', '')}</li>
            <li>{t('features.legalNotice.text_8_block_4_d', '')}</li>
          </ul>
          <p>{t('features.legalNotice.text_8_block_4_e', '')}</p>
          <p>{t('features.legalNotice.text_8_block_4_f', '')}</p>
        </Block>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={t('features.legalNotice.text_8_block_title_5', '')}
        >
          <p>
            <b>{t('features.legalNotice.whatThisMeans', 'What this means : ')}</b>
            {t('features.legalNotice.text_8_block_5', '')}
          </p>
          <p>{t('features.legalNotice.text_8_block_5_a', '')}</p>
        </Block>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={t('features.legalNotice.text_8_block_title_6', '')}
        >
          <p>
            <b>{t('features.legalNotice.whatThisMeans', 'What this means : ')}</b>
            {t('features.legalNotice.text_8_block_6', '')}
          </p>
          <p>{t('features.legalNotice.text_8_block_6_a', '')}</p>
          <p>{t('features.legalNotice.text_8_block_6_b', '')}</p>
        </Block>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={t('features.legalNotice.text_8_block_title_7', '')}
        >
          <p>
            <b>{t('features.legalNotice.whatThisMeans', 'What this means : ')}</b>
            {t('features.legalNotice.text_8_block_7', '')}
          </p>
          <p>{t('features.legalNotice.text_8_block_7_a', '')}</p>
          <p>{t('features.legalNotice.text_8_block_7_b', '')}</p>
        </Block>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={t('features.legalNotice.text_8_block_title_8', '')}
        >
          <p>
            <b>{t('features.legalNotice.means', 'What this means : ')}</b>
            {t('features.legalNotice.text_8_block_8', '')}
          </p>
          <p>{t('features.legalNotice.text_8_block_8_a', '')}</p>
          <p className="list_title">{t('features.legalNotice.text_8_block_8_b', '')}</p>
          <ul>
            <li>{t('features.legalNotice.text_8_block_8_c', ' ')}</li>
            <li>{t('features.legalNotice.text_8_block_8_d', '')}</li>
            <li>{t('features.legalNotice.text_8_block_8_e', ' ')}</li>
            <li>{t('features.legalNotice.text_8_block_8_f', ' ')}</li>
          </ul>
          <p>{t('features.legalNotice.text_8_block_8_g', '')}</p>
        </Block>
        <Block
          type="custom"
          customIcon={<>💡</>}
          title={t('features.legalNotice.text_8_block_title_9', '')}
        >
          <p>
            <b>{t('features.legalNotice.whatThisMeans', 'What this means : ')}</b>
            {t('features.legalNotice.text_8_block_9', '')}
          </p>
        </Block>

        <StyledTitle>
          {t('features.legalNotice.howCanYouExercise', 'HOW CAN YOU EXERCISE YOUR RIGHTS ?')}
        </StyledTitle>
        <p className="list_title">{t('features.legalNotice.text_9_list', '')}</p>
        <ul>
          <li>
            {t('features.legalNotice.text_9_list_1', '')}
            <StyledSubTitle>
              AXIONE SAS – Informatique et Libertés - 130 Boulevard Camélinat 92240 MALAKOFF
            </StyledSubTitle>
          </li>
          <li>
            {t('features.legalNotice.text_9_list_2', '')}
            <a href="mailto:contact.rgpd@axione.com">contact.rgpd@axione.com</a>
          </li>
        </ul>
        <p>
          {t('features.legalNotice.text_9_1', '')}
          <a href="https://www.cnil.fr/plaintes" target="_blank" rel="noopener noreferrer">
            www.cnil.fr/plaintes
          </a>
          {t('features.legalNotice.text_9_1_a', '')}
        </p>
        <StyledTitle>
          {t('features.legalNotice.changePrivacyPolicy', 'CHANGES TO THIS PRIVACY POLICY')}
        </StyledTitle>
        <p>{t('features.legalNotice.text_10_1', '')}</p>
        <p style={{ float: 'right' }}> V.1.0 03-02-2023</p>
      </Mentions>
      <Modal show={modalOpen}>
        <>
          <img width="100%" style={{ marginBottom: '1rem' }} src={incognito} />
          <ModalText>
            <h2>
              {t('features.legalNotice.proceedIncognito', 'Proceed in the incognito experience')}
            </h2>
            <p>
              {t(
                'features.legalNotice.objectTrackingBrowsing',
                'You can object to the tracking of your browsing on this website. It will also prevent the owner from learning from your actions and creating a better experience for you and other users. '
              )}
            </p>

            <Checkbox
              label={t(
                'features.legalNotice.proceedIncognito',
                'Proceed in the incognito experience'
              )}
            >
              <input
                id="consentChecked"
                type="checkbox"
                onChange={() => {
                  setConsentChecked(!consentChecked);
                }}
                checked={consentChecked}
              />
            </Checkbox>
          </ModalText>

          <ModalButton>
            <Button
              design="white"
              onClick={() => {
                setModal(false);
                trackEvent({
                  page: 'Legals',
                  category: 'Politique_confidentialité',
                  actionType: 'Clic',
                  actionDetails: 'Annuler_Optout'
                });
              }}
            >
              {t('component.button.cancel', 'Cancel')}
            </Button>
            <Button
              onClick={() => {
                updateConsent(consentChecked);
                setModal(false);
              }}
            >
              {t('component.button.confirm', 'Confirmer')}
            </Button>
          </ModalButton>
        </>
      </Modal>
    </>
  );
};

export default LegalMentions;
