import styled, { css } from 'styled-components';

interface IPopOver {
  vertical: 'top' | 'bottom';
  horizontal?: TPopOverHorizontal;
}

type TPopOverHorizontal = 'left' | 'right' | 'center';

const defineHorizontalPosition = (horizontal: TPopOverHorizontal) => {
  switch (horizontal) {
    case 'right':
      return 'left: 100%;margin-left: -25px;';
    case 'center':
      return 'left: 0px;';
    default:
      return 'right: 100%;margin-right: -25px;';
  }
};

const definePopOverPosition = (props: IPopOver) => {
  const verticalValue =
    props.vertical === 'top' ? 'bottom: 100%; margin-bottom: 4px;' : 'top: 100%; margin-top: 4px;';
  const horizontalValue = props.horizontal ? defineHorizontalPosition(props.horizontal) : '';

  return `${verticalValue}${horizontalValue}`;
};

const defaultStyle = () => {
  return css`
    position: absolute;
    ${definePopOverPosition}
    width: 300px;
    padding: 10px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
    z-index: 3;
  `;
};

export const StyledPopOver = styled.div<IPopOver>`
  ${defaultStyle()}
`;
