import OrderInformationBasicForm from '@features/order/OrderInformationBasicForm';
import { useAppSelector } from '../../app/hooks';
import { selectOrderState } from '@features/order/reducer/orderSlice';
import { productsListWithInfos } from '@features/eligibility/const';
import OrderInformationOperaBusinessForm from '@features/order/OrderInformationOperaBusinessForm';
import Spinner from '@designSystem/Spinner';

const OrderInformations = () => {
  const { productToOrder } = useAppSelector(selectOrderState);

  const productWithInfos = productsListWithInfos.find(
    (product) => product.code === productToOrder?.product.productOffering.id
  );

  if (productWithInfos === undefined)
    return (
      <div
        style={{
          display: 'grid',
          margin: 'auto',
          alignItems: 'center',
          height: 'calc(100vh - 129px)'
        }}
      >
        <Spinner spinnerSize={3} />
      </div>
    );

  if (productWithInfos?.isBusiness)
    return <OrderInformationOperaBusinessForm isZTD={!!productWithInfos.isZTD} />;
  return <OrderInformationBasicForm />;
};

export default OrderInformations;
