import { ClientType, IOrderInformationsForm } from '../utils/orderInterfaces';
import { Controller, useFormContext } from 'react-hook-form';

import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const StyledFormTypeSelectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-auto-rows: max-content;
`;

const StyledInput = styled.input`
  cursor: pointer;
  + label {
    padding-left: 0.5rem;
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.base};
  }
`;

const StyledRadioLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  font-familly: ${theme.fonts.primary};
  font-weight: ${theme.fonts.weight.medium};
  color: ${theme.palette.grey[700]};
`;

const OrderInformationsClientType = ({
  clientType,
  setToMissingSiret
}: {
  clientType: string;
  setToMissingSiret: any;
}) => {
  const { control } = useFormContext<IOrderInformationsForm>();
  const { t } = useTranslation();
  const setToIndiviual = (onChange: (...event: any[]) => void) => {
    onChange('individual');
    setToMissingSiret();
  };
  return (
    <Controller
      control={control}
      name={'clientType'}
      render={({ field: { onChange } }) => (
        <StyledFormTypeSelectionContainer>
          <div style={{ display: 'flex' }}>
            <StyledInput
              onChange={() => setToIndiviual(onChange)}
              type="radio"
              id="clientType_individual"
              name="clientType"
              value="individual"
              checked={clientType === ClientType.INDIVIDUAL}
              style={{ marginTop: 6, alignSelf: 'flex-start' }}
            />
            <StyledRadioLabelContainer>
              <label htmlFor="clientType_individual">
                {t('features.order.orderInformations.individual', 'Individual')}
              </label>
              {t('features.order.orderInformations.myClientInd', 'My client is an individual')}
            </StyledRadioLabelContainer>
          </div>

          <div style={{ display: 'flex' }}>
            <StyledInput
              onChange={() => onChange('company')}
              type="radio"
              id="clientType_company"
              name="clientType"
              value="company"
              checked={clientType === ClientType.COMPANY}
              style={{ marginTop: 6, alignSelf: 'flex-start' }}
            />
            <StyledRadioLabelContainer>
              <label htmlFor="clientType_company">
                {t('features.order.orderInformations.company', 'Company')}
              </label>
              {t('features.order.orderInformations.myClientComp', 'My client is a company')}
            </StyledRadioLabelContainer>
          </div>
        </StyledFormTypeSelectionContainer>
      )}
    />
  );
};

export default OrderInformationsClientType;
