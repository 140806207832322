import { Building, GeoAltFill, HouseDoorFill } from 'react-bootstrap-icons';
import React, { ReactChild } from 'react';

import AddressCardContent from './AddressCardContent';
import Button from '@designSystem/Button';
import { Card } from 'react-bootstrap';
import { StyledCard } from '@designSystem/Card/designStyles';
import { selectAdressInfos } from '../eligibilitySlice';
import styled from 'styled-components';
import theme from '@theme';
import { useAppSelector } from '../../../app/hooks';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';
import { useTranslation } from 'react-i18next';
import { getBuildingIdFromAddress } from '@features/eligibility/helper';

interface IAddressCardProps {
  shouldDisplayEdit?: boolean;
  badge?: ReactChild | null;
  previousStep?: CallableFunction;
}
const AddressCard: React.FC<IAddressCardProps> = ({
  previousStep,
  badge,
  shouldDisplayEdit = true
}) => {
  const addressInfos = useAppSelector(selectAdressInfos);

  const { t } = useTranslation();
  const trackEvent = useMatomoHarmonizer();
  const editAddress = () => {
    trackEvent({
      page: 'Commandes',
      category: 'Produit',
      actionType: 'Clic',
      actionDetails: 'Boutton_Modifier_Adresse'
    });
    previousStep?.();
  };
  return (
    <CustomStyledCard>
      {addressInfos && (
        <>
          <div>
            {badge}
            <StyledAddressesCardTitle as="h6">
              {addressInfos.streetNr && (
                <span>
                  {addressInfos.streetNr}
                  {addressInfos.streetNrSuffix},{' '}
                </span>
              )}
              {addressInfos.streetName}, {addressInfos.postcode} {addressInfos.city}
            </StyledAddressesCardTitle>
            <StyledAddressCardDetails>
              {addressInfos?.name && (
                <AddressCardContent icon={<GeoAltFill />} text={addressInfos.name} />
              )}
              {addressInfos?.id && (
                <AddressCardContent
                  icon={<Building />}
                  text={getBuildingIdFromAddress({
                    id: addressInfos?.id,
                    referenceType: addressInfos?.referenceType
                  })}
                />
              )}
              {addressInfos?.housingUnitsNr && (
                <AddressCardContent
                  icon={<HouseDoorFill />}
                  text={
                    <>
                      {addressInfos.housingUnitsNr}{' '}
                      {t('features.eligibility.select.housing', 'housing')}
                    </>
                  }
                />
              )}
            </StyledAddressCardDetails>
          </div>
          {shouldDisplayEdit && (
            <StyledEditButton design="reverse" onClick={editAddress}>
              {t('component.button.edit', 'Edit')}
            </StyledEditButton>
          )}
        </>
      )}
    </CustomStyledCard>
  );
};

export default AddressCard;

const CustomStyledCard = styled(StyledCard)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${theme.palette.blue[50]};
  padding: 1rem;
  margin-bottom: 1rem;
  max-width: 100%;
  box-shadow: none;
`;

const StyledAddressesCardTitle = styled(Card.Title)`
  font-size: ${theme.fonts.size.sm};
  font-weight: ${theme.fonts.weight.medium};
  color: ${theme.palette.blue[700]};
  margin-bottom: 5px;
`;

const StyledAddressCardDetails = styled.div`
  font-size: ${theme.fonts.size.xs};
  font-weight: ${theme.fonts.weight.regular};
  margin-bottom: 0;
`;

const StyledEditButton = styled(Button)`
  align-self: center;
`;
