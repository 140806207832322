import { ReactChild } from 'react';
import { i18n } from '@i18n';

export const siteStatusDetailLignBackgroundColor = 'rgba(155, 220, 238, 0.5)';

/* ****************************** */
/* ****** BUILDING DETAIL ******* */
/* ****************************** */

export const buildingDetailsKeyTranslations = (buildingDetailKey: string) => {
  switch (buildingDetailKey) {
    case 'etat_immeuble':
      return i18n.t('features.siteStatus.utils.building.status', 'Building status');
    case 'nombre_logements_adresse_ipe':
      return i18n.t('features.siteStatus.utils.building.ipeAddress', 'IPE address lodging count');
    case 'raccordement_long':
      return i18n.t('features.siteStatus.utils.building.isLong', 'Is long connection');
    case 'susceptible_raccordable_demande':
      return i18n.t('features.siteStatus.utils.building.canRequest', 'Can request connection');
    case 'code_adresse_immeuble':
      return i18n.t('features.siteStatus.utils.building.addressCode', 'Building address code');
    case 'blocage_eligibilite':
      return i18n.t('features.siteStatus.utils.building.eligibility', 'Blocking eligibility');
    case 'motif_blocage':
      return i18n.t('features.siteStatus.utils.building.reason', 'Blocking reason');
    case 'date_debut_blocage_eligibilite':
      return i18n.t(
        'features.siteStatus.utils.building.blockingEligibility',
        "Blocking eligibility's starting date"
      );
    default:
      return null;
  }
};

/* ****************************** */
/* ******** ORDER DETAIL ******** */
/* ****************************** */

export const siteStatusDetailKeysOrder = [
  'lastmodifieddate',
  'oi__c',
  'code_adresse_final__c',
  'code_postal_final__c',
  'commune_final__c',
  'libelle_de_la_voie_final__c',
  'etage_initial__c',
  'date_du_premier_cr_stoc__c',
  'date_intervention_stoc__c',
  'etat__c',
  'type_raccordement__c',
  'statut__c'
];

export const siteStatusDetailKeyTranslations = (siteStatusDetailsKey: string) => {
  switch (siteStatusDetailsKey) {
    case 'lastmodifieddate':
      return i18n.t('features.siteStatus.utils.lastAcion', 'Last action date');
    case 'oi__c':
      return i18n.t('features.siteStatus.utils.dsp', 'DSP');
    case 'code_adresse_final__c':
      return i18n.t('features.siteStatus.utils.addressCode', 'Address code');
    case 'code_postal_final__c':
      return i18n.t('features.siteStatus.utils.postalCode', 'Postal code');
    case 'commune_final__c':
      return i18n.t('features.siteStatus.utils.city', 'City');
    case 'libelle_de_la_voie_final__c':
      return i18n.t('features.siteStatus.utils.streetName', 'Street name');
    case 'etage_initial__c':
      return i18n.t('features.siteStatus.utils.stair', 'Stair');
    case 'date_du_premier_cr_stoc__c':
      return i18n.t('features.siteStatus.utils.stoc', 'STOC order sending date');
    case 'date_intervention_stoc__c':
      return i18n.t('features.siteStatus.utils.installDate', 'Installation date');
    case 'etat__c':
      return i18n.t('features.siteStatus.utils.stocStatus', 'STOC Status');
    case 'type_raccordement__c':
      return i18n.t('features.siteStatus.utils.connectionKind', 'Connection kind');
    case 'statut__c':
      return i18n.t('features.siteStatus.utils.connectionStatus', 'Connection status');
    default:
      return null;
  }
};

/* ****************************** */
/* ******* TICKET DETAIL ******** */
/* ****************************** */

const queueTranslation: { [key: string]: ReactChild } = {
  '48': i18n.t('features.siteStatus.utils.ftthIncident', 'FTTH Incident Line'),
  '83': i18n.t('features.siteStatus.utils.ftthProduction', 'FTTH Production')
};

export const getCategoryTranslation = (category: string) => {
  return queueTranslation[category] || '';
};

export const ticketDetailKeyTranslations = (ticketKey: string) => {
  switch (ticketKey) {
    case 'traitement':
      return i18n.t('features.siteStatus.utils.detail.treatment', 'Treatment');
    case 'typologie':
      return i18n.t('features.siteStatus.utils.detail.typology', 'Typology');
    case 'froid':
      return i18n.t('features.siteStatus.utils.detail.cold', 'Cold');
    case 'reference_service':
      return i18n.t('features.siteStatus.utils.detail.serviceRef', 'Service Ref');
    case 'sous_domaine_d_intervention':
      return i18n.t('features.siteStatus.utils.detail.Intervention', 'Intervention subdomain');
    case 'commercial_id':
      return i18n.t('features.siteStatus.utils.detail.commercial', 'Commercial ID');
    case 'reference_pto':
      return i18n.t('features.siteStatus.utils.detail.pro', 'PTO Ref');
    default:
      return null;
  }
};

export const ticketDetailKeysOrder = [
  'traitement',
  'typologie',
  'froid',
  'reference_service',
  'sous_domaine_d_intervention',
  'commercial_id',
  'reference_pto'
];

export const ticketsLignBackgroundColor = 'rgba(114, 94, 236, 0.12)';
