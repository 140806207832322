import {
  ChartPivotRow,
  CubejsApi,
  PivotConfig,
  Query,
  ResultSet,
  SeriesNamesColumn
} from '@cubejs-client/core';
import React, { useEffect, useState } from 'react';
import {
  columnDisplayName,
  ocNamesToParent,
  statusReferenceList,
  typesInterventionList
} from '../filters/delegantsFiltersInfos';

import FixedSpinner from '../../../components/FixedSpinner';
import { LeafletMarker } from '../interfaces';
import LeafletRenderer from '../renderer/LeafletRenderer';
import { getTimeDimensions } from '../helpers';
import { queries } from '../utils/chartsQueries';
import { DateManager } from '../../../utils/DateManager';

const LeafletDrawer: React.FC<{
  cubejsApi: CubejsApi | undefined;
  query: Query;
  columnFilters?: string[];
  cubeJsName?: string;
  errorCallback?: (_e: Error) => void;
}> = ({ cubejsApi, query, columnFilters, cubeJsName, errorCallback }) => {
  const [markers, setMarkers] = useState<LeafletMarker[]>([]);
  const [cubeResult, setCubeResult] = useState<ResultSet>();
  const [interventionObject, setInterventionObject] = useState<{ [key: string]: number }>({});

  const getMarkerTextInfos = (index: number) => {
    if (cubeResult) {
      const currentMarker = cubeResult.chartPivot()[index];
      const keys = cubeResult
        .seriesNames()
        .map((seriesNamesColumn: SeriesNamesColumn) => seriesNamesColumn.key);
      const columns = cubeResult.tableColumns({
        x: keys,
        y: ['measures'],
        fillMissingDates: true
      } as PivotConfig);
      const newText = [] as { leftText: string; rightText: string }[];

      let interventionDelai = '';
      let delaiSinceDateCreation = '';

      columns.forEach((c, i) => {
        if (
          columnFilters?.find((cf: string) => {
            return cf === c.title;
          })
        ) {
          return;
        }
        const newTitle = removeCubeName(c.title);
        let dateCreation;
        let interventionName;
        switch (newTitle) {
          case columnDisplayName['Date Creation']:
            dateCreation = new DateManager(currentMarker.xValues[i]);
            newText.push({
              leftText: newTitle,
              rightText: dateCreation.format('DD-MM-YYYY')
            });
            delaiSinceDateCreation = `${new DateManager().diff(dateCreation, 'days')} jours`;
            break;
          case columnDisplayName['Oc']:
            newText.push({
              leftText: newTitle,
              rightText: convertOC(currentMarker.xValues[i])
            });
            break;
          case columnDisplayName['Type Intervention']:
            interventionDelai = `${interventionObject[currentMarker.xValues[i]] || '?'} jours`;
            interventionName =
              typesInterventionList.find(
                (intervention) => intervention.name === currentMarker.xValues[i]
              )?.displayName || currentMarker.xValues[i];
            newText.push({
              leftText: newTitle,
              rightText: interventionName
            });
            break;
          case columnDisplayName['Status']:
            newText.push({
              leftText: newTitle,
              rightText: statusReferenceList[currentMarker.xValues[i]]
            });
            // We push this after status for order purpose only
            newText.push({
              leftText: 'Délai depuis création',
              rightText: delaiSinceDateCreation
            });
            // We push this after status for order purpose only
            newText.push({
              leftText: 'Délai moyen de résolution constaté',
              rightText: interventionDelai
            });
            break;
          case columnDisplayName['Type Voie Immeuble']:
          case columnDisplayName['Nom Voie Immeuble']:
          case columnDisplayName['Code Postal Immeuble']:
          case columnDisplayName['Commune Immeuble']:
            break;
          case columnDisplayName['Numero Voie Immeuble']:
            // In the query, we should put in this order the dimensions:
            // 0) Numero Voie Immeuble;  eg 35
            // 1) Type Voie Immeuble; eg Rue
            // 2) Nom Voie Immeuble; eg Du bachelier
            // 3) Code Postal Immeuble; eg 70120
            // 4) Commune Immeuble; eg Lyon
            newText.push({
              leftText: 'Adresse',
              rightText: `${currentMarker.xValues[i]} ${currentMarker.xValues[i + 1]} ${
                currentMarker.xValues[i + 2]
              } ${currentMarker.xValues[i + 3]} ${currentMarker.xValues[i + 4]}`
            });
            break;
          default:
            newText.push({
              leftText: newTitle,
              rightText: currentMarker.xValues[i]
            });
            break;
        }
      });

      return {
        ...markers[index],
        text: newText
      } as LeafletMarker;
    }

    return {
      ...markers[index]
    } as LeafletMarker;
  };

  const translateColumnName = (str: string) => {
    return columnDisplayName[str];
  };

  const removeCubeName = (str: string) => translateColumnName(str.replace(cubeJsName || '', ''));

  const convertOC = (str?: string) => {
    const ocName = str ? str.toUpperCase() : 'DEFAULT';
    return ocNamesToParent[ocName] || ocName;
  };

  const drawApexChart = (resultSet: ResultSet) => {
    const keys = resultSet
      .seriesNames()
      .map((seriesNamesColumn: SeriesNamesColumn) => seriesNamesColumn.key);
    const columns = resultSet.tableColumns({
      x: keys,
      y: ['measures'],
      fillMissingDates: true
    } as PivotConfig);

    let interventionColumnIndex: number;

    columns.forEach((c, index) => {
      if (
        columnFilters?.find((cf: string) => {
          return cf === c.title;
        })
      ) {
        return;
      }
      const newTitle = removeCubeName(c.title);
      if (newTitle === columnDisplayName['Type Intervention']) {
        // We store the index of intervention column to get the color easily for each marker
        interventionColumnIndex = index;
      }
    });

    const newMarkersArray = resultSet.chartPivot().map((marker: ChartPivotRow) => {
      const newText = [] as { leftText: string; rightText: string }[];

      const color =
        typesInterventionList.find(
          (intervention) => intervention.name === marker.xValues[interventionColumnIndex]
        )?.color || 'markerColor11';
      return {
        // From chartQueries xValues[1] is latitude (wgs84X), xValues[0] is longitude(wgs84Y)
        coordinates: [Number.parseFloat(marker.xValues[1]), Number.parseFloat(marker.xValues[0])],
        text: newText,
        className: `mapState ${color}`
      } as LeafletMarker;
    });
    setMarkers(newMarkersArray);
    setCubeResult(resultSet);
  };

  useEffect(() => {
    let canceled = false;
    if (cubejsApi) {
      cubejsApi
        .load(query)
        .then((resultSet) => {
          if (resultSet && !canceled) {
            drawApexChart(resultSet);
          }
        })
        .catch((e) => {
          errorCallback && errorCallback(e);
        });
    }
    return () => {
      canceled = true;
    };
  }, [interventionObject]);

  useEffect(() => {
    let canceled = false;
    // La carte appelle les tickets ouverts en cours, mais pour afficher le délai moyen de résolution,
    // on a besoin de refaire un call pour obtenir le délai moyen pour chaque tickets
    if (cubejsApi && query) {
      cubejsApi
        .load({
          ...queries.carteNbJoursOuvresMoyen,
          timeDimensions: [...getTimeDimensions('NbJoursOuvresMoyenAjournement')]
        } as Query)
        .then((resultSet) => {
          if (resultSet && !canceled) {
            const newInterventionObject = {} as { [key: string]: number };
            const key = resultSet.seriesNames().length && resultSet.seriesNames()[0].key;
            resultSet.chartPivot().forEach((values) => {
              newInterventionObject[values.x] = Math.round(values[key]);
            });
            setInterventionObject(newInterventionObject);
          }
        })
        .catch((e) => {
          errorCallback && errorCallback(e);
        });
    }
    return () => {
      canceled = false;
    };
  }, [JSON.stringify(query.segments)]);

  return (
    <FixedSpinner loading={!cubeResult}>
      <LeafletRenderer
        markers={cubeResult ? markers : []}
        getMarkerTextInfos={getMarkerTextInfos}
      />
    </FixedSpinner>
  );
};

export default LeafletDrawer;
