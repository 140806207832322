import React, { useEffect } from 'react';
import SelectSearchComponent, { SelectSearchProps } from './SelectSearchComponent';
import { StyledFormControl, StyledWrapComponent } from './SearchStyle';
import { clearSearchType, setSearchType } from '../eligibilitySlice';

import { decodeDataFrom } from '../helper';
import { useAppDispatch } from '../../../app/hooks';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IFormInputs {
  buildingRef: string;
}

const SearchByBuildingRef: React.FC<{
  selectProps?: SelectSearchProps;
}> = ({ selectProps }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { register, watch } = useForm<IFormInputs>({
    mode: 'onChange',
    defaultValues: {
      buildingRef: decodeDataFrom(searchParams).buildingRef || ''
    }
  });
  const buildingRef = watch('buildingRef');
  const { t } = useTranslation();
  useEffect(() => {
    if (buildingRef) {
      dispatch(
        setSearchType({
          type: 'building',
          data: { buildingRef: buildingRef.trim() }
        })
      );
    } else {
      dispatch(clearSearchType());
    }
  }, [buildingRef]);

  return (
    <StyledWrapComponent>
      <StyledFormControl
        type="text"
        {...register('buildingRef')}
        value={buildingRef}
        aria-label="buildingRef-input"
        placeholder={t(
          'features.eligibility.search.exactBuilding',
          'Enter the exact building reference'
        )}
      />
      {selectProps && <SelectSearchComponent {...selectProps} />}
    </StyledWrapComponent>
  );
};

export default SearchByBuildingRef;
