import Badge from '@designSystem/Badges';
import React from 'react';
import ReactSelect from 'react-select';
import { StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import { customSelectStyles } from '../../../../style/customSelectStyle';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

interface IOrderAppointmentFormScheduleProps {
  selectedSchedule: string | false;
  validTimeSchedule: string[];
  setSelectedSchedule: (value: string) => void;
}

const formatOptionLabel = (option: any) => <Badge>{option.label}</Badge>;

const OrderAppointmentFormSchedule: React.FC<IOrderAppointmentFormScheduleProps> = ({
  selectedSchedule,
  validTimeSchedule,
  setSelectedSchedule
}: IOrderAppointmentFormScheduleProps) => {
  const { t } = useTranslation();
  return (
    <StyledOrderSchedulesWrapper>
      <StyledLabel>
        {t('features.order.orderAppointment.orderAppointmentForm.slot', 'Slots')}
      </StyledLabel>
      <ReactSelect
        options={validTimeSchedule.map((e: string) => ({
          value: e,
          label: e
        }))}
        value={
          selectedSchedule
            ? {
                value: selectedSchedule,
                label: selectedSchedule
              }
            : undefined
        }
        id="select-slot-appointment"
        isDisabled={validTimeSchedule.length <= 0}
        onChange={(val: any) => setSelectedSchedule(val.value)}
        placeholder={t('features.calendars.calendarForm.selectSlot', 'Select Slot')}
        data-testid="select-slot-appointment"
        styles={customSelectStyles}
        formatOptionLabel={formatOptionLabel}
      />
    </StyledOrderSchedulesWrapper>
  );
};

export default OrderAppointmentFormSchedule;

const StyledOrderSchedulesWrapper = styled.div`
  margin-left: 24px;
  min-width: 255px;
  width: 100%;

  .schedule_row {
    display: flex;
    align-items: center;

    Button {
      font-size: ${theme.fonts.size.xs};
    }
  }

  .schedule_marged_top {
    margin-top: 4px;
  }

  .schedule_badge {
    margin-left: 10px;
    max-height: 20px;
  }
`;
