import React, { useEffect } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  resetCurrentCalendar,
  setCalendarLastWeekSlots
} from '@features/calendars/reducer/calendarSlice';

import CalendarForm from '@features/calendars/calendarForm/CalendarForm';
import { Container } from 'react-bootstrap';
import FixedSpinner from '../../components/FixedSpinner';
import styled from 'styled-components';
import { useAppDispatch } from '../../app/hooks';

const StyledCalendarFormContainer = styled(Container)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const CalendarCreate: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentCalendar } = useSelector((state: RootStateOrAny) => state.calendars);

  useEffect(() => {
    dispatch(resetCurrentCalendar());
    dispatch(setCalendarLastWeekSlots(null));
  }, []);

  return (
    <StyledCalendarFormContainer>
      <FixedSpinner loading={currentCalendar !== null}>
        {currentCalendar === null && <CalendarForm />}
      </FixedSpinner>
    </StyledCalendarFormContainer>
  );
};

export default CalendarCreate;
