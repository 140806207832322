import { ThemeProps, ThemeVariants } from '../../style/theme';

import { css } from 'styled-components';

export interface IThemedTooltip {
  onMouseEnter?: any;
  onMouseLeave?: any;
  theme?: ThemeProps;
  max: string;
  variant: ThemeVariants;
}

export const defaultTooltipStyle = () => css`
  display: block;
  position: absolute;
  width: 100vmin;
  padding: 8px;
  left: 50%;
  border-radius: 4px;
  transform: translateX(-50%);
  z-index: 100;

  color: white;
  word-break: break-word;
  background: ${(props: IThemedTooltip) => props.theme?.palette[props.variant][900]};
  font-size: ${(props: IThemedTooltip) => props.theme?.fonts.size.sm};
  max-width: ${(props: IThemedTooltip) => props.max};

  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: calc(6px * -1);
  }

  &.top {
    top: calc(6px * -1);
    transform: translateX(-50%) translateY(-100%);
  }

  &.top::before {
    top: 100%;
    border-top-color: ${(props: IThemedTooltip) => props.theme?.palette[props.variant][900]};
  }

  &.right {
    left: calc(100% + 6px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  &.right::before {
    left: calc(6px * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${(props: IThemedTooltip) => props.theme?.palette[props.variant][900]};
  }

  &.bottom {
    top: calc(100% - 6px * -1);
  }

  &.bottom::before {
    bottom: 100%;
    border-bottom-color: ${(props: IThemedTooltip) => props.theme?.palette[props.variant][900]};
  }

  &.left {
    left: auto;
    right: calc(100% + 6px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  &.left::before {
    left: auto;
    right: calc(6px * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: ${(props: IThemedTooltip) => props.theme?.palette[props.variant][900]};
  }
`;
