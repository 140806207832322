import Card from '@designSystem/Card';
import styled from 'styled-components';
import theme from '@theme';

export const StyledHeaderResults = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin: 1rem 4px;
`;

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
`;

export const StyledCustomCard = styled(Card)`
  display: flex;
  cursor: pointer;
  min-height: 180px;
  max-width: 400px;
`;

export const StyledTitleAvailableProductsStyle = styled.div`
  color: ${theme.palette.grey[500]};
  font-family: ${theme.fonts.primary};
  font-size: ${theme.fonts.size.sm};
  font-weight: ${theme.fonts.weight.medium};
`;

export const StyledOfferProductIcon = styled.div`
  margin-right: 12px;
`;

export const StyledOfferProductInfoIconContainer = styled.span`
  margin-top: 3px;
`;

export const StyledPriceGreyCard = styled.div`
  background-color: ${theme.palette.grey[50]};
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
`;

export const StyledProductDetails = styled.div`
  margin-top: 25px;
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.base};
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

export const StyledProductLeadTime = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding-left: 50px;
  line-height: ${theme.fonts.size.xs};
  font-size: ${theme.fonts.size.xs};
  font-weight: ${theme.fonts.weight.medium};
  color: ${theme.palette.grey[400]};
`;

export const StyledPopOverItemContainer = styled.div`
  display: flex;
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.sm};
  color: ${theme.palette.grey[500]};
  padding: 8px 16px;
  background-color: ${theme.palette.grey[50]};
  margin-top: 10px;
  border-radius: 4px;

  &:first-child {
    margin-top: 0px;
  }
`;
