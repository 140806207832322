import { EchartPlotOption, EchartSerie } from '../interfaces';

import React from 'react';
import ReactECharts from 'echarts-for-react';

const EchartsRenderer: React.FC<{
  series: EchartSerie[] | EchartSerie;
  plotOptions?: EchartPlotOption;
  categories?: string[];
  categoryAxisIsX?: boolean;
  categoryAxisIsY?: boolean;
}> = ({
  series,
  categories,
  plotOptions = {},
  categoryAxisIsX = false,
  categoryAxisIsY = false
}) => {
  const translatedPlotOptions = { ...plotOptions };

  if (plotOptions.title) {
    translatedPlotOptions.title = {
      ...plotOptions.title,
      text: plotOptions.title.text
    };
  }

  const echartOptions = {
    ...translatedPlotOptions,
    series
  };

  if (categories) {
    if (categoryAxisIsX) {
      echartOptions.xAxis = {
        ...echartOptions.xAxis,
        type: 'category',
        data: categories
      };
    } else if (categoryAxisIsY) {
      echartOptions.yAxis = {
        ...echartOptions.yAxis,
        type: 'category',
        data: categories
      };
    }
  }

  return <ReactECharts option={echartOptions} style={{ height: '400px' }} />;
};

export default EchartsRenderer;
