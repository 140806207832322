import { productsListWithInfos } from '@features/eligibility/const';
import InformationBlock from '@designSystem/InformationBlock';
import { env } from '../../../../../config';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import React, { Dispatch, FC } from 'react';
import styled from 'styled-components';
import theme from '@theme';
import { TFunction } from 'i18next';
import Button from '@designSystem/Button';
import { OrderOptions } from '@features/order/utils/orderInterfaces';
import { Trunk } from '@features/eligibility/products/productDetail/modal/trunk.interface';

export const retrieveActiveTrunksByProductCode = (data: Trunk[], productCode: string): Trunk[] => {
  const productMatched = productsListWithInfos.find((product) => product.code === productCode);
  return data.filter(
    (trunk) => trunk.state === 'Actif' && trunk.engineeringType === productMatched?.engineeringType
  );
};

export const NoTrunkFound = ({ t }: { t: TFunction<'translation', undefined> }) => (
  <div>
    <StyledTitle>
      {t('features.eligibility.products.productDetail.noTrunkFound', "Aucun tronc n'a été trouvé")}
    </StyledTitle>
    <br />
    <InformationBlock
      title={
        <>
          {t('features.eligibility.products.productDetail.optionTrunks_error')}
          <a href={env.URL_ORDER_TRUNKS}>lien</a>.
        </>
      }
      type="warning"
      customIcon={<ExclamationCircleFill />}
    />
  </div>
);

export const StyledTitle = styled.p`
  text-align: center;
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.lg};
  line-height: ${theme.fonts.spacing.base};
  color: ${theme.palette.grey[900]};
  margin-bottom: 0;
  margin-top: 24px;
`;

interface FooterProps {
  t: TFunction<'translation', undefined, 'translation'>;
  step: 1 | 2;
  handleClose: () => void;
  setOptionsToOrder: Dispatch<React.SetStateAction<OrderOptions | undefined>>;
  setStep: Dispatch<React.SetStateAction<1 | 2>>;
  dataTestId: string;
  validateDetailForm: () => void;
  trunk: any;
  loading: boolean;
}

export const Footer: FC<FooterProps> = ({
  t,
  handleClose,
  setStep,
  step,
  validateDetailForm,
  setOptionsToOrder,
  dataTestId,
  trunk,
  loading
}) => {
  const isDisabled = () => !(step === 2 && trunk.trunk.length) || loading;
  return (
    <div>
      {handleClose && step === 1 && (
        <Button
          design="white"
          style={{ width: '100%' }}
          onClick={() => {
            setOptionsToOrder(undefined);
            handleClose && handleClose();
          }}
        >
          {t('component.button.cancel', 'Cancel')}
        </Button>
      )}
      {step === 1 && (
        <Button
          data-testid={dataTestId}
          style={{ width: '100%' }}
          onClick={() => setStep(2)}
          disabled={false}
        >
          {t('component.button.nextStep', 'Next step')}
        </Button>
      )}
      {step === 2 && (
        <>
          <Button design="white" style={{ width: '100%' }} onClick={() => setStep(1)}>
            {t('component.button.previousStep', 'Previous step')}
          </Button>
          <Button
            data-testid={dataTestId}
            style={{ width: '100%' }}
            onClick={validateDetailForm}
            disabled={isDisabled()}
          >
            {t('component.button.validate', 'Validate')}
          </Button>
        </>
      )}
    </div>
  );
};
