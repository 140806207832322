import { displayToastErrorWithMessage } from '../../utils/toastHelper';
import { ErrorMessageWithMail } from './components/ToasErrorWithMail';
import React, { Dispatch, SetStateAction } from 'react';
import { TrackEventPage } from '../../utils/matomoHarmonizer';
import { TFunction } from 'i18next';
import { UseFormSetError } from 'react-hook-form';
import {
  IOrderInformationsForm,
  IOrderInformationsOperaBusinessForm
} from '@features/order/utils/orderInterfaces';

export const onPatchOrderError = (
  event: {
    errors: Array<{ detail: string }> | string;
  },
  setLoading: Dispatch<SetStateAction<boolean>>,
  setError:
    | UseFormSetError<IOrderInformationsForm>
    | UseFormSetError<IOrderInformationsOperaBusinessForm>,
  trackEvent: ({
    page,
    actionType,
    actionDetails,
    category,
    lastParamsNumber
  }: TrackEventPage) => void,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  setLoading(false);

  if (typeof event.errors !== 'string') {
    if (
      event.errors.find(
        (error) => error.detail === 'FIMP13: IDENTIFIANT COMMANDE INTERNE OC DEJA UTILISE'
      )
    ) {
      setError('internReference', {
        type: 'custom',
        message: t('features.order.alreadyUsed', `Already used`)
      });
      trackEvent({
        page: 'Order_informations',
        category: 'formulaire',
        actionType: 'Erreur',
        actionDetails: 'Ref_Deja_Utilisé'
      });

      displayToastErrorWithMessage(
        t(
          'features.order.errorWithMessage',
          'Oops... \n Your internal order reference has already been used, please fill in a new one.'
        )
      );
    }
    if (
      event.errors.find((error) => error.detail === 'FIMP11 : ADRESSE INELIGIBLE TEMPORAIREMENT')
    ) {
      setError('address', {
        type: 'custom',
        message: t('features.order.alreadyUsed', `Already used`)
      });

      trackEvent({
        page: 'Order_informations',
        category: 'formulaire',
        actionType: 'Erreur',
        actionDetails: 'Adresse_Ineligible_Temporairement'
      });

      displayToastErrorWithMessage(
        t(
          'features.order.errorsWithMessage.selectedSiteNotEligible',
          'Oops... \n It seems that the selected site is not eligible. Please contact the ADV if you have any question.'
        )
      );
    }
  } else {
    displayToastErrorWithMessage(
      t('errorMessages.wentWrong', `Something went wrong`),
      <ErrorMessageWithMail />
    );
  }
};
