import React from 'react';
import { doLogout, getUsername } from '../../../app/AuthService';

import Dropdown from '@designSystem/Dropdown';
import Icon from '@designSystem/Icon';
import { PersonFill } from 'react-bootstrap-icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';

const StyledUserActionsDropdownPlaceholderTitle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUserActionsDropdownUserName = styled.div`
  margin-left: 8px;
`;

const UserActions: React.FC = () => {
  const navigate = useNavigate();
  const trackEvent = useMatomoHarmonizer();

  const { t } = useTranslation();
  return (
    <Dropdown
      reversed
      placeholder={
        <StyledUserActionsDropdownPlaceholderTitle>
          <Icon round background iconSize="12px" size="minimal">
            <PersonFill />
          </Icon>
          <StyledUserActionsDropdownUserName>{getUsername()}</StyledUserActionsDropdownUserName>
        </StyledUserActionsDropdownPlaceholderTitle>
      }
    >
      <div
        className="clickable dropdown_item"
        style={{ padding: '0.5rem 0.75rem' }}
        onClick={() => {
          navigate('/');
          trackEvent({
            page: 'Header',
            category: 'Log-out',
            actionType: 'Clic',
            actionDetails: 'Déconnexion'
          });
          doLogout();
        }}
      >
        {t('component.button.logOut', 'Log out')}
      </div>
    </Dropdown>
  );
};

export default UserActions;
