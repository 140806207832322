import React, { useEffect, useState } from 'react';
import { TileLayer, useMap } from 'react-leaflet';

import { LatLngTuple } from 'leaflet';
import { LeafletMarker } from '../interfaces';
import LeafletMarkers from './LeafletMarkers';
import { parisCoordinates } from '../helpers';

type LeafletChangeViewType = {
  center: LatLngTuple | undefined;
  zoom: number;
};

interface LeafletMapProps extends LeafletChangeViewType {
  markers: LeafletMarker[];
  getMarkerTextInfos: (_i: number) => LeafletMarker;
}

const LeafletMarkerLayer: React.FC<LeafletMapProps> = ({
  markers,
  getMarkerTextInfos,
  center,
  zoom
}) => {
  const [currentMarker, setCurrentMarker] = useState<number>();
  const [innerBounds, setInnerBounds] = useState<[number, number][]>();

  const map = useMap();

  useEffect(() => {
    if (innerBounds && innerBounds.length > 0) {
      map.fitBounds(innerBounds);
    }
  }, [innerBounds]);

  useEffect(() => {
    setCurrentMarker(undefined);
    if (markers.length && markers.length > 0) {
      const newInnerBound: [number, number][] = [];
      markers.forEach((marker) => {
        newInnerBound.push([marker.coordinates[0], marker.coordinates[1]]);
      });
      setInnerBounds(newInnerBound);
    } else {
      setInnerBounds([]);
    }
  }, [markers]);

  const ChangeView = (view: LeafletChangeViewType) => {
    if (view.center && view.center !== parisCoordinates) {
      const usemap = useMap();
      usemap.setView(view.center, view.zoom);
    }
    return null;
  };

  const getCurrentMarkersCoordinates = () => {
    if (markers.length > 0 && currentMarker && markers[currentMarker]) {
      return [
        markers[currentMarker].coordinates[0],
        markers[currentMarker].coordinates[1]
      ] as LatLngTuple;
    }
    return center;
  };

  return (
    <>
      <ChangeView center={getCurrentMarkersCoordinates()} zoom={zoom} />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LeafletMarkers
        markers={markers}
        getMarkerTextInfos={getMarkerTextInfos}
        setCurrentMarker={setCurrentMarker}
        currentMarker={currentMarker}
      />
    </>
  );
};

export default LeafletMarkerLayer;
