import axios from 'axios';
import { env } from '../../../config';

export const getEscalationList = (clientName: string) => {
  return axios.get(`${env.API_URL}/escalate?clientName=${clientName}`);
};

export const getEscalationOwners = () => {
  return axios.get(`${env.API_URL}/escalate/owners`);
};

export const getLevelsBy = async (sectionId?: number, searchValue?: string) => {
  return axios.get(
    `${env.API_URL}/escalate/sections/${sectionId}/levels${
      searchValue ? `?searchValue=${searchValue}` : ''
    }`
  );
};

export const getLevelById = async (levelId: string) => {
  return axios.get(`${env.API_URL}/escalate/levels/${levelId}`);
};

export const editLevelsById = async (levelId: number, body: { [key: string]: string }) => {
  return axios.put(`${env.API_URL}/escalate/levels/${levelId}`, {
    ...body
  });
};
