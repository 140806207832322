import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';

import ApiError from '../components/ApiError';
import { PowerBIEmbed } from 'powerbi-client-react';
import Spinner from '@designSystem/Spinner';
import { getPowerBIEmbeddedInfos } from '@features/powerbi/reducer/powerbiAPI';
import { models } from 'powerbi-client';
import { reportList } from '@features/powerbi/const';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface ReportType {
  title: string;
  reportId: string;
}

const PowerBIEmbedded: React.FC = () => {
  const { t } = useTranslation();
  const [embeddedInfos, setEmbeddedInfos] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [APIError, setAPIError] = useState(false);
  const [reportSelected, setReportSelected] = useState(reportList[0]);

  // Can be useful if we replace ajournement with this page
  // useEffect(() => {
  //   trackEvent({ page: 'Ajournement', category: 'Page', actionType: 'Vu' });
  // }, []);

  const fetchPowerBIEmbeddedInfos = async (reportId: string) => {
    setIsLoading(true);
    setAPIError(false);

    await getPowerBIEmbeddedInfos(reportId)
      .then((res: any) => {
        setEmbeddedInfos(res.data);
      })
      .catch(() => {
        toast.error(t('errorMessages.errorHappen', `An error happened`));
        setAPIError(true);
        setEmbeddedInfos(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (selectedOption: SingleValue<{ value: ReportType; label: string }>) => {
    if (selectedOption) {
      setReportSelected(selectedOption.value);
    }
  };

  useEffect(() => {
    if (reportSelected) {
      fetchPowerBIEmbeddedInfos(reportSelected.reportId);
    }
  }, [reportSelected]);

  return (
    <>
      <StyledReportSelector>
        <StyledSelect>
          <Select
            placeholder={t('powerBIEmbedded.selectReportPlaceholder', 'Sélectionner')}
            value={{ value: reportSelected, label: reportSelected.title }}
            onChange={(selectedOption) => handleChange(selectedOption)}
            options={reportList.map((report) => ({ label: report.title, value: report }))}
            isSearchable
          />
        </StyledSelect>
      </StyledReportSelector>
      {isLoading && <Spinner spinnerSize={2} center />}
      {APIError && <ApiError />}
      {embeddedInfos && (
        <PowerBIEmbed
          embedConfig={{
            type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: embeddedInfos.embedUrl[0].reportId,
            embedUrl: embeddedInfos.embedUrl[0].embedUrl,
            accessToken: embeddedInfos.accessToken,
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                }
              },
              background: models.BackgroundType.Transparent
            }
          }}
          cssClassName={'powerbi-report-class'}
        />
      )}
    </>
  );
};

const StyledReportSelector = styled.div`
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

const StyledSelect = styled.div`
  width: 50%;
`;

export default PowerBIEmbedded;
