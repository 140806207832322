import {
  IDataSearchType,
  IGeographicAddress,
  ISearchInfos
} from '@features/eligibility/interfaces';
import ProgressStepper, { StepProps } from '@designSystem/ProgressStepper';
import React, { ReactElement, useContext, useEffect } from 'react';
import { buildSearchQueryByInfos, checkDataConsistency } from '@features/eligibility/select/helper';
import {
  changeStep,
  enabledSteps,
  resetToStep,
  selectCurrentStep
} from '@features/order/reducer/orderSlice';
import { decodeDataFrom, encodeDataFrom } from '@features/eligibility/helper';
import {
  loadGeographicAddresses,
  selectAdressInfos,
  selectSearchInfos,
  setAddress,
  setSearchType
} from '@features/eligibility/eligibilitySlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useParams, useSearchParams } from 'react-router-dom';

import { MatomoCustomContext } from '../utils/MatomoCustomProvider';
import SelectOptionsList from '@features/eligibility/SelectOptionsList';
import SelectSite from '@features/eligibility/SelectSite';
import { Tab } from 'react-bootstrap';
import { useMatomoHarmonizer } from '../utils/matomoHarmonizer';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../utils/stringUtils';

const StepperGoogleCondensed: React.FC<{
  otherSteps: StepProps[];
  children: ReactElement[];
}> = ({ otherSteps, children }) => {
  const searchInfos = useAppSelector(selectSearchInfos);
  const addressInfos = useAppSelector(selectAdressInfos);
  const currentStep = useAppSelector(selectCurrentStep);
  const { orderId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const { matomoTrackPageView } = useContext(MatomoCustomContext);
  const trackEvent = useMatomoHarmonizer();

  useEffect(() => {
    // No tracking on 3rd step
    if (currentStep === 0) {
      matomoTrackPageView(`order_Recherche_site`);
    } else if (currentStep === 1) {
      matomoTrackPageView(`order_Choix_site`);
    }
  }, [currentStep]);

  const tabChanged = (key: string | null) => {
    switch (key) {
      case 'step-0':
        resetSearch();
        break;
      case 'step-1':
        validateFirstStep();
        break;
      case 'step-2':
        validateSecondStep();
        break;
      default:
        key && dispatch(changeStep(parseInt(key.slice(5), 10)));
        break;
    }
  };

  const resetSearch = () => {
    dispatch(setAddress({ address: undefined })); // reset step3
    searchParams.delete('selected');
    setSearchParams(searchParams);
    dispatch(resetToStep(0));
  };

  useEffect(() => {
    if (!searchParams.get('type') && !orderId) {
      resetSearch();
    }
  }, [searchParams]);

  // On page init get to the last possible page
  useEffect(() => {
    if (currentStep === 0) {
      const type = searchParams.get('type') as IDataSearchType;
      const data = decodeDataFrom(searchParams);
      const alreadySelected = searchParams.get('selected');

      const search: ISearchInfos = {
        type,
        data
      };

      if (data && type) {
        if (checkDataConsistency(type, data)) {
          dispatch(setSearchType(search));
        } else {
          resetSearch();
          return;
        }
      }

      if (alreadySelected && data && type) {
        const queryParam = buildSearchQueryByInfos(search);
        dispatch(loadGeographicAddresses(queryParam)).then((res) => {
          if (res.payload) {
            const addresses: IGeographicAddress[] = res.payload;
            const selected = addresses.find((address) => address.id === alreadySelected);
            dispatch(setAddress({ address: selected }));
          }
        });
        dispatch(enabledSteps([0, 1, 2]));
        validateSecondStep();
      } else if (data && type) {
        dispatch(enabledSteps([0, 1]));
        validateFirstStep();
      } else {
        resetSearch();
      }
    }
  }, []);

  const validateFirstStep = () => {
    if (searchInfos.type) {
      searchParams.set('data', encodeDataFrom(searchInfos.data));
    }
    setSearchParams(searchParams);
    dispatch(changeStep(1));
    const typeValue = searchParams.get('type');

    trackEvent({
      page: 'Commandes',
      category: 'Recherche_Site',
      actionType: 'Valider',
      actionDetails: typeValue ? capitalizeFirstLetter(typeValue) : undefined
    });
  };

  const validateSecondStep = () => {
    if (addressInfos) {
      searchParams.set('type', searchInfos.type);
      searchParams.set('data', encodeDataFrom(searchInfos.data));
      searchParams.set('selected', addressInfos.id);
      setSearchParams(searchParams);
    }
    dispatch(changeStep(2));
    trackEvent({ page: 'Commandes', category: 'Choix_Site', actionType: 'Valider' });
  };

  const getCondensedStep = (step: number) => {
    // Step 0 and Step 1 are merged in timeline
    return step === 1 ? 0 : step;
  };
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          padding: '1rem 1.5rem',
          maxWidth: '1188px',
          margin: '0 auto'
        }}
      >
        <ProgressStepper
          currentStep={`step-${getCondensedStep(currentStep)}`}
          stepList={[
            {
              key: 'step-0',
              title: t('component.stepperGoogle.eligibility', 'Eligibility')
            },
            ...otherSteps
          ]}
        />
      </div>
      <Tab.Container activeKey={`step-${currentStep}`} onSelect={tabChanged} unmountOnExit>
        <Tab.Content className="px-4">
          <Tab.Pane eventKey="step-0">
            <SelectOptionsList nextStep={validateFirstStep} />
          </Tab.Pane>
          <Tab.Pane eventKey="step-1">
            <SelectSite
              nextStep={() => {
                validateSecondStep();
              }}
              previousStep={resetSearch}
            />
          </Tab.Pane>
          {children}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default StepperGoogleCondensed;
