import { IOfferingResult, IVerticalitySelectedProductData } from './interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { exportFile, isProductWithVerticality, isZTD } from './helper';
import {
  getProducts,
  orderProduct,
  orderVerticality,
  resetOrderData,
  selectAdressInfos,
  selectAdressInfosFormated
} from './eligibilitySlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import AddressCard from './select/AddressCard';
import Button from '@designSystem/Button';
import Card from '@designSystem/Card';
import { Col } from 'react-bootstrap';
import ErrorList from './products/ErrorList';
import HeaderCardCustom from './HeaderCardCustom';
import LinkOrAnchor from '@designSystem/LinkOrAnchor';
import { MatomoCustomContext } from '../../utils/MatomoCustomProvider';
import OrderInfoPanel from './products/OrderInfoPanel';
import ProductVerticalityModal from './products/productVerticality/productVerticalityModal';
import Results from './products/results/Results';
import TableNoData from '../../components/TableNoData';
import { i18n } from '@i18n';
import { isFAIRestreint } from '../../components/layout/const';
import { mailtoAxione } from './const';
import { productColumns } from './products/Columns';
import styled from 'styled-components';
import { useGuardHook } from '../../utils/FeatureFlag';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';

const CustomCard = styled(Card)`
  max-width: 100%;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 1440px) {
    max-width: 1160px;
    margin: 0 auto;
  }
`;

const StyledScroll = styled.div`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const SelectOffer: React.FC<{ previousStep?: CallableFunction }> = ({ previousStep }) => {
  const dispatch = useAppDispatch();

  const { matomoTrackPageView } = useContext(MatomoCustomContext);
  const trackEvent = useMatomoHarmonizer();

  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);
  const { products, productsErrors, productsLoading } = useSelector(
    (state: RootStateOrAny) => state.eligibility
  );
  const [productHasLoaded, setProductHasLoaded] = useState(false);

  const canDisplayPrice = useGuardHook('display_product_price');
  const canMakeAnOrder = useGuardHook('make_an_order');
  const canRequestAQuote = useGuardHook('request_a_quote');
  const [showVerticalityModal, setShowVerticalityModal] = useState(false);
  const [verticalityProductData, setVerticalityProductData] =
    useState<IVerticalitySelectedProductData>();
  const [selectedProduct, setSelectedProduct] = useState<IOfferingResult | null>(null);

  const addressInfos = useAppSelector(selectAdressInfos);
  const { t } = useTranslation();

  useEffect(() => {
    matomoTrackPageView(`Eligibilité_Choix_produit`);
  }, []);

  // Used for matomo tracking
  useEffect(() => {
    if (productsLoading) {
      setProductHasLoaded(true);
    }
  }, [productsLoading]);

  useEffect(() => {
    if (productHasLoaded && !productsLoading && products && products.length === 0) {
      trackEvent({
        page: 'Eligibilité',
        category: 'Choix_produit',
        actionType: 'Aucun',
        actionDetails: 'Produit_Disponible'
      });
    }
  }, [products, productsLoading]);

  const addressInfosFormated = useAppSelector(selectAdressInfosFormated);

  const isQuotation = (product: IOfferingResult) => {
    return product?.qualificationItemResult.toUpperCase() === 'TO_BE_STUDIED';
  };

  const takeOrder = React.useCallback(() => {
    const type =
      selectedProduct?.qualificationItemResult.toUpperCase() === 'TO_BE_STUDIED'
        ? 'quotation'
        : 'order';

    const matomoTypeLabel = type === 'quotation' ? 'Lancer_étude' : 'Commander';
    trackEvent({
      page: 'Eligibilité',
      category: 'Choix_produit',
      actionType: 'Clic',
      actionDetails: matomoTypeLabel
    });
    if (addressInfos && selectedProduct) {
      if (addressInfos.buildingRef) {
        dispatch(orderVerticality({ addressInfos }));
      }

      const isVerticality = isProductWithVerticality(selectedProduct);

      const productToOrder = selectedProduct.product;
      if (isVerticality) {
        trackEvent({
          page: 'Eligibilité',
          category: 'Choix_produit',
          actionType: 'Vu',
          actionDetails: 'Popup_Verticalité'
        });

        setVerticalityProductData({
          orderType: type,
          product: productToOrder,
          isZTD: isZTD(selectedProduct)
        });
      } else {
        dispatch(
          orderProduct({
            type,
            selectedProduct: productToOrder,
            addressInfos,
            verticalityInfos: {}
          })
        );
      }
    }
  }, [addressInfos, selectedProduct]);

  useEffect(() => {
    if (addressInfos) {
      setSelectedProduct(null);
      dispatch(resetOrderData());
      dispatch(getProducts(addressInfos));
    }
  }, [addressInfos]);

  const displayVerticality = () => {
    if (addressInfos && addressInfos.buildingRef) {
      dispatch(orderVerticality({ addressInfos }));
    }
    setShowVerticalityModal(true);
    trackEvent({
      page: 'Eligibilité',
      category: 'Choix_produit',
      actionType: 'Vu',
      actionDetails: 'Verticalité'
    });
  };

  const columns = React.useMemo(
    () => productColumns(canDisplayPrice),
    [addressInfos, canDisplayPrice]
  );

  const displayTable = productsLoading || (products && products.length > 0);

  const cardFooterButtonTitle = () => {
    if (products.length > 0 && isQuotation(products[0])) {
      return t('features.eligibility.launchStudy', 'Launch study');
    }
    return t('features.eligibility.order', 'Order');
  };

  const isOrderButtonDisabled = !(
    selectedProduct && !selectedProduct.eligibilityUnavailabilityReason
  );

  const hasNextStepButton =
    (canMakeAnOrder && !isFAIRestreint(profileType?.rolesNames)) || canRequestAQuote;

  const returnOnAddressList = () => {
    if (previousStep) {
      trackEvent({ page: 'Eligibilité', category: 'Choix_produit', actionType: 'Retour' });
      previousStep();
    }
  };
  return (
    <CustomCard
      header={
        <HeaderCardCustom
          titleHeader={t('features.eligibility.chooseProduct', 'Choose a product')}
          subTitleHeader={t(
            'features.eligibility.selectProduct',
            'Select the product that suits your needs'
          )}
        />
      }
      footer={
        <>
          <Button design="white" onClick={() => previousStep && previousStep()}>
            {t('component.button.previousStep', 'Previous Step')}
          </Button>

          {hasNextStepButton && (
            <Button
              style={{
                marginLeft: 'auto'
              }}
              disabled={isOrderButtonDisabled}
              onClick={takeOrder}
            >
              {cardFooterButtonTitle()}
            </Button>
          )}
        </>
      }
    >
      <StyledScroll>
        <Col sm="12">
          <AddressCard previousStep={returnOnAddressList} />
        </Col>
        {productsErrors && (
          <Col sm="12">
            <ErrorList productsErrors={productsErrors} />
          </Col>
        )}

        {selectedProduct && (
          <OrderInfoPanel
            isQuotation={isQuotation(selectedProduct)}
            navigateBackUrl={'/eligibility'}
          />
        )}

        <Results
          exportFile={(i) => {
            trackEvent({
              page: 'Eligibilité',
              category: 'Choix_produit',
              actionType: 'Télécharger',
              actionDetails: 'Devis'
            });

            return exportFile(window.location.href, addressInfosFormated)(i);
          }}
          loading={productsLoading}
          columns={columns}
          data={products}
          setSelectedProduct={(p: IOfferingResult) => {
            // disable product selection for FAI_Restreint (only for order) or readonly roles
            if (!isQuotation(p)) {
              setSelectedProduct(
                canMakeAnOrder && !isFAIRestreint(profileType?.rolesNames) ? p : null
              );
            } else {
              setSelectedProduct(canRequestAQuote ? p : null);
            }
          }}
          selectedProduct={selectedProduct}
          noDataDisplay={!displayTable ? NoDataComponent() : null}
          displayVerticality={displayVerticality}
          onShowPopOver={() => {
            trackEvent({
              page: 'Eligibilité',
              category: 'Choix_produit',
              actionType: 'Clic',
              actionDetails: 'Infobulle'
            });
          }}
        />
        {verticalityProductData && (
          <>
            <ProductVerticalityModal
              orderType={verticalityProductData.orderType}
              selectedProduct={verticalityProductData.product}
              isZTD={verticalityProductData?.isZTD}
              showModal={!!verticalityProductData}
              handleClose={() => setVerticalityProductData(undefined)}
              showValidate
            />
          </>
        )}
        {showVerticalityModal && (
          <>
            <ProductVerticalityModal
              showModal={showVerticalityModal}
              handleClose={() => setShowVerticalityModal(false)}
            />
          </>
        )}
      </StyledScroll>
    </CustomCard>
  );
};

export default SelectOffer;

export const NoDataComponent = () => {
  const trackEvent = useMatomoHarmonizer();
  return (
    <TableNoData
      containerStyle={{ marginTop: 20 }}
      title={i18n.t('features.eligibility.notEligible', 'You are not eligible to any offer')}
      description={
        <>
          {i18n.t(
            'features.eligibility.addressNotEligible',
            'This address is not eligible yet. Please contact the commercial service for more details :'
          )}
          <LinkOrAnchor
            onClick={(e) => {
              trackEvent({
                page: 'Eligibilité',
                category: 'Choix_produit',
                actionType: 'Clic',
                actionDetails: 'Cotation_Mail'
              });
              window.location.href = mailtoAxione;
              e.preventDefault();
            }}
          >
            cotation@axione.fr.
          </LinkOrAnchor>
        </>
      }
    />
  );
};
