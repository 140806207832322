import { YearMonthDay } from '@features/order/utils/constants';
import { DateManager } from '../../../../utils/DateManager';

export const getAdjustedDatesByProduct = (
  monthSelected: YearMonthDay,
  isHomeBasic?: boolean
): { startDay: DateManager; endDay: DateManager } => {
  const date = new DateManager(monthSelected);

  if (isHomeBasic) {
    const currentDate = new DateManager();
    const startOfMonth = new DateManager(monthSelected).startOf('month');

    const startDay =
      date.diff(currentDate, 'days') < 8
        ? new DateManager(currentDate).add(9, 'days')
        : startOfMonth;

    return { startDay, endDay: new DateManager(currentDate).add(60, 'days') };
  }

  const endDay = new DateManager(date).add(3, 'month');

  return {
    startDay: date,
    endDay: endDay
  };
};
