import React, { useEffect, useState } from 'react';
import { getSiteStatusTickets, selectSiteStatusState } from '../reducer/siteStatusSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import FixedSpinner from '../../../components/FixedSpinner';
import OrderTicketTable from './TicketTable';
import Pagination from '@designSystem/Pagination';
import { Ticket } from '../utils/siteStatusInterfaces';
import TicketDetail from './TicketDetail';
import { siteStatusTicketsListColumns } from './TicketsListColumns';

const SiteStatusTickets: React.FC<{ refPrestationPrise?: string | null }> = ({
  refPrestationPrise
}) => {
  const dispatch = useAppDispatch();
  const { siteStatusTicketsLoading, siteStatusTickets } = useAppSelector(selectSiteStatusState);
  const [pageData, setPageData] = useState<{ rowData: Ticket[]; totalCount: number }>({
    rowData: [],
    totalCount: 0
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentTicket, setCurrentTicket] = useState<Ticket | null>(null);

  useEffect(() => {
    setLoading(true);
    if (refPrestationPrise !== undefined && refPrestationPrise !== null) {
      dispatch(getSiteStatusTickets({ refPrestationPrise, currentPage, pageSize: 10 })).then(() => {
        setLoading(false);
      });
    } else {
      setPageData({ rowData: [], totalCount: 0 });
      setLoading(false);
    }
  }, [refPrestationPrise]);

  useEffect(() => {
    if (siteStatusTickets !== undefined) {
      setPageData({
        rowData: siteStatusTickets,
        totalCount: siteStatusTickets.length
      });
    }
  }, [siteStatusTickets]);

  const showTicketDetails = (value: Ticket | null) => {
    setCurrentTicket(value);
  };

  const columns = React.useMemo(
    () => siteStatusTicketsListColumns(showTicketDetails, currentTicket ? currentTicket.id : null),
    [currentPage, pageData, currentTicket]
  );

  return (
    <FixedSpinner loading={loading || siteStatusTicketsLoading}>
      <OrderTicketTable
        columns={columns}
        data={pageData.rowData}
        currentSelectedRow={currentTicket ? currentTicket.id : null}
      >
        {currentTicket !== null ? <TicketDetail currentTicket={currentTicket} /> : <></>}
      </OrderTicketTable>
      <Pagination totalRows={pageData.rowData.length} pageChangeHandler={setCurrentPage} />
    </FixedSpinner>
  );
};

export default SiteStatusTickets;
