import { ITab } from '../../components/PageHeader/tabHeader/TabHeader';
import { i18n } from '@i18n';

export const tabsOrderTracking: ITab[] = [
  {
    i18nKey: 'features.order.orderTracking.all',
    name: i18n.t('features.order.orderTracking.all'),
    state: undefined,
    inTheModal: false
  },
  {
    i18nKey: 'features.order.orderTracking.tabsStatus.inProgress',
    name: i18n.t('features.order.orderTracking.tabsStatus.inProgress'),
    state: 'inProgress',
    inTheModal: false
  },
  {
    i18nKey: 'features.order.orderTracking.tabsStatus.completed',
    name: i18n.t('features.order.orderTracking.tabsStatus.completed'),
    state: 'completed',
    inTheModal: false
  },
  {
    i18nKey: 'features.order.orderTracking.tabsStatus.rejected',
    name: i18n.t('features.order.orderTracking.tabsStatus.rejected'),
    state: 'rejected',
    inTheModal: false
  },
  {
    i18nKey: 'features.order.orderTracking.tabsStatus.cancelled',
    name: i18n.t('features.order.orderTracking.tabsStatus.cancelled'),
    state: 'cancelled',
    inTheModal: true
  },
  {
    i18nKey: 'features.order.orderTracking.tabsStatus.submitted',
    name: i18n.t('features.order.orderTracking.tabsStatus.submitted'),
    state: 'submitted',
    inTheModal: true
  },
  {
    i18nKey: 'features.order.orderTracking.tabsStatus.acknowledged',
    name: i18n.t('features.order.orderTracking.tabsStatus.acknowledged'),
    state: 'acknowledged',
    inTheModal: true
  },
  {
    i18nKey: 'features.order.orderTracking.tabsStatus.held',
    name: i18n.t('features.order.orderTracking.tabsStatus.held'),
    state: 'held',
    inTheModal: true
  },
  {
    i18nKey: 'features.order.orderTracking.tabsStatus.failed',
    name: i18n.t('features.order.orderTracking.tabsStatus.failed'),
    state: 'failed',
    inTheModal: true
  }
];
