import { Controller, useForm } from 'react-hook-form';
import { ICity, IStreet } from '../interfaces';
import React, { useEffect, useState } from 'react';
import SelectSearchComponent, { SelectSearchProps } from './SelectSearchComponent';
import { StyledFormControl, StyledWrapComponent } from './SearchStyle';
import {
  clearSearchType,
  loadCities,
  loadStreetNumbers,
  loadStreets,
  setSearchType
} from '../eligibilitySlice';
import { decodeDataFrom, encodeDataFrom } from '../helper';

import AsyncSelect from 'react-select/async';
import Spinner from '@designSystem/Spinner';
import { customSelectStyles } from '../../../style/customSelectStyle';
import styled from 'styled-components';
import { useAppDispatch } from '../../../app/hooks';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ReactSelectOption {
  label: string;
  value: string;
}

interface IFormInputs {
  postcode: string;
  city: ReactSelectOption | null;
  street: ReactSelectOption | null;
  streetNr: ReactSelectOption | null;
}

const InputSpacer = styled.div`
  margin-bottom: 1rem;
`;

const searchFilter = (items: ReactSelectOption[], inputValue: string) => {
  return items.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
};

const SearchByFunnel: React.FC<{
  selectProps?: SelectSearchProps;
}> = ({ selectProps }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObj, setSearchParamsObj] = useState(decodeDataFrom(searchParams));
  const { t } = useTranslation();

  useEffect(() => {
    setSearchParamsObj(decodeDataFrom(searchParams));
  }, [searchParams]);

  const { control, register, setValue, watch } = useForm<IFormInputs>({
    mode: 'onChange',
    defaultValues: {
      postcode: searchParamsObj.postcode || '',
      city: null,
      street: null,
      streetNr: null
    }
  });
  const [cities, setCities] = useState<ReactSelectOption[]>([]);
  const [streets, setStreets] = useState<ReactSelectOption[]>([]);
  const [streetNumbers, setStreetNumbers] = useState<ReactSelectOption[]>([]);
  const [citiesLoading, setCitiesLoading] = useState(false);

  const postcode = watch('postcode');
  const city = watch('city');
  const street = watch('street');
  const streetNr = watch('streetNr');

  const updateSearchParams = (key: string, value?: string) => {
    const param = searchParamsObj;
    if (value) {
      param[key] = value;
    } else {
      delete param[key];
    }
    searchParams.set('data', encodeDataFrom(param));
    setSearchParams(searchParams);
  };

  const resetValuesUpTo = (resetUpTo: 'city' | 'street' | 'streetNr') => {
    if (resetUpTo === 'city') {
      setValue('city', null);
      setCities([]);
    }
    if (resetUpTo === 'city' || resetUpTo === 'street') {
      setValue('street', null);
      setStreets([]);
    }
    setValue('streetNr', null);
    setStreetNumbers([]);
    dispatch(clearSearchType());
  };

  useEffect(() => {
    resetValuesUpTo('city');
    if (postcode && postcode.length === 5) {
      if (postcode !== searchParamsObj.postcode) {
        updateSearchParams('city');
        updateSearchParams('street');
        updateSearchParams('streetNr');
      }
      updateSearchParams('postcode', postcode);
      setCitiesLoading(true);
      dispatch(loadCities(postcode)).then((res) => {
        if (res.payload) {
          setCities(
            res.payload.map((i: ICity) => {
              return { label: i.city, value: i.city };
            })
          );
        }
        setCitiesLoading(false);
      });
    }
  }, [postcode]);

  useEffect(() => {
    resetValuesUpTo('street');
    if (city) {
      if (city.value != searchParamsObj.city) {
        updateSearchParams('street');
        updateSearchParams('streetNr');
      }
      updateSearchParams('city', city.value);
      dispatch(loadStreets({ postcode, city: city.value })).then((res) => {
        if (res.payload) {
          setStreets(
            res.payload.map((i: IStreet) => {
              return { label: i.name, value: i.name };
            })
          );
        }
      });
    }
  }, [city]);

  useEffect(() => {
    resetValuesUpTo('streetNr');
    if (street && city) {
      if (street.value != searchParamsObj.street) {
        updateSearchParams('streetNr');
      }
      updateSearchParams('street', street.value);
      dispatch(loadStreetNumbers({ postcode, city: city.value, street: street.value })).then(
        (res) => {
          if (res.payload) {
            setStreetNumbers(
              res.payload.map((i: number) => {
                return { label: i, value: i };
              })
            );
          }
        }
      );

      dispatch(
        setSearchType({
          type: 'funnel',
          data: {
            postcode,
            city: city.value,
            street: street.value
          }
        })
      );
    }
  }, [street]);

  useEffect(() => {
    if (street && city) {
      streetNr ? updateSearchParams('streetNr', streetNr.value) : updateSearchParams('streetNr');
      dispatch(
        setSearchType({
          type: 'funnel',
          data: {
            postcode: postcode,
            city: city.value,
            street: street.value,
            streetNr: streetNr?.value
          }
        })
      );
    }
  }, [streetNr]);

  return (
    <>
      <StyledWrapComponent>
        <StyledFormControl
          type="text"
          {...register('postcode')}
          aria-label="postcode-input"
          placeholder={t('features.eligibility.search.funnel', 'postCode')}
          maxLength={5}
          disabled={citiesLoading}
        />
        {selectProps && <SelectSearchComponent {...selectProps} />}
      </StyledWrapComponent>
      {citiesLoading && <Spinner spinnerSize={2} aria-label="postcode-spinner" center />}

      {cities.length > 0 && (
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <InputSpacer>
              <AsyncSelect
                {...field}
                aria-label="city-select"
                loadOptions={(inputValue, callback: CallableFunction) => {
                  const paramCity = searchParamsObj.city;

                  callback(searchFilter(cities, inputValue));
                  if (paramCity) {
                    const filtered = cities.filter((i) => i.value === paramCity);
                    if (filtered.length) setValue('city', filtered[0]);
                  } else if (cities.length === 1) setValue('city', cities[0]);
                }}
                defaultOptions
                cacheOptions
                value={city}
                placeholder={t('features.eligibility.search.city', 'City name')}
                styles={customSelectStyles}
                isClearable={true}
                noOptionsMessage={() => t('errorMessages.notFound', 'No found')}
              />
            </InputSpacer>
          )}
        />
      )}
      {streets.length > 0 && (
        <Controller
          name="street"
          control={control}
          render={({ field }) => (
            <InputSpacer>
              <AsyncSelect
                {...field}
                aria-label="street-select"
                loadOptions={(inputValue, callback: CallableFunction) => {
                  const paramStreet = searchParamsObj.street;

                  callback(searchFilter(streets, inputValue));
                  if (paramStreet) {
                    const filtered = streets.filter((i) => i.value === paramStreet);
                    if (filtered.length) setValue('street', filtered[0]);
                  } else if (streets.length === 1) setValue('street', streets[0]);
                }}
                defaultOptions
                cacheOptions
                value={street}
                id="street-name"
                placeholder={t('features.eligibility.search.street', 'Street name')}
                styles={customSelectStyles}
                isClearable={true}
                noOptionsMessage={() => t('errorMessages.notFound', 'No result found')}
              />
            </InputSpacer>
          )}
        />
      )}
      {streetNumbers.length > 0 && (
        <Controller
          name="streetNr"
          control={control}
          render={({ field }) => (
            <InputSpacer>
              <AsyncSelect
                {...field}
                aria-label="streetnr-select"
                loadOptions={(inputValue, callback: CallableFunction) => {
                  const paramStreetNr = searchParamsObj.streetNr;

                  callback(searchFilter(streetNumbers, inputValue));
                  if (paramStreetNr) {
                    const filtered = streetNumbers.filter((i) => i.value === paramStreetNr);
                    if (filtered.length) setValue('streetNr', filtered[0]);
                  } else if (streetNumbers.length === 1) setValue('streetNr', streetNumbers[0]);
                }}
                id={'street-nr'}
                defaultOptions
                cacheOptions
                value={streetNr}
                placeholder={t(
                  'features.eligibility.search.selectNumber',
                  'Select street number or leave empty'
                )}
                styles={customSelectStyles}
                isClearable={true}
                noOptionsMessage={() => t('errorMessages.notFound', 'No result found')}
              />
            </InputSpacer>
          )}
        />
      )}
    </>
  );
};

export default SearchByFunnel;
