import { IThemedBadge, disabledColor } from './designHelper';

import { css } from 'styled-components';

export const defaultStyle = (colorFunction: CallableFunction) => {
  return css`
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${getPadding};

    border-radius: 4px;

    box-sizing: border-box;
    transition: background-color 250ms ease-out, color 250ms ease-out, border-color 250ms ease-out;
    border: 1px solid
      ${(props: IThemedBadge) =>
        props.disabled ? disabledColor(props)['border'] : colorFunction(props)['border']};

    font-family: ${(props: IThemedBadge) => props.theme.fonts.primary};
    font-weight: ${(props: IThemedBadge) => props.theme.fonts.weight.medium};
    font-size: ${(props: IThemedBadge) =>
      props.design === 'small' ? props.theme.fonts.size.xs : props.theme.fonts.size.sm};
    line-height: ${(props: IThemedBadge) =>
      props.design === 'small' ? props.theme.fonts.size.xs : props.theme.fonts.size.sm};
  `;
};

const getPadding = (props: IThemedBadge) => {
  const hasElementRight = !!props.hasOnClick;
  const hasElementLeft = !!props.leftContent;
  const isSmall = props.design === 'small';

  if (isSmall) {
    if (hasElementRight && hasElementLeft) {
      return '2px 0px';
    } else if (hasElementRight) {
      return '2px 2px 2px 8px';
    } else if (hasElementLeft) {
      return '2px 8px 2px 2px';
    }
  } else {
    if (hasElementRight && hasElementLeft) {
      return '2px';
    } else if (hasElementRight) {
      return '2px 4px 2px 8px';
    } else if (hasElementLeft) {
      return '2px 8px 2px 4px';
    }

    return '2px 12px';
  }
  return '2px 8px';
};

const getOutlineCrossStyle = (props: IThemedBadge, colorFunction: CallableFunction) => {
  if (!props.disabled) {
    return `${colorFunction(props)['focusOutlineColor']} solid 4px`;
  }
  return '0';
};

export const crossStyle = (colorFunction: CallableFunction) => {
  return css`
     {
      display: flex;
      align-items: center;
      margin: 0px 2px;
      border-radius: 100%;

      cursor: ${(props: IThemedBadge) => (props.disabled ? 'default' : 'pointer')};
      pointer-events: ${(props: IThemedBadge) => (props.disabled ? 'none' : 'auto')};

      font-size: ${(props) =>
        props.design === 'small' ? props.theme.fonts.size.sm : props.theme.fonts.size.base};
      line-height: ${(props) =>
        props.design === 'small' ? props.theme.fonts.size.xs : props.theme.fonts.size.sm};

      &:hover,
      &:active {
        background-color: ${(props: IThemedBadge) =>
          props.disabled
            ? disabledColor(props)['backgroundColor']
            : props.leftContent
            ? colorFunction(props)['backgroundColor']
            : colorFunction(props)['hoverBackgroundColor']};
      }

      &:focus,
      &:focus:not(:focus-visible) {
        background-color: ${(props: IThemedBadge) =>
          !props.disabled && colorFunction(props)['backgroundColor']};
        outline: ${(props: IThemedBadge) => getOutlineCrossStyle(props, colorFunction)};
      }
    }
  `;
};

export const leftContentStyle = (colorFunction: CallableFunction) => {
  return css`
     {
      display: flex;
      align-items: center;
      margin: 0px 2px;
      border-radius: 100%;

      cursor: ${(props: IThemedBadge) =>
        props.disabled || !props.leftAction ? 'default' : 'pointer'};
      pointer-events: ${(props: IThemedBadge) =>
        props.disabled || !props.leftAction ? 'none' : 'auto'};

      font-size: ${(props) =>
        props.design === 'small' ? props.theme.fonts.size.xs : props.theme.fonts.size.sm};
      line-height: ${(props) =>
        props.design === 'small' ? props.theme.fonts.size.xs : props.theme.fonts.size.sm};

      &:hover,
      &:active {
        background-color: ${(props: IThemedBadge) =>
          props.disabled || !props.leftAction
            ? disabledColor(props)['hoverBackgroundColor']
            : colorFunction(props)['backgroundColor']};
      }

      &:focus,
      &:focus:not(:focus-visible) {
        background-color: ${(props: IThemedBadge) =>
          !props.disabled && props.leftAction && colorFunction(props)['backgroundColor']};
        outline: ${(props: IThemedBadge) => getOutlineCrossStyle(props, colorFunction)};
      }
    }
  `;
};
