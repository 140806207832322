import { ArrowDownCircle, ArrowUpCircle } from 'react-bootstrap-icons';
import { Button, ButtonGroup, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { DSPWithDepartements, DepartementInfos, IFormNATHDInputs } from '../interfaces';
import DelegantAdjournmentFilterColContainer, {
  DashBoardFiltersStickyContainer,
  NotPaddedBotCardBody
} from './DelegantAdjournmentContainer';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { dspNameList, getDepartementAndDSP } from '../../profiles/profileSlice';
import {
  generateDepartementsSelectList,
  generateDspSelectList,
  generateSegments
} from '../helpers';
import { ocNamesList, typesInterventionList } from './delegantsFiltersInfos';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import ReactSelect from 'react-select';
import { customSelectStyles } from '../../../style/customSelectStyle';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';

const DelegantAdjournmentFilters: React.FC<{
  // eslint-disable-next-line
  updateSegment: (_segments: any) => void;
  setSelectedDepIsAll: (_bool: boolean) => void;
  scrollDir: 'down' | 'up' | 'none';
}> = ({ updateSegment, setSelectedDepIsAll, scrollDir }) => {
  const { profileType, departementAndDSP } = useSelector((state: RootStateOrAny) => state.profile);
  const dspNames = useAppSelector(dspNameList);
  const [currentOC, setCurrentOC] = useState<string[]>(['Tous']);
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [dspSelectList, setDspSelectList] = useState<string[]>(
    generateDspSelectList(dspNames, profileType)
  );
  const [depSelectList, setDepSelectList] = useState<{ label: string; value: string }[]>(
    generateDepartementsSelectList(departementAndDSP, { value: 'Tous', label: 'Tous' })
  );

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const trackEvent = useMatomoHarmonizer();

  useEffect(() => {
    if (!departementAndDSP || departementAndDSP.length === 0) {
      dispatch(getDepartementAndDSP());
    }
  }, []);

  const { watch, control, setValue } = useForm<IFormNATHDInputs>({
    mode: 'onChange',
    defaultValues: {
      dsp: { value: 'Tous', label: 'Tous' },
      departement: { value: 'Tous', label: 'Tous' },
      typeIntervention: {
        value: 'Tous',
        label: 'Tous'
      }
    }
  });
  const currentDsp = watch('dsp');
  const currentDepartement = watch('departement');
  const currentTypeIntervention = watch('typeIntervention');

  useEffect(() => {
    trackEvent({
      page: 'Ajournement',
      category: 'Filtre',
      actionType: 'Select',
      actionDetails: 'DSP'
    });
  }, [currentDsp]);
  useEffect(() => {
    trackEvent({
      page: 'Ajournement',
      category: 'Filtre',
      actionType: 'Select',
      actionDetails: 'Département'
    });
  }, [currentDepartement]);
  useEffect(() => {
    trackEvent({
      page: 'Ajournement',
      category: 'Filtre',
      actionType: 'Select',
      actionDetails: 'Motifs'
    });
  }, [currentTypeIntervention]);
  useEffect(() => {
    trackEvent({
      page: 'Ajournement',
      category: 'Filtre',
      actionType: 'Select',
      actionDetails: 'OC'
    });
  }, [currentOC]);

  const generateAndUpdateSegment = () => {
    if (departementAndDSP.length && depSelectList.length && dspSelectList.length) {
      const segments = generateSegments({
        currentOC,
        currentDsp: departementAndDSP.find(
          (d: DSPWithDepartements) => currentDsp && d.nom_new_portail === currentDsp.value
        ),
        currentDepartement: departementAndDSP[0].departmentsList.find(
          (dep: DepartementInfos) => dep.libelle_departement === currentDepartement.value
        ),
        currentTypeIntervention
      });
      updateSegment(segments);
    }
  };

  useEffect(() => {
    const dspList = generateDspSelectList(dspNames, profileType);
    setDspSelectList(dspList);
    if (dspList.length > 0) {
      setValue('dsp', {
        value: dspList[0],
        label: dspList[0]
      });
    }
  }, [dspNames, profileType]);

  useEffect(() => {
    setSelectedDepIsAll(currentDepartement.label === 'Tous');
    generateAndUpdateSegment();
  }, [currentDepartement]);

  useEffect(() => {
    const depList = generateDepartementsSelectList(departementAndDSP, currentDsp);
    setDepSelectList(depList);
    if (depList.length > 1) {
      if (currentDepartement.label === 'Tous') {
        generateAndUpdateSegment();
      } else {
        setValue('departement', {
          value: 'Tous',
          label: 'Tous'
        });
      }
    } else {
      setValue('departement', {
        value: depList[0].value,
        label: depList[0].label
      });
    }
  }, [currentDsp, departementAndDSP]);

  useEffect(() => {
    let cancel = false;
    if (scrollDir === 'down' && showFilters && !cancel) {
      setShowFilters(false);
    }
    return () => {
      cancel = true;
    };
  }, [scrollDir]);

  const toggleOCFilter = (value: string) => () => {
    if (value === 'Tous') {
      return setCurrentOC(['Tous']);
    }
    const OCRemovedDefaultValue = currentOC.filter((e) => e !== 'Tous');
    const filtered = OCRemovedDefaultValue.filter((e) => e !== value);
    if (filtered.length < OCRemovedDefaultValue.length) {
      if (filtered.length === 0) {
        setCurrentOC(['Tous']);
      } else {
        setCurrentOC(filtered.sort());
      }
    } else {
      const newCurrentOC = [...filtered, value];
      setCurrentOC(newCurrentOC);
    }
  };

  useEffect(() => {
    generateAndUpdateSegment();
  }, [currentOC, currentTypeIntervention]);

  return (
    <DashBoardFiltersStickyContainer className={showFilters ? '' : 'hide'}>
      <NotPaddedBotCardBody className={showFilters ? '' : 'hide'}>
        <Row>
          <DelegantAdjournmentFilterColContainer
            order={1}
            label={t('features.charts.filter.chooseDsp', 'Choose a dsp:')}
          >
            <Controller
              name="dsp"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  aria-label="dsp-select"
                  inputId="dsp"
                  defaultValue={{ label: 'Tous', value: 'Tous' }}
                  options={dspSelectList.map((dsp: string) => {
                    return { value: dsp, label: dsp };
                  })}
                  value={currentDsp}
                  isDisabled={dspSelectList.length === 1}
                  placeholder={t('features.charts.filter.dsp', 'Dsp')}
                  styles={customSelectStyles}
                  noOptionsMessage={() => t('features.charts.filter.noDsp', 'No DSP found')}
                />
              )}
            />
          </DelegantAdjournmentFilterColContainer>
          <DelegantAdjournmentFilterColContainer
            order={3}
            label={t('features.charts.filter.chooseDepartment', 'Choose a department:')}
          >
            <Controller
              name="departement"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  inputId="departement"
                  aria-label="departement-select"
                  options={depSelectList}
                  value={currentDepartement}
                  placeholder={t('features.charts.filter.department', 'Departement')}
                  styles={customSelectStyles}
                  isDisabled={depSelectList.length === 1}
                  noOptionsMessage={() =>
                    t('features.charts.filter.noDepartment', 'No department found')
                  }
                />
              )}
            />
          </DelegantAdjournmentFilterColContainer>
          <DelegantAdjournmentFilterColContainer
            order={2}
            label={t('features.charts.filter.chooseIntervention', 'Choose an intervention type:')}
          >
            <Controller
              name="typeIntervention"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  inputId="type"
                  aria-label="typeIntervention-select"
                  defaultValue={{ label: 'Tous', value: 'Tous' }}
                  options={[
                    {
                      value: 'Tous',
                      label: 'Tous'
                    },
                    ...typesInterventionList.map((ti) => {
                      return { value: ti.name, label: ti.displayName };
                    })
                  ]}
                  value={currentTypeIntervention}
                  placeholder={t('features.charts.filter.interventionType', 'Intervention type')}
                  styles={customSelectStyles}
                  noOptionsMessage={() =>
                    t('features.charts.filter.noIntervention', 'No intervention type found')
                  }
                />
              )}
            />
          </DelegantAdjournmentFilterColContainer>

          <DelegantAdjournmentFilterColContainer
            order={4}
            label={t('features.charts.filter.chooseOc', 'Choose an OC:')}
          >
            <ButtonGroup className="w-100 flex-wrap">
              {[{ name: 'Tous', key: 'Tous' }, ...ocNamesList].map((operator) => {
                return (
                  <Button
                    variant="outline-primary"
                    key={`list-group-years-${operator.key}`}
                    onClick={toggleOCFilter(operator.key)}
                    className={`${
                      currentOC !== null && currentOC.find((e) => e === operator.key) !== undefined
                        ? 'active'
                        : ''
                    }`}
                  >
                    {operator.name}
                  </Button>
                );
              })}
            </ButtonGroup>
          </DelegantAdjournmentFilterColContainer>
        </Row>
      </NotPaddedBotCardBody>
      <Row onClick={() => setShowFilters(!showFilters)} className="clickable p-3">
        {showFilters ? <ArrowUpCircle /> : <ArrowDownCircle size="1.5rem" />}
      </Row>
    </DashBoardFiltersStickyContainer>
  );
};

export default DelegantAdjournmentFilters;
