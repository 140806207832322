import { MouseEventHandler, useEffect, useMemo } from 'react';
import {
  StyledOrderRecapConfirmModalButtonContainer,
  StyledOrderRecapConfirmModalContainer,
  StyledOrderRecapConfirmModalDescription,
  StyledOrderRecapConfirmModalSpinnerContainer,
  StyledOrderRecapConfirmModalTitle
} from '../../../order/orderRecap/confirmModals/OrderRecapConfirmModalStyles';

import Button from '@designSystem/Button';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import Icon from '@designSystem/Icon';
import { useTranslation } from 'react-i18next';
import { i18n } from '@i18n';
import { useMatomoHarmonizer } from '../../../../utils/matomoHarmonizer';
import { useLocation } from 'react-router-dom';

// Ici le i18nDefaultTranslation sert à initialiser et garder les traductions lors de l'extract, sinon le parser ne trouve pas la clé
const convertSaleforceError: {
  [key: string]: { i18nKey: string; i18nDefaultTranslation: string };
} = {
  "code 1001 => 'Pour accéder à cette offre merci de contacter votre interlocuteur commercial Axione'":
    {
      i18nKey: 'features.eligibility.products.orderInfoPanelModal.retry',
      i18nDefaultTranslation: i18n.t(
        'features.eligibility.products.orderInfoPanelModal.retry',
        'You do not have the rights to place your order. Please contact the ADV team.'
      )
    },
  'you cannot pass an order': {
    i18nKey: 'features.eligibility.products.orderInfoPanelModal.cannot',
    i18nDefaultTranslation: i18n.t(
      'features.eligibility.products.orderInfoPanelModal.cannot',
      'You cannot pass an order'
    )
  },
  default: {
    i18nKey: 'features.eligibility.products.orderInfoPanelModal.default',
    i18nDefaultTranslation: i18n.t(
      'features.eligibility.products.orderInfoPanelModal.default',
      'Something went wrong, please retry or select another product to order '
    )
  }
};

const OrderInfoPanelModalError = ({
  closeModal,
  isQuotation,
  error = 'default'
}: {
  closeModal: MouseEventHandler<HTMLButtonElement>;
  isQuotation: boolean;
  error?: string;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const trackEvent = useMatomoHarmonizer();
  const currentPageName = useMemo(() => {
    if (location.pathname === '/ordersBeta') return 'Commandes';
    return 'Eligibilité';
  }, []);
  useEffect(() => {
    const matomoQuotationLabel = isQuotation ? 'projet_étude_échoué' : 'commande_échouée';
    trackEvent({
      page: currentPageName,
      category: 'Choix_produit',
      actionType: 'Vu',
      actionDetails: matomoQuotationLabel
    });
  }, []);
  return (
    <StyledOrderRecapConfirmModalContainer>
      <StyledOrderRecapConfirmModalSpinnerContainer>
        <Icon variant="red" round>
          <ExclamationTriangleFill />
        </Icon>
      </StyledOrderRecapConfirmModalSpinnerContainer>
      <StyledOrderRecapConfirmModalTitle>
        {isQuotation
          ? t(
              'features.eligibility.products.orderInfoPanelModal.quotation',
              'Quotation creation has failed'
            )
          : t(
              'features.eligibility.products.orderInfoPanelModal.order',
              'Order creation has failed'
            )}
      </StyledOrderRecapConfirmModalTitle>
      <StyledOrderRecapConfirmModalDescription>
        {t(convertSaleforceError[error]?.i18nKey ?? convertSaleforceError.default.i18nKey)}
      </StyledOrderRecapConfirmModalDescription>
      <StyledOrderRecapConfirmModalButtonContainer>
        <Button autoFocus design="white" style={{ width: '100%' }} onClick={closeModal}>
          {t('component.button.goBack', 'Go Back')}
        </Button>
      </StyledOrderRecapConfirmModalButtonContainer>
    </StyledOrderRecapConfirmModalContainer>
  );
};

export default OrderInfoPanelModalError;
