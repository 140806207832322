import {
  ClientType,
  IOrderInformationsForm,
  IOrderInformationsOperaBusinessForm
} from '../utils/orderInterfaces';

import { IGeographicAddress } from '../../eligibility/interfaces';
import { formatAddressToString } from '@features/eligibility/eligibilitySlice';
import { faker } from '@faker-js/faker';

const isEnvDev = process.env.NODE_ENV === 'development';
export const constructOrderInformationsDefaultValues = (
  addressInfos?: IGeographicAddress,
  alreadyFilledInformations?: IOrderInformationsForm
) => {
  let defaultValues: IOrderInformationsForm = {
    isSiretMandatory: true,
    clientType: ClientType.COMPANY,
    businessName: isEnvDev ? faker.company.name() : '',
    siret: isEnvDev ? faker.phone.number('##############') : '',
    lastName: isEnvDev ? faker.person.lastName() : '',
    firstName: isEnvDev ? faker.person.firstName() : '',
    phoneContact1: isEnvDev ? faker.phone.number('07########') : '',
    phoneContact2: '',
    email: isEnvDev ? faker.internet.email() : '',
    buildingRef: '',
    address: '',
    building: undefined,
    stair: undefined,
    floor: undefined,
    existingOutlet: false,
    outlet: undefined,
    complementaryOutlet: false,
    internReference: isEnvDev ? `${Date.now()}_${process.env.REACT_APP_GIT_BRANCH}` || '' : '',

    comments: ''
  };

  if (addressInfos) {
    defaultValues = {
      ...defaultValues,
      buildingRef: addressInfos.buildingRef || '',
      address: formatAddressToString(addressInfos)
    };
  }

  if (alreadyFilledInformations) {
    defaultValues = { ...defaultValues, ...alreadyFilledInformations };
  }

  return defaultValues;
};

export const constructOrderOperaBusinessInformationsDefaultValues = (
  addressInfos?: IGeographicAddress,
  alreadyFilledInformations?: IOrderInformationsOperaBusinessForm
): IOrderInformationsOperaBusinessForm => {
  let defaultValues: IOrderInformationsOperaBusinessForm = {
    clientType: ClientType.COMPANY,
    businessName: '',
    siret: '',
    lastName: '',
    firstName: '',
    phoneContact1: '',
    phoneContact2: '',
    email: '',
    buildingRef: '',
    address: '',
    building: undefined,
    stair: undefined,
    floor: undefined,
    existingOutlet: false,
    outlet: undefined,
    complementaryOutlet: false,
    internReference: '',
    comments: '',
    isSiretMandatory: true,
    siretAbsenceReason: undefined,
    networkInterface: '',
    vlan: undefined,
    spanningTreeTransparency: undefined,
    interfaceOptionPlus: '',
    ntePortType: undefined,
    vlanOptionPlus: undefined,
    cVlanOptionPlus: undefined,
    projectName: '',
    siren: ''
  };

  if (addressInfos) {
    defaultValues = {
      ...defaultValues,
      buildingRef: addressInfos.buildingRef || '',
      address: formatAddressToString(addressInfos)
    };
  }

  if (alreadyFilledInformations) {
    defaultValues = { ...defaultValues, ...alreadyFilledInformations };
  }

  return defaultValues;
};

export function isIOrderInformationsOperaBusinessForm(
  value: IOrderInformationsOperaBusinessForm | IOrderInformationsForm
): value is IOrderInformationsOperaBusinessForm {
  // Remplacez 'proprieteSpecifique' par une propriété qui existe uniquement dans IOrderInformationsOperaBusinessForm
  return 'projectName' in value;
}
