import * as AppointmentApiService from './appointmentAPI';

import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Appointment, AppointmentApi, AppointmentSlot, Calendar } from '../utils/interfaces';

import { displayToastErrorWithMessage } from '../../../utils/toastHelper';
import { i18n } from '@i18n';

interface AppointmentState {
  timeSlots: Appointment[];
  timeSlotsLoading: boolean;
  bookedAppointment: any | undefined;
  bookAppointmentLoading: boolean;
  bookAppointmentError: string | undefined;
  calendar: Calendar | undefined;
  calendarSearchLoading: boolean;
  calendarSearchError: string | undefined;
  calendarSchedules: AppointmentSlot[];
  calendarSchedulesLoading: boolean;
  calendarSchedulesError: string | undefined;
  appointmentsListLoading: boolean;
  appointmentsListError: string | undefined;
  appointmentsList: AppointmentApi[];
  appointmentsListTotalCount: number | null;
}

const initialState: AppointmentState = {
  timeSlots: [],
  timeSlotsLoading: false,
  bookedAppointment: undefined,
  bookAppointmentLoading: false,
  bookAppointmentError: undefined,
  calendar: {} as Calendar,
  calendarSearchLoading: false,
  calendarSearchError: undefined,
  calendarSchedules: [],
  calendarSchedulesLoading: false,
  calendarSchedulesError: undefined,
  appointmentsListLoading: false,
  appointmentsListError: undefined,
  appointmentsList: [],
  appointmentsListTotalCount: null
};

export interface SortAppointment {
  order_by: string;
  order_ascending: boolean;
}

export const calendarSearch = createAsyncThunk(
  'calendar/search',
  async (
    params: {
      reference: string;
      country: string;
      type_operation: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await AppointmentApiService.calendarSearch(params);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const getCalendarSchedules = createAsyncThunk(
  'calendar/schedules',
  async (
    params: {
      idCalendar: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await AppointmentApiService.getCalendarSchedules(params);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const loadTimeSlots = createAsyncThunk(
  'appointment/getTimeSlots',
  async (
    params: { id_calendar: number; start_day: string; end_day: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await AppointmentApiService.getTimeSlots({
        start_day: params.start_day,
        end_day: params.end_day,
        id_calendar: params.id_calendar
      });
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const bookAppointment = createAsyncThunk(
  'appointment/book',
  async (param: { slotId: number; fai: string | null }, { rejectWithValue }) => {
    try {
      const response = await AppointmentApiService.bookAppointment({
        id_slot_date: param.slotId,
        fai: param.fai,
        subscriber_name: 'string'
      });
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const cancelAppointment = createAsyncThunk(
  'appointment/cancel',
  async (param: { id: number; reason: string }, { rejectWithValue }) => {
    try {
      const response = await AppointmentApiService.cancelAppointment(param.id, param.reason);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const postponeAppointment = createAsyncThunk(
  'appointment/postpone',
  async (param: { id: number; slotId: number }, { rejectWithValue }) => {
    try {
      const response = await AppointmentApiService.postponeAppointment(param.id, param.slotId);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const getAppointmentsList = createAsyncThunk(
  'appointment/list',
  async (
    arg: { currentPage: number; sort: SortAppointment; search?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await AppointmentApiService.getAppointments(arg);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setCalendarId: (state, action) => {
      state.calendar = action.payload;
    },
    resetAppointmentData: (state) => {
      state.bookedAppointment = undefined;
      state.bookAppointmentError = undefined;
    },
    resetCalendar: (state) => {
      state.calendar = {} as Calendar;
      state.calendarSearchLoading = false;
      state.calendarSearchError = undefined;
      state.calendarSchedules = [];
      state.calendarSchedulesLoading = false;
      state.calendarSchedulesError = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTimeSlots.pending, (state) => {
        state.timeSlotsLoading = true;
      })
      .addCase(loadTimeSlots.fulfilled, (state, { payload }) => {
        state.timeSlots = payload;
        state.timeSlotsLoading = false;
      })
      .addCase(bookAppointment.pending, (state) => {
        state.bookAppointmentLoading = true;
        state.bookAppointmentError = undefined;
      })
      .addCase(bookAppointment.fulfilled, (state, { payload }) => {
        state.bookAppointmentLoading = false;
        state.bookedAppointment = payload;
      })
      .addCase(bookAppointment.rejected, (state, action: AnyAction) => {
        state.bookAppointmentLoading = false;
        if (action.payload && action.payload.message) {
          state.bookAppointmentError = action.payload.message;
        } else {
          state.bookAppointmentError = action.error.message;
        }
      })
      .addCase(getAppointmentsList.pending, (state) => {
        state.appointmentsListLoading = true;
        state.appointmentsListError = undefined;
      })
      .addCase(getAppointmentsList.fulfilled, (state, action: AnyAction) => {
        state.appointmentsListLoading = false;
        state.appointmentsList = action.payload.appointments;
        state.appointmentsListTotalCount = action.payload.total_count;
      })
      .addCase(getAppointmentsList.rejected, (state, action: AnyAction) => {
        state.appointmentsListLoading = false;
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          i18n.t(`errorMessages.errorHappen`, 'An error happened', {
            defaultValue: '{{errorMessage}}',
            value: errorMessage
          })
        );
      })
      .addCase(calendarSearch.pending, (state) => {
        state.calendarSearchLoading = true;
        state.calendarSearchError = undefined;
      })
      .addCase(calendarSearch.fulfilled, (state, action: AnyAction) => {
        state.calendar = { ...action.payload.calendar, fai: action.payload.fai };
        state.calendarSearchLoading = false;
      })
      .addCase(calendarSearch.rejected, (state, action: AnyAction) => {
        state.calendarSearchLoading = false;
        if (action.payload && action.payload.message) {
          state.calendarSearchError = action.payload.message;
        } else {
          state.calendarSearchError = action.error.message;
        }
      })
      .addCase(getCalendarSchedules.pending, (state) => {
        state.calendarSchedulesLoading = true;
        state.calendarSchedulesError = undefined;
      })
      .addCase(getCalendarSchedules.fulfilled, (state, action: AnyAction) => {
        state.calendarSchedules = action.payload;
        state.calendarSchedulesLoading = false;
      })
      .addCase(getCalendarSchedules.rejected, (state, action: AnyAction) => {
        state.calendarSchedulesLoading = false;
        if (action.payload && action.payload.message) {
          state.calendarSchedulesError = action.payload.message;
        } else {
          state.calendarSchedulesError = action.error.message;
        }
      });
  }
});

export const { resetAppointmentData, resetCalendar, setCalendarId } = appointmentSlice.actions;

export default appointmentSlice.reducer;
