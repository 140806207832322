import { Container } from 'react-bootstrap';
import MakeAppointmentBody from '@features/appointment/MakeAppointmentBody';
import React from 'react';

const MakeAppointment: React.FC = () => {
  return (
    <Container fluid className="main-content-container pt-4">
      <MakeAppointmentBody />
    </Container>
  );
};

export default MakeAppointment;
