import { IThemedComponent } from '../designInterfaces';
import { css } from 'styled-components';

interface IThemedDropdown extends IThemedComponent {
  reversed?: boolean;
  disabled?: boolean;
}

export const defaultStyle = () => {
  return css`
    display: flex;
    font-family: ${(props) => props.theme.fonts.primary};
    font-weight: ${(props) => props.theme.fonts.weight.medium};
    font-size: ${(props) => props.theme.fonts.size.sm};
    line-height: ${(props) => props.theme.fonts.spacing.sm};
    color: ${(props) => props.theme.palette['grey'][800]};
    pointer-events: ${(props: IThemedDropdown) => (props.disabled ? 'none' : 'auto')};
    opacity: ${(props: IThemedDropdown) => (props.disabled ? '0.4' : '1')};

    .dropdown {
      position: relative;
      border-radius: 6px;
      background-color: ${(props: IThemedDropdown) =>
        props.reversed ? props.theme.palette.blue[50] : 'white'};

      &.active {
        .dropdown_text {
          background: white;
          border-color: white;
          outline: 2px solid ${(props: IThemedComponent) => props.theme.palette[props.variant][300]};

          &:after {
            top: calc(50% - 5px);
            transform: rotate(-135deg);
            -webkit-transform: rotate(45deg);
          }
        }
        .dropdown_items {
          visibility: visible;
          height: auto;
          min-width: 100%;
          opacity: 1;
          transition: opacity 1s, visibility 1s ease;
          background: white;
          border-radius: 0 0 6px 6px;
          box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
          z-index: 3;
        }
      }
    }

    .dropdown_text {
      color: ${(props) => props.theme.palette['grey'][800]};
      padding: 9px 38px 9px 13px;
      display: inline-block;
      min-height: 1rem;
      border-radius: 6px;
      border: 1px solid
        ${(props: IThemedDropdown) => {
          return props.reversed ? 'white' : props.theme.palette.grey[400];
        }};

      cursor: pointer;
      height: auto;
      transition: all 0.3s;

      &:hover {
        color: ${(props) => props.theme.palette['grey'][800]};
        background: ${(props: IThemedComponent) => props.theme.palette[props.variant][50]};
      }

      &:after {
        content: '';
        transition: all 0.3s;
        border: solid ${(props) => props.theme.palette['grey'][400]};
        border-width: 0 1px 1px 0;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(-135deg);
        position: absolute;
        right: 16px;
        top: calc(50% - 2px);
      }
    }

    .dropdown_items {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      border-bottom: 1px solid
        ${(props: IThemedComponent) => props.theme.palette[props.variant][50]};

      .dropdown_item {
        cursor: pointer;
        padding: 10px 18px;
        display: flex;
        gap: 0.5rem;
        align-items: center;

        &.selected {
          color: ${(props: IThemedComponent) => props.theme.palette['grey'][500]};
        }

        &:last-child {
          border-radius: 0 0 6px 6px;
          border-bottom: none;
        }

        &:hover {
          background: ${(props: IThemedComponent) => props.theme.palette[props.variant][50]};
        }
      }
    }
  `;
};
