import { IZoneGeoRef, IZoneGeoRefFromApi, IZoneGeoRefPageData } from './interfaces';
import React, { useEffect, useState } from 'react';
import { deleteReference, fetchReferencesList } from './reducer/referencesSlice';

import Button from '@designSystem/Button';
import DeleteModal from '../../components/DeleteModal';
import PageHeader from '../../components/PageHeader/PageHeader';
import { ThemeProps } from '@theme';
import ZoneGeoRefTable from './referencesListTable/ZoneGeoRefTable';
import ZoneGeoReferenceForm from './zoneGeoReferenceForm/ZoneGeoReferenceForm';
import { referencesColumns } from './referencesListTable/Columns';
import styled from 'styled-components';
import { useAppDispatch } from '../../app/hooks';
import { useTranslation } from 'react-i18next';

const DeleteContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.875rem;
  border-radius: 6px;
  background-color: ${(props: { theme: ThemeProps }) => props.theme.palette['grey'][50]};

  font-family: ${(props) => props.theme.fonts.primary};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  font-size: ${(props) => props.theme.fonts.size.sm};
  line-height: ${(props) => props.theme.fonts.spacing.sm};
  color: ${(props) => props.theme.palette['grey'][500]};

  p {
    font-family: ${(props) => props.theme.fonts.primary};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    font-size: ${(props) => props.theme.fonts.size.sm};
    line-height: ${(props) => props.theme.fonts.spacing.sm};
    color: ${(props) => props.theme.palette['grey'][500]};
    margin: 0.5rem 0;
  }
`;

const ZoneGeoRefBody: React.FC<{ zoneId: string }> = ({ zoneId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [pageData, setPageData] = useState<IZoneGeoRefPageData>({
    rowData: [],
    totalCount: 0
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [currentFilterAndSort, setCurrentFilterAndSort] = useState<{
    search?: string;
    currentPage: number;
  }>({
    currentPage: 0,
    search: ''
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [refToDelete, setRefToDelete] = useState<IZoneGeoRef | undefined>();
  const [refToUpdate, setRefToUpdate] = useState<IZoneGeoRef | undefined>();

  const dispatchGetReferencesList = () => {
    dispatch(
      fetchReferencesList({
        zoneId: zoneId,
        currentPage: currentFilterAndSort.currentPage,
        search: currentFilterAndSort.search
      })
    ).then((res) => {
      if (res?.payload) {
        setPageData({
          rowData: res.payload.sites as IZoneGeoRefFromApi[],
          totalCount: res.payload.total_count
        });
      }
    });
  };

  useEffect(() => {
    dispatchGetReferencesList();
  }, [currentFilterAndSort]);

  useEffect(() => {
    if (currentFilterAndSort.currentPage !== currentPage) {
      setCurrentFilterAndSort({ ...currentFilterAndSort, currentPage });
    }
  }, [currentPage]);

  const deleteReferenceById = () => {
    if (refToDelete) {
      setDeleteLoading(true);
      dispatch(deleteReference({ zoneId: zoneId, id: refToDelete.id })).then(() => {
        setRefToDelete(undefined);
        setDeleteLoading(false);
        dispatchGetReferencesList();
      });
    }
  };

  const columns = referencesColumns(setRefToUpdate, setRefToDelete);

  const updateDataFromSearchInput = (newFilter: { search?: string }) => {
    setCurrentFilterAndSort({ ...newFilter, currentPage: 0 });
    setCurrentPage(0);
  };

  return (
    <>
      <PageHeader
        updateData={updateDataFromSearchInput}
        currentFilterAndSort={currentFilterAndSort}
        totalRows={pageData.totalCount}
        totalRowsTitle={t(
          'features.zonesGeo.geographicZoneReferences',
          'geographic zones references'
        )}
        rightContent={
          <Button onClick={() => setRefToUpdate({ id_zonegeo: `${zoneId}` } as IZoneGeoRef)}>
            {t('features.zonesGeo.newReferences', 'New reference')}
          </Button>
        }
      />
      <ZoneGeoRefTable
        columns={columns}
        pageData={pageData}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      <DeleteModal
        modalId={refToDelete ? refToDelete.id : false}
        backAction={() => setRefToDelete(undefined)}
        deleteAction={deleteReferenceById}
        loading={deleteLoading}
        title={t('features.zonesGeo.deletingReference', 'Deleting a reference')}
        description={t(
          'features.zonesGeo.byClickingOnDelete',
          `By clicking on "delete", the reference will be permanently deleted`
        )}
      >
        {refToDelete && (
          <DeleteContent>
            {t('features.zonesGeo.referenceTypology', 'Reference typology')}
            <p>{refToDelete.ref_type_label}</p>
            {t('features.zonesGeo.referenceName', 'Reference name')}
            <p>{refToDelete.reference}</p>
          </DeleteContent>
        )}
      </DeleteModal>
      <ZoneGeoReferenceForm
        currentRef={refToUpdate}
        onSuccess={() => dispatchGetReferencesList()}
        closeFn={() => setRefToUpdate(undefined)}
      />
    </>
  );
};

export default ZoneGeoRefBody;
