import AddressCard from '../../../eligibility/select/AddressCard';
import Badge from '@designSystem/Badges';
import { StyledOrderSection } from '../../utils/OrderStyles';
import { useTranslation } from 'react-i18next';

const OrderRecapAddressSection = ({
  editable,
  productDSP
}: {
  editable: boolean;
  productDSP: string | undefined;
}) => {
  const { t } = useTranslation();

  const displayAddressCardBadge = () => {
    if (productDSP) {
      return (
        <Badge variant="blue" style={{ marginBottom: '0.5rem', textTransform: 'uppercase' }}>
          {productDSP}
        </Badge>
      );
    }
    return null;
  };

  return (
    <StyledOrderSection style={{ marginTop: editable ? '24px' : '0' }}>
      <div className="section-title">
        {t('features.order.orderRecap.orderRecapSections.headAddress', 'Selected address')}
      </div>
      <AddressCard shouldDisplayEdit={false} badge={displayAddressCardBadge()} />
    </StyledOrderSection>
  );
};

export default OrderRecapAddressSection;
