import { Marker, Popup } from 'react-leaflet';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';

import Leaflet from 'leaflet';
import { LeafletMarker } from '../interfaces';
import styled from 'styled-components';

interface LeafletMarkerComponentProps {
  marker: LeafletMarker;
  index: number;
  getMarkerTextInfos: () => LeafletMarker;
  setCurrentMarker: Dispatch<SetStateAction<number | undefined>>;
  openPopup: boolean;
}

const UnderlinedText = styled.span`
  text-decoration: underline;
`;

const LeafletMarkerComponent: React.FC<LeafletMarkerComponentProps> = ({
  marker,
  index,
  getMarkerTextInfos,
  setCurrentMarker,
  openPopup
}) => {
  const markerRef = useRef(null) as { current: any } | null;

  useEffect(() => {
    if (openPopup && markerRef && markerRef.current) {
      markerRef.current.openPopup();
    }
  }, [openPopup]);

  const leafletIcon = (m: LeafletMarker) => {
    return new Leaflet.DivIcon({ className: m.className });
  };

  // We use memo to prevent rendering all markers !
  const displayMarker = useCallback(() => {
    return (
      <Marker
        ref={markerRef}
        icon={leafletIcon(marker)}
        position={[marker.coordinates[0], marker.coordinates[1]]}
        eventHandlers={{
          click: () => {
            setCurrentMarker(index);
          }
        }}
      >
        <Popup>
          {openPopup &&
            getMarkerTextInfos().text.map((t, i) => {
              if (t.rightText) {
                return (
                  <div key={`marker-${i}`}>
                    <UnderlinedText>{t.leftText}:</UnderlinedText> {t.rightText}
                    <br />
                  </div>
                );
              }
              return null;
            })}
        </Popup>
      </Marker>
    );
  }, [openPopup, marker]);

  return displayMarker();
};

export default LeafletMarkerComponent;
