import { TFunction } from 'i18next';
import Modal from '@designSystem/Modal';
import Button from '@designSystem/Button';
import Icon from '@designSystem/Icon';
import theme from '@theme';
import { LightningFill } from 'react-bootstrap-icons';
import styled from 'styled-components';
import React from 'react';

export const ModalSpanningTreeTransparencyError = (props: {
  isOpen: boolean;
  goBack: () => void;
  close: () => void;
  t: TFunction<'translation', undefined, 'translation'>;
}) => {
  return (
    <Modal
      show={props.isOpen}
      onClose={props.close}
      variant="blue"
      footer={
        <div>
          <Button design="white" onClick={props.goBack}>
            {props.t('component.button.back')}
          </Button>
          <Button onClick={props.close}>{props.t('component.button.understand')}</Button>
        </div>
      }
    >
      <ModalSpanningTreeTransparencyErrorStyled>
        <Icon round iconSize="48px" variant="orange" fillColor={theme.palette.blue[500]}>
          <LightningFill style={{ fill: theme.palette.orange[600] }} size={20} />
        </Icon>
        <div className="title">
          {props.t(
            'features.order.orderInformations.technicalCharacteristics.popupTitleSpanningTreeError'
          )}
        </div>
        <div className="body">
          {props.t(
            'features.order.orderInformations.technicalCharacteristics.popupSpanningTreeWithOptionBronzeError',
            'The « Sécurisation bronze » option is not compatible and cannot be subscribed to with transparency for Spanning Tree protocols because it is redundant. It will be deactivated from your order.'
          )}
        </div>
      </ModalSpanningTreeTransparencyErrorStyled>
    </Modal>
  );
};

const ModalSpanningTreeTransparencyErrorStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .title {
    color: ${theme.palette.grey[900]};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .body {
    color: ${theme.palette.grey[500]};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;
