import React, { useState } from 'react';

import Button from '@designSystem/Button';
import Checkbox from '../../CheckBox';
import { DateRange } from 'react-day-picker';
import { ITab } from './TabHeader';
import Modal from '@designSystem/Modal';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';

interface IModalWithTabsProps {
  isOpenModal: boolean;
  tabs: ITab[];
  setTabs: React.Dispatch<React.SetStateAction<ITab[]>>;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTabsInModal: React.Dispatch<React.SetStateAction<ITab[]>>;
  tabsInModal: ITab[];
  handleCombinedChange: (search: string, range?: DateRange, state?: ITab['state']) => void;
  getValues: () => string;
  range: DateRange | undefined;
  setTabSelected: React.Dispatch<React.SetStateAction<ITab>>;
}
export const ModalWithTabs: React.FC<IModalWithTabsProps> = ({
  isOpenModal,
  tabs,
  setIsOpenModal,
  setTabs,
  range,
  getValues,
  handleCombinedChange,
  setTabSelected
}) => {
  const [tabsChecked, setTabsChecked] = useState<ITab[] | undefined>(undefined);
  const trackEvent = useMatomoHarmonizer();

  const formatTab = (tab: ITab) => {
    return {
      ...tab,
      inTheModal: false,
      canBeDeleted: true
    };
  };

  const tabsInModal = tabs.filter(({ inTheModal }) => inTheModal);

  const getNbrColumnByTabs = (tabs: ITab[]): 2 | 3 | 4 => {
    const tabsLength = tabs.length;

    if (tabsLength <= 8) return 2;
    if (tabsLength <= 12) return 3;
    return 4;
  };

  const updateTabsChecked = (tabs: typeof tabsChecked, tab: ITab): ITab[] => {
    if (!tabs?.length) {
      return [tab];
    }

    const hasMatchingTab = tabs.find(({ state }) => state === tab.state);
    return hasMatchingTab ? tabs.filter(({ state }) => state !== tab.state) : [...tabs, tab];
  };

  const handleRegistrationClick = () => {
    if (tabsChecked?.length) {
      const tabsSelectedWithInsertTabsFormated = tabsChecked.map(formatTab);
      const tabsDefaultWithoutTabsSelected = tabs.filter((tab) => !tabsChecked.includes(tab));
      setTabs([...tabsDefaultWithoutTabsSelected, ...tabsSelectedWithInsertTabsFormated]);
      setTabSelected(tabsChecked[0]);
      handleCombinedChange(getValues(), range, tabsChecked[0].state);
      setTabsChecked(undefined);
      setIsOpenModal(false);
      trackEvent({
        page: 'Mes_Commandes',
        category: 'Modal_Filtre',
        actionType: 'Select',
        actionDetails: `Filtre_Statut_${tabsChecked.map((tab) => tab.name).join('_')}`
      });
    }
  };

  const handleCancelClick = () => {
    setTabsChecked(undefined);
    setIsOpenModal(false);
  };
  const { t } = useTranslation();

  return (
    <Modal
      show={isOpenModal}
      footer={
        <div>
          <Button design="white" onClick={handleCancelClick}>
            {t`component.button.cancel`}
          </Button>
          <Button disabled={!tabsChecked?.length} onClick={handleRegistrationClick}>
            {t`component.button.register`}
          </Button>
        </div>
      }
    >
      <ModalWithTabsContainer
        style={{
          display: 'grid',
          gridGap: 20,
          gridTemplateColumns: `repeat(${getNbrColumnByTabs(tabsInModal)}, auto)`
        }}
      >
        {tabsInModal.map((tab: ITab) => (
          <div style={{ display: 'flex', alignItems: 'center' }} key={tab.state}>
            <Checkbox
              className="checkbox"
              style={{ display: 'flex', alignItems: 'center' }}
              label={tab.i18nKey ? t(tab.i18nKey) : tab.name}
            >
              <input
                className="animated-checkbox"
                type="checkbox"
                onChange={() => setTabsChecked(updateTabsChecked(tabsChecked, tab))}
              />
            </Checkbox>
          </div>
        ))}
      </ModalWithTabsContainer>
    </Modal>
  );
};

const ModalWithTabsContainer = styled.div`
  & > .checkbox {
    color: ${theme.palette.grey[700]};
    font-size: ${theme.fonts.size.xs};
    line-height: ${theme.fonts.spacing.xs};
  }
`;
