import Button from '@designSystem/Button';
import React from 'react';
import styled from 'styled-components';
import Modal from '@designSystem/Modal';
import { TrashFill } from 'react-bootstrap-icons';
import ModalContentWithIcon from '@designSystem/Modal/ModalContentWithIcon';
import { useTranslation } from 'react-i18next';

interface ModalDeleteOrderProps {
  show: boolean;
  onClose: () => void;
  deleteOrder: () => Promise<void>;
}
export const ModalDeleteOrder: React.FC<ModalDeleteOrderProps> = ({
  show,
  onClose,
  deleteOrder
}: ModalDeleteOrderProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      width="528px"
      show={show}
      onClose={onClose}
      {...{
        children: (
          <ContentModalDeleteOrderStyled>
            <ModalContentWithIcon
              variant="red"
              title={t(
                'features.order.orderList.wantToDelete',
                'Do you want to delete this draft ?'
              )}
              description={t(
                'features.order.orderList.irreversible',
                'This action is irreversible, you will not be able to access this draft.'
              )}
            >
              <TrashFill />
            </ModalContentWithIcon>
            <div className="buttons-container">
              <Button design="reverse" variant="red" onClick={onClose}>
                Annuler
              </Button>
              <Button variant="red" onClick={deleteOrder}>
                Confirmer
              </Button>
            </div>
          </ContentModalDeleteOrderStyled>
        )
      }}
    />
  );
};

const ContentModalDeleteOrderStyled = styled.div`
  .modal_content_icon {
    margin: 0 0 20px;
  }
  .buttons-container {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: auto auto;
    margin-top: 24px;
    height: 38px;
  }
`;
