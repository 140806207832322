import { ExclamationCircleFill, InfoCircleFill } from 'react-bootstrap-icons';

import Button from '@designSystem/Button';
import InformationBlock from '@designSystem/InformationBlock';
import Modal from '@designSystem/Modal';
import ModalContentWithIcon from '@designSystem/Modal/ModalContentWithIcon';
import React from 'react';
import { StyledModalConfirmPTOContent } from '../utils/OrderStyles';
import { useTranslation } from 'react-i18next';

const OrderInformationsModalConfirmPTO: React.FC<{
  handleConfirm: CallableFunction;
  isNewInstallation: boolean;
  isVisible: boolean;
  refPTO?: string;
  handleClose?: () => void;
}> = ({ handleClose, handleConfirm, isVisible, refPTO, isNewInstallation }) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={isVisible}
      onClose={handleClose}
      width="550px"
      footer={
        <div>
          <Button
            design="white"
            autoFocus
            style={{ width: '100%' }}
            onClick={() => {
              handleClose && handleClose();
            }}
          >
            {t('component.button.cancel', 'Cancel')}
          </Button>
          <Button style={{ width: '100%' }} onClick={() => handleConfirm()}>
            {t('component.button.confirm', 'Confirm')}
          </Button>
        </div>
      }
    >
      <>
        <ModalContentWithIcon
          style={{ paddingBottom: 0 }}
          title={t(
            'features.order.orderInformations.confirmReference',
            'Do you confirm the selected reference ?'
          )}
        >
          <InfoCircleFill color="blue" />
        </ModalContentWithIcon>
        <StyledModalConfirmPTOContent>{refPTO}</StyledModalConfirmPTOContent>
        {!isNewInstallation && (
          <InformationBlock
            title={t(
              'features.order.orderInformations.carefulService',
              'Be careful if there is an active service on the selected outlet, it will be erased when connecting. '
            )}
            type="warning"
            customIcon={<ExclamationCircleFill />}
          />
        )}
      </>
    </Modal>
  );
};

export default OrderInformationsModalConfirmPTO;
