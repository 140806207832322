import { RootStateOrAny, useSelector } from 'react-redux';

import { ArrowLeftCircle } from 'react-bootstrap-icons';
import { Calendar } from '../../appointment/utils/interfaces';
import { Column } from 'react-table';
import FullTable from '../../../components/FullTable';
import Pagination from '@designSystem/Pagination';
import React from 'react';
import TableContainerWithBorderMaxWidth from '../../../components/TableContainerWithBorderMaxWidth';
import { useNavigate } from 'react-router-dom';

const CalendarTable: React.FC<{
  columns: Column<Calendar>[];
  setCurrentPage: (_currentPage: number) => void;
  currentPage: number;
}> = ({ columns, setCurrentPage, currentPage }) => {
  const navigate = useNavigate();

  const { calendarsListLoading, calendarsList, calendarsTotalCount } = useSelector(
    (state: RootStateOrAny) => state.calendars
  );

  const returnOnCalendarForm = () => {
    navigate('/erdv');
  };

  return (
    <>
      <h3 onClick={() => returnOnCalendarForm()} className="clickable">
        <ArrowLeftCircle />
      </h3>
      <TableContainerWithBorderMaxWidth className="table-max-width-margin table-border-top">
        <>
          <FullTable columns={columns} data={calendarsList} isLoading={calendarsListLoading} />
          <Pagination
            totalRows={calendarsTotalCount}
            pageChangeHandler={setCurrentPage}
            parentCurrentPage={currentPage}
          />
        </>
      </TableContainerWithBorderMaxWidth>
    </>
  );
};

export default CalendarTable;
