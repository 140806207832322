import React from 'react';
import { listOfProductDuration, periodTranslations } from '../constants';

import { ProductPrice } from '../../interfaces';
import { StyledOptions } from './OptionsForm';
import Switch from '@designSystem/SwitchInput';
import { ThemeProps } from '@theme';
import { addSelectedOptionsToOrder } from '@features/order/reducer/orderSlice';
import { useAppDispatch } from '../../../../app/hooks';
import { extractMonths } from './modal/productBusinessDetailModal';

const OptionsDurationForm: React.FC<{
  durationToOrder?: string;
  durationList?: ProductPrice[];
  setDuration: CallableFunction;
  theme?: ThemeProps;
  editable?: boolean;
}> = ({ durationToOrder, durationList, setDuration, editable = true }) => {
  const dispatch = useAppDispatch();
  const notClickable = !editable ? 'not-clickable' : '';
  return (
    <>
      <StyledOptions>
        {durationList?.map((duration, index) => {
          return (
            <div key={index}>
              <Switch
                className={`${notClickable}`}
                inputLabel={
                  <p className={`${notClickable}`}>{listOfProductDuration[duration.name]}</p>
                }
              >
                <input
                  key={index}
                  style={!editable ? { cursor: 'not-allowed' } : {}}
                  onChange={() => {
                    if (editable) {
                      dispatch(
                        addSelectedOptionsToOrder({ duration: extractMonths(duration.name) })
                      );
                      setDuration(extractMonths(duration.name));
                    }
                  }}
                  type="checkbox"
                  checked={durationToOrder === extractMonths(duration.name)}
                />
              </Switch>
              <strong>
                {duration.price?.dutyFreeAmount?.value} €
                {duration.recurringChargePeriod &&
                  '/' + periodTranslations[duration.recurringChargePeriod || 'default']}
              </strong>
            </div>
          );
        })}
      </StyledOptions>
    </>
  );
};

export default OptionsDurationForm;
