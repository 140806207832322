import React, { useState } from 'react';

import Button from '@designSystem/Button';
import { DayPicker } from 'react-day-picker';
import OrderAppointmentCaption from '../orderAppointment/OrderAppointmentForm/OrderAppointmentCaption';
import PopOver from '@designSystem/PopOver';
import { orderAppointmentDatePickerStyle } from '../components/OrderAppointmentPopOver';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface OrderAppointmentFormPopOverProps {
  setIsPopOverShown: CallableFunction;
  selectedDate?: Date;
  setSelectedDate: CallableFunction;
}

const OrderAppointmentPopOver: React.FC<OrderAppointmentFormPopOverProps> = ({
  setIsPopOverShown,
  selectedDate,
  setSelectedDate
}: OrderAppointmentFormPopOverProps) => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const { t } = useTranslation();

  const onMonthChange = (m: Date) => {
    setCurrentMonth(m);
  };

  const isDayDisabled = (day: Date) => {
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(startDate.getDate() + 7);

    const DAY_OF_WEEK_SUNDAY = 0;
    const DAY_OF_WEEK_SATURDAY = 6;
    const isWeekend = day.getDay() === DAY_OF_WEEK_SUNDAY || day.getDay() === DAY_OF_WEEK_SATURDAY;

    return day < startDate || isWeekend;
  };
  const handleSelectDate = (d: Date | undefined) => {
    setSelectedDate(d);
    setIsPopOverShown(false); // Ferme la popup après la sélection de la date
  };

  return (
    <PopOver vertical="bottom" horizontal="center">
      <style>{orderAppointmentDatePickerStyle}</style>
      <DayPicker
        fixedWeeks
        mode="single"
        selected={selectedDate}
        onSelect={(d: Date | undefined) => handleSelectDate(d)}
        components={{
          Caption: OrderAppointmentCaption((d: Date) => onMonthChange(d))
        }}
        modifiersClassNames={{
          selected: 'day-selected',
          today: 'day-today'
        }}
        month={currentMonth}
        onMonthChange={(d: Date) => onMonthChange(d)}
        numberOfMonths={1}
        fromMonth={new Date()}
        disabled={isDayDisabled}
      />
      <StyledPopOverFooter>
        <Button
          design="white"
          onClick={() => setIsPopOverShown(false)}
          className="popover_footer_button"
        >
          {t('component.button.close', 'Close')}
        </Button>
      </StyledPopOverFooter>
    </PopOver>
  );
};

export default OrderAppointmentPopOver;

const StyledPopOverFooter = styled.div`
  display: flex;

  .popover_footer_button {
    flex: 1 1;
  }

  .popover_footer_button_marged {
    margin-left: 10px;
  }
`;
