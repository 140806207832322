import {
  IThemedInformationBlockType,
  StyledInformationBlockContainer,
  StyledInformationBlockCross,
  StyledInformationBlockDescription,
  StyledInformationBlockTextContainer,
  StyledInformationBlockTitle,
  informationBlockDefaultIcons,
  informationBlockDefaultVariants
} from './informationBlockDesignHelper';
import React, { ReactChild, useEffect, useState } from 'react';

import { ThemeVariants } from '../../style/theme';
import { X } from 'react-bootstrap-icons';

interface InformationBlockProps extends Omit<React.HTMLProps<HTMLElement>, 'title'> {
  type?: IThemedInformationBlockType;
  backgroundVariant?: ThemeVariants;
  textVariant?: ThemeVariants;
  iconVariant?: ThemeVariants;
  customIcon?: ReactChild;
  title?: string | ReactChild;
  canClose?: boolean;
  onClose?: CallableFunction;
  footerCTADetails?: ReactChild | null;
}

const InformationBlock: React.FC<InformationBlockProps> = ({
  type = 'default' as any,
  canClose,
  onClose,
  iconVariant,
  customIcon,
  title,
  textVariant,
  backgroundVariant,
  footerCTADetails = null,
  ref,
  as,
  ...props
}) => {
  const [isReduced, setIsReduced] = useState(false);

  useEffect(() => {
    setIsReduced(false);
  }, [type, canClose, title, iconVariant]);

  const showBlockIfReduced = () => {
    if (isReduced) {
      setIsReduced(false);
    }
  };

  let customIconVariant = informationBlockDefaultVariants(type);
  if (iconVariant !== undefined) {
    customIconVariant = iconVariant;
  }
  return (
    <StyledInformationBlockContainer
      type={type}
      backgroundVariant={backgroundVariant}
      textVariant={textVariant}
      isReduced={isReduced}
      {...props}
    >
      {informationBlockDefaultIcons(
        type,
        customIconVariant,
        showBlockIfReduced,
        isReduced,
        customIcon
      )}
      {!isReduced && (
        <StyledInformationBlockTextContainer type={type}>
          <StyledInformationBlockTitle type={type} textVariant={textVariant}>
            {title}
          </StyledInformationBlockTitle>
          {props.children && (
            <StyledInformationBlockDescription type={type} textVariant={textVariant}>
              {props.children}
            </StyledInformationBlockDescription>
          )}

          {footerCTADetails}
        </StyledInformationBlockTextContainer>
      )}
      {canClose && !isReduced && (
        <StyledInformationBlockCross
          type={type}
          className="clickable"
          variant={customIconVariant}
          size="minimal"
          variantDeclination={400}
          onClick={() => {
            onClose && onClose();
            setIsReduced(true);
          }}
        >
          <X />
        </StyledInformationBlockCross>
      )}
    </StyledInformationBlockContainer>
  );
};

export default InformationBlock;
