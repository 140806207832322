import { Card, Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { IGoogleAddressPrediction, LeafletMarker } from '../interfaces';
import React, { useEffect, useState } from 'react';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { LatLngTuple } from 'leaflet';
import LeafletLegend from '../leaflet/LeafletLegend';
import LeafletLegendButton from '../leaflet/LeafletLegendButton';
import LeafletMap from '../leaflet/LeafletMap';
import { customSelectStyles } from '../../../style/customSelectStyle';
import { env } from '../../../config';
import { parisCoordinates } from '../helpers';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';

interface IFormLeafletAddress {
  address: { label: string; value: IGoogleAddressPrediction } | null;
}

const LeafletRenderer: React.FC<{
  markers: LeafletMarker[];
  getMarkerTextInfos: (_i: number) => LeafletMarker;
}> = ({ markers, getMarkerTextInfos }) => {
  const { t } = useTranslation();
  const getDefaultCoordinates = () => {
    if (markers.length > 0) {
      return [markers[0].coordinates[0], markers[0].coordinates[1]] as LatLngTuple;
    }
    // Paris coordinates. if we return undefined the map goes nowhere
    return parisCoordinates;
  };
  const trackEvent = useMatomoHarmonizer();

  const [selectedCoordinates, setSelectedCoordinates] = useState<LatLngTuple | undefined>(
    getDefaultCoordinates()
  );
  const [inputValue, setInputValue] = useState('');
  const [showLegend, setShowLegend] = useState(false);
  const { watch, control } = useForm<IFormLeafletAddress>({
    mode: 'onChange',
    defaultValues: {
      address: undefined
    }
  });
  const address = watch('address');

  useEffect(() => {
    trackEvent({ page: 'Ajournement', category: 'Carte', actionType: 'Recherche' });
  }, [inputValue]);

  useEffect(() => {
    if (address) {
      if (!window.google?.maps?.places)
        throw new Error('[react-google-places-autocomplete]: Google maps places script not loaded');
      const placeService = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
      placeService.getDetails(
        { placeId: address.value.place_id },
        (res: google.maps.places.PlaceResult | null) => {
          res?.geometry?.location?.lat() &&
            setSelectedCoordinates([
              res?.geometry?.location?.lat(),
              res?.geometry?.location?.lng()
            ]);
        }
      );
    }
  }, [address]);

  const zoom = markers.length > 0 ? 13 : 7;

  return (
    <Row className="mt-2">
      <Col>
        <Card.Body className="p-1 text-center">
          <Row className="mb-2">
            <Col lg={9} md={8} sm={12}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <GooglePlacesAutocomplete
                    {...field}
                    aria-label="google-autocomplete-select"
                    selectProps={{
                      ...field,
                      inputValue: inputValue,
                      onInputChange: setInputValue,
                      defaultMenuIsOpen: !!inputValue,
                      placeholder: t('features.charts.renderer.location', 'Enter a location'),
                      isClearable: true,
                      styles: customSelectStyles,
                      className: 'leaflet-address-input',
                      // @ts-expect-error there is no defaultOptions type on GooglePlacesAutocomplete,
                      // but defaultOptions exist on react-select/async (https://react-select.com/props)
                      // we need this to launch search at start with given searchParams
                      defaultOptions: true
                    }}
                    apiKey={env.GOOGLE_API_KEY}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: 'fr'
                      }
                    }}
                    apiOptions={{ language: env.AUTOCOMPLETE_LANGUAGE }}
                    minLengthAutocomplete={3}
                  />
                )}
              />
            </Col>
            <Col lg={3} md={4} className="d-none d-md-flex mt-1 justify-content-end">
              <LeafletLegendButton setShowLegend={setShowLegend} showLegend={showLegend} />
            </Col>
          </Row>
          <Row>
            <Col md={showLegend ? 10 : 12}>
              <LeafletMap
                center={selectedCoordinates}
                zoom={zoom}
                markers={markers}
                getMarkerTextInfos={getMarkerTextInfos}
              />
            </Col>
            <Col sm={12} className="d-md-none mt-3 mb-2">
              <LeafletLegendButton setShowLegend={setShowLegend} showLegend={showLegend} />
            </Col>
            {showLegend && <LeafletLegend />}
          </Row>
        </Card.Body>
      </Col>
    </Row>
  );
};

export default LeafletRenderer;
