import { IThemedColors, IThemedComponent } from '../designInterfaces';

export const disabledColor = (props: IThemedComponent) => props.theme.palette['grey'][400];

export interface IThemedButton extends IThemedComponent {
  design?: 'plain' | 'white' | 'reverse';
}

export const linkColors = (props: IThemedComponent) => {
  return {
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'transparent',
    focusOutlineColor: 'none',
    border: 'transparent',
    color: props.theme.palette[props.variant][500],
    hoverColor: props.theme.palette[props.variant][600]
  } as IThemedColors;
};
