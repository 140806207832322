import { Calendar2Fill, CalendarRangeFill, GeoAltFill } from 'react-bootstrap-icons';
import { Card, Col, Container, Row } from 'react-bootstrap';
import LinkedCard, { LinkedCardContainer } from '@designSystem/LinkedCard';

import Icon from '@designSystem/Icon';
import { useGuardHook } from '../../utils/FeatureFlag';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AdministrationPage: React.FC = () => {
  const navigate = useNavigate();
  const canSeeAppointmentList = useGuardHook('erdv_appointment_list_read');
  const canSeeCalendarList = useGuardHook('erdv_calendar_list_read');
  const canSeeGeoZoneList = useGuardHook('erdv_geographic_zone_list_read');
  const { t } = useTranslation();
  return (
    <Container className="mt-4">
      <Row xs={12} className="justify-content-md-center">
        <Col xs={12} style={{ maxWidth: '800px' }}>
          <Card style={{ boxShadow: '0px 1px 3px 0px #0000001A' }}>
            <Card.Header>
              <h5>{t('pages.appointments.appointment', 'Appointments')}</h5>
            </Card.Header>
            <Card.Body>
              <LinkedCardContainer>
                <LinkedCardContainer>
                  {canSeeCalendarList && (
                    <LinkedCard
                      onClick={() => navigate('/erdv/calendar/list')}
                      data-testid="edrv-calendar-list-card"
                    >
                      <Icon background>
                        <Calendar2Fill />
                      </Icon>
                      <strong>{t('pages.appointments.calendarList', 'Calendar list')}</strong>
                      <p>
                        {t(
                          'pages.appointments.seeCalendar',
                          'See, create, udpate, and delete calendars'
                        )}
                      </p>
                    </LinkedCard>
                  )}
                  {canSeeGeoZoneList && (
                    <LinkedCard
                      onClick={() => navigate('/erdv/geographic-zone/list')}
                      data-testid="edrv-geographic-zone-list-card"
                    >
                      <Icon background>
                        <CalendarRangeFill />
                      </Icon>
                      <strong>
                        {t('pages.appointments.geographicList', 'Geographic zones list')}
                      </strong>
                      <p>
                        {t(
                          'pages.appointments.seeGeographic',
                          'See, create, udpate, and delete geographic zones'
                        )}
                      </p>
                    </LinkedCard>
                  )}
                </LinkedCardContainer>
                {canSeeAppointmentList && (
                  <LinkedCardContainer>
                    <LinkedCard
                      onClick={() => navigate('/erdv/appointment/list')}
                      data-testid="edrv-appointment-list-card"
                    >
                      <Icon background>
                        <GeoAltFill />
                      </Icon>
                      <strong>
                        {t('pages.appointments.appointmentList', 'Appointments list')}
                      </strong>
                      <p>
                        {t(
                          'pages.appointments.seeDelete',
                          'See, create, udpate, and delete appointments'
                        )}
                      </p>
                    </LinkedCard>
                  </LinkedCardContainer>
                )}
              </LinkedCardContainer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdministrationPage;
