import { Download, Eye } from 'react-bootstrap-icons';
import { StyledHeaderResults, StyledTitleAvailableProductsStyle } from './ResultsStyles';

import Button from '@designSystem/Button';
import React from 'react';
import { selectAdressInfos } from '../../eligibilitySlice';
import { useAppSelector } from '../../../../app/hooks';
import { useGuardHook } from '../../../../utils/FeatureFlag';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../../../utils/matomoHarmonizer';

const ResultsHeader: React.FC<{
  needStudy: boolean;
  exportData: CallableFunction;
  dataLength: number;
  loading: boolean;
  exportFile?: ({ _columns, _data }: any) => boolean;
  displayVerticality?: CallableFunction;
}> = ({ exportData, dataLength, needStudy, loading, displayVerticality }) => {
  const canDownloadQuotation = useGuardHook('download_quotation');
  const trackEvent = useMatomoHarmonizer();

  const addressInfos = useAppSelector(selectAdressInfos);
  const { t } = useTranslation();
  const canSeeVerticality =
    useGuardHook('eligibility_offer_verticality') &&
    addressInfos?.buildingRef &&
    !loading &&
    dataLength > 0;

  const handleVerticalityDisplay = () => {
    trackEvent({
      page: 'Commandes',
      category: 'Produit',
      actionType: 'Clic',
      actionDetails: 'Boutton_Verticalité'
    });
    displayVerticality?.();
  };

  const quotationGenerator = () => {
    trackEvent({
      page: 'Commandes',
      category: 'Produit',
      actionType: 'Clic',
      actionDetails: 'Button_Devis'
    });
    exportData('pdf', true);
  };

  return (
    <StyledHeaderResults>
      <StyledTitleAvailableProductsStyle>
        {t('features.eligibility.products.results.products', 'Product(s)', {
          defaultValue: 'Products {{count}}',
          count: dataLength
        })}
      </StyledTitleAvailableProductsStyle>
      <div>
        {canSeeVerticality && (
          <Button onClick={handleVerticalityDisplay} design={'white'} style={{ marginRight: 20 }}>
            {t('features.eligibility.products.results.verticaly', 'Verticality')}
            <Eye />
          </Button>
        )}
        {!loading && !needStudy && canDownloadQuotation && dataLength > 0 && (
          <Button data-testid="download-quotation" onClick={quotationGenerator} design={'white'}>
            {t('features.eligibility.products.results.quotation', 'Quotation')}
            <Download />
          </Button>
        )}
      </div>
    </StyledHeaderResults>
  );
};

export default ResultsHeader;
