import React, { useEffect } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { Container } from 'react-bootstrap';
import ZoneGeoForm from '@features/zonesGeo/zoneGeoForm/ZoneGeoForm';
import { getDepartementAndDSP } from '@features/profiles/profileSlice';
import { resetCurrentZoneGeo } from '@features/zonesGeo/reducer/zonesGeoSlice';
import styled from 'styled-components';
import { useAppDispatch } from '../../app/hooks';

const StyledZoneGeoFormContainer = styled(Container)`
  margin-top: 1.5rem;
  max-width: 800px;
`;

const ZoneGeoCreate: React.FC = () => {
  const dispatch = useAppDispatch();

  const { departementAndDSP } = useSelector((state: RootStateOrAny) => state.profile);
  const { currentZoneGeo } = useSelector((state: RootStateOrAny) => state.zonesGeo);

  useEffect(() => {
    dispatch(resetCurrentZoneGeo());
    if (!departementAndDSP || departementAndDSP.length === 0) {
      dispatch(getDepartementAndDSP());
    }
  }, []);

  return (
    <StyledZoneGeoFormContainer>
      {currentZoneGeo === null && <ZoneGeoForm />}
    </StyledZoneGeoFormContainer>
  );
};

export default ZoneGeoCreate;
