import { IThemedTooltip, defaultTooltipStyle } from './designStyles';
import React, { ReactChild, ReactElement, useState } from 'react';

import Icon from '../Icon';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { ThemeVariants } from '../../style/theme';
import styled from 'styled-components';

interface TooltipProps extends React.HTMLProps<HTMLElement> {
  delay?: number;
  children: ReactChild;
  direction?: 'top' | 'bottom' | 'right' | 'left';
  max?: string;
  variant?: ThemeVariants;
  icon?: ReactElement;
  onShow?: () => void;
}

const styles = defaultTooltipStyle();

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const TooltipContent = styled.div<IThemedTooltip>`
  ${styles}
`;

const Tooltip: React.FC<TooltipProps> = ({
  max = 'max-content',
  delay = 400,
  variant = 'blue',
  children,
  direction = 'top',
  icon,
  onShow
}) => {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
      onShow && onShow();
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <TooltipWrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
      {icon ? (
        icon
      ) : (
        <div style={{ maxHeight: '20px' }}>
          <Icon size="minimal" noBackground variant={variant} className="clickable">
            <InfoCircleFill size="16px" />
          </Icon>
        </div>
      )}

      {active && (
        <TooltipContent variant={variant} max={max} className={`${direction}`}>
          {children}
        </TooltipContent>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;
