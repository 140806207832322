import theme, { ThemeVariants } from '../../style/theme';

import { css } from 'styled-components';

export interface IThemedSwitch extends React.HTMLProps<HTMLElement> {
  variant?: ThemeVariants;
}

export const defaultStyle = () => {
  return css`
    display: flex;
    align-items: center;
    font-size: ${theme.fonts.size.sm};
    font-weight: ${theme.fonts.weight.regular};

    .switch {
      position: relative;
      display: inline-block;
      margin-right: 0.5rem;

      input[type='checkbox'] {
        width: 40px;
        height: 20px;
        border-radius: 12px;
        position: relative;
        --active: ${(props: IThemedSwitch) =>
          theme.palette[props.variant || 'blue'][500]}; // Background color
        --active-inner: white; // White circle in center
        --focus: 2px rgba(39, 94, 254, 0.3);
        --height-width-inner: 16px;
        --height-width-inner-disabled: 18.8px;
        --top-inner: 1px;
        --top-inner-disabled: -0.5px;
        --left-inner: 1px;
        --left-inner-disabled: -0.5px;
        --box-shadow-inner: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
        --box-shadow-inner-disabled: none;
        --border: transparent;
        --background: ${theme.palette.grey[300]};
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        display: inline-block;
        vertical-align: top;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

        &:focus {
          box-shadow: 0 0 0 var(--focus);
        }

        &:hover {
          border: 1px solid ${theme.palette.grey[300]};
        }
        &:hover:checked {
          border: 1px solid
            ${(props: IThemedSwitch) =>
              props.variant === 'green'
                ? theme.palette.green[100]
                : theme.palette[props.variant || 'blue'][500]};
        }

        &:after {
          left: var(--lid, var(--left-inner));
          top: var(--tid, var(--top-inner));
          border-radius: 50%;
          width: var(--hwid, var(--height-width-inner));
          height: var(--hwid, var(--height-width-inner));
          background: var(--dc, var(--ab, var(--active-inner)));
          transform: translateX(var(--x, 0));
          content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
          box-shadow: var(--bsid, var(--box-shadow-inner));
          display: block;
          position: absolute;
          transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
        }
        &:checked {
          --ab: var(--active-inner);
          --x: 20px;
          --b: var(--active);
          --bc: var(--active);
          --d-o: 0.3s;
          --d-t: 0.6s;
          --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }
        &:disabled {
          cursor: default;
        }
      }
    }

    // Green switch with tick
    &.with_check_icon {
      input[type='checkbox'] {
        &:hover:checked {
          border: 1px solid ${() => theme.palette.green[100]};
        }

        &:disabled {
          &:checked {
            --x: 18px;
            --b: ${theme.palette.green[100]}; // Border color
            --bc: var(--border); // Background color
            --dc: ${theme.palette.green[500]}; // Green circle in center when disabled
            --hwid: var(--height-width-inner-disabled); // Height and width
            --tid: var(--top-inner-disabled); // Top
            --lid: var(--left-inner-disabled); // Left
            --bsid: var(--box-shadow-inner-disabled); // Box shadow
          }
        }
      }
    }
  `;
};
