import { Alert } from 'react-bootstrap';
import { Trans } from 'react-i18next';

const ProductVerticalityAlert = () => {
  return (
    <Alert variant="primary" className="mt-3">
      <p className="mb-0">
        <small>
          <Trans
            i18nKey="features.eligibility.products.productVerticality.linkAppointment"
            defaultValue="
                For OPERA Home and OPERA Office offers, take your building identifier to <linky>take an appointment on our tool</linky> and get the appointement id that you will be given, it will allow you to finish the order."
            components={{
              linky: (
                <a
                  className="alert-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://erdvftth.axione.fr/"
                />
              )
            }}
          />
        </small>
      </p>
    </Alert>
  );
};

export default ProductVerticalityAlert;
