import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import Button from '@designSystem/Button';
import Icon from '@designSystem/Icon';
import LinkOrAnchor from '../../../designSystem/product/src/stories/LinkOrAnchor';
import Modal from '@designSystem/Modal';
import { StarFill } from 'react-bootstrap-icons';
import StepperRound from '@designSystem/StepperRound';
import first_draft from '../../../images/first_draft.gif';
import first_draft_step_2 from '../../../images/first_draft_step_2.gif';
import styled from 'styled-components';
import theme from '@theme';

const OrderBetaFirstDraftModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalStep, setModalStep] = useState(1);
  const { t } = useTranslation();
  const hasSeenDraft = localStorage.getItem('firstDraft');

  useEffect(() => {
    if (!hasSeenDraft) {
      const timeout = setTimeout(() => {
        setIsModalOpen(true);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <Modal show={isModalOpen}>
      <>
        <ModalIcon className="modal_draft_icon">
          <Icon round iconSize="16px" variant="grey" fillColor={theme.palette.blue[500]}>
            <StarFill />
          </Icon>
        </ModalIcon>
        <ModalText>
          <h2 className="modal_draft_header">
            {t('pages.order.customArea', 'Your customer area is evolving')}
          </h2>

          <p className="modal_draft_text">
            <Trans
              i18nKey="pages.order.findOrders"
              defaultValue={`Now you can find all your unfinished orders in <bolder>"draft"</bolder> section. If you need help, do not hesitate to contact us at`}
              components={{
                bolder: <span style={{ fontWeight: 500 }} />
              }}
            />

            <LinkOrAnchor href="mailto:support.mon-espace@axione.fr">
              support.mon-espace@axione.fr.
            </LinkOrAnchor>
          </p>
        </ModalText>

        <img
          width="100%"
          style={{ maxHeight: '232px' }}
          src={modalStep === 1 ? first_draft : first_draft_step_2}
        />
        <StepperRoundContainer>
          <StepperRound currentStep={modalStep} maxStep={2} onChange={setModalStep} />
        </StepperRoundContainer>
        {modalStep === 1 ? (
          <Button
            style={{ width: '100%' }}
            onClick={() => {
              setModalStep(2);
            }}
          >
            {t('component.button.nextStep', 'Next step')}
          </Button>
        ) : (
          <Button
            style={{ width: '100%' }}
            onClick={() => {
              localStorage.setItem('firstDraft', 'true');
              setIsModalOpen(false);
            }}
          >
            {t('component.button.understand', 'I understand')}
          </Button>
        )}
      </>
    </Modal>
  );
};

export default OrderBetaFirstDraftModal;

const ModalIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

const ModalText = styled.span`
  text-align: center;
  margin-bottom: 20px;

  .modal_draft_text {
    color: ${theme.palette.grey[500]};
    font-size: ${theme.fonts.size.sm};
    margin-bottom: 20px;
    .bold {
      font-weight: ${theme.fonts.weight.medium};
    }
  }

  .modal_draft_header {
    color: ${theme.palette.grey[800]};
    font-size: ${theme.fonts.size.lg};
    font-weight: ${theme.fonts.weight.medium};
    line-height: ${theme.fonts.spacing.lg};
  }
`;

const StepperRoundContainer = styled.div`
  margin: 24px 0;
`;
