import styled from 'styled-components';
import theme from '@theme';

const StyledProductInfosDetails = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  border: 1px solid;
  border-color: ${theme.palette.grey[200]};
  border-radius: 9px;
  padding: 12px 16px;
  gap: 2rem;

  & div + div {
    flex: 1 0 auto;
    text-align: right;
  }
`;

export default StyledProductInfosDetails;
