export const appointmentDatePickerStyle = `
  .rdp {
    margin: 0 2rem 0 0;
  }

  .rdp-months {
    justify-content: center;
  }

  .timeSlotWrapper {
    width: 280px;
    margin: 0 auto;
    display: flex;
  }

  .day-selected:not([disabled]), .day-selected:hover:not([disabled]), .rdp-button:active:not([disabled]), .rdp-button:focus:not([disabled]) {
    border-color: #0180A5;
    color: #0180A5;
    background-color: #c0ecfc;
  }

  .day-selected:not([disabled]) {
    font-weight: bold;
  }

  .rdp-button:hover:not([disabled]) {
      background-color: #c0ecfc;
  }

  .rdp-day_disabled {
    font-size: 100% !important;
  }

  .day-today {
    font-weight: bold;
    font-size: 100%;
  }
`;
