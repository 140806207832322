import { Card, Col } from 'react-bootstrap';
import { RootStateOrAny, useSelector } from 'react-redux';

import AlertBox from '../../../components/AlertBox';
import Button from '@designSystem/Button';
import InputWrapper from '@designSystem/InputWrapper';
import React from 'react';
import Spinner from '@designSystem/Spinner';
import { calendarSearch } from '../reducer/appointmentSlice';
import { useAppDispatch } from '../../../app/hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const CalendarSearchForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { calendar, calendarSearchLoading, calendarSearchError } = useSelector(
    (state: RootStateOrAny) => state.appointment
  );

  const { register, watch } = useForm<{
    reference: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      reference: ''
    }
  });
  const reference = watch('reference');

  const fetchCalendar = () => {
    dispatch(
      calendarSearch({
        reference,
        country: 'FR',
        type_operation: 1
      })
    );
  };

  return (
    <Col lg="6" sm="12">
      {calendarSearchError && (
        <AlertBox
          variant="danger"
          title={t('features.appointment.failedFetch', 'Calendar fetch failed')}
          message={JSON.stringify(calendarSearchError)}
          noMarginBottom
        />
      )}
      <Card>
        <Card.Body>
          <InputWrapper inputLabel={t('features.appointment.indicate', 'Indicate outlet')}>
            <input
              type="text"
              {...register('reference')}
              value={reference}
              id="reference"
              aria-label="reference-input"
              placeholder={t('features.appointment.calendarSearch.pto', `PTO`)}
            />
          </InputWrapper>
        </Card.Body>
        <Button
          disabled={(calendar && calendar.id) || calendarSearchLoading}
          onClick={fetchCalendar}
        >
          {calendarSearchLoading ? (
            <Spinner spinnerSize={2} center />
          ) : (
            t('features.appointment.makeAppointment', 'Make an appointment')
          )}
        </Button>
      </Card>
    </Col>
  );
};

export default CalendarSearchForm;
