import { Card, Col, Row } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import FormSectionHeader from '../../../components/FormSectionHeader';
import { ICreateCalendarForm } from '../utils/interfaces';
import { IZoneGeoFromApi } from '../../zonesGeo/utils/interfaces';
import InputWithTags from '../../../components/InputWithTags';
import InputWrapper from '@designSystem/InputWrapper';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import theme from '@theme';
import { useAppDispatch } from '../../../app/hooks';
import { zoneGeoFetchList } from '../../zonesGeo/reducer/zonesGeoSlice';
import { InterventionTypeLabel } from '@features/order/utils/constants';
import { useTranslation } from 'react-i18next';

const StyledCalendarFormDivider = styled.div`
  margin-top: 1.5rem;
  border-bottom: 1px solid ${theme.palette.grey[200]};
`;

const StyledCardBody = styled(Card.Body)`
  padding: 24px 24px;
  color: ${theme.palette.grey[700]};
  font-size: ${theme.fonts.size.sm};
`;

const StyledCalendarInputsCustomRow = styled(Row)`
  margin-top: 1.5rem;
`;

const StyledCalendarInputsTextareaInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: ${theme.palette.grey[400]};
`;

const typeInterventionList = [
  { label: 'INSTALLATION', value: '1' },
  { label: InterventionTypeLabel.BRASSAGE, value: '2' },
  { label: 'SAV', value: '3' },
  { label: 'SAV_EXPERTISE', value: '4' },
  { label: 'PRERACC', value: '5' }
];

const CalendarFormStepOne: React.FC<{
  formValues: ICreateCalendarForm;
  register: CallableFunction;
  setValue: CallableFunction;
  control: Control<ICreateCalendarForm, any>;
}> = ({ formValues, register, setValue, control }) => {
  const dispatch = useAppDispatch();
  const { zonesGeoList, zonesGeoTotalCount } = useSelector(
    (state: RootStateOrAny) => state.zonesGeo
  );

  const [loadedZonesGeo, setLoadedZonesGeo] = useState<IZoneGeoFromApi[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (zonesGeoList.length > 0) {
      setLoadedZonesGeo([...loadedZonesGeo, ...zonesGeoList]);
    }
  }, [zonesGeoList]);

  useEffect(() => {
    getNextZoneGeoListBatch(0);
  }, []);

  const loadNextZoneGeoList = () => {
    if (loadedZonesGeo.length < zonesGeoTotalCount) {
      let currentPage = 0;
      if (loadedZonesGeo.length > 0) {
        currentPage = Math.floor(loadedZonesGeo.length / 100);
      }

      getNextZoneGeoListBatch(currentPage);
    }
  };

  const getNextZoneGeoListBatch = (currentPage: number) => {
    dispatch(
      zoneGeoFetchList({
        currentPage,
        rowsPerPage: 100
      })
    );
  };

  useEffect(() => {
    if (formValues.alertThreshold && formValues.alertThreshold > 100) {
      setValue('alertThreshold', 100);
    }
  }, [formValues.alertThreshold]);

  const positiveNumberPattern = (event: { key: string; preventDefault: () => void }) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <StyledCardBody>
      <FormSectionHeader
        headerTitle={t('features.calendars.calendarForm.generalInfo', 'General informations')}
      />
      <StyledCalendarInputsCustomRow>
        <Col md={6} sm={12}>
          <Controller
            name="zoneGeoName"
            control={control}
            render={({ field }) => (
              <InputWrapper
                inputLabel={t('features.calendars.calendarForm.geographicZone', 'Geographic zone')}
              >
                <ReactSelect
                  {...field}
                  inputId="zoneGeoName"
                  aria-label="zoneGeoName-select"
                  onMenuScrollToBottom={loadNextZoneGeoList}
                  defaultValue={null}
                  options={loadedZonesGeo.map((zoneGeo: { name: string; id: number }) => {
                    return { label: zoneGeo.name, value: `${zoneGeo.id}` };
                  })}
                  value={formValues.zoneGeoName}
                  placeholder={''}
                />
              </InputWrapper>
            )}
          />
        </Col>

        <Col md={6} sm={12}>
          <Controller
            name="interventionType"
            control={control}
            render={({ field }) => (
              <InputWrapper
                inputLabel={t('features.calendars.calendarForm.intervention', 'Intervention type')}
              >
                <ReactSelect
                  {...field}
                  inputId="interventionType"
                  aria-label="intervention-type-select"
                  defaultValue={null}
                  options={typeInterventionList}
                  value={formValues.interventionType}
                  placeholder={''}
                />
              </InputWrapper>
            )}
          />
        </Col>
      </StyledCalendarInputsCustomRow>

      <StyledCalendarInputsCustomRow>
        <FormSectionHeader
          headerTitle={t(
            'features.calendars.calendarForm.workingDay',
            'Working days configuration'
          )}
        />
      </StyledCalendarInputsCustomRow>
      <StyledCalendarInputsCustomRow>
        <Col md={4} sm={12}>
          <InputWrapper
            inputLabel={t(
              'features.calendars.calendarForm.minDaysBooking',
              'Min days before booking'
            )}
          >
            <input
              type="number"
              {...register('minDaysBooking')}
              id="minDaysBooking"
              value={formValues.minDaysBooking}
              aria-label="minDaysBooking-input"
              min={0}
              onKeyPress={positiveNumberPattern}
            />
          </InputWrapper>
        </Col>
        <Col md={4} sm={12}>
          <InputWrapper
            inputLabel={t(
              'features.calendars.calendarForm.minDaysCancel',
              'Min days before cancel'
            )}
          >
            <input
              type="number"
              {...register('minDaysCancel')}
              id="minDaysCancel"
              value={formValues.minDaysCancel}
              aria-label="minDaysCancel-input"
              min={0}
              onKeyPress={positiveNumberPattern}
            />
          </InputWrapper>
        </Col>
        <Col md={4} sm={12}>
          <InputWrapper
            inputLabel={t(
              'features.calendars.calendarForm.minDaysPostpone',
              'Min days before postpone'
            )}
          >
            <input
              type="number"
              {...register('minDaysPostpone')}
              id="minDaysPostpone"
              value={formValues.minDaysPostpone}
              aria-label="minDaysPostpone-input"
              min={0}
              onKeyPress={positiveNumberPattern}
            />
          </InputWrapper>
        </Col>
      </StyledCalendarInputsCustomRow>
      <StyledCalendarFormDivider />
      <StyledCalendarInputsCustomRow>
        <FormSectionHeader headerTitle={t('features.calendars.calendarForm.manager', 'Managers')} />
      </StyledCalendarInputsCustomRow>
      <StyledCalendarInputsCustomRow>
        <Col md={12} sm={12}>
          <Controller
            name="managers"
            control={control}
            render={({ field }) => (
              <InputWrapper
                inputLabel={t(
                  'features.calendars.calendarForm.calendarManager',
                  'Calendar managers'
                )}
              >
                <InputWithTags
                  field={field}
                  placeholder={t(
                    'features.calendars.calendarForm.placeholderEmail',
                    'a.lastname@axione.fr'
                  )}
                  setValue={setValue}
                  inputId="managers"
                  selectName="managers"
                  selectValue={formValues.managers}
                />
              </InputWrapper>
            )}
          />
          <StyledCalendarInputsTextareaInfo>
            {t('features.calendars.calendarForm.addManagerEnter', `To add managers, press "Enter"`)}
          </StyledCalendarInputsTextareaInfo>
        </Col>
      </StyledCalendarInputsCustomRow>

      <StyledCalendarInputsCustomRow>
        <Col md={12} sm={12}>
          <Controller
            name="cafMails"
            control={control}
            render={({ field }) => (
              <InputWrapper inputLabel={t('features.calendars.calendarForm.caf', 'CAF Mails')}>
                <InputWithTags
                  field={field}
                  placeholder={t(
                    'features.calendars.calendarForm.placeholderEmailB',
                    'b.lastname@axione.fr'
                  )}
                  setValue={setValue}
                  inputId="cafMails"
                  selectName="cafMails"
                  selectValue={formValues.cafMails}
                />
              </InputWrapper>
            )}
          />
          <StyledCalendarInputsTextareaInfo>
            {t('features.calendars.calendarForm.addEmailEnter', `To add mails, press "Enter"`)}
          </StyledCalendarInputsTextareaInfo>
        </Col>
      </StyledCalendarInputsCustomRow>
      <StyledCalendarInputsCustomRow>
        <Col md={6} sm={12}>
          <InputWrapper
            inputLabel={t('features.calendars.calendarForm.alert', 'Alert threshold %')}
          >
            <input
              type="number"
              {...register('alertThreshold')}
              id="alertThreshold"
              value={formValues.alertThreshold}
              aria-label="alertThreshold-input"
              min={0}
              max={100}
              onKeyPress={positiveNumberPattern}
            />
          </InputWrapper>
        </Col>
      </StyledCalendarInputsCustomRow>
    </StyledCardBody>
  );
};

export default CalendarFormStepOne;
