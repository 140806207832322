import { CaptionProps, useNavigation } from 'react-day-picker';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

import styled from 'styled-components';
import theme from '@theme';
import { DateManager } from '../../../../utils/DateManager';

const OrderAppointmentCaption = (onMonthChange: CallableFunction) =>
  function CustomCaption(props: CaptionProps) {
    const { nextMonth, previousMonth } = useNavigation();
    return (
      <StyledDayPickerHeader>
        <div>
          {previousMonth && (
            <ChevronLeft
              style={{ cursor: 'pointer' }}
              color={'blue'}
              onClick={() => previousMonth && onMonthChange(previousMonth)}
            />
          )}
        </div>
        {new DateManager(props.displayMonth).format('MMMM YYYY')}
        <ChevronRight
          data-testid={'daypicker-nextMonth'}
          color={'blue'}
          style={{ cursor: 'pointer' }}
          onClick={() => nextMonth && onMonthChange(nextMonth)}
        />
      </StyledDayPickerHeader>
    );
  };

export default OrderAppointmentCaption;

const StyledDayPickerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: 100%;
  height: 40px;

  justify-content: space-between;

  background: ${theme.palette.grey[50]};
  border-radius: 6px;

  color: ${theme.palette.blue[500]};
  text-transform: capitalize;

  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.sm};
  font-weight: ${theme.fonts.weight.medium};
`;
