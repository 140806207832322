import { Calendar2Fill } from 'react-bootstrap-icons';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const OrderAppointmentHeader = () => {
  const { t } = useTranslation();
  return (
    <StyledOrderHeaderContainer>
      <div className="header-title-container">
        <span className="icon-title">
          <Calendar2Fill />
        </span>
        <div className="header-title">
          {t('features.order.orderAppointment.header', 'Appointment')}
        </div>
      </div>
    </StyledOrderHeaderContainer>
  );
};

export default OrderAppointmentHeader;

const StyledOrderHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  .icon-title {
    font-size: ${theme.fonts.size.lg};
    color: ${theme.palette.grey[300]};
    padding-right: 0.5rem;
  }

  .header-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-title {
      font-weight: ${theme.fonts.weight.medium};
      font-size: ${theme.fonts.size.sm};
      line-height: ${theme.fonts.spacing.sm};

      color: ${theme.palette.grey[900]};
    }
  }
`;
