import { MouseEventHandler } from 'react';
import OrderRecapConfirmModalError from './OrderRecapConfirmModalError';
import OrderRecapConfirmModalLoading from './OrderRecapConfirmModalLoading';
import OrderRecapConfirmModalSuccess from './OrderRecapConfirmModalSuccess';
import { useTranslation } from 'react-i18next';

const OrderRecapConfirmModal = ({
  contentToDisplay,
  currentLoading,
  closeModal
}: {
  contentToDisplay: 'loading' | 'error' | 'success';
  currentLoading: string;
  closeModal: MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();
  if (contentToDisplay === 'loading') {
    return (
      <OrderRecapConfirmModalLoading
        title={
          currentLoading === 'erdv'
            ? t(
                'features.order.orderRecap.orderRecapConfirmModals.appointmentResa',
                'Appointment reservation in progress'
              )
            : t(
                'features.order.orderRecap.orderRecapConfirmModals.orderCreation',
                'Order creation in progress'
              )
        }
      />
    );
  } else if (contentToDisplay === 'success') {
    return <OrderRecapConfirmModalSuccess />;
  }
  return <OrderRecapConfirmModalError closeModal={closeModal} />;
};

export default OrderRecapConfirmModal;
