import { ErrorMonitoring } from '../utils/ErrorMonitoring';
import Keycloak from 'keycloak-js';
import axios from 'axios';
import { env } from '../config';

const _kc = Keycloak('/keycloak.json');

// DON'T USE PROCESS ENV ON DEPLOYED ENV (it's for test only)
export const isDevEnvironment = () => {
  return process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
};

export const isProd = () => {
  return env.ENVIRONMENT === 'production' || env.ENVIRONMENT === 'preprod';
};

export const isPermitted = (userRoles, allowedRoles) => {
  if (
    typeof userRoles !== 'undefined' &&
    userRoles.length > 0 &&
    typeof allowedRoles !== 'undefined' &&
    allowedRoles.length > 0
  ) {
    return userRoles.some((role) => allowedRoles.includes(role));
  } else {
    return false;
  }
};

const axiosSetup = () => {
  axios.interceptors.request.use(async (config) => {
    await updateToken(5)
      .then(() => {
        config.headers.Authorization = `Bearer ${_kc.token}`;
      })
      .catch(() => {
        doLogin();
      });
    return config;
  });
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
export const initKeycloak = (onAuthenticatedCallback) => {
  if (isDevEnvironment()) {
    return onAuthenticatedCallback();
  }
  return _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256'
    })
    .then((authenticated) => {
      if (!authenticated) {
        return _kc.login();
      }
      axiosSetup();
      onAuthenticatedCallback();
    })
    .catch((e) => {
      console.error(e);
      new ErrorMonitoring().captureException(e);
    });
};

const doLogin = _kc.login;

export const doLogout = isDevEnvironment()
  ? () => console.error("Can't do that in dev mode")
  : _kc.logout;

export const getToken = () => _kc.token;

export const updateToken = (successCallback) => {
  if (isDevEnvironment()) {
    return Promise.resolve(successCallback);
  }
  return _kc.updateToken(5).then(successCallback).catch(doLogin);
};

export const getUsername = () => {
  if (isDevEnvironment()) {
    return process.env.REACT_APP_FK_USERNAME;
  }
  return _kc.tokenParsed?.preferred_username;
};

export const getCodeARCEP = () => {
  if (isDevEnvironment()) {
    return process.env.REACT_APP_FK_CODEARCEP;
  }
  return _kc.tokenParsed?.codeARCEP;
};

export const getUserInfo = (successCallback) => {
  if (isDevEnvironment()) {
    return successCallback({
      efoAccess: process.env.REACT_APP_FK_EFOACCESS,
      idSF: process.env.REACT_APP_FK_IDSF,
      preferred_username: process.env.REACT_APP_FK_USERNAME
    });
  }
  return _kc.loadUserInfo().then(successCallback).catch(console.error);
};
