import React from 'react';
import { SortAlphaDown, SortAlphaUp } from 'react-bootstrap-icons';
import { ThemeProps, ThemeVariants } from '../../style/theme';

import Button from '../Button';
import { Column } from 'react-table';
import styled from 'styled-components';

interface Props {
  theme: ThemeProps;
  isSorted: boolean;
}
interface PropsBtn {
  theme: ThemeProps;
  variant: ThemeVariants;
}

export type SortObject = {
  sortKey: string | null;
  sortDesc: boolean;
};

export type SearchAndSortObject = { search?: string; sort?: SortObject };

const SortWrapper = styled.span`
  font-size: ${(props: Props) => props.theme.fonts.size.base};
  margin-left: 0.2rem;
  min-width: 1rem;
  display: inline-block;
  color: ${(props: Props) => props.theme.palette[props.isSorted ? 'blue' : 'grey'][400]};
`;

const StyledButton = styled(Button)`
  padding: 2px 8px;
  color: ${(props: PropsBtn) =>
    // Force grey to weight 700 for table header
    props.theme.palette[props.variant][(props.variant === 'grey' && 700) || 400]};
  font-family: ${(props: PropsBtn) => props.theme.fonts.primary};
  font-weight: ${(props: PropsBtn) => props.theme.fonts.weight.medium};
  font-size: ${(props: PropsBtn) => props.theme.fonts.size.xs};
  line-height: ${(props: PropsBtn) => props.theme.fonts.size.base};
`;

const TableHeadSort = ({
  column,
  columnKey = column.orderKey || '',
  currentSortObject,
  sortByApi,
  children,
  ...props
}: {
  column: Column<any> | any;
  currentSortObject?: SortObject;
  columnKey?: string;
  sortByApi?: (_arg1?: SortObject) => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  const { canSort } = column;

  const isSorted =
    column.isSorted || (currentSortObject && currentSortObject?.sortKey === columnKey);
  const isSortedDesc = column.isSortedDesc || (currentSortObject && currentSortObject?.sortDesc);
  const buttonVariant = isSorted ? 'blue' : 'grey';

  const sortIcon = () => {
    if (canSort) {
      if (isSorted && isSortedDesc) {
        return <SortAlphaUp />;
      }
      return <SortAlphaDown />;
    }
    return null;
  };

  const handleSort = () => {
    if (sortByApi) {
      if (isSorted) {
        sortByApi(currentSortObject?.sortDesc ? undefined : { sortKey: columnKey, sortDesc: true });
      } else {
        sortByApi({ sortKey: columnKey, sortDesc: false });
      }
    }
  };

  if (canSort) {
    return (
      <StyledButton
        {...props}
        size="sm"
        variant={buttonVariant}
        design="reverse"
        onClick={handleSort}
      >
        <span>{children}</span>
        <SortWrapper isSorted={!!isSorted}>{sortIcon()}</SortWrapper>
      </StyledButton>
    );
  }

  return (
    <>
      <span>{children}</span>
      <SortWrapper isSorted={!!isSorted}>{sortIcon()}</SortWrapper>
    </>
  );
};

export default TableHeadSort;
