import styled, { keyframes } from 'styled-components';
import {
  IOrderInformationsOperaBusinessForm,
  ORDER_NTE_PORT_TYPE,
  ORDER_NTE_PORT_TYPE_LABELS,
  OrderOptionsPlus
} from '@features/order/utils/orderInterfaces';
import InformationBlock from '@designSystem/InformationBlock';
import InputWrapper from '@designSystem/InputWrapper';
import ReactSelect from 'react-select';
import { getErrorMessage, isFieldErrored } from '../../../../utils/formHelper';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  FormState,
  useFormContext
} from 'react-hook-form';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import theme from '@theme';
import { customVerticalityStylesWithError } from '../../../../style/customSelectStyle';
import { StyledOrderOutletWrapper } from '@features/order/utils/OrderStyles';
import { useAppSelector } from '../../../../app/hooks';
import { selectOrderState } from '@features/order/reducer/orderSlice';
import { TFunction } from 'i18next';

const vlanValidationRules = (t: TFunction<'translation', undefined, 'translation'>) => ({
  required: {
    value: true,
    message: t(
      'features.order.orderInformations.technicalCharacteristics.vlan_required',
      `VLAN is required`
    )
  },
  min: {
    value: 1,
    message: t(
      'features.order.orderInformations.technicalCharacteristics.vlan_value_less',
      `VLAN must be at least 1`
    )
  },
  max: {
    value: 4096,
    message: t(
      'features.order.orderInformations.technicalCharacteristics.vlan_value_more',
      'VLAN must be no more than 4096'
    )
  },
  validate: {
    isNumber: (value: string) => {
      if (value && value.length > 0) {
        return (
          !isNaN(Number(value)) ||
          t(
            'features.order.orderInformations.technicalCharacteristics.value_must_be_number',
            'VLAN must be a number'
          )
        );
      }
      return true;
    }
  }
});

const vlanOptionPlusValidationRules = (
  t: TFunction<'translation', undefined, 'translation'>,
  cVlanOptionPlus?: string
) => {
  return {
    required: {
      value: true,
      message: t(
        'features.order.orderInformations.technicalCharacteristics.vlan_required',
        `VLAN is required`
      )
    },
    min: {
      value: 1,
      message: t(
        'features.order.orderInformations.technicalCharacteristics.vlan_value_less',
        `VLAN must be at least 1`
      )
    },
    max: {
      value: 4096,
      message: t(
        'features.order.orderInformations.technicalCharacteristics.vlan_value_more',
        'VLAN must be no more than 4096'
      )
    },
    validate: {
      isNumber: (value: string) => {
        if (value && value.length > 0) {
          return (
            !isNaN(Number(value)) ||
            t(
              'features.order.orderInformations.technicalCharacteristics.value_must_be_number',
              'VLAN must be a number'
            )
          );
        }
        return true;
      },
      value: (value: any) => {
        const isVlanOptionPlusIsSimilarToCVlan =
          cVlanOptionPlus && cVlanOptionPlus.split('-').includes(value.toString());

        if (isVlanOptionPlusIsSimilarToCVlan) {
          return 'VLAN must be different from C-VLAN';
        }
        return true;
      }
    }
  };
};

const cVlanOptionPlusValidationRules = (
  t: TFunction<'translation', undefined, 'translation'>,
  requiredValue: boolean,
  index: number
) => {
  const { watch } = useFormContext();
  const cVlan1: string = watch('cVlan1');
  const cVlan2: string = watch('cVlan2');
  const cVlan3: string = watch('cVlan3');
  const cVlan4: string = watch('cVlan4');

  return {
    min: {
      value: 1,
      message: t(
        'features.order.orderInformations.technicalCharacteristics.vlan_value_less',
        `VLAN must be at least 1`
      )
    },
    max: {
      value: 4096,
      message: t(
        'features.order.orderInformations.technicalCharacteristics.vlan_value_more',
        'VLAN must be no more than 4096'
      )
    },
    validate: {
      isNumber: (value: string) => {
        if (value && value.length > 0) {
          return (
            !isNaN(Number(value)) ||
            t(
              'features.order.orderInformations.technicalCharacteristics.value_must_be_number',
              'C-VLAN must be a number'
            )
          );
        }
        return true;
      },
      value: (value: any) => {
        const cVlansWithoutCurrentValue = [cVlan1, cVlan2, cVlan3, cVlan4].filter(
          (_: string, i: number) => i + 1 !== index
        );
        return cVlansWithoutCurrentValue?.find((cvlan: string, index: number) => {
          const hasDuplicate = cvlan === value;
          return hasDuplicate;
        })
          ? t(
              'features.order.orderInformations.technicalCharacteristics.cvlan_duplicate',
              'Two C-VLAN cannot be identical'
            )
          : undefined;
      }
    },
    required: {
      value: requiredValue,
      message: t(
        'features.order.orderInformations.technicalCharacteristics.cvlan-required',
        'C-VLAN is required'
      )
    }
  };
};

const nominalInterfaces = [
  { maxProfile: 100, option: { value: '100BASE-TX', label: '100BASE-TX' } },
  { maxProfile: 1000, option: { value: '1000BASE-LX', label: '1000BASE-LX' } },
  { maxProfile: 1000, option: { value: '1000BASE-TX', label: '1000BASE-TX' } }
];
const optionPlusInterface = [
  { value: '1000BASE-LX', label: '1000BASE-LX' },
  { value: '1000BASE-TX', label: '1000BASE-TX' }
];

const TechnicalCharacteristics = ({
  orderInformationsFormValues,
  defaultOrderValue,
  defaultOptionPlusValue,
  isZTD
}: {
  orderInformationsFormValues: IOrderInformationsOperaBusinessForm;
  defaultOrderValue?: IOrderInformationsOperaBusinessForm | null;
  defaultOptionPlusValue?: OrderOptionsPlus;
  isZTD: boolean;
}) => {
  const { register, control, trigger, setValue, watch, formState, setError } = useFormContext();
  const { t } = useTranslation();
  const cVlanOptionPlusValue: string = watch('cVlanOptionPlus');
  const vlanOptionPlusValue: string = watch('vlanOptionPlus');
  const cVlan1: string = watch('cVlan1');
  const cVlan2: string = watch('cVlan2');
  const cVlan3: string = watch('cVlan3');
  const cVlan4: string = watch('cVlan4');

  const handleCVlanChange = (index: number, value: string) => {
    const cVlans = [cVlan1, cVlan2, cVlan3, cVlan4];
    cVlans[index] = value;
    const nonEmptyVlans = cVlans?.filter((vlan) => vlan && vlan.trim() !== '');
    setValue('cVlanOptionPlus', nonEmptyVlans.join('-'));
  };

  const { selectedOptions } = useAppSelector(selectOrderState);

  useEffect(() => {
    if (vlanOptionPlusValue?.length) {
      trigger('vlanOptionPlus');
    }
  }, [vlanOptionPlusValue, cVlan1, cVlan2, cVlan3, cVlan4]);

  useEffect(() => {
    if (cVlanOptionPlusValue) {
      const splitedCvLan = cVlanOptionPlusValue.split('-');
      setValue('cVlan1', splitedCvLan?.[0] ? splitedCvLan[0] : '');
      setValue('cVlan2', splitedCvLan?.[1] ? splitedCvLan[1] : '');
      setValue('cVlan3', splitedCvLan?.[2] ? splitedCvLan[2] : '');
      setValue('cVlan4', splitedCvLan?.[3] ? splitedCvLan[3] : '');
    }
  }, [cVlanOptionPlusValue]);

  useEffect(() => {
    if (defaultOptionPlusValue?.ntePortType) {
      setValue('ntePortType', defaultOptionPlusValue.ntePortType);
      trigger('ntePortType');
    }
    if (defaultOptionPlusValue?.cVlanOptionPlus) {
      const splitedCvLan = defaultOptionPlusValue?.cVlanOptionPlus.split('-');
      setValue('cVlanOptionPlus', defaultOptionPlusValue?.cVlanOptionPlus);
      setValue('cvlan', defaultOptionPlusValue?.cVlanOptionPlus);
      {
        splitedCvLan?.[0] && setValue('cVlan1', splitedCvLan[0]);
      }
      {
        splitedCvLan?.[1] && setValue('cVlan2', splitedCvLan[1]);
      }
      {
        splitedCvLan?.[2] && setValue('cVlan3', splitedCvLan[2]);
      }
      {
        splitedCvLan?.[3] && setValue('cVlan4', splitedCvLan[3]);
      }
    }
    if (defaultOptionPlusValue?.vlanOptionPlus) {
      setValue('vlanOptionPlus', defaultOptionPlusValue.vlanOptionPlus);
    }
    if (defaultOrderValue?.networkInterface) {
      setValue('networkInterface', defaultOrderValue?.networkInterface);
      trigger('networkInterface');
    }
    if (defaultOrderValue?.spanningTreeTransparency) {
      setValue('spanningTreeTransparency', defaultOrderValue?.spanningTreeTransparency);
    }
    if (defaultOptionPlusValue?.interfaceOptionPlus) {
      setValue('interfaceOptionPlus', defaultOptionPlusValue?.interfaceOptionPlus);
    }
  }, [defaultOptionPlusValue?.ntePortType, defaultOrderValue?.interfaceOptionPlus]);

  const errorCVlanFields = getCVlanError(formState);
  const spanningTreeTransparencyWatched = watch('spanningTreeTransparency');

  useEffect(() => {
    if (selectedOptions?.bronze === true && spanningTreeTransparencyWatched) {
      setError('spanningTreeTransparency', {
        type: 'value',

        message: t(
          'features.order.orderInformations.technicalCharacteristics.fieldSpanningTreeWithOptionBronzeError',
          'The « Sécurisation bronze » option is not compatible and cannot be subscribed to with transparency for Spanning Tree protocols because it is redundant.'
        )
      });
    }
    if (selectedOptions?.bronze === true && !spanningTreeTransparencyWatched) {
      setError('spanningTreeTransparency', {});
    }
  }, [selectedOptions?.bronze, spanningTreeTransparencyWatched]);

  const currentOptionPlusInterface = useMemo(() => {
    if (isZTD) {
      return optionPlusInterface.filter((i) => !i.value?.includes('LX'));
    }
    return optionPlusInterface;
  }, [isZTD]);

  const currentNominalInterfaces = useMemo(() => {
    const nominalInterfacesFilterByOption = nominalInterfaces
      .filter((i) => {
        return parseInt(selectedOptions?.profile || '0') <= i.maxProfile;
      })
      .map((i) => i.option);
    if (isZTD) {
      return nominalInterfacesFilterByOption.filter((i) => !i.value?.includes('LX'));
    }
    return nominalInterfacesFilterByOption;
  }, [isZTD]);

  return (
    <Container>
      <div className="section-title">
        {t(
          'features.order.orderInformations.technicalCharacteristics.nominal_access',
          `Technical characteristics of nominal access`
        )}
      </div>
      <InformationBlock
        title={t(
          'features.order.orderInformations.technicalCharacteristics.vlan_values_rule',
          'The VLAN values must not be the same and must be between 1 and 4096.'
        )}
      />
      <ReactSelectWrapper
        name="networkInterface"
        inputLabel="Interface"
        errors={formState.errors}
        value={orderInformationsFormValues.networkInterface}
        rule={{
          value: true,
          message: t(
            'features.order.orderInformations.technicalCharacteristics.interface_required',
            'Interface is required'
          )
        }}
        list={currentNominalInterfaces}
      />
      <InputWrapper inputLabel="VLAN" error={getErrorMessage('vlan', formState)}>
        <input
          {...register('vlan', vlanValidationRules(t))}
          placeholder="VLAN"
          type="number"
          value={orderInformationsFormValues.vlan}
          onKeyDown={handleVlanInputRestrictions}
          aria-label="vlan-input"
        />
      </InputWrapper>

      <StyledOrderOutletWrapper>
        <Controller
          name={'spanningTreeTransparency'}
          control={control}
          rules={{
            validate: {
              existingRules: (value) =>
                value === true ||
                value === false ||
                t(
                  'features.order.orderInformations.technicalCharacteristics.spanningTreeTransparency_required',
                  'Spanning Tree Status is required'
                ),
              checkBronzeSelection: (value) => {
                const isBronzeSelected = selectedOptions?.bronze;
                if (value === true && isBronzeSelected) {
                  return t(
                    'features.order.orderInformations.technicalCharacteristics.fieldSpanningTreeWithOptionBronzeError',
                    'The « Sécurisation bronze » option is not compatible and cannot be subscribed to with transparency for Spanning Tree protocols because it is redundant.'
                  );
                }
                return true;
              }
            }
          }}
          render={({ field: { onChange }, fieldState: { error } }) => {
            return (
              <div className="no-gap">
                <StyledLabel className="section-label-question">
                  Transparence spanning tree
                </StyledLabel>
                <div className="radio-container">
                  <input
                    onChange={() => onChange(true)}
                    type="radio"
                    id="spanningTreeTransparency_true"
                    name="spanningTreeTransparency"
                    value="true"
                    checked={orderInformationsFormValues.spanningTreeTransparency === true}
                  />
                  <label htmlFor="spanningTreeTransparency_true">{t('app.yes', 'Yes')}</label>
                </div>
                <div className="radio-container">
                  <input
                    onChange={() => onChange(false)}
                    type="radio"
                    id="spanningTreeTransparency_false"
                    name="spanningTreeTransparency"
                    value="false"
                    checked={orderInformationsFormValues.spanningTreeTransparency === false}
                  />
                  <label htmlFor="spanningTreeTransparency_false">{t('app.no', 'No')}</label>
                </div>
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
              </div>
            );
          }}
        />
      </StyledOrderOutletWrapper>

      {selectedOptions?.optionPlus ? (
        <>
          <div className="section-title">
            {t(
              'features.order.orderInformations.technicalCharacteristics.optionPlusSelectedLabel',
              'Technical characteristics of the Option Plus selected'
            )}
          </div>
          <InformationBlock
            title={t(
              'features.order.orderInformations.technicalCharacteristics.vlan_values_rule_CV',
              'The VLAN values must not be the same and must be between 1 and 4096.'
            )}
          />
          <ReactSelectWrapper
            value={orderInformationsFormValues.interfaceOptionPlus}
            errors={formState.errors}
            name="interfaceOptionPlus"
            inputLabel="Interface"
            rule={{
              value: true,
              message: t(
                'features.order.orderInformations.technicalCharacteristics.interface_required',
                'Interface is required'
              )
            }}
            list={currentOptionPlusInterface}
          />

          <StyledOrderOutletWrapper>
            <Controller
              name={'ntePortType'}
              rules={{
                required: {
                  value: true,
                  message: t(
                    'features.order.orderInformations.technicalCharacteristics.nte_port_required',
                    'NTE port is required'
                  )
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <div className="no-gap">
                    <StyledLabel className="section-label-question">
                      {t(
                        'features.order.orderInformations.technicalCharacteristics.nte_port_label',
                        'NTE Port'
                      )}
                    </StyledLabel>
                    <div className="radio-container">
                      <input
                        onChange={() => field.onChange(ORDER_NTE_PORT_TYPE.MONO_PORT)}
                        type="radio"
                        id="ntePortType_Mono-port"
                        name="ntePortType"
                        value={ORDER_NTE_PORT_TYPE.MONO_PORT}
                        checked={field.value === ORDER_NTE_PORT_TYPE.MONO_PORT}
                      />
                      <label htmlFor="ntePortType_Mono-port">
                        {ORDER_NTE_PORT_TYPE_LABELS[ORDER_NTE_PORT_TYPE.MONO_PORT]}
                      </label>
                    </div>
                    <div className="radio-container">
                      <input
                        onChange={() => field.onChange(ORDER_NTE_PORT_TYPE.BI_PORT)}
                        type="radio"
                        id="ntePortType_Bi-port"
                        name="ntePortType"
                        value={ORDER_NTE_PORT_TYPE.BI_PORT}
                        checked={field.value === ORDER_NTE_PORT_TYPE.BI_PORT}
                      />
                      <label htmlFor="ntePortType_Bi-port">
                        {ORDER_NTE_PORT_TYPE_LABELS[ORDER_NTE_PORT_TYPE.BI_PORT]}
                      </label>
                    </div>
                    {error && <ErrorMessage>{error.message}</ErrorMessage>}
                  </div>
                </>
              )}
            />
          </StyledOrderOutletWrapper>

          <InputWrapper inputLabel="VLAN" error={getErrorMessage('vlanOptionPlus', formState)}>
            <input
              {...register(
                'vlanOptionPlus',
                vlanOptionPlusValidationRules(t, orderInformationsFormValues.cVlanOptionPlus)
              )}
              type="number"
              placeholder="VLAN"
              value={orderInformationsFormValues.vlanOptionPlus}
              aria-label="vlan-input-optionel"
              onKeyDown={handleVlanInputRestrictions}
            />
          </InputWrapper>

          {orderInformationsFormValues.ntePortType === ORDER_NTE_PORT_TYPE.MONO_PORT ? (
            <div>
              <div style={{ display: 'grid', gridTemplateColumns: '80px 80px 80px 80px', gap: 10 }}>
                <VlanInputField
                  t={t}
                  name="cVlan1"
                  control={control}
                  formState={formState}
                  onChange={(value: string) => handleCVlanChange(0, value)}
                  requiredValue={true}
                  withLabel={true}
                />
                {cVlan1?.length ? (
                  <VlanInputField
                    t={t}
                    name="cVlan2"
                    control={control}
                    formState={formState}
                    onChange={(value: string) => handleCVlanChange(1, value)}
                    requiredValue={!!cVlan3 && cVlan3?.length > 0}
                  />
                ) : null}
                {cVlan2?.length ? (
                  <VlanInputField
                    t={t}
                    name="cVlan3"
                    control={control}
                    formState={formState}
                    onChange={(value: string) => handleCVlanChange(2, value)}
                    requiredValue={!!cVlan4 && cVlan4?.length > 0}
                  />
                ) : null}
                {cVlan3?.length ? (
                  <VlanInputField
                    t={t}
                    name="cVlan4"
                    control={control}
                    onChange={(value: string) => handleCVlanChange(3, value)}
                    formState={formState}
                    requiredValue={false}
                  />
                ) : null}
              </div>

              {errorCVlanFields ? (
                <ErrorMessage>{getErrorMessage(errorCVlanFields, formState)}</ErrorMessage>
              ) : null}
            </div>
          ) : null}
        </>
      ) : null}
    </Container>
  );
};
export default TechnicalCharacteristics;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const Container = styled.div`
  display: grid;
  grid-gap: 16px;

  .section-title {
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};
    color: ${theme.palette.grey[500]};
  }
`;

interface IReactSelectWrapper {
  name: string;
  inputLabel: string;
  list: { value: string; label: string }[];
  value: string;
  inputId?: string;
  rule?: { value: boolean; message: string };
  errors: FieldErrors;
}

const ReactSelectWrapper: React.FC<IReactSelectWrapper> = ({
  inputId,
  name,
  list,
  rule,
  errors,
  value,
  inputLabel
}) => {
  return (
    <>
      <Controller
        name={name}
        rules={{
          required: rule
        }}
        render={({ field: { onChange } }) => (
          <InputWrapper inputLabel={inputLabel}>
            <ReactSelect
              styles={isFieldErrored(name, errors) ? customVerticalityStylesWithError : {}}
              inputId={inputId}
              options={list}
              value={{
                value: value,
                label: value
              }}
              onChange={(val: any) => {
                onChange(val.value);
              }}
            />
          </InputWrapper>
        )}
      />
      {isFieldErrored(name, errors) && <ErrorMessage>{getErrorMessage(name, errors)}</ErrorMessage>}
    </>
  );
};

const VlanInputField: React.FC<{
  t: TFunction<'translation', undefined, 'translation'>;
  control: Control<FieldValues, object>;
  formState: FormState<FieldValues>;
  name: string;
  requiredValue: boolean;
  onChange: (value: string) => void;
  withLabel?: boolean;
}> = ({ t, control, withLabel, formState, name, onChange, requiredValue }) => {
  const currentIndexCVlan = Number(name.split('').at(-1));

  return (
    <Controller
      name={name}
      control={control}
      rules={cVlanOptionPlusValidationRules(t, requiredValue, currentIndexCVlan)}
      render={({ field }) => (
        <AnimatedInputWrapper withLabel={!withLabel}>
          <InputWrapper
            {...(withLabel ? { inputLabel: 'C-VLAN' } : {})}
            error={getErrorMessage(name, formState, !!formState.errors[name])}
          >
            <input
              {...field}
              type="number"
              onKeyDown={handleVlanInputRestrictions}
              onChange={(e) => {
                field.onChange(e);
                onChange(e.target.value);
              }}
              placeholder="1234"
            />
          </InputWrapper>
        </AnimatedInputWrapper>
      )}
    />
  );
};

const handleVlanInputRestrictions = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (['e', 'E', '+', '-'].includes(e.key)) {
    e.preventDefault();
  }
  const input = e.currentTarget as HTMLInputElement;

  const value = input.value;
  if (value.length >= 4 && e.key !== 'Backspace' && e.key !== 'Delete') {
    e.preventDefault();
  }
};

const getCVlanError = (formState: FormState<FieldValues>): string | undefined =>
  ['cVlan1', 'cVlan2', 'cVlan3', 'cVlan4'].find((field) => formState.errors[field]);

const AnimatedInputWrapper = styled.div<{ withLabel?: boolean }>`
  animation: ${slideInFromRight} 0.5s ease-out forwards;
  ${({ withLabel }) =>
    withLabel &&
    `
      align-self: end;
    `}
  input::placeholder {
      color: lightgrey;
      opacity: 1; /* Firefox */
    }

  }
`;
