import { loadOrganizations2 } from '@features/eligibility/eligibilitySlice';
import React, { useState, ReactNode, useEffect } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';
import { useAppDispatch } from '../../../../app/hooks';
import { LoaderIcon } from 'react-hot-toast';
import { Order, OrderOperaBusiness } from '@features/order/utils/orderInterfaces';

const MenuList = (props: {
  children: ReactNode;
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  reset: () => void;
  setValue: UseFormSetValue<FieldValues>;
  siren: string;
}) => {
  const { clearErrors } = useFormContext();

  const dispatch = useAppDispatch();
  const [isLoading, setIsloading] = useState(true);
  const [fidingSiren, setFidingSiren] = useState<{ siren: string } | null>(null);
  //44958654400917
  const loadOrganization = () => {
    dispatch(loadOrganizations2(props.siren)).then((res) => {
      if (res.payload) {
        setIsloading(false);
        setFidingSiren(res?.payload[0]);
      }
    });
  };
  loadOrganization();
  return (
    <div {...props}>
      <div
        style={{
          background: isLoading || fidingSiren?.siren ? 'white' : '#f0f0f0',
          position: 'sticky',
          bottom: 0,
          cursor: 'pointer'
        }}
        onClick={() => {
          clearErrors('siren');
          props.setMenuIsOpen(false);
          {
            fidingSiren?.siren ? props.setValue('siren', props.siren) : props.reset();
          }
        }}
      >
        {isLoading ? (
          <div style={{ padding: 5 }}>
            <LoaderIcon />
          </div>
        ) : (
          <div style={{ padding: '10px', textAlign: 'center' }}>
            {fidingSiren?.siren || props.message}
          </div>
        )}
      </div>
    </div>
  );
};

const SirenSelect: React.FC<{
  SIREN_LENGTH: number;
  register: UseFormRegister<FieldValues>;
  defineSiretToRegistration: () => void;
  setValue: UseFormSetValue<FieldValues>;
  companies?: {
    siren: string;
    name: string;
  }[];
  setSelectedOption: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
  selectedOption: {
    label: string;
    value: string;
  };
  sirenValue: string;
  order: Order | OrderOperaBusiness | undefined;
}> = ({ SIREN_LENGTH = 9, register, setValue, companies, setSelectedOption, order }) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const { getValues, setError, clearErrors } = useFormContext();
  const [currentValue, setCurrentValue] = useState(getValues().siren || '');
  const { t } = useTranslation();

  const handleInputChange = (inputValue: string) => {
    clearErrors();
    if (inputValue && !/^\d+$/.test(inputValue)) {
      setError('siren', { message: t('errorMessages.invalid', `Invalid format`), type: 'value' });
    }
    if (inputValue.length > 9) {
      setError('siren', { message: t('errorMessages.invalid', `Invalid format`), type: 'value' });
    }
    if (inputValue) setCurrentValue(inputValue);
    if (inputValue?.length === 9) {
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(false);
    }
  };

  const handleChange = (option: SingleValue<{ label: string; value: string }>) => {
    if (option) {
      setSelectedOption(option);
      setValue('siren', option.value);
      const companyName = companies?.find((company) => company.siren === option.value)?.name;
      setValue('businessName', companyName);
    } else {
      setValue('siren', '');
      setValue('businessName', '');
      setCurrentValue('');
    }
    setMenuIsOpen(false);
  };
  const reset = () => {
    setCurrentValue('');
    setValue('siren', '');
  };
  useEffect(() => {
    if (order?.customer?.siren) {
      setValue('siren', order?.customer?.siren);
      setCurrentValue(order?.customer?.siren);
    }
  }, [order?.customer?.siren]);
  return (
    <Select
      {...register('siren', {
        validate: {
          isValidLength: (v: string) => {
            return v?.length === SIREN_LENGTH || t('errorMessages.invalid', `Invalid format`);
          },
          isValidSiret: (v: string) => {
            return /^\d+$/.test(v) || t('errorMessages.invalid', `Invalid format`);
          }
        }
      })}
      menuIsOpen={menuIsOpen}
      value={{ label: currentValue, value: currentValue }}
      onInputChange={handleInputChange}
      options={[]}
      onChange={handleChange}
      placeholder="SIREN"
      isClearable={true}
      components={{
        MenuList: (props) => {
          return (
            <MenuList
              {...props}
              setMenuIsOpen={setMenuIsOpen}
              setValue={setValue}
              reset={reset}
              siren={currentValue}
              message={t(
                'feature.order.information.company.form.siren.immatriculation',
                `SIREN non trouvé / référencé`
              )}
            />
          );
        },
        NoOptionsMessage: (props) => <></>
      }}
    />
  );
};

export default SirenSelect;
