import { createSelector, createSlice } from '@reduxjs/toolkit';

import { ReactChild } from 'react';
import { RootState } from '../../app/store';
import { ThemeVariants } from '@theme';

export interface IBadgeWithLabelProps {
  content?: ReactChild;
  variant?: ThemeVariants;
}

interface INavigationState {
  badge: IBadgeWithLabelProps | null;
}

const initialState: INavigationState = {
  badge: null
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setBreadCrumbBadge: (state, action) => {
      state.badge = action.payload;
    }
  }
});

export const { setBreadCrumbBadge } = navigationSlice.actions;

export const selectNavigationState = createSelector(
  (state: RootState) => state.navigation,
  (state) => state
);

export default navigationSlice.reducer;
