import { Card } from 'react-bootstrap';
import React from 'react';

const ChartCardContainer: React.FC<{
  title?: string;
  noHeader?: boolean;
  children?: React.ReactNode;
}> = ({ title = '', noHeader = false, children }) => {
  return (
    <>
      <Card className="mt-2">
        {!noHeader && (
          <Card.Header className="border-bottom d-flex justify-content-between font-weight-bolder">
            <span>{title}</span>
          </Card.Header>
        )}
        <Card.Body className="p-10">{children}</Card.Body>
      </Card>
    </>
  );
};

export default ChartCardContainer;
