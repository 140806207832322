import { Container, Tab } from 'react-bootstrap';

import OrderAppointment from '@features/order/OrderAppointment';
import OrderInformations from '@features/order/OrderInformations';
import OrderOffer from '@features/eligibility/OrderOffer';
import OrderRecap from '@features/order/OrderRecap';
import { ReactChild } from 'react';
import StepperGoogleCondensed from '../components/StepperGoogleCondensed';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';
import { selectOrderState } from '@features/order/reducer/orderSlice';
import { productsListWithInfos } from '@features/eligibility/const';
import { TFunction } from 'i18next';

const Order = ({ headband = null }: { headband: ReactChild | null }) => {
  const { t } = useTranslation();
  const { productToOrder } = useAppSelector(selectOrderState);

  const productWithInfos = productsListWithInfos.find(
    (product) => product.code === productToOrder?.product.productOffering.id
  );
  return (
    <Container fluid className="main-content-container h-100 bg-white overflow-scroll px-0">
      {headband}
      <StepperGoogleCondensed
        otherSteps={[
          {
            key: `step-2`,
            title: t('pages.products', 'Products')
          },
          {
            key: `step-3`,
            title: t('pages.informations', 'Informations')
          },
          ...(productWithInfos?.isBusiness
            ? OrderOperaBusinessComponent.steps(t)
            : OrderComponent.steps(t))
        ]}
      >
        <Tab.Pane eventKey="step-2">
          <OrderOffer />
        </Tab.Pane>
        <Tab.Pane eventKey="step-3">
          <OrderInformations />
        </Tab.Pane>
        {productWithInfos?.isBusiness ? (
          <OrderOperaBusinessComponent.Component />
        ) : (
          <OrderComponent.Component />
        )}
      </StepperGoogleCondensed>
    </Container>
  );
};

export default Order;

const OrderComponent = {
  steps: (t: TFunction<'translation', undefined, 'translation'>) => [
    {
      key: `step-4`,
      title: t('pages.appointment', 'Appointment')
    },
    {
      key: `step-5`,
      title: t('pages.review', 'Review')
    }
  ],
  Component: () => (
    <>
      <Tab.Pane eventKey="step-4">
        <OrderAppointment />
      </Tab.Pane>
      <Tab.Pane eventKey="step-5">
        <OrderRecap comingFormDraft={true} />
      </Tab.Pane>
    </>
  )
};

const OrderOperaBusinessComponent = {
  steps: (t: TFunction<'translation', undefined, 'translation'>) => [
    {
      key: `step-4`,
      title: t('pages.review', 'Review')
    }
  ],
  Component: () => (
    <Tab.Pane eventKey="step-4">
      <OrderRecap comingFormDraft={true} />
    </Tab.Pane>
  )
};
