import { Card, Col } from 'react-bootstrap';
import React, { ReactElement } from 'react';

import InputWrapper from '@designSystem/InputWrapper';
import { StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import styled from 'styled-components';

const DashBoardSelectFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;
  gap: 1rem;

  .inputWrapper {
    width: 100%;
    display: flex;
    max-width: 75%;

    justify-content: space-between;
    align-items: baseline;
    gap: 0.5rem;

    & > div {
      width: 100%;
    }
  }
`;

const DelegantAdjournmentFilterColContainer: React.FC<{
  children: ReactElement;
  label: ReactElement | string;
  order: number;
}> = ({ children, label, order }) => {
  return (
    <Col md={{ order: order, span: 6 }}>
      <DashBoardSelectFilterContainer>
        {children.props.inputId}
        <StyledLabel
          htmlFor={children.props.inputId}
          style={{
            flexShrink: '0'
          }}
        >
          {label}
        </StyledLabel>
        <InputWrapper>{children}</InputWrapper>
      </DashBoardSelectFilterContainer>
    </Col>
  );
};

export default DelegantAdjournmentFilterColContainer;

export const DashBoardFiltersStickyContainer = styled(Card)`
   {
    transition: all 0.5s ease;
    top: 0px;
    position: sticky;
    width: 100%;
    z-index: 1;
    padding-left: 5px;

    &.hide {
      transform: translateY(calc(-100% + 3.5rem));
      margin-top: 0px;
    }
  }
`;
export const NotPaddedBotCardBody = styled(Card.Body)`
   {
    transition: all 0.5s ease;
    padding-bottom: 0px;

    &.hide {
      height: 0;
      overflow: hidden;
      padding: 0;
    }
  }
`;
