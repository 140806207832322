import { css, keyframes } from 'styled-components';

import { IThemedComponent } from '../designInterfaces';
import theme from '../../style/theme';

export interface IThemeSpinner extends IThemedComponent {
  spinnerSize: number;
  reverse: boolean;
  center: boolean;
}

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const defaultStyle = () => {
  return css`
    display: ${(props: IThemeSpinner) => (props.center ? 'block' : 'inline-block')};
    margin: 0 auto;
    width: ${(props: IThemeSpinner) => props.spinnerSize}rem;
    height: ${(props: IThemeSpinner) => props.spinnerSize}rem;
    border: ${(props: IThemeSpinner) => props.spinnerSize / 10}rem solid
      ${(props) =>
        props.reverse ? theme.palette['grey'][200] : props.theme.palette[props.variant][500]};
    border-top-color: ${(props: IThemeSpinner) =>
      props.reverse ? theme.palette[props.variant][500] : theme.palette['grey'][200]};
    border-radius: 50%;
    animation: ${spin} 1s ease-in-out infinite;
  `;
};
