import { Controller, useForm } from 'react-hook-form';
import {
  IGoogleAddressComponent,
  IGoogleAddressPrediction,
  IGoogleAutocompletePlacesResult
} from '../interfaces';
import React, { useEffect, useState } from 'react';
import SelectSearchComponent, { SelectSearchProps } from './SelectSearchComponent';
import { clearSearchType, setSearchType } from '../eligibilitySlice';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { StyledWrapComponent } from './SearchStyle';
import { customGoogleSelectStyle } from '../../../style/customSelectStyle';
import { decodeDataFrom } from '../helper';
import { env } from '../../../config';
import { useAppDispatch } from '../../../app/hooks';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IFormInputs {
  address: { label: string; value: IGoogleAddressPrediction } | null;
}

const getItemFromAddressComponent = (
  address: IGoogleAddressComponent[] | undefined,
  label: string
): string => {
  let ret = '';

  if (!address) return ret;
  address.forEach((comp) => {
    const filtered = comp.types.filter((el) => el.toLowerCase() === label);

    if (!ret && filtered.length) {
      ret = comp.long_name;
    }
  });
  return ret;
};

const SearchByAddress: React.FC<{
  selectProps?: SelectSearchProps;
}> = ({ selectProps }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState(decodeDataFrom(searchParams).address || '');
  const [placeService, setPlaceService] = useState<any>();
  const { control, watch, setValue } = useForm<IFormInputs>({
    mode: 'onChange',
    defaultValues: { address: null }
  });
  const address = watch('address');
  const { t } = useTranslation();

  useEffect(() => {
    if (window.google?.maps?.places && (!placeService || placeService === '')) {
      setPlaceService(new window.google.maps.places.PlacesService(document.createElement('div')));
    }
  }, [window.google?.maps?.places]);

  useEffect(() => {
    if (address && placeService) {
      if (!window.google?.maps?.places)
        throw new Error('[react-google-places-autocomplete]: Google maps places script not loaded');

      placeService.getDetails(
        { placeId: address.value.place_id },
        (res: IGoogleAutocompletePlacesResult) => {
          const postcode = getItemFromAddressComponent(res?.address_components, 'postal_code');
          const city = getItemFromAddressComponent(res?.address_components, 'locality');
          const street = getItemFromAddressComponent(res?.address_components, 'route');
          const streetNr = getItemFromAddressComponent(res?.address_components, 'street_number');

          dispatch(
            setSearchType({
              type: 'address',
              data: {
                address: res?.formatted_address,
                postcode,
                city,
                street,
                streetNr,
                latitude: res?.geometry?.location?.lat(),
                longitude: res?.geometry?.location?.lng()
              }
            })
          );
        }
      );
    } else {
      dispatch(clearSearchType());
    }
  }, [address, placeService]);

  useEffect(() => {
    // reset address if inputValue change to avoid menuIsOpen false
    if (address && inputValue) {
      setValue('address', null);
    }
  }, [inputValue, address]);

  return (
    <StyledWrapComponent>
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <GooglePlacesAutocomplete
            {...field}
            aria-label="google-autocomplete-select"
            selectProps={{
              ...field,
              inputValue: inputValue,
              onInputChange: setInputValue,
              menuIsOpen: address ? false : !!inputValue,
              placeholder: t('features.eligibility.search.siteAddress', `Enter a site address`),
              isClearable: true,
              styles: customGoogleSelectStyle,
              // @ts-expect-error there is no defaultOptions type on GooglePlacesAutocomplete,
              // but defaultOptions exist on react-select/async (https://react-select.com/props)
              // we need this to launch search at start with given searchParams
              defaultOptions: true,
              components: {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }
            }}
            apiKey={env.GOOGLE_API_KEY}
            autocompletionRequest={{
              componentRestrictions: {
                country: 'fr'
              }
            }}
            apiOptions={{ language: env.AUTOCOMPLETE_LANGUAGE, libraries: ['places'] }}
            minLengthAutocomplete={3}
          />
        )}
      />
      {selectProps && <SelectSearchComponent {...selectProps} />}
    </StyledWrapComponent>
  );
};

export default SearchByAddress;
