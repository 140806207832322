import { ChartPivotRow, CubejsApi, Query, ResultSet } from '@cubejs-client/core';
import React, { useEffect, useState } from 'react';

import { Alert } from 'react-bootstrap';
import { EchartSerie } from '../interfaces';
import Spinner from '@designSystem/Spinner';

const NumberDrawer: React.FC<{
  cubejsApi: CubejsApi | undefined;
  query: Query;
  chartType: 'additiveValue' | 'singleValue';
  errorCallback?: (_e: Error) => void;
}> = ({ cubejsApi, query, chartType, errorCallback }) => {
  const [series, setSeries] = useState<EchartSerie[] | EchartSerie>({} as EchartSerie);
  const setNoData = () => {
    setSeries({
      name: 'nodata',
      data: [],
      type: 'singleValue'
    });
  };

  useEffect(() => {
    let canceled = false;
    setSeries({});

    if (cubejsApi && query) {
      cubejsApi
        .load(query)
        .then((resultSet: ResultSet) => {
          if (!canceled) {
            if (resultSet.chartPivot().length === 0 || !resultSet.seriesNames()) {
              setNoData();
              return;
            }
            const key = resultSet.seriesNames()[0].key;
            const graphFormattedData: any = [];
            resultSet.chartPivot().forEach((chartPivotRow: ChartPivotRow) => {
              if (chartPivotRow[key] > 0) {
                graphFormattedData.push(chartPivotRow[key]);
              }
            });
            setSeries({
              name: key,
              data: graphFormattedData,
              type: chartType
            });
          }
        })
        .catch((e) => {
          setNoData();
          errorCallback && errorCallback(e);
        });
    }
    return () => {
      canceled = true;
    };
  }, [JSON.stringify(query.segments)]);

  if (series.data) {
    if (series.key === 'nodata') {
      return <Alert className="mb-0">Aucunes données à afficher</Alert>;
    }
    let totalValuesFromData = 0;
    series.data.forEach((serieDataValue: number) => {
      totalValuesFromData += Math.round(serieDataValue * 100) / 100;
    });
    return <span>{totalValuesFromData}</span>;
  }
  return <Spinner spinnerSize={2} reverse />;
};

export default NumberDrawer;
