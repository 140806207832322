import { Col, Form, Row } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import React, { useContext } from 'react';
import theme, { ThemeProps } from '@theme';

import { IVerticalityFormInputs } from '../../interfaces';
import ProductVerticalityDisabledSelect from './ProductVerticalityDisabledSelect';
import ProductVerticalitySelect from './productVerticalitySelect';
import { VerticalityContext } from '../../VerticalityProvider';
import { selectAdressInfos } from '../../eligibilitySlice';
import styled from 'styled-components';
import { useAppSelector } from '../../../../app/hooks';
import { useTranslation } from 'react-i18next';

const MoreInfoOnInput = styled.span<{ theme: ThemeProps }>`
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fonts.weight.regular};
  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.sm};
  color: ${theme.palette.grey[400]};
  margin: 0;
  margin-top: 0.5rem;
`;

export const StyledInput = styled.input`
  cursor: pointer;
  + label {
    padding-left: 0.5rem;
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.base};
  }
`;

export const StyledP = styled.p`
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.base};
  margin-bottom: 0;
`;

const ProductVerticalityForm: React.FC<{
  formValues: IVerticalityFormInputs;
  control: Control<IVerticalityFormInputs>;
  isSubmitableForm?: boolean;
}> = ({ formValues, control, isSubmitableForm = null }) => {
  const addressInfos = useAppSelector(selectAdressInfos);
  const { existingOutlet } = formValues;
  const { buildingsList, stairsList, floorsList, outletsList } = useContext(VerticalityContext);
  const { t } = useTranslation();
  return (
    <>
      <Form autoComplete="off">
        {addressInfos && (
          <ProductVerticalityDisabledSelect
            label={t('features.eligibility.products.productVerticality.address', `Address`)}
            placeholder={t('features.eligibility.products.productVerticality.address', `Address`)}
            optionValue={`${addressInfos.streetNr} ${addressInfos.streetName} ${addressInfos.postcode} ${addressInfos.city}`}
          />
        )}

        {addressInfos && (
          <ProductVerticalityDisabledSelect
            clipboard
            label={t(
              'features.eligibility.products.productVerticality.buildingRef',
              `Building reference`
            )}
            placeholder={t(
              'features.eligibility.products.productVerticality.buildingRef',
              `Building reference`
            )}
            optionValue={addressInfos.buildingRef}
          />
        )}

        <Row className="g-2 mt-3">
          <Col className="col-md-4">
            <ProductVerticalitySelect
              list={buildingsList}
              selectValue={formValues.building}
              label={t(
                'features.eligibility.products.productVerticality.buildingStar',
                `Building*`
              )}
              placeholder={t(
                'features.eligibility.products.productVerticality.building',
                `Building`
              )}
              registerTitle="building"
              control={control}
            />
          </Col>
          <Col className="col-md-4">
            {formValues.building && (
              <ProductVerticalitySelect
                list={stairsList}
                selectValue={formValues.stair}
                label={t('features.eligibility.products.productVerticality.stairStar', `Stair*`)}
                placeholder={t('features.eligibility.products.productVerticality.stair', `Stair`)}
                registerTitle="stair"
                control={control}
              />
            )}
          </Col>
          <Col className="col-md-4">
            {formValues.building && formValues.stair && (
              <ProductVerticalitySelect
                list={floorsList}
                selectValue={formValues.floor}
                label={t('features.eligibility.products.productVerticality.levelStar', `Level*`)}
                placeholder={t('features.eligibility.products.productVerticality.level', `Level`)}
                registerTitle="floor"
                control={control}
              />
            )}
          </Col>
        </Row>

        {formValues.building && formValues.stair && formValues.floor && (
          <Row className="g-2 mt-3">
            {isSubmitableForm && (
              <>
                <StyledP>
                  {t(
                    'features.eligibility.products.productVerticality.existingOutlet',
                    'Is there an existing outlet on site ?'
                  )}
                </StyledP>
                <Controller
                  control={control}
                  name={'existingOutlet'}
                  render={({ field: { onChange } }) => (
                    <>
                      <div>
                        <StyledInput
                          onChange={() => onChange(true)}
                          type="radio"
                          id="true"
                          name="existingOutlet"
                          value="true"
                          checked={existingOutlet}
                        />
                        <label htmlFor="true">{t('app.yes', 'Yes')}</label>
                      </div>
                      <div>
                        <StyledInput
                          onChange={() => onChange(false)}
                          type="radio"
                          id="false"
                          name="existingOutlet"
                          value="false"
                          checked={existingOutlet !== true}
                        />
                        <label htmlFor="false">{t('app.no', 'No')}</label>
                      </div>
                    </>
                  )}
                />
              </>
            )}
            {(existingOutlet || !isSubmitableForm) && (
              <>
                <ProductVerticalitySelect
                  clipboard
                  list={outletsList}
                  selectValue={formValues.outlet}
                  label={t('features.eligibility.products.productVerticality.outlet', `Outlet`)}
                  placeholder={t(
                    'features.eligibility.products.productVerticality.outlet',
                    `Outlet`
                  )}
                  registerTitle="outlet"
                  control={control}
                  emptyOption={
                    isSubmitableForm
                      ? {
                          label: t('errorMessages.unReferenced', `Unknown / Not referenced`),
                          value: ''
                        }
                      : undefined
                  }
                />

                {isSubmitableForm && (
                  <MoreInfoOnInput>
                    {t(
                      'features.eligibility.products.productVerticality.referenceDoubt',
                      ' If in doubt about the reference, we recommend selecting “Unknown / Not referenced” '
                    )}
                  </MoreInfoOnInput>
                )}
              </>
            )}
          </Row>
        )}
      </Form>
    </>
  );
};

export default ProductVerticalityForm;
