import { Col, Modal, Row } from 'react-bootstrap';

import Button from '@designSystem/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductVerticalityFooter: React.FC<{
  disabled?: boolean;
  backAction: CallableFunction;
  action?: CallableFunction;
  isStep2: boolean;
}> = ({ isStep2, disabled, backAction, action }) => {
  const { t } = useTranslation();
  return (
    <Modal.Footer>
      <Row style={{ width: '100%' }}>
        <Col md={action ? 6 : 12}>
          <Button style={{ width: '100%' }} design="white" onClick={() => backAction()}>
            {isStep2 ? t('component.button.cancel', 'Cancel') : t('component.button.back', 'Back')}
          </Button>
        </Col>
        {action && (
          <Col md={6}>
            <Button style={{ width: '100%' }} onClick={() => action()} disabled={disabled}>
              {isStep2
                ? t('component.button.nextStep', 'Next step')
                : t('component.button.validate', 'Validate')}
            </Button>
          </Col>
        )}
      </Row>
    </Modal.Footer>
  );
};

export default ProductVerticalityFooter;
