import { Control, Controller } from 'react-hook-form';
import ProductVerticalityForm, { StyledInput, StyledP } from './productVerticalityForm';

import { IVerticalityFormInputs } from '../../interfaces';
import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ProductVerticalityModalStep1: React.FC<{
  verticalityFormValues: IVerticalityFormInputs;
  control: Control<IVerticalityFormInputs>;
  showValidate?: boolean;
  isZTD?: boolean;
}> = ({ showValidate = false, isZTD = false, verticalityFormValues, control }) => {
  const { building, stair, floor, outlet, existingOutlet, multiAccess } = verticalityFormValues;
  const { t } = useTranslation();
  return (
    <>
      <ProductVerticalityForm
        formValues={{ building, stair, floor, outlet, existingOutlet, multiAccess }}
        control={control}
        isSubmitableForm={showValidate}
      />
      <>
        {showValidate && isZTD && existingOutlet && (
          <Row className="g-2 mb-3 mt-3">
            <StyledP>
              {t(
                'features.eligibility.products.productVerticality.additionalOutlet',
                'Would you like to install an additional outlet on this selected place ?'
              )}
            </StyledP>
            <Controller
              control={control}
              name={'multiAccess'}
              render={({ field: { onChange } }) => (
                <>
                  <div>
                    <StyledInput
                      onChange={() => onChange(true)}
                      type="radio"
                      id="multiAccess_true"
                      name="multiAccess"
                      value="true"
                      checked={multiAccess === true}
                    />
                    <label htmlFor="multiAccess_true">{t('app.yes', 'Yes')}</label>
                  </div>
                  <div>
                    <StyledInput
                      onChange={() => onChange(false)}
                      type="radio"
                      id="multiAccess_false"
                      name="multiAccess"
                      value="false"
                      checked={multiAccess !== true}
                    />
                    <label htmlFor="multiAccess_false">{t('app.no', 'No')}</label>
                  </div>
                </>
              )}
            />
          </Row>
        )}
      </>
    </>
  );
};

export default ProductVerticalityModalStep1;
