import { IThemedColors, IThemedComponent } from '../designInterfaces';

export interface IThemedBadge extends IThemedComponent {
  design?: 'small' | 'big';
  hasOnClick?: boolean;
  leftContent?: boolean;
  leftAction?: boolean;
}
export const badgeColors = (props: IThemedComponent) => {
  return {
    backgroundColor: props.theme.palette[props.variant][100],
    backgroundColorLeftIcon: props.theme.palette[props.variant][200],
    hoverBackgroundColor: props.theme.palette[props.variant][200],
    focusOutlineColor: props.theme.palette[props.variant][200],
    border: props.theme.palette[props.variant][100],
    color: props.theme.palette[props.variant][600]
  } as IThemedColors;
};

export const disabledColor = (props: IThemedComponent) => {
  return {
    backgroundColor: props.theme.palette['grey'][200],
    border: 'transparent',
    color: props.theme.palette['grey'][400]
  } as IThemedColors;
};
