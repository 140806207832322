import React, { ReactChild, ReactElement, useState } from 'react';

import { IThemedComponent } from '../designInterfaces';
import { ThemeVariants } from '../../style/theme';
import { defaultStyle } from './StepperRoundDesignStyles';
import styled from 'styled-components';

type StepperRoundProps = {
  variant?: ThemeVariants;
  maxStep: number;
  currentStep: number;
  onChange: (_step: number) => void;
};

export type StepProps = {
  key: string;
  icon: ReactChild;
  title: string | ReactElement;
  disabled?: boolean;
};

const styles = defaultStyle();

const StyledStepperRound = styled.div<IThemedComponent>`
  ${styles}
`;

const StepperRound: React.FC<StepperRoundProps> = ({
  variant = 'blue',
  currentStep,
  maxStep,
  onChange
}) => {
  const steps = [];
  for (let i = 1; i <= maxStep; i++) {
    steps.push(
      <div
        className={`roundStepShadow ${currentStep >= i && 'enabled'} ${
          currentStep === i && 'active'
        }`}
        onClick={() => onChange(i)}
        key={`stepper-round-${i}`}
      >
        <div className="roundStep" />
      </div>
    );
  }

  return <StyledStepperRound variant={variant}>{steps}</StyledStepperRound>;
};

export default StepperRound;
