import { RootStateOrAny, useSelector } from 'react-redux';
import React from 'react';

import Pagination from '@designSystem/Pagination';

import FullTable from '../../../components/FullTable';
import TableContainerWithBorderMaxWidth from '../../../components/TableContainerWithBorderMaxWidth';
import { PageHeaderSearchAndSortObject } from '../../../utils/interfaces';
import { SortObject } from '@designSystem/Table/HeadSort';

const rowsPerPage = 10;
interface TableDataProps {
  disableSortBy?: boolean;
  Header: JSX.Element | string;
  accessor: string;
  orderKey?: string;
  Cell?: any;
}

const AppointmentListTable: React.FC<{
  columns: TableDataProps[];
  setCurrentPage: (_currentPage: number) => void;
  currentPage: number;
  currentFilterAndSort: PageHeaderSearchAndSortObject;
  sortColumn: (_arg1?: SortObject | undefined) => void | null;
}> = ({ columns, currentFilterAndSort, sortColumn, setCurrentPage, currentPage }) => {
  const { appointmentsListLoading, appointmentsList, appointmentsListTotalCount } = useSelector(
    (state: RootStateOrAny) => state.appointment
  );

  return (
    <TableContainerWithBorderMaxWidth className="table-max-width-margin table-border-top">
      <>
        <FullTable
          columns={columns}
          data={appointmentsList}
          isLoading={appointmentsListLoading}
          currentFilterAndSort={currentFilterAndSort}
          sortColumn={sortColumn}
        />
        <Pagination
          totalRows={appointmentsListTotalCount}
          pageChangeHandler={setCurrentPage}
          rowsPerPage={rowsPerPage}
          parentCurrentPage={currentPage}
        />
      </>
    </TableContainerWithBorderMaxWidth>
  );
};

export default AppointmentListTable;
