import { isHomeBasicProduct, isZTD } from '../../../eligibility/helper';
import { IGetAppointmentSlot, ISelectedSlot, ISlot } from '../../utils/orderInterfaces';
import { InterventionTypeLabel, YearMonthDay } from '@features/order/utils/constants';
import { loadAppointmentSlots, selectOrderState } from '../../reducer/orderSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useEffect, useState } from 'react';

import OrderAppointmentFormDateInput from './OrderAppointmentFormDateInput';
import OrderAppointmentFormSchedule from './OrderAppointmentFormSchedule';
import OrderAppointmentPopOver from '../../components/OrderAppointmentPopOver';
import { i18n } from '@i18n';
import { selectAdressInfos } from '../../../eligibility/eligibilitySlice';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { todayUTC } from '../../../appointment/utils/helper';
import { unwrapResult } from '@reduxjs/toolkit';
import { useMatomoHarmonizer } from '../../../../utils/matomoHarmonizer';
import { useSelector } from 'react-redux';
import { DateManager } from '../../../../utils/DateManager';
import { getAdjustedDatesByProduct } from '@features/order/orderAppointment/OrderAppointmentForm/getAdjustedDatesByProduct';
import { getDSPFromProduct } from '@features/order/utils/utils';

const StyledOrderAppointmentDateAndScheduleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

interface IOrderAppointmentFormDateAndScheduleProps {
  setSelectedSlot: CallableFunction;
  selectedSlot?: ISelectedSlot;
  maxDays?: number;
}

const OrderAppointmentFormDateAndSchedule = ({
  selectedSlot,
  setSelectedSlot,
  maxDays
}: IOrderAppointmentFormDateAndScheduleProps) => {
  const dispatch = useAppDispatch();
  const { orderFormSteps, appointmentSlots } = useAppSelector(selectOrderState);
  const informationFormValues = orderFormSteps.steps[3].data;
  const addressInfos = useAppSelector(selectAdressInfos);

  const [isPopOverShown, setIsPopOverShown] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(
    selectedSlot && new Date(selectedSlot.day)
  );

  const [selectedDate, setSelectedDate] = useState<string>(
    selectedSlot ? new Date(selectedSlot.day).toString() : ''
  );
  const [month, setMonth] = useState<Date>(todayUTC);
  const [alreadyFetchedMonths, setFetched] = useState<string[]>([]);

  const [allSlots, setAllSlots] = useState<IGetAppointmentSlot[]>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<string | false>(
    selectedSlot ? selectedSlot.value : false
  );
  const [validTimeSchedule, setValidTimeSchedule] = useState<string[]>([]);
  const [validSlotsDays, setValidSlotsDays] = useState<string[]>([]);

  const orderState = useSelector(selectOrderState);
  const trackEvent = useMatomoHarmonizer();

  const defineInterventionType = () => {
    const selectedProduct = orderFormSteps.steps[2].data;
    const isProductZTD = isZTD(selectedProduct);
    const isZTDWithoutComplementaryOutlet =
      isProductZTD && !informationFormValues.complementaryOutlet;

    // To have BRASSAGE, we need:
    // - An existing outlet AND (
    //  - ZTD without complementary outlet OR
    //  - Not ZTD (= RIP)
    // )
    const isBrassage =
      informationFormValues.existingOutlet && (!isProductZTD || isZTDWithoutComplementaryOutlet);

    return isBrassage ? InterventionTypeLabel.BRASSAGE : InterventionTypeLabel.INSTALLATION;
  };

  /**
   * @param monthSelected YYYY-MM-DD
   */
  const updateTimeSlots = (monthSelected: YearMonthDay) => {
    const name = `${orderState.order?.customer.contact.firstName} ${orderState.order?.customer.contact.lastName}`;
    const productId = orderState.productToOrder?.productOffering.id;

    if (productId && addressInfos && addressInfos.buildingRef) {
      const { startDay, endDay } = getAdjustedDatesByProduct(
        monthSelected,
        isHomeBasicProduct(orderState.productToOrder)
      );
      const selectedProduct = orderFormSteps.steps[2].data;
      const spvName = getDSPFromProduct(selectedProduct?.product);
      dispatch(
        loadAppointmentSlots({
          start_day: startDay.format('YYYY-MM-DD'),
          end_day: endDay.format('YYYY-MM-DD'),
          reference:
            // sur la zone Cytifast la requête doit êtra basé sur la réf pm et non pas de l'id immeuble
            // addressInfos?.fdhRef marche quand on créer une commande, selectedProduct?.product.place[0]?.fdhRef marche depuis un brouillon
            // https://discord.com/channels/930376035659300914/1232346949856792736/1239906276423499786
            spvName === 'CityFast'
              ? addressInfos?.fdhRef || selectedProduct?.product?.place[0]?.fdhRef || ''
              : addressInfos.buildingRef,
          intervention_type: defineInterventionType(),
          product: productId ?? '',
          eligibilityId: orderState.order?.productOrderItem[0].productOrderItem
            .find((p) => p.product.productOffering.id === 'acces')
            ?.product.productCharacteristic.find((charac) => charac.name === 'eligibilityId')
            ?.value,
          companyName: orderState.order?.customer.name ?? name
        })
      )
        .then(unwrapResult)
        .catch((error) => {
          if (error?.body?.code) {
            trackEvent({
              page: 'Commandes',
              category: 'Rendez_vous',
              actionType: 'Erreur',
              actionDetails: 'Créneau_Code',
              lastParamsNumber: Number(error?.body?.code)
            });
          }
        });
    } else {
      toast.error(
        i18n.t(
          'features.order.orderAppointment.orderAppointmentForm.error',
          `An error happened retrieving building reference`
        )
      );
    }
  };

  /**
   * @param date format: YYYY-MM-DD
   */
  const updateFetchedMonths = (date: YearMonthDay) => {
    if (!alreadyFetchedMonths.includes(date)) {
      const fetchedMonths = [...alreadyFetchedMonths, date];

      setFetched(fetchedMonths);
      updateTimeSlots(date);
    }
  };

  useEffect(() => {
    // We reset calendar in case user has changed information about outlet
    setAllSlots([]);
  }, [orderFormSteps]);

  useEffect(() => {
    if (!isPopOverShown) {
      // We reset fetched months when popover is closed
      setFetched([]);
    } else {
      const date = new DateManager(month ?? new Date()).format('YYYY-MM-DD') as YearMonthDay;

      updateFetchedMonths(date);
      if (orderState.appointmentSlots.length) {
        setMonth(new Date(orderState.appointmentSlots[0].day));
      } else {
        setMonth(new Date(date));
      }
    }
  }, [isPopOverShown, orderState.appointmentSlots]);

  useEffect(() => {
    if (selectedDate && selectedSchedule && allSlots.length) {
      const newSelectedSlot = allSlots
        .find((s) => s.day === new DateManager(selectedDay).format('YYYY-MM-DD'))
        ?.slots.find((s: ISlot) => s.value === selectedSchedule);

      setSelectedSlot({ ...newSelectedSlot, day: selectedDay });
    }
    if (selectedDate === undefined || !selectedSchedule) {
      setSelectedSlot(null);
    }
  }, [selectedDate, selectedSchedule]);

  useEffect(() => {
    // on select day update possible times
    if (selectedDay) {
      const newValidTimes = allSlots
        .find((s) => s.day === new DateManager(selectedDay).format('YYYY-MM-DD'))
        ?.slots.map((s: ISlot) => s.value);
      setValidTimeSchedule(newValidTimes || []);
    } else {
      const allValidTimes = new Set(
        allSlots.flatMap((s) => s.slots.map((s: { value: string }) => s.value))
      );
      setValidTimeSchedule(Array.from(allValidTimes));
    }
  }, [selectedDay, allSlots]);

  useEffect(() => {
    setAllSlots([...allSlots, ...appointmentSlots]);
  }, [appointmentSlots]);

  const allDays = allSlots.map((s) => s.day);

  useEffect(() => {
    // on select time update possible days
    if (selectedSchedule) {
      const newvalidDays = allSlots
        .reduce<ISelectedSlot[]>(
          (accumulator, currentValue) =>
            accumulator.concat(
              currentValue?.slots.map((slot: any) => {
                return {
                  ...slot,
                  day: currentValue.day
                };
              })
            ),
          []
        )
        .filter((s: ISelectedSlot) => s.value === selectedSchedule)
        .map((s: ISelectedSlot) => s.day);
      setValidSlotsDays(newvalidDays);
    } else {
      setValidSlotsDays(allDays);
    }
  }, [selectedSchedule, allSlots]);

  const isInSlotsDays = (day: string) => validSlotsDays.includes(day);
  const displayPopOver = () => {
    if (!isPopOverShown) {
      return null;
    }

    return (
      <OrderAppointmentPopOver
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        setIsPopOverShown={setIsPopOverShown}
        month={month}
        setMonth={setMonth}
        setSelectedDate={setSelectedDate}
        updateFetchedMonths={updateFetchedMonths}
        isInSlotsDays={isInSlotsDays}
        maxDays={maxDays}
      />
    );
  };
  return (
    <StyledOrderAppointmentDateAndScheduleWrapper>
      <OrderAppointmentFormDateInput
        selectedDate={selectedDate}
        popOver={displayPopOver()}
        setIsPopOverShown={setIsPopOverShown}
      />
      <OrderAppointmentFormSchedule
        selectedSchedule={selectedSchedule}
        validTimeSchedule={validTimeSchedule}
        setSelectedSchedule={setSelectedSchedule}
      />
    </StyledOrderAppointmentDateAndScheduleWrapper>
  );
};

export default OrderAppointmentFormDateAndSchedule;
