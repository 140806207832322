const theme: ThemeProps = {
  palette: {
    purple: {
      50: '#F5F3FF',
      100: '#EDE9FE',
      200: '#DDD6FE',
      300: '#C4B5FD',
      400: '#A78BFA',
      500: '#8B5CF6',
      600: '#7C3AED',
      700: '#6D28D9',
      800: '#5B21B6',
      900: '#4C1D95'
    },
    pink: {
      50: '#FFF3FE',
      100: '#FFDFFD',
      200: '#FFCBFC',
      300: '#FAAAF5',
      400: '#F291EC',
      500: '#E974E1',
      600: '#D652CD',
      700: '#BD2CB3',
      800: '#9E0D94',
      900: '#7E0076'
    },
    red: {
      50: '#FEF2F2',
      100: '#FEE2E2',
      200: '#FECACA',
      300: '#FCA5A5',
      400: '#F87171',
      500: '#EF4444',
      600: '#DC2626',
      700: '#B91C1C',
      800: '#991B1B',
      900: '#7F1D1D'
    },
    orange: {
      50: '#FFF5E9',
      100: '#FFEDD5',
      200: '#FED7AA',
      300: '#FDBA74',
      400: '#FB923C',
      500: '#F97316',
      600: '#EA580C',
      700: '#C2410C',
      800: '#9A3412',
      900: '#7C2D12'
    },
    yellow: {
      50: '#FFFDEA',
      100: '#FFF9CA',
      200: '#FFF49D',
      300: '#FFEA3F',
      400: '#FFD910',
      500: '#EAC400',
      600: '#CBAA00',
      700: '#AA8F00',
      800: '#877100',
      900: '#594B02'
    },
    green: {
      50: '#ECFDF5',
      100: '#D1FAE5',
      200: '#A7F3D0',
      300: '#6EE7B7',
      400: '#34D399',
      500: '#14BF86',
      600: '#059669',
      700: '#047857',
      800: '#065F46',
      900: '#064E3B'
    },
    blue: {
      50: '#F5F9FF',
      100: '#E7F0FF',
      200: '#CCE3FF',
      300: '#92C4FC',
      400: '#60A5FA',
      500: '#3884FF',
      600: '#1567ED',
      700: '#0D4EC5',
      800: '#003494',
      900: '#002260'
    },
    grey: {
      25: '#FBFDFF',
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#9CA3AF',
      500: '#6B7280',
      600: '#4B5563',
      700: '#374151',
      800: '#1F2937',
      900: '#111827'
    }
  },
  fonts: {
    primary: "'Roboto', sans-serif",
    secondary: "'Lato', sans-serif",
    size: {
      xxs: '0.6875rem',
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      xxl: '1.5rem'
    },
    spacing: {
      xxs: '0.875rem',
      xs: '1rem',
      sm: '1.25rem',
      base: '1.5rem',
      lg: '1.75rem',
      xl: '1.75rem',
      xxl: '2rem'
    },
    weight: {
      regular: '400',
      medium: '500',
      semibold: '700'
    }
  }
};

export default theme;

export type ThemeProps = {
  palette: Record<ThemeVariants, ThemeShade>;
  fonts: {
    primary: string;
    secondary: string;
    size: Record<ThemeSizes, string>;
    spacing: Record<ThemeSizes, string>;
    weight: {
      regular: string;
      medium: string;
      semibold: string;
    };
  };
};

export type ThemeSizes = 'xxs' | 'xs' | 'sm' | 'base' | 'lg' | 'xl' | 'xxl';

export type ThemeVariants =
  | 'purple'
  | 'pink'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'grey';

export type ThemeVariantsDeclination = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export type ThemeShade = { [key: number]: string };

export const defaultToastStyle = {
  // Define default options
  duration: 5000,
  style: {
    border: theme.palette.grey[200],
    padding: '16px',
    color: theme.palette.grey[900]
  },
  // Default options for specific types
  success: {
    duration: 4000,
    iconTheme: {
      primary: theme.palette.green[500],
      secondary: 'white'
    }
  },
  error: {
    duration: 6000,
    iconTheme: {
      primary: theme.palette.red[500],
      secondary: 'white'
    }
  }
};
