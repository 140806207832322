import Button from '@designSystem/Button';
import React from 'react';

const DatePickerSchedule: React.FC<{
  design: 'plain' | 'reverse';
  disabled: boolean;
  onClick: () => void;
  message?: string;
}> = ({ design, onClick, disabled, message }) => {
  return (
    <Button
      type="button"
      className="mb-2"
      disabled={disabled}
      onClick={() => onClick()}
      design={design}
    >
      {message}
    </Button>
  );
};
export default DatePickerSchedule;
