import {
  IDataAddress,
  IDataBuilding,
  IDataCoordinates,
  IDataFunnel,
  IDataOTO,
  IDataPostcode,
  IDataSearchType,
  IDataSiret,
  IDataUprn,
  ISearchInfos
} from '../interfaces';

export enum GoogleMapZoom {
  ZOOM_100M = 18,
  ZOOM_BETWEEN_101_AND_300M = 17,
  ZOOM_500M = 16
}
export const getZoomFromRadius = (radius: number): GoogleMapZoom => {
  switch (true) {
    case radius <= 100:
      return GoogleMapZoom.ZOOM_100M;
    case radius <= 300:
      return GoogleMapZoom.ZOOM_BETWEEN_101_AND_300M;
    case radius <= 500:
      return GoogleMapZoom.ZOOM_500M;
    default:
      return GoogleMapZoom.ZOOM_BETWEEN_101_AND_300M;
  }
};
export const getRadiusFromZoom = (zoom: number): number => {
  switch (true) {
    case zoom >= GoogleMapZoom.ZOOM_100M:
      return 100;
    case zoom === GoogleMapZoom.ZOOM_BETWEEN_101_AND_300M:
      return 300;
    case zoom <= GoogleMapZoom.ZOOM_500M:
      return 500;
    default:
      return 200;
  }
};

export const getDefaultCenterFromSearch = (search: ISearchInfos) => {
  let defaultCenter;

  if (search.type && ['address', 'geoaddress', 'coordinates'].includes(search.type)) {
    defaultCenter = {
      lat: parseFloat((search.data as IDataAddress).latitude),
      lng: parseFloat((search.data as IDataAddress).longitude),
      radius: (search.data as IDataCoordinates).radius
    };
  }
  return defaultCenter;
};

export const buildSearchQueryByInfos = (search: ISearchInfos, filterReferenceType?: string) => {
  const urlParams = new URLSearchParams();
  switch (search.type) {
    case 'funnel':
      urlParams.append('postcode', (search.data as IDataFunnel).postcode);
      urlParams.append('city', (search.data as IDataFunnel).city);
      urlParams.append('streetName', (search.data as IDataFunnel).street);
      urlParams.append('streetNr', (search.data as IDataFunnel).streetNr || '');
      break;
    case 'geoaddress':
      urlParams.append(
        'geographicLocation.bbox',
        `${(search.data as IDataAddress).longitude}, ${(search.data as IDataAddress).latitude}`
      );
      urlParams.append('geographicLocation.radius', '80');
      break;
    case 'postcode':
      urlParams.append('postcode', (search.data as IDataPostcode).postcode);
      break;
    case 'siret':
      urlParams.append('siret', (search.data as IDataSiret).siret);
      break;
    case 'building':
      urlParams.append('buildingRef', (search.data as IDataBuilding).buildingRef);
      break;
    case 'initialOTO':
      urlParams.append('otoRef', (search.data as IDataOTO).otoRef);
      break;
    case 'uprn':
      urlParams.append('uprn', (search.data as IDataUprn).uprn);
      break;
    case 'address':
    case 'coordinates':
      urlParams.append(
        'geographicLocation.bbox',
        `${(search.data as IDataCoordinates).longitude}, ${
          (search.data as IDataCoordinates).latitude
        }`
      );
      urlParams.append(
        'geographicLocation.radius',
        (search.data as IDataCoordinates).radius || '200'
      );

      break;
  }

  if (filterReferenceType) {
    urlParams.append('referenceType', filterReferenceType);
  }

  return urlParams.toString();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkDataConsistency = (type: IDataSearchType, data: any) => {
  switch (type) {
    case 'funnel':
      return !!(data.postcode && data.city && data.street);
    case 'address':
      return !!(data.postcode && data.city && data.longitude && data.latitude);
    case 'geoaddress':
      return !!(data.longitude && data.latitude);
    case 'postcode':
      return !!data.postcode;
    case 'siret':
      return !!data.siret;
    case 'building':
      return !!data.buildingRef;
    case 'initialOTO':
      return !!data.otoRef;
    case 'uprn':
      return !!data.uprn;
    case 'coordinates':
      return !!(data.longitude && data.latitude && data.radius);
  }
};
