import { CSSObjectWithLabel, StylesConfig } from 'react-select';

import theme from '@theme';
import { buildReactSelectStyled } from '../utils/styled';

export const customSelectStyles: StylesConfig = {
  container: (base) =>
    buildReactSelectStyled(base, {
      flex: 2,
      fontSize: theme.fonts.size.sm,
      maxWidth: '100%'
    }),
  menu: (base) =>
    buildReactSelectStyled(base, {
      zIndex: 9
    }),
  control: (base: CSSObjectWithLabel, state) =>
    buildReactSelectStyled(base, {
      cursor: 'pointer',
      borderColor: state.isFocused ? theme.palette.blue[500] : theme.palette.grey[200],
      borderRadius: '6px 0px 0px 6px',
      '&:hover': {
        borderColor: state.isFocused ? theme.palette.blue[500] : theme.palette.grey[200]
      }
    }),
  option: (base, state) =>
    buildReactSelectStyled(base, {
      cursor: 'pointer',
      color: state.isSelected ? theme.palette.grey[500] : theme.palette.grey[800],
      backgroundColor: state.isFocused ? theme.palette.blue[50] : 'white',
      '&:hover': {
        backgroundColor: theme.palette.blue[50]
      }
    }),
  singleValue: (base) =>
    buildReactSelectStyled(base, {
      color: theme.palette.grey[700]
    }),
  placeholder: (base) =>
    buildReactSelectStyled(base, {
      color: theme.palette.grey[500]
    })
};

export const customSelectSearch: StylesConfig = {
  ...customSelectStyles,
  singleValue: (base) =>
    buildReactSelectStyled(base, {
      color: theme.palette.blue[500]
    }),

  container: (base) =>
    buildReactSelectStyled(base, {
      flex: 1,
      minWidth: '160px',
      maxWidth: '160px'
    }),

  control: (base: CSSObjectWithLabel) =>
    buildReactSelectStyled(base, {
      backgroundColor: theme.palette.blue[50],
      borderColor: theme.palette.blue[500],
      fontWeight: theme.fonts.weight.medium,
      borderRadius: '0px 6px 6px 0px',
      cursor: 'pointer'
    }),

  dropdownIndicator: (base) =>
    buildReactSelectStyled(base, {
      color: theme.palette.blue[500],
      '&:hover': {
        color: theme.palette.blue[500]
      }
    })
};

export const customVerticalityStyles: StylesConfig = {
  option: (base, state) =>
    buildReactSelectStyled(base, {
      cursor: 'pointer',
      color: state.isSelected ? theme.palette.grey[500] : theme.palette.grey[800],
      backgroundColor: state.isFocused ? theme.palette.blue[50] : 'white',
      '&:hover': {
        backgroundColor: theme.palette.blue[50]
      }
    }),
  container: (base) =>
    buildReactSelectStyled(base, {
      fontSize: theme.fonts.size.sm
    }),
  control: (base: CSSObjectWithLabel) =>
    buildReactSelectStyled(base, {
      borderColor: theme.palette.grey[200]
    })
};

export const customVerticalityStylesWithError: StylesConfig = {
  ...customVerticalityStyles,
  control: (base: CSSObjectWithLabel) =>
    buildReactSelectStyled(base, {
      borderColor: theme.palette.red[500],
      backgroundColor: theme.palette.red[50]
    })
};

export const customGoogleSelectStyle: StylesConfig = {
  ...customVerticalityStyles,
  container: (base: CSSObjectWithLabel) =>
    buildReactSelectStyled(base, {
      flex: 2,
      fontSize: theme.fonts.size.sm,
      maxWidth: 'calc(100% - 160px)'
    })
};
