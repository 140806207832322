import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Badge from '@designSystem/Badges';
import { ProductOfferingQualification } from '@features/eligibility/interfaces';
import { listOfProductOptions } from '@features/eligibility/products/constants';
import { OrderSections } from '@features/order/utils/OrderHelper';
import { OrderOptions } from '@features/order/utils/orderInterfaces';

import OptionsForm from '../../../eligibility/products/productDetail/OptionsForm';
import ResultsItemMinified from '../../../eligibility/products/results/ResultsItemMinified';
import { StyledOrderSection, StyledSideContentCard } from '../../utils/OrderStyles';
import OrderRecapEditLink from '../components/OrderRecapEditLink';
import { useSearchParams } from 'react-router-dom';

const OrderRecapProductSection = ({
  editable,
  product,
  selectedOptions
}: {
  editable: boolean;
  product: ProductOfferingQualification;
  selectedOptions: OrderOptions;
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const editableStatus = searchParams.get('draftStatut');
  const productItem = product.productOfferingQualificationItem?.[0];
  const isEditable = useMemo(() => {
    if (editable && editableStatus !== 'finish') {
      return true;
    }
    return false;
  }, []);
  return (
    <StyledOrderSection>
      <div className="section-title">
        {t('features.order.orderRecap.orderRecapSections.productOptions', 'Product and option(s)')}
      </div>
      {productItem && (
        <StyledProductCardsWrapper>
          <div className="product_card">
            <div className="section-subtitle">
              {t(
                'features.order.orderRecap.orderRecapSections.productSelected',
                'Product selected'
              )}
            </div>
            <StyledSideContentCard>
              <ResultsItemMinified selectedProduct={productItem} canDisplayPrice={isEditable} />
            </StyledSideContentCard>
            {selectedOptions?.profile && (
              <>
                <br />
                <StyledSideContentCard>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontWeight: 500,
                      fontSize: '0.80rem'
                    }}
                  >
                    <span>
                      {t('features.order.orderInformations.debit', 'Débit intermédiaire')}
                    </span>
                    <Badge>{selectedOptions?.profile}</Badge>
                  </div>
                </StyledSideContentCard>
              </>
            )}
            {isEditable && (
              <OrderRecapEditLink stepToGo={3} anchorKey={OrderSections.EndClientInformations} />
            )}
          </div>
          <div className="product_card">
            <div className="section-subtitle">
              {t(
                'features.order.orderRecap.orderRecapSections.optionSelected',
                'Option(s) selected'
              )}
            </div>
            <StyledSideContentCard>
              {isEditable ? (
                <OptionsForm
                  selectedProduct={productItem}
                  defaultOption={{ gtr: selectedOptions?.gtr, eqt: undefined }}
                  optionsToOrder={selectedOptions}
                  editable={false}
                />
              ) : (
                <div style={{ display: 'flex', gap: '10px' }}>
                  {selectedOptions?.gtr && (
                    <Badge variant="grey">
                      {t('features.order.orderRecap.gtr', 'GTR')}{' '}
                      {t(listOfProductOptions[selectedOptions?.gtr])}
                    </Badge>
                  )}
                  {selectedOptions?.trunks?.safetyTrunk && (
                    <Badge variant="grey">
                      {t('features.order.orderRecap.bronze', 'Sécurisation Bronze')}
                    </Badge>
                  )}
                  {selectedOptions?.optionPlus && (
                    <Badge variant="grey">
                      {t('features.order.orderRecap.optionPlus', 'Option Plus')}
                    </Badge>
                  )}
                  {selectedOptions?.eqt && (
                    <Badge variant="grey" style={{ marginLeft: '10px' }}>
                      {t(
                        'features.order.orderRecap.installation',
                        'Terminal equipment installation'
                      )}
                      {selectedOptions?.eqt}
                    </Badge>
                  )}
                </div>
              )}
            </StyledSideContentCard>
          </div>
        </StyledProductCardsWrapper>
      )}
      {isEditable && (
        <OrderRecapEditLink stepToGo={3} anchorKey={OrderSections.EndClientInformations} />
      )}
    </StyledOrderSection>
  );
};

export default OrderRecapProductSection;

const StyledProductCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .product_card {
    width: 100%;
  }
`;
