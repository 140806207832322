import { Card, Col, Row } from 'react-bootstrap';
import { queries, transformSegmentForCube } from './utils/chartsQueries';

import ChartCardContainer from './ChartCardContainer';
import { CubejsApi } from '@cubejs-client/core';
import EchartsDrawer from './drawer/EchartsDrawer';
import NumberDrawer from './drawer/NumberDrawer';
import React from 'react';
import { Segment } from './interfaces';
import { getTimeDimensions } from './helpers';
import { options } from './utils/chartsOptions';
import { useTranslation } from 'react-i18next';

const NumbersAndBacklog: React.FC<{
  showAdjByDep: boolean;
  segments: Segment[] | undefined;
  cubejsApi: CubejsApi;
  manageError?: (_e: Error) => void;
}> = ({ showAdjByDep, segments, cubejsApi, manageError }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col lg={6} sm={12}>
        <Card className="mt-4 col-md-12 rounded bg-primary">
          <Card.Body className="p-20 text-center text-white">
            {t('features.charts.running', 'Running adjournments')}
            <h3>
              <NumberDrawer
                cubejsApi={cubejsApi}
                query={{
                  ...queries.nombreAjournementEnCours,
                  segments: transformSegmentForCube(segments, 'NombreAjournementEnCours')
                }}
                chartType="additiveValue"
                errorCallback={manageError}
              />
            </h3>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6} sm={12}>
        <Card className="mt-4 col-md-12 rounded bg-primary">
          <Card.Body className="p-20 text-center text-white">
            {t('features.charts.commandRate', 'Concerned command rate')}
            <h3>
              <NumberDrawer
                cubejsApi={cubejsApi}
                query={{
                  ...queries.tauxBrut,
                  segments: transformSegmentForCube(segments, 'TTauxBrutEspaceDelegant', [
                    'intervention'
                  ]),
                  timeDimensions: getTimeDimensions('TTauxBrutEspaceDelegant')
                }}
                chartType="singleValue"
                errorCallback={manageError}
              />
              % {t('features.charts.on', 'on')}{' '}
              <NumberDrawer
                cubejsApi={cubejsApi}
                query={{
                  ...queries.denomTauxReelRT,
                  segments: transformSegmentForCube(segments, 'DenomTauxReelRT', ['intervention']),
                  timeDimensions: getTimeDimensions('DenomTauxReelRT')
                }}
                chartType="singleValue"
                errorCallback={manageError}
              />{' '}
              {t('features.charts.orders', 'orders')}
            </h3>
          </Card.Body>
        </Card>
      </Col>

      <Col className={showAdjByDep ? '' : 'd-none'} sm={12}>
        <ChartCardContainer noHeader>
          <EchartsDrawer
            cubejsApi={cubejsApi}
            query={{
              ...queries.nombreAjournementEnCours,
              segments: transformSegmentForCube(segments, 'NombreAjournementEnCours')
            }}
            chartType="bar"
            chartOptions={options.nombreAjournementEnCours}
            errorCallback={manageError}
          />
        </ChartCardContainer>
      </Col>
    </Row>
  );
};

export default NumbersAndBacklog;
