import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { loadTimeSlots, resetCalendar } from '../reducer/appointmentSlice';

import { Appointment } from '../utils/interfaces';
import Button from '@designSystem/Button';
import DatePicker from '../utils/datePicker';
import { Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../../app/hooks';
import { useTranslation } from 'react-i18next';
import { DateManager } from '../../../utils/DateManager';

const AppointmentPostponeModal: React.FC<{
  idToPostpone: number | false;
  setIdToPostpone: (_newId: number | false) => void;
  resetAlerts: () => void;
  onValidate: (_newAppt: any) => void;
}> = ({ idToPostpone, setIdToPostpone, resetAlerts, onValidate }) => {
  const dispatch = useAppDispatch();
  const { timeSlotsLoading, timeSlots, calendar } = useSelector(
    (state: RootStateOrAny) => state.appointment
  );
  const { t } = useTranslation();
  const [choosedTimeSlot, setChoosedTimeSlot] = useState<Appointment | undefined>(undefined);

  const updateTimeSlots = (month: string) => {
    if (calendar && calendar.id) {
      dispatch(
        loadTimeSlots({
          id_calendar: calendar.id,
          start_day: new DateManager(month).startOf('month').format('YYYY-MM-DD'),
          end_day: new DateManager(month).endOf('month').format('YYYY-MM-DD')
        })
      );
    }
  };

  useEffect(() => {
    if (timeSlotsLoading) {
      resetAlerts();
    }
  }, [timeSlotsLoading]);

  const reset = () => {
    dispatch(resetCalendar());
    setIdToPostpone(false);
  };

  return (
    <Modal show={!!idToPostpone || idToPostpone === 0} onHide={() => reset()}>
      <Modal.Header closeButton>
        <Modal.Title>{t('features.appointment.modal', 'Select a new date')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DatePicker
          loading={timeSlotsLoading}
          slots={timeSlots}
          updateTimeSlots={updateTimeSlots}
          chooseTimeSlot={setChoosedTimeSlot}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button design="reverse" onClick={() => reset()} style={{ float: 'right' }}>
          {t('component.button.cancel', 'Cancel')}
        </Button>
        <Button
          disabled={!choosedTimeSlot}
          onClick={() => onValidate(choosedTimeSlot)}
          data-testid="AppointmentPostponeModal-confirm"
        >
          {t('component.button.validate', 'Validate')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppointmentPostponeModal;
