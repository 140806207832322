import React, { ReactChild } from 'react';
import { disabledColor, linkColors } from './designHelper';

import { IThemedComponent } from '../designInterfaces';
import { Link } from 'react-router-dom';
import { ThemeVariants } from '../../style/theme';
import { defaultStyle } from '../Button/designStyles';
import styled from 'styled-components';

type LinkProps = {
  variant?: ThemeVariants;
  nopadding?: boolean;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  to?: { pathname: string; state: string };
  disabled?: boolean;
  children?: ReactChild;
};

interface IThemedAnchor extends IThemedComponent {
  nopadding?: boolean;
}

const styles = defaultStyle(linkColors);

const SidebarLink = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  ({ disabled, type, variant, theme, ...props }) => <Link {...props} />
)<IThemedComponent>`
  ${styles}
  background-color: ${(props) => linkColors(props)['backgroundColor']};
  color: ${(props: IThemedComponent) =>
    props.disabled ? disabledColor(props) : linkColors(props)['color']};
`;

const Anchor = styled.a<IThemedAnchor>`
  ${styles}
  background-color: ${(props) => linkColors(props)['backgroundColor']};
  color: ${(props: IThemedComponent) =>
    props.disabled ? disabledColor(props) : linkColors(props)['color']};
`;

const SpanLink = styled.span<IThemedAnchor>`
  ${styles}
  background-color: ${(props) => linkColors(props)['backgroundColor']};
  color: ${(props: IThemedComponent) =>
    props.disabled ? disabledColor(props) : linkColors(props)['color']};
`;

const LinkOrAnchor: React.FC<LinkProps> = ({
  variant = 'blue',
  nopadding = false,
  onClick,
  ...props
}) => {
  const { to } = props;
  if (to) {
    return <SidebarLink nopadding={nopadding} variant={variant} {...props} />;
  }
  if (onClick) {
    return (
      <SpanLink
        className="clickable"
        onClick={onClick}
        nopadding={nopadding}
        variant={variant}
        {...props}
      />
    );
  }
  return <Anchor nopadding={nopadding} variant={variant} {...props} />;
};

export default LinkOrAnchor;
