import styled from 'styled-components';
import theme from '@theme';

export const StyledOrderRecapConfirmModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledOrderRecapConfirmModalTitle = styled.div`
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.lg};
  line-height: ${theme.fonts.spacing.base};

  text-align: center;

  color: ${theme.palette.grey[800]};
  margin-bottom: 8px;
`;

export const StyledOrderRecapConfirmModalDescription = styled.div`
  font-weight: ${theme.fonts.weight.regular};
  font-size: ${theme.fonts.size.base};
  line-height: ${theme.fonts.spacing.base};

  text-align: center;

  color: ${theme.palette.grey[500]};
`;

export const StyledOrderRecapConfirmModalButtonContainer = styled.div`
  margin-top: 24px;
  height: 34px; // Spinner size
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const StyledOrderRecapConfirmModalSpinnerContainer = styled.div`
  margin-bottom: 24px;
  height: 34px; // Spinner size
  display: flex;
  justify-content: center;
`;
