import { Appointment, AppointmentApi, BookedAppointment } from './utils/interfaces';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  cancelAppointment,
  getAppointmentsList,
  postponeAppointment,
  resetCalendar,
  setCalendarId
} from './reducer/appointmentSlice';

import AppointmentCancelModal from './modals/AppointmentCancelModal';
import AppointmentListTable from './appointmentListTable/AppointmentListTable';
import AppointmentPostponeModal from './modals/AppointmentPostponeModal';
import { ArrowLeftCircle } from 'react-bootstrap-icons';
import Button from '@designSystem/Button';
import PageHeader from '../../components/PageHeader/PageHeader';
import { SortObject } from '@designSystem/Table/HeadSort';
import { appointmentsColumns } from './appointmentListTable/Columns';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface AppointmentListBodyProps {
  resetAlerts: () => void;
  setAppointmentAlert: (_arg: BookedAppointment | undefined) => void;
  setAppointmentPostponeAlert: (_arg: Appointment | undefined) => void;
  setAppointmentsPostponeState: (_arg: { error: boolean; success: boolean }) => void;
  setAppointmentsCancelState: (_arg: { error: boolean; success: boolean }) => void;
}

const AppointmentListBody: React.FC<AppointmentListBodyProps> = ({
  resetAlerts,
  setAppointmentAlert,
  setAppointmentPostponeAlert,
  setAppointmentsPostponeState,
  setAppointmentsCancelState
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { appointmentsList, appointmentsListTotalCount } = useSelector(
    (state: RootStateOrAny) => state.appointment
  );

  const [idToPostpone, setIdToPostpone] = useState<number | false>(false);
  const [idToCancel, setIdToCancel] = useState<number | false>(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [currentFilterAndSort, setCurrentFilterAndSort] = useState<{
    search?: string;
    sort?: SortObject;
    currentPage: number;
  }>({
    currentPage: 0,
    sort: {
      sortKey: '1',
      sortDesc: true
    }
  });

  const dispatchGetAppointmentList = () => {
    const sortObject = {
      order_by: currentFilterAndSort.sort?.sortKey || '',
      order_ascending: !currentFilterAndSort.sort?.sortDesc
    };

    dispatch(
      getAppointmentsList({
        currentPage: currentFilterAndSort.currentPage,
        sort: sortObject,
        search: currentFilterAndSort.search
      })
    );
  };

  useEffect(() => {
    resetAlerts();
    dispatchGetAppointmentList();
  }, [currentFilterAndSort]);

  useEffect(() => {
    if (currentFilterAndSort.currentPage !== currentPage) {
      setCurrentFilterAndSort({ ...currentFilterAndSort, currentPage });
    }
  }, [currentPage]);

  const columns = React.useMemo(
    () =>
      appointmentsColumns(
        (id) => {
          resetAlerts();
          setIdToCancel(id);
        },
        (id, idCalendar) => {
          resetAlerts();
          setIdToPostpone(id);
          dispatch(setCalendarId({ id: idCalendar }));
        }
      ),
    []
  );

  const onPostponeValidate = (newTimeSlot: Appointment) => {
    if (newTimeSlot && idToPostpone) {
      setAppointmentAlert(
        appointmentsList.find(
          (singleAppointment: AppointmentApi) => singleAppointment.id === idToPostpone
        )
      );
      setAppointmentPostponeAlert(newTimeSlot);
      dispatch(postponeAppointment({ id: idToPostpone, slotId: newTimeSlot.id }))
        .then(unwrapResult)
        .then(() => {
          setAppointmentsPostponeState({
            error: false,
            success: true
          });
          dispatch(resetCalendar());
          setIdToPostpone(false);
          dispatchGetAppointmentList();
        })
        .catch((rejectedValueOrSerializedError) => {
          setAppointmentsPostponeState({
            error:
              rejectedValueOrSerializedError.msg ||
              rejectedValueOrSerializedError.message ||
              rejectedValueOrSerializedError.detail,
            success: false
          });
          dispatch(resetCalendar());
          setIdToPostpone(false);
        });
    }
  };

  const onCancelValidate = (cancelReason: string) => {
    if (idToCancel) {
      setAppointmentAlert(
        appointmentsList.find(
          (singleAppointment: AppointmentApi) => singleAppointment.id === idToCancel
        )
      );
      dispatch(cancelAppointment({ id: idToCancel, reason: cancelReason || '' }))
        .then(unwrapResult)
        .then(() => {
          setAppointmentsCancelState({
            error: false,
            success: true
          });
          setIdToCancel(false);
          dispatchGetAppointmentList();
        })
        .catch((rejectedValueOrSerializedError) => {
          setAppointmentsCancelState({
            error:
              rejectedValueOrSerializedError.msg ||
              rejectedValueOrSerializedError.message ||
              rejectedValueOrSerializedError.detail,
            success: false
          });
          setIdToCancel(false);
        });
    }
  };

  const updateDataFromSearchInput = (newFilter: { search?: string; sort?: SortObject }) => {
    setCurrentFilterAndSort({ ...newFilter, currentPage: 0 });
    setCurrentPage(0);
  };

  const sortColumn = (sort?: SortObject) => {
    updateDataFromSearchInput({ search: currentFilterAndSort.search, sort });
  };

  const returnOnCalendarForm = () => {
    navigate('/erdv');
  };

  const { t } = useTranslation();
  return (
    <>
      <PageHeader
        updateData={updateDataFromSearchInput}
        currentFilterAndSort={currentFilterAndSort}
        totalRows={appointmentsListTotalCount}
        totalRowsTitle={<>{t('features.appointment.appointments', 'Appointment')}</>}
        rightContent={
          <Button
            style={{ marginLeft: '2rem' }}
            onClick={() => navigate('/erdv/appointment/create/step1')}
          >
            {t('features.appointment.newAppointment', 'New appointment')}
          </Button>
        }
      />

      <h3
        onClick={() => returnOnCalendarForm()}
        className="clickable"
        style={{ marginBottom: '24px' }}
      >
        <ArrowLeftCircle />
      </h3>
      <AppointmentListTable
        columns={columns}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        currentFilterAndSort={currentFilterAndSort}
        sortColumn={sortColumn}
      />

      <AppointmentPostponeModal
        idToPostpone={idToPostpone}
        setIdToPostpone={setIdToPostpone}
        resetAlerts={resetAlerts}
        onValidate={onPostponeValidate}
      />

      <AppointmentCancelModal
        idToCancel={idToCancel}
        setIdToCancel={setIdToCancel}
        onValidate={onCancelValidate}
      />
    </>
  );
};

export default AppointmentListBody;
