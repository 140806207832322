import React, { ReactChild } from 'react';
import { createInstance, useMatomo } from '@jonkoops/matomo-tracker-react';

import { env } from '../config';

const makeMatomoInstance = () => {
  // If we're on UK environnement, we disable tracking; otherwise, we track.
  const disableTracking = localStorage.getItem('forgetMe') === 'true';

  let siteId;
  switch (env.ENVIRONMENT) {
    case 'production':
      siteId = 1;
      break;
    case 'preprod':
      siteId = 2;
      break;
    case 'development':
      siteId = 3;
      break;
    default:
      siteId = 4;
      break;
  }

  return createInstance({
    urlBase: env.MATOMO_URL,
    siteId: siteId,
    disabled: disableTracking,
    configurations: {
      disableCookies: true
    }
  });
};

interface IMatomoCustomContext {
  matomoTrackPageView: (documentTitle: string) => void;
  matomoTrackEvent: (
    documentTitle: string | undefined,
    category: string,
    action: string,
    value?: number
  ) => void;
  matomoTrackLink: (href: string, linkType: 'download' | 'link') => void;
  matomoPushInstruction: (instructionName: string, instructionValue?: string) => void;
}

const MatomoCustomContext = React.createContext<IMatomoCustomContext>({
  matomoTrackPageView: (documentTitle) => {
    throw new Error(
      `matomoTrackPageView needs to be implemented. Document title: ${documentTitle}`
    );
  },
  matomoTrackEvent: (documentTitle, category, action, value) => {
    throw new Error(
      `matomoTrackEvent needs to be implemented. Document title: ${documentTitle}. Category: ${category}. Action: ${action}. Value: ${value}`
    );
  },
  matomoTrackLink: (href, linkType) => {
    throw new Error(
      `matomoTrackLink needs to be implemented. Href: ${href}. Link type: ${linkType}`
    );
  },
  matomoPushInstruction: (instructionName, instructionValue) => {
    throw new Error(
      `matomoPushInstruction needs to be implemented. Instruction name: ${instructionName}. Instruction value: ${instructionValue}`
    );
  }
});

function MatomoCustomProvider({ children }: { children: ReactChild[] | ReactChild }) {
  const { trackPageView, trackEvent, trackLink, pushInstruction } = useMatomo();

  const matomoTrackPageView = (documentTitle: string) => {
    if (env.ENVIRONMENT === 'development' && env.showMatomoLog) {
      console.info('matomoTrackPageView', documentTitle);
    }
    trackPageView({ documentTitle });
  };

  const matomoTrackEvent = (
    documentTitle: string | undefined,
    category: string,
    action: string,
    value?: number
  ) => {
    if (documentTitle !== undefined) {
      const actionCategory = `${documentTitle}_${category}`;

      if (env.ENVIRONMENT === 'development' && env.showMatomoLog) {
        console.info('matomoTrackEvent', actionCategory, action, value);
      }
      trackEvent({
        category: actionCategory,
        name: actionCategory,
        action,
        ...(value ? { value } : {})
      });
    }
  };

  const matomoTrackLink = (href: string, linkType: 'download' | 'link' = 'link') => {
    if (env.ENVIRONMENT === 'development') {
      console.info('trackLink', href, linkType);
    }
    trackLink({ href, linkType });
  };

  const matomoPushInstruction = (instructionName: string, instructionValue?: string) => {
    if (env.ENVIRONMENT === 'development') {
      console.info('pushInstruction', instructionName, instructionValue);
    }
    pushInstruction(instructionName, instructionValue);
  };

  return (
    <MatomoCustomContext.Provider
      value={{
        matomoTrackPageView: matomoTrackPageView,
        matomoTrackEvent: matomoTrackEvent,
        matomoTrackLink: matomoTrackLink,
        matomoPushInstruction: matomoPushInstruction
      }}
    >
      {children}
    </MatomoCustomContext.Provider>
  );
}

export { MatomoCustomContext, MatomoCustomProvider, makeMatomoInstance };
