import Card from '@designSystem/Card';
import styled from 'styled-components';
import theme from '@theme';

export const StyledOrderSection = styled.div`
  padding-left: 1px; // important for card z-index

  .section-title {
    margin-bottom: 1rem;
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};

    color: ${theme.palette.grey[500]};
  }

  .section-subtitle {
    margin-bottom: 10px;

    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.xs};
    line-height: ${theme.fonts.spacing.xs};

    text-transform: uppercase;

    color: ${theme.palette.grey[400]};
  }

  .half-width {
    max-width: 55%;
  }

  .section_warning {
    margin: 24px 0;
  }
`;

export const StyledOrderClientWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-auto-rows: max-content;
`;

export const StyledOrderSiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .section-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-column-gap: 1rem;

    .section-row-item {
      height: 100%;
      width: 100%;
      max-width: 180px;
    }
  }
`;

export const StyledOrderOutletWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .section-label-question {
    color: ${theme.palette.grey[900]};
    margin-bottom: 8px;
  }

  input + label {
    padding-left: 0.5rem;
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};
    color: ${theme.palette.grey[700]};
  }

  .no-gap {
    gap: 0;
  }
`;

export const StyledOrderComplementaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.palette.grey[200]};
  margin: 32px 0 24px 0;
`;

export const StyledModalConfirmPTOContent = styled.div`
  border: none;
  padding: 0.6rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  background-color: ${theme.palette.grey[50]};
  color: ${theme.palette.grey[500]};
`;

export const StyledOrderLabelValue = styled.div`
  font-weight: ${theme.fonts.weight.regular};
  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.sm};

  color: ${theme.palette.grey[500]};
`;

export const StyledSideContentCard = styled(Card)`
  box-shadow: none;
  border: 1px solid ${theme.palette.grey[200]};
`;
