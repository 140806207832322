import { LatLngTuple } from 'leaflet';
import { LeafletMarker } from '../interfaces';
import LeafletMarkerLayer from './LeafletMarkerLayer';
import { MapContainer } from 'react-leaflet';
import React from 'react';

type LeafletChangeViewType = {
  center: LatLngTuple | undefined;
  zoom: number;
};

interface LeafletMapProps extends LeafletChangeViewType {
  markers: LeafletMarker[];
  getMarkerTextInfos: (_i: number) => LeafletMarker;
}

const LeafletMap: React.FC<LeafletMapProps> = ({ markers, center, zoom, getMarkerTextInfos }) => {
  return (
    <MapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom={false}
      minZoom={6}
      style={{ height: 640, width: '100%', zIndex: 0 }}
    >
      <LeafletMarkerLayer
        markers={markers}
        center={center}
        zoom={zoom}
        getMarkerTextInfos={getMarkerTextInfos}
      />
    </MapContainer>
  );
};

export default LeafletMap;
