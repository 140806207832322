import * as OrderApiService from './orderAPI';

import { AnyAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  ErdvApexBookErrorsMap,
  ErdvApexErrorsMap,
  displayOrderBookToastError
} from '../utils/OrderHelper';
import {
  IGetAppointmentSlot,
  IOrderAppointmentBook,
  IOrderAppointmentGetSlotsParams,
  IOrderAppointmentSlot,
  IOrderCreationOperaBusinessParams,
  IOrderCreationParams,
  IOrderFromSteps,
  ISelectedSlot,
  Order,
  OrderPatchParams,
  OrderState
} from '../utils/orderInterfaces';

import { IGeographicAddress } from '@features/eligibility/interfaces';
import { OrderSteps } from '../utils/constants';
import { RootState } from '../../../app/store';
import toast from 'react-hot-toast';
import { Trunk } from '@features/eligibility/products/productDetail/modal/trunk.interface';

const defaultValueOrderFormSteps = {
  currentStep: 0,
  steps: [
    { enabled: false }, // 0 -> search address
    { enabled: false }, // 1 -> select address
    { enabled: false, data: null }, // 2 -> select product
    { enabled: false, data: null, errors: [] }, // 3 -> order form
    { enabled: false, data: null, errors: [] }, // 4 -> appointment
    { enabled: false, data: null, errors: [] },
    { enabled: false, data: null, errors: [] }
  ]
} as IOrderFromSteps;

const initialState: OrderState = {
  lastApiError: '',
  productToOrder: undefined,
  selectedOptions: undefined,
  orderLoading: false,
  appointmentSlots: [],
  erdvRaccoBooked: undefined,
  erdvRaccoBookedSlot: undefined,
  orderCreationError: undefined,
  orderFormSteps: defaultValueOrderFormSteps,
  order: undefined
};

/**
 * For order appointment
 */
export const loadAppointmentSlots = createAsyncThunk(
  'order/appointmentSlots',
  async (params: IOrderAppointmentGetSlotsParams, { rejectWithValue }) => {
    try {
      const { data }: { data: IGetAppointmentSlot[] } = await OrderApiService.getAppointmentSlots(
        params
      );
      return data;
    } catch (e: any) {
      if (e.response.data) {
        return rejectWithValue(e.response.data);
      }
      throw e;
    }
  }
);

export const callPlaceOrder = createAsyncThunk(
  'order/place',
  async (params: IOrderCreationParams | IOrderCreationOperaBusinessParams, { rejectWithValue }) => {
    try {
      const { data }: { data: Order } = await OrderApiService.placeOrder(params);

      return data;
    } catch (e: any) {
      if (e.response.data) {
        return rejectWithValue({ ...e.response.data, params });
      }
      throw e;
    }
  }
);

export const callGetTrunks = createAsyncThunk(
  'order/trunks',
  async (params, { rejectWithValue }) => {
    try {
      const { data }: { data: Trunk[] } = await OrderApiService.getTrunks();

      return data;
    } catch (e: any) {
      if (e.response.data) {
        return rejectWithValue({ ...e.response.data, params });
      }
      throw e;
    }
  }
);

export const callBookErdv = createAsyncThunk(
  'order/bookErdv',
  async (
    params: { orderId: string; selectedSlot: ISelectedSlot; productCode: string },
    { rejectWithValue }
  ) => {
    try {
      const { data }: { data: IOrderAppointmentBook } = await OrderApiService.bookErdv(params);
      return data;
    } catch (e: any) {
      if (e.response.data) {
        return rejectWithValue({ ...e.response.data, params });
      }
      throw e;
    }
  }
);

export const callPatchOrder = createAsyncThunk(
  'order/place',
  async (params: OrderPatchParams, { rejectWithValue }) => {
    try {
      const { data }: { data: IOrderAppointmentSlot[] } = await OrderApiService.patchOrder(params);
      return data;
    } catch (e: any) {
      if (e.response.data) {
        return rejectWithValue({ ...e.response.data, params });
      }
      throw e;
    }
  }
);

export const getOrder = createAsyncThunk(
  'order/getOrder',
  async (params: { id: string }, { rejectWithValue }) => {
    try {
      const { data }: { data: Order } = await OrderApiService.getOrder(params);
      return data;
    } catch (e: any) {
      if (e.response.data) {
        return rejectWithValue(e.response.data);
      }
      throw e;
    }
  }
);

export const getSelectProductByName = createAsyncThunk(
  'eligibility/getSelectProductByName',
  async (params: { site: IGeographicAddress; name: string }) => {
    const { data } = await OrderApiService.getProductByName(params);
    return { category: data.category, ...data.productOfferingQualificationItem[0] };
  }
);

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    enabledSteps: (state, action) => {
      state.orderFormSteps.steps = state.orderFormSteps.steps.map((step, index) => {
        return action.payload.includes(index) ? { ...step, enabled: true } : { ...step };
      });
    },
    changeStep: (state, action) => {
      state.orderFormSteps.steps[action.payload].enabled = true;
      state.orderFormSteps.currentStep = action.payload;
    },
    updateStepData: (state, action) => {
      state.orderFormSteps.steps[action.payload.step].data = action.payload.data;
      // If we precised nextStep = true, go to next step
      if (action.payload.nextStep) {
        const nextStepId = action.payload.step + 1;
        state.orderFormSteps.steps[nextStepId].enabled = true;
        state.orderFormSteps.currentStep = nextStepId;
      }
    },
    resetAllSteps: (state, action) => {
      if (action.payload.keepEnableds) {
        state.orderFormSteps.steps[OrderSteps.ORDER_INFO_FORM].data = null;
        state.orderFormSteps.steps[OrderSteps.APPOINTMENT].data = null;
        state.orderFormSteps.steps[OrderSteps.RECAP].data = null;
      } else {
        state.orderFormSteps = defaultValueOrderFormSteps;
      }
    },
    resetToStep: (state, action) => {
      state.orderFormSteps.steps = state.orderFormSteps.steps.map((step, index) => {
        return index > action.payload ? { ...step, enabled: false } : { ...step };
      });
      state.orderFormSteps.currentStep = action.payload;
    },
    selectProductToOrder: (state, action) => {
      state.productToOrder = action.payload;
    },
    selectOptionsToOrder: (state, action) => {
      state.selectedOptions = action.payload;
    },
    addSelectedOptionsToOrder: (state, action) => {
      state.selectedOptions = { ...state.selectedOptions, ...action.payload };
    },
    setErdvRaccoBookedSlot: (state, action) => {
      state.erdvRaccoBookedSlot = action.payload;
    },
    clearDraft: (state) => {
      state.order = undefined;
      state.productToOrder = undefined;
      state.selectedOptions = undefined;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(loadAppointmentSlots.pending, (state) => {
        state.orderLoading = true;
        state.appointmentSlots = [];
      })
      .addCase(loadAppointmentSlots.fulfilled, (state, action) => {
        state.orderLoading = false;
        state.appointmentSlots = action.payload;
      })
      .addCase(loadAppointmentSlots.rejected, (state, action: AnyAction) => {
        state.orderLoading = false;
        const errorMap = ErdvApexErrorsMap();
        const errorKey = action?.payload?.code || action?.payload?.error || 'default';
        const errorMessage = errorKey in errorMap ? errorMap[errorKey] : errorMap['default'];
        toast.error(errorMessage, action?.payload?.code);
      })
      .addCase(callBookErdv.pending, (state) => {
        state.orderLoading = true;
        state.erdvRaccoBooked = undefined;
        state.orderCreationError = undefined;
        if (!state.order?.id) {
          state.order = undefined;
        }
      })
      .addCase(callBookErdv.fulfilled, (state, action) => {
        state.orderLoading = false;
        state.erdvRaccoBooked = action.payload;
      })
      .addCase(callBookErdv.rejected, (state, action: AnyAction) => {
        state.orderLoading = false;
        const errorCode = action?.payload?.body.code;
        const errorMessage = ErdvApexBookErrorsMap[errorCode || 'default'];
        displayOrderBookToastError(errorMessage, errorCode);
      })
      .addCase(callPlaceOrder.pending, (state) => {
        state.orderLoading = true;
        state.orderCreationError = undefined;
        if (!state.order?.id) {
          state.order = undefined;
        }
      })
      .addCase(callPlaceOrder.fulfilled, (state, action) => {
        if (action.payload.state.status !== 'submitted') {
          state.order = action.payload;
        }
        state.orderLoading = false;
        state.erdvRaccoBooked = undefined; // reset erdv booked
        state.erdvRaccoBookedSlot = undefined; // reset erdv booked slot
        state.appointmentSlots = []; // reset erdv slots
        state.orderCreationError = undefined; // reset error
      })
      .addCase(callPlaceOrder.rejected, (state, action: AnyAction) => {
        state.orderLoading = false;
        state.orderCreationError = {
          pdcErrors: action?.payload?.errors,
          internalError: action?.payload?.code
        };
      })
      .addCase(getOrder.pending, (state) => {
        state.orderLoading = true;
        state.order = undefined;
        state.productToOrder = undefined;
        state.erdvRaccoBooked = undefined; // reset erdv booked
        state.erdvRaccoBookedSlot = undefined; // reset erdv booked slot
        state.appointmentSlots = []; // reset erdv slots
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.orderLoading = false;
        state.order = action.payload;
      })
      .addCase(getOrder.rejected, (state) => {
        state.orderLoading = false;
      })
      .addCase(getSelectProductByName.fulfilled, (state, action) => {
        state.productToOrder = action.payload;
      });
  }
});

export const {
  enabledSteps,
  changeStep,
  resetToStep,
  resetAllSteps,
  updateStepData,
  selectProductToOrder,
  selectOptionsToOrder,
  addSelectedOptionsToOrder,
  setErdvRaccoBookedSlot,
  clearDraft
} = orderSlice.actions;

export const selectOrderState = createSelector(
  (state: RootState) => state.order,
  (s) => s
);

export const selectCurrentStep = createSelector(
  selectOrderState,
  (s) => s.orderFormSteps.currentStep
);

export default orderSlice.reducer;
