import OptionsForm, { getDefaultGtrFromProduct } from '../OptionsForm';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  callGetTrunks,
  selectOptionsToOrder,
  selectProductToOrder
} from '@features/order/reducer/orderSlice';
import theme, { ThemeProps } from '@theme';
import Badge from '@designSystem/Badges';

import Button from '@designSystem/Button';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import InformationBlock from '@designSystem/InformationBlock';
import Modal from '@designSystem/Modal';
import OptionsDurationForm from '../OptionsDurationForm';
import { OrderOptions } from '@features/order/utils/orderInterfaces';
import ProductNameAndDelais from '../ProductNameAndDelais';
import { ProductOfferingQualificationItem } from '../../../interfaces';
import ProductPriceAndFees from '../ProductPriceAndFees';
import ProgressStepper from '@designSystem/ProgressStepper';
import ReactSelect, { SingleValue } from 'react-select';
import Spinner from '@designSystem/Spinner';
import StyledProductInfosDetails from '../StyledProductInfosDetails';
import { env } from '../../../../../config';
import { productsListWithInfos } from '@features/eligibility/const';
import styled from 'styled-components';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../../../app/hooks';
import { Trans, useTranslation } from 'react-i18next';
import {
  NoTrunkFound,
  StyledTitle
} from '@features/eligibility/products/productDetail/modal/trunkUtils';
import Select from 'react-select';
import { Trunk } from '@features/eligibility/products/productDetail/modal/trunk.interface';

const OptionsTitle = styled.p`
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.base};
  color: ${theme.palette.grey[500]};
  margin-bottom: 1rem;
`;

const emptyTrunks = {
  trunk: undefined,
  safetyTrunk: undefined,
  trunkOptionPlus: undefined
};

export interface SelectedTrunks {
  [key: string]: string | undefined;

  trunk?: string;
  safetyTrunk?: string;
  trunkOptionPlus?: string;
}

const ProductBusinessDetailModal: React.FC<{
  selectedProduct?: ProductOfferingQualificationItem;
  handleClose?: () => void;
  theme?: ThemeProps;
  nextStep: CallableFunction;
  selectedOptions?: OrderOptions | undefined;
  detailProductData?: ProductOfferingQualificationItem;
  loading: boolean;
}> = ({ selectedProduct, handleClose, nextStep, selectedOptions, loading, detailProductData }) => {
  const dispatch = useAppDispatch();
  const defaultGtr = selectedProduct ? getDefaultGtrFromProduct(selectedProduct) : '';
  const [optionsToOrder, setOptionsToOrder] = useState<OrderOptions | undefined>(undefined);
  const [duration, setDuration] = useState<string | undefined>(undefined);
  const [profileSelected, setProfileSelected] = useState<string | undefined>(undefined);
  const [profiles, setProfiles] = useState<{ label: string; value: string }[]>([]);
  const [trunks, setTrunks] = useState<SelectedTrunks>(emptyTrunks);
  const { t } = useTranslation();
  const [trunkList, setTrunkList] = useState<Trunk[]>([]);
  const [isTrunkLoading, setIsTrunkLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const durationList = selectedProduct ? getDurationListFromProduct(selectedProduct) : undefined;

  const getProfilesByProductId = (id: string) => {
    const productWithInfos = productsListWithInfos.find((product) => product.code === id);

    return productWithInfos?.profiles?.map((profile) => ({ label: profile, value: profile }));
  };

  useEffect(() => {
    const initialOptions = selectedOptions
      ? {
          gtr: selectedOptions.gtr || defaultGtr,
          optionPlus: selectedOptions.optionPlus,
          bronze: selectedOptions.bronze,
          eqt: selectedOptions.eqt,
          profile: selectedOptions.profile
        }
      : undefined;
    setOptionsToOrder(initialOptions);

    if (!selectedOptions?.duration) {
      setDuration('36');
    } else {
      setDuration(
        durationList?.length
          ? selectedOptions?.duration || extractMonths(durationList[0].name) || '36'
          : undefined
      );
    }

    setTrunks(selectedOptions?.trunks || emptyTrunks);
    const profiles =
      selectedProduct && getProfilesByProductId(selectedProduct?.product.productOffering.id);

    if (profiles) {
      setProfiles(profiles);
      setProfileSelected(selectedOptions?.profile || profiles.at(-1)?.value);
    }
  }, [selectedProduct, selectedOptions, detailProductData]);

  useEffect(() => {
    if (!optionsToOrder?.bronze) {
      setTrunks((prevTrunks) => ({ ...prevTrunks, safetyTrunk: undefined }));
    }
    if (!optionsToOrder?.optionPlus) {
      setTrunks((prevTrunks) => ({ ...prevTrunks, trunkOptionPlus: undefined }));
    }
  }, [optionsToOrder]);

  useEffect(() => {
    setIsTrunkLoading(true);
    dispatch(callGetTrunks())
      .then(unwrapResult)
      .then((data: Trunk[]) => {
        const trunks = data.filter(
          (trunk) =>
            trunk.maxLink > trunk.totalLinkCount && trunk.maxLink >= 0 && trunk.state !== 'Résilié'
        );
        setTrunkList(trunks);
      })
      .catch(() => setIsError(true))
      .finally(() => {
        setIsTrunkLoading(false);
      });
  }, [dispatch]);
  const [step, setStep] = useState(1);

  const validateDetailForm = () => {
    if (selectedProduct) {
      dispatch(selectProductToOrder(selectedProduct));
      const options = { ...optionsToOrder, duration, trunks, profile: profileSelected };
      dispatch(selectOptionsToOrder(options));
      nextStep(options);
    }
  };

  const isDisabled = () => {
    if (isError || loading) {
      return true;
    }
    if (step < 3) {
      // enable every next button for smaller steps
      return false;
    } else {
      // principal trunk is mandatory at last step
      // if optionPlus is enable, trunkOptionPlus is mandatory as well
      if (!trunks['trunk'] || (optionsToOrder?.optionPlus && !trunks['trunkOptionPlus'])) {
        return true;
      }
    }
    return false;
  };

  const handleNext = () => {
    if (step < 3) {
      setStep(step + 1);
    } else if (isError) {
      window.open(env.URL_ORDER_TRUNKS, '_blank', 'noopener,noreferrer');
    } else {
      validateDetailForm();
    }
  };

  const cleanData = () => {
    setOptionsToOrder(undefined);
    setDuration(undefined);
    setTrunks(emptyTrunks);
    setProfiles([]);
    setProfileSelected(undefined);
    setStep(1);
  };

  const handlePrev = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      handleClose && cleanData();
      handleClose && handleClose();
    }
  };

  const handleChange = (
    selectedOption: SingleValue<{ value: string; label: string }>,
    trunkName: string
  ) => {
    const newValue = selectedOption ? selectedOption.value : '';
    setTrunks({ ...trunks, [trunkName]: newValue });
  };

  const formatTrunk = (trunk: Trunk) => ({ value: trunk.reference, label: trunk.reference });

  const optionsTrunkFiltered = trunkList
    .filter(
      (trunk) =>
        trunk.engineeringType !== 'VLAN best effort' && trunk.reference !== trunks.safetyTrunk
    )
    .map(formatTrunk);

  const optionsSafetyTrunkFiltered = trunkList
    .filter(
      (trunk) =>
        trunk.reference !== trunks.trunk &&
        !(trunk.reference === trunks.optionPlusTrunk && trunks.trunk === trunks.optionPlusTrunk)
    )
    .map(formatTrunk);
  const optionsTrunkOptionPlusFiltered = trunkList.map(formatTrunk);

  const renderBadge = (trunkKey: string): ReactNode => {
    if (!trunks[trunkKey]) {
      return null;
    }
    const trunkSelected = trunkList.find((e) => e.reference === trunks[trunkKey]);
    return (
      <Badge style={{ gap: '3px' }}>
        <Trans
          i18nKey="features.eligibility.products.productDetail.trunk_capacity"
          defaults="<B>Capacité du tronc :</B> {{totalLinkCount}}/{{maxLink}} accès utilisés, {{speedFillingRate}} Taux d'occupation débit"
          values={{
            totalLinkCount: trunkSelected?.totalLinkCount || 0,
            maxLink: trunkSelected?.maxLink || 0,
            speedFillingRate: trunkSelected?.speedFillingRate || 0
          }}
          components={{ B: <b /> }}
        />
      </Badge>
    );
  };

  return (
    <Modal
      show={!!detailProductData}
      onClose={() => {
        handleClose && cleanData();
        handleClose && handleClose();
      }}
      width="550px"
      footer={
        <div>
          {step === 1 && handleClose && (
            <Button design="white" style={{ width: '100%' }} onClick={handlePrev}>
              {t('component.button.cancel', 'Cancel')}
            </Button>
          )}
          {step !== 1 && (
            <Button design="white" style={{ width: '100%' }} onClick={handlePrev}>
              {t('component.button.previousStep', 'Previous step')}
            </Button>
          )}
          {step < 3 && (
            <Button style={{ width: '100%' }} onClick={handleNext} disabled={isDisabled()}>
              {t('component.button.nextStep', 'Next step')}
            </Button>
          )}
          {step >= 3 && (
            <Button style={{ width: '100%' }} onClick={handleNext} disabled={isDisabled()}>
              {isError
                ? t('component.button.continue_order', 'Poursuivre ma commande')
                : t('component.button.validate', 'Validate')}
            </Button>
          )}
        </div>
      }
    >
      <>
        <ProgressStepper
          currentStep={step !== 4 ? `${step}` : '3'}
          stepList={[
            {
              key: '1',
              title: ''
            },
            {
              key: '2',
              title: ''
            },
            {
              key: '3',
              title: ''
            }
          ]}
        />
        {loading ? (
          <div style={{ marginTop: '1rem' }}>
            <Spinner spinnerSize={2} center />
          </div>
        ) : (
          <>
            {step === 1 && (
              <>
                <StyledTitle>
                  {t(
                    'features.eligibility.products.productDetail.chooseDuration',
                    'Quels caractéristiques souhaitez-vous ?'
                  )}
                </StyledTitle>
                {!!selectedProduct && (
                  <>
                    <StyledProductInfosDetails>
                      <ProductNameAndDelais product={selectedProduct} />
                      <ProductPriceAndFees product={selectedProduct} />
                    </StyledProductInfosDetails>
                    <OptionsTitle tabIndex={0}>
                      {t(
                        'features.eligibility.products.productDetail.optionDuration',
                        'Délais d’engagement disponibles'
                      )}
                    </OptionsTitle>
                    <OptionsDurationForm
                      durationToOrder={duration}
                      durationList={durationList}
                      setDuration={setDuration}
                    />
                    {profiles?.length > 1 && (
                      <div>
                        <OptionsTitle tabIndex={0}>
                          {t(
                            'features.eligibility.products.productDetail.desiredIntermediateBandwidth'
                          )}{' '}
                          :{' '}
                        </OptionsTitle>
                        <ReactSelect
                          options={profiles}
                          value={{ label: profileSelected, value: profileSelected }}
                          onChange={(val: any) => {
                            setProfileSelected(val.value);
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {step === 2 && (
              <>
                <StyledTitle>
                  {t(
                    'features.eligibility.products.productDetail.addOption',
                    'Voulez-vous ajouter une option ?'
                  )}
                </StyledTitle>
                {!!selectedProduct && (
                  <>
                    <StyledProductInfosDetails>
                      <ProductNameAndDelais product={selectedProduct} />
                      <ProductPriceAndFees product={selectedProduct} />
                    </StyledProductInfosDetails>
                    <OptionsTitle tabIndex={0}>
                      {t(
                        'features.eligibility.products.productDetail.optionDispo',
                        'Disponible options'
                      )}
                    </OptionsTitle>

                    <OptionsForm
                      selectedProduct={selectedProduct}
                      defaultOption={{
                        gtr: defaultGtr,
                        eqt: undefined,
                        bronze: undefined,
                        optionPlus: undefined
                      }}
                      optionsToOrder={optionsToOrder}
                      setOptionsToOrder={setOptionsToOrder}
                      trunkCount={trunkList.length}
                    ></OptionsForm>
                  </>
                )}
              </>
            )}
            {step === 3 && (
              <>
                {isTrunkLoading ? (
                  <div style={{ marginTop: '1rem' }}>
                    <Spinner spinnerSize={2} center />
                  </div>
                ) : (
                  <>
                    {isError || trunkList.length === 0 ? (
                      <NoTrunkFound t={t} />
                    ) : (
                      <>
                        <StyledTitle>
                          {t('features.eligibility.products.productDetail.chooseTrunk')}
                        </StyledTitle>
                        <div>
                          <div style={{ marginTop: 16 }}>
                            <OptionsTitle>
                              {t(
                                'features.eligibility.products.productDetail.optionTrunks_nominal',
                                "Tronc de l'accès nominal"
                              )}
                            </OptionsTitle>
                            <Select
                              placeholder={t('component.select.placeholder', 'Sélectionner')}
                              value={
                                optionsTrunkFiltered.find(
                                  (option) => option.value === trunks?.trunk
                                ) || null
                              }
                              onChange={(selectedOption) => handleChange(selectedOption, 'trunk')}
                              options={optionsTrunkFiltered}
                              isClearable
                              isSearchable
                            />
                            {renderBadge('trunk')}
                          </div>

                          {optionsToOrder?.bronze && (
                            <div style={{ marginTop: 16 }}>
                              <OptionsTitle>
                                {t(
                                  'features.eligibility.products.productDetail.optionTrunks_bronze'
                                )}
                              </OptionsTitle>
                              <Select
                                placeholder={t('component.select.placeholder', 'Sélectionner')}
                                value={
                                  optionsSafetyTrunkFiltered.find(
                                    (option) => option.value === trunks?.safetyTrunk
                                  ) || null
                                }
                                onChange={(selectedOption) =>
                                  handleChange(selectedOption, 'safetyTrunk')
                                }
                                options={optionsSafetyTrunkFiltered}
                                isClearable
                                isSearchable
                              />
                              {renderBadge('safetyTrunk')}
                            </div>
                          )}
                          {optionsToOrder?.optionPlus && (
                            <div style={{ marginTop: 16 }}>
                              <OptionsTitle>
                                {t('features.eligibility.products.productDetail.optionTrunks_plus')}
                              </OptionsTitle>
                              <Select
                                placeholder={t('component.select.placeholder', 'Sélectionner')}
                                value={
                                  optionsTrunkOptionPlusFiltered.find(
                                    (option) => option.value === trunks?.trunkOptionPlus
                                  ) || null
                                }
                                onChange={(selectedOption) =>
                                  handleChange(selectedOption, 'trunkOptionPlus')
                                }
                                options={optionsTrunkOptionPlusFiltered}
                                isClearable
                                isSearchable
                              />
                              {renderBadge('trunkOptionPlus')}
                            </div>
                          )}

                          <InformationBlock
                            style={{ marginTop: 16 }}
                            title={
                              <>
                                {t(
                                  'features.eligibility.products.productDetail.optionTrunks_error'
                                )}
                                <a href={env.URL_ORDER_TRUNKS}>lien</a>.
                              </>
                            }
                            type="warning"
                            customIcon={<ExclamationCircleFill />}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    </Modal>
  );
};

export default ProductBusinessDetailModal;

const getDurationListFromProduct = (product?: ProductOfferingQualificationItem) => {
  return product?.product.productPrice?.filter((i) => i.name.includes('main_activation_fee'));
};

export const extractMonths = (str: string) => {
  const match = str.match(/\d+/);
  return match ? match[0] : undefined;
};
