import { UserRole } from '../../utils/role.types';
import axios from 'axios';
import { env } from '../../config';

export const getProfile = async () => {
  return axios.get(`${env.API_URL}/me`);
};

export const postSpecifiedProfile = async (args: UserRole) => {
  const { rolesNames, username, organization } = args;
  return axios.post(
    `${env.API_URL}/profile/load`,
    {
      rolesNames,
      organization,
      username
    },
    {
      headers: { 'Content-Type': 'application/json' }
    }
  );
};

export const getDepartementAndDSP = async () => {
  return axios.get(`${env.API_URL}/cube/dspAndDepartments`);
};
