import * as ZonesGeoAPIService from './zonesGeoAPI';

import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICreateZoneGeoAPIForm, IUpdateZoneGeoAPIForm, IZoneGeoFromApi } from '../utils/interfaces';

import { displayToastErrorWithMessage } from '../../../utils/toastHelper';
import toast from 'react-hot-toast';
import { i18n } from '@i18n';

interface ZonesGeoState {
  zonesGeoCreateLoading: boolean;
  zonesGeoListLoading: boolean;
  currentZoneGeoLoading: boolean;
  currentZoneGeo: IZoneGeoFromApi | null;
  zonesGeoList: IZoneGeoFromApi[];
  zonesGeoTotalCount: number | null;
}

const initialState: ZonesGeoState = {
  zonesGeoCreateLoading: false,
  zonesGeoListLoading: false,
  currentZoneGeoLoading: false,
  currentZoneGeo: null,
  zonesGeoList: [],
  zonesGeoTotalCount: null
};

export interface SortZonesGeo {
  order_by: string;
  order_ascending: boolean;
}

export const zoneGeoCreate = createAsyncThunk(
  'zoneGeo/create',
  async (params: ICreateZoneGeoAPIForm, { rejectWithValue }) => {
    try {
      const response = await ZonesGeoAPIService.zoneGeoCreate(params);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const zoneGeoFetchById = createAsyncThunk(
  'calendar/fetchById',
  async (arg: { zoneGeoId: string }, { rejectWithValue }) => {
    try {
      const response = await ZonesGeoAPIService.zoneGeoFetchById(arg);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const zoneGeoUpdate = createAsyncThunk(
  'zoneGeo/update',
  async (params: IUpdateZoneGeoAPIForm, { rejectWithValue }) => {
    try {
      const response = await ZonesGeoAPIService.zoneGeoUpdate({
        name: params.name,
        dsp: params.dsp,
        type: params.type,
        contract_delay: params.contract_delay,
        zoneGeoId: parseInt(params.zoneGeoId, 10)
      });
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const zoneGeoDelete = createAsyncThunk(
  'zoneGeo/delete',
  async (params: { id: number }, { rejectWithValue }) => {
    try {
      const response = await ZonesGeoAPIService.zoneGeodelete(params.id);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const zoneGeoFetchList = createAsyncThunk(
  'zoneGeo/fetch',
  async (
    arg: {
      currentPage: number;
      sort?: SortZonesGeo;
      search?: string;
      rowsPerPage?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await ZonesGeoAPIService.zoneGeoFetchList(arg);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

const zoneGeoSlice = createSlice({
  name: 'zonesGeo',
  initialState,
  reducers: {
    resetCurrentZoneGeo: (state) => {
      state.currentZoneGeo = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(zoneGeoCreate.pending, (state) => {
        state.zonesGeoCreateLoading = true;
      })
      .addCase(zoneGeoCreate.fulfilled, (state) => {
        state.zonesGeoCreateLoading = false;
        toast.success(
          i18n.t(
            'features.zonesGeo.reducer.geographicCreate',
            `Geographic zone created successfully`
          )
        );
      })
      .addCase(zoneGeoCreate.rejected, (state, action: AnyAction) => {
        state.zonesGeoCreateLoading = false;
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          `${i18n.t('errorMessages.errorHappen', `An error happened`)} ${errorMessage}`
        );
      })
      .addCase(zoneGeoUpdate.fulfilled, () => {
        toast.success(
          i18n.t(
            'features.zonesGeo.reducer.geographicUpdate',
            `Geographic zone updated successfully`
          )
        );
      })
      .addCase(zoneGeoUpdate.rejected, (state, action: AnyAction) => {
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          `${i18n.t('errorMessages.errorHappen', `An error happened`)} ${errorMessage}`
        );
      })
      .addCase(zoneGeoFetchById.pending, (state) => {
        state.currentZoneGeoLoading = true;
      })
      .addCase(zoneGeoFetchById.fulfilled, (state, action: AnyAction) => {
        state.currentZoneGeoLoading = false;
        state.currentZoneGeo = action.payload;
      })
      .addCase(zoneGeoFetchById.rejected, (state, action: AnyAction) => {
        state.currentZoneGeoLoading = false;
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          `${i18n.t('errorMessages.errorHappen', `An error happened`)} ${errorMessage}`
        );
      })
      .addCase(zoneGeoDelete.fulfilled, () => {
        toast.success(
          i18n.t(
            'features.zonesGeo.reducer.geographicDeleted',
            `Geographic zone deleted successfully`
          )
        );
      })
      .addCase(zoneGeoDelete.rejected, (state, action: AnyAction) => {
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          `${i18n.t('errorMessages.errorHappen', `An error happened`)} ${errorMessage}`
        );
      })
      .addCase(zoneGeoFetchList.pending, (state) => {
        state.zonesGeoListLoading = true;
      })
      .addCase(zoneGeoFetchList.fulfilled, (state, action: AnyAction) => {
        state.zonesGeoListLoading = false;
        state.zonesGeoTotalCount = action.payload.total_count;
        state.zonesGeoList = action.payload.zonegeos; // Named zonegeos instead of zonesgeo in API
      })
      .addCase(zoneGeoFetchList.rejected, (state, action: AnyAction) => {
        state.zonesGeoListLoading = false;
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          `${i18n.t('errorMessages.errorHappen', `An error happened`)} ${errorMessage}`
        );
      });
  }
});

export const { resetCurrentZoneGeo } = zoneGeoSlice.actions;

export default zoneGeoSlice.reducer;
