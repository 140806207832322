import { StyledOrderComplementaryWrapper, StyledOrderSection } from '../utils/OrderStyles';

import {
  IOrderInformationsForm,
  IOrderInformationsOperaBusinessForm
} from '../utils/orderInterfaces';
import InputWrapper from '@designSystem/InputWrapper';
import { getErrorMessage } from '../../../utils/formHelper';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isIOrderInformationsOperaBusinessForm } from '@features/order/orderInformations/OrderInformationsHelper';

const OrderInformationsComplementaryForm = ({
  orderInformationsFormValues
}: {
  orderInformationsFormValues: IOrderInformationsForm | IOrderInformationsOperaBusinessForm;
}) => {
  const { register, formState } = useFormContext();
  const { t } = useTranslation();

  const isOperaBusinessProduct = isIOrderInformationsOperaBusinessForm(orderInformationsFormValues);
  const referenceCharLimit = isOperaBusinessProduct ? 100 : 30;
  return (
    <StyledOrderSection>
      <div className="section-title">
        {t('features.order.orderInformations.complementaryInfo', 'Complementary informations')}
      </div>
      <StyledOrderComplementaryWrapper>
        <div className="half-width">
          <InputWrapper
            inputLabel={
              <>
                {t(
                  'features.order.orderInformations.internalOrder',
                  'Your internal order reference'
                )}
              </>
            }
            error={getErrorMessage('internReference', formState)}
          >
            <input
              type="text"
              id="internReference"
              data-testid="order-input-internReference"
              {...register('internReference', {
                setValueAs: (v) => v?.trimStart(),
                maxLength: {
                  value: referenceCharLimit,
                  message: t(
                    'errorMessages.tooManyCharactersEntered',
                    `Maximum number of ${referenceCharLimit} characters exceeded`,
                    {
                      defaultValue: '{{count}}',
                      count: referenceCharLimit
                    }
                  )
                },
                required: { value: true, message: t('errorMessages.valueMissing', `Missing value`) }
              })}
              placeholder={t(
                'features.order.orderInformations.internalRef',
                `Your internal order reference`
              )}
              value={orderInformationsFormValues.internReference}
              aria-label="internReference-input"
            />
          </InputWrapper>
        </div>
        {isOperaBusinessProduct ? (
          <InputWrapper
            inputLabel={
              <>
                {t('features.order.orderInformations.project_name', `Project name`)}{' '}
                <div className="optional">
                  {t('features.order.orderInformations.optional', `Optional`)}
                </div>
              </>
            }
            error={getErrorMessage('projectName', formState)}
          >
            <input
              type="text"
              id="projectName"
              {...register('projectName', {
                setValueAs: (v) => v?.trimStart(),
                maxLength: {
                  value: 100,
                  message: t(
                    'errorMessages.tooManyCharactersEntered',
                    'Maximum number of 100 characters exceeded',
                    {
                      defaultValue: '{{count}}',
                      count: 100
                    }
                  )
                },
                required: {
                  value: false,
                  message: t('errorMessages.valueMissing', `Missing value`)
                }
              })}
              value={orderInformationsFormValues.projectName}
              aria-label="projectName-input"
            />
          </InputWrapper>
        ) : null}
        <div>
          <InputWrapper
            inputLabel={
              <>
                {t('features.order.orderInformations.comments', 'Comments')}{' '}
                <div className="optional">
                  {t('features.order.orderInformations.optional', `Optional`)}
                </div>
              </>
            }
            error={getErrorMessage('comments', formState)}
          >
            <textarea
              {...register('comments', {
                maxLength: {
                  value: 200,
                  message: t(
                    'errorMessages.tooManyCharactersEntered',
                    'Maximum number of 200 characters exceeded',
                    {
                      defaultValue: '{{count}}',
                      count: 200
                    }
                  )
                }
              })}
              id="comments"
              value={orderInformationsFormValues.comments}
              placeholder={t('features.order.orderInformations.comments', `Comments`)}
              aria-label="comments-input"
              style={{ minHeight: 83 }}
            />
          </InputWrapper>
        </div>
      </StyledOrderComplementaryWrapper>
    </StyledOrderSection>
  );
};

export default OrderInformationsComplementaryForm;
