import {
  IBadgeWithLabelProps,
  selectNavigationState,
  setBreadCrumbBadge
} from './navigationReducer';
import React, { ReactChild, useEffect } from 'react';

import Badge from '@designSystem/Badges';
import { ChevronLeft } from 'react-bootstrap-icons';
import styled from 'styled-components';
import theme from '@theme';
import { useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface IBreadCrumbsProps {
  title?: ReactChild;
  returnTitle?: ReactChild;
  returnPath?: string;
}

const BreadCrumbs: React.FC<IBreadCrumbsProps> = ({ title = '', returnTitle, returnPath }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const badgeInfos = useSelector(selectNavigationState).badge;

  useEffect(() => {
    if (badgeInfos !== null) {
      dispatch(setBreadCrumbBadge(null));
    }
  }, [title]);

  const constructTitleForPreviousPath = () => {
    if (returnPath) {
      return (
        <StyledBreadCrumbsPreviousContainer
          onClick={() => {
            navigate(returnPath);
          }}
        >
          <StyledBreadCrumbsIcon>
            <ChevronLeft size={12} color={theme.palette.blue[500]} />
          </StyledBreadCrumbsIcon>
          {returnTitle && (
            <>
              <StyledBreadCrumbsPreviousPage>{returnTitle}</StyledBreadCrumbsPreviousPage>
              <StyledBreadCrumbsSeparator> /</StyledBreadCrumbsSeparator>
            </>
          )}
        </StyledBreadCrumbsPreviousContainer>
      );
    }
    return null;
  };

  return (
    <StyledBreadCrumbsContainer>
      {constructTitleForPreviousPath()}
      {title}
      {badgeInfos !== null && displayBadgeIfExisting(badgeInfos)}
    </StyledBreadCrumbsContainer>
  );
};

export default BreadCrumbs;

const displayBadgeIfExisting = (newBadge: IBadgeWithLabelProps) => {
  return (
    <Badge className="breadcrumbs_badge" variant={newBadge.variant}>
      {newBadge.content}
    </Badge>
  );
};

const StyledBreadCrumbsContainer = styled.div`
  display: flex;
  flex-direction: row;

  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.sm};
  color: ${theme.palette.grey[800]};

  margin-right: 24px;

  /**/
  color: rgb(55 65 81, 1);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  /**/

  .breadcrumbs_badge {
    margin-left: 16px;
  }
`;

const StyledBreadCrumbsPreviousContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 12px;

  cursor: pointer;
`;

const StyledBreadCrumbsIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

const StyledBreadCrumbsPreviousPage = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${theme.fonts.weight.regular};
  font-size: ${theme.fonts.size.base};
  line-height: 20px;

  color: ${theme.palette.grey[400]};
  margin-right: 12px;
`;

const StyledBreadCrumbsSeparator = styled.div`
  display: flex;
  align-items: center;

  font-weight: ${theme.fonts.weight.regular};
  font-size: ${theme.fonts.size.base};
  line-height: 20px;

  color: ${theme.palette.grey[400]};
`;
