import { IThemedBadge, badgeColors, disabledColor } from './designHelper';
import React, { ReactChild } from 'react';
import { crossStyle, defaultStyle, leftContentStyle } from './designStyles';

import { ThemeVariants } from '../../style/theme';
import { X } from 'react-bootstrap-icons';
import styled from 'styled-components';

interface BadgeProps extends React.HTMLProps<HTMLElement> {
  variant?: ThemeVariants;
  disabled?: boolean;
  leftContent?: ReactChild;
  leftAction?: CallableFunction;
  design?: 'small' | 'big';
}

const StyledBadge = styled.span<IThemedBadge>`
  ${defaultStyle(badgeColors)}
  background-color: ${(props) =>
    props.disabled
      ? disabledColor(props)['backgroundColor']
      : badgeColors(props)['backgroundColor']};
  color: ${(props: IThemedBadge) =>
    props.disabled ? disabledColor(props)['color'] : badgeColors(props)['color']};

  &:hover {
    background-color: ${(props: IThemedBadge) =>
      props.leftContent && !props.disabled
        ? badgeColors(props)['hoverBackgroundColor']
        : badgeColors(props)['backgroundColor']};
  }
`;

const StyledCross = styled.span<IThemedBadge>`
  ${crossStyle(badgeColors)}
`;

const StyledLeftContent = styled.span<IThemedBadge>`
  ${leftContentStyle(badgeColors)}
`;

const Badge: React.FC<BadgeProps> = ({
  variant = 'blue',
  design = 'small',
  leftContent,
  leftAction,
  onClick,
  ref,
  as,
  ...props
}) => {
  return (
    <StyledBadge
      design={design}
      variant={variant}
      hasOnClick={!!onClick}
      leftContent={!!leftContent}
      leftAction={!!leftAction}
      {...props}
    >
      {leftContent && (
        <StyledLeftContent
          leftContent={!!leftContent}
          leftAction={!!leftAction}
          variant={variant}
          onClick={leftAction && leftAction()}
          {...props}
        >
          {leftContent}
        </StyledLeftContent>
      )}
      {props.children}
      {onClick && (
        <StyledCross
          design={design}
          variant={variant}
          leftContent={!!leftContent}
          onClick={onClick}
          {...props}
        >
          <X />
        </StyledCross>
      )}
    </StyledBadge>
  );
};

export default Badge;
