import * as orderApiService from './siteStatusAPI';

import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';
import { SiteStatusState } from '../utils/siteStatusInterfaces';

const initialState: SiteStatusState = {
  lastApiError: '',
  siteStatusListLoading: false,
  siteStatusList: [],
  siteStatusTotalCount: 0,
  buildingDetails: undefined,
  buildingDetailsLoading: false,
  siteStatusDetail: undefined,
  siteStatusDetailLoading: false,
  siteStatusTickets: undefined,
  siteStatusTicketsLoading: false
};

export const loadOrders = createAsyncThunk(
  'orders/loadOrders',
  async (
    params: {
      idBuilding: string;
      currentPage: number;
      pageSize: number;
      sortKey: string | null;
      sortDesc: string;
    },
    { dispatch }
  ) => {
    const { idBuilding, currentPage, pageSize, sortKey, sortDesc } = params;
    dispatch(setAPIError(null));
    try {
      const response = await orderApiService.getSiteStatus(
        idBuilding,
        currentPage,
        pageSize,
        sortKey,
        sortDesc
      );
      return response.data;
    } catch (e) {
      dispatch(setAPIError((e as Error).message));
      throw e;
    }
  }
);

export const getSiteStatusTickets = createAsyncThunk(
  'orders/getSiteStatusTickets',
  async (
    params: { refPrestationPrise: string; currentPage: number; pageSize: number },
    { dispatch }
  ) => {
    const { refPrestationPrise, currentPage, pageSize } = params;
    dispatch(setAPIError(null));
    try {
      const response = await orderApiService.getSiteStatusTickets(
        refPrestationPrise,
        currentPage,
        pageSize
      );
      return response.data;
    } catch (e) {
      dispatch(setAPIError((e as Error).message));
      throw e;
    }
  }
);

export const getSiteStatusDetail = createAsyncThunk(
  'orders/getSiteStatusDetail',
  async (params: { orderId: string }, { dispatch }) => {
    const { orderId } = params;
    dispatch(setAPIError(null));
    try {
      const response = await orderApiService.getSiteStatusDetails(orderId);
      return response.data;
    } catch (e) {
      dispatch(setAPIError((e as Error).message));
      throw e;
    }
  }
);

export const getBuildingDetails = createAsyncThunk(
  'orders/getBuildingDetails',
  async (idBuilding: string, { dispatch }) => {
    dispatch(setAPIError(null));
    try {
      const response = await orderApiService.getBuildingDetails(idBuilding);
      return response.data;
    } catch (e) {
      dispatch(setAPIError((e as Error).message));
      throw e;
    }
  }
);

const siteStatusSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setBuildingDetails: (state, action) => {
      state.buildingDetails = action.payload;
    },
    setAPIError: (state, action) => {
      state.lastApiError = action.payload;
    },
    resetBuildingDetails: (state) => {
      state.buildingDetails = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadOrders.pending, (state) => {
        state.siteStatusListLoading = true;
      })
      .addCase(loadOrders.fulfilled, (state, { payload }) => {
        state.siteStatusListLoading = false;
        state.siteStatusList = payload.data.ftth_src_t_ftth_cmde_acces_v2;
        state.siteStatusTotalCount =
          payload.data.ftth_src_t_ftth_cmde_acces_v2_aggregate.aggregate.totalCount;
      })
      .addCase(loadOrders.rejected, (state, action) => {
        state.siteStatusListLoading = false;
        state.lastApiError = action.error.message;
      })
      .addCase(getBuildingDetails.pending, (state) => {
        state.buildingDetailsLoading = true;
      })
      .addCase(getBuildingDetails.fulfilled, (state, { payload }) => {
        state.buildingDetails = payload.data.charlie_src_t_ipe_line[0] || null;
        state.buildingDetailsLoading = false;
      })
      .addCase(getBuildingDetails.rejected, (state, action) => {
        state.buildingDetailsLoading = false;
        state.lastApiError = action.error.message;
      })
      .addCase(getSiteStatusDetail.pending, (state) => {
        state.siteStatusDetailLoading = true;
      })
      .addCase(getSiteStatusDetail.fulfilled, (state, { payload }) => {
        state.siteStatusDetail = payload.ftth_src_t_ftth_cmde_acces_v2[0];
        state.siteStatusDetailLoading = false;
      })
      .addCase(getSiteStatusDetail.rejected, (state, action) => {
        state.siteStatusDetailLoading = false;
        state.lastApiError = action.error.message;
      })
      .addCase(getSiteStatusTickets.pending, (state) => {
        state.siteStatusTicketsLoading = true;
      })
      .addCase(getSiteStatusTickets.fulfilled, (state, { payload }) => {
        state.siteStatusTickets = payload || [];
        state.siteStatusTicketsLoading = false;
      })
      .addCase(getSiteStatusTickets.rejected, (state, action) => {
        state.siteStatusTicketsLoading = false;
        state.lastApiError = action.error.message;
      });
  }
});

export const { setBuildingDetails, resetBuildingDetails, setAPIError } = siteStatusSlice.actions;

export const selectSiteStatusState = createSelector(
  (state: RootState) => state.siteStatus,
  (s) => s
);

export default siteStatusSlice.reducer;
