import './style/styles.scss';
import 'react-day-picker/dist/style.css';
import '@i18n';

import * as serviceWorker from './serviceWorker';
import { env } from './config';

import { MatomoCustomProvider, makeMatomoInstance } from './utils/MatomoCustomProvider';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ErrorMonitoring } from './utils/ErrorMonitoring';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { initKeycloak } from './app/AuthService';
import { store } from './app/store';
import * as Sentry from '@sentry/browser';

import { BrowserOptions } from '@sentry/browser';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

const matomoInstance = makeMatomoInstance();
const errorMonitoring = new ErrorMonitoring();
const defaultOptions: BrowserOptions = {
  dsn: `https://${env.SENTRY_KEY_FRONT}@sentry.axione.fr/41`,
  integrations: [],
  environment: env.ENVIRONMENT,
  tracesSampleRate: 1.0,
  release: env.DOCKER_TAG || 'unknown-version'
};

Sentry.init(defaultOptions);
errorMonitoring.init();

const renderApp = () => {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          {matomoInstance ? (
            <MatomoProvider value={matomoInstance}>
              <MatomoCustomProvider>
                <App />
              </MatomoCustomProvider>
            </MatomoProvider>
          ) : (
            <MatomoCustomProvider>
              <App />
            </MatomoCustomProvider>
          )}
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
};
initKeycloak(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
