import {
  ICatalogSolution,
  IGeographicAddress,
  IOfferingResult,
  IVerticalityInfos,
  OrderTypes,
  ProductError,
  ProductOfferingItem,
  ProductOfferingQualification,
  ProductOfferingQualificationItem
} from './interfaces';

import axios from 'axios';
import { env } from '../../config';

export function getCities(postcode: string) {
  return axios.get(`${env.API_URL}/neli/city`, { params: { postcode } });
}

export function getStreets(query: { postcode: string; city: string }) {
  return axios.get(`${env.API_URL}/neli/street`, {
    params: { postcode: query.postcode, city: query.city }
  });
}

export function getStreetNumbers(query: { postcode: string; city: string; street: string }) {
  return axios.get(`${env.API_URL}/neli/streetNumber`, {
    params: { postcode: query.postcode, city: query.city, street: query.street }
  });
}

export function getOrganizations(siret: string) {
  return axios.get(`${env.API_URL}/neli/organization`, { params: { siret } });
}

export function getGeographicAddresses(urlParams: string) {
  return axios.get(`${env.API_URL}/neli/geographicAddress?${urlParams}`);
}

const getSolutions = async (): Promise<ICatalogSolution[]> => {
  return axios.get(`${env.API_URL}/neli/category?type=gamme`).then((res) => {
    const categories = res.data;
    // categories is a object {catId: {...catData}, catId: {...catData}, etc} not an array
    return Object.keys(categories).map((key: string) => {
      const { id, description, name, parentId } = categories[key];
      return {
        category: 'OPERA',
        id,
        description,
        name,
        parentId
      };
    });
  });
};

export const getProducts = async (site: IGeographicAddress) => {
  let solutions: ICatalogSolution[] = [];
  const allDatas: IOfferingResult[] = [];
  const errors: ProductError[] = [];

  try {
    solutions = await getSolutions();
  } catch (e) {
    errors.push({ solution: 'OPERA', message: (e as Error).message });
    return { data: allDatas, errors: errors };
  }

  return Promise.allSettled(
    solutions.map(async (solution: ICatalogSolution) => {
      const res = await axios.post(
        `${env.API_URL}/neli/productOfferingQualification`,
        {
          category: solution,
          productOfferingQualificationItem: [{ id: '1' }],
          provideUnavailabilityReason: true,
          place: [
            {
              ...site,
              role: 'installation address'
            }
          ]
        },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
      return { ...res.data };
    })
  ).then((responseArr) => {
    responseArr.forEach((res, index) => {
      if (res.status === 'fulfilled') {
        const dataFetched: ProductOfferingQualification = res.value;

        dataFetched.productOfferingQualificationItem.forEach(
          (item: ProductOfferingQualificationItem) => {
            if (
              !item.eligibilityUnavailabilityReason ||
              ['AVAILABLE_SOON', 'FROZEN_NETWORK'].includes(
                item.eligibilityUnavailabilityReason[0].code
              )
            ) {
              allDatas.push({
                category: dataFetched.category,
                ...item
              });
            }
          }
        );
      } else if (res.status === 'rejected') {
        errors.push({
          solution: solutions[index].description,
          message: res.reason.message
        });
      }
    });
    return { data: allDatas, errors: errors.length > 0 ? errors : null };
  });
};

export const orderProduct = async (
  type: OrderTypes,
  product: ProductOfferingItem,
  address: IGeographicAddress,
  verticalityInfos: IVerticalityInfos,
  existingPlug?: boolean,
  multiAccess?: boolean
) => {
  return axios.post(
    `${env.API_URL}/neli/${type}`,
    {
      product,
      address,
      existingPlug,
      multiAccess,
      ...verticalityInfos
    },
    {
      headers: { 'Content-Type': 'application/json' }
    }
  );
};

export const orderVerticality = async (address: IGeographicAddress) => {
  return axios.get(`${env.API_URL}/neli/verticality`, {
    params: { geographic_address_id: address.id }
  });
};
