import { Control, Controller } from 'react-hook-form';
import { StyledOrderOutletWrapper, StyledOrderSection } from '../utils/OrderStyles';

import {
  IOrderInformationsForm,
  IOrderInformationsOperaBusinessForm
} from '../utils/orderInterfaces';
import OrderInformationsOutletFormOptions from './OrderInformationsOutletFormOptions';
import { StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import { useTranslation } from 'react-i18next';

const OrderInformationsOutletForm = ({
  orderInformationsFormValues
}: {
  orderInformationsFormValues: IOrderInformationsForm;
  control: Control<IOrderInformationsForm> | Control<IOrderInformationsOperaBusinessForm>;
}) => {
  const { t } = useTranslation();
  return (
    <StyledOrderSection>
      <div className="section-title">{t('features.order.orderInformations.outlet', 'Outlet')}</div>
      <StyledOrderOutletWrapper>
        <Controller
          name={'existingOutlet'}
          render={({ field: { onChange } }) => (
            <div className="no-gap">
              <StyledLabel className="section-label-question">
                {t(
                  'features.order.orderInformations.existingOutlet',
                  'Is there an existing outlet on site ?'
                )}
              </StyledLabel>
              <div className="radio-container">
                <input
                  onChange={() => onChange(true)}
                  type="radio"
                  id="existingOutlet_true"
                  name="existingOutlet_true"
                  value="true"
                  checked={orderInformationsFormValues.existingOutlet}
                />
                <label htmlFor="existingOutlet_true">{t('app.yes', 'Yes')}</label>
              </div>
              <div className="radio-container">
                <input
                  onChange={() => onChange(false)}
                  type="radio"
                  id="existingOutlet_false"
                  name="existingOutlet"
                  value="false"
                  checked={!orderInformationsFormValues.existingOutlet}
                />
                <label htmlFor="existingOutlet_false">{t('app.no', 'No')}</label>
              </div>
            </div>
          )}
        />
        {orderInformationsFormValues.existingOutlet && (
          <OrderInformationsOutletFormOptions
            orderInformationsFormValues={orderInformationsFormValues}
          />
        )}
      </StyledOrderOutletWrapper>
    </StyledOrderSection>
  );
};

export default OrderInformationsOutletForm;
