import {
  IOrderAppointmentGetSlotsParams,
  IOrderCreationOperaBusinessParams,
  IOrderCreationParams,
  ISelectedSlot,
  OrderPatchParams
} from '../utils/orderInterfaces';
import {
  displayToastErrorWithMessage,
  displayToastSuccessWithMessage
} from '../../../utils/toastHelper';

import { IGeographicAddress } from '@features/eligibility/interfaces';
import axios from 'axios';
import { env } from '../../../config';
import { i18n } from '@i18n';

export const getAppointmentSlots = async (params: IOrderAppointmentGetSlotsParams) => {
  const { start_day, end_day, intervention_type, reference, product, companyName, eligibilityId } =
    params;
  return axios.get(
    `${env.API_URL}/appointment/slots?intervention_type=${intervention_type}&start_day=${start_day}&end_day=${end_day}&reference=${reference}&eligibilityId=${eligibilityId}&product=${product}&companyName=${companyName}`
  );
};
export const bookErdv = async (params: {
  orderId?: string;
  selectedSlot: ISelectedSlot;
  productCode: string;
}) => {
  const { orderId, selectedSlot, productCode } = params;

  return axios.post(`${env.API_URL}/appointment/book`, { orderId, selectedSlot, productCode });
};

type SelectedSlotType =
  | ISelectedSlot
  | { date: Date; time: string }[]
  | { date: string; time: string; slotId: string; moment: string };

export interface IReportErdv {
  orderId?: string;
  selectedSlot: SelectedSlotType;
  isHomeBasic?: boolean;
}
export const reportErdv = async (params: IReportErdv) => {
  const { orderId, selectedSlot } = params;
  return axios.post(`${env.API_URL}/appointment/report`, {
    orderId,
    selectedSlot: Array.isArray(selectedSlot) ? selectedSlot : [selectedSlot]
  });
};

export const placeOrder = async (
  params: IOrderCreationParams | IOrderCreationOperaBusinessParams
) => {
  return axios.post(`${env.API_URL}/order/orders`, params);
};

interface QueryOrdersPaginate {
  page: string;
  state?: string;
  state_not?: string;
  search?: string;
  from?: string;
  to?: string;
}
export const getOrdersPaginate = (query: QueryOrdersPaginate) => {
  const queryString = '?' + new URLSearchParams({ ...query }).toString();
  return axios.get(`${env.API_URL}/order/orders${queryString}`);
};

export const deleteOrderById = async (id: string) => {
  try {
    await axios.delete(`${env.API_URL}/order/orders/${id}`);
    displayToastSuccessWithMessage(
      i18n.t(
        'features.order.reducer.deletionYourDraft',
        `Draft deleted \n
      The deletion of your draft has been taken into account
      `
      )
    );
    return true;
  } catch (e) {
    displayToastErrorWithMessage(
      i18n.t(
        'features.order.reducer.somethingWrong',
        `Something went wrong \n
      It is not possible to delete this draft
      `
      )
    );
    return false;
  }
};

export const patchOrder = async (params: OrderPatchParams) => {
  return axios.patch(`${env.API_URL}/order/orders/${params.id}`, params);
};

export const getProductByName = async (params: { site: IGeographicAddress; name: string }) => {
  return await axios.post(
    `${env.API_URL}/neli/productOfferingQualification`,
    {
      category: {
        id: params.name
      },
      productOfferingQualificationItem: [{ id: '1' }],
      provideUnavailabilityReason: true,
      place: [
        {
          ...params.site,
          role: 'installation address'
        }
      ]
    },
    {
      headers: { 'Content-Type': 'application/json' }
    }
  );
};

export const getOrder = async (params: { id: string }) => {
  return axios.get(`${env.API_URL}/order/orders/${params.id}`);
};

export const getTrunks = async () => {
  return axios.get(`${env.API_URL}/order/trunks`);
};
