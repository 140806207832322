import { EyeFill, EyeSlashFill, QuestionCircle } from 'react-bootstrap-icons';
import React, { useState } from 'react';

import Button from '@designSystem/Button';
import { Modal } from 'react-bootstrap';
import { typesInterventionList } from '../filters/delegantsFiltersInfos';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';

const LeafletLegendButton: React.FC<{
  setShowLegend: (_arg: boolean) => void;
  showLegend: boolean;
}> = ({ setShowLegend, showLegend }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const trackEvent = useMatomoHarmonizer();

  return (
    <>
      <div
        className="justify-content-center"
        style={{
          display: 'flex',
          maxHeight: 30,
          alignItems: 'center'
        }}
      >
        <Button
          className="mr-3"
          style={{
            display: 'flex',
            position: 'relative'
          }}
          onClick={() => {
            trackEvent({ page: 'Ajournement', category: 'voir_légende', actionType: 'Clic' });
            setShowLegend(!showLegend);
          }}
        >
          <>
            {t('features.charts.leaflet.legend', 'Legend')}
            <div style={{ cursor: 'pointer' }}>{showLegend ? <EyeFill /> : <EyeSlashFill />}</div>
          </>
        </Button>
        <QuestionCircle
          role="button"
          className="ms-3 text-primary"
          size={24}
          onClick={handleShow}
        />
      </div>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('features.charts.leaflet.info', 'Informations complémentaires')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {typesInterventionList
              .filter((e) => e.definition)
              .map((li, i) => {
                return (
                  <li key={i}>
                    <b>{li.displayName} : </b>
                    {li.definition}
                    <br />
                  </li>
                );
              })}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeafletLegendButton;
