import { t } from 'i18next';

export const EscalationTrad: () => { [key: string]: string } = () => {
  return {
    'Niveau 1': t(`escalate.db.level1`, 'Niveau 1'),
    'Niveau 2': t(`escalate.db.level2`, 'Niveau 2'),
    'Niveau 3': t(`escalate.db.level3`, 'Niveau 3'),
    'Niveau 4': t(`escalate.db.level4`, 'Niveau 4'),
    'Niveau 5': t(`escalate.db.level5`, 'Niveau 5'),
    'Niveau 1 (GTR +100%)': t(`escalate.db.level1GTR`, 'Niveau 1 (GTR +100%)'),
    'Niveau 2 (GTR +200%)': t(`escalate.db.level2GTR`, 'Niveau 2 (GTR +200%)'),
    'Niveau 3 (GTR +300%)': t(`escalate.db.level3GTR`, 'Niveau 3 (GTR +300%))'),
    'Niveau 4 (GTR +400%)': t(`escalate.db.level4GTR`, 'Niveau 4 (GTR +400%)'),
    "Point d'entrée": t(`escalate.db.levelEntry`, "Point d'entrée"),

    'Jours ouvrés de 9h à 17h': t(`escalate.db.day9hTo17h`, 'Jours ouvrés de 9h à 17h'),
    'Jours ouvrés 08h00 – 18h00': t(`escalate.db.day8hTo18h`, 'Jours ouvrés 08h00 – 18h00'),
    'Jours et heures non ouvrés': t(`escalate.db.dayNoWork`, 'Jours et heures non ouvrés'),

    'Responsable Service Clients': t(`escalate.db.respoClients`, 'Responsable Service Clients'),

    'Contacts Administratifs': t(`escalate.db.ContactsAdmin`, 'Contacts Administratifs'),
    'Gestion des commandes': t(`escalate.db.GestionCommandes`, 'Gestion des commandes'),
    Facturation: t(`escalate.db.Facturation`, 'Facturation'),
    SAV: t(`escalate.db.SAV`, 'SAV'),
    'Offres Entreprise': t(`escalate.db.OffresEntreprise`, 'Offres Entreprise'),
    'Offres Grand Public': t(`escalate.db.OffresGP`, 'Offres Grand Public'),
    'Opérations de Maintenance': t(
      `escalate.db.OperationsMaintenance`,
      'Opérations de Maintenance'
    ),
    'Programmés ou Curatives': t(`escalate.db.ProgrammesCuratives`, 'Programmés ou Curatives'),
    'Applications Axione': t(`escalate.db.ApplicationsAxione`, 'Applications Axione')
  };
};
