import { Appointment, BookedAppointment } from '@features/appointment/utils/interfaces';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import AppointmentListAlerts from '@features/appointment/AppointmentListAlerts';
import AppointmentListBody from '@features/appointment/AppointmentListBody';
import AppointmentToast from '@features/appointment/AppointmentToast';
import { Trans } from 'react-i18next';
import { i18n } from '@i18n';
import { resetAppointmentData } from '@features/appointment/reducer/appointmentSlice';
import { timeSlotsNames } from '@features/appointment/utils/helper';
import toast from 'react-hot-toast';
import { useAppDispatch } from '../../app/hooks';
import { DateManager } from '../../utils/DateManager';

const defaultCancelState = {
  error: false,
  success: false
};
const defaultPostponeState = {
  error: false,
  success: false
};

function AppointmentsPage() {
  const dispatch = useAppDispatch();
  const { appointmentsListError } = useSelector((state: RootStateOrAny) => state.appointment);

  const [appointmentAlert, setAppointmentAlert] = useState<BookedAppointment | undefined>(
    undefined
  );
  const [appointmentPostponeAlert, setAppointmentPostponeAlert] = useState<Appointment | undefined>(
    undefined
  );
  const [appointmentsCancelState, setAppointmentsCancelState] = useState(defaultCancelState);
  const [appointmentsPostponeState, setAppointmentsPostponeState] = useState(defaultPostponeState);

  const resetAlerts = () => {
    dispatch(resetAppointmentData());
    setAppointmentsCancelState(defaultCancelState);
    setAppointmentsPostponeState(defaultPostponeState);
  };

  const constructAppointmentCancelAlertMessage = (appointmentAlertInfos: BookedAppointment) => {
    return (
      <>
        <Trans
          i18nKey="pages.appointments.canceledIdAt"
          default="Your appointment {{id}} settled the {{day}} at"
          defaultValue="Your appointment {{id}} settled the {{day}} at"
          values={{
            id: appointmentAlertInfos.id,
            day: new DateManager(new Date(appointmentAlertInfos?.slot?.day)).format('LL')
          }}
        />
        {' ' + timeSlotsNames[appointmentAlertInfos?.slot?.schedule?.type] + ' '}
        {i18n.t('pages.appointments.canceledSuccess', 'has successfully been canceled')}
      </>
    );
  };

  const constructAppointmentPostponeAlertMessage = (
    appointmentAlertInfos: BookedAppointment,
    appointmentPostponeAlertInfos: Appointment
  ) => {
    return (
      <>
        <Trans
          i18nKey="pages.appointments.settledIdAt"
          values={{
            id: appointmentAlertInfos.id,
            day: new DateManager(new Date(appointmentAlertInfos.slot.day)).format('LL')
          }}
          defaultValue="Your appointment {{id}} settled the {{day}} at"
        />
        {' ' + timeSlotsNames[appointmentAlertInfos.slot.schedule.type] + ' '}
        <Trans
          i18nKey="pages.appointments.postponedIdAt"
          values={{
            day: new DateManager(new Date(appointmentPostponeAlertInfos.day)).format('LL')
          }}
          defaultValue="has been reported to {{day}} at"
        />
        {' ' + timeSlotsNames[appointmentPostponeAlertInfos.schedule.type]}
      </>
    );
  };

  useEffect(() => {
    if (appointmentsListError || appointmentsCancelState.error || appointmentsPostponeState.error) {
      toast((t) => {
        t.type = 'error';
        t.className = 'toast-top-right';

        return (
          <AppointmentToast
            title={i18n.t('errorMessages.errorHappen', 'An error happened')}
            message={
              appointmentsListError ||
              appointmentsCancelState.error ||
              appointmentsPostponeState.error
            }
          />
        );
      });
    }
    if (appointmentsCancelState.success && appointmentAlert) {
      toast((t) => {
        t.type = 'success';
        t.className = 'toast-top-right';

        return (
          <AppointmentToast
            title={i18n.t('pages.appointments.canceled', 'Appointment canceled')}
            message={constructAppointmentCancelAlertMessage(appointmentAlert)}
          />
        );
      });
    }

    if (appointmentsPostponeState.success && appointmentAlert && appointmentPostponeAlert) {
      toast((t) => {
        t.type = 'success';
        t.className = 'toast-top-right';

        return (
          <AppointmentToast
            title={i18n.t('pages.appointments.updated', 'Appointment updated')}
            message={constructAppointmentPostponeAlertMessage(
              appointmentAlert,
              appointmentPostponeAlert
            )}
          />
        );
      });
    }
  }, [
    appointmentsPostponeState,
    appointmentAlert,
    appointmentPostponeAlert,
    appointmentsListError,
    appointmentsCancelState
  ]);

  return (
    <>
      <AppointmentListBody
        resetAlerts={resetAlerts}
        setAppointmentAlert={setAppointmentAlert}
        setAppointmentPostponeAlert={setAppointmentPostponeAlert}
        setAppointmentsPostponeState={setAppointmentsPostponeState}
        setAppointmentsCancelState={setAppointmentsCancelState}
      />
      <AppointmentListAlerts />
    </>
  );
}

export default AppointmentsPage;
