import { NavLink as RouteNavLink } from 'react-router-dom';
import styled from 'styled-components';
import theme from '@theme';

export const SidebarLink = styled(RouteNavLink)`
  height: 28px;
  text-decoration: none;
  font-family: ${theme.fonts.primary};
  color: ${theme.palette.grey[400]};
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding-left: 6.5px;
  font-weight: ${theme.fonts.weight.medium};
  &:hover {
    background-color: ${theme.palette.grey[100]};
    color: ${theme.palette.grey[400]};
  }
  &.active {
    background-color: ${theme.palette.blue[100]};
    & > div,
    & > span {
      color: ${theme.palette.blue[500]};
      img {
        filter: invert(56%) sepia(88%) saturate(5082%) hue-rotate(207deg) brightness(108%)
          contrast(100%);
      }
    }
  }
`;

export const SidebarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${theme.fonts.size.sm};
`;

// SidebarItem styles
export const SidebarItemsList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const SidebarItemContainer = styled.li`
  // margin-top: 5px;
  cursor: pointer;
  &:fisrt-child {
    margin-top: 11px;
  }
  svg {
    margin-top: -4px;
  }
  & span + svg {
    margin-left: 0.25rem;
    margin-top: 2px;
  }
  &:has(a.active) > button {
    background: ${theme.palette.blue[50]};
    & > * {
      color: ${theme.palette.blue[500]};
    }
  }
`;

export const SidebarItemName = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
  animation: visible 0.5s;
`;

const SidebarSublinks = styled.div`
  height: 0;
  overflow: hidden;
  margin-left: 1rem;

  &.open {
    height: 100%;
    padding-top: 0.5rem;
  }
`;

export const SidebarFooterLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${theme.fonts.weight.medium};
  & p {
    display: inline-block;
    animation: visible 0.5s;
    margin: 0;
    width: 100%;
  }
  & > a {
    border: 1px solid ${theme.palette.grey[100]};
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: ${theme.fonts.primary};
    font-size: ${theme.fonts.size.xs};
    color: ${theme.palette.grey[500]};
    padding: 0;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    text-align: left;
    &:hover {
      background-color: ${theme.palette.blue[50]};
    }
    & > svg {
      margin-left: 10px;
    }
    span {
      font-style: normal;
      font-weight: ${theme.fonts.weight.medium};
      font-size: ${theme.fonts.size.xs};
      line-height: ${theme.fonts.spacing.xs};
      color: ${theme.palette.grey[400]};
      display: block;

      & + span {
        font-size: ${theme.fonts.size.sm};
        color: ${theme.palette.grey[800]};
      }
    }
  }
`;

interface ISidebarContainer {
  reduced: boolean;
}
const expandedProps = (props: ISidebarContainer) => {
  if (props.reduced) {
    return `
    width: 4rem;
    transition: width 0.5s;

    & ${SidebarLink} {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
    }
    & ${SidebarItemName}, & ${SidebarSublinks} {
      opacity: 0;
      display: none;
    }

    & ${SidebarItemContainer} {
      list-style: none;
      & span + svg {
        display: none;
      }
      &:has(a.active) > button {
        background: ${theme.palette.blue[50]};
        color: ${theme.palette.blue[500]};
      }
    }

    & ${SidebarFooterLinkWrapper} > a {
      width: initial;

      & > svg {
        margin-right: 0;
      }
    }`;
  }

  return '';
};

// Sidebar Container
export const SidebarContainer = styled.div<ISidebarContainer>`
  width: 232px;
  border-right: 1px solid ${theme.palette.grey[200]};
  height: 100vh;
  padding: 16px 14px 16px 16px;
  background: white;
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ::-webkit-scrollbar {
    width: 4px;
    height: 3px;
  }
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
  -o-transition: width 0.5s;
  ${expandedProps}
`;
