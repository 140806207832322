import 'dayjs/locale/fr';
import {
  AdministrationPage,
  AppointmentsPage,
  CalendarCreate,
  CalendarSearch,
  CalendarUpdate,
  CalendarsList,
  Dataracco,
  DelegantDashboard,
  Eligibility,
  Error,
  Escalate,
  Home,
  Legals,
  MakeAppointment,
  OrderBetaEditPage,
  OrderBetaPage,
  OrderDraftsPage,
  ZoneGeoUpdate,
  ZonesGeoCreate,
  ZonesGeoList,
  ZonesGeoReferences
} from './pages';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { flagListDEV, flagListPROD } from './utils/FeatureFlag/flaglist';
import {
  loadProfile,
  selectProfileError,
  selectProfilePending
} from '@features/profiles/profileSlice';
import { useAppDispatch, useAppSelector } from './app/hooks';

import DigitalObservatory from './pages/digitalObservatory';
import { ErrorBoundary } from 'react-error-boundary';
import { FeatureFlagProvider } from './utils/FeatureFlag';
import Layout from './components/layout';
import OrderBetaTrackingPage from './pages/order/OrderBetaTrackingPage';
import { RedirectHandler } from './components/RedirectHandler';
import { ThemeProvider } from 'styled-components';
import ToasterCustom from './components/ToasterCustom';
import { env } from './config';
import { i18n, changeLanguage } from '@i18n';
import { isProd } from './app/AuthService';
import dayjs from 'dayjs';
import theme from '@theme';
import { useEffect } from 'react';
import PowerBIEmbedded from './pages/powerBIEmbedded';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import logger from './utils/logger';
import { store } from './app/store';

const defaultOptions = {
  dsn: `https://${env.SENTRY_KEY_FRONT}@sentry.axione.fr/41`,
  integrations: [],
  environment: env.ENVIRONMENT,
  tracesSampleRate: 1.0,
  release: env.DOCKER_TAG || 'unknown-version'
};

Sentry.init(defaultOptions);

function App() {
  const dispatch = useAppDispatch();
  const profileLoading = useAppSelector(selectProfilePending);
  const profileError = useAppSelector(selectProfileError);
  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);
  const flagList = isProd() ? flagListPROD : flagListDEV;
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const currentLang = params.get('lang') || null;
    if (currentLang) changeLanguage(currentLang);
    dayjs.locale(i18n.language);
    dispatch(loadProfile());
  }, []);
  useEffect(() => {
    const logPageView = () => {
      const pageUrl = location.pathname + location.search;
      Sentry.addBreadcrumb({
        category: 'navigation',
        message: `Navigated to ${pageUrl}`
      });
      Sentry.captureMessage(`Current state: ${JSON.stringify(store)}`);
    };
    logPageView();
  }, [location]);
  if (profileLoading) {
    return <div></div>;
  }

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log('logger(store)', logger(store));
      const errorContext = {
        timestamp: new Date().toISOString(),
        url: window.location.href,
        role: profileType ? profileType.rolesNames : 'unknown',
        errorMessage: error.message,
        response: {
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data
        },
        request: {
          url: error.config?.url,
          method: error.config?.method,
          headers: error.config?.headers,
          data: error.config?.data
        },
        store: logger(store)
      };
      console.error('axios error', errorContext);
      Sentry.captureException(error, {
        extra: errorContext
      });
      return Promise.reject(error);
    }
  );
  return (
    <ThemeProvider theme={theme}>
      {profileError ? (
        <Error error={{ message: profileError, name: '' }} resetErrorBoundary={() => null} />
      ) : (
        <ErrorBoundary
          FallbackComponent={Error}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <ToasterCustom />
          <RedirectHandler>
            <FeatureFlagProvider
              initialRoleNames={profileType.rolesNames}
              featureFlagsDictionary={flagList}
            >
              {/* <BrowserRouter> */}
              <Routes>
                <Route path="/eligibility-light" element={<Eligibility />} />

                <Route element={<Layout withSidebar />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/legals" element={<Legals />} />
                  <Route path="/eligibility" element={<Eligibility />} />
                  <Route path="/dashboard/ajournements" element={<DelegantDashboard />} />
                  <Route path="/dashboard/indicateurs" element={<PowerBIEmbedded />} />
                  <Route path="/ordersBeta" element={<OrderBetaPage />} />
                  <Route path="/ordersBeta/tracking" element={<OrderBetaTrackingPage />} />
                  <Route path="/ordersBeta/:orderId" element={<OrderBetaEditPage />} />
                  <Route path="/ordersBeta/draft" element={<OrderDraftsPage />} />
                  <Route path="/dataracco" element={<Dataracco />} />
                  <Route path="/erdv" element={<AdministrationPage />} />
                  <Route path="/erdv/appointment/create/step1" element={<CalendarSearch />} />
                  <Route path="/erdv/appointment/create/step2" element={<MakeAppointment />} />
                  <Route path="/erdv/appointment/list" element={<AppointmentsPage />} />
                  <Route path="/erdv/calendar/list" element={<CalendarsList />} />
                  <Route path="/erdv/calendar/create" element={<CalendarCreate />} />
                  <Route path="/erdv/calendar/:calendarId/update" element={<CalendarUpdate />} />
                  <Route
                    path="/erdv/geographic-zone/:zoneGeoId/update"
                    element={<ZoneGeoUpdate />}
                  />
                  <Route path="/erdv/geographic-zone/create" element={<ZonesGeoCreate />} />
                  <Route path="/erdv/geographic-zone/list" element={<ZonesGeoList />} />
                  <Route
                    path="/erdv/geographic-zone/:zoneId/reference/list"
                    element={<ZonesGeoReferences />}
                  />
                  <Route path="/digital-observatory" element={<DigitalObservatory />} />
                  <Route path="/escalation/*" element={<Escalate />} />
                  {/** 404 */}
                  <Route
                    path="*"
                    element={
                      <Error
                        errorCode={404}
                        error={{ message: '', name: '' }}
                        resetErrorBoundary={() => null}
                      />
                    }
                  />
                </Route>
              </Routes>
              {/* </BrowserRouter> */}
            </FeatureFlagProvider>
          </RedirectHandler>
        </ErrorBoundary>
      )}
    </ThemeProvider>
  );
}

export default App;
