import { Container } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Home: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container fluid className="main-content-container">
      <div className="error">
        <div className="error__content">
          <h3>{t('pages.home.welcome', 'Welcome')}</h3>
          <p>
            {t('pages.home.newPortal', 'Vous êtes sur le nouveau portail : ')}
            <br />
            {t(
              'pages.home.requestAccess',
              " Si vous n'avez acces à aucune page demandez à votre créateur de compte de vous donner les droits dont vous avez besoin "
            )}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Home;
