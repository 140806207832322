import { CheckLg, StarFill, Stars } from 'react-bootstrap-icons';
import { Walktour, WalktourLogic } from 'walktour';
import { useEffect, useState } from 'react';

import Badge from '@designSystem/Badges';
import Button from '@designSystem/Button';
import Icon from '@designSystem/Icon';
import LinkOrAnchor from '@designSystem/LinkOrAnchor';
import Modal from '@designSystem/Modal';
import first_draft from '../../../images/first_draft.gif';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const OrderBetaPageTrackingTuto = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTuto, setTuto] = useState(false);
  const { t } = useTranslation();

  const hasSeenTrackingTuto = localStorage.getItem('trackingTuto');

  useEffect(() => {
    if (!hasSeenTrackingTuto) {
      const timeout = setTimeout(() => {
        setIsModalOpen(true);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, []);

  const renderStepBadge = (step: string) => {
    return (
      <span style={{ display: 'inline-block', marginBottom: '0.5rem' }}>
        <Badge>
          <Stars style={{ marginRight: '0.25rem' }} />
          {t('pages.order.tuto_step', 'Step')} {step}
        </Badge>
      </span>
    );
  };

  return (
    <>
      <Walktour
        steps={[
          {
            selector: '#PageHeader',
            customTitleRenderer: () => renderStepBadge('1'),
            description: '',
            customDescriptionRenderer: () => (
              <TutoText>
                {t('pages.order.tuto_step1', 'Quickly view the different statuses of an order')}
              </TutoText>
            ),
            disableClose: true
          },
          {
            selector: '#search-info-header',
            customTitleRenderer: () => renderStepBadge('2'),
            description: '',
            customDescriptionRenderer: () => (
              <TutoText>
                {t(
                  'pages.order.tuto_step2',
                  'You can search by references, customer names, address or by date.'
                )}
              </TutoText>
            ),
            disableClose: true
          },
          {
            selector: '#Card-first-line',
            customTitleRenderer: () => renderStepBadge('3'),
            description: '',
            customDescriptionRenderer: () => (
              <TutoText>
                {t(
                  'pages.order.tuto_step3',
                  'Get a quick overview of your order, and view the details by clicking on the line.'
                )}
              </TutoText>
            ),
            disableClose: true
          },
          {
            selector: '#Final',
            description: '',
            customDescriptionRenderer: () => (
              <>
                <ModalIcon className="modal_icon">
                  <Icon round iconSize="19px" variant="green">
                    <CheckLg />
                  </Icon>
                </ModalIcon>
                <ModalText>
                  <h2 className="tuto_header">
                    {t(
                      'pages.order.tuto_end_title',
                      'Congratulations, you have completed the tutorial'
                    )}
                  </h2>
                  <p className="tuto_text">
                    {t(
                      'pages.order.tuto_end_text',
                      'You can now view your orders that have been submitted from this portal or place a new order.'
                    )}
                  </p>
                </ModalText>
              </>
            ),
            disableClose: false
          }
        ]}
        identifier={'1'}
        rootSelector={'#root'}
        isOpen={isTuto}
        customCloseFunc={(logic: WalktourLogic) => {
          setTuto(false);
          localStorage.setItem('trackingTuto', 'true');
          logic.close();
        }}
        disableCloseOnClick
        customFooterRenderer={(logic?: WalktourLogic) => (
          <>
            {logic?.stepContent.disableClose ? (
              <StyledBtn className="d-flex justify-content-between">
                {logic?.stepIndex ? (
                  <LinkOrAnchor variant="grey" onClick={() => logic?.prev()}>
                    {t('component.button.previousStep', 'Previous Step')}
                  </LinkOrAnchor>
                ) : (
                  <span></span>
                )}
                <LinkOrAnchor onClick={() => logic?.next()}>
                  {t('component.button.nextStep', 'Next step')}
                </LinkOrAnchor>
              </StyledBtn>
            ) : (
              <Button onClick={() => logic?.close()}>{t('component.button.close', 'Close')}</Button>
            )}
          </>
        )}
      />
      <Modal show={isModalOpen}>
        <>
          <ModalIcon className="modal_icon">
            <Icon round iconSize="16px" variant="grey" fillColor={theme.palette.blue[500]}>
              <StarFill />
            </Icon>
          </ModalIcon>
          <ModalText>
            <h2 className="modal_header">
              {t(
                'pages.order.tuto_welcome_title',
                'Welcome to the beta version of the order tracking'
              )}
            </h2>
            <p className="modal_text">
              {t(
                'pages.order.tuto_welcome_text',
                'We will discover in less than 2 minutes the new features implemented in the order tracking. In case of problem please contact'
              )}
              <LinkOrAnchor href="mailto:support.mon-espace@axione.fr">
                support.mon-espace@axione.fr.
              </LinkOrAnchor>
            </p>
          </ModalText>

          <img
            width="100%"
            style={{ maxHeight: '232px', marginBottom: '1rem' }}
            src={first_draft}
          />

          <Button
            style={{ width: '100%' }}
            onClick={() => {
              setIsModalOpen(false);
              setTuto(true);
            }}
          >
            {t('pages.order.tuto_start', 'Start the tutorial')}
          </Button>
        </>
      </Modal>
    </>
  );
};

export default OrderBetaPageTrackingTuto;

const ModalIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

const ModalText = styled.span`
  text-align: center;

  .modal_text,
  .tuto_text {
    color: ${theme.palette.grey[500]};
    font-size: ${theme.fonts.size.sm};
    .bold {
      font-weight: ${theme.fonts.weight.medium};
    }
  }
  .modal_text {
    margin-bottom: 20px;
  }

  .modal_header,
  .tuto_header {
    color: ${theme.palette.grey[800]};
    font-size: ${theme.fonts.size.lg};
    font-weight: ${theme.fonts.weight.medium};
    line-height: ${theme.fonts.spacing.lg};
  }
`;

const StyledBtn = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
`;

const TutoText = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.sm};
`;
