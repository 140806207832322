export const typesInterventionList = [
  {
    name: 'Adduction publique inexistante',
    color: 'markerColor0',
    displayName: 'Adduction publique inexistante',
    definition: 'La jonction entre la partie publique et la parcelle privée est à construire'
  },
  {
    name: 'GC partie publique',
    color: 'markerColor1',
    displayName: 'GC partie publique',
    definition: 'La partie publique (fourreau) est cassée et nécessite une réparation'
  },
  {
    name: 'PBO introuvable',
    color: 'markerColor2',
    displayName: 'PBO introuvable',
    definition: 'Le PBO n’est pas trouvé par le raccordeur au moment de son intervention'
  },
  {
    name: 'Probleme de Referentiel',
    color: 'markerColor3',
    displayName: 'Problème de référentiel',
    definition:
      'La route optique fournie par Axione ne correspond pas à la situation trouvée par le raccordeur sur le terrain'
  },
  {
    name: 'Probleme de continuite',
    color: 'markerColor4',
    displayName: 'Problème de continuité',
    definition: 'Le signal entre le PM et le PBO est absent'
  },
  {
    name: 'Probleme de capacite',
    color: 'markerColor5',
    displayName: 'Problème de capacité',
    definition: 'Le PBO fournit dans la route optique est plein et non utilisable'
  },
  {
    name: 'Probleme de cheminement public',
    color: 'markerColor6',
    displayName: 'Problème de cheminement public',
    definition:
      'Le parcours pour réaliser le raccordement nécessite des travaux sur la partie publique'
  },
  {
    name: 'Raccordement long',
    color: 'markerColor7',
    displayName: 'Raccordement long',
    definition: 'Le raccordement à réaliser est supérieur à 150 mètres linéaires'
  },
  {
    name: 'Fibre ou tube HS',
    color: 'markerColor8',
    displayName: 'Fibre ou tube HS',
    definition: 'La fibre ou le tube présent au PBO sont endommagés'
  },
  {
    name: 'Probleme d acces',
    color: 'markerColor9',
    displayName: "Problème d'accès",
    definition:
      'Les équipements PM ou PBO ne sont pas accessibles pour effectuer le raccordement (élagage, chambre goudronnée…)'
  },
  { name: 'Divers', color: 'markerColor10', displayName: 'Autres motifs' }
];

export const getSegmentName = (value: string) => {
  return `_${value
    .replace(/[ '&()-]/g, '_')
    .replace(/[éè]/g, 'e')
    .replace(/[à@]/g, 'a')
    .trim()
    .toLowerCase()}`;
};

export const ocNamesToParent: { [key: string]: string } = {
  'BOUYGUES TELECOM': 'BYTEL',
  BYTEL: 'BYTEL',
  BOUY: 'BYTEL',
  FREE: 'FREE',
  IFT: 'FREE',
  IFTR: 'FREE',
  ORANGE: 'ORANGE',
  FTEL: 'ORANGE',
  SCOREFIT: 'ORANGE',
  SFR: 'SFR',
  SFRA: 'SFR',
  DEFAULT: 'Autre'
};

export const ocNamesList = [
  { key: 'BYTEL', name: 'BYTEL' },
  { key: 'FREE', name: 'FREE' },
  { key: 'ORANGE', name: 'ORANGE' },
  { key: 'SFR', name: 'SFR' },
  { key: 'Others', name: 'Autres' }
];

export const columnDisplayName = {
  'Date Creation': 'Date de création',
  'Reference Pm': 'PM',
  'Reference Pbo': 'PBO',
  'Identifiant Immeuble': 'Identifiant Immeuble',
  'Type Intervention': 'Motif de l’ajournement',
  Status: 'Statut',
  Oc: 'OC',
  Plaque: 'DSP',
  Id: 'Numéro du ticket',
  'Numero Voie Immeuble': 'Numero Voie Immeuble',
  'Type Voie Immeuble': 'Type Voie Immeuble',
  'Nom Voie Immeuble': 'Nom Voie Immeuble',
  'Code Postal Immeuble': 'Code Postal Immeuble',
  'Commune Immeuble': 'Commune Immeuble'
} as { [key: string]: string };

export const statusReferenceList = {
  new: 'Nouveau',
  open: 'En cours',
  stalled: 'En attente',
  closed: 'Fermé'
} as { [key: string]: string };
