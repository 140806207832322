import React, { ReactChild, useRef, useState } from 'react';

import DelegantAdjournmentFilters from './DelegantAdjournmentFilters';

const ScrollDirFilters: React.FC<{
  children: ReactChild;
  updateSegment: (_segments: any) => void;
  setSelectedDepIsAll: (_bool: boolean) => void;
}> = ({ children, updateSegment, setSelectedDepIsAll }) => {
  const [scrollDir, setScrollDir] = useState<'up' | 'down' | 'none'>('none');

  const myRef = useRef(null) as { current: any } | null;
  let previousScrollYPosition = myRef && myRef.current && myRef.current.scrollTop;

  const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
    Math.abs(currentScrollYPosition - previousScrollYPosition) > 200;

  const isScrollingUp = (currentScrollYPosition: number) =>
    currentScrollYPosition > previousScrollYPosition &&
    !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
    !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

  const updateScrollDirection = () => {
    const currentScrollYPosition = myRef && myRef.current && myRef.current.scrollTop;

    if (scrolledMoreThanThreshold(currentScrollYPosition)) {
      const newScrollDirection = isScrollingUp(currentScrollYPosition) ? 'down' : 'up';
      setScrollDir(newScrollDirection);
      previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
    }
  };

  const elementScrollData = () => {
    updateScrollDirection();
  };

  return (
    <div ref={myRef} style={{ height: '100%', overflowY: 'scroll' }} onScroll={elementScrollData}>
      <DelegantAdjournmentFilters
        scrollDir={scrollDir}
        updateSegment={updateSegment}
        setSelectedDepIsAll={setSelectedDepIsAll}
      />
      {children}
    </div>
  );
};

export default ScrollDirFilters;
