// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { FeatureFlagContext } from '.';
import React from 'react';

const useFlagHook = () => {
  const {
    data: { featureFlags, roles },
    updateContext
  } = React.useContext(FeatureFlagContext);

  return { featureFlags, roles, updateContext };
};

const useGuardHook = (permission) => {
  const {
    data: { featureFlags }
  } = React.useContext(FeatureFlagContext);

  return featureFlags ? featureFlags[permission] : false;
};

export { useFlagHook, useGuardHook };
