// Reporting d'exploitation des RIP
// https://app.powerbi.com/Redirect?action=OpenApp&appId=aea06ef7-7efd-4044-8206-a7b03b8514ae&ctid=4a3d9983-e936-4837-9552-9d9126a92eb0

export const reportList = [
  {
    title: 'Commercialisation - Parc Grand Public',
    reportId: 'a3058049-f3d5-4e86-9336-875c67f80af8'
  },
  {
    title: 'Commercialisation - Parc Entreprise',
    reportId: '77328338-1a17-471a-9efe-5927b60ef6a9'
  },
  {
    title: 'Commercialisation - Parc catalogue B2C (et promoteurs)',
    reportId: 'd2b33582-0ce0-42d3-9f4b-fc49ff60eac3'
  },
  {
    title: 'Commercialisation - Décommissionnement du cuivre',
    reportId: '7da1f96b-f94b-42eb-928d-98a2ce88c034'
  },
  {
    title: 'Commercialisation - Pré-raccordement',
    reportId: 'b56df0b8-8e53-4d9f-aa35-c5974358ac79'
  },
  {
    title: 'Exploitation - Audits',
    reportId: '44e4a1cd-e093-43ab-810d-0189b53286ac'
  },
  {
    title: 'Exploitation - Audits à chaud et à froid',
    reportId: 'c1657646-23a1-45de-9f7d-63313f71eca1'
  },
  {
    title: 'Exploitation - Audits des ROP & PBO',
    reportId: '0db4b548-4b0d-4c11-9d0f-15a2acb1a958'
  },
  {
    title: 'Exploitation - DICT',
    reportId: 'c6d87d82-a0b2-48b8-97fe-d69899a1cab9'
  },
  {
    title: 'Exploitation - Dommages Réseaux',
    reportId: 'aed92570-bd13-40e8-beac-26cd594dec3a'
  },
  {
    title: 'Exploitation - Maintenance Curative - Incidents détecté en supervision',
    reportId: 'd351896c-cc6e-41f0-aa7f-3ee4bdd16071'
  },
  {
    title: 'Exploitation - Maintenance Préventive',
    reportId: '10a649e8-eabd-4992-8e4e-f76f795e4c24'
  },
  {
    title: 'Exploitation - Sinistres',
    reportId: 'acf39f9a-5624-4ee3-9c31-19bba7217856'
  },
  {
    title: 'Exploitation - Taux de disponibilité et délai de transit',
    reportId: '292a5b2c-1ff1-4abd-a48f-c4b59d96ee33'
  },
  {
    title: 'Exploitation - VDR',
    reportId: 'c073a075-0d83-4171-9d7d-ac25f486cfe9'
  },
  {
    title: 'Inventaire & Capacité du réseau - Complétude Hexaclés IPE',
    reportId: 'f8ac94db-e702-46c5-a03c-5a0938e1a7b7'
  },
  {
    title: 'Inventaire & Capacité du réseau - Complétude IPE',
    reportId: '5c302b09-2492-47da-acc7-d27689837d02'
  },
  {
    title: 'Inventaire & Capacité du réseau - Disponibilités des équipements',
    reportId: '1acf98ec-9660-4644-be42-650363c73a81'
  },
  {
    title: "Inventaire et Capacité du réseau - Taux d'occupation",
    reportId: '8f5a7376-abc1-425d-88eb-915a3cb36b91'
  },
  {
    title: 'Inventaire & Capacité du réseau - Taux de présence au PM',
    reportId: '9761c851-778f-4a29-9b6d-0c9e476f1458'
  },
  {
    title: 'Inventaire & Capacité du réseau - Temps de réponse des équipements',
    reportId: '5611c730-03ec-4418-a457-4d6bc7c52977'
  },
  {
    title: 'Inventaire & Capacité du réseau - Typologie des raccordements Finaux',
    reportId: '032ea5b8-2398-43ad-9918-44776dc8b24b'
  },
  {
    title: 'Qualité des Services - Ajournements',
    reportId: 'ac9bf486-9de1-4353-aa9e-29bbf801102b'
  },
  {
    title: 'Qualité des Services - Incidents',
    reportId: 'c9ee4513-f8ee-4192-9123-2e100f01d777'
  },
  {
    title: 'Qualité des services - Raccordement à la demande',
    reportId: 'a25c92ff-4550-47c9-b0f6-00bb8b73c54f'
  },
  {
    title: 'Qualité des services - Raccordements Entreprise mode OI',
    reportId: 'd01093f6-523d-4555-9fd0-620d30ea0d1f'
  },
  {
    title: 'Qualité des Services - Raccordements mode OI',
    reportId: '3e4856ca-fb00-4481-9598-0c8ca9518603'
  },
  {
    title: 'Qualité des services - SAV (avec et sans GTR)',
    reportId: 'e9e097df-6015-4e55-a5f8-9e15a6197c3c'
  },
  {
    title: 'Qualité des services - Services Infra Opérateurs',
    reportId: '67ec0147-d2db-42f4-8317-4a8dddb0b7a6'
  },
  {
    title: 'Qualité des Services - Répartition raccordements',
    reportId: '46784c1a-f62b-4fd9-ad2d-24b057e32ca6'
  },
  {
    title: 'Cartographie - Dommages réseau',
    reportId: '819f429a-e01d-43bd-a0c2-e4d9261e2f82'
  }
];
