import { OrderState, statusStrategy } from '@features/order/utils/constants';

import Badge from '@designSystem/Badges';
import React from 'react';
import Tooltip from '@designSystem/Tooltip';
import { VerticalLineStyled } from '@features/order/orderList/CardOrderContainer';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';
import { DateManager } from '../../../utils/DateManager';
import { BoxArrowInUpRight, ExclamationCircleFill } from 'react-bootstrap-icons';
import { env } from '../../../config';

const OrderStatus: React.FC<{
  state: { status: OrderState; lastEvent?: { name: string; date: string } };
  orderDate: string;
  withSeparator?: boolean;
  isBusiness?: boolean;
  salesforceId?: string;
}> = ({ state, orderDate, withSeparator, isBusiness, salesforceId }) => {
  const { t } = useTranslation();
  const statusData = statusStrategy(t)[state.status];
  const { iconStatus, status, color, iconLabel } = statusData || {};

  const orderStatusTrads: { [key: string]: string } = {
    'On site intervention failed': t(
      'order.label.intervention_failed',
      'L’intervention sur le site a échoué'
    ),
    'On site intervention succeeded': t(
      'order.label.succeeded',
      'L’intervention sur site s’est terminée avec succès'
    ),
    'Order acknowledged': t('order.label.acknowledged', 'La commande est reconnue'),
    'Order appointment agreed': t('order.label.agreed', 'Le RDV d’intervention est planifié'),
    'Order appointment being rescheduled': t(
      'order.label.beingrescheduled',
      'Demande de report de rdv en cours'
    ),
    'Order appointment rescheduled': t(
      'order.label.rescheduled',
      'Le RDV d’intervention a été replanifié'
    ),
    'Order cancelled': t('order.label.cancelled', 'La commande est annulée'),
    'Order completed': t('order.label.completed', 'la commande est terminée'),
    'Order failed': t('order.label.failed', 'La commande s’est terminée en échec'),
    'Order failure': t('order.label.failure', "L'intervention sur site a échoué"),
    'Order held': t('order.label.held', 'Un problème sur la commande est en cours de résolution'),
    'Order info received': t('order.label.received', 'L’Informations de commande est reçues'),
    'Order pending': t('order.label.pending', 'La commande est en attente'),
    'Order rejected': t('order.label.rejected', 'La commande est rejetée'),
    'Order running': t('order.label.running', 'La commande est en cours'),
    'Order submitted': t('order.label.submitted', 'La commande a été soumise')
  };

  return (
    <CardOrderStatusStyled className={withSeparator ? 'with-separator' : ''}>
      {isBusiness ? (
        <Badge className="order-badge">
          <ExclamationCircleFill />
          <span>
            <a
              style={{ display: 'inline', textDecoration: 'none' }}
              href={`${env.ORDER_REQUESTS_PAGE_URL}/${salesforceId}`}
              target="_blank"
              rel="noreferrer"
            >
              Suivre cette commande <BoxArrowInUpRight />
            </a>
          </span>
        </Badge>
      ) : (
        <Tooltip
          icon={
            <Badge className="order-badge" variant={color}>
              <img src={iconStatus} alt="icone" />
              {status}
            </Badge>
          }
        >
          {new DateManager(orderDate).format('DD/MM/YYYY - HH:mm')}
        </Tooltip>
      )}
      {withSeparator && state?.lastEvent ? <VerticalLineStyled height={11} /> : null}
      {state?.lastEvent ? (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            gridGap: '10px',
            alignItems: 'center'
          }}
          title={orderStatusTrads[state.lastEvent?.name] ?? ''}
        >
          <img className="status-icon" src={iconLabel} alt="icone statut" />
          {orderStatusTrads[state.lastEvent?.name] ?? '-'}
        </div>
      ) : null}
    </CardOrderStatusStyled>
  );
};

export default OrderStatus;

const CardOrderStatusStyled = styled.div`
  display: grid;
  gap: 5px;

  &.with-separator {
    grid-template-columns: repeat(3, max-content);
    align-items: center;
  }

  .order-badge {
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.xs};
    line-height: ${theme.fonts.spacing.xs};
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 6px;
    height: 20px;
    width: max-content;

    .order-identity {
      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .status-icon {
    width: 14px;
    height: 14px;
  }

  .status-label {
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.xs};
    line-height: ${theme.fonts.spacing.sm};
    color: ${theme.palette.grey[400]};
  }
`;
