import styled, { css } from 'styled-components';

import Icon from '../Icon';
import { ReactChild } from 'react';
import { ThemeProps } from '../../style/theme';

export interface IThemedInput extends React.HTMLProps<HTMLElement> {
  icon?: ReactChild;
  error?: string | null;
  theme: ThemeProps;
}

const makeIconPadding = (props: IThemedInput) => {
  if (props.icon !== undefined) {
    return 'padding-left: 35px;';
  }
  return '';
};

const inputErrorStyle = (props: IThemedInput) => {
  if (props.error) {
    return `
    color: ${props.theme.palette.red[500]};
    background: ${props.theme.palette.red[50]};
    border: 1px solid ${props.theme.palette.red[500]};
    &:focus-visible {
      outline: 1px solid ${props.theme.palette.red[500]};
    }
    `;
  }
  return '';
};

export const inputDefaultStyle = () => {
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 7px 10px;
    ${makeIconPadding}
    width: 100%;

    background: white;
    border: 1px solid ${(props) => props.theme.palette.grey[200]};

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;

    font-weight: ${(props) => props.theme.fonts.weight.regular};
    font-size: ${(props) => props.theme.fonts.size.xs};
    line-height: ${(props) => props.theme.fonts.spacing.xs};
    color: ${(props) => props.theme.palette.grey[800]};

    &:disabled {
      color: ${(props) => props.theme.palette.grey[400]};
      background-color: ${(props) => props.theme.palette.grey[100]};
    }
    ${inputErrorStyle}
  `;
};

export const StyledInputWrapper = styled.div<IThemedInput>`
  input,
  textarea {
    ${inputDefaultStyle()}
  }
`;

export const StyledInputIcon = styled(Icon)`
  position: absolute;
  height: 2rem;
`;

export const StyledErrorIcon = styled(Icon)`
  position: absolute;
  height: inherit;
  width: inherit;
  top: 10px;
  right: 10px;
`;

export const StyledLabel = styled('label')<{ theme: ThemeProps }>`
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  font-size: ${(props) => props.theme.fonts.size.sm};
  line-height: ${(props) => props.theme.fonts.spacing.sm};
  color: ${(props) => props.theme.palette.grey[700]};
  margin: 0;
  margin-bottom: 4px;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;

  .optional {
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    color: ${(props) => props.theme.palette.grey[400]};
  }
`;

export const ErrorMessage = styled.p`
  color: ${(props) => props.theme.palette.red[500]};
  font-size: ${(props) => props.theme.fonts.size.sm};
  line-height: ${(props) => props.theme.fonts.spacing.sm};
  margin: 6px 0 0;
`;
