import { css } from 'styled-components';

export const informationBlockDefaultStyle = () => {
  return css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    position: relative;

    gap: 12px;
    border-radius: 6px;
    text-decoration: none;

    box-sizing: border-box;
    transition: background-color 250ms ease-out, color 250ms ease-out, border-color 250ms ease-out;
    border: none;

    font-family: ${(props) => props.theme.fonts.primary};
    font-weight: ${(props) => props.theme.fonts.weight.normal};
    font-size: ${(props) => props.theme.fonts.size.sm};
    line-height: ${(props) => props.theme.fonts.spacing.sm};
  `;
};
