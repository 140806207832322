import { BookAppointmentBody } from '../utils/interfaces';
import { SortAppointment } from './appointmentSlice';
import axios from 'axios';
import { env } from '../../../config';

export const calendarSearch = async (params: {
  reference: string;
  country: string;
  type_operation: number;
}) => {
  const { reference, country, type_operation } = params;

  return axios.get(`${env.API_URL}/erdv/calendar/search`, {
    params: {
      reference,
      country,
      type_operation
    }
  });
};

export const getCalendarSchedules = async (params: { idCalendar: number }) => {
  const { idCalendar } = params;
  return axios.get(`${env.API_URL}/erdv/calendar/${idCalendar}/schedule`);
};

export const getTimeSlots = async (params: {
  id_calendar: number;
  start_day: string;
  end_day: string;
}) => {
  const { start_day, end_day, id_calendar } = params;

  return axios.get(`${env.API_URL}/erdv/appointment/search_slot?offset=0&limit=100`, {
    params: {
      start_day,
      end_day,
      id_calendar
    }
  });
};

export const bookAppointment = async (body: BookAppointmentBody) => {
  return axios.post(`${env.API_URL}/erdv/appointment/book`, {
    ...body
  });
};

export const cancelAppointment = async (id: number, cause: string) => {
  return axios.put(`${env.API_URL}/erdv/appointment/${id}/cancel`, {
    cause
  });
};

export const postponeAppointment = async (id: number, slotId: number) => {
  return axios.put(`${env.API_URL}/erdv/appointment/${id}/postpone`, {
    id_slot_date: slotId
  });
};

export const getAppointments = async (arg: {
  currentPage: number;
  sort?: SortAppointment;
  search?: string;
}) => {
  const { currentPage, sort, search } = arg;
  const order =
    sort && sort.order_by
      ? `&order_by=${sort.order_by}&order_ascending=${sort.order_ascending}`
      : '';
  const search_str = search ? `&search=${search}` : '';
  return axios.get(
    `${env.API_URL}/erdv/appointment?offset=${currentPage * 10}&limit=10${order}${search_str}`
  );
};
