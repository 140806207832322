import React from 'react';
import { enFlag, frFlag } from './const';

import Dropdown from '@designSystem/Dropdown';
import { i18n, changeLanguage } from '@i18n';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';

const Language: React.FC = () => {
  const trackEvent = useMatomoHarmonizer();
  return (
    <Dropdown
      reversed
      style={{ marginRight: '12px' }}
      placeholder={i18n?.language === 'fr' ? frFlag : enFlag}
    >
      {i18n?.language === 'fr' ? (
        <div
          onClick={() => {
            trackEvent({
              page: 'Header',
              category: 'Choix_Langue',
              actionType: 'Clic',
              actionDetails: 'Anglais'
            });
            changeLanguage('en');
          }}
          className="dropdown_item"
        >
          {enFlag} English
        </div>
      ) : (
        <div
          onClick={() => {
            trackEvent({
              page: 'Header',
              category: 'Choix_Langue',
              actionType: 'Clic',
              actionDetails: 'Français'
            });
            changeLanguage('fr');
          }}
          className="dropdown_item"
        >
          {frFlag} Français
        </div>
      )}
    </Dropdown>
  );
};

export default Language;
