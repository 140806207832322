import { IThemedComponent } from '../designInterfaces';
import { css } from 'styled-components';

export const defaultStyle = () => {
  return css`
    display: flex;
    width: 100%;
    justify-content: center;

    .roundStepShadow {
      background-color: transparent;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 8px;
      cursor: pointer;

      &.active {
        background-color: ${(props: IThemedComponent) => props.theme.palette[props.variant][200]};
      }

      &.enabled {
        .roundStep {
          background-color: ${(props: IThemedComponent) => props.theme.palette[props.variant][500]};
          width: 10px;
          height: 10px;
          border-radius: 100%;
        }
      }

      .roundStep {
        background-color: ${(props: IThemedComponent) => props.theme.palette.grey[200]};
        width: 10px;
        height: 10px;
        border-radius: 100%;
      }
    }
  `;
};
