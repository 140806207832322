import { Container } from 'react-bootstrap';
import Orders from '@features/siteStatus/Orders';
import StepperGoogle from '../components/StepperGoogle';
import { useTranslation } from 'react-i18next';

const Dataracco = () => {
  const { t } = useTranslation();
  return (
    <Container fluid className="main-content-container h-100 bg-white overflow-scroll px-0">
      <StepperGoogle
        thirdStepText={t('pages.search', 'Search result')}
        filterReferenceType="Building"
        pageTracking="EtatSite"
      >
        <Orders />
      </StepperGoogle>
    </Container>
  );
};

export default Dataracco;
