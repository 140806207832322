import { Calendar2Fill, ChevronDown } from 'react-bootstrap-icons';
import React, { CSSProperties, MouseEventHandler } from 'react';

import Icon from '@designSystem/Icon';
import { ProductOrderItem } from '@features/order/utils/orderInterfaces';
import styled from 'styled-components';
import { t } from 'i18next';
import theme from '@theme';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';
import { DateManager } from '../../../utils/DateManager';

interface EventDateProps {
  action: MouseEventHandler<HTMLDivElement>;
  productOrderItem: ProductOrderItem[];
  className?: string;
  style?: CSSProperties;
}

const AppointmentDate: React.FC<EventDateProps> = ({
  action,
  className,
  productOrderItem,
  style
}) => {
  const productOrderItemWithAppointment = productOrderItem.find((item) =>
    Object.prototype.hasOwnProperty.call(item, 'appointment')
  );
  const date =
    productOrderItemWithAppointment?.appointment.finalDate ||
    productOrderItemWithAppointment?.appointment.initialDate ||
    null;
  const dateFormated = date !== null ? new DateManager(date).format('DD/MM/YYYY') : '';
  const trackEvent = useMatomoHarmonizer();

  const openModalEditRdv = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    trackEvent({
      page: 'Mes_Commandes',
      category: 'Carte_Home_Page',
      actionType: 'Clic',
      actionDetails: 'Modifier_Rdv'
    });
    if (!productOrderItemWithAppointment?.appointment.isEditable) {
      e.stopPropagation();
    } else {
      action(e);
    }
  };

  if (!dateFormated) {
    return <>&nbsp;</>;
  }

  return (
    <AppointmentDateStyled
      onClick={openModalEditRdv}
      className={`${className ?? ''} ${
        productOrderItemWithAppointment?.appointment.isEditable ? 'editable' : 'not-editable'
      }`}
      style={style}
    >
      {' '}
      <Icon style={{ width: 10, height: 10 }} iconSize="12px" noBackground>
        <Calendar2Fill />
      </Icon>
      <div>{dateFormated && `${t('features.order.appointmentDatePrefix')} ${dateFormated}`}</div>
      <ChevronDown size="8px" />
    </AppointmentDateStyled>
  );
};
export default AppointmentDate;

const AppointmentDateStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 4px 6px;
  width: max-content;
  cursor: pointer;

  &.editable {
    color: ${theme.palette.blue[500]};
    background: ${theme.palette.blue[50]};

    svg {
      fill: ${theme.palette.blue[500]};
    }
  }

  &.not-editable {
    color: ${theme.palette.grey[400]};
    background: ${theme.palette.grey[100]};
    cursor: default;
    svg {
      fill: ${theme.palette.grey[400]};
    }
  }
`;
