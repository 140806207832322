import { CaptionProps, useNavigation } from 'react-day-picker';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import styled from 'styled-components';

import theme from '@theme';
import { DateManager } from './DateManager';

export const CustomCaption = (props: CaptionProps) => {
  const { nextMonth, previousMonth, goToMonth } = useNavigation();

  return (
    <StyledDayPickerHeader>
      <div>
        {previousMonth && (
          <ChevronLeft
            style={{ cursor: 'pointer' }}
            color={'blue'}
            onClick={() => previousMonth && goToMonth(previousMonth)}
          />
        )}
      </div>
      {new DateManager(props.displayMonth).format('MMMM YYYY')}
      <ChevronRight
        color={'blue'}
        style={{ cursor: 'pointer' }}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      />
    </StyledDayPickerHeader>
  );
};

const StyledDayPickerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: 100%;
  height: 40px;

  justify-content: space-between;

  background: ${theme.palette.grey[50]};
  border-radius: 6px;

  color: ${theme.palette.blue[500]};
  text-transform: capitalize;

  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.sm};
  font-weight: ${theme.fonts.weight.medium};
`;

export const RdpCustomStyle = `
  .rdp {
    margin: 0;
  }

  .rdp-months {
    justify-content: center;
  }

  .timeSlotWrapper {
    width: 280px;
    margin: 0 auto;
    display: flex;
  }

  .rdp-button:focus:not([disabled]) {
    border: none;
    background-color: white;
  }

  .day-selected:not([disabled]).rdp-button:focus:not([disabled]) {
    background-color: ${theme.palette.blue[500]};
  }

  .rdp-button:active:not([disabled]) {
    border: none;
    background-color: white;
  }

  .day-selected:not([disabled]), .day-selected:hover:not([disabled]) {
    border: none;
    color: white;
    background-color: ${theme.palette.blue[500]};
  }

  .day-selected:not([disabled]), day-selected:hover:not([disabled]) {
    font-weight: bold;
    background-color: ${theme.palette.blue[500]};
  }

  .rdp-button:hover:not([disabled]):not(.day-selected) {
      background-color: ${theme.palette.blue[100]};
      color: ${theme.palette.blue[500]};
  }

  .rdp-day_disabled {
    font-size: 100% !important;
  }

  .day-today, .rdp-button[aria-disabled='true'].day-today {
    font-weight: bold;
    font-size: 100%;
    opacity: 1;

    color: ${theme.palette.blue[500]};
  }
`;
