import React, { ReactChild } from 'react';

import { Alert } from 'react-bootstrap';
import { i18n } from '@i18n';

const SiteStatusAlert: React.FC<{ variant?: string; title?: ReactChild; message: ReactChild }> = ({
  variant = 'warning',
  title = i18n.t('component.button.warning', 'Warning'),
  message
}) => {
  return (
    <Alert variant={variant} className="mt-3">
      <h4 className="alert-heading">{title}</h4>
      <p>{message}</p>
    </Alert>
  );
};

export default SiteStatusAlert;
