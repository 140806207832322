import { Column } from 'react-table';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Button from '@designSystem/Button';

import { IZoneGeoFromApi } from './utils/interfaces';
import { PageHeaderSearchAndSortObject } from '../../utils/interfaces';
import { useAppDispatch } from '../../app/hooks';
import { useGuardHook } from '../../utils/FeatureFlag';
import { zoneGeoDelete, zoneGeoFetchList } from './reducer/zonesGeoSlice';
import { zonesGeoColumns } from './zonesGeoListTable/Columns';
import DeleteModal from '../../components/DeleteModal';
import PageHeader from '../../components/PageHeader/PageHeader';
import ZoneGeoTable from './zonesGeoListTable/ZoneGeoTable';
import { useTranslation } from 'react-i18next';

const ZonesGeoListBody: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { zonesGeoTotalCount } = useSelector((state: RootStateOrAny) => state.zonesGeo);
  const canCreateGeographicZone = useGuardHook('erdv_geographic_zone_create');
  const canUpdateGeographicZone = useGuardHook('erdv_geographic_zone_update');
  const canDeleteGeographicZone = useGuardHook('erdv_geographic_zone_delete');

  const [currentPage, setCurrentPage] = useState(0);
  const [idToDelete, setIdToDelete] = useState<number | false>(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [currentFilterAndSort, setCurrentFilterAndSort] = useState<PageHeaderSearchAndSortObject>({
    sort: {
      sortKey: '1',
      sortDesc: true
    }
  });

  const dispatchGetZonesGeoList = () => {
    const sortObject = {
      order_by: currentFilterAndSort.sort?.sortKey || '',
      order_ascending: !currentFilterAndSort.sort?.sortDesc
    };

    dispatch(
      zoneGeoFetchList({
        currentPage: currentPage,
        sort: sortObject,
        search: currentFilterAndSort.search
      })
    );
  };

  useEffect(() => {
    dispatchGetZonesGeoList();
  }, [currentPage, currentFilterAndSort]);

  const zoneGeoUpdateNavigate = (id: number) => {
    navigate(`/erdv/geographic-zone/${id}/update`);
  };
  const goToRef = (id: number) => {
    navigate(`/erdv/geographic-zone/${id}/reference/list`);
  };

  const zoneGeoDeleteById = () => {
    if (idToDelete) {
      setDeleteLoading(true);
      dispatch(zoneGeoDelete({ id: idToDelete })).then(() => {
        setIdToDelete(false);
        setDeleteLoading(false);
        dispatchGetZonesGeoList();
      });
    }
  };

  const columns = React.useMemo<Column<IZoneGeoFromApi>[]>(
    () =>
      zonesGeoColumns(
        zoneGeoUpdateNavigate,
        setIdToDelete,
        goToRef,
        canUpdateGeographicZone,
        canDeleteGeographicZone
      ),
    [canUpdateGeographicZone, canDeleteGeographicZone]
  );

  const updateDataFromSearchInput = (newFilter: PageHeaderSearchAndSortObject) => {
    setCurrentPage(0);
    setCurrentFilterAndSort({ ...newFilter });
  };

  const { t } = useTranslation();
  return (
    <>
      <PageHeader
        updateData={updateDataFromSearchInput}
        currentFilterAndSort={currentFilterAndSort}
        totalRows={zonesGeoTotalCount}
        totalRowsTitle={t('features.zonesGeo.geographicZone', 'geographic zones')}
        rightContent={
          canCreateGeographicZone && (
            <Button onClick={() => navigate('/erdv/geographic-zone/create')}>
              {t('features.zonesGeo.newgeographicZone', 'New geographic zone')}
            </Button>
          )
        }
      />
      <ZoneGeoTable columns={columns} setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <DeleteModal
        modalId={idToDelete}
        backAction={() => setIdToDelete(false)}
        deleteAction={zoneGeoDeleteById}
        loading={deleteLoading}
        title={t('features.zonesGeo.deletingZone', 'Deleting a geographic zone')}
        description={t(
          'features.zonesGeo.buClickingDelete',
          `By clicking on "delete", the geographic zone will be permanently deleted`
        )}
      />
    </>
  );
};

export default ZonesGeoListBody;
