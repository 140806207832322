import {
  baseMapFranceRaster,
  geocodeSource,
  popupObsNum,
  portailObsumOutfields,
  portailObsumUrl
} from './constants';

import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Search from '@arcgis/core/widgets/Search';
import TileLayer from '@arcgis/core/layers/TileLayer';
import { env } from '../../../config';

export function initializeMap(ref: HTMLDivElement) {
  const map = new Map();
  const tileLayer = new TileLayer({
    url: baseMapFranceRaster,
    apiKey: env.DIGITAL_OBSERVATORY_API_KEY
  });
  const view = new MapView({
    container: ref,
    map: map,
    center: [3.5065286, 49.4528922], // Longitude, latitude
    zoom: 10 // Zoom level
  });
  const portailObsum = new FeatureLayer({
    url: portailObsumUrl,
    outFields: portailObsumOutfields,
    popupTemplate: popupObsNum
  });

  const sources = [geocodeSource];
  const search = new Search({
    sources,
    view: view,
    includeDefaultSources: false
  });

  view.ui.add(search, 'top-right');

  map.add(portailObsum, 0);
  map.add(tileLayer, 0);

  return view;
}
