import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import { appointmentStatus, timeSlotsNames } from '../utils/helper';

import { Row } from 'react-table';
import theme from '@theme';
import { i18n } from '@i18n';
import { DateManager } from '../../../utils/DateManager';

export const appointmentsColumns = (
  cancel: (_id: number) => void,
  postpone: (_id: number, _idCalendar: number) => void
) => {
  return [
    {
      disableSortBy: true,
      Header: 'ID RDV',
      accessor: 'id'
    },
    {
      Header: 'Date',
      orderKey: '1',
      accessor: 'slot.day',
      Cell: ({ value }: { value: Date }) => new DateManager(value).format('DD/MM/YYYY')
    },
    {
      Header: 'Créneau',
      orderKey: '2',
      accessor: 'slot.schedule.type',
      Cell: ({ value }: { value: number }) => i18n.t(timeSlotsNames[value])
    },
    {
      disableSortBy: true,
      Header: 'Abonné',
      accessor: 'subscriber_name'
    },
    {
      Header: 'FAI',
      orderKey: '3',
      accessor: 'fai'
    },
    {
      Header: 'Statut',
      disableSortBy: true,
      accessor: 'state',
      Cell: ({ value }: { value: number }) => i18n.t(appointmentStatus[value])
    },
    {
      disableSortBy: true,
      Header: 'Actions',
      accessor: '',
      Cell: ({ row }: { row: Row<any> }) => {
        const value = row?.original;

        const dateIsPassed = new DateManager(value.slot.day).isBefore();

        // 1 = Reserved, 2 = Confirmed, 3 = Canceled, 4 = Postponed
        const canCancel = [1, 2, 4].includes(value.state) && !dateIsPassed;
        const canPostpone = [1, 2, 4].includes(value.state) && !dateIsPassed;

        return (
          <div>
            {canPostpone && (
              <PencilFill
                className="clickable"
                color={theme.palette.blue[500]}
                onClick={() => {
                  postpone(value.id, value.slot.schedule.id_calendar);
                }}
              />
            )}
            {canCancel && (
              <TrashFill
                className="clickable"
                color={theme.palette.blue[500]}
                onClick={() => {
                  cancel(value.id);
                }}
              />
            )}
          </div>
        );
      }
    }
  ];
};
