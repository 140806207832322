import { enUS as enLocale, fr as frLocale } from 'date-fns/locale';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import dayjs from 'dayjs';

export const DEFAULT_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = ['en', 'fr'];

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const resources = {
  fr: {
    translation: translationFR,
    locale: frLocale
  },
  en: {
    translation: translationEN,
    locale: enLocale
  }
} as const;

i18next
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    returnNull: false,
    debug: false,
    // debug: process.env.NODE_ENV === 'development',
    resources,
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  });

export const i18n = i18next;

export const changeLanguage = (locale: string) => {
  dayjs.locale(locale);
  i18n.changeLanguage(locale);
};
