import { ISearchInfos } from './interfaces';
import React from 'react';
import { getAddressInfosFormatted } from './helper';
import styled from 'styled-components';
import theme from '@theme';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';
import { useTranslation } from 'react-i18next';

const StyledSelectSiteHeaderContainer = styled.div`
  padding: 8px 12px;
  background: ${theme.palette.blue[50]};
  border-radius: 9px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

const StyledSelectSiteHeaderBody = styled.div`
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.xs};
  line-height: ${theme.fonts.spacing.xxs};
  color: ${theme.palette.blue[700]};
  display: flex;
  justify-content: space-between;
  font-family: ${theme.fonts.primary};
  flex: 1;

  span span {
    font-size: 12px;
  }
`;

const StyledSelectSiteHeaderColModify = styled.div`
  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.sm};
  text-align: right;
  color: ${theme.palette.blue[500]};
  font-weight: ${theme.fonts.weight.regular};
  cursor: pointer;
`;

const SelectSiteHeader: React.FC<{
  search?: ISearchInfos;
  text?: string[] | null;
  previousStep: () => void;
}> = ({ search, text, previousStep }) => {
  const { body } = getAddressInfosFormatted(search, text);
  const { t } = useTranslation();
  const trackEvent = useMatomoHarmonizer();

  const goToPreviousStep = () => {
    trackEvent({
      page: 'Commandes',
      category: 'Choix_du_site',
      actionType: 'Clic',
      actionDetails: 'Modifier'
    });

    previousStep();
  };

  return (
    <StyledSelectSiteHeaderContainer>
      <StyledSelectSiteHeaderBody>{body}</StyledSelectSiteHeaderBody>
      <StyledSelectSiteHeaderColModify onClick={goToPreviousStep}>
        {t('component.button.edit', 'Edit')}
      </StyledSelectSiteHeaderColModify>
    </StyledSelectSiteHeaderContainer>
  );
};

export default SelectSiteHeader;
