import { CSSObjectWithLabel } from 'react-select';

export const buildReactSelectStyled = (
  props: CSSObjectWithLabel,
  styles: { [key: string]: string | number } | any
): CSSObjectWithLabel => {
  return {
    ...props,
    ...styles
  } as CSSObjectWithLabel;
};
