import React, { ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import Icon from '@designSystem/Icon';
import styled from 'styled-components';
import theme from '@theme';

const StyledCardHeader = styled((props) => <Card.Header {...props} />)`
  display: flex;
  gap: 0.75rem;
  padding: 24px 24px 0;
  color: ${theme.palette.grey[800]};
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.lg};
`;

const CardHeader: React.FC<{ icon: ReactNode; title: ReactNode }> = ({ icon, title }) => {
  return (
    <StyledCardHeader>
      <Icon iconSize="1rem" border round>
        {icon}
      </Icon>
      {title}
    </StyledCardHeader>
  );
};

export default CardHeader;
