import { IThemedButton, buttonColors, disabledColor } from './designHelper';

import React from 'react';
import { ThemeVariants } from '../../style/theme';
import { defaultStyle } from './designStyles';
import styled from 'styled-components';

interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  type?: 'button' | 'submit' | 'reset';
  variant?: ThemeVariants;
  selected?: boolean;
  disabled?: boolean;
  design?: 'plain' | 'white' | 'reverse';
  size?: 'sm' | 'normal';
}

const styles = defaultStyle(buttonColors);

const StyledButton = styled.button<IThemedButton>`
  ${styles}
  background-color: ${(props) =>
    props.disabled
      ? disabledColor(props)['backgroundColor']
      : buttonColors(props)['backgroundColor']};
  color: ${(props: IThemedButton) =>
    props.disabled ? disabledColor(props)['color'] : buttonColors(props)['color']};
`;

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  variant = 'blue',
  design = 'plain',
  size = 'normal',
  ref,
  as,
  ...props
}) => {
  return <StyledButton size={size} design={design} variant={variant} type={type} {...props} />;
};

export default Button;
