import { Alert, Col, Modal } from 'react-bootstrap';
import { IVerticalityFormInputs, OrderTypes, ProductOfferingItem } from '../../interfaces';
import React, { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { orderProduct, selectAdressInfos } from '../../eligibilitySlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

import { GeoAltFill } from 'react-bootstrap-icons';
import ModalContentWithIcon from '@designSystem/Modal/ModalContentWithIcon';
import ProductVerticalityFooter from './ProductVerticalityFooter';
import ProductVerticalityModalFormBody from './productVerticalityModalFormBody';
import { ThemeProps } from '@theme';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProductVerticalityModalProps {
  orderType?: OrderTypes;
  selectedProduct?: ProductOfferingItem;
  handleClose?: () => void;
  theme?: ThemeProps;
  showModal: boolean;
  showValidate?: boolean;
  isZTD?: boolean;
}

const ProductVerticalityModal: React.FC<IProductVerticalityModalProps> = ({
  orderType,
  selectedProduct,
  handleClose,
  showModal = false,
  showValidate = false,
  isZTD = false
}) => {
  const dispatch = useAppDispatch();
  const { verticalityError } = useSelector((state: RootStateOrAny) => state.eligibility);
  const [step, setStep] = useState<number>(1);
  const { t } = useTranslation();
  const addressInfos = useAppSelector(selectAdressInfos);

  const { watch, setValue, getValues, control } = useForm<IVerticalityFormInputs>({
    mode: 'onChange'
  });

  const verticalityFormValues = watch();

  const needStep2 = () => {
    return step === 1 && !!verticalityFormValues.outlet;
  };

  const validateVerticalityForm = () => {
    handleClose && handleClose();
    if (selectedProduct && addressInfos && orderType) {
      dispatch(
        orderProduct({
          type: orderType,
          selectedProduct: { ...selectedProduct },
          addressInfos,
          verticalityInfos: {
            pto: verticalityFormValues.outlet,
            buildingName: verticalityFormValues.building,
            stair: verticalityFormValues.stair,
            floor: verticalityFormValues.floor
          },
          existingPlug: verticalityFormValues.existingOutlet,
          multiAccess: verticalityFormValues.multiAccess
        })
      );
    }
  };

  const isNextStepDisabled =
    !verticalityFormValues.building || !verticalityFormValues.stair || !verticalityFormValues.floor;

  return (
    <StyledModal
      size="lg"
      className={step === 1 ? 'step1' : 'step2'}
      show={showModal}
      onHide={handleClose}
    >
      <Modal.Body>
        <ModalContentWithIcon
          style={{ paddingBottom: 0 }}
          title={
            step === 1
              ? t(
                  'features.eligibility.products.productVerticality.verticalStructure',
                  'Vertical structure'
                )
              : t(
                  'features.eligibility.products.productVerticality.confirmSelecteReference',
                  'Do you confirm the selected reference ?'
                )
          }
        >
          <GeoAltFill color={'blue'} />
        </ModalContentWithIcon>

        {verticalityError && (
          <Col sm="12">
            <Alert variant="danger">
              <h4 className="alert-heading">{t('errorMessages.error', 'Error')}</h4>
              <p>
                {t(
                  'features.eligibility.products.productVerticality.notBeenRetrieved',
                  'Verticality structure could not been retrieved :'
                )}
                {verticalityError.message}
              </p>
              <hr />
            </Alert>
          </Col>
        )}
        <ProductVerticalityModalFormBody
          setValue={setValue}
          getValues={getValues}
          control={control}
          verticalityFormValues={verticalityFormValues}
          step={step}
          isZTD={isZTD}
          showValidate={showValidate}
        />
      </Modal.Body>

      {showValidate && (
        <ProductVerticalityFooter
          disabled={isNextStepDisabled}
          backAction={step === 1 ? () => handleClose && handleClose() : () => setStep(1)}
          isStep2={needStep2()}
          action={needStep2() ? () => setStep(2) : validateVerticalityForm}
        />
      )}
    </StyledModal>
  );
};

export default ProductVerticalityModal;

const StyledModal = styled(Modal)`
  &.step2 {
    width: 100%;
    height: 100%;
    text-align: center;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: ' ';
      height: 100%;
    }

    .modal-dialog {
      width: 500px;
      max-width: 500px;
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }
`;
