import { DayPicker } from 'react-day-picker';
import styled from 'styled-components';

import Button from '@designSystem/Button';
import PopOver from '@designSystem/PopOver';
import theme from '@theme';

import { selectOrderState } from '../reducer/orderSlice';
import { todayUTC } from '../../appointment/utils/helper';
import { useAppSelector } from '../../../app/hooks';
import FixedSpinner from '../../../components/FixedSpinner';
import OrderAppointmentCaption from '../orderAppointment/OrderAppointmentForm/OrderAppointmentCaption';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { DateManager } from '../../../utils/DateManager';

interface OrderAppointmentFormPopOverProps {
  isInSlotsDays: CallableFunction;
  setIsPopOverShown: CallableFunction;
  selectedDay?: Date;
  setSelectedDay: CallableFunction;
  month: Date;
  setSelectedDate: CallableFunction;
  updateFetchedMonths: CallableFunction;
  setMonth: CallableFunction;
  isLoading?: boolean;
  maxDays?: number;
}

const OrderAppointmentPopOver = ({
  isInSlotsDays,
  setIsPopOverShown,
  selectedDay,
  setSelectedDay,
  month,
  setSelectedDate,
  updateFetchedMonths,
  setMonth,
  isLoading,
  maxDays = Infinity
}: OrderAppointmentFormPopOverProps) => {
  const { orderLoading } = useAppSelector(selectOrderState);
  const isDayDisabled = (day: Date) => {
    return !isInSlotsDays(new DateManager(day).format('YYYY-MM-DD'));
  };

  const onMonthChange = (m: Date, maximumDays: number) => {
    const numberDaysFormToday = new DateManager(m, 'DD-MM-YYYY').diff(new DateManager(), 'days');
    if (maximumDays !== Infinity && maximumDays < numberDaysFormToday) {
      toast.error(
        t(
          'features.order.orderAppointment.appointment.cap',
          'For this product, available appointments are between D+7 and up to D+30'
        )
      );
      return;
    }
    updateFetchedMonths(new DateManager(m).format('YYYY-MM-DD'));
    setMonth(m);
  };
  const { t } = useTranslation();
  return (
    <PopOver vertical="bottom" horizontal="center">
      <style>{orderAppointmentDatePickerStyle}</style>
      <FixedSpinner loading={isLoading !== undefined ? isLoading : !!orderLoading}>
        <DayPicker
          fixedWeeks
          mode="single"
          selected={selectedDay}
          onSelect={(d: Date | undefined) => {
            setSelectedDay(d);
            setSelectedDate(undefined);
          }}
          components={{
            Caption: OrderAppointmentCaption((d: Date) => onMonthChange(d, maxDays))
          }}
          modifiersClassNames={{
            selected: 'day-selected',
            today: 'day-today'
          }}
          month={month}
          fromMonth={todayUTC}
          onMonthChange={(d: Date) => onMonthChange(d, maxDays)}
          modifiersStyles={{
            disabled: { fontSize: '75%' }
          }}
          pagedNavigation
          numberOfMonths={1}
          disabled={isDayDisabled}
        />
      </FixedSpinner>
      <StyledPopOverFooter>
        <Button
          design="white"
          onClick={() => setIsPopOverShown(false)}
          className="popover_footer_button"
        >
          {t('component.button.close', 'Close')}
        </Button>
        <Button
          className="popover_footer_button popover_footer_button_marged"
          data-testid={'date-validation'}
          onClick={() => {
            setIsPopOverShown(false);
            setSelectedDate(selectedDay);
          }}
          disabled={!selectedDay}
        >
          {t('component.button.confirm', 'Confirm')}
        </Button>
      </StyledPopOverFooter>
    </PopOver>
  );
};

export default OrderAppointmentPopOver;

export const orderAppointmentDatePickerStyle = `
  .rdp {
    margin: 0;
  }

  .rdp-months {
    justify-content: center;
  }

  .timeSlotWrapper {
    width: 280px;
    margin: 0 auto;
    display: flex;
  }

  .rdp-button:focus:not([disabled]) {
    border: none;
    background-color: white;
  }

  .day-selected:not([disabled]).rdp-button:focus:not([disabled]) {
    background-color: ${theme.palette.blue[500]};
  }

  .rdp-button:active:not([disabled]) {
    border: none;
    background-color: white;
  }

  .day-selected:not([disabled]), .day-selected:hover:not([disabled]) {
    border: none;
    color: white;
    background-color: ${theme.palette.blue[500]};
  }


  .day-selected:not([disabled]), day-selected:hover:not([disabled]) {
    font-weight: bold;
    background-color: ${theme.palette.blue[500]};
  }

  .rdp-button:hover:not([disabled]):not(.day-selected) {
      background-color: ${theme.palette.blue[100]};
      color: ${theme.palette.blue[500]};
  }

  .rdp-day_disabled {
    font-size: 100% !important;
  }

  .day-today, .rdp-button[aria-disabled='true'].day-today {
    font-weight: bold;
    font-size: 100%;
    opacity: 1;

    color: ${theme.palette.blue[500]};
  }
`;

const StyledPopOverFooter = styled.div`
  display: flex;

  .popover_footer_button {
    flex: 1 1;
  }

  .popover_footer_button_marged {
    margin-left: 10px;
  }
`;
