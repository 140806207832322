import React, { useEffect, useState } from 'react';

import Button from '../Button';
import { IThemedComponent } from '../designInterfaces';
import { ThemeVariants } from '../../style/theme';
import { defaultStyle } from './designStyles';
import { paginationWithDots } from './helper';
import styled from 'styled-components';

type PaginationProps = {
  pageChangeHandler: (_currentPage: number) => void;
  totalRows: number;
  rowsPerPage?: number;
  parentCurrentPage?: number;
  variant?: ThemeVariants;
  backgroundHasBlur?: boolean;
  isSticky?: boolean;
};

const styles = defaultStyle();

const StyledPagination = styled.ul<IThemePaginationComponent>`
  ${styles}
`;

export interface IThemePaginationComponent extends IThemedComponent {
  isSticky?: boolean;
  backgroundHasBlur?: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  pageChangeHandler,
  totalRows,
  rowsPerPage = 10,
  parentCurrentPage,
  variant = 'blue',
  ...props
}) => {
  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  // Creating an array with length equal to no.of pages
  // const pagesArr = [...new Array(noOfPages)];

  // State variable to hold the current page. This value is
  // passed to the callback provided by the parent
  const [currentPage, setCurrentPage] = useState(parentCurrentPage || 0);

  // Creating an array with length equal to no.of pages
  const pagesArr = paginationWithDots(currentPage, noOfPages);

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // Onclick handlers for the butons
  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo: number) => setCurrentPage(pageNo - 1);

  useEffect(() => {
    setCurrentPage(parentCurrentPage || 0);
  }, [parentCurrentPage]);

  // Disable previous and next Buttons in the first and last page
  // respectively
  useEffect(() => {
    if (noOfPages - 1 === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 0) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  useEffect(() => {
    pageChangeHandler(currentPage);
  }, [currentPage]);

  return (
    <>
      {noOfPages > 1 ? (
        <StyledPagination {...props} variant={variant}>
          <li className="prev">
            <Button
              className="button-direction button-prev"
              disabled={!canGoBack}
              onClick={() => onPrevPage()}
            ></Button>
          </li>
          <li>
            {pagesArr.map((num, index) => {
              const isActive = num === currentPage + 1;
              const buttonDesign = isActive ? 'reverse' : 'white';
              const buttonVariant = isActive ? variant : 'grey';
              const buttonClassName = `item ${isActive ? 'active' : 'not_active'}`;
              return num === '...' ? (
                <Button className="item not_active" key={`${index}${num}`} disabled>
                  ...
                </Button>
              ) : (
                <Button
                  design={buttonDesign}
                  key={num}
                  variant={buttonVariant}
                  onClick={() => onPageSelect(num)}
                  className={buttonClassName}
                >
                  {num}
                </Button>
              );
            })}
          </li>
          <li className="next">
            <Button
              className="button-direction button-next"
              design="white"
              disabled={!canGoNext}
              onClick={() => onNextPage()}
            ></Button>
          </li>
        </StyledPagination>
      ) : null}
    </>
  );
};

export default Pagination;
