import {
  HomeBasicProductCode,
  productsListWithInfos,
  productsListWithInfosType
} from '@features/eligibility/const';
import {
  IOrderAppointmentBook,
  IOrderAppointmentSlot,
  ISelectedSlot,
  ProductOrderItem
} from '@features/order/utils/orderInterfaces';
import { IReportErdv, getAppointmentSlots, reportErdv } from '@features/order/reducer/orderAPI';
import { InterventionTypeLabel, YearMonthDay } from '@features/order/utils/constants';
import React, { useEffect, useState } from 'react';
import {
  getProductCharacteristic,
  productCharacteristicFinder
} from '@features/order/utils/OrderHelper';

import Button from '@designSystem/Button';
import { Calendar2Fill } from 'react-bootstrap-icons';
import HomeBasicDatePicker from './HomeBasicDatePicker';
import InformationBlock from '@designSystem/InformationBlock';
import Modal from '@designSystem/Modal';
import ModalContentWithIcon from '@designSystem/Modal/ModalContentWithIcon';
import OrderAppointmentFormDateInput from '../orderAppointment/OrderAppointmentForm/OrderAppointmentFormDateInput';
import OrderAppointmentFormSchedule from '../orderAppointment/OrderAppointmentForm/OrderAppointmentFormSchedule';
import OrderAppointmentPopOver from '@features/order/components/OrderAppointmentPopOver';
import Spinner from '@designSystem/Spinner';
import { i18n } from '@i18n';
import { selectOrderState } from '@features/order/reducer/orderSlice';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { todayUTC } from '@features/appointment/utils/helper';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateManager } from '../../../utils/DateManager';
import { useAppSelector } from '../../../app/hooks';
import { selectAdressInfos } from '@features/eligibility/eligibilitySlice';
import { getDSPFromProduct } from '@features/order/utils/utils';

interface ModalDeleteOrderProps {
  show: boolean;
  onClose: () => void;
  orderInfos: {
    orderId: string | undefined;
    buildingId: string | undefined;
    productOrderItem: ProductOrderItem[] | undefined;
  };
}

export const ModalEditAppointment: React.FC<ModalDeleteOrderProps> = ({
  show,
  onClose,
  orderInfos
}: ModalDeleteOrderProps) => {
  const [selectedSlot, setSelectedSlot] = useState<ISelectedSlot | undefined>();
  const [selectedSlotHomeBasic, setSelectedSlotHomeBasic] = useState<
    { date: Date; time: string }[]
  >([]);
  const [selectedDateInputHomeBasic, setSelectedDateInputHomeBasic] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedSchedule, setSelectedSchedule] = useState<string | false>(false);
  const [isPopOverShown, setIsPopOverShown] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(undefined);
  const [month, setMonth] = useState<Date>(todayUTC);
  const [alreadyFetchedMonths, setFetched] = useState<string[]>([]);
  const [validSlotsDays, setValidSlotsDays] = useState<string[]>([]);
  const [appointmentSlots, setAppointmentSlots] = useState<IOrderAppointmentSlot[]>();
  const [slotsLoading, setSlotsLoading] = useState<boolean>(true);
  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [allSlots, setAllSlots] = useState<any[]>([]);
  const allDays = allSlots.map((s) => s.day);
  const { t } = useTranslation();
  const [isRequiredToAskADVForRdv, setisRequiredToAskADVForRdv] = useState(false);
  const [isIsHomeBasic, setisIsHomeBasic] = useState<'FORBIDDEN' | 'EDITABLE' | undefined>(
    undefined
  );
  const trackEvent = useMatomoHarmonizer();

  useEffect(() => {
    if (selectedDate && selectedSchedule && allSlots.length) {
      const newSelectedSlot = allSlots
        .find((s) => s.day === new DateManager(selectedDay).format('YYYY-MM-DD'))
        ?.slots.find((s: any) => s.value === selectedSchedule);

      setSelectedSlot({ ...newSelectedSlot, day: selectedDay });
    }
    if (selectedDate === undefined || !selectedSchedule) {
      setSelectedSlot(undefined);
    }
  }, [selectedDate, selectedSchedule]);

  const [validTimeSchedule, setValidTimeSchedule] = useState<string[]>([]);

  useEffect(() => {
    // on select day update possible times
    if (selectedDay) {
      const newValidTimes = allSlots
        .find((s) => s.day === new DateManager(selectedDay).format('YYYY-MM-DD'))
        ?.slots.map((s: any) => s.value);
      setValidTimeSchedule(newValidTimes || []);
    } else {
      const allValidTimes = new Set(
        allSlots.flatMap((s) => s.slots.map((s: { value: string }) => s.value))
      );
      setValidTimeSchedule(Array.from(allValidTimes));
    }
  }, [selectedDay, allSlots]);

  useEffect(() => {
    // on select time update possible days
    if (selectedSchedule) {
      const newvalidDays = allSlots
        .reduce(
          (accumulator, currentValue) =>
            accumulator.concat(
              currentValue?.slots.map((slot: any) => {
                return {
                  ...slot,
                  day: currentValue.day
                };
              })
            ),
          []
        )
        .filter((s: any) => s.value === selectedSchedule)
        .map((s: any) => s.day);
      setValidSlotsDays(newvalidDays);
    } else {
      setValidSlotsDays(allDays);
    }
  }, [selectedSchedule, allSlots]);

  useEffect(() => {
    if (appointmentSlots) {
      setAllSlots([...allSlots, ...appointmentSlots]);
    }
  }, [appointmentSlots]);

  const defineInterventionType = () => {
    const productOfferingNames = orderInfos.productOrderItem?.map(
      (item: ProductOrderItem) => item.product.productOffering.id
    );

    const isProductZTD = productsListWithInfos.find((product: productsListWithInfosType) =>
      productOfferingNames?.includes(product.code)
    )?.isZTD;

    const productComment =
      orderInfos.productOrderItem &&
      getProductCharacteristic(orderInfos.productOrderItem[0], 'comment');
    const productOtoRef =
      orderInfos.productOrderItem &&
      getProductCharacteristic(orderInfos.productOrderItem[0], 'initialOTO');
    const isMultiAccess = productComment?.value?.startsWith('MULTI-ACCES');
    const existingOutlet =
      !!productOtoRef?.value?.reference || isMultiAccess || productOtoRef?.value?.isPresent;
    const isZTDWithoutComplementaryOutlet = isProductZTD && !isMultiAccess;
    const isBrassage = existingOutlet && (!isProductZTD || isZTDWithoutComplementaryOutlet);

    return isBrassage ? InterventionTypeLabel.BRASSAGE : InterventionTypeLabel.INSTALLATION;
  };

  const updateFetchedMonths = async (date: string) => {
    if (!alreadyFetchedMonths.includes(date)) {
      const fetchedMonths = [...alreadyFetchedMonths, date];

      setFetched(fetchedMonths);
      setSlotsLoading(true);
      setBookErdvError(undefined);
      await updateTimeSlots(date);
    }
  };

  const ErdvReportBookErrorsMap: { [key: string]: string } = {
    default: i18n.t('errorMessages.errorHappen', 'An error happened'),
    '-1': i18n.t(
      'errorMessages.ErdvReportBookErrorsMap.err1',
      'The rescheduling of the appointment failed for an unknown reason'
    ),
    '3': i18n.t('errorMessages.ErdvReportBookErrorsMap.err3', 'The appointment is not found'),
    '-4': i18n.t(
      'errorMessages.ErdvReportBookErrorsMap.err4',
      'The original appointment could not be found, please try again later.'
    ),
    '-5': i18n.t(
      'errorMessages.ErdvReportBookErrorsMap.err5',
      'The date of your initial appointment is too close, you cannot modify it.'
    ),
    '-6': i18n.t(
      'errorMessages.ErdvReportBookErrorsMap.err6',
      'The slot chosen must be in the same calendar as the old slot.'
    ),
    '-7': i18n.t(
      'errorMessages.ErdvReportBookErrorsMap.err7',
      'This appointment slot has already been reserved.'
    ),
    '-8': i18n.t(
      'errorMessages.ErdvReportBookErrorsMap.err8',
      'The date of your initial appointment is too close, you cannot modify it.'
    ),
    '-9': i18n.t(
      'errorMessages.ErdvReportBookErrorsMap.err9',
      'The RDV is not assigned to your ISP.'
    )
  };

  useEffect(() => {
    const pmRef =
      orderInfos &&
      productCharacteristicFinder<{ reference: string }>(
        orderInfos.productOrderItem || [],
        'deliveryNode'
      );

    const isHomeBasic =
      orderInfos.productOrderItem &&
      orderInfos?.productOrderItem[0]?.product.productOffering.id === HomeBasicProductCode;
    const isWithin72Hours =
      orderInfos.productOrderItem &&
      new DateManager(orderInfos?.productOrderItem[0]?.appointment.initialDate).diff(
        new DateManager(),
        'hours'
      ) < 72;

    if (!isHomeBasic && pmRef?.value?.reference.search(/^FI-.*/) !== -1) {
      // If it's a ZTD or ZONE AMII, we can't update the appointment, we show special text to inform customer
      setisIsHomeBasic(undefined);
      setisRequiredToAskADVForRdv(true);
    } else if (isHomeBasic) {
      setisIsHomeBasic(isWithin72Hours ? 'FORBIDDEN' : 'EDITABLE');
      setisRequiredToAskADVForRdv(false);
    }
    return () => {
      setisRequiredToAskADVForRdv(false);
      setisIsHomeBasic(undefined);
    };
  }, [orderInfos]);

  useEffect(() => {
    const fetchMonths = async () =>
      await updateFetchedMonths(new DateManager(month).format('YYYY-MM-DD') as YearMonthDay);
    if (!isPopOverShown) {
      setFetched([]);
      setSlotsLoading(true);
    } else {
      fetchMonths();
    }
  }, [isPopOverShown]);

  const orderState = useSelector(selectOrderState);
  const addressInfos = useAppSelector(selectAdressInfos);
  const updateTimeSlots = async (monthSelected: string) => {
    if (!orderInfos.buildingId) {
      toast.error(
        t(
          'features.order.orderAppointment.orderAppointmentForm.error',
          `An error happened retrieving building reference`
        )
      );
    }
    const spvName = getDSPFromProduct(orderState.productToOrder?.product);
    try {
      const fdhRef =
        addressInfos?.fdhRef || orderState.productToOrder?.product.place[0]?.fdhRef || '';

      const productCode =
        orderInfos?.productOrderItem?.[0].product.productOffering.id ||
        orderState.order?.productOrderItem[0].product.productOffering.id ||
        '';

      const { data }: { data: IOrderAppointmentSlot[] } = await getAppointmentSlots({
        start_day: new DateManager(monthSelected).startOf('month').format('YYYY-MM-DD'),
        end_day: new DateManager(monthSelected).endOf('month').format('YYYY-MM-DD'),
        reference: spvName === 'CityFast' ? fdhRef : orderInfos.buildingId!,
        intervention_type: defineInterventionType(),
        product: productCode,
        eligibilityId: orderState.order?.productOrderItem[0].product.productCharacteristic.find(
          (pc) => pc.name === 'eligibilityId'
        )?.value,
        companyName: orderState.order?.customer.name ?? ''
      });
      setAppointmentSlots(data);
      setSlotsLoading(false);
    } catch (e) {
      toast.error(t`features.order.orderAppointment.orderAppointmentForm.error`);
      setSlotsLoading(false);
      setIsPopOverShown(false);
      throw e;
    }
  };

  const isInSlotsDays = (day: string) => validSlotsDays.includes(day);

  interface IBookErdvError {
    code: string;
    message: string;
  }

  const id_appointment = orderInfos.productOrderItem?.find(
    (order: ProductOrderItem) => order.appointment.id
  )?.appointment.id;

  const [bookErdvError, setBookErdvError] = useState<IBookErdvError | undefined>(undefined);

  const callReportRdv = async ({ selectedSlot, isHomeBasic, orderId }: IReportErdv) => {
    try {
      const { data }: { data: IOrderAppointmentBook } = await reportErdv({
        orderId: orderId || '',
        selectedSlot
      });
      if (data) {
        closeModal();
        {
          isHomeBasic
            ? toast.success(t`features.order.orderList.appointmentChangeRegistered`)
            : toast.success(t`features.order.orderList.appointmentRegistered`);
        }
      }
    } catch (e: any) {
      if (e.response?.data) {
        setBookErdvError(e.response.data.body as IBookErdvError);
        setOrderLoading(false);
        setIsPopOverShown(false);
        setMonth(todayUTC);
        setSlotsLoading(true);
        setSelectedDate('');
        setAllSlots([]);
        setSelectedSlot(undefined);
        setSelectedSchedule(false);
        return e.response;
      }
      toast.error(t`errorMessages.errorHappen`);
      setOrderLoading(false);
      throw e;
    }
  };

  const displayPopOver = () => {
    if (isPopOverShown) {
      return (
        <OrderAppointmentPopOver
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          setIsPopOverShown={setIsPopOverShown}
          month={month}
          setMonth={setMonth}
          setSelectedDate={setSelectedDate}
          updateFetchedMonths={updateFetchedMonths}
          isInSlotsDays={isInSlotsDays}
          isLoading={slotsLoading}
        />
      );
    }
    return null;
  };

  const displayPopOverHomeBasic = (index: number) => {
    if (index === selectedDateInputHomeBasic) {
      return (
        <HomeBasicDatePicker
          selectedDate={selectedSlotHomeBasic[index]?.date || ''}
          setSelectedDate={(v: Date) => handleSelectedDateChange(v, index)}
          setIsPopOverShown={setSelectedDateInputHomeBasic}
        />
      );
    }
    return null;
  };

  const handleSelectedDateChange = (value: Date, index: number) => {
    const updatedSelectedSlotHomeBasic = [...selectedSlotHomeBasic];
    updatedSelectedSlotHomeBasic[index] = {
      date: value,
      time: selectedSlotHomeBasic[index]?.time
    };
    setSelectedSlotHomeBasic(updatedSelectedSlotHomeBasic);
  };

  const handleSelectedScheduleChange = (value: string, index: number) => {
    const updatedSelectedSlotHomeBasic = [...selectedSlotHomeBasic];
    updatedSelectedSlotHomeBasic[index] = {
      date: selectedSlotHomeBasic[index]?.date,
      time: value
    };
    setSelectedSlotHomeBasic(updatedSelectedSlotHomeBasic);
  };

  const confirmAppointment = async () => {
    if (selectedSlot && !orderLoading && id_appointment) {
      setOrderLoading(true);
      await callReportRdv({
        selectedSlot,
        orderId: orderInfos?.orderId
      });
    }
  };

  const hasDuplicates = (arr: { date: Date; time: string }[]) =>
    new Set(arr.map(({ date, time }) => `${date}-${time}`)).size !== arr.length;

  const confirmAppointmentHomeBasic = async () => {
    if (selectedSlotHomeBasic && !orderLoading && id_appointment) {
      if (hasDuplicates(selectedSlotHomeBasic)) {
        toast.error(t`errorMessages.duplicateDateTime`);
        return;
      }
      await callReportRdv({
        selectedSlot: selectedSlotHomeBasic,
        isHomeBasic: true,
        orderId: orderInfos?.orderId
      });
    }
  };

  const resetState = () => {
    setIsPopOverShown(false);
    setSelectedSchedule(false);
    setMonth(todayUTC);
    setSlotsLoading(true);
    setSelectedDate('');
    setAllSlots([]);
    setSelectedSlot(undefined);
    setBookErdvError(undefined);
    setOrderLoading(false);
    setSelectedSlotHomeBasic([]);
    setSelectedDateInputHomeBasic(null);
  };

  const closeModal = () => {
    resetState();
    onClose();
  };

  useEffect(() => {
    if (bookErdvError)
      trackEvent({
        page: 'Mes_Commandes',
        category: 'Modal_Appointment_Change',
        actionType: 'Erreur',
        actionDetails: `Report_Rdv_KO_${bookErdvError.code}`,
        lastParamsNumber: Number(bookErdvError.code)
      });
  }, [bookErdvError]);

  return (
    <Modal
      width="528px"
      show={show}
      onClose={closeModal}
      {...{
        children: (
          <ContentModalDeleteOrderStyled>
            <ModalContentWithIcon
              variant="blue"
              title={t`features.order.orderList.editAppointment`}
              description={
                <>
                  {isRequiredToAskADVForRdv && (
                    <>
                      {t('features.order.orderList.updateRdvZtdAMII')}
                      <br />
                      <a href="mailto:adv-ftth-active@axione.fr">adv-ftth-active@axione.fr</a>
                    </>
                  )}
                  {isIsHomeBasic === 'FORBIDDEN' &&
                    t('features.order.orderList.updateHomeBasicShort')}
                  {isIsHomeBasic === 'EDITABLE' && t('features.order.orderList.updateHomeBasic')}
                  {!isRequiredToAskADVForRdv &&
                    !isIsHomeBasic &&
                    t(`features.order.orderList.chooseDate`)}
                </>
              }
            >
              <Calendar2Fill size="19px" />
            </ModalContentWithIcon>
            <div className="container">
              {!isRequiredToAskADVForRdv && !isIsHomeBasic && (
                <StyledOrderAppointmentDateAndScheduleWrapper>
                  <OrderAppointmentFormDateInput
                    selectedDate={selectedDate}
                    popOver={displayPopOver()}
                    setIsPopOverShown={setIsPopOverShown}
                  />
                  <OrderAppointmentFormSchedule
                    selectedSchedule={selectedSchedule}
                    validTimeSchedule={validTimeSchedule}
                    setSelectedSchedule={setSelectedSchedule}
                  />
                </StyledOrderAppointmentDateAndScheduleWrapper>
              )}
            </div>
            {!isRequiredToAskADVForRdv && isIsHomeBasic === 'EDITABLE' && (
              <>
                {Array.from({ length: 3 }).map((_, index) => (
                  <div className="container" key={index}>
                    <StyledOrderAppointmentDateAndScheduleWrapper>
                      <OrderAppointmentFormDateInput
                        selectedDate={selectedSlotHomeBasic[index]?.date?.toString() || ''}
                        popOver={displayPopOverHomeBasic(index)}
                        setIsPopOverShown={() => setSelectedDateInputHomeBasic(index)}
                      />
                      <OrderAppointmentFormSchedule
                        selectedSchedule={selectedSlotHomeBasic[index]?.time || ''}
                        validTimeSchedule={['Matin', 'Après-midi']} // Horaires matin/après-midi
                        setSelectedSchedule={(value) => handleSelectedScheduleChange(value, index)}
                      />
                    </StyledOrderAppointmentDateAndScheduleWrapper>
                  </div>
                ))}
              </>
            )}
            {bookErdvError && (
              <InformationBlock style={{ marginTop: 16 }} title="Oups.." type="error">
                {i18n.t(
                  ErdvReportBookErrorsMap[bookErdvError.code || 'default'] ||
                    ErdvReportBookErrorsMap['default']
                )}
              </InformationBlock>
            )}

            <div className="buttons-container">
              <Button design="white" onClick={closeModal}>
                {t`component.button.cancel`}
              </Button>
              {!isRequiredToAskADVForRdv && !isIsHomeBasic && (
                <Button
                  className="btn-submit"
                  variant="blue"
                  disabled={!selectedSlot}
                  onClick={confirmAppointment}
                >
                  {t`component.button.confirm`} {orderLoading && <Spinner spinnerSize={1} />}
                </Button>
              )}
              {isIsHomeBasic === 'EDITABLE' && (
                <Button
                  className="btn-submit"
                  variant="blue"
                  disabled={
                    selectedSlotHomeBasic.filter((slot) => slot.date && slot.time).length < 3
                  }
                  onClick={confirmAppointmentHomeBasic}
                >
                  {t`component.button.confirm`} {orderLoading && <Spinner spinnerSize={1} />}
                </Button>
              )}
            </div>
          </ContentModalDeleteOrderStyled>
        )
      }}
    />
  );
};

const ContentModalDeleteOrderStyled = styled.div`
  .modal_content_icon {
    margin: 0 0 24px;
  }

  .container {
    margin-top: 24px;
  }

  .buttons-container {
    display: flex;
    gap: 12px;
    margin-top: 24px;
    height: 38px;
    & > * {
      flex: 1;
    }
  }
  .btn-submit {
    & > * {
      margin: 0;
    }
  }
`;

const StyledOrderAppointmentDateAndScheduleWrapper = styled.div`
  display: flex;
  width: 100%;
`;
