import { ProductPriceContractTerm } from '../interfaces';
import { ThemeVariants } from '@theme';
import { i18n } from '@i18n';

export const billingZoneMap: {
  [key: string]: { label: string; color: string; badgeVariant: ThemeVariants };
} = {
  VERTE: {
    label: i18n.t('enums.billingZoneMap.green', 'green zone'),
    color: '#12A500',
    badgeVariant: 'green'
  },
  VIOLETTE: {
    label: i18n.t('enums.billingZoneMap.purple', 'purple zone'),
    color: '#9000FF',
    badgeVariant: 'purple'
  },
  ROSE: {
    label: i18n.t('enums.billingZoneMap.pink', 'pink zone'),
    color: '#FF33A5',
    badgeVariant: 'pink'
  },
  BLEUE: {
    label: i18n.t('enums.billingZoneMap.blue', 'blue zone'),
    color: '#0180A5',
    badgeVariant: 'blue'
  }
};

export const offerStatus: {
  [key: string]: string;
} = {
  ABLE_TO_PREORDER: i18n.t('enums.offerStatus.ABLE_TO_PREORDER', 'ABLE_TO_PREORDER'),
  ELIGIBLE: i18n.t('enums.offerStatus.ELIGIBLE', 'ELIGIBLE'),
  INELIGIBLE: i18n.t('enums.offerStatus.INELIGIBLE', 'INELIGIBLE'),
  TO_BE_STUDIED: i18n.t('enums.offerStatus.TO_BE_STUDIED', 'TO_BE_STUDIED')
};

export const pricingLabels: {
  [key: string]: string;
} = {
  euro: i18n.t('enums.pricingLabels.euro', '€')
};

const listOfLabelsShort: {
  [key: string]: string;
} = {
  no_contract: i18n.t('enums.listOfLabelsShort.no_engagement', 'No eng.'),
  '12_months': i18n.t('enums.listOfLabelsShort.12_month_engagement', 'Eng. 12 months'),
  '36_months': i18n.t('enums.listOfLabelsShort.36_month_engagement', 'Eng. 36 months')
};

export const listOfLabelsFull: {
  [key: string]: string;
} = {
  no_contract: i18n.t('enums.listOfLabelsFull.no_engagement_full', 'no commitment'),
  '12_months': i18n.t('enums.listOfLabelsFull.12 month_engagement_full', '12 months commitment'),
  '36_months': i18n.t('enums.listOfLabelsFull.36_month_engagement_full', '36 months commitment')
};

export const engagementLabels = (
  contractTerm: ProductPriceContractTerm | null,
  listOfLabels = listOfLabelsShort
) => {
  const term = contractTerm
    ? `${contractTerm.duration.amount}_${contractTerm.duration.units}`
    : 'no_contract';

  return listOfLabels[term];
};

export const leadTimeMsg = (
  val: number
): {
  [key: string]: string;
} => {
  return {
    day: i18n.t('enums.leadTimeMsg.day', 'Day(s)', {
      defaultValue: '{{count}} day(s)',
      count: val
    }),
    week: i18n.t('enums.leadTimeMsg.week', 'Week(s)', {
      defaultValue: '{{count}} week(s)',
      count: val
    }),
    month: i18n.t('enums.leadTimeMsg.month', 'Month(s)', {
      defaultValue: '{{count}} month(s)',
      count: val
    })
  };
};

export const listOfProductOptions: {
  [key: string]: string;
} = {
  gtr_sla_plus_monthly_fee: i18n.t(
    'enums.listOfProductOptions.gtr_sla_plus_monthly_fee',
    'SLA Plus'
  ),
  gtr_4h_monthly_fee: i18n.t('enums.listOfProductOptions.gtr_4h_monthly_fee', '4h'),
  gtr_basic_monthly_fee: i18n.t('enums.listOfProductOptions.gtr_basic_monthly_fee', 'Basic'),
  gtr_10h_monthly_fee: i18n.t('enums.listOfProductOptions.gtr_10h_monthly_fee', '10h')
};

export const listOfProductDuration: {
  [key: string]: string;
} = {
  main_activation_fee_green_12_months: i18n.t(
    'enums.listOfProductOptions.main_activation_fee_12_months',
    '12 Months'
  ),
  main_activation_fee_violet_12_months: i18n.t(
    'enums.listOfProductOptions.main_activation_fee_12_months',
    '12 Months'
  ),
  main_activation_fee_12_months: i18n.t(
    'enums.listOfProductOptions.main_activation_fee_12_months',
    '12 Months'
  ),
  main_activation_fee_green_36_months: i18n.t(
    'enums.listOfProductOptions.main_activation_fee_36_months',
    '36 Months'
  ),
  main_activation_fee_36_months: i18n.t(
    'enums.listOfProductOptions.main_activation_fee_36_months',
    '36 Months'
  ),
  main_activation_fee_violet_36_months: i18n.t(
    'enums.listOfProductOptions.main_activation_fee_36_months',
    '36 Months'
  )
};

export const periodTranslations: { [mapDescription: string]: string } = {
  month: i18n.t('enums.listOfProductOptions.month', 'month'),
  year: i18n.t('enums.listOfProductOptions.year', 'year'),
  default: i18n.t('enums.listOfProductOptions.month', 'month')
};
