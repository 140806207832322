import { Order, OrdersListPaginateModel } from '@features/order/utils/constants';
import { clearAddress, resetOrderData } from '@features/eligibility/eligibilitySlice';
import { useContext, useEffect, useState } from 'react';

import ApiError from '../../components/ApiError';
import Button from '@designSystem/Button';
import { CardOrderContainer } from '@features/order/orderList/CardOrderContainer';
import { MatomoCustomContext } from '../../utils/MatomoCustomProvider';
import { ModalEditAppointment } from '@features/order/orderList/ModalEditAppointment';
import NoOrdersFound from '../../features/order/orderList/NoOrdersFound';
import OrderBetaPageTrackingTuto from './modals/OrderBetaTrackingTutoModal';
import PageHeader from '../../components/PageHeader/PageHeader';
import Pagination from '@designSystem/Pagination';
import { ProductOrderItem } from '@features/order/utils/orderInterfaces';
import Spinner from '@designSystem/Spinner';
import { getOrdersPaginate } from '@features/order/reducer/orderAPI';
import styled from 'styled-components';
import { tabsOrderTracking } from './constants';
import theme from '@theme';
import toast from 'react-hot-toast';
import { useAppDispatch } from '../../app/hooks';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OrderBetaTrackingPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [orders, setOrders] = useState<OrdersListPaginateModel | null>(null);
  const [orderIdToUpdate, setOrderIdToUpdate] = useState<string | undefined>();
  const [orderInfos, setOrderInfos] = useState<{
    orderId: string | undefined;
    buildingId: string | undefined;
    productOrderItem: ProductOrderItem[] | undefined;
  }>({ orderId: undefined, buildingId: undefined, productOrderItem: undefined });
  const [isLoading, setIsLoading] = useState(false);
  const [APIError, setAPIError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const trackEvent = useMatomoHarmonizer();
  const { matomoTrackPageView } = useContext(MatomoCustomContext);

  const [currentFilterAndSort, setCurrentFilterAndSort] = useState<{
    currentPage: number;
    search?: string;
    from?: string;
    to?: string;
    state?: string;
  }>({
    search: '',
    currentPage: Number(searchParams.get('currentPage')) || 0
  });

  const hasNoOrdersFound = orders?.orders.length === 0;

  const totalOrders = orders?.total ?? 0;
  const rowsPerPage = 10;

  useEffect(() => {
    dispatch(resetOrderData());
    dispatch(clearAddress());
    matomoTrackPageView('Mes_Commandes');
  }, []);

  const fetchOrders = async () => {
    setIsLoading(true);
    setAPIError(false);

    await getOrdersPaginate({
      page: (currentFilterAndSort.currentPage + 1).toString(),
      state_not: 'draft',
      search: currentFilterAndSort.search,
      ...(currentFilterAndSort.from ? { from: currentFilterAndSort.from } : {}),
      ...(currentFilterAndSort.to ? { to: currentFilterAndSort.to } : {}),
      ...(currentFilterAndSort.state ? { state: currentFilterAndSort.state } : {})
    })
      .then((res) => {
        setOrders(res.data);
      })
      .catch(() => {
        toast.error(t('errorMessages.errorHappen', `An error happened`));
        setAPIError(true);
        setOrders(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateDataFromSearchInput = (newFilter: { search?: string }) => {
    setCurrentFilterAndSort({ ...newFilter, currentPage: 0 });
    searchParams.set('currentPage', `0`);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    fetchOrders();
  }, [currentFilterAndSort]);

  const currentTabs = tabsOrderTracking;
  const goToNewOrder = () => {
    trackEvent({
      page: 'Mes_Commandes',
      category: 'Boutton_Header',
      actionType: 'Clic',
      actionDetails: 'Nouvelle_Commande'
    });
    navigate('/ordersBeta?no-construction=true');
  };

  const handlerPagination = (e: number) => {
    if (currentFilterAndSort.currentPage !== e)
      trackEvent({
        page: 'Mes_Commandes',
        category: 'Pagination',
        actionType: 'Clic',
        actionDetails: `Current_Page_${currentFilterAndSort.currentPage}_Go_To_${e}`
      });

    setCurrentFilterAndSort({ ...currentFilterAndSort, currentPage: e });
    searchParams.set('currentPage', `${e}`);
    setSearchParams(searchParams);
  };

  return (
    <OrderBetaStyled>
      <OrderBetaPageTrackingTuto />
      <PageHeader
        className="page-header"
        id="PageHeader"
        updateData={updateDataFromSearchInput}
        trackingSearchName={'Commande'}
        currentFilterAndSort={currentFilterAndSort}
        totalRows={totalOrders}
        allowZeroResults
        inputPlaceholder={t(
          'pages.order.searchByReference',
          `Search by reference, name or address`
        )}
        allowDateRange
        tabs={currentTabs}
        actionsDisabled={APIError}
        rightContent={
          <Button onClick={goToNewOrder}>{t('pages.order.newOrder', 'New order')}</Button>
        }
      />
      <ModalEditAppointment
        orderInfos={{
          orderId: orderInfos?.orderId,
          buildingId: orderInfos?.buildingId,
          productOrderItem: orderInfos?.productOrderItem
        }}
        show={!!orderIdToUpdate}
        onClose={() => {
          setOrderIdToUpdate(undefined);
          fetchOrders();
        }}
      />
      <div className="container-content">
        <div className="orders">
          {isLoading && <Spinner spinnerSize={2} center />}
          {!isLoading && (
            <>
              {hasNoOrdersFound && (
                <div className="container-not-found">
                  <NoOrdersFound />
                </div>
              )}
              {APIError && (
                <div className="container-not-found">
                  <ApiError />
                </div>
              )}
            </>
          )}
          {!isLoading &&
            orders?.orders.map((order: Order, index: number) => {
              return (
                <div key={order.orderId} id={index === 0 ? 'Card-first-line' : undefined}>
                  <CardOrderContainer
                    key={order.orderId}
                    order={order}
                    toHighLight={currentFilterAndSort.search}
                    setOrderIdToUpdate={setOrderIdToUpdate}
                    setOrderInfos={setOrderInfos}
                  />
                </div>
              );
            })}
        </div>
        <Pagination
          totalRows={totalOrders}
          parentCurrentPage={currentFilterAndSort.currentPage}
          pageChangeHandler={handlerPagination}
          {...{ backgroundHasBlur: true, isSticky: true }}
          rowsPerPage={rowsPerPage}
        />
      </div>
    </OrderBetaStyled>
  );
};

export default OrderBetaTrackingPage;

const OrderBetaStyled = styled.div`
  background: ${theme.palette.grey[25]};
  min-height: calc(100vh - 63px);

  .page-header {
    background-color: rgba(255, 255, 255, 0.7);
    input {
      width: 370px;
    }
  }
  .orders {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: calc(100vh - 63px);
  }
  .container-content {
    margin: 12px 24px 0;
    height: 100%;
    background: ${theme.palette.grey[25]};
  }
  .container-not-found {
    margin-top: 24px;
    display: grid;
    align-items: center;
    justify-content: center;
  }
`;
