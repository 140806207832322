import { ReactChild } from 'react';
import styled from 'styled-components';
import theme from '@theme';

const StyledAppointmentToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledAppointmentToastTitle = styled.div`
  font-weight: ${theme.fonts.weight.regular};
  font-size: ${theme.fonts.size.base};
  line-height: 20px;
  color: ${theme.palette.grey[900]};
`;

const AppointmentToast = ({
  title = '',
  message = ''
}: {
  title: string | ReactChild;
  message: string | ReactChild;
}) => {
  return (
    <StyledAppointmentToastContainer>
      <StyledAppointmentToastTitle>{title}</StyledAppointmentToastTitle>
      <div>{message}</div>
    </StyledAppointmentToastContainer>
  );
};

export default AppointmentToast;
