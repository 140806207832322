import {
  BoxWithSeparator,
  BoxWithoutSeparator,
  CardBaseOrderStyled,
  CardOrderStyled
} from './CardOrderContainer';
import { ChatLeftTextFill, ChevronRight, PersonFill } from 'react-bootstrap-icons';
import React, { Dispatch, SetStateAction } from 'react';

import AppointmentDate from '@features/order/components/AppointmentDate';
import Badge from '@designSystem/Badges';
import Icon from '@designSystem/Icon';
import { Order } from '../utils/constants';
import OrderStatus from './OrderStatus';
import { ProductOrderItem } from '@features/order/utils/orderInterfaces';
import Tooltip from '@designSystem/Tooltip';
import theme from '@theme';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { productsListWithInfos } from '@features/eligibility/const';

interface CardOrderProps {
  makeReferenceText: (
    commercialOperator: string,
    textToHighLight: string | undefined,
    tooltip?: boolean,
    isDraft?: boolean
  ) => string | JSX.Element;
  order: Order;
  toHighLight: string | undefined;
  setOrderIdToDelete?: Dispatch<SetStateAction<string | undefined>>;
  setOrderIdToUpdate?: Dispatch<SetStateAction<string | undefined>>;
  setOrderInfos?: Dispatch<
    SetStateAction<{
      orderId: string | undefined;
      buildingId: string | undefined;
      productOrderItem: ProductOrderItem[] | undefined;
    }>
  >;
  makeClientName: (
    name: string,
    textToHighLight: string | undefined,
    tooltip?: boolean
  ) => string | JSX.Element;
  setOrderId: Dispatch<SetStateAction<string | undefined>> | undefined;
}

const CardOrder = ({
  makeReferenceText,
  toHighLight,
  order,
  makeClientName,
  setOrderInfos,
  setOrderIdToUpdate
}: CardOrderProps) => {
  const {
    internalReference,
    externalReference,
    name,
    orderId,
    buildingId,
    productOrderItem,
    orderDate,
    state
  } = order;

  const navigate = useNavigate();
  const trackEvent = useMatomoHarmonizer();
  const { t } = useTranslation();

  const goToOrderDescription = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    trackEvent({
      page: 'Mes_Commandes',
      category: 'Carte_Home_Page',
      actionType: 'Clic',
      actionDetails: 'Détails_Commande'
    });
    navigate(`/ordersBeta/${orderId}?draftStatut=finish`);
    e.stopPropagation();
  };

  const openModalInformation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    trackEvent({
      page: 'Mes_Commandes',
      category: 'Carte_Home_Page',
      actionType: 'Clic',
      actionDetails: 'Modifier_Rdv'
    });
    setOrderIdToUpdate?.(orderId);
    setOrderInfos?.({ orderId, buildingId, productOrderItem });
    e.stopPropagation();
  };

  const productCode = order.productOrderItem[0].product.productOffering.id;
  const salesforceId = productOrderItem[0].productOrderItem
    ?.find((p) => p.product.productOffering.id === 'acces')
    ?.product.productCharacteristic.find((charac) => charac.name === 'remoteOrderId');
  const isBusiness = productsListWithInfos.find(
    (product) => product.code === productCode
  )?.isBusiness;

  return (
    <CardBaseOrderStyled>
      <CardOrderStyled onClick={goToOrderDescription} style={{ cursor: 'pointer', height: 40 }}>
        <div className="info-tracking">
          <BoxWithSeparator>
            <div style={{ marginBottom: '4px' }}>
              {makeReferenceText(internalReference, toHighLight)}
            </div>
            {!isBusiness && (
              <AppointmentDate
                action={openModalInformation}
                productOrderItem={productOrderItem}
                style={{ zIndex: 1 }}
              />
            )}
          </BoxWithSeparator>

          <BoxWithSeparator>
            <Tooltip
              icon={
                <div className="external-reference">
                  {makeReferenceText(externalReference, toHighLight)}
                </div>
              }
            >
              {makeReferenceText(externalReference, toHighLight, true)}
            </Tooltip>
          </BoxWithSeparator>

          <BoxWithoutSeparator>
            <Tooltip
              icon={
                <div className="client-text">
                  <Badge
                    className={name ? '' : 'text-faded'}
                    variant="grey"
                    {...{
                      leftContent: (
                        <PersonFill
                          size="10px"
                          color={theme.palette.grey[300]}
                          className="client-icon"
                        />
                      ),
                      children: <div>{makeClientName(name, toHighLight)}</div>
                    }}
                  />
                </div>
              }
            >
              {makeClientName(name, toHighLight, true)}
            </Tooltip>
          </BoxWithoutSeparator>
          <BoxWithoutSeparator>
            <OrderStatus
              salesforceId={salesforceId?.value}
              isBusiness={isBusiness}
              state={state}
              orderDate={orderDate}
            />
          </BoxWithoutSeparator>
        </div>

        <div className="actions">
          {order.productOrderItem[0].productOrderItem
            ?.find((p) => p.product.productOffering.id === 'acces')
            ?.product.productCharacteristic.find((e) => e.name === 'failureReason')?.value && (
            <Tooltip
              icon={
                <Icon size="small" variant="red" iconSize="10px">
                  <ChatLeftTextFill />
                </Icon>
              }
            >
              {t('order.label.commentAvailable', 'Un commentaire est disponible')}
            </Tooltip>
          )}
          <Icon
            className="icon-link"
            size="small"
            variant="blue"
            iconSize="10px"
            onClick={goToOrderDescription}
          >
            <ChevronRight />
          </Icon>
        </div>
      </CardOrderStyled>
    </CardBaseOrderStyled>
  );
};

export default CardOrder;
