import { Card, Modal } from 'react-bootstrap';

import styled from 'styled-components';

export const StyledRoundCard = styled(Card)`
  border: 1px solid #e7e7e7;
  box-shadow: 0px 3px 7px 1px rgba(35, 35, 35, 0.12);
  border-radius: 9px;
`;

export const StyledBuildingDetailsCardHeader = styled(Card.Header)`
  margin: 0 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid grey;
`;

export const StyledOrderStatusDiv = styled.div`
  background: #ecfdf3;
  border-radius: 16px;
  width: fit-content;
`;

export const StyledOrderStatusText = styled.div`
  color: #108353;
  padding: 2px 8px;
`;

export const StyledModalBody = styled(Modal.Body)`
  width: 100%;
  height: 100%;
  background-color: #f3f4f6;
  border-radius: 10px;
`;

export const StyledModalCrossButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  font-size: 2rem;
`;
