import * as ReferencesAPIService from './referencesAPI';

import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { displayToastErrorWithMessage } from '../../../utils/toastHelper';
import { i18n } from '@i18n';
import toast from 'react-hot-toast';

interface ReferencesState {
  referencesListLoading: boolean;
  referenceList: any;
  referenceTotalCount: number | null;
  zonesGeoRefCreateLoading: boolean;
  zonesGeoRefListLoading: boolean;
}

const initialState: ReferencesState = {
  referencesListLoading: false,
  referenceList: [],
  referenceTotalCount: null,
  zonesGeoRefCreateLoading: false,
  zonesGeoRefListLoading: false
};

export interface SortReferences {
  order_by: string;
  order_ascending: boolean;
}

export const deleteReference = createAsyncThunk(
  'references/delete',
  async (params: { zoneId: string; id: number }, { rejectWithValue }) => {
    try {
      const response = await ReferencesAPIService.deleteReference(params.zoneId, params.id);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const fetchReferencesList = createAsyncThunk(
  'references/fetch',
  async (
    arg: { zoneId: string; currentPage: number; sort?: SortReferences; search?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await ReferencesAPIService.fetchReferencesList(arg);
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const zoneGeoRefCreate = createAsyncThunk(
  'references/create',
  async (params: { name: string; typology: number; zoneId: string }, { rejectWithValue }) => {
    try {
      const response = await ReferencesAPIService.zoneGeoRefCreate({
        name: params.name,
        typology: params.typology,
        zoneId: parseInt(params.zoneId, 10)
      });
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

export const zoneGeoRefUpdate = createAsyncThunk(
  'references/update',
  async (
    params: { name: string; typology: number; zoneId: string; id: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await ReferencesAPIService.zoneGeoRefUpdate({
        name: params.name,
        typology: params.typology,
        id: params.id,
        zoneId: parseInt(params.zoneId, 10)
      });
      return response.data;
    } catch (e: any) {
      if (e.response.data) return rejectWithValue(e.response.data);
      throw e;
    }
  }
);

const referencesSlice = createSlice({
  name: 'reference',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(zoneGeoRefCreate.pending, (state) => {
        state.zonesGeoRefCreateLoading = true;
      })
      .addCase(zoneGeoRefCreate.fulfilled, (state) => {
        state.zonesGeoRefCreateLoading = false;
        toast.success(
          i18n.t('features.zonesGeo.reducer.createSuccessfully', `Reference created successfully`)
        );
      })
      .addCase(zoneGeoRefCreate.rejected, (state, action: AnyAction) => {
        state.zonesGeoRefCreateLoading = false;
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          `${i18n.t('errorMessages.errorHappen', 'An error happened')} ${errorMessage}`
        );
      })
      .addCase(zoneGeoRefUpdate.fulfilled, () => {
        toast.success(
          i18n.t('features.zonesGeo.reducer.createSuccessfully', `Reference created successfully`)
        );
      })
      .addCase(zoneGeoRefUpdate.rejected, (state, action: AnyAction) => {
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          `${i18n.t('errorMessages.errorHappen', 'An error happened')} ${errorMessage}`
        );
      })
      .addCase(deleteReference.fulfilled, () => {
        toast.success(
          i18n.t('features.zonesGeo.reducer.createSuccessfully', `Reference created successfully`)
        );
      })
      .addCase(deleteReference.rejected, (state, action: AnyAction) => {
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          `${i18n.t('errorMessages.errorHappen', 'An error happened')} ${errorMessage}`
        );
      })
      .addCase(fetchReferencesList.pending, (state) => {
        state.referencesListLoading = true;
      })
      .addCase(fetchReferencesList.fulfilled, (state) => {
        state.referencesListLoading = false;
      })
      .addCase(fetchReferencesList.rejected, (state, action: AnyAction) => {
        state.referencesListLoading = false;
        const errorMessage = action.payload?.message || action.error.message;
        displayToastErrorWithMessage(
          `${i18n.t('errorMessages.errorHappen', 'An error happened')} ${errorMessage}`
        );
      });
  }
});

export default referencesSlice.reducer;
