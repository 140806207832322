import { IZoneGeoRefFromApi, IZoneGeoRefPageData } from '../interfaces';
import { RootStateOrAny, useSelector } from 'react-redux';

import { ArrowLeftCircle } from 'react-bootstrap-icons';
import { Column } from 'react-table';
import FullTable from '../../../components/FullTable';
import Pagination from '@designSystem/Pagination';
import React from 'react';
import TableContainerWithBorderMaxWidth from '../../../components/TableContainerWithBorderMaxWidth';
import TableNoData from '../../../components/TableNoData';
import theme from '@theme';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledNoDataContainer = {
  padding: '1rem',
  backgroundColor: 'white',
  borderStyle: 'solid',
  borderColor: ` ${theme.palette.grey[200]}`,
  borderWidth: '0px 1px 1px 1px',
  borderRadius: '0px 0px 8px 8px'
};

const ZoneGeoRefTable: React.FC<{
  columns: Column<IZoneGeoRefFromApi>[];
  pageData: IZoneGeoRefPageData;
  setCurrentPage: (_currentPage: number) => void;
  currentPage: number;
}> = ({ columns, pageData, setCurrentPage, currentPage }) => {
  const navigate = useNavigate();

  const { referencesListLoading } = useSelector((state: RootStateOrAny) => state.zoneGeoReferences);

  const returnOnZoneGeoList = () => {
    navigate('/erdv/geographic-zone/list');
  };
  const { t } = useTranslation();
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <h3 onClick={() => returnOnZoneGeoList()} className="clickable">
          <ArrowLeftCircle />
        </h3>
      </div>
      <TableContainerWithBorderMaxWidth className="table-max-width-margin table-border-top">
        <>
          <FullTable columns={columns} data={pageData.rowData} isLoading={referencesListLoading} />
          {!referencesListLoading && pageData?.rowData && pageData.rowData.length === 0 && (
            <TableNoData
              containerStyle={StyledNoDataContainer}
              title={t('errorMessages.noData', 'No data')}
              description={t(
                'features.zonesGeo.referencesListTable.noReference',
                'No reference is found for this zone'
              )}
            />
          )}
          <Pagination
            totalRows={pageData.totalCount}
            pageChangeHandler={setCurrentPage}
            parentCurrentPage={currentPage}
          />
        </>
      </TableContainerWithBorderMaxWidth>
    </>
  );
};

export default ZoneGeoRefTable;
