import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import DevProfiles from './DevProfiles';
import Language from './Language';
import UserActions from './UserActions';
import { generatePageTitle } from '../const';
import styled from 'styled-components';
import theme from '@theme';

const StyledNavbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  // background-color: white;
  // padding: 11px 24px;
  // border-bottom: 1px solid ${theme.palette.grey[200]};
`;

const StyledNavbarContainerRightContent = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const StyledNavbarTitle = styled.div`
  align-items: center;
  display: flex;
`;

const MainNavbar: React.FC = () => {
  const location = useLocation();
  const params = useParams();
  const pathname = location.pathname.replace(/[/#]/, '');

  return (
    <StyledNavbarContainer>
      <StyledNavbarTitle>{generatePageTitle(pathname, params)}</StyledNavbarTitle>
      <StyledNavbarContainerRightContent>
        <DevProfiles />
        <Language />
        <UserActions />
      </StyledNavbarContainerRightContent>
    </StyledNavbarContainer>
  );
};

export default MainNavbar;
