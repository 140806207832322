import { useContext } from 'react';
import { MatomoCustomContext } from './MatomoCustomProvider';
import { env } from '../config';

export type trackingPages =
  | 'Pagination'
  | 'Ajournement'
  | 'Legals'
  | 'EtatSite'
  | 'Header'
  | 'Commandes'
  | 'Brouillons'
  | 'Sidebar'
  | 'Eligibilité'
  | 'Order_informations'
  | 'Mes_Commandes';

type actionTrackingPages =
  | 'Clic'
  | 'Erreur'
  | 'Chargement'
  | 'Vu'
  | 'Select'
  | 'Aucun'
  | 'Télécharger'
  | 'Retour'
  | 'Valider'
  | 'Survol'
  | 'Recherche'
  | 'Rafraîchir'
  | 'Saisie';

export type TrackEventPage = {
  page: trackingPages;
  category: string;
  actionType: actionTrackingPages;
  actionDetails?: string;
  lastParamsNumber?: number;
};

const checkFormatActionDetails = (page: string, category: string, actionDetails?: string) => {
  if (!actionDetails) return;
  if (
    !/^([A-ZÀ-Ÿ][a-zà-ÿ0-9]*|[A-ZÀ-Ÿ]+)(_([A-ZÀ-Ÿ][a-zà-ÿ0-9]*|[A-ZÀ-Ÿ]+))*$/.test(actionDetails) &&
    process.env.NODE_ENV === 'development'
  ) {
    console.error(`
        MATOMO FORMAT ERROR::::::
        fonction : trackEvent()
        page : ${page}
        catégorie : ${category}
        actionDetails: 🚨${actionDetails}🚨

        Chaque mot dans >>actionDetails<< doit commencer par une majuscule et les mots doivent être séparés par un underscore.
        Les mots peuvent être entièrement en majuscules. Un seul mot est également acceptable.
        Les accents sont acceptés.
        `);
  }
};
const formatSecondParam = (actionType: TrackEventPage['actionDetails'], actionDetails?: string) =>
  actionType + (actionDetails ? `_${actionDetails}` : '');

export function useMatomoHarmonizer() {
  const { matomoTrackEvent } = useContext(MatomoCustomContext);
  const trackEvent = ({
    page,
    actionType,
    actionDetails,
    category,
    lastParamsNumber
  }: TrackEventPage) => {
    checkFormatActionDetails(page, category, actionDetails);
    if (env.showMatomoLog && process.env.NODE_ENV === 'development') {
      console.log(
        'TrackEvent:',
        page,
        category,
        formatSecondParam(actionType, actionDetails),
        lastParamsNumber
      );
    }
    matomoTrackEvent(
      page,
      category,
      formatSecondParam(actionType, actionDetails),
      lastParamsNumber
    );
  };
  return trackEvent;
}
