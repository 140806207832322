import { i18n } from '@i18n';
export const baseMapFranceRaster =
  'https://services.esrifrance.fr/arcgis/rest/services/FranceRaster/France_FranceRaster_Premium/MapServer';

export const portailObsumUrl =
  'https://ws-sigfedere-fr.axione.fr/arcgis/rest/services/Hosted/test_portail_obsnum/FeatureServer/0';

export const portailObsumOutfields = [
  'objectid',
  'id',
  'nom',
  'nom_m',
  'insee_com',
  'statut',
  'population',
  'insee_can',
  'insee_arr',
  'insee_dep',
  'insee_reg',
  'siren_epci',
  'objectid_1',
  'cog2022',
  'eligibles_ipe',
  'abonnes',
  'commandes_en_cours',
  'penetration'
];

export const popupObsNum = {
  title: '{id}',
  content: `
        <b>objectid</b>: {objectid}<br>
        <b>id</b>: {id}<br>
        <b>nom</b>: {nom}<br>
        <b>nom_m</b>: {nom_m}<br>
        <b>insee_com</b>: {insee_com}<br>
        <b>statut</b>: {statut}<br>
        <b>population</b>: {population}<br>
        <b>insee_can</b>: {insee_can}<br>
        <b>insee_arr</b>: {insee_arr}<br>
        <b>insee_dep</b>: {insee_dep}<br>
        <b>insee_reg</b>: {insee_reg}<br>
        <b>siren_epci</b>: {siren_epci}<br>
        <b>objectid_1</b>: {objectid_1}<br>
        <b>cog2022</b>: {cog2022}<br>
        <b>eligibles_ipe</b>: {eligibles_ipe}<br>
        <b>abonnes</b>: {abonnes}<br>
        <b>commandes_en_cours</b>: {commandes_en_cours}<br>
        <b>penetration</b>: {penetration}<br>
      `
};

export const geocodeSource = {
  url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',
  singleLineFieldName: 'SingleLine',
  name: 'Custom Geocoding Service',
  placeholder: i18n.t('features.digitalObservatory.esri.find', `Find an address`),
  maxResults: 3,
  maxSuggestions: 6,
  suggestionsEnabled: false,
  minSuggestCharacters: 0
};
