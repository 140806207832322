import React from 'react';
import {
  IOfferingResult,
  ProductOfferingQualificationItem
} from '@features/eligibility/interfaces';
import { isBusiness, isHomeBasicProduct } from '@features/eligibility/helper';
import ProductDetailHomeBasicModal from '@features/eligibility/products/productDetail/modal/ProductDetailHomeBasicModal';
import ProductDetailModal from '@features/eligibility/products/productDetail/modal/productDetailModal';
import { getDefaultGtrFromProduct } from '@features/eligibility/products/productDetail/OptionsForm';
import ProductBusinessDetailModal from './productBusinessDetailModal';
import { OrderOptions } from '@features/order/utils/orderInterfaces';

const ProductDetailModalFactory: React.FC<{
  handleClose: () => void;
  nextStep: CallableFunction;
  selectedProduct: IOfferingResult | null;
  detailProductData?: ProductOfferingQualificationItem;
  selectedOptions?: OrderOptions;
  loading: boolean;
}> = ({ selectedProduct, detailProductData, handleClose, nextStep, selectedOptions, loading }) => {
  const defaultGtr = detailProductData && getDefaultGtrFromProduct(detailProductData);
  const isHomeBasic = selectedProduct && isHomeBasicProduct(selectedProduct);
  const isBusinessProduct = selectedProduct && isBusiness(selectedProduct);
  if (isHomeBasic) {
    return (
      <ProductDetailHomeBasicModal
        defaultGtr={defaultGtr}
        selectedProduct={selectedProduct}
        detailProductData={detailProductData}
        handleClose={handleClose}
        nextStep={nextStep}
        loading={loading}
      />
    );
  } else if (isBusinessProduct) {
    return (
      <ProductBusinessDetailModal
        selectedProduct={selectedProduct}
        detailProductData={detailProductData}
        handleClose={handleClose}
        nextStep={nextStep}
        selectedOptions={selectedOptions}
        loading={loading}
      />
    );
  }

  return (
    <ProductDetailModal
      detailProductData={detailProductData}
      defaultGtr={defaultGtr}
      selectedProduct={selectedProduct}
      handleClose={handleClose}
      nextStep={nextStep}
      selectedOptions={selectedOptions}
      loading={loading}
    />
  );
};

export default ProductDetailModalFactory;
