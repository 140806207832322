import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { LeafletMarker } from '../interfaces';
import LeafletMarkerComponent from './LeafletMarkerComponent';

interface LeafletMarkersProps {
  markers: LeafletMarker[];
  getMarkerTextInfos: (_i: number) => LeafletMarker;
  setCurrentMarker: Dispatch<SetStateAction<number | undefined>>;
  currentMarker: number | undefined;
}

const LeafletMarkers: React.FC<LeafletMarkersProps> = ({
  markers,
  getMarkerTextInfos,
  setCurrentMarker,
  currentMarker
}) => {
  const displayMarkers = useCallback(() => {
    return markers.map((m: LeafletMarker, i: number) => {
      return (
        <LeafletMarkerComponent
          marker={m}
          index={i}
          key={`marker-${i}`}
          getMarkerTextInfos={() => getMarkerTextInfos(i)}
          setCurrentMarker={setCurrentMarker}
          openPopup={i === currentMarker}
        />
      );
    });
  }, [markers, currentMarker]);

  return <>{displayMarkers()}</>;
};

export default LeafletMarkers;
