import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const OrderRecapHeader = () => {
  const { t } = useTranslation();
  return (
    <StyledOrderRecapHeaderContainer>
      <div className="header-title-container">
        <div className="header-title">
          {t('features.order.orderRecap.orderRecap', 'Order recap')}
        </div>
      </div>
      <div className="header-subtitle">
        {t(
          'features.order.orderRecap.pleaseVerify',
          'Please verify the information relating to your order before validating it.'
        )}
      </div>
    </StyledOrderRecapHeaderContainer>
  );
};

export default OrderRecapHeader;

const StyledOrderRecapHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  .header-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-title {
      font-weight: ${theme.fonts.weight.medium};
      font-size: ${theme.fonts.size.sm};
      line-height: ${theme.fonts.spacing.sm};

      color: ${theme.palette.grey[900]};
    }
  }

  .header-subtitle {
    font-weight: ${theme.fonts.weight.regular};
    font-size: ${theme.fonts.size.xs};
    line-height: ${theme.fonts.spacing.xs};

    color: ${theme.palette.grey[500]};
  }
`;
