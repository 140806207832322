import { Alert } from 'react-bootstrap';
import React, { ReactChild } from 'react';
import { i18n } from '@i18n';

const AlertBox: React.FC<{
  variant?: string;
  title?: ReactChild;
  message: ReactChild | string | null;
  noMarginBottom?: boolean;
}> = ({
  variant = 'warning',
  title = i18n.t('component.button.warning', 'Warning'),
  message,
  noMarginBottom = false
}) => {
  return (
    <Alert variant={variant} className="mt-3">
      <h4 className="alert-heading">{title}</h4>
      <p className={noMarginBottom ? 'mb-0' : ''}>{message}</p>
    </Alert>
  );
};

export default AlertBox;
