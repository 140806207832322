import React from 'react';
import styled from 'styled-components';

const LignFilled = styled.div`
  position: absolute;
  width: 9px;
  height: 100%;
  top: 0px;
  left: 0px;
  border-radius: 9px 0px 0px 9px;
`;

const LignBackgroundFill: React.FC<{ lignColor: string }> = ({
  lignColor = 'rgba(155, 220, 238, 0.5)'
}) => {
  return (
    <LignFilled
      style={{
        background: `${lignColor}`
      }}
    />
  );
};

export default LignBackgroundFill;
