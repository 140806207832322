import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { Link, useParams } from 'react-router-dom';
import { NavItemsType, canDisplaySideBarItem, isPathHighlighted, navItems } from '../const';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Ellipsis } from '../../../images/sidebar';
import styled from 'styled-components';
import theme from '@theme';

const HeaderSection = ({ isOpen, text }: { isOpen: boolean; text: string }) => {
  return (
    <>
      {isOpen ? (
        <div className="section-title-sidebar">{text}</div>
      ) : (
        <div className="menu-ellipsis-container">
          <img className="menu-ellipsis" src={Ellipsis} alt="Plus d'options" />
          <div className="tooltip-text">{text}</div>
        </div>
      )}
    </>
  );
};
export const SidebarItems = ({
  childRoutes = null,
  expanded
}: {
  childRoutes?: NavItemsType[] | null;
  expanded: boolean;
}) => {
  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);
  const { orderId } = useParams();
  const currentLocationPath = window.location.pathname;

  const displayItems = useMemo(() => {
    return (childRoutes || navItems).map((item) => {
      if (!canDisplaySideBarItem(item, profileType) || !item.title) {
        return null;
      }
      return item;
    });
  }, []);

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {displayItems.map((item, index) => {
        if (item?.to() === 'isSectionName') {
          return <HeaderSection text={(item?.title?.() as string) || ''} isOpen={expanded} />;
        }
        if (item === null) return <div key={index}></div>;

        const isChildSelected = Boolean(
          item.childRoute?.find(
            (child) =>
              child.to() === currentLocationPath ||
              isPathHighlighted(currentLocationPath, child.highLight, orderId)
          )
        );
        const isSelected =
          currentLocationPath === item.to() ||
          isPathHighlighted(window.location.pathname, item.highLight, orderId);

        return (
          <MenuItem
            key={index}
            item={item}
            isSelected={isSelected}
            isChildSelected={isChildSelected}
            expanded={expanded}
          />
        );
      })}
    </div>
  );
};

const MenuItem = ({
  item,
  isSelected,
  isChildSelected,
  expanded
}: {
  item: NavItemsType;
  isSelected: boolean;
  isChildSelected: boolean;
  expanded: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(isSelected || isChildSelected);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const itemChilds = item.childRoute;
  const modalRef = useRef<HTMLDivElement>(null);

  const handleLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (item.isExternalLink) {
      e.preventDefault();
      window.location.assign(item.to());
    }
    if (!expanded && itemChilds) {
      setIsModalOpen(true);
      e.preventDefault();
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      style={{
        width: expanded ? '100%' : '28px',
        margin: 'auto'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 5
        }}
        onClick={(e) => {
          if (expanded && itemChilds) {
            setIsOpen(!isOpen);
          }
          e.preventDefault();
        }}
        className="hover-grey"
      >
        <Link
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          to={
            item.shouldNotRedirect
              ? null
              : (item.firstChildRedirect ? item?.childRoute?.[0]?.to() : item.to()) || ''
          }
          style={{
            width: '100%',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            padding: item.isChild ? '0 0 0 32px' : expanded ? '0 6.4px' : '0',
            height: item.isChild ? '24px' : '28px',
            color:
              isSelected || isChildSelected ? theme.palette.blue[500] : theme.palette.grey[500],
            backgroundColor: isSelected && !itemChilds ? theme.palette.blue[100] : 'white',
            borderRadius: '4px',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '20px'
          }}
          onClick={handleLinkClick}
        >
          <ContainerItem expanded={expanded}>
            {item.htmlBefore?.props?.src && (
              <img
                src={item.htmlBefore?.props?.src}
                style={{
                  filter:
                    !isSelected && !isChildSelected
                      ? ''
                      : 'invert(56%) sepia(88%) saturate(5082%) hue-rotate(207deg) brightness(108%) contrast(100%)',
                  width: '12px',
                  height: '12px'
                }}
                alt="icone"
              />
            )}
            <div>{expanded && (item?.title?.() || '')}</div>
          </ContainerItem>
          {itemChilds && (
            <div>
              {expanded ? (
                isOpen ? (
                  <ChevronUp
                    size={8}
                    style={{
                      strokeWidth: 3,
                      stroke:
                        isChildSelected || isSelected
                          ? theme.palette.blue[500]
                          : theme.palette.grey[500]
                    }}
                  />
                ) : (
                  <ChevronDown
                    size={8}
                    style={{
                      strokeWidth: 3,
                      stroke:
                        isChildSelected || isSelected
                          ? theme.palette.blue[500]
                          : theme.palette.grey[500]
                    }}
                  />
                )
              ) : (
                <></>
              )}
            </div>
          )}
        </Link>
      </div>

      {isOpen && expanded && itemChilds && (
        <SidebarItems childRoutes={itemChilds} expanded={expanded} />
      )}

      {isModalOpen && !expanded && (
        <div
          ref={modalRef}
          style={{
            position: 'absolute',
            left: '65px',
            backgroundColor: 'white',
            boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
            overflowY: 'auto',
            zIndex: 1000,
            borderRadius: '8px',
            padding: '4px'
          }}
          onMouseLeave={() => setIsModalOpen(false)}
        >
          {item.childRoute?.map((childItem) => (
            <Link
              key={childItem.to()}
              to={childItem.to()}
              style={{
                display: 'block',
                padding: '10px',
                color: theme.palette.grey[500],
                textDecoration: 'none',
                transition: 'color 0.3s ease-in-out',
                fontWeight: '500'
              }}
              onMouseEnter={(e) => (e.currentTarget.style.color = theme.palette.blue[500])}
              onMouseLeave={(e) => (e.currentTarget.style.color = theme.palette.grey[500])}
            >
              {childItem?.title?.() || ''}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const ContainerItem = styled.div<{ expanded: boolean }>`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: ${(props) => (props.expanded ? 'max-content max-content' : 'max-content')};
  gap: ${(props) => (props.expanded ? '8px' : '0')};
  justify-content: ${(props) => (props.expanded ? 'initial' : 'center')};
`;
