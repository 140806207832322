import { ICalendarSaveAPIForm, ICreateCalendarGetSlotsAPIForm } from '../utils/interfaces';

import { IPaginationSort } from '../../../utils/interfaces';
import axios from 'axios';
import { env } from '../../../config';

export const calendarCreate = async (body: ICalendarSaveAPIForm) => {
  return axios.post(`${env.API_URL}/erdv/calendar`, {
    ...body,
    country: 'FR'
  });
};

export const calendarTimeSlotsBulk = async (body: ICreateCalendarGetSlotsAPIForm) => {
  return axios.get(
    `${env.API_URL}/erdv/calendar/${body.id_calendar}/schedule/${body.id_schedule}`,
    {
      params: {
        ...body
      }
    }
  );
};

export const calendarUpdate = async (body: ICalendarSaveAPIForm) => {
  return axios.put(`${env.API_URL}/erdv/calendar/${body.id}`, {
    ...body
  });
};

export const calendarDelete = async (id: number) => {
  return axios.delete(`${env.API_URL}/erdv/calendar/${id}`);
};

export const calendarListFetch = async (arg: {
  currentPage: number;
  sort?: IPaginationSort;
  search?: string;
}) => {
  const { currentPage, sort, search } = arg;
  const order =
    sort && sort.order_by
      ? `&order_by=${sort.order_by}&order_ascending=${sort.order_ascending}`
      : '';
  const search_str = search ? `&search=${search}` : '';
  return axios.get(
    `${env.API_URL}/erdv/calendar?offset=${currentPage * 10}&limit=10${order}${search_str}`
  );
};

export const calendarFetchById = async (arg: { calendarId: string }) => {
  const { calendarId } = arg;
  return axios.get(`${env.API_URL}/erdv/calendar/${calendarId}`);
};
