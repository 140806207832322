import React, { useRef, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';

type Position = 'bottom' | 'left' | 'top' | 'right';

interface DrawerProps {
  isOpen: boolean;
  position?: Position;
  onClose?: () => void;
  children: React.ReactNode;
  headerComponent?: React.ReactNode;
  headerBackgroundImage?: string;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const DrawerWrapper = styled.div<DrawerProps>`
  position: fixed;
  background-color: white;
  transition: all 300ms ease-in-out;
  z-index: 10;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  ${(props) => {
    switch (props.position) {
      case 'bottom':
        return `
          width: 100%;
          height: 30vh;
          bottom: ${props.isOpen ? '0' : '-40vh'};
          left: 0;
        `;
      case 'top':
        return `
          width: 100%;
          height: 40vh;
          top: ${props.isOpen ? '0' : '-40vh'};
          left: 0;
        `;
      case 'left':
        return `
          width: 40%;
          height: 100%;
          top: 0;
          left: ${props.isOpen ? '0' : '-40%'};
        `;
      case 'right':
        return `
          width: 40%;
          height: 100%;
          top: 0;
          right: ${props.isOpen ? '0' : '-40%'};
        `;
      default:
        return '';
    }
  }}

  animation: ${fadeIn} 300ms forwards;
  box-shadow: ${(props) => (props.isOpen ? '0px 0px 4px rgba(0, 0, 0, 0.1)' : 'none')};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px;
  gap: 8px;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #dcdce4;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  color: #a5a5ba;
`;

const Header = styled.div<{ backgroundImage?: string }>`
  height: 110px;
  border-bottom: 1px solid #f6f6f9;
  background-image: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
  background-size: cover;
  background-position: center;
`;

const Drawer: React.FC<DrawerProps> = ({
  isOpen = false,
  position = 'left',
  onClose,
  children,
  headerComponent,
  headerBackgroundImage
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        onClose?.();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    event?.stopPropagation();
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]);

  return (
    <Transition in={isOpen} timeout={300}>
      {(state) => (
        <DrawerWrapper ref={drawerRef} isOpen={state === 'entered'} position={position}>
          {isOpen && (
            <>
              <CloseButton onClick={onClose}>X</CloseButton>
              {(headerComponent || headerBackgroundImage) &&
                (position === 'left' || position === 'right') && (
                  <Header backgroundImage={headerBackgroundImage}>{headerComponent}</Header>
                )}
              {children}
            </>
          )}
        </DrawerWrapper>
      )}
    </Transition>
  );
};

export default Drawer;
