import { Controller, useFormContext } from 'react-hook-form';
import { StyledOrderSection, StyledOrderSiteWrapper } from '../utils/OrderStyles';
import {
  customVerticalityStyles,
  customVerticalityStylesWithError
} from '../../../style/customSelectStyle';
import { getErrorMessage, isFieldErrored } from '../../../utils/formHelper';

import { ErrorMessage } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import {
  IOrderInformationsForm,
  IOrderInformationsOperaBusinessForm
} from '../utils/orderInterfaces';
import InputWrapper from '@designSystem/InputWrapper';
import ReactSelect from 'react-select';
import { VerticalityContext } from '../../eligibility/VerticalityProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../app/hooks';
import { getBuildingIdFromAddress } from '@features/eligibility/helper';
import { selectAdressInfos } from '@features/eligibility/eligibilitySlice';

const OrderInformationsSiteForm = ({
  orderInformationsFormValues
}: {
  orderInformationsFormValues: IOrderInformationsForm | IOrderInformationsOperaBusinessForm;
}) => {
  const { buildingsList, stairsList, floorsList } = useContext(VerticalityContext);
  const { register, formState, clearErrors, setValue } = useFormContext();
  const { errors } = formState;
  const { t } = useTranslation();
  const addressInfos = useAppSelector(selectAdressInfos);

  return (
    <StyledOrderSection>
      <div className="section-title">
        {t('features.order.orderInformations.endClientSite', 'End Client site')}
      </div>
      <StyledOrderSiteWrapper>
        <div className="half-width">
          <InputWrapper
            inputLabel={t(
              'features.order.orderInformations.buildingReference',
              'Building reference'
            )}
          >
            <input
              disabled
              type="text"
              {...register('buildingRef')}
              placeholder={t('features.order.orderInformations.buildingRef', `Building reference`)}
              value={getBuildingIdFromAddress({
                id: addressInfos?.id ?? '',
                referenceType: addressInfos?.referenceType
              })}
              aria-label="buildingRef-input"
            />
          </InputWrapper>
        </div>
        <div>
          <InputWrapper inputLabel={t('features.order.orderInformations.address', 'Address')}>
            <input
              disabled
              type="text"
              {...register('address')}
              placeholder={t('features.order.orderInformations.address', `Address`)}
              value={orderInformationsFormValues.address}
              aria-label="address-input"
            />
          </InputWrapper>
        </div>
        <div className="section-row">
          <div className="section-row-item">
            <Controller
              name={'building'}
              rules={{
                required: { value: true, message: t('errorMessages.valueMissing', `Missing value`) }
              }}
              render={({ field: { onChange } }) => (
                <InputWrapper
                  inputLabel={t('features.order.orderInformations.building', 'Building')}
                >
                  <ReactSelect
                    styles={
                      isFieldErrored('building', errors)
                        ? customVerticalityStylesWithError
                        : customVerticalityStyles
                    }
                    inputId="building"
                    options={buildingsList}
                    value={{
                      value: orderInformationsFormValues.building,
                      label: orderInformationsFormValues.building
                    }}
                    onChange={(val: any) => {
                      onChange(val.value);
                      setValue('stair', stairsList?.length === 1 ? stairsList[0].value : undefined);
                      setValue('floor', floorsList?.length === 1 ? floorsList[0].value : undefined);
                      setValue('outlet', undefined);
                      clearErrors('building');
                    }}
                  />
                </InputWrapper>
              )}
            />
            {isFieldErrored('building', errors) && (
              <ErrorMessage>{getErrorMessage('building', formState)}</ErrorMessage>
            )}
          </div>
          <div className="section-row-item">
            <Controller
              name={'stair'}
              rules={{
                required: { value: true, message: t('errorMessages.valueMissing', `Missing value`) }
              }}
              render={({ field: { onChange } }) => (
                <InputWrapper inputLabel={t('features.order.orderInformations.stair', 'Stair')}>
                  <ReactSelect
                    styles={
                      isFieldErrored('stair', errors)
                        ? customVerticalityStylesWithError
                        : customVerticalityStyles
                    }
                    inputId="stair"
                    options={stairsList}
                    value={{
                      value: orderInformationsFormValues.stair,
                      label: orderInformationsFormValues.stair
                    }}
                    onChange={(val: any) => {
                      onChange(val.value);
                      setValue('floor', floorsList?.length === 1 ? floorsList[0].value : undefined);
                      setValue('outlet', undefined);
                      clearErrors('stair');
                    }}
                  />
                </InputWrapper>
              )}
            />
            {isFieldErrored('stair', errors) && (
              <ErrorMessage>{getErrorMessage('stair', formState)}</ErrorMessage>
            )}
          </div>
          <div className="section-row-item">
            <Controller
              name={'floor'}
              rules={{
                required: { value: true, message: t('errorMessages.valueMissing', `Missing value`) }
              }}
              render={({ field: { onChange } }) => (
                <InputWrapper inputLabel={t('features.order.orderInformations.level', 'Level')}>
                  <ReactSelect
                    styles={
                      isFieldErrored('floor', errors)
                        ? customVerticalityStylesWithError
                        : customVerticalityStyles
                    }
                    inputId="level"
                    options={floorsList}
                    value={{
                      value: orderInformationsFormValues.floor,
                      label: orderInformationsFormValues.floor
                    }}
                    onChange={(val: any) => {
                      onChange(val.value);
                      setValue('outlet', undefined);
                      clearErrors('floor');
                    }}
                  />
                </InputWrapper>
              )}
            />
            {isFieldErrored('floor', errors) && (
              <ErrorMessage>{getErrorMessage('floor', formState)}</ErrorMessage>
            )}
          </div>
        </div>
      </StyledOrderSiteWrapper>
    </StyledOrderSection>
  );
};

export default OrderInformationsSiteForm;
