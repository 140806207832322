import { RootStateOrAny, useSelector } from 'react-redux';

import { Control } from 'react-hook-form';
import { IVerticalityFormInputs } from '../../interfaces';
import InformationBlock from '@designSystem/InformationBlock';
import ProductVerticalityAlert from './ProductVerticalityAlert';
import ProductVerticalityModalStep1 from './productVerticalityModalStep1';
import React from 'react';
import ReactSelect from 'react-select';
import Spinner from '@designSystem/Spinner';
import { VerticalityProvider } from '../../VerticalityProvider';
import { customSelectStyles } from '../../../../style/customSelectStyle';
import { useTranslation } from 'react-i18next';

interface IProductVerticalityModalFormBodyProps {
  verticalityFormValues: IVerticalityFormInputs;
  setValue: CallableFunction;
  getValues: CallableFunction;
  control: Control<IVerticalityFormInputs>;
  step: number;
  showValidate?: boolean;
  isZTD?: boolean;
}

const ProductVerticalityModalFormBody: React.FC<IProductVerticalityModalFormBodyProps> = ({
  verticalityFormValues,
  setValue,
  getValues,
  control,
  step,
  isZTD = false,
  showValidate
}) => {
  const { verticalityLoading, verticalityError } = useSelector(
    (state: RootStateOrAny) => state.eligibility
  );

  const { outlet, multiAccess, existingOutlet } = verticalityFormValues;
  const { t } = useTranslation();
  const defineCurrentStepDisplay = () => {
    if (step === 1) {
      return (
        <VerticalityProvider getValues={getValues} setValue={setValue}>
          <ProductVerticalityModalStep1
            verticalityFormValues={verticalityFormValues}
            control={control}
            isZTD={isZTD}
            showValidate={showValidate}
          />
        </VerticalityProvider>
      );
    }
    return (
      <ReactSelect
        options={[]}
        value={{ value: outlet, label: outlet }}
        isDisabled
        styles={customSelectStyles}
      />
    );
  };

  if (!verticalityError && verticalityLoading) {
    return <Spinner spinnerSize={2} aria-label="postcode-spinner" center />;
  }

  return (
    <>
      {defineCurrentStepDisplay()}
      {showValidate && existingOutlet && outlet && !multiAccess && (
        <InformationBlock
          type="warning"
          style={{ margin: '0.5rem 0rem' }}
          title={t(
            'features.eligibility.products.productVerticality.beCareful',
            'Be careful if there is an active service on the selected outlet, it will be erased when connecting.  '
          )}
        ></InformationBlock>
      )}
      {showValidate && step === 1 && <ProductVerticalityAlert />}
    </>
  );
};

export default ProductVerticalityModalFormBody;
