import { Calendar2Fill, Search } from 'react-bootstrap-icons';
import { CustomCaption, RdpCustomStyle } from '../../utils/RdpCustomization';
import { DateRange, DayPicker } from 'react-day-picker';
import React, { Dispatch, ReactChild, SetStateAction, useCallback, useState } from 'react';
import { enGB, fr } from 'date-fns/locale';
import styled, { css } from 'styled-components';

import Button from '@designSystem/Button';
import CrossSvg from '../../images/pageHeader/cross.svg';
import { DateManager } from '../../utils/DateManager';
import { ITab } from './tabHeader/TabHeader';
import InputWrapper from '@designSystem/InputWrapper';
import Modal from '@designSystem/Modal';
import { PageHeaderSearchAndSortObject } from '../../utils/interfaces';
import { TabsHeader } from './tabHeader/TabsHeader';
import debounce from 'debounce';
import { i18n } from '@i18n';
import theme from '@theme';
import { useForm } from 'react-hook-form';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';
import { useTranslation } from 'react-i18next';

interface PageHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  updateData?: (_arg1: PageHeaderSearchAndSortObject) => void | null;
  currentFilterAndSort?: PageHeaderSearchAndSortObject;
  setCurrentFilterAndSort?: Dispatch<SetStateAction<PageHeaderSearchAndSortObject>>;
  rightContent?: ReactChild | null;
  totalRows?: number;
  totalRowsTitle?: ReactChild;
  inputPlaceholder?: string;
  allowZeroResults?: boolean;
  allowDateRange?: boolean;
  tabs?: ITab[];
  actionsDisabled?: boolean;
  trackingSearchName?: string;
  isClearSelectedTab?: boolean;
  resetOnClick?: boolean;
  hideSearch?: boolean;
}

const styles = css`
  padding: 0.75rem 1.875rem 0;
  background-color: ${theme.palette.grey[50]};
  border-bottom: none;
  border-bottom: 1px solid ${theme.palette.grey[200]};
  position: sticky;
  top: 0;
  z-index: 2;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.7);
  .header-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.125rem;
  }
`;

const StyledPageHeader = styled.div`
  ${styles}
`;

const PageHeader: React.FC<PageHeaderProps> = ({
  currentFilterAndSort,
  setCurrentFilterAndSort,
  updateData = null,
  rightContent,
  totalRows = -1,
  totalRowsTitle = i18n.t('app.results', 'results'),
  inputPlaceholder = i18n.t('app.search', 'Search'),
  allowZeroResults = false,
  trackingSearchName,
  allowDateRange = false,
  tabs,
  actionsDisabled,
  isClearSelectedTab,
  resetOnClick = false,
  hideSearch = false,
  ...otherProps
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [range, setRange] = useState<DateRange | undefined>();
  const [isModalWithTabs, setIsModalWithTabs] = useState<boolean>(false);
  const today = new Date();
  const { t } = useTranslation();
  const trackEvent = useMatomoHarmonizer();
  const { register, getValues, resetField } = useForm<{
    search: string;
    dateRange: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      search: '',
      dateRange: ''
    }
  });

  const hasSeparator =
    updateData && (currentFilterAndSort?.sort || currentFilterAndSort?.search !== undefined);

  const handleSearchChange = useCallback(
    debounce((search: string, range?: DateRange, state?: ITab['state']) => {
      const sortingInfo = currentFilterAndSort?.sort ? { sort: currentFilterAndSort.sort } : {};
      const startDate = range?.from ? new DateManager(range.from).format('YYYY-MM-DD') : '';
      let endDate = range?.to ? new DateManager(range.to).format('YYYY-MM-DD') : '';

      if (startDate === endDate) {
        endDate = '';
      }

      updateData && updateData({ ...sortingInfo, from: startDate, to: endDate, search, state });
    }, 500),
    [currentFilterAndSort, updateData]
  );

  const handleTracking = useCallback(
    debounce(
      () =>
        trackEvent({
          page: 'Header',
          category: trackingSearchName || '',
          actionType: 'Recherche',
          actionDetails: 'Text'
        }),
      2000
    ),
    []
  );

  const handleCombinedChange = (search: string, range?: DateRange, state?: ITab['state']) => {
    handleSearchChange(search, range, state);
    if (trackingSearchName) {
      handleTracking();
    }
  };
  const onFocusTrackEvent = (inputType: 'Texte' | 'Date') => {
    trackEvent({
      page: 'Mes_Commandes',
      category: 'Search_Bar',
      actionType: 'Clic',
      actionDetails: `Rechercher_${inputType}`
    });
  };

  const getRangeStringForInput = () => {
    const startDate = range?.from ? new DateManager(range.from).format('DD/MM/YYYY') : '';
    const endDate = range?.to ? new DateManager(range.to).format('DD/MM/YYYY') : '';

    if (endDate && startDate !== endDate) {
      return `${startDate} — ${endDate}`;
    } else {
      return startDate;
    }
  };

  const onClickTabHeader = () => {
    if (resetOnClick) resetField('search');
    return getValues('search');
  };

  return (
    <StyledPageHeader {...otherProps}>
      <div className="header-search">
        <StyledLeftContainer id="search-info-header">
          {!hideSearch && (
            <>
              <InputWrapper icon={<Search />}>
                <>
                  <input
                    type="text"
                    disabled={actionsDisabled}
                    {...register('search')}
                    onChange={(e) => handleCombinedChange(e.target.value, range)}
                    onFocus={() => onFocusTrackEvent('Texte')}
                    aria-label="search-input"
                    placeholder={inputPlaceholder}
                  />
                  {currentFilterAndSort?.search?.length && setCurrentFilterAndSort ? (
                    <StyledInputClearable>
                      <img
                        src={CrossSvg}
                        onClick={() => {
                          setCurrentFilterAndSort({ ...currentFilterAndSort, search: '' });
                          resetField('search');
                        }}
                        alt="icon"
                      />
                    </StyledInputClearable>
                  ) : null}
                </>
              </InputWrapper>
              {allowDateRange && (
                <InputWrapper icon={<Calendar2Fill />} iconSize={'12px'}>
                  <>
                    <input
                      id="search-datepicker-input"
                      type="text"
                      disabled={actionsDisabled}
                      {...register('dateRange')}
                      value={getRangeStringForInput()}
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                      onFocus={() => onFocusTrackEvent('Date')}
                      autoComplete="off"
                      aria-label="search-datepicker-input"
                      placeholder={t(
                        'component.pageHeader.searchBy.creationDate',
                        `Search by creation date`
                      )}
                    />
                    {range && (
                      <StyledInputClearable
                        onClick={() => {
                          if (!actionsDisabled) {
                            setRange(undefined);
                            handleCombinedChange(getValues('search'));
                          }
                        }}
                      >
                        <img
                          src={CrossSvg}
                          onClick={() => {
                            setCurrentFilterAndSort &&
                              setCurrentFilterAndSort({ ...currentFilterAndSort, search: '' });
                            resetField('search');
                          }}
                          alt="icon"
                        />
                      </StyledInputClearable>
                    )}
                  </>
                </InputWrapper>
              )}
            </>
          )}
          {(totalRows > 0 || allowZeroResults) && (
            <StyledTotalRowsContainer>
              {hasSeparator && <StyledSeparator />}
              <StyledTotalRowsNumber>{totalRows}</StyledTotalRowsNumber>
              <StyledTotalRowsTitle>{totalRowsTitle}</StyledTotalRowsTitle>
            </StyledTotalRowsContainer>
          )}
        </StyledLeftContainer>
        {rightContent}
        {allowDateRange && (
          <Modal
            show={isModalOpen}
            width="350px"
            onClose={() => {
              setIsModalOpen(false);
            }}
          >
            <>
              <style>{RdpCustomStyle}</style>
              <DayPicker
                mode="range"
                defaultMonth={today}
                fixedWeeks
                selected={range}
                modifiers={{
                  disabled: { after: new Date() }
                }}
                onSelect={setRange}
                toDate={today}
                components={{
                  Caption: CustomCaption
                }}
                modifiersClassNames={{
                  selected: 'day-selected',
                  today: 'day-today'
                }}
                modifiersStyles={{
                  disabled: { fontSize: '75%' }
                }}
                pagedNavigation
                locale={i18n.language === 'fr' ? fr : enGB}
                numberOfMonths={1}
              />
              <StyledPopOverFooter>
                <Button
                  design="white"
                  onClick={() => setIsModalOpen(false)}
                  className="popover_footer_button"
                >
                  {t('component.button.close', 'close')}
                </Button>
                <Button
                  className="popover_footer_button popover_footer_button_marged"
                  onClick={() => {
                    handleCombinedChange(getValues('search'), range);
                    setIsModalOpen(false);
                  }}
                  disabled={!range?.from}
                >
                  {t('component.button.confirm', 'close')}
                </Button>
              </StyledPopOverFooter>
            </>
          </Modal>
        )}
      </div>
      {!actionsDisabled && tabs && (
        <TabsHeader
          tabsState={tabs}
          getValues={onClickTabHeader}
          range={range}
          isClearSelectedTab={isClearSelectedTab}
          setIsOpenModal={setIsModalWithTabs}
          isModalWithTabs={isModalWithTabs}
          handleCombinedChange={handleCombinedChange}
        />
      )}
    </StyledPageHeader>
  );
};

export default PageHeader;

const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 10px;

  input[id='search-datepicker-input'] {
    width: 250px !important;
  }
`;

const StyledSeparator = styled.div`
  display: flex;
  border-left: 1px solid ${theme.palette.grey[300]};
  min-width: 1px;
  min-height: 24px;
  margin-left: 4px;
  margin-right: 12px;
`;

const StyledTotalRowsContainer = styled.div`
  display: flex;
  color: ${theme.palette.grey[900]};
  flex-direction: row;
  align-items: center;
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.base};
  line-height: 24px;
`;

const StyledTotalRowsNumber = styled.div`
  display: flex;
  color: ${theme.palette.grey[900]};
  font-size: ${theme.fonts.size.xs};
`;

const StyledTotalRowsTitle = styled.div`
  display: flex;
  color: ${theme.palette.grey[400]};
  margin-left: 6px;
  min-width: 140px;
  flex: 1 0 auto;
  font-size: ${theme.fonts.size.xs};
`;

const StyledPopOverFooter = styled.div`
  display: flex;

  .popover_footer_button {
    flex: 1 1;
  }

  .popover_footer_button_marged {
    margin-left: 10px;
  }
`;

const StyledInputClearable = styled.span`
  color: ${(props) => props.theme.palette.grey[400]};
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 3px;
`;
