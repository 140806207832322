import type { AppDispatch, RootState } from './store';
import { RootStateOrAny, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  getFullOpsRoute,
  getRule,
  hasAnyKnownRole,
  hasDSPRole,
  hasErdvRole,
  hasFullBetaRole,
  hasObservabilityRole
} from '../components/layout/const';

import { Role } from '../utils/role.types';
import { isPermitted } from './AuthService';
import { selectProfilePending } from '@features/profiles/profileSlice';
import { useLocation } from 'react-router-dom';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useRedirect = (): { redirect?: string; isAbsolute?: boolean } => {
  const { pathname } = useLocation();
  const profileLoading = useAppSelector(selectProfilePending);
  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);
  if (profileLoading || !profileType) {
    return {};
  }
  const roles: Role[] = profileType.rolesNames;

  // Internal redirect logic
  const rule = getRule(pathname);

  const hasMissingProps = !roles || !rule;

  const shouldRedirect = !isPermitted(roles, rule?.authorizedProfiles);

  if (hasMissingProps || shouldRedirect) {
    const isDSP = hasDSPRole(roles);
    if (isDSP) return { redirect: '/dashboard/ajournements' };

    const isFullBetaRole = hasFullBetaRole(roles);
    if (isFullBetaRole) return { redirect: '/ordersBeta/tracking' };

    const isAnyKnownRole = hasAnyKnownRole(roles);
    if (isAnyKnownRole) return { redirect: '/eligibility' };

    const isERDVRole = hasErdvRole(roles);
    if (isERDVRole) return { redirect: '/erdv' };

    if (hasObservabilityRole(roles)) return { redirect: getFullOpsRoute(), isAbsolute: true };

    return pathname !== '/' ? { redirect: '/' } : {};
  }

  return {};
};
