import CheckedGreenCircleSVG from '../../../images/order/tracking/checked-green-circle.svg';
import EllipseAcknowledgedGreenSVG from '../../../images/order/tracking/ellipse-acknowledged-green.svg';
import EllipseCompletedGreenSVG from '../../../images/order/tracking/ellipse-completed-green.svg';
import EllipseInProgressGreenSVG from '../../../images/order/tracking/ellipse-in-progress-green.svg';
import EllipseOrangeSVG from '../../../images/order/tracking/ellipse-orange.svg';
import EllipseRedSVG from '../../../images/order/tracking/ellipse-red.svg';
import EllipseSubmittedGreenSVG from '../../../images/order/tracking/ellipse-submitted-green.svg';
import ExclamationCircleOrangeSVG from '../../../images/order/tracking/exclamation-circle-orange.svg';
import ExclamationCircleRedSVG from '../../../images/order/tracking/exclamation-circle-red.svg';
import { IGeographicAddress } from '@features/eligibility/interfaces';
import { ProductOrderItem } from '@features/order/utils/orderInterfaces';
import { ThemeVariants } from '@theme';
import { TFunction } from 'i18next';

export enum OrderSteps {
  SELECT_ADDRESS = 1,
  SELECT_PRODUCT = 2,
  ORDER_INFO_FORM = 3,
  APPOINTMENT = 4,
  RECAP = 5
}

export interface OrdersListPaginateModel extends OrdersPaginateModel {
  orders: Order[];
}

interface OrdersPaginateModel {
  total: number;
}

export interface Order {
  address?: IGeographicAddress;
  externalReference: string;
  internalReference: string;
  name: string;
  orderDate: string;
  orderId: string;
  buildingId: string;
  state: {
    status: OrderState;
    lastEvent?: { name: string; date: string };
  };
  productOrderItem: ProductOrderItem[];
}

interface IStatus {
  iconStatus: string;
  color: ThemeVariants;
  status: string;
  iconLabel: string;
}

type StateStatusGreen = 'submitted' | 'acknowledged' | 'inProgress' | 'completed';
type StateStatusOrange =
  | 'held'
  | 'pending'
  | 'assessing_cancellation'
  | 'pending_cancellation'
  | 'created'
  | 'draft'
  | 'pending_info_request'
  | 'pending_oc_info_request';
type StateStatusRed = 'failed' | 'rejected' | 'cancelled';
export type OrderState = StateStatusGreen | StateStatusOrange | StateStatusRed;
type StatusStrategy = Record<OrderState, IStatus>;

export const statusStrategy: (
  t: TFunction<'translation', undefined, 'translation'>
) => StatusStrategy = (t: TFunction<'translation', undefined, 'translation'>) => ({
  //orange
  held: {
    iconStatus: EllipseOrangeSVG,
    color: 'orange',
    status: t('enums.orderState.status.held', 'Held'),
    iconLabel: ExclamationCircleOrangeSVG
  },
  pending: {
    iconStatus: EllipseOrangeSVG,
    color: 'orange',
    status: t('enums.orderState.status.pending', 'Pending'),
    iconLabel: ExclamationCircleOrangeSVG,
    label: 'On-site intervention failed'
  },
  assessing_cancellation: {
    iconStatus: EllipseOrangeSVG,
    color: 'orange',
    status: t('enums.orderState.status.assessing_cancellation', 'Assessing cancellation'),
    iconLabel: ExclamationCircleOrangeSVG
  },
  pending_cancellation: {
    iconStatus: EllipseOrangeSVG,
    color: 'orange',
    status: t('enums.orderState.status.pending_cancellation', 'Pending cancellation'),
    iconLabel: ExclamationCircleOrangeSVG
  },
  created: {
    iconStatus: EllipseOrangeSVG,
    color: 'orange',
    status: t('enums.orderState.status.create', 'Create'),
    iconLabel: ExclamationCircleOrangeSVG
  },
  draft: {
    iconStatus: EllipseOrangeSVG,
    color: 'orange',
    status: t('enums.orderState.status.draft', 'Draft'),
    iconLabel: ExclamationCircleOrangeSVG
  },
  pending_info_request: {
    iconStatus: EllipseOrangeSVG,
    color: 'orange',
    status: t('enums.orderState.status.pending_info_request', 'Pending info request'),
    iconLabel: ExclamationCircleOrangeSVG
  },
  pending_oc_info_request: {
    iconStatus: EllipseOrangeSVG,
    color: 'orange',
    status: t('enums.orderState.status.pending_oc_info_request', 'Pending oc info request'),
    iconLabel: ExclamationCircleOrangeSVG
  },

  //red
  rejected: {
    iconStatus: EllipseRedSVG,
    color: 'red',
    status: t('enums.orderState.status.rejected', 'Rejected'),
    iconLabel: ExclamationCircleRedSVG
  },
  cancelled: {
    iconStatus: EllipseRedSVG,
    color: 'red',
    status: t('enums.orderState.status.cancelled', 'Rejected'),
    iconLabel: ExclamationCircleRedSVG
  },
  failed: {
    iconStatus: EllipseRedSVG,
    color: 'red',
    status: t('enums.orderState.status.failed', 'Failed'),
    iconLabel: ExclamationCircleRedSVG
  },

  //green
  submitted: {
    iconStatus: EllipseSubmittedGreenSVG,
    color: 'green',
    status: t('enums.orderState.status.submitted', 'Submitted'),
    iconLabel: CheckedGreenCircleSVG
  },
  acknowledged: {
    iconStatus: EllipseAcknowledgedGreenSVG,
    color: 'green',
    status: t('enums.orderState.status.acknowledged', 'Acknowledged'),
    iconLabel: CheckedGreenCircleSVG
  },
  completed: {
    iconStatus: EllipseCompletedGreenSVG,
    color: 'green',
    status: t('enums.orderState.status.completed', 'Completed'),
    iconLabel: CheckedGreenCircleSVG
  },
  inProgress: {
    iconStatus: EllipseInProgressGreenSVG,
    color: 'green',
    status: t('enums.orderState.status.inProgress', 'In progress'),
    iconLabel: CheckedGreenCircleSVG
  }
});

type Year = `${number}${number}${number}${number}`;
type Month = `${number}${number}`;
type Day = `${number}${number}`;

export type YearMonthDay = `${Year}-${Month}-${Day}`;

export enum InterventionTypeLabel {
  BRASSAGE = 'BRASSAGE',
  INSTALLATION = 'INSTALLATION'
}

export const siretAbsenceReasonsList = (t: TFunction<'translation', undefined, 'translation'>) => [
  {
    value: "Entreprise en cours d'immatriculation",
    label: t('features.order.orderInformations.siretAbsenceReasons.companyInProcessOfRegistration')
  },
  {
    value: "Etablissement en cours d'immatriculation",
    label: t(
      'features.order.orderInformations.siretAbsenceReasons.establishmentInProcessOfRegistration'
    )
  },
  {
    value: 'Etablissement non immatriculé',
    label: t('features.order.orderInformations.siretAbsenceReasons.unregisteredEstablishment')
  },
  {
    value: 'Site technique',
    label: t('features.order.orderInformations.siretAbsenceReasons.technicalSite')
  },
  {
    value: 'Site temporaire de type chantier',
    label: t('features.order.orderInformations.siretAbsenceReasons.temporaryConstructionSite')
  }
];
