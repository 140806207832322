import { FieldErrors, FieldValues, FormState } from 'react-hook-form';

import { t } from 'i18next';

export const isFieldErrored = (field: string, errors: FieldErrors): boolean => {
  return !!errors[field];
};

export const getErrorMessage = (
  field: string,
  { errors }: Partial<FormState<FieldValues>> = { errors: {} },
  withoutMessage?: boolean
): string | null => {
  if (withoutMessage) {
    return ' ';
  }
  if (errors && isFieldErrored(field, errors)) {
    const error = `${errors[field]?.message}`;

    if (error === '') {
      return t('errorMessages.invalidInput', `Invalid input`);
    }

    return error;
  }

  return null;
};
