import { ThemeVariants, ThemeVariantsDeclination } from '../../style/theme';

import { IThemedComponent } from '../designInterfaces';
import React from 'react';
import { defaultStyle } from './designStyles';
import styled from 'styled-components';

export interface IThemedIcon extends IThemedComponent {
  background?: boolean;
  border?: boolean;
  round?: boolean;
  iconSize?: string;
  variantDeclination?: ThemeVariantsDeclination;
  fillColor?: string;
  noBackground?: boolean;
  size?: 'large' | 'regular' | 'small' | 'minimal';
}

interface IconProps extends Omit<React.HTMLProps<HTMLElement>, 'size'> {
  background?: boolean; // If background is true, Icon will be white filled
  border?: boolean;
  round?: boolean;
  iconSize?: string;
  size?: 'large' | 'regular' | 'small' | 'minimal';
  variant?: ThemeVariants;
  variantDeclination?: ThemeVariantsDeclination;
  fillColor?: string;
  noBackground?: boolean;
}

const styles = defaultStyle();

const StyledIcon = styled.div<IThemedIcon>`
  ${styles}
`;

const Icon: React.FC<IconProps> = ({ variant = 'blue', ref, as, ...props }) => {
  return <StyledIcon variant={variant} {...props} />;
};

export default Icon;
