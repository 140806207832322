import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import theme from '@theme';

const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${theme.palette.grey[200]};
  border-radius: 0 4px 4px 0;
  margin-bottom: 1rem;
  position: relative;
  div {
    height: 10px;
    border-radius: 0 4px 4px 0;
    background-image: linear-gradient(
      to right,
      ${theme.palette.red[500]},
      ${theme.palette.red[500]},
      ${theme.palette.orange[500]},
      ${theme.palette.yellow[500]},
      ${theme.palette.green[500]},
      ${theme.palette.blue[500]},
      ${theme.palette.blue[500]}
    );
    background-size: 300% 100%;
    background-position: 100% 50%;
    margin-bottom: 1rem;
    transition: width 0.5s;
  }
`;

const FakeProgressBar: React.FC<{ time: number }> = ({ time }) => {
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [newTime, setNewTime] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((newElapsedTime) => newElapsedTime + 10);
    }, 10);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setNewTime(elapsedTime + time);
  }, [time]);

  const width = Math.min((elapsedTime / newTime) * 100, 100);

  return (
    <ProgressBar>
      <div style={{ width: `${width}%`, backgroundPosition: `${width}% 50%` }} />
    </ProgressBar>
  );
};

export default FakeProgressBar;
