import Button from '@designSystem/Button';
import { Container } from 'react-bootstrap';
import React from 'react';
import theme from '@theme';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error: React.FC<{ error: Error; resetErrorBoundary: () => void; errorCode?: number }> = ({
  error,
  errorCode,
  resetErrorBoundary
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container fluid className="main-content-container">
      <div className="error">
        <div className="error__content">
          <h2>{errorCode}</h2>
          <h3>
            {errorCode === 404
              ? t('errorMessages.notFound', 'Not found')
              : t('errorMessages.wentWrong', 'Something went wrong')}
          </h3>
          <p>{error.message}</p>
          <Button
            onClick={() => {
              resetErrorBoundary();
              navigate(-1);
            }}
            style={{ fontWeight: theme.fonts.weight.regular }}
          >
            &larr; {t('component.button.goBack', 'Go Back')}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Error;
