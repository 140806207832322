import styled, { CSSProperties } from 'styled-components';

import React from 'react';
import { ThemeProps } from '../../style/theme';
import { defaultStyle } from './designStyles';

export const TableWrapper = styled.div`
  border-width: 1px;
  border-radius: 8px 8px;
  border-style: solid;
  border: 1px solid ${(props: { theme: ThemeProps }) => props.theme.palette['grey'][200]};
  padding: 0px;
  background: white;

  .table_header {
    border-radius: 8px 8px 0 0;
    padding: 1.125rem 1.875rem;
    background-color: #fff;
    border-bottom: none;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${(props: { theme: ThemeProps }) => props.theme.palette['grey'][200]};
  }
`;

export interface TableProps {
  children?: React.ReactNode;
  theme?: ThemeProps;
  style?: CSSProperties;
  className?: string;
  role?: string;
  maxCols?: number;
}
const styles = defaultStyle();

const StyledTable = styled.table<TableProps>`
  ${styles}
`;

const Table = ({ style, ...props }: TableProps) => {
  return <StyledTable {...props} />;
};

export default Table;
