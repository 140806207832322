import {
  ICalendarLastWeekSlotFromApi,
  ICalendarLastWeekSlotsForForm
} from '@features/calendars/utils/interfaces';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  calendarFetchById,
  calendarTimeSlotsBulk,
  resetCurrentCalendar,
  setCalendarLastWeekSlots
} from '@features/calendars/reducer/calendarSlice';
import {
  mergeLastWeekSlots,
  transformApiSlotsForCalendarForm
} from '@features/calendars/utils/calendarFunctions';

import CalendarForm from '@features/calendars/calendarForm/CalendarForm';
import { Container } from 'react-bootstrap';
import Error from '../error';
import FixedSpinner from '../../components/FixedSpinner';
import styled from 'styled-components';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { DateManager } from '../../utils/DateManager';

const StyledCalendarFormContainer = styled(Container)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const CalendarUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const { calendarId } = useParams();
  const { currentCalendar, currentCalendarLoading } = useSelector(
    (state: RootStateOrAny) => state.calendars
  );

  const defaultSlotsValue = {
    slotsForMonday: { morning: 0, afternoon: 0, enabled: false },
    slotsForTuesday: { morning: 0, afternoon: 0, enabled: false },
    slotsForWednesday: { morning: 0, afternoon: 0, enabled: false },
    slotsForThursday: { morning: 0, afternoon: 0, enabled: false },
    slotsForFriday: { morning: 0, afternoon: 0, enabled: false }
  };

  const [lastWeekMorningSlots, setLastWeekMorningSlots] =
    useState<ICalendarLastWeekSlotsForForm>(defaultSlotsValue);

  const [lastWeekAfternoonSlots, setLastWeekAfternoonSlots] =
    useState<ICalendarLastWeekSlotsForForm>(defaultSlotsValue);

  useEffect(() => {
    dispatch(resetCurrentCalendar());
    dispatch(setCalendarLastWeekSlots(null));
    if (calendarId) {
      dispatch(calendarFetchById({ calendarId }))
        .then(unwrapResult)
        .then((calendar) => {
          const tempSlotsValue = { ...defaultSlotsValue };

          dispatch(
            calendarTimeSlotsBulk({
              id_calendar: parseInt(calendar.id, 10),
              id_schedule: parseInt(calendar.schedules[0].id),
              start_day: new DateManager().startOf('week').format('YYYY-MM-DD'),
              end_day: new DateManager().add(1, 'year').endOf('week').format('YYYY-MM-DD')
            })
          )
            .then(unwrapResult)
            .then((slots: ICalendarLastWeekSlotFromApi[]) => {
              setLastWeekMorningSlots(
                transformApiSlotsForCalendarForm(tempSlotsValue, slots.slice(-7), 'morning')
              );
            });
          dispatch(
            calendarTimeSlotsBulk({
              id_calendar: parseInt(calendar.id, 10),
              id_schedule: parseInt(calendar.schedules[1].id),
              start_day: new DateManager().startOf('week').format('YYYY-MM-DD'),
              end_day: new DateManager().add(1, 'year').endOf('week').format('YYYY-MM-DD')
            })
          )
            .then(unwrapResult)
            .then((slots: ICalendarLastWeekSlotFromApi[]) => {
              setLastWeekAfternoonSlots(
                transformApiSlotsForCalendarForm(tempSlotsValue, slots.slice(-7), 'afternoon')
              );
            });
        });
    }
  }, [calendarId]);

  useEffect(() => {
    const mergedSlots = mergeLastWeekSlots(lastWeekMorningSlots, lastWeekAfternoonSlots);
    dispatch(setCalendarLastWeekSlots(mergedSlots));
  }, [lastWeekMorningSlots, lastWeekAfternoonSlots]);

  return (
    <StyledCalendarFormContainer>
      <FixedSpinner loading={currentCalendarLoading}>
        {currentCalendar && currentCalendar.id && <CalendarForm />}
        {!currentCalendar && !currentCalendarLoading && (
          <Error
            error={{ message: 'Calendar not found', name: '' }}
            resetErrorBoundary={() => null}
            errorCode={404}
          />
        )}
      </FixedSpinner>
    </StyledCalendarFormContainer>
  );
};

export default CalendarUpdate;
