import { FileEarmarkBarGraphFill } from 'react-bootstrap-icons';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const OrderInformationsHeader = () => {
  const { t } = useTranslation();
  return (
    <StyledOrderHeaderContainer>
      <div className="header-title-container">
        <span className="icon-title">
          <FileEarmarkBarGraphFill />
        </span>
        <div className="header-title">
          {t('features.order.orderInformations.orderInfo', 'Order informations')}
        </div>
      </div>
      <div className="header-subtitle">
        {t(
          'features.order.orderInformations.pleaseFill',
          'Please fill out order related informations'
        )}
      </div>
    </StyledOrderHeaderContainer>
  );
};

export default OrderInformationsHeader;

const StyledOrderHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  .header-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-title {
      font-size: ${theme.fonts.size.lg};
      color: ${theme.palette.grey[300]};
      padding-right: 0.5rem;
    }

    .header-title {
      font-weight: ${theme.fonts.weight.medium};
      font-size: ${theme.fonts.size.sm};
      line-height: ${theme.fonts.spacing.sm};

      color: ${theme.palette.grey[900]};
    }
  }

  .header-subtitle {
    font-weight: ${theme.fonts.weight.regular};
    font-size: ${theme.fonts.size.xs};
    line-height: ${theme.fonts.spacing.xs};

    color: ${theme.palette.grey[500]};
  }
`;
