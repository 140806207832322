import { useTranslation } from 'react-i18next';

import Button from '@designSystem/Button';
import Icon from '@designSystem/Icon';

import {
  StyledOrderRecapConfirmModalButtonContainer,
  StyledOrderRecapConfirmModalDescription,
  StyledOrderRecapConfirmModalSpinnerContainer,
  StyledOrderRecapConfirmModalTitle
} from './OrderRecapConfirmModalStyles';
import MsgRequest from '../../../../images/order/tracking/msg-request.svg';

type OrderRecapConfirmModalRequestsType = {
  cancelBtnClicked: () => void;
  title: string;
  subtitle: string;
  confirmAction: () => void;
  confirmationText?: string;
};
const OrderRecapConfirmModalRequests = ({
  title,
  subtitle,
  confirmAction,
  cancelBtnClicked,
  confirmationText
}: OrderRecapConfirmModalRequestsType) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledOrderRecapConfirmModalSpinnerContainer>
        <Icon variant="blue" round>
          <img src={MsgRequest} alt="requests" />
        </Icon>
      </StyledOrderRecapConfirmModalSpinnerContainer>
      <StyledOrderRecapConfirmModalTitle>{title}</StyledOrderRecapConfirmModalTitle>
      <StyledOrderRecapConfirmModalDescription>{subtitle}</StyledOrderRecapConfirmModalDescription>
      <StyledOrderRecapConfirmModalButtonContainer>
        <Button design="white" style={{ width: '100%' }} onClick={cancelBtnClicked}>
          {t('component.button.cancel', 'Cancel')}
        </Button>
        <Button style={{ width: '100%' }} onClick={confirmAction}>
          {confirmationText || t('component.button.confirm', 'Confirm')}
        </Button>
      </StyledOrderRecapConfirmModalButtonContainer>
    </>
  );
};

export default OrderRecapConfirmModalRequests;
