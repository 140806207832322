import React, { useEffect } from 'react';
import SelectSearchComponent, { SelectSearchProps } from './SelectSearchComponent';
import { StyledFormControl, StyledWrapComponent } from './SearchStyle';
import { clearSearchType, setSearchType } from '../eligibilitySlice';

import { decodeDataFrom } from '../helper';
import { useAppDispatch } from '../../../app/hooks';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchByOtoRef: React.FC<{
  selectProps?: SelectSearchProps;
}> = ({ selectProps }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { register, watch } = useForm<{ otoRef: string }>({
    mode: 'onChange',
    defaultValues: {
      otoRef: decodeDataFrom(searchParams).otoRef || ''
    }
  });
  const { t } = useTranslation();
  const otoRef = watch('otoRef');

  useEffect(() => {
    if (otoRef) {
      dispatch(
        setSearchType({
          type: 'initialOTO',
          data: { otoRef }
        })
      );
    } else {
      dispatch(clearSearchType());
    }
  }, [otoRef]);

  return (
    <>
      <StyledWrapComponent>
        <StyledFormControl
          type="text"
          {...register('otoRef')}
          value={otoRef}
          aria-label="otoRef-input"
          placeholder={t('features.eligibility.search.exactOto', 'Enter the exact OTO reference')}
        />
        {selectProps && <SelectSearchComponent {...selectProps} />}
      </StyledWrapComponent>
    </>
  );
};

export default SearchByOtoRef;
