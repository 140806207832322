import React, { useEffect } from 'react';
import ZoneGeoReferenceFormInputs, { ZoneGeoRefFormData } from './ZoneGeoReferenceFormInputs';
import { zoneGeoRefCreate, zoneGeoRefUpdate } from '../reducer/referencesSlice';

import Button from '@designSystem/Button';
import { GeoAltFill } from 'react-bootstrap-icons';
import { IZoneGeoRef } from '../interfaces';
import Modal from '@designSystem/Modal';
import ModalContentWithIcon from '@designSystem/Modal/ModalContentWithIcon';
import toast from 'react-hot-toast';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../app/hooks';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ZoneGeoReferenceForm: React.FC<{
  currentRef?: IZoneGeoRef;
  closeFn: () => void;
  onSuccess: () => void;
}> = ({ currentRef, closeFn, onSuccess }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const { register, control, watch, setValue } = useForm<ZoneGeoRefFormData>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      typology: undefined
    }
  });
  const name = watch('name');
  const typology = watch('typology');

  useEffect(() => {
    if (currentRef) {
      setValue('name', currentRef?.reference);
      setValue('typology', {
        label: currentRef?.ref_type_label,
        value: currentRef?.ref_type
      });
    }
  }, [currentRef]);

  const saveReference = () => {
    if (typology?.value && name && currentRef && params.zoneId) {
      const newReferenceParams = {
        name: name,
        typology: typology.value,
        zoneId: params.zoneId
      };
      if (currentRef.id) {
        dispatch(zoneGeoRefUpdate({ ...newReferenceParams, id: currentRef.id }))
          .then(unwrapResult)
          .then(onSuccess)
          .then(closeFn);
      } else {
        dispatch(zoneGeoRefCreate(newReferenceParams))
          .then(unwrapResult)
          .then(onSuccess)
          .then(closeFn);
      }
    } else {
      toast.error(t('errorMessages.valueMissing', `Some values are missing`));
    }
  };

  const modalDesc = () =>
    currentRef && currentRef.id
      ? t('features.zonesGeo.zoneGeoReferencesForm.canUpdate', 'You can update the reference')
      : t(
          'features.zonesGeo.zoneGeoReferencesForm.wantCreate',
          'Select the reference you want to create'
        );
  const modalTitle = () =>
    currentRef && currentRef.id
      ? t('features.zonesGeo.zoneGeoReferencesForm.updateReference', 'Update reference')
      : t('features.zonesGeo.zoneGeoReferencesForm.createReference', 'Create reference');

  return (
    <Modal
      show={!!currentRef}
      onClose={closeFn}
      footer={
        <div>
          <Button design="white" onClick={closeFn}>
            {t('component.button.cancel', 'Cancel')}
          </Button>
          <Button disabled={!(name && typology?.value)} onClick={() => saveReference()}>
            {t('component.button.save', 'Save')}
          </Button>
        </div>
      }
    >
      <>
        <ModalContentWithIcon title={modalTitle()} description={modalDesc()}>
          <GeoAltFill />
        </ModalContentWithIcon>
        <ZoneGeoReferenceFormInputs
          register={register}
          control={control}
          formValues={{ name, typology }}
        />
      </>
    </Modal>
  );
};

export default ZoneGeoReferenceForm;
