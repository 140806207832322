import { ProductOfferingQualificationItem } from '../../interfaces';
import ProductNameAndDelais from '../productDetail/ProductNameAndDelais';
import ProductPriceAndFees from '../productDetail/ProductPriceAndFees';
import React from 'react';
import styled from 'styled-components';

const StyledProductInfosDetails = styled.div`
  display: flex;
  flex-direction: row;

  & div + div {
    flex: 1 0 auto;
    text-align: right;
  }
`;

const ResultsItemMinified: React.FC<{
  selectedProduct: ProductOfferingQualificationItem;
  canDisplayPrice?: boolean;
}> = ({ selectedProduct, canDisplayPrice }) => {
  return (
    <StyledProductInfosDetails>
      <ProductNameAndDelais product={selectedProduct} />
      {canDisplayPrice && <ProductPriceAndFees product={selectedProduct} />}
    </StyledProductInfosDetails>
  );
};

export default ResultsItemMinified;
