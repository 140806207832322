import { IThemedIcon } from '.';
import { css } from 'styled-components';

export const defaultStyle = () => {
  return css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: ${(props: IThemedIcon) => (props.size ? iconSize(props) : '2.5rem')};
    width: ${(props: IThemedIcon) => (props.size ? iconSize(props) : '2.5rem')};
    background-color: ${(props: IThemedIcon) => getBgColor(props)};
    border: ${(props: IThemedIcon) => getBorder(props)};
    border-radius: ${(props: IThemedIcon) => (props.round ? '50%' : '6px')};

    & svg {
      font-size: ${(props: IThemedIcon) => props.iconSize || '1.5rem'};
      fill: ${(props: IThemedIcon) => getFillColor(props)};
      color: ${(props: IThemedIcon) => getFillColor(props)};
    }
  `;
};

const getBorder = (props: IThemedIcon) => {
  const variantWeight = props.variantDeclination || 200;

  if (props.border) {
    return `1px solid ${props.theme.palette[props.variant][variantWeight]}`;
  }
  return 'none';
};

const getFillColor = (props: IThemedIcon) => {
  if (props.fillColor) {
    return props.fillColor;
  }

  const variantWeight = props.variantDeclination || 500;
  return props.background ? 'white' : props.theme.palette[props.variant][variantWeight];
};

const getBgColor = (props: IThemedIcon) => {
  if (props.noBackground) {
    return '';
  }
  const variantWeight = props.variantDeclination || 500;
  return props.theme.palette[props.variant][props.background ? variantWeight : 50];
};

const iconSize = (props: IThemedIcon) => {
  switch (props.size) {
    case 'large':
      return '3rem';
    case 'minimal':
      return '1.25rem';
    case 'small':
      return '1.75rem';
    case 'regular':
    default:
      return '2.5rem';
  }
};
