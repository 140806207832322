import { IDataCoordinates, IGeographicAddress } from './interfaces';
import React, { FunctionComponent, RefObject, useEffect, useState } from 'react';
import { buildSearchQueryByInfos, getDefaultCenterFromSearch } from './select/helper';
import {
  loadGeographicAddresses,
  selectAdressInfos,
  selectLastApiError,
  selectSearchInfos,
  setAddress,
  setSearchType
} from './eligibilitySlice';
import { trackingPages, useMatomoHarmonizer } from '../../utils/matomoHarmonizer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import AddressesCard from './select/AddressesCard';
import { Alert } from 'react-bootstrap';
import Button from '@designSystem/Button';
import Card from '@designSystem/Card';
import EligGoogleMap from './select/EligGoogleMap';
import HeaderCardCustom from './HeaderCardCustom';
import InformationBlock from '@designSystem/InformationBlock';
import LinkOrAnchor from '@designSystem/LinkOrAnchor';
import SelectSiteHeader from './SelectSiteHeader';
import Spinner from '@designSystem/Spinner';
import { mailtoAxione } from './const';
import { scrollIntoViewIfNeeded } from '../../utils';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const SelectSite: FunctionComponent<{
  nextStep: () => void;
  previousStep: () => void;
  filterReferenceType?: string;
  pageTracking?: trackingPages;
}> = ({ nextStep, previousStep, filterReferenceType, pageTracking }) => {
  const dispatch = useAppDispatch();
  const searchInfos = useAppSelector(selectSearchInfos);
  const selectedSite = useAppSelector(selectAdressInfos);
  const lastApiError = useAppSelector(selectLastApiError);

  const [centerChanged, setCenterChanged] = useState<{
    lng: number;
    lat: number;
    radius?: number;
  } | null>();
  const [loading, setLoading] = useState(false);
  const [refs, setRefs] = useState<(RefObject<HTMLDivElement> | null | undefined)[]>();
  const { t } = useTranslation();
  const [addresses, setAddresses] = useState<IGeographicAddress[]>([]);
  const [centerMap, setCenterMap] = useState<{ lng: number; lat: number }>({ lng: 0, lat: 0 });
  const [validated, setValidated] = useState(false);
  const trackEvent = useMatomoHarmonizer();

  const searchDataAsCoordinate = searchInfos.data as IDataCoordinates;

  function setAddressWithId(addressId: string) {
    const selected = addresses.find((e) => e.id === addressId);
    dispatch(setAddress({ address: selected }));
  }

  const reloadData = (coords: { lat: number; lng: number }) => {
    dispatch(
      setSearchType({
        type: 'coordinates',
        data: {
          latitude: coords.lat,
          longitude: coords.lng,
          radius: centerChanged?.radius || searchDataAsCoordinate.radius || '200'
        }
      })
    );
    setCenterChanged(null);
    if (pageTracking)
      trackEvent({
        page: pageTracking,
        category: 'Choix_Site',
        actionType: 'Rafraîchir',
        actionDetails: 'Carte'
      });
  };

  const setMapCenter = (
    centerPoint: { geographicLocation: { bbox: number[] } },
    forceData: boolean
  ) => {
    // Center map by info or with response
    const defaultCenter = getDefaultCenterFromSearch(searchInfos);
    if (defaultCenter && !forceData) {
      setCenterMap(defaultCenter);
    } else if (
      centerPoint &&
      centerPoint.geographicLocation &&
      centerPoint.geographicLocation.bbox.length >= 2
    ) {
      setCenterMap({
        lng: centerPoint.geographicLocation.bbox[0],
        lat: centerPoint.geographicLocation.bbox[1]
      });
    }
  };

  useEffect(() => {
    if (addresses.length === 1) {
      dispatch(setAddress({ address: addresses[0] }));
    }
  }, [addresses]);

  useEffect(() => {
    if (searchInfos.type) {
      const queryParam = buildSearchQueryByInfos(searchInfos, filterReferenceType);
      setLoading(true);

      dispatch(loadGeographicAddresses(queryParam)).then((res) => {
        if (res.payload) {
          const data = res.payload.map((ga: IGeographicAddress, index: number) => {
            const newData = { ...ga };

            newData.index = index + 1;
            return newData;
          });

          if (!['coordinates', 'geoaddress', 'address'].includes(searchInfos.type)) {
            data?.sort((a: IGeographicAddress, b: IGeographicAddress) => {
              return a.streetNr && b.streetNr ? parseInt(a.streetNr) - parseInt(b.streetNr) : 0;
            });
          }
          setMapCenter(data[0], false);
          setAddresses(data);
          if (data.length === 0) {
            if (pageTracking)
              trackEvent({
                page: pageTracking,
                category: 'Choix_Site',
                actionType: 'Aucun',
                actionDetails: 'Site_Disponible'
              });
          }

          const newRefs = data.reduce((obj: { [id: string]: string }, item: IGeographicAddress) => {
            return {
              ...obj,
              [item['index']]: React.createRef()
            };
          }, {});
          setRefs(newRefs);
        }
        setLoading(false);
      });
    }
  }, [searchInfos.type, searchInfos.data]);

  useEffect(() => {
    const id = selectedSite?.index;

    if (selectedSite && refs && id && refs[id]) {
      const target = refs[id]?.current;

      setMapCenter(selectedSite, true);
      if (target) {
        scrollIntoViewIfNeeded(target);
      }
    }
  }, [selectedSite]);

  useEffect(() => {
    if (selectedSite && validated) {
      nextStep();
    }
  }, [validated]);

  const goToCommercialService = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (pageTracking) {
      trackEvent({
        page: pageTracking,
        category: 'Choix_Site',
        actionType: 'Clic',
        actionDetails: 'Cotation_Mail'
      });
    }
    window.location.href = mailtoAxione;
    e.preventDefault();
  };

  const refreshMap = () => {
    if (centerChanged) reloadData(centerChanged);
    if (pageTracking)
      trackEvent({
        page: pageTracking,
        category: 'Choix_Site',
        actionType: 'Clic',
        actionDetails: 'Button_Refresh_Map'
      });
  };
  return (
    <CustomWrapper>
      <CustomCard
        header={
          <HeaderCardCustom
            titleHeader={t('features.eligibility.placeSelected', 'Place selected')}
            subTitleHeader={t(
              'features.eligibility.switchEdit',
              'You can switch the selected place by clicking “Edit”.'
            )}
          />
        }
        footer={
          <StyledSelectSiteFooter>
            <Button design="white" onClick={previousStep}>
              {t('component.button.previousStep', 'Previous Step')}
            </Button>
            <Button
              disabled={!selectedSite?.id}
              onClick={() => {
                setValidated(true);
              }}
              data-testid={'select-site_next-step'}
            >
              {t('component.button.nextStep', 'Next step')}
            </Button>
          </StyledSelectSiteFooter>
        }
      >
        <SelectSiteHeader search={searchInfos} previousStep={previousStep} />
        <StyledSelectSiteCardBodyContainer>
          <StyledInformationBlock
            title={
              <div style={{ display: 'block' }}>
                <b>
                  {addresses.length} {t('features.eligibility.result', 'Results')}
                </b>
                .{' '}
                {t(
                  'features.eligibility.cantFindAddress',
                  "If you can't find the address in our referential, please contact the commercial service for more details :"
                )}{' '}
                <LinkOrAnchor onClick={goToCommercialService}>cotation@axione.fr</LinkOrAnchor>
              </div>
            }
            backgroundVariant="grey"
            textVariant="grey"
            iconVariant="grey"
          />
          {loading && <Spinner spinnerSize={2} center />}
          {!loading && !addresses.length && (
            <>
              <Alert variant="danger" className="text-center">
                {lastApiError || t('errorMessages.notFoundResult', 'No result found')}
              </Alert>
              <LinkOrAnchor onClick={() => previousStep()}>
                {t('features.eligibility.returnSearch', 'Click here to return to search form')}
              </LinkOrAnchor>
            </>
          )}
          <StyledAddressListCards>
            {addresses.map((address: IGeographicAddress, index) => (
              <StyledAddressListCard
                key={index}
                data-testid={`address-card ${address.buildingRef}`}
                onClick={() => dispatch(setAddress({ address: address }))}
                ref={refs ? refs[address.index] : null}
              >
                <AddressesCard
                  trackingPages={pageTracking}
                  address={address}
                  validate={() => {
                    dispatch(setAddress({ address: address }));
                  }}
                  selected={selectedSite?.id === address.id}
                />
              </StyledAddressListCard>
            ))}
          </StyledAddressListCards>
        </StyledSelectSiteCardBodyContainer>
      </CustomCard>
      <CustomMap>
        {centerChanged && (
          <Button onClick={refreshMap}>
            {t('features.eligibility.clickToRefresh', 'Click here to refresh')}
          </Button>
        )}
        <MapWrapper>
          <EligGoogleMap
            center={centerMap}
            markers={addresses}
            searchCenter={searchInfos.data as IDataCoordinates}
            searchType={searchInfos.type}
            selectedSite={selectedSite || null}
            setSelectedSite={(addressId: string) => setAddressWithId(addressId)}
            setCenterChanged={setCenterChanged}
            pageTracking={pageTracking}
          />
        </MapWrapper>
      </CustomMap>
    </CustomWrapper>
  );
};

export default SelectSite;

const StyledAddressListCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  margin-top: 0.5rem;
  height: calc(100vh - 400px);
  grid-auto-rows: max-content;
`;
const StyledAddressListCard = styled.div`
  display: flex;
`;

const StyledInformationBlock = styled(InformationBlock)`
  margin-top: 0.5rem;
  padding: 8px 12px;
  b {
    color: ${theme.palette.grey[500]};
  }
  div,
  span {
    padding: 0;
    font-size: ${theme.fonts.size.xxs};
    line-height: ${theme.fonts.spacing.xxs};
  }
`;

const CustomWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
`;

const CustomCard = styled(Card)`
  max-width: 50%;
`;

const CustomMap = styled.div`
  height: 100%;
  width: 100%;
  max-width: 50%;
  position: relative;
  height: calc(100vh - 150px);

  button {
    z-index: 3;
    position: absolute;
    top: 0;
  }
`;

const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: sticky;
  top: 80px;

  @media screen and (max-width: 40em) {
    display: none;
  }
`;

const StyledSelectSiteResults = styled.div`
  display: flex;
  margin: 12px 0;

  font-weight: ${theme.fonts.weight.regular};
  font-size: ${theme.fonts.size.sm};
  line-height: ${theme.fonts.spacing.base};

  color: ${theme.palette.grey[500]};
`;

const StyledSelectSiteFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledSelectSiteCardBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid ${theme.palette.grey[200]};
`;
