import { IOrderInformationsForm, ISelectedSlot, OrderOptions } from './utils/orderInterfaces';
import {
  callBookErdv,
  callPatchOrder,
  selectOrderState,
  setErdvRaccoBookedSlot
} from './reducer/orderSlice';
import { concatComments, productCharacteristicFinder } from './utils/OrderHelper';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useContext, useEffect, useMemo, useState } from 'react';

import Card from '@designSystem/Card';
import { ErrorMonitoring } from '../../utils/ErrorMonitoring';
import { MatomoCustomContext } from '../../utils/MatomoCustomProvider';
import Modal from '@designSystem/Modal';
import OrderFooter from './components/OrderFooter';
import OrderRecapComplementary from './orderRecap/OrderRecapComplementary';
import OrderRecapConfirmModal from './orderRecap/confirmModals/OrderRecapConfirmModal';
import OrderRecapHeader from './orderRecap/components/OrderRecapHeader';
import OrderRecapInformations from './orderRecap/OrderRecapInformations';
import { OrderSteps } from './utils/constants';
import { StyledOrderRecapConfirmModalContainer } from './orderRecap/confirmModals/OrderRecapConfirmModalStyles';
import { getDefaultGtrFromProduct } from '../eligibility/products/productDetail/OptionsForm';
import { isHomeBasicProduct } from '@features/eligibility/helper';
import { listOfProductOptions } from '../eligibility/products/constants';
import { selectAdressInfos } from '../eligibility/eligibilitySlice';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { unwrapResult } from '@reduxjs/toolkit';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateManager } from '../../utils/DateManager';
import { productsListWithInfos } from '@features/eligibility/const';
import useGetOrderInformationsByOrderId from '@features/order/utils/orderHooks';

const OrderRecap = ({ comingFormDraft }: { comingFormDraft: boolean }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentLoading, setCurrentLoading] = useState<'erdv' | 'order'>('erdv');
  const [hasDoneSubmitting, sethasDoneSubmitting] = useState<boolean>(false);
  const [confirmModalDisplay, setConfirmModalDisplay] = useState<'loading' | 'error' | 'success'>(
    'loading'
  );
  const { matomoTrackPageView } = useContext(MatomoCustomContext);
  const trackEvent = useMatomoHarmonizer();

  const { orderId } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    erdvRaccoBooked,
    erdvRaccoBookedSlot,
    orderCreationError,
    orderFormSteps,
    orderLoading,
    productToOrder,
    order: orderStore
  } = useAppSelector(selectOrderState);

  const {
    order,
    selectedOptions,
    productSelected,
    orderInformation,
    address,
    appointmentComments,
    optionPlusSelected
  } = useGetOrderInformationsByOrderId(orderId ?? orderStore?.id ?? '');
  const addressInfos = useAppSelector(selectAdressInfos);

  const builderSelectedOptions = (
    gtrValue: string,
    selectedOptions: OrderOptions | null,
    isBusiness?: boolean
  ): OrderOptions => {
    const hasBronzeValue = selectedOptions?.bronze === true || selectedOptions?.bronze === false;
    const profile = selectedOptions?.profile;

    return {
      eqt: selectedOptions?.eqt || false,
      gtr: gtrValue,
      ...(profile ? { profile } : {}),
      ...(isBusiness && {
        ...(hasBronzeValue ? { bronze: selectedOptions?.bronze } : {}),
        optionPlus: selectedOptions?.optionPlus,
        trunks: {
          trunk: selectedOptions?.trunks?.trunk,
          ...(selectedOptions?.trunks?.safetyTrunk && {
            safetyTrunk: selectedOptions?.trunks?.safetyTrunk
          }),
          ...(selectedOptions?.trunks?.trunkOptionPlus && {
            trunkOptionPlus: selectedOptions?.trunks?.trunkOptionPlus
          })
        }
      })
    };
  };
  const clientInformations: IOrderInformationsForm =
    orderFormSteps.steps[OrderSteps.ORDER_INFO_FORM].data;
  const appointmentInformations = orderFormSteps.steps[OrderSteps.APPOINTMENT].data;

  useEffect(() => {
    if (order?.state?.status === 'draft') {
      matomoTrackPageView('Commande_Recapulatif');
    }
  }, []);

  useEffect(() => {
    if (orderLoading) {
      setConfirmModalDisplay('loading');
    } else if (orderCreationError) {
      setConfirmModalDisplay('error');
    } else if (order && hasDoneSubmitting) {
      setConfirmModalDisplay('success');
    }
  }, [orderLoading, orderCreationError, order, hasDoneSubmitting]);

  const placeOrder = (appointmentId: string, slot?: ISelectedSlot) => {
    if (order && order.id) {
      const comment = concatComments(
        clientInformations.clientType,
        clientInformations?.complementaryOutlet || false,
        clientInformations.outlet,
        clientInformations?.businessName,
        clientInformations.comments,
        appointmentInformations?.appointmentComments
      );

      const isBusiness =
        productToOrder &&
        productsListWithInfos.find((p) => p.code === productToOrder.product.productOffering.id)
          ?.isBusiness;

      setCurrentLoading('order');

      const gtrValue =
        (productToOrder &&
          listOfProductOptions[selectedOptions?.gtr || getDefaultGtrFromProduct(productToOrder)]) ||
        '';
      dispatch(
        callPatchOrder({
          ...clientInformations,
          id: order.id,
          state: 'submitted',
          address: addressInfos,
          comments: comment,
          appointmentId: appointmentId,
          appointmentDate: new DateManager(slot?.day ?? new Date()).format(),
          appointmentSlot: slot?.value ?? '',
          productOfferingId: productToOrder?.product.productOffering.id,
          options: builderSelectedOptions(gtrValue, selectedOptions, isBusiness)
        })
      )
        .then(unwrapResult)
        .then(() => {
          sethasDoneSubmitting(true); // Catch is done by error through loading modal
        })
        .catch((error) => {
          new ErrorMonitoring().captureException(Error('PATCH order exception'), {
            extra: error
          });
        });
    } else {
      toast.error('An error happened');
      new ErrorMonitoring().captureException(
        Error('PATCH order exception: There is no order or order.id')
      );
    }
  };

  const confirmOrder = (erdvRaccoBookedSlot?: ISelectedSlot) => {
    trackEvent({
      page: 'Commandes',
      category: orderId ? 'Recapulatif_Brouillon' : 'Recapulatif',
      actionType: 'Clic',
      actionDetails: 'Soumettre'
    });
    const selectedSlot = orderFormSteps.steps[OrderSteps.APPOINTMENT].data?.selectedSlot;
    const isHomeBasic = isHomeBasicProduct(productToOrder);
    const isBusiness =
      productToOrder &&
      productsListWithInfos.find((p) => p.code === productToOrder.product.productOffering.id)
        ?.isBusiness;

    setShowModal(true);
    // book appointment only if not already booked (in case of order submission fail but appointment booked successfully)
    // appointment book is not needed for Home Basic orders
    if (
      productToOrder &&
      !isBusiness &&
      !isHomeBasic &&
      (!erdvRaccoBooked?.id ||
        !erdvRaccoBooked?.id_calendar ||
        selectedSlot.id !== erdvRaccoBookedSlot?.id)
    ) {
      setCurrentLoading('erdv');
      dispatch(
        callBookErdv({
          orderId: order?.id || '',
          selectedSlot,
          productCode: productToOrder.product.productOffering.id
        })
      )
        .then(unwrapResult)
        .then((erdvRes) => {
          if (erdvRes?.id) {
            // erdv is booked, so we save selected slot to avoid re-book call if slot doesn't change
            dispatch(setErdvRaccoBookedSlot(selectedSlot));
            placeOrder(`${erdvRes.id}`, selectedSlot);
          }
        })
        .catch((error) => {
          setShowModal(false);
          new ErrorMonitoring().captureException(Error('Book erdv exception'), {
            extra: error
          });
        });
    } else if (isHomeBasic) {
      dispatch(setErdvRaccoBookedSlot(selectedSlot));
      placeOrder(`${selectedSlot?.id}`, selectedSlot);
    } else {
      placeOrder(`${erdvRaccoBooked?.id}`, erdvRaccoBookedSlot);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const displayRightPanel = useMemo(() => {
    const circuitId =
      order && productCharacteristicFinder<string>(order.productOrderItem, 'circuitId')?.value;
    const characteristicsIntervention = orderStore?.followUp?.find(
      (f) => f.name === 'INTERVENTION'
    );
    if (characteristicsIntervention) return true;
    if (comingFormDraft || !circuitId) return false;
    return true;
  }, [order, comingFormDraft]);

  const Container = displayRightPanel
    ? StyledCardOrderInformation
    : StyledCardOrderInformationDraft;

  const productWithInfos = productsListWithInfos.find(
    (product) => product.code === order?.productOrderItem[0].product.productOffering.id
  );
  return (
    <>
      <Container>
        <CustomCard
          header={comingFormDraft ? <OrderRecapHeader /> : null}
          footer={
            comingFormDraft ? (
              <OrderFooter
                handleNextStep={() => confirmOrder(erdvRaccoBookedSlot)}
                previousStep={
                  productWithInfos?.isBusiness ? OrderSteps.ORDER_INFO_FORM : OrderSteps.APPOINTMENT
                }
                nextStepTitle={t('features.order.confirmOrder', 'Confirm order')}
              />
            ) : null
          }
        >
          <OrderRecapInformations
            editable={comingFormDraft}
            productToOrder={productToOrder}
            order={order}
            orderInformation={orderInformation}
            productSelected={productSelected}
            selectedOptions={selectedOptions}
            address={address}
            optionPlusSelected={optionPlusSelected}
            appointmentComments={appointmentComments}
          />
        </CustomCard>
        {displayRightPanel && (
          <CustomCard>
            <OrderRecapComplementary />
          </CustomCard>
        )}
      </Container>

      <Modal canClose={false} show={showModal}>
        <StyledOrderRecapConfirmModalContainer>
          <OrderRecapConfirmModal
            contentToDisplay={confirmModalDisplay}
            currentLoading={currentLoading}
            closeModal={closeModal}
          ></OrderRecapConfirmModal>
        </StyledOrderRecapConfirmModalContainer>
      </Modal>
    </>
  );
};

const StyledCardOrderInformation = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
  align-items: start;
  margin: 0 2rem;
`;
const StyledCardOrderInformationDraft = styled.div`
  display: flex;
  gap: 2rem;
  align-items: start;
  margin: 0 auto 2rem auto;
`;

const CustomCard = styled(Card)`
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 1.5rem;
`;

export default OrderRecap;
