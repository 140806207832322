import { calendarDelete, calendarListFetch } from './reducer/calendarSlice';
import { Column } from 'react-table';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Button from '@designSystem/Button';

import { Calendar } from '../appointment/utils/interfaces';
import { calendarsColumns } from './calendarsListTable/Columns';
import { PageHeaderSearchAndSortObject } from '../../utils/interfaces';
import { Role } from '../../utils/role.types';
import { useAppDispatch } from '../../app/hooks';
import { useGuardHook } from '../../utils/FeatureFlag';
import CalendarTable from './calendarsListTable/CalendarTable';
import DeleteModal from '../../components/DeleteModal';
import MailListModal from './MailListModal';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/PageHeader/PageHeader';

export type MailList = { isCAF?: boolean; mails: { id: string; name: string }[] };

const CalendarsListBody: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const canCreateNewCalendar = useGuardHook('erdv_calendar_create');
  const canDeleteCalendar = useGuardHook('erdv_calendar_delete');
  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);
  const userIsAdmin = profileType.rolesNames.includes(Role.ERDV_ADMIN);
  const { t } = useTranslation();
  const { calendarsTotalCount } = useSelector((state: RootStateOrAny) => state.calendars);

  const [currentPage, setCurrentPage] = useState(0);
  const [idToDelete, setIdToDelete] = useState<number | false>(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [mailList, setMailList] = useState<MailList>({ mails: [] });
  const [currentFilterAndSort, setCurrentFilterAndSort] = useState<PageHeaderSearchAndSortObject>({
    sort: {
      sortKey: '1',
      sortDesc: true
    }
  });

  const dispatchGetCalendarsList = () => {
    const sortObject = {
      order_by: currentFilterAndSort.sort?.sortKey || '',
      order_ascending: !currentFilterAndSort.sort?.sortDesc
    };

    dispatch(
      calendarListFetch({
        currentPage: currentPage,
        sort: sortObject,
        search: currentFilterAndSort.search
      })
    );
  };

  useEffect(() => {
    dispatchGetCalendarsList();
  }, [currentPage, currentFilterAndSort]);

  const updateCalendarNavigate = (id: number) => {
    navigate(`/erdv/calendar/${id}/update`);
  };

  const deleteCalendarById = () => {
    if (idToDelete) {
      setDeleteLoading(true);
      dispatch(calendarDelete({ id: idToDelete })).then(() => {
        setIdToDelete(false);
        setDeleteLoading(false);
        dispatchGetCalendarsList();
      });
    }
  };

  const columns = React.useMemo(
    () =>
      calendarsColumns(
        updateCalendarNavigate,
        setIdToDelete,
        setMailList,
        userIsAdmin,
        canDeleteCalendar
      ),
    [canDeleteCalendar]
  ) as Column<Calendar>[];

  const updateDataFromSearchInput = (newFilter: PageHeaderSearchAndSortObject) => {
    setCurrentPage(0);
    setCurrentFilterAndSort({ ...newFilter });
  };

  return (
    <>
      <PageHeader
        updateData={updateDataFromSearchInput}
        currentFilterAndSort={currentFilterAndSort}
        totalRows={calendarsTotalCount}
        totalRowsTitle={t('features.calendars.calendar', 'calendars')}
        rightContent={
          canCreateNewCalendar ? (
            <Button onClick={() => navigate('/erdv/calendar/create')}>
              {t('features.calendars.newCalendar', 'New calendar')}
            </Button>
          ) : null
        }
      />
      <CalendarTable columns={columns} setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <MailListModal
        mails={mailList.mails}
        isCAF={!!mailList.isCAF}
        backAction={() => setMailList({ mails: [] })}
      />
      <DeleteModal
        modalId={idToDelete}
        backAction={() => setIdToDelete(false)}
        deleteAction={deleteCalendarById}
        loading={deleteLoading}
        title={t('features.calendars.deleteCalendar', 'Deleting a calendar')}
        description={t(
          'features.calendars.clickDelete',
          'By clicking on "delete", the calendar will be permanently deleted'
        )}
      />
    </>
  );
};

export default CalendarsListBody;
