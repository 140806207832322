import styled from 'styled-components';
import theme from '@theme';

export const StyledProductInfosDetails = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  border: 1px solid;
  border-color: ${theme.palette.grey[200]};
  border-radius: 9px;
  padding: 12px 16px;
  gap: 2rem;

  & div + div {
    flex: 1 0 auto;
    text-align: right;
  }
`;

export const OptionsTitle = styled.p`
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.base};
  color: ${theme.palette.grey[500]};
  margin-bottom: 1rem;
`;
