import { PencilFill, TrashFill } from 'react-bootstrap-icons';

import Badge from '@designSystem/Badges';
import Button from '@designSystem/Button';
import { MailList } from '../CalendarsListBody';
import { Row } from 'react-table';
import { getUsername } from '../../../app/AuthService';
import { i18n } from '@i18n';
import theme from '@theme';

export const calendarsColumns = (
  updateCalendar: (_id: number) => void,
  deleteCalendar: (_id: number) => void,
  openMailList: (_mailList: MailList) => void,
  userIsAdmin: boolean,
  canDeleteCalendar: boolean
) => {
  return [
    {
      Header: i18n.t('features.calendars.calendarsListTable.columns.name', 'Name'),
      accessor: 'name',
      disableSortBy: true
    },
    {
      Header: i18n.t('features.calendars.calendarsListTable.columns.type', 'Intervention type'),
      accessor: 'type_operation_label',
      disableSortBy: true
    },
    {
      Header: i18n.t('features.calendars.calendarsListTable.columns.zone', 'Geographic zone'),
      accessor: 'zonegeo_name',
      disableSortBy: true
    },
    {
      Header: i18n.t('features.calendars.calendarsListTable.columns.manager', 'Managers'),
      accessor: 'managers',
      disableSortBy: true,
      Cell: ({ value }: { value: any[] }) => {
        if (value && value.length > 0) {
          return (
            <div>
              <Badge variant="grey" design="small">
                {value[0].name}
              </Badge>{' '}
              {value.length > 1 && (
                <Button
                  design="white"
                  size="sm"
                  onClick={() => openMailList({ isCAF: false, mails: value })}
                >
                  +{value.length - 1}
                </Button>
              )}
            </div>
          );
        }
        return null;
      }
    },
    {
      Header: i18n.t('features.calendars.calendarsListTable.columns.threshold', 'Threshold %'),
      accessor: 'alert_threshold',
      disableSortBy: true
    },
    {
      Header: i18n.t('features.calendars.calendarsListTable.columns.contact', 'Contacts'),
      accessor: 'alert_contacts',
      disableSortBy: true,
      Cell: ({ value }: { value: any[] }) => {
        if (value && value.length > 0) {
          return (
            <div>
              <Badge variant="grey" design="small">
                {value[0].name}
              </Badge>
              {value.length > 1 && (
                <Button
                  design="white"
                  size="sm"
                  onClick={() => openMailList({ isCAF: true, mails: value })}
                >
                  +{value.length - 1}
                </Button>
              )}
            </div>
          );
        }
        return null;
      }
    },
    {
      disableSortBy: true,
      Header: i18n.t('features.calendars.calendarsListTable.columns.action', 'Actions'),
      accessor: 'any',
      Cell: ({ row }: { row: Row<any> }) => {
        const value = row?.original;
        const canUpdate =
          userIsAdmin || !!value.managers.find((manager: any) => manager.name === getUsername());

        return (
          <div>
            {canUpdate && (
              <PencilFill
                color={theme.palette.blue[500]}
                className="clickable"
                onClick={() => {
                  updateCalendar(value.id);
                }}
              />
            )}
            {canDeleteCalendar && (
              <TrashFill
                color={theme.palette.blue[500]}
                className="clickable"
                onClick={() => {
                  deleteCalendar(value.id);
                }}
              />
            )}
          </div>
        );
      }
    }
  ];
};
