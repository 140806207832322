import { CardList, PencilFill, TrashFill } from 'react-bootstrap-icons';
import { Column, Row } from 'react-table';

import { IZoneGeoFromApi } from '../utils/interfaces';
import { i18n } from '@i18n';
import theme from '@theme';

export const zonesGeoColumns = (
  zoneGeoUpdate: (_id: number) => void,
  zoneGeoDelete: (_id: number) => void,
  goToRef: (_id: number) => void,
  canUpdateGeographicZone: boolean,
  canDeleteGeographicZone: boolean
) => {
  return [
    {
      Header: i18n.t('features.zonesGeo.zonesGeoListTable.geographicZone', 'Geographic zone'),
      accessor: 'name',
      disableSortBy: true
    },
    {
      Header: i18n.t('features.zonesGeo.zonesGeoListTable.type', 'Type'),
      accessor: 'type',
      disableSortBy: true
    },
    {
      Header: i18n.t('features.zonesGeo.zonesGeoListTable.dsp', 'DSP'),
      accessor: 'dsp',
      disableSortBy: true
    },
    {
      Header: i18n.t(
        'features.zonesGeo.zonesGeoListTable.contractual',
        'Contractual delay for appointments'
      ),
      accessor: 'contract_delay',
      disableSortBy: true
    },
    {
      disableSortBy: true,
      Header: i18n.t('features.zonesGeo.zonesGeoListTable.action', 'Actions'),
      accessor: 'any',
      Cell: ({ row }: { row: Row<any> }) => {
        const value = row?.original;
        return (
          <div>
            {canUpdateGeographicZone && (
              <PencilFill
                className="clickable"
                color={theme.palette.blue[500]}
                onClick={() => {
                  zoneGeoUpdate(value.id);
                }}
              />
            )}
            <CardList
              className="clickable"
              color={theme.palette.blue[500]}
              onClick={() => {
                goToRef(value.id);
              }}
            />
            {canDeleteGeographicZone && (
              <TrashFill
                className="clickable"
                color={theme.palette.blue[500]}
                onClick={() => {
                  zoneGeoDelete(value.id);
                }}
              />
            )}
          </div>
        );
      }
    }
  ] as Column<IZoneGeoFromApi>[];
};
