import { DateRange } from 'react-day-picker';
import Icon from '@designSystem/Icon';
import React from 'react';
import { X } from 'react-bootstrap-icons';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

export interface ITab {
  i18nKey?: string;
  name: string;
  state: string | undefined;
  canBeDeleted?: boolean;
  inTheModal?: boolean;
}

interface ITabHeaderProps {
  tab: ITab;
  tabs: ITab[];
  setTabSelected: React.Dispatch<React.SetStateAction<ITab>>;
  handleCombinedChange: (search: string, range?: DateRange, state?: ITab['state']) => void;
  getValues: () => string;
  setTabs: React.Dispatch<React.SetStateAction<ITab[]>>;
  tabSelected: ITab;
  range: DateRange | undefined;
  isClearSelectedTab?: boolean;
}
export const TabHeader: React.FC<ITabHeaderProps> = ({
  tab,
  tabs,
  setTabs,
  setTabSelected,
  getValues,
  range,
  handleCombinedChange,
  tabSelected,
  isClearSelectedTab
}) => {
  const handleTabClick = () => {
    setTabSelected(tab);
    handleCombinedChange(getValues(), range, tab.state);
  };
  const { t } = useTranslation();
  return (
    <TabHeaderStyle className={`${tab.canBeDeleted && 'tab-with-icon'}`}>
      <div
        onClick={handleTabClick}
        className={`tab-name ${
          tabSelected.state === tab.state && !isClearSelectedTab && 'tab-selected'
        }`}
      >
        {tab.i18nKey ? t(tab.i18nKey) : tab.name}
      </div>
      {tab.canBeDeleted && (
        <Icon
          variant="blue"
          onClick={() => {
            const tabsFiltered = tabs.filter(({ state }) => state !== tab.state);
            setTabs([...tabsFiltered, { ...tab, inTheModal: true, canBeDeleted: false }]);
            if (tabSelected.state === tab.state) {
              setTabSelected(tabs[0]);
              handleCombinedChange(getValues(), range, tabs[0].state);
            }
          }}
          style={{ width: 13, height: 13, cursor: 'pointer', marginBottom: 9 }}
          round
        >
          <X size={13} />
        </Icon>
      )}
    </TabHeaderStyle>
  );
};

const TabHeaderStyle = styled.div`
  .tab-with-icon {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .tab-name {
    padding-bottom: 12px;
    font-size: ${theme.fonts.size.xs};
    font-weight: ${theme.fonts.weight.medium};
    line-height: ${theme.fonts.spacing.xs};
  }

  .tab-selected {
    padding-bottom: 10px;
    color: ${theme.palette.blue[500]};
    border-bottom: 2px solid ${theme.palette.blue[500]};
  }

  .tab-name:hover {
    padding-bottom: 10px;
    color: ${theme.palette.blue[700]};
    border-bottom: 2px solid ${theme.palette.blue[700]};
    cursor: pointer;
  }
`;
