import { MultiValue, OnChangeValue } from 'react-select';
import React, { KeyboardEventHandler, ReactChild, useState } from 'react';

import CreatableSelect from 'react-select/creatable';
import theme from '@theme';

interface Option {
  readonly label: string;
  readonly value: string;
}

// https://react-select.com/creatable
// https://codesandbox.io/s/2f0wbv?module=/example.tsx
const InputWithTags: React.FC<{
  selectValue?: MultiValue<Option>;
  selectName: string;
  setValue: CallableFunction;
  field: any;
  inputId: string;
  placeholder: ReactChild | string | null;
}> = ({ selectValue, selectName, setValue, field, placeholder, inputId }) => {
  const [inputValue, setInputValue] = useState('');

  const createOption = (label: string) => ({
    label,
    value: label
  });

  const handleChange = (value: OnChangeValue<Option, true>) => {
    setValue(selectName, value);
  };
  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
  };
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    if (selectValue?.findIndex((val) => val.value === inputValue) !== -1) {
      setInputValue('');
      event.preventDefault();
      return;
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        setValue(selectName, [...(selectValue as []), createOption(inputValue)]);
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      {...field}
      components={{
        DropdownIndicator: null
      }}
      inputId={inputId}
      styles={{
        multiValueRemove: (base) => ({
          ...base,
          borderRadius: 4
        }),
        multiValueLabel: (base) => ({
          ...base,
          borderRadius: 4,
          fontWeight: 700,
          color: theme.palette.grey[800]
        })
      }}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={selectValue}
    />
  );
};

export default InputWithTags;
