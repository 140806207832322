import {
  StyledOrderComplementaryWrapper,
  StyledOrderLabelValue,
  StyledOrderSection
} from '../../utils/OrderStyles';

import {
  IOrderInformationsForm,
  IOrderInformationsOperaBusinessForm
} from '../../utils/orderInterfaces';
import OrderRecapEditLink from '../components/OrderRecapEditLink';
import { OrderSections } from '../../utils/OrderHelper';
import { StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import { useTranslation } from 'react-i18next';

const OrderRecapComplementaryInformations = ({
  editable,
  orderInformation,
  productIsBusiness
}: {
  editable: boolean;
  productIsBusiness?: boolean;
  orderInformation: IOrderInformationsForm | IOrderInformationsOperaBusinessForm;
}) => {
  const { t } = useTranslation();
  const isExistingComment =
    orderInformation?.comments?.length && orderInformation?.comments !== ' ';

  const valueNotSpecified = t(
    'features.order.orderRecap.orderRecapSections.notSpecified',
    'Not specified'
  );
  return (
    <StyledOrderSection>
      <div className="section-title">
        {t(
          'features.order.orderRecap.orderRecapSections.complementary',
          'Complementary informations'
        )}
      </div>
      <StyledOrderComplementaryWrapper>
        <div className="half-width">
          <StyledLabel>
            {t(
              'features.order.orderRecap.orderRecapSections.orderReference',
              'Your internal order reference'
            )}
          </StyledLabel>
          <StyledOrderLabelValue>{orderInformation.internReference}</StyledOrderLabelValue>
        </div>

        {productIsBusiness && (
          <div>
            <StyledLabel>
              {t('features.order.orderInformations.project_name', `Project name`)}
              <div className="optional">
                {t('features.order.orderInformations.optional', `Optional`)}
              </div>
            </StyledLabel>
            <StyledOrderLabelValue>
              {'projectName' in orderInformation && orderInformation.projectName?.length
                ? orderInformation?.projectName
                : valueNotSpecified}
            </StyledOrderLabelValue>
          </div>
        )}

        <div>
          <StyledLabel>
            {t('features.order.orderRecap.orderRecapSections.comments', 'Comments')}
            <div className="optional">
              {t('features.order.orderInformations.optional', `Optional`)}
            </div>
          </StyledLabel>
          <StyledOrderLabelValue>
            {isExistingComment ? orderInformation?.comments : valueNotSpecified}
          </StyledOrderLabelValue>
        </div>
      </StyledOrderComplementaryWrapper>
      {editable && (
        <OrderRecapEditLink stepToGo={3} anchorKey={OrderSections.ComplementaryInformations} />
      )}
    </StyledOrderSection>
  );
};

export default OrderRecapComplementaryInformations;
