import { ChartPivotRow, CubejsApi, Query, ResultSet, SeriesNamesColumn } from '@cubejs-client/core';
import { EchartOption, EchartSerie } from '../interfaces';
import React, { useEffect, useState } from 'react';

import { Alert } from 'react-bootstrap';
import EchartsRenderer from '../renderer/EchartsRenderer';
import Spinner from '@designSystem/Spinner';
import { useTranslation } from 'react-i18next';

const EchartsDrawer: React.FC<{
  cubejsApi: CubejsApi | undefined;
  query: Query;
  chartType: string;
  chartOptions?: EchartOption;
  errorCallback?: (_e: Error) => void;
}> = ({ cubejsApi, query, chartType, chartOptions = {}, errorCallback }) => {
  const [series, setSeries] = useState<EchartSerie[] | EchartSerie>({} as EchartSerie);
  const [categories, setCategories] = useState<string[]>();
  const { t } = useTranslation();
  const noData = {
    key: 'nodata',
    data: [],
    type: chartType
  };

  const setNoData = () => {
    setSeries(noData);
  };

  const recoverSerieNameByKeys = (key: string) => {
    if (
      key &&
      chartOptions?.seriesOption?.length > 0 &&
      chartOptions.seriesOption.find((i: { key: string }) => i.key === key)
    ) {
      return chartOptions.seriesOption.find((i: { key: string }) => i.key === key).name || '';
    }
    return key;
  };

  const drawApexChart = (resultSet: ResultSet) => {
    // If we got no result we skip this part
    if (resultSet.chartPivot().length === 0 || !resultSet.seriesNames()) {
      setNoData();
      return;
    }

    const newCategories: string[] = resultSet
      .chartPivot()
      .map((chartPivotRow: ChartPivotRow) => chartPivotRow.x);

    setCategories(newCategories);

    const graphFormattedData: { [key: string]: any } = {};
    const keys: string[] = resultSet.seriesNames().map((seriesNamesColumn: SeriesNamesColumn) => {
      graphFormattedData[seriesNamesColumn.key] = [];
      return seriesNamesColumn.key;
    });
    resultSet.chartPivot().forEach((chartPivotRow: ChartPivotRow) => {
      keys.forEach((seriesNameKey: string) => {
        graphFormattedData[seriesNameKey].push(chartPivotRow[seriesNameKey]);
      });
    });

    const newSeries: EchartSerie[] | EchartSerie = keys.map(
      (seriesNameKey: string, index: number) => {
        if (chartOptions?.seriesOption) {
          if (chartOptions.seriesOption[index] === undefined) {
            return noData;
          }
          const seriesOptions = chartOptions?.seriesOption.find(
            (i: { key: string }) => i.key === seriesNameKey
          );
          return {
            ...seriesOptions,
            name: recoverSerieNameByKeys(seriesNameKey),
            data: graphFormattedData[seriesNameKey]
          };
        }
        return noData;
      }
    );

    setSeries(newSeries);
  };

  useEffect(() => {
    let canceled = false;
    setSeries({});
    if (cubejsApi && query) {
      cubejsApi
        .load(query)
        .then((resultSet) => {
          if (!canceled) {
            drawApexChart(resultSet);
          }
        })
        .catch((e) => {
          setNoData();
          errorCallback && errorCallback(e);
        });
    }
    return () => {
      canceled = true;
    };
  }, [JSON.stringify(query.segments)]);

  if (series && (series.data || series[0]?.data)) {
    if (series.key === 'nodata') {
      return (
        <>
          <h5>{chartOptions?.plotOptions?.title?.text}</h5>
          <Alert className="mb-0">
            {t('features.charts.drawer.noData', 'No data to display for this section')}
          </Alert>
        </>
      );
    }
    return (
      <EchartsRenderer
        series={series}
        categories={categories}
        plotOptions={chartOptions?.plotOptions || {}}
        categoryAxisIsX={chartOptions.categoryAxisIsX}
        categoryAxisIsY={chartOptions.categoryAxisIsY}
      />
    );
  }
  return <Spinner spinnerSize={2} center />;
};

export default EchartsDrawer;
