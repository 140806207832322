import { ReactChild } from 'react';

import Button from '@designSystem/Button';
import Spinner from '@designSystem/Spinner';
import { changeStep } from '../reducer/orderSlice';
import { useAppDispatch } from '../../../app/hooks';
import { useTranslation } from 'react-i18next';
import { useMatomoHarmonizer } from '../../../utils/matomoHarmonizer';

interface IOrderRecapFooterProps {
  disabled?: boolean;
  disabledBack?: boolean;
  previousStep: number;
  handleNextStep: CallableFunction;
  nextStepTitle?: ReactChild;
  loading?: boolean;
}
const OrderFooter = ({
  disabled = false,
  disabledBack = false,
  previousStep,
  handleNextStep,
  loading = false,
  nextStepTitle
}: IOrderRecapFooterProps) => {
  const dispatch = useAppDispatch();
  const trackEvent = useMatomoHarmonizer();

  const goToPreviousStep = () => {
    trackEvent({
      page: 'Order_informations',
      category: 'footer',
      actionType: 'Clic',
      actionDetails: 'Etape_Précédente'
    });
    dispatch(changeStep(previousStep));
  };
  const { t } = useTranslation();
  return (
    <>
      <Button design="white" onClick={() => goToPreviousStep()} disabled={disabledBack}>
        {t('component.button.previousStep', 'Previous Step')}
      </Button>
      <Button
        style={{
          marginLeft: 'auto'
        }}
        onClick={() => !loading && handleNextStep()}
        disabled={disabled}
        data-testid={`order-next-step${nextStepTitle ? `-${nextStepTitle}` : ''}`}
      >
        {nextStepTitle || t('component.button.nextStep', 'Next Step')}
        {loading && <Spinner spinnerSize={1} reverse />}
      </Button>
    </>
  );
};

export default OrderFooter;
