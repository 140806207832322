import React from 'react';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const StyledZoneGeoInputsTitle = styled.div`
  color: ${theme.palette.grey[500]};
  font-weight: ${theme.fonts.weight.regular};
  font-size: ${theme.fonts.size.base};
`;

const ZoneGeoFormInfos: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <StyledZoneGeoInputsTitle>
        {t('features.zonesGeo.zoneGeoForm.infoGeneral', 'Informations générales')}
      </StyledZoneGeoInputsTitle>
    </>
  );
};

export default ZoneGeoFormInfos;
