import {
  changeStep,
  getOrder,
  getSelectProductByName,
  selectOptionsToOrder,
  selectOrderState,
  updateStepData
} from '@features/order/reducer/orderSlice';
import {
  getDefinedListOfProduct,
  getFormValuesFromData,
  getProductByOfferingName
} from '@features/order/utils/OrderHelper';
import {
  selectAdressInfos,
  setAddress,
  setSearchType
} from '@features/eligibility/eligibilitySlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ModalEditAppointment } from '@features/order/orderList/ModalEditAppointment';
import Order from '../order';
import OrderRecap from '@features/order/OrderRecap';
import { OrderSteps } from '@features/order/utils/constants';
import OrderTrackingHeader from '@features/order/components/OrderTrackingHeader';
import { ProductOrderItem } from '@features/order/utils/orderInterfaces';
import Spinner from '@designSystem/Spinner';
import { isUUID } from '@features/order/utils/utils';
import theme from '@theme';
import { useTranslation } from 'react-i18next';
import { formatSelectedOptionsFromOrder } from '@features/order/utils/orderHooks';

const OrderBetaEditPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const addressInfos = useAppSelector(selectAdressInfos);
  const { order, productToOrder } = useAppSelector(selectOrderState);
  const [orderIdToUpdated, setOrderIdToUpdated] = useState<string | undefined>();
  const [readyToGetOrder, setReadyToGetOrder] = useState<boolean>(false);
  const [orderInfos, setOrderInfos] = useState<{
    orderId: string | undefined;
    buildingId: string | undefined;
    productOrderItem: ProductOrderItem[] | undefined;
  }>({ orderId: undefined, buildingId: undefined, productOrderItem: undefined });

  const [loadingDraftData, setLoadingDraftData] = useState(true);
  const [error, setError] = useState(false);

  const LoadOrError = () => {
    if (error) {
      return <p>{t('errorMessages.errorHappen', 'An error happened')}</p>;
    }
    return (
      <div style={{ marginTop: '2rem' }}>
        <Spinner spinnerSize={2} center />
      </div>
    );
  };

  useEffect(() => {
    if (orderId && isUUID(orderId)) {
      dispatch(getOrder({ id: orderId }));
      setReadyToGetOrder(true);
    } else {
      navigate('/');
    }
  }, [orderId]);

  useEffect(() => {
    if (order && loadingDraftData && readyToGetOrder) {
      if (order.state?.status) {
        populateOrderInformations();
      } else {
        navigate('/drafts');
      }
    }
  }, [order, addressInfos, readyToGetOrder]);

  const populateOrderInformations = () => {
    const places = order?.productOrderItem.find((p) => p.product.isBundle)?.product?.place;

    if (places?.length && order) {
      // GET ADDRESS
      dispatch(
        setSearchType({
          type: 'building',
          data: { buildingRef: places[0].id }
        })
      );
      dispatch(
        setAddress({
          address: places[0]
        })
      );

      // INIT FORM DATA
      const newFormValues = getFormValuesFromData(order, addressInfos);

      dispatch(
        updateStepData({
          step: OrderSteps.ORDER_INFO_FORM,
          data: { ...newFormValues.form },
          nextStep: false
        })
      );
      dispatch(
        updateStepData({
          step: OrderSteps.APPOINTMENT,
          data: { ...newFormValues.rdv },
          nextStep: false
        })
      );

      // GET Selected Options
      const formatedOptions = formatSelectedOptionsFromOrder(order);
      dispatch(selectOptionsToOrder(formatedOptions));
    }
  };

  useEffect(() => {
    if (addressInfos && order) {
      // GET PRODUCT
      const productToOrder = getProductByOfferingName(
        order.productOrderItem,
        getDefinedListOfProduct()
      );

      if (productToOrder) {
        dispatch(
          getSelectProductByName({
            site: addressInfos,
            name: productToOrder.product.productOffering.id
          })
        );
      } else {
        setError(true);
      }
    }
  }, [addressInfos]);

  useEffect(() => {
    dispatch(
      updateStepData({
        step: OrderSteps.SELECT_PRODUCT,
        data: productToOrder,
        nextStep: true
      })
    );
  }, [productToOrder]);

  useEffect(() => {
    // Display page
    // If we don't check loadingDraftData, we reload step ORDER_INFO_FORM each time we update steps
    if (order && addressInfos && loadingDraftData) {
      dispatch(changeStep(OrderSteps.ORDER_INFO_FORM));
      setLoadingDraftData(false);
    }
  }, [addressInfos, order]);

  const defineDisplayComponents = () => {
    if (addressInfos && order && order.state.status === 'draft') {
      return <Order headband={null} />;
    } else if (addressInfos && order) {
      return (
        <div style={{ background: theme.palette.grey[25] }}>
          <OrderTrackingHeader
            order={order}
            setOrderIdToUpdated={setOrderIdToUpdated}
            setOrderInfos={setOrderInfos}
          />
          <ModalEditAppointment
            orderInfos={{
              orderId: orderInfos?.orderId,
              buildingId: orderInfos?.buildingId,
              productOrderItem: orderInfos?.productOrderItem
            }}
            show={!!orderIdToUpdated}
            onClose={() => {
              setOrderIdToUpdated(undefined);
              if (orderId) {
                dispatch(getOrder({ id: orderId }));
              }
            }}
          />
          <OrderRecap comingFormDraft={false} />
        </div>
      );
    }

    return <LoadOrError />;
  };

  return <>{defineDisplayComponents()}</>;
};

export default OrderBetaEditPage;
