import LevelNotFoundSvg from '../../images/escalate/escalate-not-found.svg';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const NoEscalationListFound = () => {
  const { t } = useTranslation();
  return (
    <NoListFroundStyled>
      <img className="image" src={LevelNotFoundSvg} />
      <div className="title">{t('features.escalate.list.noResult', 'No results were found')}</div>
      <div className="sub-title">
        {t(
          'features.escalate.list.noResultMatch',
          'In order to access the escalation matrices, please select filters and sub-filters.'
        )}
      </div>
    </NoListFroundStyled>
  );
};

const NoListFroundStyled = styled.div`
  display: grid;
  grid-gap: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 350px;
  margin: auto;

  .image {
    width: 100%;
    height: 87px;
  }
  .title {
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};
    font-weight: ${theme.fonts.weight.medium};
    color: ${theme.palette.grey[500]};
  }
  .sub-title {
    font-size: ${theme.fonts.size.xs};
    line-height: ${theme.fonts.spacing.xs};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.palette.grey[400]};
    width: 100%;
  }
`;
export default NoEscalationListFound;
