import { RootStateOrAny, useSelector } from 'react-redux';

import { ArrowLeftCircle } from 'react-bootstrap-icons';
import { Column } from 'react-table';
import FullTable from '../../../components/FullTable';
import { IZoneGeoFromApi } from '../utils/interfaces';
import Pagination from '@designSystem/Pagination';
import TableContainerWithBorderMaxWidth from '../../../components/TableContainerWithBorderMaxWidth';
import { useNavigate } from 'react-router-dom';

const ZoneGeoTable = ({
  columns,
  setCurrentPage,
  currentPage
}: {
  columns: Column<IZoneGeoFromApi>[];
  setCurrentPage: (_currentPage: number) => void;
  currentPage: number;
}) => {
  const navigate = useNavigate();
  const { zonesGeoListLoading, zonesGeoList, zonesGeoTotalCount } = useSelector(
    (state: RootStateOrAny) => state.zonesGeo
  );

  const returnOnCalendarForm = () => {
    navigate('/erdv');
  };

  return (
    <>
      <h3 onClick={() => returnOnCalendarForm()} className="clickable">
        <ArrowLeftCircle />
      </h3>
      <TableContainerWithBorderMaxWidth className="table-max-width-margin table-border-top">
        <>
          <FullTable columns={columns} data={zonesGeoList} isLoading={zonesGeoListLoading} />
          <Pagination
            totalRows={zonesGeoTotalCount}
            pageChangeHandler={setCurrentPage}
            parentCurrentPage={currentPage}
          />
        </>
      </TableContainerWithBorderMaxWidth>
    </>
  );
};

export default ZoneGeoTable;
