import React, { ReactChild } from 'react';

import { StyledPopOver } from './PopOverDesignStyles';

interface PopOverProps extends Omit<Omit<React.HTMLProps<HTMLElement>, 'as'>, 'ref'> {
  children?: ReactChild[] | ReactChild | null;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'right' | 'center';
}

const PopOver: React.FC<PopOverProps> = ({
  vertical = 'bottom',
  horizontal,
  children,
  ...props
}) => {
  return (
    <StyledPopOver vertical={vertical} horizontal={horizontal} {...props}>
      {children}
    </StyledPopOver>
  );
};

export default PopOver;
