import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { calendarCreate, calendarUpdate } from '../reducer/calendarSlice';
import {
  makeDefautlFormValues,
  makeFormValuesForAPI,
  makeScheduleBulkObject,
  slotsDaysFormName
} from '../utils/calendarFunctions';
import { useNavigate, useParams } from 'react-router-dom';

import { CalendarFill } from 'react-bootstrap-icons';
import CalendarFormSteps from './CalendarFormSteps';
import { Card } from 'react-bootstrap';
import CardHeader from '../../../components/CardHeader';
import FormFooter from '../../../components/FormFooter';
import { ICreateCalendarForm } from '../utils/interfaces';
import { OrderSteps } from '../../order/utils/constants';
import { Role } from '../../../utils/role.types';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../app/hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const CalendarForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { calendarId } = useParams();
  const { currentCalendar, calendarLastWeekSlots } = useSelector(
    (state: RootStateOrAny) => state.calendars
  );
  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);
  const userIsManager = profileType.rolesNames.includes(Role.ERDV_MANAGER);
  const [currentStep, setCurrentStep] = useState<number>(
    userIsManager ? OrderSteps.SELECT_PRODUCT : OrderSteps.SELECT_ADDRESS
  );

  const {
    register,
    control,
    watch,
    setValue,
    formState: { dirtyFields }
  } = useForm<ICreateCalendarForm>({
    mode: 'onChange',
    defaultValues: {
      ...makeDefautlFormValues(currentCalendar, calendarLastWeekSlots)
    }
  });
  const { t } = useTranslation();
  useEffect(() => {
    if (calendarLastWeekSlots !== null) {
      slotsDaysFormName.forEach((slotDay) => {
        setValue(slotDay, calendarLastWeekSlots[slotDay]);
      });
    }
  }, [calendarLastWeekSlots]);

  const watchAllFields = watch();

  const shouldDisableContinueButton = () => {
    if (calendarId) {
      return !(
        watchAllFields.interventionType !== undefined && watchAllFields.zoneGeoName !== undefined
      );
    }

    return !(dirtyFields.interventionType && dirtyFields.zoneGeoName);
  };

  const saveCalendar = () => {
    const splittedRange = watchAllFields.dateRange.split(' ');
    const start_date = splittedRange[0];
    const end_date = splittedRange[splittedRange.length - 1];

    const formValuesForApi = makeFormValuesForAPI(watchAllFields);

    formValuesForApi.schedules = [
      {
        type: 1,
        bulk_slots: makeScheduleBulkObject(1, start_date, end_date, watchAllFields)
      },
      { type: 2, bulk_slots: makeScheduleBulkObject(2, start_date, end_date, watchAllFields) }
    ];

    if (calendarId) {
      dispatch(
        calendarUpdate({
          id: currentCalendar.id,
          ...formValuesForApi
        })
      )
        .then(unwrapResult)
        .then(() => {
          navigate('/erdv/calendar/list');
        });
    } else {
      dispatch(calendarCreate(formValuesForApi))
        .then(unwrapResult)
        .then(() => {
          navigate('/erdv/calendar/list');
        });
    }
  };

  const makeFormTitle = () => {
    if (calendarId) {
      return t('features.calendars.calendarForm.update', 'Update calendar');
    }
    return t('features.calendars.calendarForm.create', 'Create calendar');
  };

  const makeFormActionTitle = () => {
    if (currentStep === OrderSteps.SELECT_ADDRESS) {
      return t('component.button.continue', 'Continue');
    }
    if (calendarId) {
      return t('component.button.update', 'Update');
    }
    return t('component.button.create', 'Create');
  };

  return (
    <Card>
      <CardHeader icon={<CalendarFill />} title={makeFormTitle()} />
      <CalendarFormSteps
        formValues={watchAllFields}
        register={register}
        setValue={setValue}
        control={control}
        currentStep={currentStep}
      />
      <FormFooter
        action={() => {
          if (currentStep === OrderSteps.SELECT_ADDRESS) {
            return setCurrentStep(OrderSteps.SELECT_PRODUCT);
          }

          saveCalendar();
        }}
        actionTitle={makeFormActionTitle()}
        isActionDisabled={shouldDisableContinueButton()}
        backAction={() => {
          if (!userIsManager && currentStep === OrderSteps.SELECT_PRODUCT) {
            return setCurrentStep(OrderSteps.SELECT_ADDRESS);
          }
          navigate('/erdv/calendar/list');
        }}
      />
    </Card>
  );
};

export default CalendarForm;
