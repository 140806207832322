import OrderNotFoundSvg from '../../../images/order/drafts/order-not-found.svg';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const NoOrdersFound = () => {
  const { t } = useTranslation();
  return (
    <NoOrdersFroundStyled>
      <img className="image" src={OrderNotFoundSvg} />
      <div className="title">{t('features.order.orderList.noResult', 'No results were found')}</div>
      <div className="sub-title">
        {t(
          'features.order.orderList.noResultMatch',
          ' No result matches your reference. You can try again by entering a new reference or a  new customer name. '
        )}
      </div>
    </NoOrdersFroundStyled>
  );
};

const NoOrdersFroundStyled = styled.div`
  display: grid;
  grid-gap: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 350px;
  margin: auto;

  .image {
    width: 100%;
    height: 282px;
  }
  .title {
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};
    font-weight: ${theme.fonts.weight.medium};
    color: ${theme.palette.grey[500]};
  }
  .sub-title {
    font-size: ${theme.fonts.size.xs};
    line-height: ${theme.fonts.spacing.xs};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.palette.grey[400]};
    width: 100%;
  }
`;
export default NoOrdersFound;
