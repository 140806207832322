import ZoneGeoRefBody from '@features/zonesGeoReferences/ZoneGeoRefBody';
import { useParams } from 'react-router-dom';

function ZonesGeoReferences() {
  const { zoneId } = useParams();

  if (zoneId) {
    return <ZoneGeoRefBody zoneId={zoneId} />;
  }
  return null;
}

export default ZonesGeoReferences;
