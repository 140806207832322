import { ITab, TabHeader } from './TabHeader';
import React, { useEffect, useState } from 'react';

import { DateRange } from 'react-day-picker';
import { ModalWithTabs } from './ModalWithTabs';
import { Plus } from 'react-bootstrap-icons';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

interface ITabHeaderProps {
  handleCombinedChange: (search: string, range?: DateRange, state?: ITab['state']) => void;
  range: DateRange | undefined;
  getValues: () => string;
  tabsState: ITab[];
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  isModalWithTabs: boolean;
  isClearSelectedTab?: boolean;
}
export const TabsHeader: React.FC<ITabHeaderProps> = ({
  getValues,
  range,
  handleCombinedChange,
  tabsState,
  isModalWithTabs,
  setIsOpenModal,
  isClearSelectedTab
}) => {
  const [tabs, setTabs] = useState<ITab[]>(tabsState);
  const [tabsInModal, setTabsInModal] = useState<Array<ITab>>(tabs.filter((tab) => tab.inTheModal));
  const [tabSelected, setTabSelected] = useState<ITab>(tabs[0]);

  const tabsWithoutInModal = tabs.filter((tab) => !tab.inTheModal);

  const hasTabInModal = tabs.find((filter) => filter.inTheModal);
  const { t } = useTranslation();
  const addStatusModal = () => {
    setIsOpenModal(true);
  };

  useEffect(() => {
    setTabs(tabsState);
  }, [tabsState]);

  return (
    <TabsHeaderStyle>
      {tabsWithoutInModal.map((tab: ITab) => (
        <TabHeader
          key={tab.i18nKey ? t(tab.i18nKey) : tab.name}
          tab={tab}
          range={range}
          tabs={tabs}
          isClearSelectedTab={isClearSelectedTab}
          setTabSelected={setTabSelected}
          handleCombinedChange={handleCombinedChange}
          getValues={getValues}
          setTabs={setTabs}
          tabSelected={tabSelected}
        />
      ))}
      {hasTabInModal && (
        <div className="btn-open-modal-by-status" onClick={addStatusModal}>
          <Plus size={16} />
          {t('features.order.orderTracking.addStatus')}
        </div>
      )}
      <ModalWithTabs
        isOpenModal={isModalWithTabs}
        tabs={tabs}
        setTabs={setTabs}
        setIsOpenModal={setIsOpenModal}
        setTabsInModal={setTabsInModal}
        tabsInModal={tabsInModal}
        handleCombinedChange={handleCombinedChange}
        getValues={getValues}
        range={range}
        setTabSelected={setTabSelected}
      />
    </TabsHeaderStyle>
  );
};

const TabsHeaderStyle = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  color: ${theme.palette.grey[500]};
  margin-top: 2px;

  .tab-with-icon {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .tab-name {
    padding-bottom: 12px;
    font-size: ${theme.fonts.size.xs};
    font-weight: ${theme.fonts.weight.medium};
    line-height: ${theme.fonts.spacing.xs};
  }

  .tab-selected {
    padding-bottom: 10px;
    color: ${theme.palette.blue[500]};
    border-bottom: 2px solid ${theme.palette.blue[500]};
  }

  .tab-name:hover {
    padding-bottom: 10px;
    color: ${theme.palette.blue[700]};
    border-bottom: 2px solid ${theme.palette.blue[700]};
    cursor: pointer;
  }

  .btn-open-modal-by-status {
    color: ${theme.palette.blue[500]};
    display: flex;
    font-size: ${theme.fonts.size.xs};
    font-weight: ${theme.fonts.weight.medium};
    line-height: ${theme.fonts.spacing.xs};
    align-items: center;
    gap: 12px;
    cursor: pointer;
    margin-bottom: 12px;
  }
`;
