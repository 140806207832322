import { StyledOrderSection, StyledSeparator } from '../utils/OrderStyles';

import { ChatLeftTextFill } from 'react-bootstrap-icons';
import {
  IGeographicAddress,
  IOfferingResult,
  ProductOfferingQualification,
  ProductOfferingQualificationItem
} from '@features/eligibility/interfaces';
import InformationBlock from '@designSystem/InformationBlock';
import { LoaderIcon } from 'react-hot-toast';
import { OrderRecapTechnicalCharacteristic } from '@features/order/orderRecap/OrderRecapTechnicalCharacteristic';
import OrderRecapAddressSection from './informations/OrderRecapAddressSection';
import OrderRecapAppointment from './informations/OrderRecapAppointment';
import OrderRecapComplementaryInformations from './informations/OrderRecapComplementaryInformations';
import OrderRecapEndClientSection from './informations/OrderRecapEndClientSection';
import OrderRecapOutletSection from './informations/OrderRecapOutletSection';
import OrderRecapProductSection from './informations/OrderRecapProductSection';
import OrderRecapSiteSection from './informations/OrderRecapSiteSection';
import { appointmentDateFinder } from '../utils/OrderHelper';
import { productsListWithInfos } from '@features/eligibility/const';
import { selectOrderState } from '../reducer/orderSlice';
import { useAppSelector } from '../../../app/hooks';
import { useMemo } from 'react';
import {
  IOrderInformationsForm,
  IOrderInformationsOperaBusinessForm,
  Order,
  OrderOptions,
  OrderOptionsPlus
} from '@features/order/utils/orderInterfaces';
import { OrderRecapTrunk } from '@features/order/orderRecap/informations/OrderRecapTrunk';
// import { ReportCharacteristicsMAD } from '@features/order/ReportCharacteristicsMAD';

const OrderRecapInformations = ({
  editable,
  productToOrder,
  order,
  orderInformation,
  productSelected,
  selectedOptions,
  address,
  optionPlusSelected,
  appointmentComments
}: {
  editable: boolean;
  productToOrder: IOfferingResult | undefined;
  order: (Order & ProductOfferingQualificationItem) | null;
  orderInformation: IOrderInformationsForm | IOrderInformationsOperaBusinessForm | null;
  productSelected: ProductOfferingQualification | null;
  selectedOptions: OrderOptions | null;
  address: IGeographicAddress | null;
  optionPlusSelected: OrderOptionsPlus;
  appointmentComments?: string;
}) => {
  const { order: orderStore } = useAppSelector(selectOrderState);

  const { orderFormSteps } = useAppSelector(selectOrderState);
  const appointmentDates = useMemo(() => {
    if (editable) {
      return {
        initialDate: orderFormSteps.steps[4]?.data?.selectedSlot?.day || '',
        initialSlot: orderFormSteps.steps[4]?.data?.selectedSlot?.value || '',
        finalDate: null,
        finalSlot: null
      };
    }
    if (orderStore) {
      return appointmentDateFinder(orderStore);
    }
    return {};
  }, [order, orderStore, editable, orderFormSteps]);

  if (!order || !selectedOptions || !productSelected || !address || !orderInformation)
    return (
      <div
        style={{
          minHeight: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <LoaderIcon />
      </div>
    );

  const productOrderItemAcces = order.productOrderItem[0].productOrderItem.find(
    (p) => p.product.productOffering.id === 'acces'
  );
  const failureReason = productOrderItemAcces?.product.productCharacteristic?.find(
    (e) => e.name === 'failureReason'
  )?.value;
  const dspName = productOrderItemAcces?.product.productCharacteristic?.find(
    (e) => e.name === 'spvName'
  )?.value?.value;

  const productWithInfos = productsListWithInfos.find(
    (product) => product.code === order.productOrderItem[0].product.productOffering.id
  );

  const caracteristics = orderStore!.followUp?.find((f) => f.name === 'MAD');
  return (
    <>
      {/* {caracteristics && (
        <>
          <ReportCharacteristicsMAD characteristics={caracteristics} />
          <StyledSeparator />
        </>
      )} */}

      {!editable && failureReason && (
        <>
          <StyledOrderSection>
            <InformationBlock
              iconVariant="red"
              textVariant="red"
              backgroundVariant="red"
              customIcon={<ChatLeftTextFill />}
              title="Commentaire"
              type="custom"
            >
              {failureReason}
            </InformationBlock>
          </StyledOrderSection>
          <StyledSeparator />
        </>
      )}
      <OrderRecapAddressSection editable={editable} productDSP={dspName} />
      <StyledSeparator />
      <OrderRecapProductSection
        editable={editable}
        product={productSelected}
        selectedOptions={selectedOptions}
      />
      <StyledSeparator />

      {productWithInfos?.isBusiness ? (
        <>
          <OrderRecapTrunk selectedOptions={selectedOptions} />
          <StyledSeparator />
        </>
      ) : null}

      <OrderRecapEndClientSection editable={editable} client={order?.customer} />
      <StyledSeparator />
      <OrderRecapSiteSection
        editable={editable}
        places={order.productOrderItem[0].product.place}
        address={address}
      />
      {!productWithInfos?.isBusiness ||
        (productWithInfos.isZTD && (
          <>
            <StyledSeparator />
            <OrderRecapOutletSection
              editable={editable}
              orderInformation={orderInformation as IOrderInformationsForm}
              order={order}
            />
          </>
        ))}

      <StyledSeparator />

      {productWithInfos?.isBusiness && (
        <>
          <OrderRecapTechnicalCharacteristic
            orderInformation={orderInformation as IOrderInformationsOperaBusinessForm}
            optionPlusSelected={optionPlusSelected}
          />
          <StyledSeparator />
        </>
      )}

      <OrderRecapComplementaryInformations
        productIsBusiness={productWithInfos?.isBusiness}
        editable={editable}
        orderInformation={orderInformation}
      />
      {appointmentDates?.initialDate && (
        <>
          <StyledSeparator />
          <OrderRecapAppointment
            editable={editable}
            date={appointmentDates.initialDate}
            slot={appointmentDates.initialSlot}
            comment={appointmentComments}
          />
        </>
      )}
      {appointmentDates?.finalDate && (
        <>
          <StyledSeparator />
          <OrderRecapAppointment
            finalDate
            editable={editable}
            date={appointmentDates.finalDate}
            slot={appointmentDates.finalSlot}
          />
        </>
      )}
    </>
  );
};

export default OrderRecapInformations;
