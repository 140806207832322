import {
  IOfferingResult,
  ProductOfferingQualificationItem
} from '@features/eligibility/interfaces';
import {
  OptionsTitle,
  StyledProductInfosDetails
} from '@features/eligibility/products/productDetail/modal/utils';
import React, { useEffect, useState } from 'react';
import {
  callGetTrunks,
  selectOptionsToOrder,
  selectProductToOrder
} from '@features/order/reducer/orderSlice';

import Modal from '@designSystem/Modal';
import OptionsForm from '@features/eligibility/products/productDetail/OptionsForm';
import { OrderOptions } from '@features/order/utils/orderInterfaces';
import ProductNameAndDelais from '@features/eligibility/products/productDetail/ProductNameAndDelais';
import ProductPriceAndFees from '@features/eligibility/products/productDetail/ProductPriceAndFees';
import { useAppDispatch } from '../../../../../app/hooks';
import { useTranslation } from 'react-i18next';
import ProgressStepper from '@designSystem/ProgressStepper';
import { unwrapResult } from '@reduxjs/toolkit';
import Spinner from '@designSystem/Spinner';
import {
  Footer,
  NoTrunkFound,
  retrieveActiveTrunksByProductCode,
  StyledTitle
} from '@features/eligibility/products/productDetail/modal/trunkUtils';
import Select, { SingleValue } from 'react-select';
import InformationBlock from '@designSystem/InformationBlock';
import { env } from '../../../../../config';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { Trunk } from '@features/eligibility/products/productDetail/modal/trunk.interface';

const ProductDetailModal: React.FC<{
  nextStep: CallableFunction;
  handleClose: () => void;
  selectedProduct: IOfferingResult | null;
  defaultGtr?: string;
  detailProductData?: ProductOfferingQualificationItem;
  selectedOptions?: OrderOptions;
  loading: boolean;
}> = ({
  selectedProduct,
  detailProductData,
  defaultGtr,
  handleClose,
  nextStep,
  selectedOptions,
  loading
}) => {
  const dispatch = useAppDispatch();
  const [optionsToOrder, setOptionsToOrder] = useState<OrderOptions | undefined>(undefined);
  const { t } = useTranslation();
  const [step, setStep] = useState<1 | 2>(1);
  const [trunk, setTrunk] = useState<any>();
  const [trunkList, setTrunkList] = useState<Trunk[]>([]);
  const [isTrunkLoading, setIsTrunkLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const validateDetailForm = () => {
    if (detailProductData) {
      dispatch(selectProductToOrder(selectedProduct));
      dispatch(selectOptionsToOrder(optionsToOrder));
      nextStep(optionsToOrder);
    }
  };
  useEffect(() => {
    if (selectedOptions) {
      const initialOptions = selectedOptions
        ? {
            gtr: selectedOptions.gtr,
            eqt: selectedOptions.eqt
          }
        : undefined;
      setOptionsToOrder(initialOptions);
    }
  }, [selectedProduct, selectedOptions, detailProductData]);
  const dataTestId = `select-option-next-step_${selectedProduct?.productOffering.name.replaceAll(
    ' ',
    '-'
  )}`;

  useEffect(() => {
    setTrunk(selectedOptions?.trunks?.trunk || { trunk: '' });
  }, [selectedProduct, selectedOptions, detailProductData]);
  useEffect(() => {
    setIsTrunkLoading(true);
    dispatch(callGetTrunks())
      .then(unwrapResult)
      .then((data: Trunk[]) => {
        const trunks = retrieveActiveTrunksByProductCode(
          data,
          selectedProduct?.productOffering.id || ''
        );
        setTrunkList(trunks);
      })
      .catch(() => setIsError(true))
      .finally(() => {
        setIsTrunkLoading(false);
      });
  }, [dispatch, selectedProduct]);

  const handleChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    const newValue = selectedOption ? selectedOption.value : '';
    setTrunk({ trunk: newValue });
  };

  const options = trunkList.map((trunk) => ({ value: trunk.reference, label: trunk.reference }));

  const selectValue = options.find((option) => option.value === trunk.trunk) || null;

  return (
    <Modal
      show={!!detailProductData}
      onClose={() => {
        setOptionsToOrder(undefined);
        handleClose && handleClose();
      }}
      width="550px"
      footer={
        <Footer
          t={t}
          dataTestId={dataTestId}
          validateDetailForm={validateDetailForm}
          handleClose={handleClose}
          step={step}
          setStep={setStep}
          setOptionsToOrder={setOptionsToOrder}
          trunk={trunk}
          loading={loading}
        />
      }
    >
      <>
        <ProgressStepper
          currentStep={`${step}`}
          stepList={[
            {
              key: '1',
              title: ''
            },
            {
              key: '2',
              title: ''
            }
          ]}
        />
        {loading ? (
          <div style={{ marginTop: '1rem' }}>
            <Spinner spinnerSize={2} center />
          </div>
        ) : (
          <>
            {step === 1 && !!detailProductData && (
              <>
                <StyledTitle>
                  {t(
                    'features.eligibility.products.productDetail.addOption',
                    'Do you want to add an option?'
                  )}
                </StyledTitle>
                <StyledProductInfosDetails>
                  <ProductNameAndDelais product={detailProductData} />
                  <ProductPriceAndFees product={detailProductData} />
                </StyledProductInfosDetails>

                <OptionsTitle tabIndex={0}>
                  {t(
                    'features.eligibility.products.productDetail.optionDispo',
                    'Disponible options'
                  )}
                </OptionsTitle>
                <OptionsForm
                  selectedProduct={detailProductData}
                  defaultOption={{ gtr: defaultGtr, eqt: undefined }}
                  optionsToOrder={optionsToOrder}
                  setOptionsToOrder={setOptionsToOrder}
                ></OptionsForm>
              </>
            )}

            {step === 2 && (
              <div>
                {isTrunkLoading ? (
                  <div style={{ marginTop: '1rem' }}>
                    <Spinner spinnerSize={2} center />
                  </div>
                ) : (
                  <>
                    {isError || trunkList.length === 0 ? (
                      <NoTrunkFound t={t} />
                    ) : (
                      <>
                        <StyledTitle>
                          {t('features.eligibility.products.productDetail.chooseTrunk')}
                        </StyledTitle>
                        <div>
                          <div style={{ marginTop: 16 }}>
                            <OptionsTitle>
                              {t(
                                'features.eligibility.products.productDetail.optionTrunks_nominal',
                                "Tronc de l'accès nominal"
                              )}
                            </OptionsTitle>
                            <Select
                              placeholder={t('component.select.placeholder', 'Sélectionner')}
                              value={selectValue}
                              onChange={(selectedOption) => handleChange(selectedOption)}
                              options={options}
                              isClearable
                              isSearchable
                            />
                          </div>
                          <InformationBlock
                            style={{ marginTop: 16 }}
                            title={
                              <>
                                {t(
                                  'features.eligibility.products.productDetail.optionTrunks_error'
                                )}
                                <a href={env.URL_ORDER_TRUNKS}>lien</a>.
                              </>
                            }
                            type="warning"
                            customIcon={<ExclamationCircleFill />}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </>
    </Modal>
  );
};

export default ProductDetailModal;
