import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { Role, UserRole } from '../../../utils/role.types';
import { RootStateOrAny, useSelector } from 'react-redux';

import React from 'react';
import { isDevEnvironment } from '../../../app/AuthService';
import { setNewApiProfile } from '@features/profiles/profileSlice';
import { useAppDispatch } from '../../../app/hooks';
import { useFlagHook } from '../../../utils/FeatureFlag';

// Role only used in dev environnement for Orders to take an appointement
const ftthRoleForApex = 'erdvftth-convergence' as Role;
export const roleList = [
  {
    name: 'FAI 1',
    profile: { username: 'FAI 1', rolesNames: [Role.FAI], organization: '' }
  },
  {
    name: 'FAI 2',
    profile: { username: 'FAI 2', rolesNames: [Role.FAI_RESTREINT], organization: '' }
  },
  {
    name: 'Intern',
    profile: {
      username: 'Intern',
      rolesNames: [
        Role.INTERN,
        Role.EFO_INTERN,
        Role.ERDV_ADMIN,
        Role.OBSERVABILITY_VIEWER,
        Role.PORTAL_ORDER_FULL_BETA,
        Role.PORTAL_INFOS_ALL,
        ftthRoleForApex
      ],
      organization: 'All'
    }
  },
  {
    name: 'Full Bêta',
    profile: {
      username: 'Full Bêta',
      rolesNames: [Role.PORTAL_ORDER_FULL_BETA, ftthRoleForApex],
      organization: ''
    }
  },
  {
    name: 'Full',
    profile: { username: 'Full', rolesNames: [Role.PORTAL_ORDER_FULL], organization: '' }
  },
  {
    name: 'Quotation',
    profile: { username: 'Quotation', rolesNames: [Role.PORTAL_ORDER_QUOTATION], organization: '' }
  },
  {
    name: 'Price',
    profile: { username: 'Price', rolesNames: [Role.PORTAL_ORDER_PRICE], organization: 'All' }
  },
  {
    name: 'Consultation 1',
    profile: {
      username: 'Consultation 1',
      rolesNames: [Role.PORTAL_ORDER_CONSULTATION],
      organization: 'THD 42 Exploitation'
    }
  },
  {
    name: 'Consultation 2',
    profile: {
      username: 'Consultation 2',
      rolesNames: [Role.PORTAL_ORDER_CONSULTATION],
      organization: 'THD 59-62'
    }
  },
  {
    name: 'EFO_INTERN',
    profile: {
      username: 'EFO_INTERN',
      rolesNames: [Role.EFO_INTERN],
      organization: ''
    }
  },
  {
    name: 'Delegant THD42',
    profile: {
      username: 'Delegant THD42',
      rolesNames: [Role.DELEGANT, Role.PORTAL_INFOS_DSP_RESTRICTED],
      organization: 'THD 42 Exploitation'
    }
  },
  {
    name: 'Delegant THD 59-62',
    profile: {
      username: 'U-DEL THD 59-62',
      rolesNames: [Role.DELEGANT, Role.PORTAL_INFOS_DSP_RESTRICTED],
      organization: 'THD 59-62'
    }
  },
  {
    name: 'Delegant Portal Infos Restricted THD42',
    profile: {
      username: 'Delegant THD42',
      rolesNames: [Role.PORTAL_INFOS_DSP_RESTRICTED],
      organization: 'THD 42 Exploitation'
    }
  },
  {
    name: 'Delegant Portal Infos All',
    profile: {
      username: 'Delegant THD42',
      rolesNames: [Role.PORTAL_INFOS_ALL],
      organization: 'All'
    }
  },
  {
    name: 'ERDV ADMIN',
    profile: { username: 'ERDV Admin', rolesNames: [Role.ERDV_ADMIN] }
  },
  {
    name: 'ERDV MANAGER',
    profile: { username: 'ERDV Manager', rolesNames: [Role.ERDV_MANAGER] }
  },
  {
    name: 'ERDV SUPPLIER',
    profile: { username: 'ERDV Supplier', rolesNames: [Role.ERDV_SUPPLIER] }
  },
  {
    name: 'Y78 Viewer',
    profile: { username: 'Y78 Viewer', rolesNames: [Role.OBSERVABILITY_VIEWER] }
  },
  {
    name: 'Y78 Admin',
    profile: { username: 'Y78 Admin', rolesNames: [Role.OBSERVABILITY_ADMIN] }
  },
  {
    name: 'ESCALATE VIEWER',
    profile: { username: 'ESCALATE VIEWER', rolesNames: [Role.PORTAIL_INFOS_ESCALATION_VIEWER] }
  },
  {
    name: 'ESCALATE EDITOR & VIEWER',
    profile: {
      username: 'ESCALATE EDITOR & VIEWER',
      rolesNames: [Role.PORTAIL_INFOS_ESCALATION_EDITOR_INTERN]
    }
  }
];

const DevProfiles: React.FC = () => {
  const { updateContext } = useFlagHook();

  const callBack = (newFlags: string, role: string) => {
    console.info('@callBack: newFlags', newFlags, 'role', role);
  };

  if (!isDevEnvironment()) {
    return null;
  }
  const dispatch = useAppDispatch();
  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);

  const setProfile = (profileTypeUpdated: UserRole) => {
    dispatch(setNewApiProfile(profileTypeUpdated));
    updateContext(profileTypeUpdated?.rolesNames || [], callBack);
  };

  const getProfileName = () => {
    const hasProfileOrganization = !!profileType.organization && profileType.organization !== '';
    let organizationToDisplay = '';

    // User has multiple roles case
    if (profileType.rolesNames.length > 1) {
      organizationToDisplay = hasProfileOrganization ? `, ${profileType.organization}` : '';
      return `${profileType.rolesNames[0]} (+ ${
        profileType.rolesNames.length - 1
      })${organizationToDisplay}`;
    }
    // User as single role case
    organizationToDisplay = profileType.organization || '';
    return `${profileType.rolesNames} ${organizationToDisplay}`;
  };

  return (
    <Dropdown as={NavItem}>
      <Dropdown.Toggle as={NavLink} className="text-nowrap px-3 border-right">
        {getProfileName()}
      </Dropdown.Toggle>
      <Dropdown.Menu className="collapse dropdown-menu-small dropdown-menu-right">
        {roleList.map((role) => (
          <Dropdown.Item key={role.name} onClick={() => setProfile(role.profile)}>
            {role.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DevProfiles;
