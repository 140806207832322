import React, { useEffect } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { resetCurrentZoneGeo, zoneGeoFetchById } from '@features/zonesGeo/reducer/zonesGeoSlice';

import { Container } from 'react-bootstrap';
import FixedSpinner from '../../components/FixedSpinner';
import ZoneGeoForm from '@features/zonesGeo/zoneGeoForm/ZoneGeoForm';
import { getDepartementAndDSP } from '@features/profiles/profileSlice';
import styled from 'styled-components';
import { useAppDispatch } from '../../app/hooks';
import { useParams } from 'react-router-dom';

const StyledZoneGeoFormContainer = styled(Container)`
  margin-top: 1.5rem;
  max-width: 800px;
`;

const ZoneGeoUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const { zoneGeoId } = useParams();

  const { departementAndDSP } = useSelector((state: RootStateOrAny) => state.profile);
  const { currentZoneGeo, currentZoneGeoLoading } = useSelector(
    (state: RootStateOrAny) => state.zonesGeo
  );

  useEffect(() => {
    if (!departementAndDSP || departementAndDSP.length === 0) {
      dispatch(getDepartementAndDSP());
    }
  }, []);

  useEffect(() => {
    dispatch(resetCurrentZoneGeo());
    if (zoneGeoId) {
      dispatch(zoneGeoFetchById({ zoneGeoId }));
    }
  }, [zoneGeoId]);

  return (
    <StyledZoneGeoFormContainer>
      <FixedSpinner loading={currentZoneGeoLoading}>
        {currentZoneGeo && currentZoneGeo.name && <ZoneGeoForm />}
      </FixedSpinner>
    </StyledZoneGeoFormContainer>
  );
};

export default ZoneGeoUpdate;
