import { CalendarFill, ChatLeftTextFill } from 'react-bootstrap-icons';
import {
  StyledOrderComplementaryWrapper,
  StyledOrderSection,
  StyledSideContentCard
} from '../../utils/OrderStyles';

import Badge from '@designSystem/Badges';
import Icon from '@designSystem/Icon';
import InformationBlock from '@designSystem/InformationBlock';
import OrderRecapEditLink from '../components/OrderRecapEditLink';
import { OrderSections } from '../../utils/OrderHelper';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';
import { DateManager } from '../../../../utils/DateManager';

const OrderRecapAppointment = ({
  editable,
  finalDate,
  date,
  slot,
  comment
}: {
  editable: boolean;
  finalDate?: boolean;
  date?: string;
  slot?: string;
  comment?: string;
}) => {
  const { t } = useTranslation();
  return (
    <StyledOrderSection>
      <div className="section-title">
        {finalDate
          ? t('features.order.orderRecap.orderRecapSections.appointment_final', 'Appointment Final')
          : t('features.order.orderRecap.orderRecapSections.appointment', 'Appointment')}
      </div>

      {editable && (
        <InformationBlock
          type="warning"
          title={t(
            'features.order.orderRecap.orderRecapSections.appointmentReserved',
            'Your appointment will be reserved only after order is confirmed'
          )}
          className="section_warning"
        />
      )}
      <StyledOrderComplementaryWrapper>
        <StyledSideContentCard>
          <StyledAppointmentDetails>
            <div style={{ marginRight: '12px' }}>
              <Icon iconSize="12px" round>
                <CalendarFill />
              </Icon>
            </div>
            <p style={{ paddingRight: 12, margin: 0, alignSelf: 'center' }}>
              {t('app.at', 'At')} {new DateManager(date).format('DD/MM/YYYY')}
            </p>
            <Badge
              style={{ maxHeight: '22px', margin: 0, alignSelf: 'center' }}
              className="schedule_badge"
            >
              {slot === 'AM' &&
                t('features.order.orderRecap.orderRecapSections.bet8', 'Bet. 8h - 12h')}
              {slot === 'PM' &&
                t('features.order.orderRecap.orderRecapSections.bet14', 'Bet. 14h - 18h')}
              {slot !== 'AM' && slot !== 'PM' && slot && slot}
            </Badge>
          </StyledAppointmentDetails>

          {comment ? (
            <StyledAppointmentComment>
              <ChatLeftTextFill
                style={{ minWidth: '1rem', alignSelf: 'center' }}
                size={16}
                color={theme.palette.grey[400]}
              />
              {comment ||
                t('features.order.orderRecap.orderRecapSections.notSpecified', 'Not specified')}
            </StyledAppointmentComment>
          ) : (
            <></>
          )}
        </StyledSideContentCard>
      </StyledOrderComplementaryWrapper>
      {editable && (
        <OrderRecapEditLink stepToGo={4} anchorKey={OrderSections.ComplementaryInformations} />
      )}
    </StyledOrderSection>
  );
};

export default OrderRecapAppointment;

const StyledAppointmentComment = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding-left: 50px;
  line-height: ${theme.fonts.size.xs};
  font-size: ${theme.fonts.size.xs};
  font-weight: ${theme.fonts.weight.medium};
  color: ${theme.palette.grey[400]};
`;

const StyledAppointmentDetails = styled.div`
  font-weight: ${theme.fonts.weight.medium};
  font-size: ${theme.fonts.size.base};
  display: flex;
`;
