import {
  Back,
  Envelope,
  EnvelopeFill,
  Icon as IconType,
  Joystick,
  Pen,
  Person,
  Square,
  Telephone
} from 'react-bootstrap-icons';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { RootStateOrAny, useSelector } from 'react-redux';
import { editLevelsById, getLevelById } from '@features/escalate/reducer/matriceAPI';
import theme, { ThemeVariants } from '@theme';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Badge from '@designSystem/Badges';
import Button from '@designSystem/Button';
import Drawer from '@designSystem/Drawer';
import { DualPanel } from './DualPanel';
import { EscalationTrad } from '@features/escalate/const';
import Icon from '@designSystem/Icon';
import InformationBlock from '@designSystem/InformationBlock';
import InputWrapper from '@designSystem/InputWrapper';
import { Level } from '@features/escalate/escalateTypes';
import { Role } from '../utils/role.types';
import Tooltip from '@designSystem/Tooltip';
import hearder_drawer_contact from './hearder_drawer_contact.svg';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface RightContentProps {
  mailto?: boolean;
}

const HeaderComponent = ({
  title,
  name,
  matriceSection,
  subMatriceSection,
  priority = 0
}: {
  title: string;
  name: string;
  matriceSection: string;
  subMatriceSection: string;
  priority?: number;
}) => {
  return (
    <HeaderContainer>
      <ContentContainer>
        <Title>{EscalationTrad()[title] || title}</Title>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '5px'
          }}
        >
          <Badge
            variant={
              (['green', 'yellow', 'orange', 'red', 'purple'][priority] || 'grey') as ThemeVariants
            }
          >
            {EscalationTrad()[name] || name}
          </Badge>
          <div style={{ gap: '18px', display: 'flex' }}>
            {matriceSection && <Badge> {EscalationTrad()[matriceSection] || matriceSection}</Badge>}
            {subMatriceSection && (
              <Badge> {EscalationTrad()[subMatriceSection] || subMatriceSection}</Badge>
            )}
          </div>
        </div>
      </ContentContainer>
    </HeaderContainer>
  );
};

// Harmonizers
const LeftContentHarmonizer = ({ CurrentIcon, text }: { CurrentIcon: IconType; text: string }) => (
  <LeftContent>
    <CurrentIcon /> {text}
  </LeftContent>
);

const RightContentHarmonizer = ({
  text,
  editionMod,
  register,
  fieldName = 'dont_forgot_to_add_field_name',
  mailto,
  mailtoCC
}: {
  text?: string;
  editionMod?: boolean;
  register?: UseFormRegister<FieldValues>;
  fieldName?: string;
  mailto?: boolean;
  mailtoCC?: string;
}) => {
  const { t } = useTranslation();
  const mailtoAction = () => {
    const mailtoLink = `mailto:${text}?cc=${mailtoCC}`;
    window.location.href = mailtoLink;
  };

  if (editionMod) {
    return (
      <InputWrapper>
        <input
          type="text"
          aria-label="-"
          placeholder={text}
          defaultValue={text}
          style={{ width: '50%', backgroundColor: theme.palette.grey[100] }}
          {...register?.(fieldName)}
        />
      </InputWrapper>
    );
  }
  return (
    <RightContent>
      {text || '-'}
      {mailto && text && (
        <Tooltip
          icon={
            <Icon
              onClick={mailtoAction}
              className="icon-link"
              size="small"
              variant="blue"
              iconSize="14px"
              style={{ cursor: 'pointer' }}
            >
              <EnvelopeFill />
            </Icon>
          }
          direction="bottom"
        >
          {t('escalate.drawer.info.sendMail', 'Envoyer Email')}
        </Tooltip>
      )}
    </RightContent>
  );
};

export const DrawerInformationsContact = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { register, handleSubmit } = useForm();

  const [editionMod, setEditionMod] = useState(false);
  const [level, setLevel] = useState<Level | null>(null);

  useEffect(() => {
    const fetchLevel = async () => {
      if (params.levelId) {
        const response = await getLevelById(params.levelId);
        setLevel(response.data);
      }
    };
    fetchLevel();
  }, [params.levelId]);

  const { title, childSection, parentSection } = useMemo(() => {
    return {
      title: level?.section?.escalation?.name,
      childSection: level?.section?.name,
      parentSection: level?.section?.parentSection?.name
    };
  }, [level]);

  const { name, priority, role, lastname, firstname, phone, email, email_carbon_copy } =
    level ?? {};

  const closeModal = () => navigate('/escalation');
  const openEdition = () => setEditionMod(true);
  const closeEdition = () => setEditionMod(false);

  const mySubmit = (data: FieldValues) => {
    editLevelsById(Number(params.levelId), data)
      .then((response) => {
        setLevel(response.data);
        toast.success(t('escalate.drawer.info.toast.edition', 'Votre contact à bien été modifié'));
        closeEdition();
      })
      .catch(() => {
        toast.error(t('escalate.drawer.info.toast.editionFail', 'Please retry'));
      });
  };

  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);

  return (
    <Drawer
      isOpen={true}
      position="right"
      headerBackgroundImage={hearder_drawer_contact}
      headerComponent={
        <HeaderComponent
          title={title || '-'}
          name={name || '-'}
          matriceSection={parentSection || ''}
          subMatriceSection={childSection || ''}
          priority={priority}
        />
      }
      onClose={closeModal}
    >
      <Container>
        {!!priority && (
          <InformationBlock
            type="warning"
            title={t(
              'escalate.drawer.info.blocWarning',
              "Les contacts d’escalades doivent être sollicités de manière séquencée, niveau par niveau. Un niveau d’escalade ne peut être sollicité que si le niveau inférieur l’a été au préalable. L'escalade ne sera pas prise en considération si ce process n'a pas été respecté."
            )}
          />
        )}
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px', overflow: 'auto' }}>
          <Square
            size={10}
            style={{ color: theme.palette.green[500], backgroundColor: theme.palette.green[500] }}
          />
          <Title style={{ fontSize: '12px', overflow: 'hidden' }}>
            {t('escalate.drawer.info.container.title', 'Informations')}
          </Title>
        </div>
        <DualPanel
          right={
            <RightContentHarmonizer
              register={register}
              text={role}
              editionMod={editionMod}
              fieldName="role"
            />
          }
          left={
            <LeftContentHarmonizer
              text={t('escalate.drawer.info.role', 'Role')}
              CurrentIcon={Joystick}
            />
          }
        />
        <DualPanel
          right={
            <RightContentHarmonizer
              register={register}
              editionMod={editionMod}
              text={lastname}
              fieldName="lastname"
            />
          }
          left={
            <LeftContentHarmonizer
              text={t('escalate.drawer.info.lastname', 'Nom')}
              CurrentIcon={Person}
            />
          }
        />
        <DualPanel
          right={
            <RightContentHarmonizer
              register={register}
              editionMod={editionMod}
              text={firstname}
              fieldName="firstname"
            />
          }
          left={
            <LeftContentHarmonizer
              text={t('escalate.drawer.info.firstName', 'Prénom')}
              CurrentIcon={Person}
            />
          }
        />
        <DualPanel
          right={
            <RightContentHarmonizer
              register={register}
              editionMod={editionMod}
              text={phone}
              fieldName="phone"
            />
          }
          left={
            <LeftContentHarmonizer
              text={t('escalate.drawer.info.phone', 'Téléphone')}
              CurrentIcon={Telephone}
            />
          }
        />
        <DualPanel
          right={
            <RightContentHarmonizer
              register={register}
              editionMod={editionMod}
              fieldName="email"
              text={email}
              mailto
              mailtoCC={email_carbon_copy}
            />
          }
          left={
            <LeftContentHarmonizer
              text={t('escalate.drawer.info.email', 'Email')}
              CurrentIcon={Envelope}
            />
          }
        />
        <DualPanel
          right={
            <RightContentHarmonizer
              register={register}
              text={email_carbon_copy}
              editionMod={editionMod}
              fieldName="email_carbon_copy"
            />
          }
          left={
            <LeftContentHarmonizer
              text={t('escalate.drawer.info.copyMail', 'Email en copie')}
              CurrentIcon={Back}
            />
          }
        />

        {profileType.rolesNames.includes(Role.PORTAIL_INFOS_ESCALATION_EDITOR_INTERN) && (
          <EditSavePanel
            editionMod={editionMod}
            closeEdition={closeEdition}
            openEdition={openEdition}
            handleSubmit={handleSubmit(mySubmit)}
          />
        )}
      </Container>
    </Drawer>
  );
};

interface EditSavePanelProps {
  editionMod: boolean;
  openEdition: () => void;
  closeEdition: () => void;
  handleSubmit: () => void;
}

const EditSavePanel = ({
  editionMod,
  openEdition,
  closeEdition,
  handleSubmit
}: EditSavePanelProps) => {
  const { t } = useTranslation();
  return editionMod ? (
    <DualPanel
      right={
        <div>
          <Button design="white" style={{ marginRight: '8px' }} onClick={closeEdition}>
            {t('component.button.cancel')}
          </Button>
          <Button onClick={handleSubmit}>{t('component.button.save')}</Button>
        </div>
      }
      left={<></>}
    />
  ) : (
    <Button onClick={openEdition} style={{ width: '', alignSelf: 'center' }}>
      <Pen />
      {t('component.button.edit')}
    </Button>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px;
  gap: 25px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-bottom: 150px;
`;

const LeftContent = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--gray-gray-500, #a5a5ba);
  font-size: 12px;
  font-family: Roboto;
  font-size: normal;
  font-weight: 500;
`;

const RightContent = styled.div<RightContentProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  color: var(--gray-600, #4b5563);
  text-align: center;
  font-size: 12px;
  font-family: Roboto;
  font-size: normal;
  font-weight: 500;
`;

const HeaderContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
`;

const ContentContainer = styled.div`
  height: 80%;
  width: 100%;
  margin-right: 24px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  font-size: ${(props) => props.theme.fonts.size.xl};
`;
