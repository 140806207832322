import {
  ICalendarAPI,
  ICalendarLastWeekSlotFromApi,
  ICalendarLastWeekSlotsForForm,
  ICalendarSaveAPIForm,
  ICreateCalendarForm
} from './interfaces';

import { DateManager } from '../../../utils/DateManager';

const slotsDaysFormName = [
  'slotsForMonday',
  'slotsForTuesday',
  'slotsForWednesday',
  'slotsForThursday',
  'slotsForFriday'
];

const makeDefautlFormValues = (
  currentCalendar: ICalendarAPI | null,
  calendarLastWeekSlots: ICalendarLastWeekSlotsForForm | null
) => {
  let stepOneValues = {
    zoneGeoName: undefined,
    interventionType: undefined,
    minDaysBooking: 0,
    minDaysCancel: 0,
    minDaysPostpone: 0,
    alertThreshold: 0,
    dateRange: undefined,
    managers: [],
    cafMails: []
  } as Partial<ICreateCalendarForm>;

  if (currentCalendar !== null) {
    stepOneValues = {
      zoneGeoName: { label: currentCalendar.zonegeo_name, value: `${currentCalendar.id_zonegeo}` },
      interventionType: {
        label: currentCalendar.type_operation_label,
        value: `${currentCalendar.type_operation}`
      },
      minDaysBooking: currentCalendar.min_days_book_before_appointment,
      minDaysCancel: currentCalendar.min_days_cancel_before_appointment,
      minDaysPostpone: currentCalendar.min_days_postpone_before_appointment,
      alertThreshold: currentCalendar.alert_threshold,
      managers: currentCalendar.managers.map((manager: { name: string }) => {
        return { label: manager.name, value: manager.name };
      }),
      cafMails: currentCalendar.alert_contacts.map((contact: { name: string }) => {
        return { label: contact.name, value: contact.name };
      })
    };
  }

  if (calendarLastWeekSlots !== null) {
    return {
      ...stepOneValues,
      ...calendarLastWeekSlots
    };
  }
  return {
    ...stepOneValues,
    slotsForMonday: {
      morning: 0,
      afternoon: 0,
      enabled: false
    },
    slotsForTuesday: {
      morning: 0,
      afternoon: 0,
      enabled: false
    },
    slotsForWednesday: {
      morning: 0,
      afternoon: 0,
      enabled: false
    },
    slotsForThursday: {
      morning: 0,
      afternoon: 0,
      enabled: false
    },
    slotsForFriday: {
      morning: 0,
      afternoon: 0,
      enabled: false
    }
  } as ICreateCalendarForm;
};

const makeFormValuesForAPI = (formValues: ICreateCalendarForm) => {
  const id_zonegeo = formValues.zoneGeoName ? parseInt(formValues.zoneGeoName?.value, 10) : 0;
  const type_operation = formValues.interventionType
    ? parseInt(formValues.interventionType?.value, 10)
    : 0;

  let managers = [] as any;
  if (formValues.managers && Array.isArray(formValues.managers) && formValues.managers.length > 0) {
    managers =
      formValues.managers.length > 0
        ? formValues.managers.map((manager) => {
            return { name: manager.value };
          })
        : [];
  }

  let contacts = [] as any;
  if (formValues.cafMails && Array.isArray(formValues.cafMails) && formValues.cafMails.length > 0) {
    contacts =
      formValues.cafMails.length > 0
        ? formValues.cafMails.map((contact) => {
            return { name: contact.value };
          })
        : [];
  }

  return {
    id_zonegeo,
    type_operation,
    max_appointments_per_slot: 10, // fixme:  Not anticipated
    min_days_book_before_appointment: formValues.minDaysBooking || 0,
    min_days_cancel_before_appointment: formValues.minDaysCancel || 0,
    min_days_postpone_before_appointment: formValues.minDaysPostpone || 0,
    alert_threshold: formValues.alertThreshold || 0,
    managers,
    alert_contacts: contacts,
    alert_enable: true
  } as ICalendarSaveAPIForm;
};

const makeScheduleBulkObject = (
  scheduleType: number,
  start_date: string,
  end_date: string,
  formValues: ICreateCalendarForm
) => {
  return {
    max_appointments_list: {
      Mon: getSlotsForADay('slotsForMonday', scheduleType, formValues),
      Tue: getSlotsForADay('slotsForTuesday', scheduleType, formValues),
      Wed: getSlotsForADay('slotsForWednesday', scheduleType, formValues),
      Thu: getSlotsForADay('slotsForThursday', scheduleType, formValues),
      Fri: getSlotsForADay('slotsForFriday', scheduleType, formValues)
    },
    start_date: new DateManager(start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    end_date: new DateManager(end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
  };
};

const getSlotsForADay = (
  slotName: string,
  scheduleType: number,
  formValues: ICreateCalendarForm
) => {
  let daySlots = 0;
  if (formValues[slotName].enabled) {
    daySlots = scheduleType === 1 ? formValues[slotName].morning : formValues[slotName].afternoon;
  }
  return daySlots;
};

const transformApiSlotsForCalendarForm = (
  slotsValue: ICalendarLastWeekSlotsForForm,
  slots: ICalendarLastWeekSlotFromApi[],
  slotTime: 'morning' | 'afternoon'
) => {
  const tempSlotsValue = { ...slotsValue };

  slots.forEach((slot) => {
    switch (new DateManager(slot.day).day()) {
      case 1:
        tempSlotsValue.slotsForMonday.enabled = true;
        tempSlotsValue.slotsForMonday[slotTime] = slot.max_appointments;
        break;
      case 2:
        tempSlotsValue.slotsForTuesday.enabled = true;
        tempSlotsValue.slotsForTuesday[slotTime] = slot.max_appointments;
        break;

      case 3:
        tempSlotsValue.slotsForWednesday.enabled = true;
        tempSlotsValue.slotsForWednesday[slotTime] = slot.max_appointments;
        break;
      case 4:
        tempSlotsValue.slotsForThursday.enabled = true;
        tempSlotsValue.slotsForThursday[slotTime] = slot.max_appointments;
        break;
      case 5:
        tempSlotsValue.slotsForFriday.enabled = true;
        tempSlotsValue.slotsForFriday[slotTime] = slot.max_appointments;
        break;
      default:
        break;
    }
  });
  return tempSlotsValue;
};

const mergeLastWeekSlots = (
  morningSlots: ICalendarLastWeekSlotsForForm,
  afternoonSlots: ICalendarLastWeekSlotsForForm
) => {
  const tempMergeSlotsValue = {
    ...morningSlots
  };

  slotsDaysFormName.forEach((dayOfTheWeek) => {
    tempMergeSlotsValue[dayOfTheWeek] = {
      ...tempMergeSlotsValue[dayOfTheWeek],
      enabled: afternoonSlots[dayOfTheWeek].enabled || morningSlots[dayOfTheWeek].enabled,
      afternoon: afternoonSlots[dayOfTheWeek].afternoon
    };
  });

  return tempMergeSlotsValue;
};

export {
  slotsDaysFormName,
  makeDefautlFormValues,
  makeFormValuesForAPI,
  makeScheduleBulkObject,
  transformApiSlotsForCalendarForm,
  mergeLastWeekSlots
};
