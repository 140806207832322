import { IThemedSwitch, defaultStyle } from './designStyles';
import React, { ReactElement } from 'react';

import { ThemeVariants } from '../../style/theme';
import styled from 'styled-components';

export interface SwitchProps extends React.HTMLProps<HTMLElement> {
  variant?: ThemeVariants;
  disabled?: boolean;
  inputLabel: ReactElement | string;
  children: React.ReactNode;
}

const StyledSwitch = styled.label<IThemedSwitch>`
  ${defaultStyle()}
`;

const Switch = ({ variant = 'blue', ref, as, inputLabel, children, ...props }: SwitchProps) => {
  return (
    <StyledSwitch variant={variant} {...props}>
      <div className="switch">{children}</div>
      <span>{inputLabel}</span>
    </StyledSwitch>
  );
};

export default Switch;
