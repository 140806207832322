import { ProductOfferingQualificationItem, ProductPrice } from '../../interfaces';
import { listOfProductOptions, periodTranslations } from '../constants';
import theme, { ThemeProps } from '@theme';

import Badge from '@designSystem/Badges';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { OrderOptions } from '@features/order/utils/orderInterfaces';
import React from 'react';
import Switch from '@designSystem/SwitchInput';
import Tooltip from '@designSystem/Tooltip';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const OptionsForm: React.FC<{
  selectedProduct?: ProductOfferingQualificationItem;
  defaultOption: OrderOptions;
  optionsToOrder?: OrderOptions;
  setOptionsToOrder?: CallableFunction;
  theme?: ThemeProps;
  editable?: boolean;
  trunkCount?: number;
}> = ({
  selectedProduct,
  defaultOption,
  optionsToOrder = defaultOption,
  setOptionsToOrder,
  editable = true,
  trunkCount
}) => {
  const gtrList = getGtrListFromProduct(selectedProduct);
  const getPriceByName = (
    selectedProduct: ProductOfferingQualificationItem | undefined,
    namePart: string
  ) => {
    const item = selectedProduct?.product.productPrice?.filter((i) => i.name.includes(namePart));
    const itemPrice = item && item.length > 0 ? item[0].price.dutyFreeAmount.value : null;
    return itemPrice;
  };

  const eqtPrice = getPriceByName(selectedProduct, 'install_eqt_true_activation_fee');
  const bronzePrice = getPriceByName(selectedProduct, 'bronze_securing_true_monthly_fee');
  const optionPlusPrice = getPriceByName(selectedProduct, 'option_plus_true_monthly_fee');

  const { t } = useTranslation();

  const renderOption = (
    optionPrice: number | null,
    optionKey: 'eqt' | 'bronze' | 'optionPlus',
    label: string,
    forbiddenReason?: string
  ) => {
    const classNotClickable = !editable || forbiddenReason ? 'not-clickable' : '';

    return optionPrice !== null ? (
      <div>
        <Switch
          className={classNotClickable}
          inputLabel={
            <p className={`clickable ${classNotClickable}`}>
              <span style={forbiddenReason ? { opacity: 0.5 } : {}}>{label}</span>&nbsp;
              {forbiddenReason && (
                <Tooltip icon={<InfoCircleFill fill={theme.palette.red[500]} />}>
                  {forbiddenReason}
                </Tooltip>
              )}
            </p>
          }
        >
          <input
            type="checkbox"
            style={!editable || !!forbiddenReason ? { cursor: 'not-allowed', opacity: 0.5 } : {}}
            checked={!!optionsToOrder[optionKey]}
            onChange={() => {
              if (editable && setOptionsToOrder) {
                setOptionsToOrder({ ...optionsToOrder, [optionKey]: !optionsToOrder[optionKey] });
              }
            }}
            disabled={!editable || !!forbiddenReason}
          />
        </Switch>

        {optionPrice === 0 ? (
          <Badge variant={optionsToOrder[optionKey] ? 'green' : 'grey'}>
            {t('features.eligibility.products.free', 'Free')}{' '}
            {optionsToOrder[optionKey] ? '✨' : ''}
          </Badge>
        ) : (
          <strong>{optionPrice} €</strong>
        )}
      </div>
    ) : null;
  };

  const notClickable = !editable ? 'not-clickable' : '';

  return (
    <>
      <StyledOptions>
        <p>{t('features.eligibility.products.productDetail.gtr', 'GTR')}</p>
        {gtrList?.map((gtr, index) => {
          const isSwitchDisabled =
            !editable || (defaultOption.gtr === gtr.name && optionsToOrder.gtr === gtr.name);

          return (
            <div key={index}>
              <Switch
                className={`${
                  index === 0 && isSwitchDisabled ? 'with_check_icon' : ''
                } ${notClickable}`}
                inputLabel={
                  <p className={`${!isSwitchDisabled ? 'clickable' : ''} ${notClickable}`}>
                    {listOfProductOptions[gtr.name]}
                  </p>
                }
              >
                <input
                  style={!editable ? { cursor: 'not-allowed', opacity: 0.5 } : {}}
                  onChange={() => {
                    if (editable && setOptionsToOrder) {
                      setOptionsToOrder({
                        ...optionsToOrder,
                        gtr: optionsToOrder.gtr !== gtr.name ? gtr.name : defaultOption.gtr
                      });
                    }
                  }}
                  type="checkbox"
                  checked={optionsToOrder.gtr === gtr.name}
                  disabled={isSwitchDisabled}
                />
              </Switch>
              <strong>
                {gtr.price?.dutyFreeAmount?.value === 0 ? (
                  <Badge variant="green">
                    {t(
                      'features.eligibility.products.productDetail.includedOffer',
                      'Included in your offer'
                    )}
                  </Badge>
                ) : (
                  <>
                    {gtr.price?.dutyFreeAmount?.value} €
                    {gtr.recurringChargePeriod &&
                      '/' + periodTranslations[gtr.recurringChargePeriod || 'default']}
                  </>
                )}
              </strong>
            </div>
          );
        })}
        {(eqtPrice ?? bronzePrice ?? optionPlusPrice) !== null ? (
          <>
            <p>Autres </p>
            {renderOption(
              eqtPrice,
              'eqt',
              t(
                'features.eligibility.products.productDetail.terminal',
                'Terminal equipment installation'
              )
            )}
            {renderOption(
              bronzePrice,
              'bronze',
              t('features.eligibility.products.productDetail.bronzeOption', 'Sécurisation Bronze'),
              trunkCount && trunkCount < 2
                ? t(
                    'features.eligibility.products.productDetail.bronzeForbiddenReason',
                    'Pas assez de troncs'
                  )
                : undefined
            )}
            {renderOption(
              optionPlusPrice,
              'optionPlus',
              t('features.eligibility.products.productDetail.optionPlus', 'Option Plus')
            )}
          </>
        ) : null}
      </StyledOptions>
    </>
  );
};

export default OptionsForm;

const sortGtrProductByPrice = (
  productPrice: ProductPrice[] | undefined
): ProductPrice[] | undefined => {
  if (productPrice === undefined) return;
  return [...productPrice].sort(
    (a, b) => a.price.dutyFreeAmount.value - b.price.dutyFreeAmount.value
  );
};
const getGtrListFromProduct = (product: ProductOfferingQualificationItem | undefined) => {
  const gtrList = product?.product.productPrice?.filter((i) => i.name.includes('gtr'));

  return sortGtrProductByPrice(gtrList);
};

export const getDefaultGtrFromProduct = (product: ProductOfferingQualificationItem): string => {
  const gtrList = getGtrListFromProduct(product);
  return gtrList?.length ? gtrList[0].name : 'gtr_basic_monthly_fee';
};

export const StyledOptions = styled.div`
  p {
    font-size: ${theme.fonts.size.sm};
    font-weight: ${theme.fonts.weight.medium};
    color: ${theme.palette.grey[700]};
    margin-bottom: 0.25rem;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    p,
    strong {
      color: ${theme.palette.grey[900]};
      margin-bottom: 0;
      font-weight: ${theme.fonts.weight.medium};
      font-size: ${theme.fonts.size.sm};
    }
    strong {
      font-size: ${theme.fonts.size.base};
    }
  }
`;
