import { Col, Row } from 'react-bootstrap';
import { queries, transformSegmentForCube } from './utils/chartsQueries';

import ChartCardContainer from './ChartCardContainer';
import { CubejsApi } from '@cubejs-client/core';
import EchartsDrawer from './drawer/EchartsDrawer';
import LeafletDrawer from './drawer/LeafletDrawer';
import React from 'react';
import { Segment } from './interfaces';
import { getTimeDimensions } from './helpers';
import { options } from './utils/chartsOptions';
import { useTranslation } from 'react-i18next';

const ChartsAndMap: React.FC<{
  segments: Segment[] | undefined;
  cubejsApi: CubejsApi;
  manageError?: (_e: Error) => void;
}> = ({ segments, cubejsApi, manageError }) => {
  const { t } = useTranslation();
  return (
    <>
      <ChartCardContainer
        title={t('features.charts.cardAdjournments', 'Carte des ajournements en cours')}
      >
        <LeafletDrawer
          cubejsApi={cubejsApi}
          query={{
            ...queries.carteAjournements,
            segments: transformSegmentForCube(segments, 'VMapAdjournments'),
            timeDimensions: [...getTimeDimensions('VMapAdjournments')]
          }}
          columnFilters={[
            'V Map Adjournments Liste Ajournements Coordonnees',
            'V Map Adjournments Wgs84 X',
            'V Map Adjournments Wgs84 Y'
          ]}
          cubeJsName="V Map Adjournments "
          errorCallback={manageError}
        />
      </ChartCardContainer>

      <Row>
        <Col>
          <ChartCardContainer noHeader>
            <EchartsDrawer
              cubejsApi={cubejsApi}
              query={{
                ...queries.nbJoursOuvresMoyenAjournement,
                segments: [
                  ...transformSegmentForCube(segments, 'NbJoursOuvresMoyenAjournement'),
                  ...(queries.nbJoursOuvresMoyenAjournement.segments || [])
                ],
                timeDimensions: [...getTimeDimensions('NbJoursOuvresMoyenAjournement')]
              }}
              chartType="line"
              chartOptions={options.nbJoursOuvresMoyenAjournement}
              errorCallback={manageError}
            />
          </ChartCardContainer>
        </Col>
      </Row>
    </>
  );
};

export default ChartsAndMap;
