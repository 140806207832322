import { ICreateZoneGeoAPIForm } from '../utils/interfaces';
import { SortZonesGeo } from './zonesGeoSlice';
import axios from 'axios';
import { env } from '../../../config';

export const zoneGeoCreate = async (body: ICreateZoneGeoAPIForm) => {
  return axios.post(`${env.API_URL}/erdv/zoneGeo`, {
    ...body
  });
};

export const zoneGeoFetchById = async (arg: { zoneGeoId: string }) => {
  const { zoneGeoId } = arg;
  return axios.get(`${env.API_URL}/erdv/zonegeo/${zoneGeoId}`);
};

export const zoneGeoUpdate = async (body: ICreateZoneGeoAPIForm) => {
  return axios.put(`${env.API_URL}/erdv/zoneGeo/${body.zoneGeoId}`, {
    ...body
  });
};

export const zoneGeodelete = async (id: number) => {
  return axios.delete(`${env.API_URL}/erdv/zoneGeo/${id}`);
};

export const zoneGeoFetchList = async (arg: {
  currentPage: number;
  sort?: SortZonesGeo;
  search?: string;
  rowsPerPage?: number;
}) => {
  const { currentPage, sort, search, rowsPerPage = 10 } = arg;
  const order =
    sort && sort.order_by
      ? `&order_by=${sort.order_by}&order_ascending=${sort.order_ascending}`
      : '';
  const search_str = search ? `&search=${search}` : '';
  return axios.get(
    `${env.API_URL}/erdv/zoneGeo?offset=${
      currentPage * rowsPerPage
    }&limit=${rowsPerPage}${order}${search_str}`
  );
};
