/**
 * Enum
 */
export enum Role {
  /**
   * This role comes from LDAP via SSO Keycloak, give you access to these features :<br>
   * [YES] eligibility.<br>
   * [YES] verticality.<br>
   * [YES] prices.<br>
   * [YES] estimate.<br>
   * [YES] study launch.<br>
   * [YES] command to SF.<br>
   * [YES] portal command.<br>
   * [YES] search slot (APEX)<br>
   * [YES] book slot (APEX)<br>
   */
  PORTAL_ORDER_FULL_BETA = 'portal-order_full-beta',
  /**
   * This role comes from LDAP via SSO Keycloak, give you access to these features:<br>
   * [YES] eligibility.<br>
   * [YES] verticality.<br>
   * [YES] prices.<br>
   * [YES] estimate.<br>
   * [YES] study launch.<br>
   * [YES] command to SF.<br>
   * [NO] portal command.<br>
   * [NO] search slot (APEX)<br>
   * [NO] book slot (APEX)<br>
   */
  PORTAL_ORDER_FULL = 'portal-order_full',
  /**
   * This role comes from LDAP via SSO Keycloak, give you access to these features:<br>
   * [YES] eligibility.<br>
   * [YES] verticality.<br>
   * [YES] prices.<br>
   * [YES] estimate.<br>
   * [YES] study launch.<br>
   * [NO] command to SF.<br>
   * [NO] portal command.<br>
   * [NO] search slot (APEX)<br>
   * [NO] book slot (APEX)<br>
   */
  PORTAL_ORDER_QUOTATION = 'portal-order_quotation',
  /**
   * This role comes from LDAP via SSO Keycloak, give you access to these features:<br>
   * [YES] eligibility.<br>
   * [YES] verticality.<br>
   * [YES] prices.<br>
   * [YES] estimate.<br>
   * [NO] study launch.<br>
   * [NO] command to SF.<br>
   * [NO] portal command.<br>
   * [NO] search slot (APEX)<br>
   * [NO] book slot (APEX)<br>
   */
  PORTAL_ORDER_PRICE = 'portal-order_price',
  /**
   * This role comes from LDAP via SSO Keycloak, give you access to these features:<br>
   * [YES] eligibility.<br>
   * [YES] verticality.<br>
   * [NO] prices.<br>
   * [NO] estimate.<br>
   * [NO] study launch.<br>
   * [NO] command to SF.<br>
   * [NO] portal command.<br>
   * [NO] search slot (APEX)<br>
   * [NO] book slot (APEX)<br>
   */
  PORTAL_ORDER_CONSULTATION = 'portal-order_consultation',
  /**
   * This role comes from LDAP via SSO Keycloak, give you access to these features:<br>
   * [YES] Site status.<br>
   * [YES] Adjournments.<br>
   */
  PORTAL_INFOS_ALL = 'portal-infos_all',
  /**
   * This role comes from LDAP via SSO Keycloak, give you access to these features:<br>
   * [YES] Site status.<br>
   * [YES] Adjournments.<br>
   */
  PORTAL_INFOS_DSP_RESTRICTED = 'portal-infos_dsp-restricted',
  /**
   * This role comes from hasura, give you access to these features:<br>
   * [YES] eligibility.<br>
   * [YES] verticality.<br>
   * [NO] prices.<br>
   * [NO] estimate.<br>
   * [NO] study launch.<br>
   * [NO] command to SF.<br>
   * [NO] portal command.<br>
   * [NO] search slot (APEX)<br>
   * [NO] book slot (APEX)<br>
   */
  DELEGANT = 'U-DEL',
  /**
   * This role comes from hasura, give you access to these features:<br>
   * [YES] eligibility.<br>
   * [YES] verticality.<br>
   * [YES] prices.<br>
   * [YES] estimate.<br>
   * [YES] study launch.<br>
   * [NO] command to SF.<br>
   * [NO] portal command.<br>
   * [NO] search slot (APEX)<br>
   * [NO] book slot (APEX)<br>
   */
  INTERN = 'U-AXI',
  /**
   * This role comes from LDAP via SSO Keycloak
   */
  ERDV_ADMIN = 'erdv_admin',
  /**
   * This role comes from LDAP via SSO Keycloak
   */
  ERDV_MANAGER = 'erdv_manager',
  /**
   * This role comes from LDAP via SSO Keycloak
   */
  ERDV_SUPPLIER = 'erdv_supplier',
  /**
   * This role comes from LDAP via SSO Keycloak
   */
  ERDV_PROVIDER = 'erdv_provider',
  /**
   * This role comes from LDAP via SSO Keycloak
   */
  OBSERVABILITY_VIEWER = 'grafana-y78_viewer',
  /**
   * This role comes from LDAP via SSO Keycloak
   */
  OBSERVABILITY_ADMIN = 'grafana-y78_admin',
  /**
   * This role comes from LDAP via SSO Keycloak
   */
  OBSERVABILITY_EDITOR = 'grafana-y78_editor',
  FAI = 'efo_fai',
  DISTRIBUTEUR = 'efo_distributeur',
  FAI_RESTREINT = 'M-FAI-RESTREINT',
  EFO_INTERN = 'efo_interne',

  /**
   * /escalation
   * [YES] viewer and edit<br>
   */
  PORTAIL_INFOS_ESCALATION_EDITOR_INTERN = 'portal-infos_escalation-editor-intern',
  /**
   * /escalation/levels/:id not possibility for edit
   * [YES] viewer only<br>
   */
  PORTAIL_INFOS_ESCALATION_VIEWER = 'portal-infos_escalation-viewer'
}
export enum SpaceServicesRole {
  ServicesConsultation = 'mon-espace_services_consultation',
  SupportAll = 'mon-espace_support_sav_all',
  SupportConsultation = 'mon-espace_support_sav_consultation',
  ObservabilityConsultation = 'mon-espace_observabilite_consultation',
  ApplicationSupport = 'mon-espace_support_applicatif'
}

export type UserRole = {
  rolesNames: Role[] | SpaceServicesRole[];
  organization?: string;
  username?: string;
};
