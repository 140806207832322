import React, { ReactChild, useState } from 'react';

import { IThemedComponent } from '../designInterfaces';
import { ThemeVariants } from '../../style/theme';
import { defaultStyle } from './designStyles';
import styled from 'styled-components';

interface DropdownProps
  extends Omit<Omit<React.HTMLProps<HTMLElement>, 'placeholder'>, 'onSelect'> {
  variant?: ThemeVariants;
  onSelect?: (_txt: string) => void;
  items?: string[];
  placeholder?: ReactChild | string;
  children?: ReactChild[] | ReactChild | null;
  disabled?: boolean;
}

const styles = defaultStyle();

const StyledDropdown = styled.div<IThemedComponent>`
  ${styles}
`;

const Dropdown: React.FC<DropdownProps> = ({
  variant = 'blue',
  onSelect,
  items,
  placeholder = <span> </span>,
  children,
  ref, // Omit ref from React.HTMLProps<HTMLElement>
  as, // Omit as from React.HTMLProps<HTMLElement>
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const updatePlaceholder = (ev: any) => {
    const text = ev.currentTarget.textContent;
    setSelectedItem(text);
    onSelect && onSelect(text);
  };

  const itemList = (listItems: string[]) => {
    return listItems.map((item: string) => {
      const isSelected = selectedItem === item;
      return (
        <div
          onClick={updatePlaceholder}
          className={isSelected ? 'dropdown_item selected' : 'dropdown_item'}
          key={item.toString()}
        >
          {item}
        </div>
      );
    });
  };
  return (
    <StyledDropdown variant={variant} {...props}>
      <div
        className={isOpen ? 'dropdown active' : 'dropdown'}
        onClick={handleClick}
        data-testid="dropdown"
        style={{ width: '100%' }}
      >
        <div className="dropdown_text">{!selectedItem ? placeholder : selectedItem}</div>
        <div className="dropdown_items">{items ? itemList(items) : children}</div>
      </div>
    </StyledDropdown>
  );
};

export default Dropdown;
