import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import SelectSearchComponent, { SelectSearchProps } from './SelectSearchComponent';
import { clearSearchType, loadOrganizations, setSearchType } from '../eligibilitySlice';

import AsyncSelect from 'react-select/async';
import { IOrganization } from '../interfaces';
import { StyledWrapComponent } from './SearchStyle';
import { customSelectStyles } from '../../../style/customSelectStyle';
import { decodeDataFrom } from '../helper';
import { useAppDispatch } from '../../../app/hooks';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IFormInputs {
  siret: { label: string; value: string } | null;
}

const formatOptions = (items: IOrganization[]) => {
  return items.map((item: IOrganization) => {
    return {
      label: `${item.name} - ${item.siret}`,
      value: item.siret
    };
  });
};
const SearchBySIRET: React.FC<{
  selectProps?: SelectSearchProps;
}> = ({ selectProps }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [inputValue] = useState(decodeDataFrom(searchParams).siret || '');
  const { control, watch } = useForm<IFormInputs>({
    mode: 'onChange',
    defaultValues: { siret: null }
  });
  let options = [];
  const siret = watch('siret');

  const loadOptions = (inputVal: string, callback: CallableFunction) => {
    const paramSiret = decodeDataFrom(searchParams).siret;
    if (!inputVal && paramSiret) inputVal = paramSiret;
    if (inputVal) {
      dispatch(loadOrganizations(inputVal)).then((res) => {
        if (res.payload) {
          options = formatOptions(res.payload);
          callback(options);
        }
      });
    } else {
      callback([]);
    }
  };

  useEffect(() => {
    if (siret) {
      dispatch(
        setSearchType({
          type: 'siret',
          data: { label: siret.label, siret: siret.value }
        })
      );
    } else {
      dispatch(clearSearchType());
    }
  }, [siret]);

  return (
    <>
      <StyledWrapComponent>
        <Controller
          name="siret"
          control={control}
          render={({ field }) => (
            <AsyncSelect
              {...field}
              aria-label="organization-select"
              loadOptions={loadOptions}
              placeholder={t(
                'features.eligibility.search.enterSiren',
                'Enter a SIREN or a SIRET to find a company'
              )}
              defaultInputValue={inputValue}
              defaultOptions
              defaultMenuIsOpen={!!inputValue}
              isClearable={true}
              value={siret}
              styles={customSelectStyles}
              noOptionsMessage={() => t('errorMessages.notFound', 'No result found')}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
            />
          )}
        />
        {selectProps && <SelectSearchComponent {...selectProps} />}
      </StyledWrapComponent>
    </>
  );
};

export default SearchBySIRET;
