import { OrderSections } from '../utils/OrderHelper';
import { StyledOrderSection } from '@features/order/utils/OrderStyles';
import { useTranslation } from 'react-i18next';
import { StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import React from 'react';
import styled from 'styled-components';
import theme from '@theme';
import {
  IOrderInformationsOperaBusinessForm,
  OrderOptionsPlus
} from '@features/order/utils/orderInterfaces';
import { useAppSelector } from '../../../app/hooks';
import { selectOrderState } from '@features/order/reducer/orderSlice';
import { TFunction } from 'i18next';
import OrderRecapEditLink from './components/OrderRecapEditLink';
import { useSearchParams } from 'react-router-dom';

export const OrderRecapTechnicalCharacteristic: React.FC<{
  orderInformation: IOrderInformationsOperaBusinessForm;
  optionPlusSelected: OrderOptionsPlus;
}> = ({ orderInformation, optionPlusSelected }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isEditable = searchParams.get('draftStatut') !== 'finish';
  const { selectedOptions } = useAppSelector(selectOrderState);

  return (
    <StyledOrderSection>
      <div className="section-title">
        {t(
          'features.order.orderInformations.technicalCharacteristics.nominal_access',
          `Technical characteristics of nominal access`
        )}
      </div>
      <Information title="Interface" value={orderInformation.networkInterface} />
      <Information title="VLAN" value={orderInformation.vlan} />
      <Information
        t={t}
        title="Transparence spanning tree"
        value={orderInformation.spanningTreeTransparency}
      />

      {selectedOptions?.optionPlus ? (
        <>
          <div className="section-title" style={{ marginTop: 32 }}>
            {t(
              'features.order.orderInformations.technicalCharacteristics.optionPlusSelectedLabel',
              'Technical characteristics of the Option Plus selected'
            )}
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <Information title="Interface" value={optionPlusSelected.interfaceOptionPlus} />
            <Information
              t={t}
              title={t('features.order.orderInformations.technicalCharacteristics.nte_port_label')}
              value={optionPlusSelected.ntePortType}
            />
            <Information title="VLAN" value={optionPlusSelected.vlanOptionPlus} />
            <Information title="C-VLAN" value={optionPlusSelected.cVlanOptionPlus} force />
          </div>
        </>
      ) : null}

      {isEditable && <OrderRecapEditLink stepToGo={3} anchorKey={OrderSections.Outlet} />}
    </StyledOrderSection>
  );
};

const ValueStyled = styled.div`
  color: ${theme.palette.grey[700]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 18px;
`;

const Information = ({
  title,
  value,
  force = false,
  t
}: {
  title: string;
  value?: string | number | boolean;
  force?: boolean;
  t?: TFunction<'translation', undefined, 'translation'>;
}) => {
  const yesTranlsation = t && t('app.yes', 'Yes');
  const noTranlsation = t && t('app.no', 'No');

  if (typeof value === 'boolean') {
    return (
      <div>
        <StyledLabel className="section-label-question">{title}</StyledLabel>
        <ValueStyled>{value ? yesTranlsation : noTranlsation}</ValueStyled>
      </div>
    );
  }

  return value || force ? (
    <div>
      <StyledLabel className="section-label-question">{title}</StyledLabel>
      <ValueStyled>{value || 'N/A'}</ValueStyled>
    </div>
  ) : null;
};
