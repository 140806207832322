import React, { useEffect } from 'react';

import Button from '@designSystem/Button';
import InputWrapper from '@designSystem/InputWrapper';
import { Modal } from 'react-bootstrap';
import { StyledLabel } from '@designSystem/InputWrapper/InputWrapperDesignStyles';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AppointmentCancelModal: React.FC<{
  idToCancel: number | false;
  setIdToCancel: (_newId: number | false) => void;
  onValidate: (_newAppt: string) => void;
}> = ({ idToCancel, setIdToCancel, onValidate }) => {
  const { register, watch, setValue } = useForm<{ cancelReason: string }>({
    mode: 'onChange',
    defaultValues: {
      cancelReason: ''
    }
  });
  const cancelReason = watch('cancelReason');
  useEffect(() => {
    setValue('cancelReason', '');
  }, [idToCancel]);
  const { t } = useTranslation();
  return (
    <Modal show={!!idToCancel || idToCancel === 0} onHide={() => setIdToCancel(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('features.appointment.modals.confirmCancel', 'Confirm cancel ?')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledLabel>
          {t('features.appointment.modals.precise', 'Precise cancel reason')}
        </StyledLabel>

        <InputWrapper>
          <input
            autoFocus
            type="text"
            {...register('cancelReason')}
            value={cancelReason}
            aria-label="cancelReason-input"
            placeholder={t('features.appointment.modal.cancelReason', 'cancel reason')}
          />
        </InputWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button design="reverse" onClick={() => setIdToCancel(false)} style={{ float: 'right' }}>
          {t('component.button.cancel', 'Cancel')}
        </Button>
        <Button
          onClick={() => onValidate(cancelReason)}
          data-testid="AppointmentCancelModal-confirm"
        >
          {t('component.button.confirm', 'Confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppointmentCancelModal;
