import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from '@theme';
import { OrderSections } from '@features/order/utils/OrderHelper';
import OrderRecapEditLink from '@features/order/orderRecap/components/OrderRecapEditLink';
import { OrderOptions } from '@features/order/utils/orderInterfaces';
import { useSearchParams } from 'react-router-dom';

export const OrderRecapTrunk = ({ selectedOptions }: { selectedOptions: OrderOptions }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isEditable = searchParams.get('draftStatut') !== 'finish';
  return (
    <OrderRecapTrunkStyled>
      <div className="title_section">Troncs</div>

      <div className="trunks">
        <Trunk
          title={t(
            'features.eligibility.products.productDetail.optionTrunks_nominal',
            "Tronc de l'accès nominal"
          )}
          data={selectedOptions?.trunks?.trunk ?? '-'}
        />

        {selectedOptions?.trunks?.safetyTrunk ? (
          <Trunk
            title={t(
              'features.eligibility.products.productDetail.optionTrunks_bronze',
              "Tronc de l'option sécurisation bronze"
            )}
            data={selectedOptions?.trunks?.safetyTrunk}
          />
        ) : null}

        {selectedOptions?.trunks?.trunkOptionPlus ? (
          <Trunk
            title={t(
              'features.eligibility.products.productDetail.optionTrunks_plus',
              "Tronc de l'Option Plus"
            )}
            data={selectedOptions?.trunks?.trunkOptionPlus}
          />
        ) : null}
      </div>

      {isEditable && (
        <OrderRecapEditLink stepToGo={3} anchorKey={OrderSections.EndClientInformations} />
      )}
    </OrderRecapTrunkStyled>
  );
};

const OrderRecapTrunkStyled = styled.div`
  display: grid;
  grid-gap: 16px;

  .title_section {
    color: ${theme.palette.grey[500]};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .trunks {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
`;

const Trunk = ({ title, data }: { title: string; data: string }) => {
  return (
    <TrunkStyled>
      <div className="title-trunk">{title}</div>
      <div className="data">{data}</div>
    </TrunkStyled>
  );
};

const TrunkStyled = styled.div`
  font-size: 14px;
  font-style: normal;

  .title-trunk {
    color: ${theme.palette.grey[700]};
    font-weight: 500;
    line-height: 20px;
  }

  .data {
    color: ${theme.palette.grey[500]};
    font-weight: 400;
    line-height: 24px;
  }
`;
