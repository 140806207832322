import { FAKE_SIRET, OrderSections, concatComments } from './utils/OrderHelper';
import { FormProvider, useForm } from 'react-hook-form';
import { callPatchOrder, selectOrderState, updateStepData } from '../order/reducer/orderSlice';
import { orderVerticality, selectAdressInfos } from '../eligibility/eligibilitySlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import Card from '@designSystem/Card';
import { ClientType, IOrderCreationParams, IOrderInformationsForm } from './utils/orderInterfaces';
import { MatomoCustomContext } from '../../utils/MatomoCustomProvider';
import OrderFooter from './components/OrderFooter';
import OrderInformationsClientForm from './orderInformations/OrderInformationsClientForm';
import OrderInformationsComplementaryForm from './orderInformations/OrderInformationsComplementaryForm';
import OrderInformationsHeader from './orderInformations/OrderInformationsHeader';
import OrderInformationsModalConfirmPTO from './orderInformations/OrderInformationsModalConfirmPTO';
import OrderInformationsOutletForm from './orderInformations/OrderInformationsOutletForm';
import OrderInformationsSiteForm from './orderInformations/OrderInformationsSiteForm';
import { OrderSteps } from './utils/constants';
import { StyledSeparator } from './utils/OrderStyles';
import { VerticalityProvider } from '../eligibility/VerticalityProvider';
import { constructOrderInformationsDefaultValues } from './orderInformations/OrderInformationsHelper';
import { flushSync } from 'react-dom';
import { getDefaultGtrFromProduct } from '../eligibility/products/productDetail/OptionsForm';
import { isHomeBasicProduct } from '@features/eligibility/helper';
import { listOfProductOptions } from '../eligibility/products/constants';
import { onPatchOrderError } from '@features/order/OrderPatchOrderErrorHandler';
import styled from 'styled-components';
import theme from '@theme';
import toast from 'react-hot-toast';
import { unwrapResult } from '@reduxjs/toolkit';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorMonitoring } from '../../utils/ErrorMonitoring';
import OrderInformationsClientCompanyForm from '@features/order/orderInformations/company/OrderInformationsClientCompanyForm';
import OrderInformationsClientType from '@features/order/orderInformations/OrderInformationsClientType';
import OrderInformationsSidebar from '@features/order/orderInformations/orderInformationsSidebar/OrderInformationsSidebar';

const OrderInformationsBasicForm = () => {
  const dispatch = useAppDispatch();

  const addressInfos = useAppSelector(selectAdressInfos);
  const { orderId } = useParams();

  const { orderFormSteps, productToOrder, selectedOptions, order } =
    useAppSelector(selectOrderState);

  const alreadyFilledInformations = orderFormSteps.steps[OrderSteps.ORDER_INFO_FORM].data;
  const appointmentInformations = orderFormSteps.steps[OrderSteps.APPOINTMENT].data;

  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();

  const [showPTOWarningModal, setshowPTOWarningModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { matomoTrackPageView } = useContext(MatomoCustomContext);
  const trackEvent = useMatomoHarmonizer();

  useEffect(() => {
    const hash = window.location.hash;
    const id = hash.replace('#', '');
    const element = document.getElementById(id);

    if (element) {
      scrollToAnchor(element);
    }
  }, []);

  useEffect(() => {
    if (addressInfos && addressInfos.buildingRef) {
      dispatch(orderVerticality({ addressInfos }));
    }
  }, [addressInfos]);

  useEffect(() => {
    matomoTrackPageView(`Order_informations`);
  }, []);

  const scrollToAnchor = (element: HTMLElement) => {
    // 👇 Will wait until the DOM is updated with the new state
    // Works approximately for some ids cause of the VerticalityProvider
    flushSync(() => {
      element.scrollIntoView({ behavior: 'smooth' });
      history.replaceState(null, '', ' '); // We delete hash to prevent auto-scrolling
    });
  };

  const formMethods = useForm<IOrderInformationsForm>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: constructOrderInformationsDefaultValues(addressInfos, alreadyFilledInformations)
  });

  const {
    control,
    setValue,
    getValues,
    trigger,
    watch,
    setError,
    clearErrors,
    formState: { isDirty }
  } = formMethods;
  const watchAllFields = watch();
  const [isMissingSIRET, setIsMissingSIRET] = useState<boolean>(
    watchAllFields.clientType === ClientType.INDIVIDUAL ? true : false
  );

  const nextStep = () => {
    if (!isDirty) {
      dispatch(
        updateStepData({ step: OrderSteps.ORDER_INFO_FORM, data: getValues(), nextStep: true })
      );
    } else if (order && order.id) {
      const formValues = getValues();
      const comment = concatComments(
        formValues.clientType,
        formValues?.complementaryOutlet || false,
        formValues.outlet,
        formValues?.businessName,
        formValues.comments,
        appointmentInformations?.appointmentComments
      );
      dispatch(
        callPatchOrder({
          ...formValues,
          siret: formValues.siret || (isHomeBasicProduct(productToOrder) ? FAKE_SIRET : undefined),
          id: order.id,
          address: addressInfos,
          comments: comment,
          productOfferingId: productToOrder?.product.productOffering.id,
          options: {
            eqt: selectedOptions?.eqt || false,
            gtr:
              (productToOrder &&
                listOfProductOptions[
                  selectedOptions?.gtr || getDefaultGtrFromProduct(productToOrder)
                ]) ||
              ''
          }
        })
      )
        .then(unwrapResult)
        .then(() => {
          dispatch(
            updateStepData({ step: OrderSteps.ORDER_INFO_FORM, data: getValues(), nextStep: true })
          );
        })
        .catch((error) => {
          onPatchOrderError(error, setLoading, setError, trackEvent, t);
          new ErrorMonitoring().captureException(Error('PATCH order exception'), {
            extra: error
          });
        });
    } else {
      setLoading(false);
      toast.error('An error happened');
      new ErrorMonitoring().captureException(
        Error('PATCH order exception: There is no order or order.id')
      );
    }
  };

  const handleNextStep = async () => {
    trackEvent({
      page: 'Order_informations',
      category: 'go_next_step_button',
      actionType: 'Clic',
      actionDetails: 'Etape_Suivante'
    });
    const isValid = await trigger();

    const phoneRegex = /^(?!00)(\+)?\d{10,14}$/;
    const formValues = getValues();
    const isPhoneValid = phoneRegex.test(formValues.phoneContact1);
    if (!isPhoneValid) {
      setError('phoneContact1', {
        type: 'manual',
        message: t('features.order.orderInformations.invalid.form.phone1', 'Invalid phone number.')
      });
      formRef?.current?.scrollIntoView();
      return;
    }
    const siretRegex = /^\d{14}$/;
    const isSiretValid = isMissingSIRET ? true : siretRegex.test(formValues.siret);
    if (!isSiretValid) {
      setError('siret', {
        type: 'manual',
        message: t('features.order.orderInformations.invalid.form.invalid', 'Invalid Siret')
      });
      formRef?.current?.scrollIntoView();
      return;
    }
    if (isValid) {
      const existingOutletSelected = watchAllFields.existingOutlet && watchAllFields.outlet;

      if (existingOutletSelected) {
        trackEvent({
          page: 'Order_informations',
          category: 'go_next_step_button',
          actionType: 'Vu',
          actionDetails: 'PTO_Warning_PopUp'
        });
        return setshowPTOWarningModal(true);
      }
      setLoading(true);
      return nextStep();
    } else {
      trackEvent({
        page: 'Order_informations',
        category: 'go_next_step_button',
        actionType: 'Erreur',
        actionDetails: 'Formulaire_Invalide'
      });
      formRef?.current?.scrollIntoView();
    }
  };

  const isHomeOrOfficeProduct = useMemo(() => {
    if (
      productToOrder?.productOffering.name.includes('Home') ||
      productToOrder?.productOffering.name.includes('Office')
    )
      return true;
    return false;
  }, [productToOrder?.productOffering.name]);

  //SIRET
  const companies = addressInfos?.organizations?.map((organization) => ({
    siret: organization.siret,
    name: organization.name,
    siren: organization.siren
  }));
  const siretValue = watch('siret');

  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  }>({ label: '', value: '' });
  const defineSiretToRegistration = () => {
    setValue('siret', '');
    setValue('businessName', '');
    setValue('isSiretMandatory', false);
    clearErrors('siret');

    clearErrors('businessName');
    setIsMissingSIRET(true);
  };

  const setWithSiret = () => {
    setValue('siret', '');
    clearErrors('siret');
    clearErrors('businessName');
    setSelectedOption({ label: '', value: '' });
    setIsMissingSIRET(false);
  };

  const setToMissingSiret = () => {
    setValue('siret', '');
    clearErrors('siret');
    clearErrors('businessName');
    setSelectedOption({ label: '', value: '' });
    setIsMissingSIRET(true);
  };

  return (
    <>
      <CardOrderInformation>
        <Card
          header={<OrderInformationsHeader />}
          footer={
            <OrderFooter
              handleNextStep={handleNextStep}
              previousStep={OrderSteps.SELECT_PRODUCT}
              disabledBack={!!(order && orderId)}
              loading={loading}
            />
          }
        >
          <FormProvider {...formMethods}>
            <form ref={formRef} onSubmit={formMethods.handleSubmit(handleNextStep)}>
              {!isHomeBasicProduct(productToOrder) ? (
                <OrderInformationsClientType
                  clientType={watchAllFields.clientType}
                  setToMissingSiret={setToMissingSiret}
                />
              ) : null}

              <div style={{ marginTop: 24 }} id={OrderSections.EndClientInformations} />
              <div className="section-title">
                {t('features.order.orderInformations.endClient', 'End Client informations')}
              </div>
              <div
                style={{
                  width: '100%',
                  paddingBottom: '16px'
                }}
              >
                {watchAllFields.clientType === ClientType.COMPANY && (
                  <OrderInformationsClientCompanyForm
                    orderInformationsFormValues={watchAllFields}
                    isHomeBasicProduct={isHomeBasicProduct(productToOrder)}
                    companies={companies}
                    setSelectedOption={setSelectedOption}
                    selectedOption={selectedOption}
                    order={order}
                    defineSiretToRegistration={defineSiretToRegistration}
                    siretValue={siretValue}
                    isMissingSIRET={isMissingSIRET}
                    setToMissingSiret={setToMissingSiret}
                    setWithSiret={setWithSiret}
                  />
                )}
              </div>
              <OrderInformationsClientForm<IOrderCreationParams>
                isHomeOrOfficeProduct={isHomeOrOfficeProduct}
                isHomeBasicProduct={isHomeBasicProduct(productToOrder)}
                isBusiness={false}
                orderInformationsFormValues={watchAllFields}
              />
              <StyledSeparator />
              <div id={OrderSections.FinalClientSite} />
              <VerticalityProvider getValues={getValues} setValue={setValue}>
                <OrderInformationsSiteForm orderInformationsFormValues={watchAllFields} />
                <StyledSeparator />
                <div id={OrderSections.Outlet} />
                <>
                  {watchAllFields.building && watchAllFields.floor && watchAllFields.stair && (
                    <>
                      <OrderInformationsOutletForm
                        control={control}
                        orderInformationsFormValues={watchAllFields}
                      />
                      <StyledSeparator />
                    </>
                  )}
                </>
              </VerticalityProvider>

              <div id={OrderSections.ComplementaryInformations} />
              <OrderInformationsComplementaryForm orderInformationsFormValues={watchAllFields} />
            </form>
          </FormProvider>
        </Card>
        <Card className="side_content">
          {productToOrder && (
            <OrderInformationsSidebar
              productToOrder={productToOrder}
              selectedOptions={selectedOptions}
            />
          )}
        </Card>
      </CardOrderInformation>
      <OrderInformationsModalConfirmPTO
        isVisible={showPTOWarningModal}
        refPTO={watchAllFields.outlet}
        isNewInstallation={watchAllFields.complementaryOutlet}
        handleClose={() => setshowPTOWarningModal(false)}
        handleConfirm={nextStep}
      />
    </>
  );
};

export default OrderInformationsBasicForm;

const CardOrderInformation = styled.div`
  display: flex;
  gap: 2rem;
  align-items: start;
  max-width: 1140px;
  margin: 0 auto 2rem auto;

  .section-title {
    margin-bottom: 1rem;
    font-weight: ${theme.fonts.weight.medium};
    font-size: ${theme.fonts.size.sm};
    line-height: ${theme.fonts.spacing.sm};

    color: ${theme.palette.grey[500]};
  }

  .side_content {
    max-width: 400px;
    margin-bottom: 1.5rem;
    background: ${theme.palette.blue[50]};
    box-shadow: none;
    p {
      font-weight: ${theme.fonts.weight.medium};
      color: ${theme.palette.grey[500]};
    }
  }
`;
