import { loadOrganizations } from '../../../eligibility/eligibilitySlice';
import React, { useState, ReactNode, useEffect } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { useAppDispatch } from '../../../../app/hooks';
import { LoaderIcon } from 'react-hot-toast';
import { Order, OrderOperaBusiness } from '@features/order/utils/orderInterfaces';

const MenuList = (props: {
  children: ReactNode;
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  defineSiretToRegistration: () => void;
  setValue: UseFormSetValue<FieldValues>;
  options:
    | {
        value: string;
        label: string;
      }[]
    | undefined;
}) => {
  const { watch, clearErrors } = useFormContext();
  const siret = watch('siret');
  const dispatch = useAppDispatch();
  const [isLoading, setIsloading] = useState(true);
  const [fidingSiret, setFidingSiret] = useState<{ siret: string; name: string } | null>(null);
  //44958654400917
  const loadOrganisation = () => {
    dispatch(loadOrganizations(siret)).then((res) => {
      if (res.payload) {
        setIsloading(false);
        setFidingSiret(res?.payload[0]);
      }
    });
  };
  loadOrganisation();
  const setValues = () => {
    props.setValue('siret', siret);
    props.setValue('businessName', fidingSiret?.name.substring(0, 64));
  };
  return (
    <div {...props}>
      <div
        style={{
          background: isLoading || fidingSiret?.siret ? 'white' : '#f0f0f0',
          position: 'sticky',
          bottom: 0,
          cursor: 'pointer'
        }}
        onClick={() => {
          clearErrors('siret');
          props.setMenuIsOpen(false);
          {
            fidingSiret?.siret ? setValues() : props.defineSiretToRegistration();
          }
        }}
      >
        {isLoading ? (
          <div style={{ padding: 5 }}>
            <LoaderIcon />
          </div>
        ) : (
          <div style={{ padding: '10px', textAlign: 'center' }}>
            {fidingSiret?.siret || props.message}
          </div>
        )}
      </div>
    </div>
  );
};

const SiretSelect: React.FC<{
  isSiretMandatory?: boolean;
  SIRET_LENGTH: number;
  register: UseFormRegister<FieldValues>;
  defineSiretToRegistration: () => void;
  setValue: UseFormSetValue<FieldValues>;
  companies?: {
    siret: string;
    name: string;
  }[];
  setSelectedOption: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
  selectedOption: {
    label: string;
    value: string;
  };
  siretValue: string;
  order: Order | OrderOperaBusiness | undefined;
  disabled?: boolean;
}> = ({
  isSiretMandatory,
  SIRET_LENGTH,
  register,
  defineSiretToRegistration,
  setValue,
  companies,
  setSelectedOption,
  selectedOption,
  siretValue,
  order,
  disabled = false
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const { setError, clearErrors } = useFormContext();

  const { t } = useTranslation();

  const options = companies?.map((company) => ({
    value: company.siret,
    label: `${company.siret}`
  }));

  const handleInputChange = (inputValue: string, state: any) => {
    clearErrors();
    if (inputValue && !/^\d+$/.test(inputValue)) {
      setError('siret', { message: t('errorMessages.invalid', `Invalid format`), type: 'value' });
    }
    if (inputValue.length > SIRET_LENGTH) {
      setError('siret', { message: t('errorMessages.invalid', `Invalid format`), type: 'value' });
    }
    if (inputValue) setSelectedOption({ label: inputValue || '', value: inputValue });
    if (inputValue?.length === 14) {
      setMenuIsOpen(true);

      setValue('siret', inputValue);
    } else {
      setMenuIsOpen(false);
    }
  };

  const handleChange = (option: SingleValue<{ label: string; value: string }>) => {
    if (option) {
      setSelectedOption(option);
      setValue('siret', option.value);
      const companyName = companies?.find((company) => company.siret === option.value)?.name;
      setValue('businessName', companyName);
    } else {
      setValue('siret', null);
      setValue('businessName', '');
      setSelectedOption({ label: '', value: '' });
    }
    setMenuIsOpen(false);
  };

  useEffect(() => {
    if (order?.customer?.siret) {
      setSelectedOption({ value: order?.customer?.siret, label: order?.customer?.siret });
    }
  }, [order?.customer?.siret]);

  const filterOption = (
    candidate: FilterOptionOption<{ label: string; value: string }>,
    input: string
  ) => {
    return candidate.label.startsWith(input);
  };

  const resetField = () => {
    setSelectedOption({ label: '', value: '' });
    defineSiretToRegistration();
  };

  const handleBlur = () => {
    if (!siretValue || menuIsOpen || siretValue.length !== SIRET_LENGTH) {
      setSelectedOption({ label: '', value: '' });
    }
  };

  return (
    <Select
      disabled={disabled}
      {...register('siret', {
        validate: {
          isValidLength: (v: string) => {
            if (!isSiretMandatory) {
              return true;
            }
            return v?.length === SIRET_LENGTH || t('errorMessages.invalid', `Invalid format`);
          },
          isValidSiret: (v: string) => {
            if (!isSiretMandatory) {
              return true;
            }
            return /^\d+$/.test(v) || t('errorMessages.invalid', `Invalid format`);
          }
        }
      })}
      menuIsOpen={menuIsOpen}
      options={options}
      filterOption={filterOption}
      onBlur={handleBlur}
      value={{ label: selectedOption.label, value: selectedOption.value }}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isDisabled={!isSiretMandatory}
      placeholder="SIRET"
      isClearable={true}
      components={{
        MenuList: (props) => {
          return (
            <MenuList
              {...props}
              setMenuIsOpen={setMenuIsOpen}
              setValue={setValue}
              defineSiretToRegistration={resetField}
              message={t(
                'feature.order.information.company.form.siret.immatriculation',
                `SIRET non trouvé / référencé`
              )}
              options={options}
            />
          );
        },
        NoOptionsMessage: (props) => <></>
      }}
    />
  );
};

export default SiretSelect;
