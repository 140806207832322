import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

export const ErrorMessageWithMail = () => {
  const { t } = useTranslation();
  return (
    <ErrorMessageWithMailStyled>
      {t('errorMessages.tryAgain', 'Please try again later or contact')}{' '}
      <span className="email">support.mon-espace@axione.fr</span>
    </ErrorMessageWithMailStyled>
  );
};
const ErrorMessageWithMailStyled = styled.div`
  .email {
    font-weight: ${theme.fonts.weight.medium};
    color: ${theme.palette.blue[500]};
  }
`;
