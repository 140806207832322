import { Fragment, Key, ReactChild, ReactFragment, ReactPortal } from 'react';
import { HomeBasicProductCode, productsListWithInfos, productsListWithInfosType } from './const';
import {
  IDataAddress,
  IDataBuilding,
  IDataCoordinates,
  IDataFunnel,
  IDataOTO,
  IDataSiret,
  IOfferingResult,
  ISearchInfos,
  ProductOfferingQualificationItem,
  ProductPrice
} from './interfaces';
import { engagementLabels, leadTimeMsg, listOfLabelsFull } from './products/constants';

import Badge from '@designSystem/Badges';
import { DateManager } from '../../utils/DateManager';
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { axioneInfoBase64 } from '../../images/axioneInfoBase64';
import { axioneLogoBase64 } from '../../images/axioneLogoBase64';
import styled from 'styled-components';
import theme from '@theme';
import { useTranslation } from 'react-i18next';

const StyledCoordinates = styled.span`
  font-weight: ${theme.fonts.weight.regular};
`;

export const exportFile = (
  link: string,
  infos?: { address: string; id: string; referenceType?: string }
) => {
  return ({ columns, data, filename, fileType }: any) => {
    const headerNames = columns.map((column: { exportValue: any }) => column.exportValue);
    const doc = new JsPDF('l', 'mm', 'a4');

    const image = new Image();
    image.src = axioneLogoBase64;
    doc.addImage(image, 'PNG', (doc.internal.pageSize.getWidth() - 44) / 2, 10, 44, 11);

    const id = getBuildingIdFromAddress({
      id: infos?.id ?? '',
      referenceType: infos?.referenceType
    });
    autoTable(doc, {
      theme: 'plain',
      bodyStyles: { fontSize: 12 },
      margin: { top: 30 },
      body: [
        ['Date du devis', new DateManager().format('DD/MM/YYYY')],
        ['Date de fin de validité', new DateManager().add(3, 'M').format('DD/MM/YYYY')],
        ['Adresse', infos?.address || ''],
        ['Identifiant immeuble', id]
      ]
    });

    // Create a blue rectangle #F5F9FF
    doc.setFillColor(245, 249, 255);
    doc.rect(10, 70, doc.internal.pageSize.getWidth() - 20, 30, 'F');

    // add info bulle img
    const infoImg = new Image();
    infoImg.src = axioneInfoBase64;
    doc.addImage(infoImg, 'PNG', 15, 75, 10, 10);

    doc.link(10, 70, doc.internal.pageSize.getWidth() - 20, 30, { url: link });

    doc.text('Souhaitez-vous passer commande ?', 27, 83);

    doc.setTextColor(56, 132, 255);
    doc.text('Commander ', 27, 93);

    autoTable(doc, {
      headStyles: { fillColor: [56, 132, 255] },
      head: [headerNames],
      body: data,
      startY: 105,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: 'left',
        valign: 'middle',
        fontSize: 10
      }
    });

    // doc.output('dataurlnewwindow'); //to check pdf generate

    const file_name = filename || `Devis Axione - ${new DateManager().unix()}`;
    doc.save(`${file_name}.${fileType}`);

    return false;
  };
};

export const getBuildingIdFromAddress = (
  addressInfos: { referenceType?: string; id: string } | undefined
) => {
  return addressInfos?.referenceType === 'Building' ? addressInfos.id : '-';
};

export const decodeDataFrom = (searchParams: URLSearchParams) => {
  let jsonData;

  try {
    jsonData = JSON.parse(decodeURIComponent(atob(searchParams.get('data') || '') || '{}'));
  } catch {
    jsonData = {};
  }
  return jsonData;
};

export const encodeDataFrom = (data: any) => {
  return btoa(encodeURIComponent(JSON.stringify(data)));
};

export const getAddressInfosFormatted = (
  search: ISearchInfos | undefined,
  text: (boolean | ReactChild | ReactFragment | ReactPortal | null | undefined)[] | null | undefined
) => {
  const { t } = useTranslation();
  let title = t('features.eligibility.selectedAddress', 'Selected address');
  let body =
    text && text.length > 0 ? (
      text.map(
        (
          t: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined,
          i: Key | null | undefined
        ) => {
          return (
            <Fragment key={i}>
              <span>{t}</span>
              <br />
            </Fragment>
          );
        }
      )
    ) : (
      <></>
    );
  if (search) {
    switch (search.type) {
      case 'funnel':
        body = (
          <>
            {(search.data as IDataFunnel).streetNr || ''}, {(search.data as IDataFunnel).street},{' '}
            {(search.data as IDataFunnel).postcode} {(search.data as IDataFunnel).city}
          </>
        );
        break;
      case 'address':
        body = <>{(search.data as IDataAddress).address}</>;
        break;
      case 'geoaddress':
        body = <>{(search.data as IDataAddress).address}</>;
        break;
      case 'siret':
        title = t('features.eligibility.selectedCompany', 'Selected company');
        body = <>{(search.data as IDataSiret).label}</>;
        break;
      case 'initialOTO':
        title = t('features.eligibility.selectedOto', 'Selected OTO');
        body = <>{(search.data as IDataOTO).otoRef}</>;
        break;
      case 'building':
        title = t('features.eligibility.selectedBuilding', 'Selected building');
        body = <>{(search.data as IDataBuilding).buildingRef}</>;
        break;
      case 'coordinates':
        title = t('features.eligibility.selectedCoordinate', 'Selected coordinates');
        body = (
          <>
            <span>
              {t('features.eligibility.latitude', 'Latitude')}
              <StyledCoordinates>{(search.data as IDataCoordinates).latitude}</StyledCoordinates>
            </span>
            <span>
              {t('features.eligibility.longitude', 'Longitude:')}
              <StyledCoordinates>{(search.data as IDataCoordinates).longitude}</StyledCoordinates>
            </span>
            <span>
              {t('features.eligibility.radius', 'Radius:')}
              <StyledCoordinates>
                {(search.data as IDataCoordinates).radius || 100}
                {t('features.eligibility.meters', 'meters')}
              </StyledCoordinates>
            </span>
          </>
        );
        break;
    }
  }
  return { title, body };
};

export const getQualificationItemBillingZone = (item: ProductOfferingQualificationItem) => {
  const bz = item?.product?.productCharacteristic.filter((pc) => pc.name === 'billingZone');

  if (bz && bz.length && bz[0].value && bz[0].value.zone) {
    return bz[0].value.zone;
  }
  return '';
};

export const getPriceByType = (prices: ProductPrice[] | undefined, priceType: string) => {
  const clonedPrices = prices?.slice(0);

  // sort by contractTerm (36 months in first, 12 months, no contractTerm at last)
  clonedPrices?.sort((a, b) => {
    if (!a.contractTerm || !b.contractTerm) return 0;
    return b.contractTerm.duration.amount - a.contractTerm.duration.amount;
  });
  return clonedPrices?.find(
    (p) =>
      (p.name.includes(priceType) &&
        p.contractTerm &&
        (p.contractTerm.duration.amount === 36 || p.contractTerm.duration.amount === 12)) ||
      p.name === priceType
  );
};

const productFilterCodes = (
  product: IOfferingResult,
  filterfunc: (param: productsListWithInfosType) => boolean
) => {
  return productsListWithInfos
    .filter(filterfunc)
    .map((offering) => offering.code)
    .some((string) => product?.productOffering.id.includes(string));
};

export const isZTD = (product: IOfferingResult) => {
  return productFilterCodes(product, (item) => item.isZTD);
};

export const isBusiness = (product: IOfferingResult) => {
  return productFilterCodes(product, (item) => item.isBusiness);
};

export const isHomeBasicProduct = (product?: IOfferingResult) => {
  return product?.productOffering.id === HomeBasicProductCode;
};

export const isProductWithVerticality = (product: IOfferingResult) => {
  return productFilterCodes(product, (item) => !!item.hasVerticality);
};

export const displayEngagement = (prices: ProductPrice[] | undefined) => {
  const price = getPriceByType(prices, 'main_monthly_fee');

  if (price) {
    return engagementLabels(price.contractTerm, listOfLabelsFull) || ' ';
  }
  return null;
};

export const getQualificationItemDelay = (item: ProductOfferingQualificationItem) => {
  const itemResult = item.qualificationItemResult;

  if (item.leadTime && ['eligible', 'to_be_studied'].indexOf(itemResult) !== -1) {
    const { value, unit } = item.leadTime;
    return leadTimeMsg(value)[unit];
  }
  return '';
};

export const makeUnavailabilityReasonBadge = (product: IOfferingResult) => {
  const { t } = useTranslation();
  const reasonStatus =
    product.eligibilityUnavailabilityReason && product.eligibilityUnavailabilityReason[0].code;
  let badge = null;

  if (!reasonStatus) {
    return <Badge variant={'green'}>{t('features.eligibility.available', 'Available')}</Badge>;
  }

  switch (reasonStatus) {
    case 'AVAILABLE_SOON':
      badge = (
        <Badge variant={'yellow'}>
          {t('features.eligibility.availableSoon', 'Available soon')}
        </Badge>
      );
      break;

    case 'FROZEN_NETWORK':
      badge = <Badge variant={'grey'}>{t('features.eligibility.frozen', 'Frozen network')}</Badge>;
      break;

    default:
      badge = <Badge>{reasonStatus}</Badge>;
      break;
  }

  return badge;
};

export const makeAvailableDateBadge = (product: IOfferingResult) => {
  const { t } = useTranslation();
  if (product.product.productCharacteristic.length) {
    let availableDate: boolean | string | undefined = false;
    product.product.productCharacteristic.forEach((characteristic) => {
      if (characteristic.name === 'commercialLaunchDate') {
        availableDate = new DateManager(characteristic.value.date).format('DD-MM-YYYY');
      }
    });

    if (availableDate) {
      return (
        <Badge variant={'blue'} style={{ marginLeft: '6px' }}>
          {t('features.eligibility.on', 'On')}
          {availableDate}
        </Badge>
      );
    }
  }
  return false;
};
