import { Fragment, ReactChild } from 'react';
import TableHeadSort, { SortObject } from '@designSystem/Table/HeadSort';

import { Column } from 'react-table';
import FullTable from '../../../components/FullTable';
import { SiteStatusRT } from '../utils/siteStatusInterfaces';
import theme from '@theme';

const OrderListTable = ({
  columns,
  data,
  isLoading,
  currentSelectedRow,
  selectedRowClassName = 'selected-row-blue',
  children,
  sortByApi,
  currentSortObject
}: {
  columns: Column<SiteStatusRT> | any;
  data: SiteStatusRT[];
  isLoading: boolean;
  currentSelectedRow: string | null;
  selectedRowClassName?: string;
  children?: ReactChild | null;
  sortByApi: (_arg1?: SortObject) => void;
  currentSortObject?: SortObject;
}) => {
  const orderListTableHeaderDisplay = (column: {
    getHeaderProps: () => { [x: string]: any; key: any };
    render: (arg0: string) => React.ReactNode;
  }) => {
    const { key, ...restColumn } = column.getHeaderProps();
    const columnKey = key.toString().replace('header_', '');
    return (
      <th style={{ padding: '8px 5px' }} key={key} {...restColumn}>
        <TableHeadSort
          style={{ padding: '2px 3px', fontSize: theme.fonts.size.xxs }}
          column={column}
          columnKey={columnKey}
          currentSortObject={currentSortObject}
          sortByApi={sortByApi}
        >
          {column.render('Header')}
        </TableHeadSort>
      </th>
    );
  };

  const orderListTableCellDisplay = (
    row: {
      getRowProps: () => { [x: string]: any; key: any };
      cells: {
        getCellProps: () => { [x: string]: any; key: any };
        render: (arg0: string) => any;
        value?: string;
      }[];
    },
    prepareRow: CallableFunction
  ) => {
    prepareRow(row);
    const { key, ...restRow } = row.getRowProps();
    const isSelected = row.cells.find(
      (c) => c.value === currentSelectedRow && currentSelectedRow !== null
    );
    return (
      <Fragment key={key}>
        <tr {...restRow} className={`${isSelected ? selectedRowClassName : ''}`}>
          {row.cells.map((cell) => {
            const { key: cellKey, ...restCell } = cell.getCellProps();
            return (
              <td
                style={{ padding: '14px', fontSize: theme.fonts.size.xxs }}
                key={cellKey}
                {...restCell}
              >
                {cell.render('Cell')}
              </td>
            );
          })}
        </tr>
        <tr>{isSelected && <td colSpan={12}>{children}</td>}</tr>
      </Fragment>
    );
  };

  return (
    <FullTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      headerRendering={orderListTableHeaderDisplay}
      cellRendering={orderListTableCellDisplay}
    />
  );
};

export default OrderListTable;
