import axios from 'axios';
import { env } from '../../../config';

export function getSiteStatusTickets(
  refPrestationPrise: string,
  currentPage: number,
  pageSize: number
) {
  return axios.get(`${env.API_URL}/dataracco/ordersTickets`, {
    params: {
      refPrestationPrise,
      currentPage: currentPage * pageSize,
      pageSize: pageSize
    }
  });
}

export function getSiteStatusDetails(orderId: string) {
  return axios.get(`${env.API_URL}/dataracco/orders/${orderId}`);
}

export function getSiteStatus(
  buildingRef: string,
  currentPage: number,
  pageSize: number,
  sortKey: string | null,
  sortDesc = 'asc'
) {
  return axios.get(`${env.API_URL}/dataracco/orders`, {
    params: {
      currentPage: currentPage * pageSize,
      pageSize: pageSize,
      buildingRef,
      sortKey: sortKey,
      sortDesc: sortDesc
    }
  });
}

export const getBuildingDetails = async (idBuilding: string) => {
  return axios.get(`${env.API_URL}/dataracco/buildingDetails`, {
    params: { buildingRef: idBuilding }
  });
};
