import { Navbar } from 'react-bootstrap';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Axione, Matrice } from '../../images/sidebar';

import Tooltip from '@designSystem/Tooltip';

import {
  SidebarContainer,
  SidebarFooterLinkWrapper,
  SidebarItemContainer,
  SidebarItemName,
  SidebarItemsList,
  SidebarLink,
  SidebarWrapper
} from './MainSidebar/SidebarStyles';
import { BYPASS_FLAG_KEY } from './const';
import { DocumentSearch } from '../../images/sidebar';
import { selectSideBarExpanded } from '@features/profiles/profileSlice';
import { SidebarMenuToggle } from './MainSidebar/menuToggle';
import { useAppSelector } from '../../app/hooks';
import classNames from 'classnames';
import CustomBetaFooter from './CustomBetaFooter';
import MainNavbar from './MainNavbar';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Role } from '../../utils/role.types';
import { SidebarItems } from './MainSidebar/SidebarItems';
import BoxUpArrow from './BoxUpArrow.svg';

const StyledNavbar = styled(Navbar)`
  grid-row: unset;
`;

const Layout = ({ withSidebar }: { children?: React.ReactNode; withSidebar: boolean }) => {
  const expanded = useAppSelector(selectSideBarExpanded);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const bypassFlag = searchParams.get(BYPASS_FLAG_KEY);
  const trackEvent = useMatomoHarmonizer();

  useEffect(() => {
    if (bypassFlag !== null) {
      localStorage.setItem(BYPASS_FLAG_KEY, bypassFlag);
    }
  }, [bypassFlag]);

  const canDisplayCustomBetaFooter = expanded && pathname.startsWith('/ordersBeta');
  const { t } = useTranslation();

  const { profileType } = useSelector((state: RootStateOrAny) => state.profile);

  const rolesEscalate = [
    Role.PORTAIL_INFOS_ESCALATION_VIEWER,
    Role.PORTAIL_INFOS_ESCALATION_EDITOR_INTERN
  ];

  const isEscalateProfil = profileType.rolesNames.some((role: Role) =>
    rolesEscalate.includes(role)
  );

  return (
    <div className="main-wrapper main-wrapper-responsive-lg">
      <StyledNavbar className="main-header" expand="lg" bg="light" variant="light">
        <MainNavbar />
      </StyledNavbar>
      {withSidebar && (
        <SidebarContainer reduced={!expanded}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <SidebarMenuToggle />
            <div style={{ flex: 1, overflow: 'auto' }}>
              <SidebarItems expanded={expanded} />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px'
              }}
            >
              {/* <SidebarFooterLink> */}
              {isEscalateProfil && (
                <div style={{ height: '28px' }}>
                  <SidebarWrapper>
                    <SidebarItemsList>
                      <SidebarItemContainer className={classNames('sidebar-menu-nav-link')}>
                        {isEscalateProfil && (
                          <SidebarLink to={'/escalation'}>
                            <Tooltip icon={<img src={Matrice} alt="icon" />} direction="right">
                              {t('component.layout.escalate', `Matrice d'escalade`)}
                            </Tooltip>
                            {expanded && (
                              <SidebarItemName>
                                {t('component.layout.escalate', `Matrice d'escalade`)}
                              </SidebarItemName>
                            )}
                          </SidebarLink>
                        )}
                      </SidebarItemContainer>
                    </SidebarItemsList>
                  </SidebarWrapper>
                </div>
              )}

              <div style={{ height: '28px' }}>
                <SidebarLink to={'/legals'}>
                  <Tooltip icon={<img src={DocumentSearch} alt="icon" />} direction="right">
                    {t('component.layout.privacy', 'Privacy policy')}
                  </Tooltip>
                  {expanded && (
                    <SidebarItemName>
                      <div
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          textAlign: 'left',
                          color: '#6B7280'
                        }}
                      >
                        {t('component.layout.privacy', 'Privacy policy')}
                      </div>
                    </SidebarItemName>
                  )}
                </SidebarLink>
              </div>

              {canDisplayCustomBetaFooter && <CustomBetaFooter />}
              <div style={{ backgroundColor: '#F3F4F6', height: '1px' }}></div>

              <SidebarFooterLinkWrapper style={{ height: '52px' }}>
                <a
                  style={{ padding: '8px' }}
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://espace-client.axione.fr"
                  onClick={() =>
                    trackEvent({
                      page: 'Sidebar',
                      category: 'Ancien_Portail',
                      actionType: 'Clic',
                      actionDetails: `Redirection`
                    })
                  }
                >
                  {expanded && (
                    <div
                      style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '8px' }}
                    >
                      <img style={{ width: '19px', height: '19px' }} src={Axione} />
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span>Redirection</span>
                        <span style={{ fontSize: '12px' }}>Espace Client</span>
                      </div>
                    </div>
                  )}
                  <Tooltip
                    icon={<img src={BoxUpArrow} style={{ width: '12px' }} />}
                    direction="right"
                  >
                    Redirection Espace Client
                  </Tooltip>
                </a>
              </SidebarFooterLinkWrapper>
              {/* </SidebarFooterLink> */}
            </div>
          </div>
        </SidebarContainer>
      )}
      <main className="main-container container-fluid p-0">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
