import { CSSProperties, useContext, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import InformationBlock from '@designSystem/InformationBlock';
import { MatomoCustomContext } from '../../utils/MatomoCustomProvider';
import Order from '../order';
import { selectOrderState } from '@features/order/reducer/orderSlice';
import { useAppSelector } from '../../app/hooks';
import { useMatomoHarmonizer } from '../../utils/matomoHarmonizer';
import { useTranslation } from 'react-i18next';
import { Stars } from 'react-bootstrap-icons';
import styled from 'styled-components';
import theme from '@theme';

const OrderBetaCreatePage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { orderFormSteps } = useAppSelector(selectOrderState);
  const { matomoTrackPageView } = useContext(MatomoCustomContext);
  const trackEvent = useMatomoHarmonizer();

  // If we come from drafts or tracking, we want to go directly to order.
  // True by default. If no-construction is undefined, it will be set to true; otherwise, it will be set to false;
  const [isOnConstructionPage, setIsOnConstructionPage] = useState<boolean>(
    !searchParams.get('no-construction')
  );
  const [displayHeadband, setDisplayHeadband] = useState(false);

  const hasAddressInfo = !!searchParams.get('type');
  const closeHeadBand = () => {
    trackEvent({
      page: 'Commandes',
      category: 'page_accueil',
      actionType: 'Clic',
      actionDetails: 'Close_Bandeau'
    });
    setDisplayHeadband(false);
  };

  useEffect(() => {
    if (orderFormSteps.currentStep === 0 && !searchParams.get('no-construction')) {
      setIsOnConstructionPage(true);
    }
  }, [orderFormSteps.currentStep]);

  useEffect(() => {
    // if (searchParams.get('no-construction')) {
    //   setSearchParams(''); // Remove construction parameter from url to prevent unsuspected behaviors
    // }
    matomoTrackPageView('Commandes');
  }, []);

  const HeadandComponent = (style: CSSProperties = {}) => {
    if (displayHeadband)
      return (
        <InformationBlock
          type="custom"
          customIcon={<Stars color="#C2410C" />}
          iconVariant="orange"
          backgroundVariant="orange"
          style={style}
          title={<TitleStyled>{t('pages.order.news_mep')}</TitleStyled>}
          canClose
          onClose={closeHeadBand}
        />
      );
    return null;
  };

  if (orderFormSteps.currentStep > 0 || hasAddressInfo || !isOnConstructionPage) {
    if (orderFormSteps.currentStep === 2) {
      return <Order headband={HeadandComponent()} />;
    }
    return <Order headband={null} />;
  }

  return <Navigate to="/ordersBeta/tracking" />;
};

export default OrderBetaCreatePage;

const TitleStyled = styled.div`
  color: ${theme.palette.orange[700]};
`;
