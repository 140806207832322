import { Navigate } from 'react-router-dom';
import React from 'react';
import { useRedirect } from '../app/hooks';

export const RedirectHandler = ({ children }: { children?: React.ReactNode }) => {
  const { redirect, isAbsolute = false } = useRedirect();
  if (redirect) {
    if (isAbsolute) {
      window.location.replace(redirect);
      return null;
    }

    return <Navigate to={redirect} replace />;
  }

  return <>{children}</>;
};
